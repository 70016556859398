<template>
  <ExperienceCard
    v-if="activeBrandIsFord"
    :experience-id="1"
    :title="$l('resourceCenter.experience.reserve.title')"
    :bottom-title="$l('resourceCenter.experience.reserve.bottomTitle')"
  >
    <template #top>
      <!-- This code wasn't deleted because the links might be needed in the future -->
      <!-- <TextButton
        :to="{ name:'ResourceResults', query:{ experience: 1, topic: 23 }}"
        text="Mustang Mach-E"
      />
      <TextButton
        :to="{ name:'ResourceResults', query:{ experience: 1, topic: 5 }}"
        text="Bronco"
      />
      <TextButton
        :to="{ name:'ResourceResults', query:{ experience: 1, topic: 27 }}"
        text="All-Electric F-150 Lightning"
      /> -->
    </template>
    <template #topFooter>
      <StandardButton
        icon-right="chevron-right"
        track-event-name="select_reserve"
        track-section-name="reserve"
        :to="{ name: 'ResourceResults', query: { experience: 1 }}"
        :text="$l('general.select')"
      />
    </template>
    <!-- This code wasn't deleted because the links might be needed in the future -->
    <!-- <template #bottom>
      <p>{{ $l('general.comingSoon') }}</p>
    </template>
    <template #bottomFooter>
      <StandardButton
        outline
        disabled
        iconRight="chevron-right"
        :text="$l('general.select')"
      />
    </template> -->
  </ExperienceCard>
  <ExperienceCard
    v-else
    :title="$l('resourceCenter.experience.reserve.title')"
  >
    <template #topFooter>
      <StandardButton
        outline
        icon-right="chevron-right"
        track-event-name="select_reserve"
        track-section-name="reserve"
        :to="{ name: 'ResourceResults', query: { experience: 1 }}"
        :text="$l('general.select')"
      />
    </template>
  </ExperienceCard>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import ExperienceCard from './ResourceCenterExperienceCard.vue';

export default {
  name: 'ResourceCenterCardReserve',
  components: {
    StandardButton,
    ExperienceCard,
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
    }),
  },
};
</script>
