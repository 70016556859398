<template>
  <div class="stripe-cart-checkout">
    <form
      class="stripe-cart-checkout__form"
      @submit.prevent="confirmCartCheckoutAmount"
    >
      <AppDropdown
        v-model="maxCreditCardAmount"
        id="stripe-cart-checkout__max-credit-amount"
        class="stripe-cart-checkout__dropdown"
        :disabled="!userIsKeyDecisionMaker"
        :label="$l('stripe.feeSetup.card.cartCheckout.select')"
        :options="dropdownOptions"
      />
      <span
        v-if="showDepositIsConfirmedMessage"
        v-text="$l('general.confirmed')"
        class="stripe-cart-checkout__confirmed-message"
      />
      <template v-else-if="userIsKeyDecisionMaker">
        <AppCheckbox
          v-model="userConfirmAmount"
          id="stripe-cart-checkout__checkbox"
          :label="$l('general.confirm')"
        />
        <StandardButton
          type="submit"
          track-event-name="submitForm_cartCheckout"
          track-section-name="stripeAccountCartCheckout"
          :is-loading="isLoading"
          :disabled="!userConfirmAmount"
          :text="$l('general.save')"
        />
      </template>
    </form>
    <TextField
      v-model="checkoutDepositText"
      id="stripe-cart-checkout__input"
      class="stripe-cart-checkout__input"
      disabled
      :label="$l('stripe.feeSetup.card.cartCheckout.input')"
      :description="$l('stripe.feeSetup.card.reservation.description')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppCheckbox from './common/AppCheckbox.vue';
import AppDropdown from './common/AppDropdown.vue';
import StandardButton from './common/StandardButton.vue';
import TextField from './common/TextField.vue';

export default {
  name: 'StripeFeeSetupCardCartCheckout',
  components: {
    AppCheckbox,
    AppDropdown,
    StandardButton,
    TextField,
  },
  data() {
    return {
      isLoading: false,
      maxCreditCardAmount: 0,
      userConfirmAmount: false,
    };
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      depositOptions: 'stripe/cartCheckoutDepositOptions',
      records: 'stripe/records',
    }),
    dropdownOptions() {
      return this.depositOptions.map((option) => ({
        text: `$${option.amount}`,
        value: +option.amount,
      }));
    },
    checkoutDepositText() {
      return '$100';
    },
    showDepositIsConfirmedMessage() {
      return this.records.cartCheckout.depositConfirmed
        && this.records.cartCheckout.deposit === this.maxCreditCardAmount;
    },
  },
  watch: {
    activeBrandIsFord: {
      immediate: true,
      handler() {
        this.userConfirmAmount = false;
        this.getMaxCreditCardAmount();
      },
    },
  },
  methods: {
    getMaxCreditCardAmount() {
      const defaultDeposit = this.depositOptions.filter(({ isDefault }) => isDefault)[0]?.amount;
      const defaultValue = +(defaultDeposit || this.depositOptions[0]?.amount);
      const { deposit } = this.records.cartCheckout;

      this.maxCreditCardAmount = deposit || defaultValue;
    },
    async confirmCartCheckoutAmount() {
      if (!this.userConfirmAmount) return;

      this.isLoading = true;

      try {
        await this.$store.dispatch('stripe/confirmCartCheckoutAmount', {
          amount: this.maxCreditCardAmount,
        });

        this.$store.dispatch('topBanner/open', {
          message: this.$l('stripe.feeSetup.card.cartCheckout.success'),
        });

        this.userConfirmAmount = false;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('stripe.feeSetup.card.cartCheckout.error'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-cart-checkout {
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    margin-bottom: 60px;
    @include respond(sm-up) {
      flex-direction: row;
      justify-content: unset;
      align-items: center;
    }
  }
  &__dropdown {
    width: 100%;
    max-width: 375px;
  }
  &__confirmed-message {
    color: $color--fds-success2;
  }
  &__input {
    max-width: 300px;
    &.lincoln {
      max-width: 475px;
    }
  }
}
</style>
