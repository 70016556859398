<template>
  <AppAccordion
    :id="accordionId"
    :description="$l('modelEDashboard.stripe.description')"
    :status="activationSummaryStatus.stripe"
    :title="$l('modelEDashboard.stripe.title')"
    :is-open="accordionIsOpen"
    color="primary-light"
    @click="$store.dispatch('modelE/dashboard/accordions/openAccordion', accordionId)"
  >
    <div class="container--accordion-group">
      <AppAccordion
        :id="feeStripeAccountId"
        color="secondary-light"
        :track-section-name="accordionId"
        :title="$l('modelEDashboard.stripe.accountSetup.title')"
        :is-open="activeSubcordionId === feeStripeAccountId"
        @click="openSubcordion(feeStripeAccountId)"
      >
        <ModelEDashboardStripeAccountSetup />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import ModelEDashboardStripeAccountSetup from './ModelEDashboardStripeAccountSetup.vue';

export default {
  name: 'ModelEDashboardAccordionStripeAccount',
  components: {
    AppAccordion,
    ModelEDashboardStripeAccountSetup,
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      accordions: 'modelE/dashboard/accordions/accordions',
      activeAccordionId: 'modelE/dashboard/accordions/activeAccordionId',
      activeSubcordionId: 'modelE/dashboard/accordions/activeSubcordionId',
      activationSummaryStatus: 'modelE/activationSummary/status',
    }),
    accordionId() {
      return this.accordions.modelEStripeAccountSetup.id;
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.accordionId;
    },
    feeStripeAccountId() {
      return this.accordions.modelEStripeAccountSetup.feeStripeAccount.id;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('modelE/dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
