<template>
  <LoadingIndicator />
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default {
  name: 'LoginView',
  components: {
    LoadingIndicator,
  },
  computed: {
    useOAuth() {
      const freeOAuthEnvironments = [];
      return !freeOAuthEnvironments.includes(process.env.VUE_APP_BASE_ENVIRONMENT);
    },
  },
  async mounted() {
    try {
      if (!this.useOAuth) {
        await this.$store.dispatch('auth/authenticateUserWithoutOAuth');
      } else {
        await this.$store.dispatch('auth/login');
      }
      this.$router.push({ name: 'Brand' });
    } catch (error) {
      this.$router.push('Ineligible');
    }
  },
};
</script>
