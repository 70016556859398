import BaseAPI from './baseAPI';

export default class Login extends BaseAPI {
  async getUser() {
    const { data } = await this.axios.get('/LoggedInUser');
    return data;
  }

  async getFeatureFlags() {
    const { data } = await this.axios.get('/FeatureFlag/FeatureFlag');
    const featuresData = data.data;
    return featuresData;
  }

  async authorize() {
    const { data } = await this.axios.get('/Login/Auth');
    return data;
  }
}
