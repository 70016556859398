<template>
  <section class="operationalize">
    <h2 class="operationalize__title fds-headline__5">
      {{ $l('resourceCenter.operationalize.title') }}
    </h2>
    <div class="row">
      <div class="operationalize__content col-xs-12 col-lg-11">
        <div class="operationalize__image-wrapper">
          <img
            class="operationalize__image"
            alt="Manager's toolkit"
            src="@/assets/images/toolkit1.png"
          />
          <img
            class="operationalize__image"
            alt="Manager's toolkit"
            src="@/assets/images/toolkit2.png"
          />
        </div>
        <div v-if="$store.getters['features/activeFeatures'].dealerAccountability">
          <h3 class="operationalize__subtitle fds-headline__6">
            {{ $l('resourceCenter.operationalize.subtitle') }}
          </h3>
          <p>{{ $l('resourceCenter.operationalize.description') }}</p>
          <ol>
            <li>{{ $l('resourceCenter.operationalize.item1') }}</li>
            <li>{{ $l('resourceCenter.operationalize.item2') }}</li>
            <li>{{ $l('resourceCenter.operationalize.item3') }}</li>
          </ol>
          <StandardButton
            class="mt-4 wide"
            icon-right="chevron-right"
            track-section-name="operationalizeEcommerce"
            :track-event-name="Event.OPEN_DEALER_READINESS_MODAL"
            :text="$l('resourceCenter.operationalize.item4')"
            @click="toggleReadinessModal"
          />
        </div>
        <h3
          v-else
          class="operationalize__coming-soon"
        >
          {{ $l('general.comingSoon') }}
        </h3>
      </div>
    </div>
    <ReadinessPlanModal
      v-if="showReadinessModal"
      @close="toggleReadinessModal"
    />
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { Event } from '@/constants/track';
import StandardButton from './common/StandardButton.vue';
import ReadinessPlanModal from './ReadinessPlanModal.vue';

const showReadinessModal = ref(false);

function toggleReadinessModal() {
  showReadinessModal.value = !showReadinessModal.value;
}
</script>

<style lang="scss" scoped>
.operationalize {
  &__title {
    margin: 0;
    margin-bottom: 40px;
  }
  &__subtitle {
    margin: 0;
    margin-bottom: 12px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include respond(sm-up) {
      flex-direction: row;
    }
  }
  &__image-wrapper {
    max-width: 350px;
  }
  &__image {
    width: 100%;
    max-width: inherit;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
  &__coming-soon {
    margin: auto;
  }
}
</style>
