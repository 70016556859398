export default {
  title:'Conditions d\'activation',
  stripe: {
    sectionOne: {
      description: 'Les nouvelles expériences commerce électronique de Lincoln présentent aux clients la capacité de soumettre des réservations, des demandes de commande et des demandes d’achat de véhicules neufs directement aux détaillants. Pour accepter les fonds soumis par les clients commerce électronique, les détaillants Lincoln doivent configurer un compte Stripe. Pour commencer le processus de demande de compte Stripe, cliquez sur le bouton « Établissement de compte Stripe ».',
      descriptionActive: 'Les nouvelles expériences commerce électronique de Lincoln présentent aux clients la capacité de soumettre des réservations, des demandes de commande et des demandes d’achat de véhicules neufs directement aux détaillants. Pour afficher les renseignements de votre compte Stripe, cliquez sur le bouton « Compte Stripe et frais ».',
      modalKdm: {
        paragraphOne: 'Nos registres indiquent que vous n’êtes pas un décideur principal pour votre concessionnaire. Les personnes suivantes sont des décideurs principaux: {names}',
      },
    },
  },
  accessoryPricing: {
    sectionOne: {
      paragraphThree: 'Il faut définir les privilèges liés aux prix des accessoires avant d’accéder au module des accessoires. Avant de pouvoir établir le prix, votre administrateur doit accorder l’autorisation EMP_eComm_Accessories à l’adresse www.dsps.dealerconnection.com. Seuls les utilisateurs autorisés du détaillant détenant la permission d’établir les prix des accessoires doivent obtenir les autorisations propres au module à cet effet.',
    },
  },
  vehiclePricing: {
    sectionOne: {
      setEntitlements: 'Il faut définir les privilèges liés aux prix des véhicules avant d’accéder au module d’établissement du prix des véhicules. Avant de pouvoir établir le prix, votre administrateur doit accorder l’autorisation VL_PRICE à l’adresse www.dsps.dealerconnection.com. Seuls les utilisateurs autorisés du détaillant détenant la permission d’établir les prix des véhicules doivent obtenir les autorisations propres au module à cet effet.',
    },
  },
  tradeIn: {
    sectionOne: {
      enrolledInARS: 'Votre détaillant est inscrit au programme ARS.',
      notEnrolledInARS: 'Votre détaillant n’est pas inscrit au programme ARS.',
      noTradeInMembers: 'Aucun membre de l’équipe consacrée aux échanges n’est défini pour votre détaillant. Veuillez cliquer sur « Mettre à jour l’équipe consacrée aux échanges ».',
      noEntitlements: 'Aucune autorisation n’est définie pour votre détaillant.',
      paragraphOne: 'Lincoln a établi un programme d’évaluation des véhicules d’échange qui permet aux clients d’entrer les données sur leur véhicule d’échange afin de recevoir une offre transactionnelle de Lincoln. Votre détaillant Lincoln pourra entièrement confirmer que le client a fourni des renseignements précis sur son véhicule et décider d’ajouter le véhicule à ses stocks, de le refuser ou de l’envoyer aux enchères par l’intermédiaire du programme d’échange entre concessionnaires des Services de remise en marché des véhicules (ARS) pour réclamer l’offre de Lincoln. Les détaillants doivent être inscrits au programme d’échange entre concessionnaires ARS pour réclamer les offres de Lincoln.',
      paragraphThree: 'Le programme ARS permet aux concessionnaires Ford Canada de vendre leurs véhicules d’occasion, quelle qu’en soit la marque, lors de ventes aux enchères commanditées par Lincoln. Ces véhicules sont mélangés à d’autres véhicules d’entreprise Lincoln. Une telle pratique améliore la rentabilité des détaillants et allège les stocks en offrant une autre option pour la vente en gros de véhicules d’occasion.',
      paragraphSix: {
        partTwo: ' en indiquant ceci comme objet : « <nom de votre détaillant> – Documents d’inscription ARS ». L’enregistrement de l’inscription peut prendre jusqu’à 10 jours ouvrables.',
      },
      paragraphEight: {
        partTwo: ' Seuls les utilisateurs du détaillant autorisés détenant la permission de gérer les véhicules d’échange doivent obtenir les autorisations propres au module de véhicules d’échange du programme ARS.',
      },
    },
  },
  customerHandling: {
    title: 'Traitement des Clients <i style="font-size: 13px;">(à venir bientôt)</i>',
    sectionOne: {
      title: 'Demandes d\'achat de Véhicules Neufs <i style="font-size: 13px;">(à venir bientôt)</i>',
      paragraphOne: '[NT] The eCommerce experiences provide your Clients the ability to submit New Vehicle Purchase Requests with direct Client-to-store deposits. Any requests a Client submits with your Lincoln store will flow directly into your CRM and into the new Client Handling Pages. The contact elected to receive these alerts during enrolment will also receive a hot email alert, notifying them of the new request and directing them to the Client Handling Pages to take action. The Client Handling Pages provide you access to the selections the Client has made in the online experience. Here you will be able to make decisions on things like accepting the submission, deposits, down payments, accepting trade-in requests and responding to inquiries for online paperwork.',
      paragraphTwo: '[NT] The overview below provides job aids and guides for review before accessing the Client Handling Pages to view and action upon requests.',
      paragraphThree: '[NT] The EMP_eComm_CustomerHandling permission is required to access and manage New Vehicle Purchase Requests in the eCommerce Client Handling Pages. Your Lincoln store administrator can set entitlements at www.dsps.dealerconnection.com. Only authorized store users with authority to manage New Vehicle Purchase Requests should be granted this entitlement.',
    },
  },
  customerActivity: {
    title: 'Activité Clientèle <i style="font-size: 13px;">(à venir bientôt)</i>',
  },
};
