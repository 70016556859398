<template>
  <div
    class="team-member"
    :class="$store.getters['user/activeBrandName']"
  >
    <header class="team-member__header">
      <div>
        <button
          v-if="!shouldBeDeleted"
          class="team-member__header-toggle-button"
          type="button"
          :aria-label="showDetails ? 'Close details' : 'Open details'"
          :disabled="isLoading"
          @click="toggleDetails"
        >
          <AppChevron :open="showDetails" />
        </button>
      </div>
      <div>
        <p
          v-if="shouldBeReviewed || shouldBeDeleted"
          v-text="messageText"
          class="team-member__message"
          :data-delete="shouldBeDeleted"
        />
        <ul
          v-if="information.length"
          class="team-member__header-info"
        >
          <template v-for="(item, index) in information">
            <li
              v-if="Boolean(item)"
              :key="index"
              class="team-member__header-info-item"
            >
              {{ item }}
            </li>
          </template>
        </ul>
      </div>
    </header>
    <div class="team-member__content">
      <button
        v-if="!showDetails"
        class="team-member__departments"
        type="button"
        :disabled="shouldBeDeleted || isLoading"
        :aria-label="showDetails ? 'Close details' : 'Open details'"
        @click="toggleDetails"
      >
        <PillLabel
          v-for="(label, index) in labels"
          :key="index"
          :active="label.active"
          :text="label.text"
        />
      </button>
      <div
        v-else
        class="team-member__details"
      >
        <slot />
      </div>
      <StandardButton
        v-if="userIsKeyDecisionMaker && shouldBeReviewed && !showDetails"
        class="team-member__button"
        type="button"
        :is-loading="isLoading"
        :text="$l('modelEDealerPersonnel.form.saveButton')"
        @click="$emit('save-contact')"
      />
      <StandardButton
        v-if="userIsKeyDecisionMaker && shouldBeDeleted"
        class="team-member__button"
        type="button"
        :is-loading="isLoading"
        :text="$l('modelEDealerPersonnel.form.deleteButton')"
        @click="$emit('delete-contact')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppChevron from './common/AppChevron.vue';
import PillLabel from './common/PillLabel.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'DashboardTeamMember',
  components: {
    PillLabel,
    StandardButton,
    AppChevron,
  },
  emits: ['save-contact', 'delete-contact'],
  props: {
    openOnMounted: {
      type: Boolean,
      default: false,
    },
    information: {
      type: Array,
      default: () => ([]),
    },
    labels: {
      type: Array,
      default: () => ([]),
    },
    shouldBeReviewed: {
      type: Boolean,
      default: false,
    },
    shouldBeDeleted: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
    }),
    messageText() {
      return this.shouldBeDeleted
        ? this.$l('modelEDashboard.dealershipSetupTeam.p2')
        : this.$l('modelEDashboard.dealershipSetupTeam.p1');
    },
  },
  mounted() {
    if (this.openOnMounted) {
      this.toggleDetails();
    }
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.team-member {
  --text-color: #{$color--fds-primary};
  --font-size: 16px;
  padding-bottom: 45px;
  margin-top: 15px;
  border-bottom: 1px solid #eee;
  &.lincoln {
    --text-color: #{$color--lds-primary};
    --font-size: 18px;
  }
  &__header {
    display: grid;
    grid-template-columns: 56px 1fr;
    margin-bottom: 30px;
  }
  &__header-toggle-button {
    width: fit-content;
    height: fit-content;
    padding: 0;
    background-color: transparent;
    border: 0;
    outline-offset: 5px;
    transform: translateY(6px);
  }
  &__header-info {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    @include respond(sm-up) {
     flex-direction: row;
    }
  }
  &__header-info-item {
    color: var(--text-color);
    font-size: var(--font-size);
    line-height: 1;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
    @include respond(sm-up) {
      display: flex;
      align-items: center;
      &:not(:last-of-type) {
        margin-right: 16px;
        padding-right: 16px;
        margin-bottom: 0;
        border-right: 1px solid #C8C8C8;
      }
    }
  }
  &__departments {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 35px;
    padding: 0;
    padding-left: 45px;
    background-color: transparent;
    border: 0;
    outline-offset: 5px;
  }
  &__button {
    margin-top: 40px;
  }
  &__content {
    padding-left: 56px;
  }
  &__message {
    color: $color--fds-primary;
    margin-bottom: 24px;
    position: relative;
    &[data-delete="true"] {
      --icon-color: #{$color--fds-error1};
    }
    &::before {
      content: $app-icon-warning-filled;
      display: block;
      color: var(--icon-color, #{$color--fds-caution1});
      font-family: $font-family--icons;
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(calc(-100% - 8px));
    }
  }
}
</style>
