<template>
  <div
    class="top-banner"
    :class="topBannerClasses"
  >
    <div class="top-banner__content-wrapper">
      <div
        class="top-banner__content"
        :class="{ 'top-banner__content--clickable': hasDetails }"
        @click="toggleDetails"
      >
        <p class="top-banner__message">
          {{ message }}
        </p>
      </div>
      <div
        v-if="hasDetails && showDetails"
        class="top-banner__message-details"
      >
        <slot />
      </div>
    </div>
    <button
      v-if="!isPersistent || (isPersistent && hasDetails)"
      type="button"
      class="top-banner__button"
      :class="{ 'top-banner__button--open': showDetails }"
      :aria-label="isPersistent ? 'Toggle details' : 'Close banner'"
      @click="handleButtonClick"
    >
      <span class="top-banner__button-icon" />
    </button>
  </div>
</template>

<script>
import { topBannerTypes } from '@/constants/topBanner';

export default {
  name: 'TopBanner',
  emits: ['close'],
  props: {
    isPersistent: {
      type: Boolean,
      default: false,
    },
    hasDetails: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: topBannerTypes.DEFAULT,
      validator(type) {
        return Object.values(topBannerTypes).includes(type);
      },
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    topBannerClasses() {
      return {
        'top-banner--persistent': this.isPersistent || this.hasDetails,
        'top-banner--default': this.type === topBannerTypes.DEFAULT,
        'top-banner--success': this.type === topBannerTypes.SUCCESS,
        'top-banner--warning': this.type === topBannerTypes.WARNING,
        'top-banner--error': this.type === topBannerTypes.ERROR,
      };
    },
  },
  methods: {
    handleButtonClick() {
      if (this.isPersistent) this.toggleDetails();
      else this.$emit('close');
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
$top-banner-animation-duration: .5s;
.top-banner {
  position: relative;
  z-index: $z-index__topBanner;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
    0 20px 20px 0 rgba(0, 0, 0, 0.1),
    0 30px 30px 0 rgba(0, 0, 0, 0.15);
  &__content-wrapper {
    padding: 1.5rem 3rem 2.3rem;
    background-size: 100% 200%;
    background-position: 0% calc(0% - 0.8rem);
    animation-duration: $top-banner-animation-duration;
  }
  &__content {
    display: flex;
    align-items: center;
    padding-right: 2.5rem;
    animation-name: banner-icon-and-text;
    animation-duration: $top-banner-animation-duration;
    &--clickable {
      cursor: pointer;
    }
    &::before {
      font-size: 2.2rem;
      line-height: 1.8rem;
      padding-right: 1.5rem;
      font-family: $font-family--icons;
    }
  }
  &__message {
    margin: 0;
  }
  &__message-details {
    margin-top: 20px;
    :deep(p) {
      margin: 0;
    }
  }
  &__button {
    position: absolute;
    top: 0.4rem;
    right: 1.8rem;
    line-height: 1.8rem;
    padding: 1.2rem;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    font-weight: inherit;
    color: inherit;
  }
  &__button-icon {
    font-size: 1.2rem;
    &::before {
      font-family: $font-family--icons;
      content: $app-icon-clear;
      animation-name: banner-icon-and-text;
      animation-duration: $top-banner-animation-duration;

    }
  }
  &--persistent {
    .top-banner__button--open {
      .top-banner__button-icon::before {
        transform: rotate(-180deg);
      }
    }
    .top-banner__button-icon {
      font-size: 1.6rem;
      &::before {
        content: $app-icon-chevron-down;
        display: inline-block;
        transition: $top-banner-animation-duration;
      }
    }
  }
  &--default {
    .top-banner__content-wrapper {
      background-image: linear-gradient(
        to bottom, #{$color--white} 50%, #{$color--fds-primary} 50%
      );
      animation-name: show-banner, banner-default;
    }
    .top-banner__content::before {
      color: $color--fds-primary;
      content: $app-icon-info-filled;
    }
  }
  &--success {
    .top-banner__content-wrapper {
      background-image: linear-gradient(
        to bottom, #{$color--white} 50%, #{$color--fds-success1} 50%
      );
      animation-name: show-banner, banner-success;
    }
    .top-banner__content::before {
      color: $color--fds-success1;
      content: $app-icon-check-filled;
    }
  }
  &--warning {
    .top-banner__content-wrapper {
      background-image: linear-gradient(
        to bottom, #{$color--white} 50%, #{$color--fds-caution2} 50%
      );
      animation-name: show-banner, banner-warning;
    }
    .top-banner__content::before {
      color: $color--fds-caution2;
      content: $app-icon-warning-filled;
    }
  }
  &--error {
    .top-banner__content-wrapper {
      background-image: linear-gradient(
        to bottom, #{$color--white} 50%, #{$color--fds-error1} 50%
      );
      animation-name: show-banner, banner-error;
    }
    .top-banner__content::before {
      color: $color--fds-error1;
      content: $app-icon-warning-filled;
    }
  }
}

@keyframes show-banner {
  0% {
    margin-top: -58px;
    background-position: 0% 0%;
  }

  50% {
    margin-top: 0;
    background-position: 0% -100%;
  }

  100% {
    background-position: 0% calc(-200% - 8px);
  }
}

@keyframes banner-icon-and-text {
  0% {
    opacity: 0;
    color: $color--white;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  75% {
    color: $color--white;
  }
}

@keyframes banner-default {
  0% {
    background-image: linear-gradient(
      to bottom, #{$color--fds-primary} 50%, #{$color--fds-primary} 50%
    );
  }
}
@keyframes banner-success {
  0% {
    background-image: linear-gradient(
      to bottom, #{$color--fds-success1} 50%, #{$color--fds-success1} 50%
    );
  }
}
@keyframes banner-warning {
  0% {
    background-image: linear-gradient(
      to bottom, #{fds-color--caution2} 50%, #{fds-color--caution2} 50%
    );
  }
}
@keyframes banner-error {
  0% {
    background-image: linear-gradient(
      to bottom, #{fds-color--error1} 50%, #{fds-color--error1} 50%
    );
  }
}
</style>
