export default {
  title: 'Key Decision Maker(s)',
  description1: 'In order to successfully enrol you must assign at least one Key Decision Maker (KDM) within your dealership that will have authority to make eCommerce Program decisions.',
  description2: 'When updated Terms & Conditions are sent, your dealership will need to agree to them to remain in the program. The functionality below allows you to add up to three Key Decision Makers, in addition to yourself, who will be authorized to agree to the updated Terms & Conditions.',
  tooltip: 'The Key Decision Maker has the authority to agree to Terms & Conditions and manage the eCommerce team members for your dealership.',
  department: {
    name: 'Department',
    titleOne: 'Management',
  },
  item: {
    toggleDetailsButtonAriaLabel: '@:dealerPersonnel.item.toggleDetailsButtonAriaLabel',
    checkboxLabel: 'By selecting the checkbox, I acknowledge that the information provided is correct and that I am authorized (with F(ii) Authority) to complete the enrolment for my dealership.',
  },
  input: {
    position: '@:dealerPersonnel.input.position',
    phone: '@:dealerPersonnel.input.phone',
    extension: '@:dealerPersonnel.input.extension',
    wslx: 'WSLX ID (FMCDealer Login)',
  },
  messages: {
    savedSuccessfuly: 'Contact saved successfully.',
    updatedSuccessfuly: 'Contact updated successfully.',
    deletedSuccessfuly: 'Contact deleted successfully.',
    failedToSave: 'Failed to save the contact. Please try again.',
    failedToUpdate: 'Failed to update the contact. Please try again.',
    failedToDelete: 'Failed to delete the contact. Please try again.',
    saveKdm: 'Key Decision Maker',
    fourKeys: 'Only four Key Decision Makers can be active at one time. Please disable another Key Decision Maker before activating this one.',
    disableKdm: 'Please save another Key Decision Maker as Active before disabling this user.',
  },
  buttons: {
    add: 'Add Key Decision Maker',
    save: '@:dealerPersonnel.item.saveButtonText',
    delete: '@:dealerPersonnel.item.deleteButtonText',
  },
};
