<template>
  <div class="select-department">
    <h2 class="select-department__title fds-headline__5">
      {{ $l('resourceCenter.department.title') }}
    </h2>
    <div class="select-department__content">
      <StandardButton
        v-for="department in activeDepartments"
        :key="department.id"
        track-section-name="selectByDepartment"
        :track-event-name="`select_department-${department.text.replaceAll(' ', '')}`"
        :to="{ name: 'ResourceResults', query: { department: department.id } }"
        :text="department.text"
      />
    </div>
  </div>
</template>

<script>
import apiManager from '@/api';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'ResourceCenterDepartment',
  components: {
    StandardButton,
  },
  data() {
    return {
      departmentList: [],
    };
  },
  computed: {
    activeDepartments() {
      const activeLocale = this.$store.getters['locale/activeLocale'];
      return this.departmentList.filter(({ language }) => language === activeLocale);
    },
  },
  async mounted() {
    const { data: { departments } } = await apiManager.resourceCenter.getFilters('comp');

    this.departmentList = departments;
  },
};
</script>

<style lang="scss" scoped>
.select-department {
  &__title {
    margin: 0;
    margin-bottom: 40px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
}
</style>
