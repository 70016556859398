<template>
  <div class="ars | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="ars-document"
      :resources="resources"
    />
    <ModelEDashboardArsTradeIn @trade-in-complete="handleTradeInComplete" />
    <div
      v-if="showArsEntitlementsSection"
    >
      <DashboardDivider />
      <div class="ars__toast-wrapper">
        <AppToast
          v-if="isEnrolledInARS"
          class="ars__toast--success"
          type="success"
          theme="model-e"
          :text="$l('modelEDashboard.tradeIn.dashboardArs.text1')"
        />
        <AppToast
          v-else
          class="ars__toast--error"
          theme="model-e"
          type="error"
          :text="$l('modelEDashboard.tradeIn.dashboardArs.text2')"
        />
      </div>
      <div class="mt-3 mb-3">
        <p>{{ $l("modelEDashboard.tradeIn.dashboardArs.p1") }}</p>
        <p v-html="$l('modelEDashboard.tradeIn.dashboardArs.p2')" />
        <ol class="ars__list">
          <li>
            <span>{{ $l("modelEDashboard.tradeIn.dashboardArs.text3") }}</span>
          </li>
          <li>
            <span>{{ $l("modelEDashboard.tradeIn.dashboardArs.text4") }}</span>
          </li>
          <li>
            <span>{{ $l("modelEDashboard.tradeIn.dashboardArs.text8") }}</span>
            <ul>
              <li>{{ $l("modelEDashboard.tradeIn.dashboardArs.text9") }}</li>
              <li>{{ $l("modelEDashboard.tradeIn.dashboardArs.text10") }}</li>
              <li>{{ $l("modelEDashboard.tradeIn.dashboardArs.text11") }}</li>
            </ul>
          </li>
          <li>
            <span>{{ $l("modelEDashboard.tradeIn.dashboardArs.text5") }}</span>
          </li>
        </ol>
      </div>
      <div class="ars__documents">
        <DocumentCard
          v-for="document in formDocuments.documents"
          :id="`ars-document-${document.documentID}`"
          :key="document.documentID"
          :title="document.title"
          :document-name="document.document"
          :thumbnail="document.thumbnail"
          :description="document.longdesc"
        />
      </div>
      <DashboardDivider />
      <div class="row">
        <div class="col-md-9">
          <p>{{ $l('modelEDashboard.tradeIn.dashboardArs.p4') }}</p>
        </div>
        <div class="ars__button-wrapper | col-md-3">
          <StandardButton
            to="https://www.dsps.dealerconnection.com/"
            track-event-name="click_setEntitlements"
            track-section-name="tradeIn"
            :text="$l('modelEDashboard.tradeIn.dashboardArs.btn2')"
          />
        </div>
      </div>
      <div class="ars__sheet-wrapper">
        <ModelESheet>
          <b>{{ $l('modelEDashboard.tradeIn.dashboardArs.text6') }}</b>
        </ModelESheet>
        <AppToast
          v-if="entitlements.length === 0"
          class="ars__toast--error"
          theme="model-e"
          type="error"
          :text="$l('modelEDashboard.tradeIn.dashboardArs.text7')"
        />
        <span v-else>
          {{ entitlements.join(', ') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppToast from './common/AppToast.vue';
import StandardButton from './common/StandardButton.vue';
import DashboardDivider from './DashboardDivider.vue';
import DocumentCard from './DocumentCard.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';
import ModelESheet from './ModelESheet.vue';
import ModelEDashboardArsTradeIn from './ModelEDashboardArsTradeIn.vue';

export default {
  name: 'ModelEDashboardArs',
  components: {
    AppToast,
    StandardButton,
    DashboardDivider,
    DocumentCard,
    DashboardResourcesDisplay,
    ModelESheet,
    ModelEDashboardArsTradeIn,
  },
  data() {
    return {
      showArsEntitlementsSection: true,
    };
  },
  computed: {
    ...mapGetters({
      resources: 'modelE/dashboard/tradeIn/activeResources',
      formDocuments: 'modelE/dashboard/tradeIn/formDocuments',
      entitlements: 'modelE/dashboard/tradeIn/entitlements',
      isEnrolledInARS: 'modelE/dashboard/tradeIn/isEnrolledInARS',
      hideSection: 'modelE/dashboard/tradeIn/hideSection',
    }),
  },
  watch: {
    hideSection: {
      immediate: true,
      async handler() {
        this.showArsEntitlementsSection = !this.hideSection;
      },
    },
  },
  methods: {
    async handleTradeInComplete() {
      this.showArsEntitlementsSection = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ars {
  &__toast-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
  }
  &__list {
    font-weight: $font-weight--medium;
  }
  &__button-wrapper {
    margin-top: 24px;
    @include respond(md-up) {
      margin-top: 0;
      text-align: right;
    }
  }
  &__documents {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 100%;
    margin-top: 32px;
  }
  &__sheet-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 32px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
    text-align: center;
  }
  &__toast--error {
    border: none;
    font-weight: $font-weight--normal;
  }
  &__toast--success {
    border: none;
    font-weight: $font-weight--normal;
  }
}
</style>
