import apiManager from '@/api';

const stripeStore = {
  namespaced: true,
  state: {
    accountInfo: {
      contact: '',
      applicationStatus: 'incomplete',
      accountStatus: 'incomplete',
    },
    contacts: [],
    mid: {
      reservation: {
        division: '',
        dealerID: '',
        merchantID: '',
        merchantIDType: 'Reservation',
        merchantID_Confirmed: false,
        localConfirm: false,
        deposit_ACH: '',
        deposit_ACH_Confirmed: false,
        showStripeCarryOverMessage: false,
        deposit: '',
        deposit_Confirmed: false,
        deposit_AllPaymentTypes: '',
        deposit_AllPaymentTypes_Confirmed: false,
        opts: [],
      },
      order: {
        division: '',
        dealerID: '',
        merchantID: '',
        merchantIDType: 'Order',
        merchantID_Confirmed: false,
        localConfirm: false,
        showStripeCarryOverMessage: false,
        deposit: '',
        deposit_ACH: '',
        deposit_ACH_Confirmed: false,
        deposit_Confirmed: false,
        deposit_AllPaymentTypes: '',
        deposit_AllPaymentTypes_Confirmed: false,
        opts: [],
      },
      cartcheckout: {
        division: '',
        dealerID: '',
        merchantID: '',
        merchantIDType: 'CartCheckout',
        merchantID_Confirmed: false,
        localConfirm: false,
        showStripeCarryOverMessage: false,
        deposit: '',
        deposit_Confirmed: false,
        deposit_ACH: '',
        deposit_ACH_Confirmed: false,
        localConfirmACH: false,
        deposit_AllPaymentTypes: '',
        deposit_AllPaymentTypes_Confirmed: false,
        localConfirmAllPayments: false,
        opts: [],
        opts_ACH: [],
      },
      experience: '',
    },
  },
  getters: {
    primaryContact({ contacts }, getters, rootState, rootGetters) {
      const contact = contacts.find(
        ({ division }) => division === rootGetters['user/activeBrand'],
      );

      return (contact) ? `${contact.firstName} ${contact.lastName}` : null;
    },
  },
  mutations: {
    setStripeRecords: (
      state,
      { records, division },
    ) => {
      const mid = {
        division,
        dealerID: '',
        deposit: '',
        deposit_Confirmed: false,
        deposit_ACH: '',
        deposit_ACH_Confirmed: false,
        merchantID: '',
        merchantIDType: 'Reservation',
        merchantID_Confirmed: false,
        localConfirm: false,
        localConfirmACH: false,
        deposit_AllPaymentTypes: '',
        deposit_AllPaymentTypes_Confirmed: false,
        localConfirmAllPayments: false,
        showStripeCarryOverMessage: false,
        opts: [],
        opts_ACH: [],
        opts_AllPayments: [],
      };

      // == load empty template object
      state.mid = {
        reservation: JSON.parse(JSON.stringify(mid)),
        order: JSON.parse(JSON.stringify(mid)),
        cartcheckout: JSON.parse(JSON.stringify(mid)),
        experience: '',
      };

      records
        .filter((x) => x.division === division)
        .forEach((x) => {
          const mType = x.merchantIDType.toLowerCase();
          const m = state.mid;

          m[mType].division = x.division;
          m[mType].dealerID = x.dealerID;
          m[mType].deposit = x.deposit;
          m[mType].deposit_ACH = x.deposit_ACH;
          m[mType].deposit_Confirmed = x.deposit_Confirmed;
          m[mType].deposit_ACH_Confirmed = x.deposit_ACH_Confirmed;
          m[mType].deposit_AllPaymentTypes = x.deposit_AllPaymentTypes;
          m[mType].deposit_AllPaymentTypes_Confirmed = x.deposit_AllPaymentTypes_Confirmed;
          m[mType].merchantID = x.merchantID;
          m[mType].merchantIDType = x.merchantIDType;
          m[mType].merchantID_Confirmed = x.merchantID_Confirmed;
          m[mType].showStripeCarryOverMessage = x.showStripeCarryOverMessage;
        });
    },
    setDepositOptions: (
      state,
      { options, division},
    ) => {
      state.mid.reservation.opts = [];
      state.mid.order.opts = [];
      state.mid.cartcheckout.opts = [];
      options
        .filter((x) => x.division === division)
        .forEach((y) => {
          if (y.depositType === 'Reservation') {
            state.mid.reservation.opts.push({
              text: `$${y.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
              value: y.amount,
              selected: y.amount == state.mid.reservation.deposit,
            });
          }
          if (y.depositType === 'Order') {
            state.mid.order.opts.push({
              text: `$${y.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
              value: y.amount,
              selected: y.amount == state.mid.order.deposit,
            });
          }
          if (y.depositType === 'CartCheckout') {
            // eslint-disable-next-line no-console
            state.mid.cartcheckout.opts.push({
              text: `$${y.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
              value: y.amount,
              selected: y.amount == state.mid.cartcheckout.deposit,
            });
          }
          if (y.depositType === 'CartCheckout_ACH') {
            state.mid.cartcheckout.opts_ACH.push({
              text: `$${y.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
              value: y.amount,
              selected: y.amount == state.mid.cartcheckout.deposit_ACH,
            });
          }
          if (y.depositType === 'CartCheckout_AllPaymentTypes') {
            state.mid.cartcheckout.opts_AllPayments.push({
              text: `$${y.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
              value: y.amount,
              selected: y.amount == state.mid.cartcheckout.deposit_AllPaymentTypes,
            });
          }
        });
    },
    setAccountInfo: (state, { account, division }) => {
      const info = account.find((x) => x.division === division);
      if (info) {
        state.accountInfo.contact = info.primaryContact;
        state.accountInfo.accountStatus = info.stripeAccountStatus;
        state.accountInfo.applicationStatus = info.stripeApplicationStatus;
      }
    },
    setContacts(state, payload) {
      state.contacts = payload;
    },
  },
  actions: {
    getStripeData: async ({ commit }, division) => {
      if (division !== 'FL') {
        const {data} = await apiManager.stripe.getModelE();
        commit('setAccountInfo', { account: data.stripeAccounts, division });
        commit('setStripeRecords', { records: data.stripeRecords, division });
        commit('setDepositOptions', { options: data.depositOptions, division });
        commit('setContacts', data.stripeContacts.map((contact) => ({
          division: contact.division,
          firstName: contact.contact_FirstName,
          lastName: contact.contact_LastName,
        })));
      }
    },
    postStripeData: async ({ dispatch }, payload) => {
      const mid = JSON.parse(JSON.stringify(payload));
      // Since account confirmation is tied to Reservation,
      // set the others as confirmed also
      mid.reservation.merchantID_Confirmed = payload.reservation.localConfirm;
      mid.order.merchantID_Confirmed = payload.reservation.localConfirm;
      mid.cartcheckout.merchantID_Confirmed = payload.reservation.localConfirm;

      if (!mid.order.deposit_Confirmed) {
        mid.order.deposit_Confirmed = payload.order.localConfirm;
      }
      if (!mid.cartcheckout.deposit_Confirmed) {
        mid.cartcheckout.deposit_Confirmed = payload.cartcheckout.localConfirm;
      }
      if (!mid.cartcheckout.deposit_ACH_Confirmed) {
        mid.cartcheckout.deposit_ACH_Confirmed = payload.cartcheckout.localConfirmACH;
      }
      if (!mid.cartcheckout.deposit_AllPaymentTypes_Confirmed) {
        mid.cartcheckout.deposit_AllPaymentTypes_Confirmed =
        payload.cartcheckout.localConfirmAllPayments;
      }

      const cartcheckout = [
        {
          dealerID: mid.cartcheckout.dealerID,
          deposit: mid.cartcheckout.deposit,
          deposit_Confirmed: mid.cartcheckout.deposit_Confirmed,
          deposit_ACH: mid.cartcheckout.deposit_ACH,
          deposit_ACH_Confirmed: mid.cartcheckout.deposit_ACH_Confirmed,
          division: mid.cartcheckout.division,
          localConfirm: mid.cartcheckout.localConfirm,
          localConfirmACH: mid.cartcheckout.localConfirmACH,
          deposit_AllPaymentTypes: mid.cartcheckout.deposit_AllPaymentTypes,
          deposit_AllPaymentTypes_Confirmed: mid.cartcheckout.deposit_AllPaymentTypes_Confirmed,
          localConfirmAllPayments: mid.cartcheckout.localConfirmAllPayments,
          merchantID: mid.cartcheckout.merchantID,
          merchantIDType: mid.cartcheckout.merchantIDType,
          merchantID_Confirmed: mid.cartcheckout.merchantID_Confirmed,
          showStripeCarryOverMessage: mid.cartcheckout.showStripeCarryOverMessage,
        },
      ];
      const order = [
        {
          dealerID: mid.order.dealerID,
          deposit: mid.order.deposit,
          deposit_Confirmed: mid.order.deposit_Confirmed,
          deposit_ACH: mid.order.deposit_ACH,
          deposit_ACH_Confirmed: mid.order.deposit_ACH_Confirmed,
          deposit_AllPaymentTypes: mid.order.deposit_AllPaymentTypes,
          deposit_AllPaymentTypes_Confirmed: mid.order.deposit_AllPaymentTypes_Confirmed,
          division: mid.order.division,
          localConfirm: mid.order.localConfirm,
          merchantID: mid.order.merchantID,
          merchantIDType: mid.order.merchantIDType,
          merchantID_Confirmed: mid.order.merchantID_Confirmed,
          showStripeCarryOverMessage: mid.order.showStripeCarryOverMessage,
        },
      ];
      const reservation = [
        {
          dealerID: mid.reservation.dealerID,
          deposit: mid.reservation.deposit,
          deposit_Confirmed: mid.reservation.deposit_Confirmed,
          deposit_ACH: mid.reservation.deposit_ACH,
          deposit_ACH_Confirmed: mid.reservation.deposit_ACH_Confirmed,
          deposit_AllPaymentTypes: mid.reservation.deposit_AllPaymentTypes,
          deposit_AllPaymentTypes_Confirmed: mid.reservation.deposit_AllPaymentTypes_Confirmed,
          division: mid.reservation.division,
          localConfirm: mid.reservation.localConfirm,
          merchantID: mid.reservation.merchantID,
          merchantIDType: mid.reservation.merchantIDType,
          merchantID_Confirmed: mid.reservation.merchantID_Confirmed,
          showStripeCarryOverMessage: mid.reservation.showStripeCarryOverMessage,
        },
      ];
      if (payload.experience === 'reservation') {
        await apiManager.stripe.postModelE(reservation);
      }
      if (payload.experience === 'order') {
        await apiManager.stripe.postModelE(order);
      }
      if (payload.experience === 'cartAndCheckout') {
        await apiManager.stripe.postModelE(cartcheckout);
      }
      dispatch('getStripeData', mid.reservation.division);
    },
    async getAccountUrl({ dispatch, rootGetters }) {
      const { data: accountUrl } = await apiManager.stripe.getModelEAccountUrl({
        dealerId: rootGetters['user/activeDealership'].dealerId,
        language: rootGetters['locale/activeLocale'],
      });

      dispatch('load');

      return accountUrl;
    },
  },
};

export default stripeStore;
