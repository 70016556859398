import notifications from '@/data/dashboardNotifications';

export default {
  namespaced: true,
  getters: {
    notifications(state, getters, rootState, rootGetters) {
      const filteredNotifications = [];

      if (!rootGetters['termsAndConditions/userHasAcceptedTerms']) {
        filteredNotifications.push(notifications.termsAndConditions);
      }

      if (rootGetters['user/activeDealership'].enrolled) {
        // Check for ARS entitlements
        if (rootGetters['dashboard/tradeIn/entitlements'].length < 1) {
          filteredNotifications.push(notifications.arsNotSet);
        }

        // Check for Accessory Pricing entitlements
        if (rootGetters['dashboard/accessoryPricing/entitlements'].length < 1) {
          filteredNotifications.push(notifications.accessoryPricingNotSet);
        }
      }

      return [
        ...filteredNotifications,
        notifications.evCertified,
      ];
    },
  },
};
