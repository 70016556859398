export default {
  title1: 'Customer journeys',
  infoText1: 'To meet growing Customer expectations and deliver a competitive advantage, Ford Motor Company has built an eCommerce Suite of tools:',
  infoList1: {
    item1: 'Reservation',
    item2: 'Order',
    item3: 'Cart',
    item4: 'Checkout',
  },
  infoText2: 'These tools were developed with input from Ford Dealers and operate 100% through the Ford Dealer network. The result is a convenient and guided end-to-end digital retailing solution that is paired with the value of the in-dealership experience. This journey outlines the ideal eCommerce Customer and Dealer Journey, beginning with Reservation, continuing through Order and Cart, and finishing with the Checkout process.',
  infoText3: 'Select to View a Journey',
  infoList2: {
    item1: 'Reserve',
    item2: 'Reserve2',
    item3: 'Reserve3',
    item4: 'Reserve4',
  },
  linkPrint: 'Print this page',
  label1: 'Customer',
  label2: 'Dealer',
  title2: 'Reserve',
  infoText4: 'Customers can Reserve an eCommerce-eligible vehicle online from their preferred Dealer. Reservation captures early Customer interest, supports order and build prioritization, and provides Customers with a prioritized place in line.',
  infoText5: 'Customer chooses their preferred dealer',
  infoText6: 'Customer views MSRP pricing and selects their vehicle configuration (detail available will be vehicle specific)',
  infoText7: 'Customer submits their Reservation with a deposit to reserve their timestamp',
  infoP1Part1: 'Dealer receives the lead in their CRM (lead source:',
  infoP1Part2: ') and in the eCommerce Marketplace Portal (EMP).',
  infoP2: 'Dealer contacts assigned to receive alerts during eCommerce setup will also receive a Hot Alert Email at each customer submission',
  linkLearnMore: 'Learn More',
  infoText8: 'Customer receives confirmation email',
  subtitle1: 'order',
  infoText9: 'Customers can configure an eCommerce-eligible vehicle online and submit an Order Request to their preferred Dealer. Order functionality brings early hand raisers a step closer to purchase and captures new online shoppers, providing them with the ability to place an Order Request from practically anywhere.',
  infoText10: 'Customer receives an email invitation from https://www.ford.ca/ to return to complete their Order Request',
  infoText11: '@:resourcesPathJourneys.infoText10',
  infoText12: 'Customer configures their vehicle',
  infoText13: 'Customer views Dealer-set pricing (plan pricing capabilities available)',
  infoText14: 'Customer evaluates a trade-in vehicle for an estimated value',
  infoText15: 'Customer calculates and view estimated monthly payment with current available incentives',
  infoText16: 'Customer reviews and submits their Order Request to their preferred dealer with a deposit',
  infoP3Part1: 'Dealer receives the lead in their CRM (lead source:',
  infoP3Part2: ') and in the eCommerce Marketplace Portal (EMP).',
  infoP4: 'Dealer contacts assigned to receive alerts during eCommerce setup will also receive a Hot Alert Email at each customer submission',
  infoP5: 'Dealer accepts in EMP to send Order to WBDO',
  infoList3: {
    item1: 'Dealer can edit/cancel in WBDO (until vehicle is scheduled)',
  },
  infoText17: 'Customer receives confirmation email',
  subtitle2: 'buy',
  infoText18: 'Customers can complete as much or as little of the purchase process as they would like online using the new Cart and Checkout experiences. Customers who have placed orders will be invited to return to continue their purchase journey online, and, later this year, Customers will be able to pull any eligible VIN from Dealer inventory directly into the Cart to begin their purchase.',
  title3: 'Finalize purchase',
  infoText19: 'New Vehicle Purchase Requests will include a purchase type that can be found in the eCommerce Marketplace Portal (EMP) that indicates how much a Customer completed online. This information is important to Dealers as they prepare to finalize the purchase.',
  infoList4: {
    item1: 'Purchase Type Copy TBD',
    item2: 'Deal Finalization Copy TBD',
  },
};
