import activationSummary from './translations/activationSummary';
import commsCenter from './translations/commsCenter';
import dashboard from './translations/dashboard';
import dealerInformation from './translations/dealerInformation';
import dealerPersonnel from './translations/dealerPersonnel';
import dealerProfile from './translations/dealerProfile';
import faqs from './translations/faqs';
import footer from './translations/footer';
import header from './translations/header';
import help from './translations/help';
import home from './translations/home';
import impersonate from './translations/impersonate';
import keyDecisionMaker from './translations/keyDecisionMaker';
import readinessCheck from './translations/readinessCheck';
import reports from './translations/reports';
import resourceCenter from './translations/resourceCenter';
import resourcesPathJourneys from './translations/resourcesPathJourneys';
import selectExperience from './translations/selectExperience';
import start from './translations/start';
import stripe from './translations/stripe';
// Model e translations
import modelEDashboard from './translations/modelEDashboard';
import modelEStart from './translations/modelEStart';
import modelEKeyDecisionMaker from './translations/modelEKeyDecisionMaker';
import modelEDealerPersonnel from './translations/modelEDealerPersonnel';
import modelEStripe from './translations/modelEStripe';
import modelEHelp from './translations/modelEHelp';
import modelEResourceCenter from './translations/modelEResourceCenter';
import modelEReports from './translations/modelEReports';

export default {
  activationSummary,
  commsCenter,
  dashboard,
  dealerInformation,
  dealerPersonnel,
  dealerProfile,
  faqs,
  footer,
  header,
  help,
  home,
  impersonate,
  keyDecisionMaker,
  readinessCheck,
  reports,
  resourceCenter,
  resourcesPathJourneys,
  selectExperience,
  start,
  stripe,
  modelEDashboard,
  modelEStart,
  modelEKeyDecisionMaker,
  modelEDealerPersonnel,
  modelEStripe,
  modelEHelp,
  modelEResourceCenter,
  modelEReports,
  general: {
    accept: 'Accept',
    cancel: 'Cancel',
    confirm: 'Confirm',
    confirmed: 'Confirmed',
    continue: 'Continue',
    comingSoon: 'Coming Soon',
    download: 'Download',
    editsNotSaved: 'Edits not saved',
    email: 'Email',
    goBack: 'Go Back',
    name: 'Name',
    no: 'No',
    none: 'None',
    phone: 'Phone',
    preferredLanguage: 'Preferred language',
    search: 'Search',
    select: 'Select',
    submit: 'Submit',
    view: 'View',
    yes: 'Yes',
    save: 'Save',
    status: {
      notStarted: 'Not Started',
      complete: 'Complete',
      completed: 'Completed',
      incomplete: 'Incomplete',
      active: 'Active',
      inactive: 'Inactive',
      pending: 'Pending',
      inProgress: 'In Progress',
      onHold: 'On Hold',
      unknown: 'Unknown',
      optOut: 'Opt-Out',
    },
    skipContent: 'Skip to main content',
    scrollToTop: 'To top',
  },
  validations: {
    required: 'Required',
    email: 'Email Address is not valid',
    minLength: 'This field must have at least {min} characters',
    phoneNumber: 'Phone Number is not valid',
    extension: 'Extension is not valid',
  },
  calendar: {
    weekDays: 'S, M, T, W, T, F, S',
  },
  notFound: {
    text: 'Sorry, the requested url cannot be found',
    button: 'Take me Home',
  },
  brand: {
    text: 'Please choose a franchise to continue',
    failedToLoad: 'Failed to load user profile. Please refresh the page and try again.',
  },
  videoCard: {
    link1: 'Transcript',
  },
  termsAndConditions: {
    title: 'Terms & Conditions',
    linkTitle: 'Download the Terms & Conditions',
    acceptedOn: 'Accepted on ',
    text1: 'Download the @:footer.tc',
    text2: 'I Agree to the @:footer.tc',
    text3: 'Please accept the @:footer.tc',
  },
  buttons: {
    backToTop: 'Back to Top',
    loading: 'Loading...',
  },
  textArea: {
    charText: 'Characters remaining: ',
  },
  resourceResults: {
    noResultText: 'No Results Found',
  },
  searchResultPdf: {
    readTime: 'Min Read Time',
    filterText1: 'Departments:',
    filterText2: 'Roles:',
    filterText3: 'Topics:',
    link1: 'View/Download',
    link2: 'Share via email',
    relatedLinks: 'Related Links',
    publishText: 'Published',
  },
  searchResultVideo: {
    playTime: '{time} Min Play Time',
    filterText1: 'Departments:',
    filterText2: 'Roles:',
    filterText3: 'Topics:',
    transcriptLink: 'Transcript',
    publishText: 'Published',
  },
  resourcesSearchBar: {
    label: 'Search by keyword',
    submitError: 'You must have at least three characters before submitting',
    filterText1: 'Filter Content by Department',
    filterText2: 'Filter Content by Role',
    filterText3: 'Filter Content by Topic',
    printPage: 'Print This Page',
    keywordSearch: 'Click here for keyword search',
  },
  multiSelectDropdown: {
    label1: '-- Select --',
    label2: '{count} selected',
    label3: 'Select All',
  },
  readinessPlan: {
    title: 'Ecommerce Readiness Plan',
    subtitle: 'New Vehicle Sales',
    tabOne: 'Manager\'s Meeting',
    tabTwo: 'Department Team Meetings',
    tabThree: 'Readiness Check',
  },
  readinessPrep: {
    versionOne: {
      itemOne: {
        title: 'Before scheduling Manager\'s Meetings, visit the eCommerce Dealer Portal to review Dealer\'s:',
        list: {
          itemOne: 'Activation statuses',
          itemTwo: 'Department Lead assignments',
          itemThree: 'Customer Handling Team assignments',
        },
      },
      itemTwo: {
        title: 'Review the resources to be used when hosting Manager\'s Meetings:',
        list: {
          itemOne: {
            title: '<b>Manager\'s Meeting Template</b> with valuable best practices and tools to help save you time and run a successful meeting:',
            listItemOne: 'Webex invitation to copy and paste when setting meetings',
            listItemTwo: 'Dealership roles to invite, and how to find each dealership\'s assigned contacts for each role',
            listItemThree: 'Step-by-step guidance to run your meetings, navigating between training presentation and the eCommerce Dealer Portal',
            listItemFour: 'Attachments to include in the meeting notice to help your dealers prepare',
          },
          itemTwo: '<b>eCommerce Dealer Readiness Training</b> presentation',
          itemThree: 'Supporting documents',
          itemFour: 'Follow up items and next steps',
        },
      },
      itemThree: {
        title: 'Schedule and host Manager\'s Meetings with each of your Dealers:',
        list: {
          itemOne: 'Present the<b>eCommerce Dealer Readiness Training</b> presentation',
          itemTwo: 'Confirm dealership has completed setup for the eCommerce experiences',
          itemThree: 'Use the <b>eCommerce Playbook</b> and <b>eCommerce Readiness Checklist</b> to activate/confirm dealership\'s eCommerce Team',
          itemFour: 'Use the <b>eCommerce Department Team Worksheet</b> to review key roles and actions to discuss and define dealership Customer handling, coordination and CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
          itemFive: 'Review the eCommerce resources with Dealers, show them where to locate them on the portal, and recommend they print and distribute the <b>eCommerce Department Team Worksheet</b> and <b>New Vehicle Purchase Request Checklist</b> to all team members during their department meetings',
          itemSix: 'Support the Dealer in scheduling their own Department Team Meetings, which will follow your session, informing teams, and delivering strategies',
        },
      },
      itemFour: 'Follow up with your Dealers post-session to encourage them to complete their <b>Readiness Check</b>',
      itemFive: {
        title: 'Complete an <b>eCommerce Readiness Visit Report</b> for each Dealer, marking progress for each step of the Readiness Plan as completed <i>(do not mark \'Final\' until all actions are complete. The report will allow you to \'save\' your work and return to update until marked \'final\')</i>',
        list: {
          itemOne: 'Manager\'s Meeting completion',
          itemTwo: 'Department Team Meeting completion',
          itemThree: 'Strategy Implementation',
        },
      },
    },
    versionTwo: {
      title: 'Schedule a Manager\'s Meeting to Confirm Program Setup and Define In-Dealership Strategies:',
      itemOne: 'Review/Confirm your dealership has completed setup for the eCommerce experiences',
      itemTwo: {
        title: 'Download the <strong>eCommerce Playbook</strong> and <b>eCommerce Readiness Checklist</b> to:',
        list: {
          itemOne: {
            pieceOne: 'Confirm/',
            pieceTwo: 'activate your eCommerce Team',
          },
          itemTwo: 'Define your coordination strategy',
          itemThree: 'Define your Customer Handling and CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
        },
      },
      itemThree: 'Plan and schedule department team meetings to inform teams and deliver strategies',
      itemFour: {
        title: 'Download and print enough copies of the below documents to distribute to each team member during the Department Team Meetings (tab 2):',
        list: {
          itemOne: '<b>eCommerce Department Team Meeting Worksheet</b>',
          itemTwo: '<b>eCommerce New Vehicle Purchase Request Customer Handling Checklist (NVPR)</b>',
        },
      },
    },
  },
  readinessMeeting: {
    title: 'Host eCommerce Department Team Meetings:',
    itemOne: {
      title: 'Inform teams',
      list: {
        itemOne: 'Use the <b>eCommerce Playbook</b> to provide an eCommerce program overview',
        itemTwo: 'Deliver team assignments',
        itemThree: 'Deliver key strategies',
      },
    },
    itemTwo: {
      title: 'Distribute and complete the <b>eCommerce Department Team Meeting Worksheet</b>',
      list: {
        itemOne: 'Review key actions and resources for each role',
        itemTwo: 'Identify with whom in-dealership coordination is needed',
        itemThree: 'Discuss the coordination strategy and align on the implementation plan',
        itemFour: 'Identify where to find customer information in EMP to support each action',
      },
    },
    itemThree: 'Distribute the <b>eCommerce NVPR Client Handling Checklist</b> to be used as an easy reference to guide Customer Handling Team as they work to honor Customer requests',
    itemFour: 'Review dealership CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
    itemFive: 'Establish a process where the identified Single Point of Contact is the only individual responding to eCommerce leads',
    itemSix: 'Once all Department Team meetings are complete, alert Management that the <b>eCommerce Readiness Plan</b> can be marked as final for eCommerce reporting <i>(this is how we will track your dealership’s participation)</i>',
  },
  modalFrame: {
    shareByEmail: 'Share via email',
    buttonBack: 'Go Back',
    buttonAcknowledge: 'I Acknowledge',
    emailSubject: '{firstName} {lastName} is sharing eCommerce content with you',
    emailBody: {
      line1: 'Hi,',
      line2: 'I thought you might like to review the piece of eCommerce content I found on the eCommerce Dealer Portal (click link provided below)',
    },
  },
  nonKeyDecisionMakerDisclaimer: {
    title: 'Authorization notification',
    message: 'You are not assigned as an active Key Decision Maker with the ability to apply these settings for your dealership. Please contact PHQ by visiting the help page on this portal to report any contact name and WSLX ID (FMCDealer login) mismatches that may have been created during initial enrolment. Active Key Decision Makers for your dealership:',
    message2: 'You are not assigned as an active Key Decision Maker with the ability to apply these settings for your dealership. Please contact PHQ by visiting the help page on this portal to report any contact name and WSLX ID (InFord Login) mismatches that may have been created during initial enrolment. Active Key Decision Makers for your dealership:',
  },
  toasts: {
    feedback: {
      errorTextToDisplay: 'Data Communication Error. Please check your network connection.',
      successTextToDisplay: 'Data successfully saved.',
    },
  },
  browserIsNotCompatible: {
    title: 'Your browser is out of date',
    paragraphOne: 'The eCommerce dealer portal has detected you are using an out of date or non-compatible browser which will prevent you from accessing certain features and/or websites. To ensure you have a seamless experience, it is recommended you use the latest version of Google Chrome or Microsoft Edge',
  },
  theStripe: {
    text1: 'Stripe <sup>TM</sup> Account & Fee Setup',
    p1: 'Complete setup to enable Guests to submit deposits to your dealership from Ford.ca',
    text3: 'Stripe Account & Fee Setup',
  },
  dashboardStripeAccountSetup: {
    p1: 'The Model e eCommerce experiences introduce the ability for Customers to submit electric vehicle, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Dealers. To accept eCommerce Customer-submitted funds, dealerships must set up a Stripe account. To begin the Stripe account application process, click on the ‘Stripe Account Setup’ button.',
    p2: 'The Model e eCommerce experiences introduce the ability for Customers to submit electric vehicle, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Dealers. To accept eCommerce Customer-submitted funds, dealerships must set up a Stripe account.',
    btn1: 'Stripe Account Setup',
    btn2: 'Stripe Account & Fees',
    text1: 'Stripe',
    text2: 'Stripe Account IDs for Dual Dealers',
    p3: 'Our records indicate you are setting up Stripe for a dual dealership, which provides you the choice to set up and receive one or two Stripe Account IDs. Click your choice below.',
    text3: 'Set Up One Stripe Account ID: ',
    text4: 'Ford and Lincoln credit transactions will be posted under one Stripe Account ID number with distinct franchise indicators.',
    text5: 'Set Up Two Stripe Account IDs:',
    text6: 'Ford and Lincoln credit transactions will be posted to two unique Stripe Account IDs with separate reporting for each ID number.',
    btn3: 'Set Up One ID',
    btn4: 'Set Up Two IDs',
    btn5: 'Confirm',
    btn6: 'Cancel',
    p6: 'Your dealership’s deposit and fee settings have carried over from your previous selections, and any changes you make will be applied to both payment processors. You can return to update your selections at any time.',
    text9: 'Deposit and Fee Settings',
    p4: 'Our records indicate you are not a Key Decision Maker for your dealership. The following people are Key Decision Makers:',
  },
  stripeAccountFeeSetup: {
    text1: 'STRIPE ACCOUNT SETUP',
    p1: 'To get started on your Stripe account application, click on the \'Stripe Application\' button.',
    p2: 'Once your dealership\'s account is approved, the button will update to \'Access Stripe,\' which will take you to your Stripe dashboard.',
    text2: 'Stripe Account Status',
    text3: 'Stripe Account Status\' may indicate: Not Started, Pending, On Hold, or Active',
    text4: 'Last checked:',
    text5: 'Confirm Stripe Account ID and Fees',
    text6: '\'Confirm Stripe Account ID and Fees\' status may indicate: Incomplete or Complete',
    p3: 'Once you have completed your Stripe account application, a pending status will appear, and it should take approximately 10 minutes until your account is active. The \'Check Status\' button can be clicked to check progress and the details on the last status check will be displayed to its right.',
    p9: 'Once you have completed your Stripe account application, a pending status will appear, and it should take approximately 10 minutes until your account is active.',
    text7: '*Scroll down to review/complete',
    p10: 'Your Stripe account status is unknown, as the status below indicates. It should take approximately 10 minutes until your account status is active. Click Check Status for an update, the details of the last status check are displayed to the right of the button.',
    p4: 'Once your Stripe account is approved, you\'ll return to this page and confirm your Stripe Account ID below.',
    btn1: 'Stripe Application',
    btn2: 'Access Stripe',
    btn3: 'Setup Tips',
    text8: 'The ‘Order Deposit’ is the amount your dealership will ask Customers to deposit for an Order Request. Select a value from the dropdown click ‘Confirm’ and ‘Save.’',
    text9: 'The ‘Max Amount Accepted on Credit Card’ is the maximum amount that your dealership can accept on a credit card for a Ford Credit finance Customer. Select a value from the dropdown click ‘Confirm’ and ‘Save.’',
    text10: 'The ‘Max Amount Accepted with ACH’ is the maximum amount that your dealership can accept through the Automated Clearing House (ACH), which will use an electronic funds transfer (EFT) from the Customer’s financial institution. Select a value from the dropdown click ‘Confirm’ and ‘Save.’',
    text11: 'The ‘All Payment Types Checkout Deposit’ is the amount your dealership will ask Customers to deposit for the purchase of their vehicle. Select a value from the dropdown click ‘Confirm’ and ‘Save.’',
    p5: 'Your Stripe account is pending, as the status below indicates.',
    p6: 'Your Stripe account is active, as the status below indicates.',
    p7: 'To access your Stripe dashboard, click on the \'Access Stripe\' button.',
    p8: 'Please confirm your Stripe Account ID below. Please reference the email sent from Ford eCommerce with the subject line (include the subject line of the email in the subject line variable) to locate your Stripe Account ID. You will need this to confirm your ID below:',
    dlgtext1: 'Stripe',
    dlgtext2: 'Account On Hold',
    dlgtext3: 'Our records indicate your Stripe account is currently on hold. Please contact Stripe by using the form at this link:',
    dlgtext4: 'Stripe Setup Tips',
    dlgtext5: 'Setup & Maintenance Tips for Your Stripe Account',
    dlgtext6: 'Before beginning your Stripe Application, download the Stripe Account Setup checklist for easy reference',
    dlgtext7: 'Make sure you\'ve gathered the necessary financial documents before beginning the application process',
    dlgtext8: 'When you receive an email with your Stripe Account ID, be sure to print it or favorite it to locate again when needed',
    dlgtext9: 'Ensure your dealership is prepared to receive eCommerce funds by removing the debit blocker with your financial institution. Request removal of debit blocker by adding Stripe company IDs (1800948598 and 4270465600)',
    dlgtext10: 'If you\'re a dual Dealer and chose one account, but later would like to inquire about separate account IDs, contact eCommerce program HQ at 1-800-647-7268 or helpdesk&commat;eCommDealerSetup.com',
  },
  stripeAccountIdFees: {
    text1: 'STRIPE ACCOUNT ID and FEES',
    p1: 'Once your Stripe account is active, your Stripe Account ID will populate below. When it appears, you’ll cross reference the Stripe Account ID sent via email, check ‘Confirm’ and click ‘Save.’',
    p2: 'Click the accordion to set parameters for funds accepted by your dealership in eCommerce. The \'Reservation Deposit\' is set by Ford and can\'t be edited. The \'Order Deposit,\' \'Max Amount Accepted on Credit Card,\' \'Max Amount Accepted with ACH,\' and \'All Payment Types Checkout Deposit\' have dropdown menus with pre-populated amounts.',
    modelE: 'Click the accordion to set parameters for funds accepted by your dealership in eCommerce. The ‘Advanced Access Deposit’ is set by Ford and can\'t be edited. The \'Order Deposit,\' \'Max Amount Accepted on Credit Card,\' \'Max Amount Accepted with ACH,\' and \'All Payment Types Checkout Deposit\' have dropdown menus with pre-populated amounts.',
    p3: 'Your dealership\'s Stripe Account ID is displayed below. To confirm it matches the Stripe Account ID provided in the confirmation email, check \'Confirm\' and click \'Save\'.',
    text2: 'Primary Contact:',
    text3: 'Stripe account ID',
    text4: 'Confirm',
    text5: 'Save',
    text6: 'Confirmed',
    text7: 'Questions about your Stripe account ID?',
    text8: 'Deposit',
    text9: 'This value was carried over from a previous selection, you may change it at any time.',
    modelEReservationTooltip: 'The ‘Advanced Access Deposit’ is the amount your dealership will ask Guests to deposit for Advanced Access. This amount has been set by Ford and can’t be edited.',
  },
  theFaqs: {
    title: 'FAQs',
    label1: 'Type your question here',
    btn1: 'SUBMIT',
    p1: 'Additional Information:',
    label2: 'Helpful?',
    label3: 'No Results Found',
    label4: '@:resourceHeader.help',
  },
  resourceDepartment: {
    title: 'Select by Department',
  },
  experienceCard: {
    newContent: 'New Content >',
    btnText: 'Select',
  },
  resourceJourney: {
    title: 'Customer Journey',
    p1: 'New to eCommerce? No problem! Click through for the Customer and Dealer eCommerce journey to learn more about the new Customer submissions that may be coming your way!',
    p2: 'Every New Vehicle eCommerce submission will be unique, much like every Customer. This journey provides a look at how the Customer and Dealer take turns online, driving the deal forward, and the new tools that will help your dealership deliver a seamless eCommerce Customer experience.',
    btn1: 'Start the Journey',
  },
  resourceEmp: {
    title: 'eCommerce Marketplace Portal (EMP) Demo',
    subtitle: 'Navigating The eCommerce Command Center',
    p1: 'EMP is the command center for monitoring and managing eCommerce Customers—it is where your dealership can act on Customer-submitted Reservations, Orders, and New Vehicle Purchase Requests (NVPRs).',
    p2: 'This EMP Demo provides a user-friendly walkthrough, from start to finish, to give you an understanding of the layout and accordions, and locations to find Customer-submissions (deposits and trade-in vehicles) and Customer-requests (protection plans and accessories).',
    btn1: 'Get Started',
  },
  resourceOperationalize: {
    title: 'Operationalize eCommerce in Your Dealership',
    subtitle: 'Manager\'s Toolkit',
    p1: 'Three simple activities will help you operationalize eCommerce your way — within your current process, with your team, to help reach your Customer Handling goals.',
    text1: 'Manager\'s Meeting',
    text2: 'Department Team Meetings',
    text3: 'Strategy Implementation',
    btn1: 'Get Started',
  },
};
