<template>
  <div class="mobile-app-controls">
    <TheHeaderBrandSelector float-label />
    <TheHeaderExperienceSelector float-label />
    <TheHeaderLanguageSelector float-label />
  </div>
</template>

<script>
import TheHeaderBrandSelector from './TheHeaderBrandSelector.vue';
import TheHeaderLanguageSelector from './TheHeaderLanguageSelector.vue';
import TheHeaderExperienceSelector from './TheHeaderExperienceSelector.vue';

export default {
  name: 'TheMobileAppControls',
  components: {
    TheHeaderBrandSelector,
    TheHeaderLanguageSelector,
    TheHeaderExperienceSelector,
  },
};
</script>

<style lang="scss" scoped>
.mobile-app-controls {
  display: grid;
  justify-content: center;
  gap: 24px;
  margin: 24px 0;
  @include respond(sm-up) {
    grid-template-columns: repeat(3, auto);
    gap: 146px;
  }
  @media (min-width: $breakpoint__the-header-desktop) {
    display: none;
  }
}
</style>
