import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  getters: {
    resources(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['modelE/dashboard/resources'],
        resourceCenterId.section.MODEL_E_STRIPE_ACCOUNT,
        resourceCenterId.subsection.MODEL_E_STRIPE_ACCOUNT,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
};
