<template>
  <div
    v-if="aplicationStatus.active"
    class="stripe-account-id"
  >
    <form
      class="stripe-account-id__form"
      @submit.prevent="confirmAccountId"
    >
      <div class="stripe-account-id__field-wrapper">
        <div
          class="stripe-account-id__field-label"
          :class="$store.getters['user/activeBrandName']"
        >
          <span
            v-if="!activeBrandIsLincoln"
            v-text="$l('stripe.feeSetup.card.accountId.input')"
            class="stripe-account-id__field-label-text"
          />
          <AppTooltip :text="$l('stripe.feeSetup.card.accountId.tooltip')" />
        </div>
        <TextField
          v-model="activeDealership.stripeAccountId"
          id="stripe-field-account-id"
          class="stripe-account-id__field"
          disabled
          :label="activeBrandIsLincoln ? $l('stripe.feeSetup.card.accountId.input') : ''"
        />
      </div>
      <span
        v-if="userConfirmedAccountId"
        v-text="$l('general.confirmed')"
        class="stripe-account-id__confirmed-message"
      />
      <template v-if="!userConfirmedAccountId && userIsKeyDecisionMaker">
        <AppCheckbox
          v-model="userConfirmAccountId"
          id="stripe-account-id__checkbox"
          :label="$l('general.confirm')"
        />
        <StandardButton
          type="submit"
          track-event-name="submitForm_stripeAccountId"
          track-section-name="stripeAccountId"
          :is-loading="isLoading"
          :disabled="!userConfirmAccountId"
          :text="$l('general.save')"
        />
      </template>
    </form>
    <TextButton
      to="/Help?feedback=2&topic=8"
      :text="$l('stripe.feeSetup.card.accountId.questions')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppCheckbox from './common/AppCheckbox.vue';
import StandardButton from './common/StandardButton.vue';
import TextButton from './common/TextButton.vue';
import TextField from './common/TextField.vue';
import AppTooltip from './common/AppTooltip.vue';

export default {
  name: 'StripeFeeSetupCardAccountId',
  components: {
    AppCheckbox,
    StandardButton,
    TextButton,
    TextField,
    AppTooltip,
  },
  data() {
    return {
      userConfirmAccountId: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      activeBrandIsLincoln: 'user/activeBrandIsLincoln',
      activeDealership: 'user/activeDealership',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      records: 'stripe/records',
      aplicationStatus: 'stripe/aplicationStatus',
    }),
    userConfirmedAccountId() {
      return this.records.reservation.merchantIdConfirmed;
    },
  },
  watch: {
    activeDealership() {
      this.userConfirmAccountId = false;
    },
  },
  methods: {
    async confirmAccountId() {
      if (!this.userConfirmAccountId) return;

      this.isLoading = true;

      try {
        await this.$store.dispatch('stripe/confirmAccountId');

        this.$store.dispatch('topBanner/open', {
          message: this.$l('stripe.feeSetup.card.accountId.success'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('stripe.feeSetup.card.accountId.error'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-account-id {
  margin-bottom: 60px;
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
    @include respond(sm-up) {
      flex-direction: row;
      justify-content: unset;
      align-items: center;
    }
  }
  &__field-wrapper {
    position: relative;
  }
  &__field {
    @include respond(sm-dn) {
      --input-min-width: unset;
    }
  }
  &__field-label {
    &.lincoln {
      justify-content: flex-end;
      width: 100%;
    }
    display: flex;
    gap: 8px;
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    z-index: 1;
  }
  &__confirmed-message {
    color: $color--fds-success2;
  }
}
</style>
