import ApiManager from '@/api';
import CookieManager from '@/helpers/cookieManager';

export default class sessionCookie {
  static get() {
    return CookieManager.get(process.env.VUE_APP_COOKIE_NAME) || {};
  }

  static set(cookie) {
    CookieManager.set(process.env.VUE_APP_COOKIE_NAME, cookie, cookie.expires);

    ApiManager.setSessionHeader(cookie.sessionId);
    ApiManager.setTokenHeader(cookie.token);
  }

  static clear() {
    CookieManager.delete(process.env.VUE_APP_COOKIE_NAME);

    ApiManager.setSessionHeader();
    ApiManager.setTokenHeader();
  }
}
