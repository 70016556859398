export default {
  RETURN_TO_ORDER_DEA: 0,
  NEW_ORDER_DEA: 0,
  FINALIZE_THE_PURCHASE_CUS: 0,
  ACCESSORY_PRICING: 1,
  ACTIVATION_TOOLS: 2,
  ARS_ENROLLMENT_SECTION: 3,
  CART_AND_CHECKOUT: 4,
  CLIENT_ACTIVITY: 5,
  CLIENT_HANDLING_TOOLS: 6,
  CUSTOMER_ACTIVITY: 7,
  CUSTOMER_HANDLING_TOOLS: 8,
  DEALER_INFORMATION_TEAM_SETUP: 9,
  ECOMMERCE_CART_AND_CHECKOUT: 11,
  ECOMMERCE_CART_AND_CHECKOUT_JOB_AID: 12,
  EDD_REPORTING_SECTION1: 13,
  FEE_SALES_MERCHANT_SETUP: 17,
  FORDPAY_SETUP: 20,
  LINCOLN_PAY_SETUP: 21,
  NEW_VEHICLE_PURCHASE_REQUESTS: 22,
  ORDER: 23,
  ORDER_JOB_AID: 24,
  PROGRAM_OVERVIEW_CONTENT: 25,
  RESERVATION: 26,
  RESERVATIONS_JOB_AID: 27,
  ROUTEONE_SETUP: 28,
  SALES_MID_SETUP: 29,
  SALES_MID_SETUP_DETAILS: 30,
  VEHICLE_PRICING: 35,
  DEALER_TAB: 36,
  FIELD_TAB: 37,
  ARS_ENROLLMENT_SECTION2: 38,
  MAXIMTRAK_SETUP: 39,
  SELECT_AND_CONFIGURE_VEHICLE_CUS: 40,
  SUBMIT_RESERVATION_WITH_DEPOSIT_CUS: 41,
  TRACK_AND_MANAGE_RESERVATION_CUS: 42,
  RETURN_TO_ORDER_CUS: 43,
  NEW_ORDER_CUS: 44,
  CONFIGURE_VEHICLE_CUS: 45,
  SUBMIT_ORDER_REQUEST_WITH_DEPOSIT_CUS: 46,
  TRACK_AND_MANAGE_ORDER_CUS: 47,
  RETURN_TO_PURCHASE_CUS: 48,
  NEW_VEHICLE_PURCHASE_REQUEST_CUS: 49,
  PERSONALIZE_VEHICLE_CUS: 50,
  SELECT_PAYMENT_METHOD_CUS: 51,
  REQUEST_TEST_DRIVE_SUBMIT_PREFERRED_METHOD_OF_COMMUNICATION_CUS: 52,
  VIEW_OFFERS_INCENTIVES_CUS: 53,
  EVALUATE_TRADE_IN_VEHICLE_CUS: 54,
  REQUEST_CHARGING_CUS: 55,
  SELECT_ACCESSORIES_CUS: 56,
  SELECT_PROTECTION_PLANS_CUS: 57,
  VIEW_TAXES_FEES_AND_MONTHLY_PAYMENT_CUS: 58,
  SUBMIT_AND_CONTINUE_AT_DEALER_CUS: 59,
  SUBMIT_AND_CONTINUE_AT_STORE_CUS: 59,
  FINALIZE_THE_PURCHASE_DEA: 60,
  COMPLETES_CART_AND_BEGINS_CHECKOUT_CUS: 61,
  COMPLETES_CART_AND_BEGINS_CUS: 61,
  FINANCE_WITH_FORD_CREDIT_CUS: 62,
  FINANCE_WITH_LINCOLN_FINANCIAL_CUS: 62,
  SELECT_METHOD_OF_SIGNING_PAPERWORK_CUS: 63,
  REQUEST_REMOTE_DELIVERY_CUS: 64,
  SUBMIT_A_DEPOSIT_DOWN_PAYMENT_CUS: 65,
  SUBMIT_TO_DEALER_CUS: 66,
  SUBMIT_TO_STORE_CUS: 66,
  SELECT_AND_CONFIGURE_VEHICLE_DEA: 67,
  SUBMIT_RESERVATION_WITH_DEPOSIT_DEA: 68,
  TRACK_AND_MANAGE_RESERVATION_DEA: 69,
  CONFIGURE_VEHICLE_DEA: 70,
  SUBMIT_ORDER_REQUEST_WITH_DEPOSIT_DEA: 71,
  TRACK_AND_MANAGE_ORDER_DEA: 72,
  RETURN_TO_PURCHASE_DEA: 73,
  NEW_VEHICLE_PURCHASE_REQUEST_DEA: 74,
  PERSONALIZE_VEHICLE_DEA: 75,
  SELECT_PAYMENT_METHOD_DEA: 76,
  REQUEST_TEST_DRIVE_SUBMIT_PREFERRED_METHOD_OF_COMMUNICATION_DEA: 77,
  VIEW_OFFERS_INCENTIVES_DEA: 78,
  EVALUATE_TRADE_IN_VEHICLE_DEA: 79,
  REQUEST_CHARGING_DEA: 80,
  SELECT_ACCESSORIES_DEA: 81,
  SELECT_PROTECTION_PLANS_DEA: 82,
  VIEW_TAXES_FEES_AND_MONTHLY_PAYMENT_DEA: 83,
  SUBMIT_AND_CONTINUE_AT_DEALER_DEA: 84,
  SUBMIT_AND_CONTINUE_AT_STORE_DEA: 84,
  COMPLETES_CART_AND_BEGINS_CHECKOUT_DEA: 85,
  COMPLETES_CART_AND_BEGINS_DEA: 85,
  FINANCE_WITH_FORD_CREDIT_DEA: 86,
  FINANCE_WITH_LINCOLN_FINANCIAL_DEA: 86,
  SELECT_METHOD_OF_SIGNING_PAPERWORK_DEA: 87,
  REQUEST_REMOTE_DELIVERY_DEA: 88,
  SUBMIT_A_DEPOSIT_DOWN_PAYMENT_DEA: 89,
  SUBMIT_TO_DEALER_DEA: 90,
  SUBMIT_TO_STORE_DEA: 90,
  WELCOME: 91,
  EDD_REPORTING_SECTION2: 92,
  STRIPE_PAGE_ACCOUNT_SETUP: 113,
  STRIPE_ACCOUNT_FEE_SETUP: 114,
  FI_TAXES_AND_FEES: 115,
  FI_DOCUMENT_FEES: 116,
  FI_PRODUCT: 117,
  FI_E_SIGN: 118,
  MODEL_E_STRIPE_ACCOUNT: 130,
  MODEL_E_ACCESSORY_PRICING: 127,
  MODEL_E_DEALER_INFORMATION_TEAM_SETUP: 133,
  MODEL_E_TABLETS: 142,
  MODEL_E_TABLETS_URL: 143,
  MODEL_E_ARS_ENROLLMENT_SECTION: 148,
  MODEL_E_ARS_ENROLLMENT_SECTION2: 149,
  MODEL_E_CUSTOMER_HANDLING: 128,
  MODEL_E_CUSTOMER_ACTIVITY: 129,
  MODEL_E_VEHICLE_PRICING: 135,
};
