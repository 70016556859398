<template>
  <label
    class="radio-button"
    :class="[$store.getters['user/activeBrandName'], $attrs.class]"
    :for="id"
  >
    <input
      v-bind="$attrs"
      v-model="inputValue"
      :id="id"
      ref="radioButton"
      class="radio-button__input"
      type="radio"
      :value="radioValue"
      @keypress.enter="$refs.radioButton.click()"
    />
    <div class="radio-button__control" />
    <div class="radio-button__content">
      <slot>
        <span>{{ label }}</span>
      </slot>
    </div>
  </label>
</template>

<script>
export default {
  name: 'RadioButton',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    radioValue: {},
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  --size: 16px;
  --spacing: 16px;
  --text-color: #{$color--fds-primary};
  --border-width: 2px;
  --border-color: #{$color--fds-gray2};
  --background-color: #{$color--fds-primary};
  --outline-color: #{$color--fds-primary};
  --checked-border-color: #{$color--fds-primary};
  &.lincoln {
    --size: 20px;
    --spacing: 8px;
    --border-width: 1px;
    --text-color: #{$color--lds-primary};
    --border-color: #{$color--lds-primary};
    --background-color: #{$color--lds-secondary};
    --outline-color: #{$color--lds-primary};
    --checked-border-color: #{$color--lds-secondary};
  }

  display: grid;
  grid-template-columns: var(--size) 1fr;
  align-items: center;
  gap: var(--spacing);
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  &__input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    &:checked + .radio-button__control {
      border-color: var(--checked-border-color);
      border-width: 2px;
      &::before {
        display: block;
      }
    }
    &:focus-visible + .radio-button__control::after {
      display: block;
    }
  }
  &__control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    border: var(--border-width) solid var(--border-color);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    &::before {
      content: '';
      display: none;
      width: calc(var(--size) / 2);
      height: calc(var(--size) / 2);
      background-color: var(--background-color);
      border-radius: 50%;
      position: absolute;
    }
    &::after {
      content: '';
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      outline: 1px solid var(--outline-color);
      outline-offset: 8px;
    }
  }
  &__content {
    color: var(--text-color);
    font-weight: $font-weight--normal;
  }
}
</style>
