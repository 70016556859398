<template>
  <div class="experience-card">
    <h3 class="experience-card__title fds-subtitle__1">
      {{ title }}
    </h3>
    <div class="experience-card__content">
      <div class="experience-card__top">
        <slot name="top" />
        <router-link
          v-if="experienceHasNewContent"
          class="experience-card__new-content"
          :to="{
            name: 'ResourceResults',
            query: { experience: experienceId, new: true },
          }"
        >
          <span
            class="experience-card__new-content-icon app-icon-new-content"
          />
          <span>{{ $l("experienceCard.newContent") }}</span>
        </router-link>
        <div
          v-if="$slots.topFooter"
          class="experience-card__top-footer"
        >
          <slot name="topFooter" />
        </div>
      </div>
      <div
        v-if="$slots.bottom || $slots.bottomFooter"
        class="experience-card__bottom"
      >
        <h4
          v-if="bottomTitle"
          v-html="bottomTitle"
          class="experience-card__bottom-title fds-subtitle__1"
        />
        <slot name="bottom" />
        <div
          v-if="$slots.bottomFooter"
          class="experience-card__bottom-footer"
        >
          <slot name="bottomFooter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';

export default {
  name: 'ModelEResourceCenterExperienceCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    bottomTitle: {
      type: String,
      default: '',
    },
    experienceId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      newContent: [],
    };
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
    }),
    experienceHasNewContent() {
      return this.newContent.filter((item) => {
        const brand = this.activeBrandIsFord ? item.ford_Division : item.lincoln_Division;

        return item.experienceID === this.experienceId && item.hasNewContent && brand;
      }).length > 0;
    },
  },
  mounted() {
    if (this.experienceId !== -1) {
      this.getModelENewContentExperience();
    }
  },
  methods: {
    async getModelENewContentExperience() {
      const { data } = await apiManager.resourceCenter.getNewContentExperience();

      this.newContent = data.newExperience;
    },
  },
};
</script>

<style lang="scss" scoped>
.experience-card {
  --new-content-color: #{$color--fds-secondary};
  width: 100%;
  height: fit-content;
  background-color: $color--white;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba($color--black, 0.4);
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 15px 0 rgba($color--black, 0.6);
  }
  @include respond(sm-up) {
    width: 350px;
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 92px;
    margin: 0;
    color: #{$color--fds-primary};
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &__content {
    padding: 24px 32px;
  }
  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
  }
  &__bottom {
    min-height: 250px;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 2px solid #ddd;
  }
  &__top-footer,
  &__bottom-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: auto;
  }
  &__bottom-title {
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  &__new-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-top: 16px;
    color: var(--new-content-color);
  }
  &__new-content-icon {
    font-size: 20px;
  }
}
</style>
