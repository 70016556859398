export default {
  deactivate: {
    date: 'Your Store will be deactivated on:',
    warning: 'Your Store will be deactivated on:<br>{date}<br>Click on ‘Active’ to remain in the experience',
  },
  optOutPermission: {
    description: 'You have do not have permissions to opt out of eCommerce experiences. The following people have permissions to opt-out in your store:',
  },
  cancelOptOutPermission: {
    description: 'You do not have permissions to cancel the opt out of an eCommerce experience. The following people have permissions in your store:',
  },
  reactivatePermission: {
    description: 'You do not have permissions to re-activate an eCommerce experience. The following people have permissions in your store:',
  },
  reservation: {
    cancelOptOut: {
      description: 'You have succesfully canceled the opt out process for the reservation and order eCommerce experiences for your Store.',
    },
    reactivate: {
      description: 'You have succesfully re-activated the reservation eCommerce experience for your store.',
    },
  },
  order: {
    cancelOptOut: {
      description: 'You have succesfully canceled the opt out process for the order eCommerce experience for your store.',
    },
    reactivate: {
      description: 'You have initiated re-activation of the order eCommerce experience for your Store. Please review all activation requirements for completion; reactivation may take up to 24 hours.',
    },
  },
  cartCheckout: {
    item7: 'Client Handling <i style=\'font-size: 13px;\'>(Coming Soon)</i>',
    cancelOptOut: {
      description: 'You have succesfully canceled the opt out process for the cart and checkout eCommerce experience for your store.',
    },
    reactivate: {
      description: 'You have initiated re-activation of the cart and checkout eCommerce experience for your Store. Please review all activation requirements for completion; reactivation may take up to 24 hours.',
    },
  },
};
