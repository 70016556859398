<template>
  <div
    class="status-bullet"
    :class="statusBulletClasses"
  >
    <span class="status-bullet__icon" />
    <span>{{ statusText }}</span>
  </div>
</template>

<script>
const VALID_STATUSES = {
  notstarted: 'notstarted',
  completed: 'completed',
  active: 'active',
  inprogress: 'inprogress',
  comingsoon: 'comingsoon',
  yes: 'yes',
  no: 'no',
};

const normalizeStatus = (s) => s.toLowerCase().replaceAll(/[^a-z]/g, '');

export default {
  name: 'DealerProfileStatusBullet',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    normalizedStatus() {
      return normalizeStatus(this.status);
    },
    statusText() {
      const {
        notstarted, completed, active, inprogress, comingsoon, yes, no,
      } = VALID_STATUSES;

      switch (this.normalizedStatus) {
        case notstarted:
          return this.$l('general.status.notStarted');
        case completed:
          return this.$l('general.status.completed');
        case active:
          return this.$l('general.status.active');
        case inprogress:
          return this.$l('general.status.inProgress');
        case comingsoon:
          return this.$l('general.comingSoon');
        case yes:
          return this.$l('general.yes');
        case no:
          return this.$l('general.no');
        default:
          return '';
      }
    },
    statusBulletClasses() {
      const {
        notstarted, completed, active, inprogress, comingsoon, yes, no,
      } = VALID_STATUSES;

      return {
        'status-bullet--not-started': this.normalizedStatus === notstarted,
        'status-bullet--completed': this.normalizedStatus === completed,
        'status-bullet--active': this.normalizedStatus === active,
        'status-bullet--in-progress': this.normalizedStatus === inprogress,
        'status-bullet--coming-soon': this.normalizedStatus === comingsoon,
        'status-bullet--yes': this.normalizedStatus === yes,
        'status-bullet--no': this.normalizedStatus === no,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.status-bullet {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &--not-started,
  &--no {
    .status-bullet__icon::before {
      content: $app-icon-not-started;
    }
  }
  &--active,
  &--completed,
  &--yes {
    .status-bullet__icon::before {
      content: $app-icon-Step-Complete;
      color: $color--fds-success1;
    }
  }
  &--in-progress {
    .status-bullet__icon::before {
      content: $app-icon-in-progress;
      color: $color--fds-caution1;
    }
  }
  &--not-started {
    .status-bullet__icon::before {
      content: $app-icon-not-started;
    }
  }
  &--coming-soon {
    .status-bullet__icon::before {
      content: $app-icon-Not-Available;
    }
  }
  &__icon {
    margin-right: 8px;
    font-family: $font-family--icons;
    font-size: 18px;
    &::before {
      content: '';
      color: $color--fds-gray2;
    }
  }
}
</style>
