export default {
  description: 'Experience(s) currently available for selection.',
  blue: {
    title: 'Ford Blue',
    subtitle: 'eCommerce Experience',
    description: 'As a Ford Blue Dealer, enroling in eCommerce provides your Guests with an option to reserve, order, or buy their vehicle online, and enables your dealership to receive direct digital payments.',
  },
  modelE: {
    title: 'Ford Model e Certified',
    titleElite: 'Ford Model e Certified Elite',
    subtitle: 'eCommerce Experience',
    subtitleElite: 'Your dealership is Model e Certified',
    subtitleElite2: 'Elite',
    description: 'eCommerce provides your Guests an option to request Advanced Access, submit a request to purchase or lease their electric vehicle online with the support of a Model e Dealer, and enables you to meet one or more of the Model e Electric Vehicle Program standards. As noted in the Program standards, today’s EV Guest expects and demands a convenient, efficient, informative and transparent sales and service experience that is easy to navigate. These standards were designed and developed with a Guest-first mentality so that Guests could receive an unparalleled purchase and ownership experience.',
  },
};
