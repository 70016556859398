export default {
  title: 'Activation Requirements',
  blueTitle: 'Blue Activation Requirements',
  heading: 'Onboarding and Maintenance',
  brandText: 'toggle franchises',
  notifications: {
    title: 'Notifications ({count})',
    termsAndConditions: 'You have not accepted the latest Terms and Conditions',
    arsNotSet: 'Your dealership does not have ARS entitlements set',
    customerHandling: 'Your dealership does not have Customer Handling entitlements set',
    accessoryPricingNotSet: 'Your dealership does not have Accessory Pricing entitlements set',
    evCertified: 'Your Dealership is EV (Electric Vehicle) certified',
  },
  enrollment: {
    title: 'Enrolment (F(ii) Authority)',
    terms: {
      title: 'Terms & Conditions',
      subtitle: 'Enrolment Terms & Conditions',
      checkbox: 'I Agree to the Terms & Conditions',
      tooltip: 'Below is the English version of the Terms & Conditions, following that is the exact translated French version of the Terms & Conditions.',
      acceptTerms: {
        success: 'Terms and Conditions successfully marked as accepted.',
        error: 'Failed to mark Terms and Conditions as accepted. Please try again.',
      },
    },
    teamSetup: {
      title: 'Dealer Information & Team Setup',
    },
    submit: {
      buttonText: 'Submit Enrolment',
      errorMessage: 'Failed to submit the enrolment. Please try again.',
      successMessage: 'Congratulations, you have successfully enroled your dealership!',
    },
  },
  remoteDelivery: {
    title: 'Remote Delivery Preferences',
    description: 'Please choose whether your dealership would like to present an option for customers to indicate their interest in remote delivery in the eCommerce experience. If offered, customers can check a box to indicate their remote delivery interest which will appear in their deal submission. All remote delivery details will be handled offline between your dealership and your customer. Dealership agrees to remain compliant with federal and local guidelines.',
    label: 'Would your dealership like to present remote delivery as on option to customers in the eCommerce experience?',
    successMessage: 'Remote Delivery Preference saved successfully.',
    errorMessage: 'Failed to save Remote Delivery Preference. Please try again.',
  },
  stripe: {
    title: 'Stripe Account (Required for Direct-to-Dealer Payments)',
    sectionOne: {
      title: 'Stripe Account & Fee Setup',
      videoTitle: 'FordPay Sales MID Setup for eCommerce',
      description: 'The new Ford eCommerce experiences introduce the ability for Customers to submit Reservations, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Dealers. To accept eCommerce Customer-submitted funds, dealerships must set up a Stripe account. To begin the Stripe account application process, click on the ‘Stripe Account Setup’ button.',
      descriptionActive: 'The new Ford eCommerce experiences introduce the ability for Customers to submit Reservations, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Dealers. To view your Stripe account details, click on the ‘Stripe Account & Fees’ button.',
      link: 'Stripe Account Setup',
      linkActive: 'Stripe Account & Fees',
      modal: {
        title: 'Stripe',
        subtitle: 'Account IDs for Dual Dealers',
      },
      modalKdm: {
        paragraphOne: 'Our records indicate you are not a Key Decision Maker for your dealership. The following people are Key Decision Makers: {names}',
      },
      modalAccounts: {
        paragraphOne: 'Our records indicate you are setting up Stripe for a dual dealership, which provides you the choice to set up and receive one or two Stripe ID numbers. Click your choice below.',
        paragraphTwo: 'Set Up One ID: <br /> Ford and Lincoln credit transactions will be posted under one ID number with distinct franchise indicators.',
        paragraphThree: 'Set Up Two IDs: <br /> Ford and Lincoln credit transactions will be posted to two unique ID with separate reporting for each ID number.',
        buttonOneId: 'Set Up One ID',
        buttonTwoIds: 'Set Up Two IDs',
      },
    },
  },
  accessoryPricing: {
    title: 'Accessory Pricing',
    sectionOne: {
      title: 'Accessory Pricing',
      paragraphOne: 'The eCommerce Accessory Module provides Dealers the opportunity to set pricing structure and labor rates for accessories. Customers will then be able to select the priced accessories and add them to their new vehicle in the eCommerce experiences.',
      paragraphTwo: 'Click "Set Accessory Pricing" to access the module and complete pricing.',
      paragraphThree: 'Setting accessory pricing entitlements is a mandatory step that must be completed before entering the accessory module. Before pricing can be completed, your administrator must assign EMP_eComm_Accessories entitlement at www.dsps.dealerconnection.com. Only authorized Dealer users with authority to set pricing should be granted accessory pricing module entitlements.',
      linkOne: 'set accessory pricing',
      linkTwo: 'set entitlements',
      errorToast: 'Your dealership does not have entitlements set.',
    },
  },
  vehiclePricing: {
    title: 'Vehicle Pricing',
    sectionOne: {
      title: 'Vehicle Pricing',
      description: `
        <p>The Vehicle Locator Plus (VL Plus) eCommerce Pricing Module provides Dealers the ability to complete vehicle pricing to include Dealer-installed options (DIOs) to be presented to Customers within the eCommerce experiences. The Dealer Set Price will be the price shown for your eligible vehicles, prior to any incentives, rebates, taxes and/or fees. Click: ‘SET VEHICLE PRICING’ to access the module and complete pricing.<p/>
        <p>Please note: work done in VL Plus can take up to 24 hours to register in the Dealer Portal while systems are transferring information.</p>
      `,
      setEntitlements: 'Setting vehicle pricing entitlements is a mandatory step that must be completed before entering the vehicle pricing module. Before pricing can be completed, your administrator must assign the VL_PRICE entitlement at www.dsps.dealerconnection.com. Only authorized Dealer users with authority to set pricing should be granted vehicle pricing module entitlements.',
      linkOne: 'set vehicle pricing',
      linkTwo: 'set entitlements',
      labelOne: 'Current pricing source:',
      labelTwo: 'Pricing source validation:',
      labelThree: 'Current percentage of inventory priced:',
      complete: 'Complete',
      incomplete: 'Incomplete',
      smallOne: '**Must be greater than 70% for activation',
      errorToast: 'Your dealership does not have entitlements set.',
      interruption: 'Pricing data interruption detected, please contact VLPlus: fdvlplus{\'@\'}forddirect.com',
    },
    pricingBelowInventory: 'VIN-based pricing has fallen below 50% of total inventory. Navigate to Dashboard > Vehicle Pricing > Set Vehicle Pricing to increase your percentage.',
    pricingBelowInventoryDashboard: 'VIN-based pricing has fallen below 50% of total inventory. Navigate to Vehicle Pricing > Set Vehicle Pricing to increase your percentage.',
  },
  fiSetup: {
    title: 'F&I',
    titleComingSoon: 'F&I <i style="font-size: 13px;">(Coming Soon)</i>',
    subtitleComingSoon: 'RouteOne Setup <i style="font-size: 13px;">(Coming Soon)</i>',
    routeOne: {
      title: 'RouteOne ID',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      buttonText: 'Access RouteOne',
      statusLabel: 'Confirm RouteOne ID',
      inputLabel: 'RouteOne Dealer ID',
      paragraph1: 'Confirm this is the same RouteOne ID your dealership will use within the RouteOne website. Dual dealerships should read the examples below before confirming.',
      paragraph2: 'Settings will be based on the individual RouteOne dealer ID selected.',
      paragraph3: 'Example 1: ABC Ford/Lincoln uses one RouteOne ID of AB7CD. Their settings for ePA, tax/fee and online paperwork will apply for both the Ford and Lincoln transactions. If ABC Ford/Lincoln is presented with 2 RouteOne IDs in the drop down, select the one ID that they will use for eCommerce transactions.',
      paragraph4: 'Example 2: ABC Ford uses RouteOne ID of EF7GH and ABC Lincoln uses JK8LM. They will both need to create settings based on these RouteOne IDs.',
    },
    taxesAndFees: {
      title: 'Taxes & Fees',
      statusLabel: 'Taxes & Fees',
      description: 'The RouteOne tax & fee module has been designed to provide a quick setup by connecting to Carlton & ATC to provide zip code + 4 level taxes and to include state required fees. Please review "sample fee" when accessing RouteOne to review the work that\'s already been done to save you time before adding any additional taxes & fees you see fit.',
    },
    documentFees: {
      title: 'Document Fees',
      statusLabel: 'Document Fees',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    fiProduct: {
      title: 'F&I Product',
      statusLabel: 'F&I Product',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia dese',
    },
    eSign: {
      title: 'Online Paperwork with eSign',
      statusLabel: 'Online Paperwork with eSign',
      description: 'Dealers need to upload their dealership\'s privacy policy in PDF format to enable eContracting and Remote eSign capabilities to allow Ford Credit-specific documents to be generated through RouteOne at customer request. Dealers have control to work with their customers the way they prefer to complete Ford Credit paperwork and should remain compliant with federal and local laws when deciding whether to support a customer\'s request for online paperwork. Functionality will continue to grow to also include dealer-specific documents as we move further down the product cycle. Some dealers may already be enrolled and will not need to complete this step.',
    },
  },
  tradeIn: {
    title: 'Trade-In',
    sectionOne: {
      title: 'Automotive Remarketing Services (ARS) Program Enrolment',
      enrolledInARS: 'Your dealership is enroled in ARS.',
      notEnrolledInARS: 'Your dealership is not enroled in ARS.',
      noTradeInMembers: 'Your dealership does not have a trade-in team member set. Please click ‘Update Trade-In Team.’',
      noEntitlements: 'Your dealership does not have entitlements set.',
      documents: {
        itemOne: 'ARS Customer Information Form',
        itemTwo: 'ARS Remarketing Agreement',
        itemThree: '<b>Power of Attorney Form(s)</b> specific to province or city:',
        itemFour: 'Supplier Code EFT Request Letter',
        subItem1: 'Power of Attorney – ADESA British Columbia',
        subItem2: 'Power of Attorney – Manheim Toronto and Manheim Montreal',
        subItem3: 'Power of Attorney – ADESA (all other Provinces and Cities)',
      },
      linkOne: 'Update Trade-In Team',
      linkTwo: 'set entitlements',
      paragraphOne: 'Ford has a built a vehicle trade-in appraisal process that allows a Customer to enter their trade-in vehicle information to receive a transactional offer from Ford. Your dealership will maintain full control of confirming the Customer has represented the vehicle accurately and making decisions about accepting the vehicle to retain for your inventory, rejecting, or sending the vehicle to auction through the Automotive Remarketing Services (ARS) Dealer Trade Program (DTP) to redeem the Ford offer. Dealerships must be enroled in ARS DTP to redeem Ford offers.',
      paragraphTwo: 'Ford of Canada’s ARS program offers a comprehensive lineup of remarketing services to Ford of Canada Dealers, rental companies, commercial fleets, and leasing companies.',
      paragraphThree: 'The ARS program provides Ford of Canada Dealers the opportunity to sell their used vehicles, of any brand, at Ford-sponsored auctions intermixed with other Ford company vehicles, assisting with Dealer profitability and inventory relief by offering an alternative wholesale outlet for selling used vehicles.',
      paragraphFour: 'There is no cost to enrol in ARS.',
      paragraphFive: 'To enrol in ARS, download and complete these forms:',
      paragraphSix: {
        partOne: 'Email them directly to ',
        partTwo: ' with the Subject: "<your dealership name> - ARS Enrolment Docs." Enrolment can take up to 10 business days to complete.',
      },
      paragraphSeven: 'The names listed below were assigned during enrolment to manage trade-ins submitted through the eCommerce experiences and make decisions on which vehicles to retain and enrol in ARS. In the event you wish to adjust your trade-in team, click ‘Update Trade-In Team.’',
      paragraphEight: {
        partOne: 'Setting trade-in entitlements is a mandatory step that must be completed before entering the trade-in module. To manage trade-ins in ARS, your administrator must assign the ARSInvoice and ARSVehicleNotification entitlements at ',
        partTwo: ' Only authorized Dealer users with authority to manage trade-ins should be granted ARS Trade-In module entitlements.',
      },
    },
  },
  customerActivity: {
    title: 'Customer Activity <i style="font-size: 13px;">(Coming Soon)</i>',
    sectionOne: {
      title: 'Enhanced Dealer Dashboard (EDD) Reporting <i style="font-size: 13px;">(Coming Soon)</i>',
      paragraph: 'Your dealership will have access to eCommerce customer activity reporting in Enhanced Dealer Dashboard (EDD) . The eCommerce views will be available in the EDD menu and include rolled up metrics on the "eCommerce Reporting" view and customer level activity on the "eCommerce Lead Detail" view. You can access these views with your existing credentials.  If you do not have access to EDD, contact your dealership administrator to grant access at www.dsps.dealerconnection.com.',
      link: 'enhanced dealer dashboard',
    },
  },
  customerHandling: {
    title: 'Customer Handling <i style="font-size: 13px;">(Coming Soon)</i>',
    sectionOne: {
      title: 'Reservations, Order Requests, and New Vehicle Purchase Requests <i style="font-size: 13px;">(Coming Soon)</i>',
      videoTitle: 'eCommerce Customer Handling Overview',
      paragraphOne: 'The eCommerce experiences provide your customers the ability to submit Reservations, Order Requests and New Vehicle Purchase Requests with direct customer-to-dealer payments. Any requests a customer submits to your dealership will flow directly into your CRM and into the new Customer Handling Pages. The contact elected to receive these alerts during enrolment will also receive a hot email alert, notifying them of the new request and directing them to the Customer Handling Pages to take action. The Customer Handling Pages provide you access to the selections the customer has made in the online experience. Here you will be able to make decisions on things like accepting the submission, deposits, down payments, accepting trade-in requests and responding to inquiries for online paperwork.',
      paragraphTwo: 'Each overview below provides experience-specific job aids and guides for review before accessing the Customer Handling Pages to view and action upon requests.',
      paragraphThree: 'Assigning EMP_eComm_CustomerHandling and WBDO View and/or Submit permissions is required to manage reservations, order requests and new vehicle purchase requests from your customers. Your dealer administrator can set entitlements at www.dsps.dealerconnection.com. Only authorized dealer users with authority to manage reservations, order requests and new vehicle purchase requests should be granted entitlements.',
      linkOne: 'customer handling pages',
      linkTwo: 'set entitlements',
      errorToast: 'Your dealership does not have entitlements set.',
      reservationRequests: {
        title: 'How do Reservations Requests work?',
        documentsTitle: 'Reservation Job Aids',
        paragraphOne: 'Customers will be able to submit a reservation for a new vehicle for vehicle lines offering the reservation experience. The reservation will flow to the dealership of their choice and contain a deposit to reserve their vehicle. Reservations placed with your dealership can be viewed in your CRM and in the eCommerce Customer Handling Pages.',
        paragraphTwo: 'The EMP_eComm_CustomerHandling and OrderMgt View permissions are required to access and manage Reservations in the Customer Handling Pages.',
        pdfOne: 'Reservation Customer Experience Overview',
        pdfTwo: 'Reservation & Order Customer Handling Guide',
        pdfThree: 'Mustang Mach-e Customer Handling Guide',
        pdfFour: 'Bronco Customer Handling Guide',
        pdfFive: 'eCommerce Customer Communications',
        pdfSix: 'eCommerce Deposits In Dealership',
      },
      orderRequests: {
        title: 'How do Order Requests work?',
        documentsTitle: 'Order Job Aids',
        paragraphOne: 'The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage Order Requests in the Customer Handling Pages.',
        pdfOne: 'Order Customer Experience Overview',
        pdfTwo: 'Reservation & Order Customer Handling Guide',
        pdfThree: 'Payment & Reporting Guide',
        pdfFour: 'eCommerce Customer Communications',
        pdfFive: 'eCommerce Deposits In Dealership',
      },
      fordCartAndCheckoutRequests: {
        title: 'How do New Vehicle Purchase Requests work?',
        documentsTitle: 'eCommerce Cart And Checkout Job Aids',
        paragraphOne: 'eCommerce cart and checkout introduces the ability for customers to select a preferred dealer in Showroom and complete the cart and checkout experience. Customers can search dealer-priced inventory by payment options/methods, generate trade-in values backed by Ford, select from a menu of dealer-priced accessories and protection plans, view personalized taxes and fees, submit a credit application with decisioning and request online paperwork with eSign. New Vehicle Purchase Requests can be viewed in your dealership’s CRM and in the eCommerce Customer Handling Pages. You, as the dealer, will maintain full control over accepting New Vehicle Purchase Requests and making decisions in reaction to customer requests. Customers will be able to submit New Vehicle Purchase Requests beginning with the Mustang Mach-E, with the expansion of full line of vehicles to follow.',
        paragraphTwo: 'The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage New Vehicle Purchase Requests in the Customer Handling Pages.',
        pdfOne: 'eCommerce Cart And Checkout Readiness Checklist',
        pdfTwo: 'ecommerce Cart And Checkout Customer Experience Overview',
        pdfThree: 'eCommerce Cart And Checkout Playbook',
        pdfFour: 'Customer Handling Overview Job Aid',
        pdfFive: 'Customer Handling Accessories Job Aid',
        pdfSix: 'Customer Handling Trade-in Job Aid',
        pdfSeven: 'Customer Handling F&I Job Aid',
        pdfEight: 'Customer Customer Communications',
        pdfNine: 'crm Templates',
        pdfTen: 'eCommerce Deposits In Dealership',
      },
      lincolnCartAndCheckoutRequests: {
        title: 'How do New Vehicle Purchase Requests work?',
        documentsTitle: 'Cart And Checkout Job Aids',
        paragraphOne: 'Cart and Checkout introduces the ability for Clients to select a preferred Lincoln store in Showroom and complete the Cart and Checkout experience. Clients can search Lincoln store-priced inventory by payment options/methods, generate trade-in values backed by Lincoln, select from a menu of store-priced accessories and protection plans, view personalized taxes and fees, submit a credit application with decisioning and request online paperwork with eSign. New Vehicle Purchase Requests can be viewed in your store’s CRM and in the eCommerce Client Handling Pages. You, as the store, will maintain full control over accepting New Vehicle Purchase Requests and making decisions in reaction to Client requests. Clients will be able to submit New Vehicle Purchase Requests on your full vehicle line in Mid-Year 2021.',
        paragraphTwo: 'The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage New Vehicle Purchase Requests in the Client Handling Pages.',
        pdfOne: 'Reservation Client Experience Overview',
        pdfTwo: 'Cart and Checkout Playbook',
        pdfThree: 'Client Handling Overview Job Aid',
        pdfFour: 'Client Handling Accessories Job Aid',
        pdfFive: 'Client Handling Trade-in Job Aid',
        pdfSix: 'Client Handling F&I Job Aid',
        pdfSeven: 'CRM Templates',
        pdfEight: 'eCommerce Marketplace Portal Overview',
      },
    },
  },
  errors: {
    TERMS_CONDITIONS_NOT_COMPLETED: 'Accept Terms and Conditions.',
    DEALER_INFO_NOT_COMPLETED: 'Add a dealer phone and save before continue.',
    teamSetupNotCompleted: 'Team Setup form is not completed.',
    kdmNotSet: 'At least one Key Decision Maker (KDM) is required. More details on KDM\'s can be found in the Terms & Conditions section of enrolment.',
    remoteDeliveryNotSet: 'Remote Delivery option not set.',
  },
};
