<template>
  <header
    class="app-page-header"
    :class="$store.getters['user/activeBrandName']"
  >
    <div class="app-page-header__content">
      <h1 class="app-page-header__title">
        {{ title }}
      </h1>
      <slot name="controls" />
    </div>
    <div class="app-page-header__divider">
      <router-link
        v-if="homePath"
        class="app-page-header__link"
        aria-label="Go to home"
        :to="homePath"
      >
        <span class="app-icon-home-o" />
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    homePath: {
      type: [String, Object],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.app-page-header {
  --divider-color: #{$color--fds-primary};
  --header-padding: 0 15px;
  &.lincoln {
    --divider-color: #2b383e;
  }
  @include respond(lg-up) {
    --header-padding: 0 64px;
  }

  margin: 0 -15px;
  margin-bottom: 40px;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--header-padding);
    margin-bottom: 20px;
  }
  &__title {
    text-transform: uppercase;
    margin: 0;
    font-weight: $font-weight--light;
    line-height: 1.2;
    @include respond(xs-dn) {
      text-align: center;
      font-size: 30px;
    }
    @include respond(sm-up) {
      font-size: 41px;
      line-height: 1.6;
    }
    @include respond(lg-up) {
      font-size: 45px;
    }
  }
  &__divider {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: var(--header-padding);
    background-color: var(--divider-color);
    @include respond(xs-dn) {
      height: 24px;
    }
  }
  &__link {
    display: flex;
    font-size: 32px;
    color: $color--white;
    @include respond(xs-dn) {
      font-size: 22px;
    }
  }
}
</style>
