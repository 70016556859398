<template>
  <div class="stripe-account-setup | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="stripe-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-md-9">
        <p>{{ description }}</p>
      </div>
      <div class="stripe-account-setup__button-wrapper | col-md-3">
        <StandardButton
          track-event-name="click_stripeAccountSetup"
          track-section-name="stripeAccount"
          :text="buttonText"
          @click="navigateTo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEDashboardStripeAccountSetup',
  components: {
    StandardButton,
    DashboardResourcesDisplay,
  },
  computed: {
    ...mapGetters({
      resources: 'modelE/dashboard/stripe/activeResources',
    }),
    description() {
      if (this.$store.state.modelE.stripe.accountInfo.applicationStatus === 'active') {
        return this.$l('modelEDashboard.stripe.accountSetup.p2');
      }
      return this.$l('modelEDashboard.stripe.accountSetup.p1');
    },
    buttonText() {
      if (this.$store.state.modelE.stripe.accountInfo.applicationStatus === 'active') {
        return this.$l('dashboardStripeAccountSetup.btn2');
      }
      return this.$l('dashboardStripeAccountSetup.btn1');
    },
  },
  mounted(){
    this.$store.dispatch('modelE/stripe/getStripeData', 'F');
  },
  methods: {
    navigateTo() {
      this.$router.push({ name: 'ModelEStripe' });
    },
  },
}
</script>

<style lang="scss" scoped>
.stripe-account-setup {
  &__button-wrapper {
    margin-top: 24px;
    @include respond(md-up) {
      margin-top: 0;
      text-align: right;
    }
  }
}
</style>
