<template>
  <BaseButton
    class="navigation-card__link"
    :to="path"
    :aria-label="ariaLabel"
  >
    <div class="navigation-card">
      <img
        v-if="imageName"
        class="navigation-card__image"
        alt="Card image"
        :src="require(`@/assets/images/${imageName}`)"
      />
      <div class="navigation-card__content">
        <h2 class="navigation-card__title">
          {{ title }}
        </h2>
        <span class="navigation-card__subtitle">
          {{ subtitle }}
        </span>
        <slot />
        <div class="navigation-card__footer">
          <TextButton text="Select" />
        </div>
      </div>
    </div>
  </BaseButton>
</template>

<script>
import BaseButton from './common/BaseButton.vue';
import TextButton from './common/TextButton.vue';

export default {
  name: 'ModelENavigationCard',
  components: {
    BaseButton,
    TextButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    imageName: {
      type: String,
      default: '',
    },
    path: {
      type: [String, Object],
      required: true,
    },
    ariaLabel: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-card {
  padding: 16px;
  background-color: $color--white;
  color: $color--fds-primary;
  border: 1px solid #dedede;
  border-radius: 6px;
  font-weight: $font-weight--normal;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.15);
  @include respond(sm-up) {
    display: flex;
    gap: 44px;
    width: 100%;
    padding: 32px;
  }
  &__link:focus {
    text-decoration: none;
  }
  &__image {
    width: 208px;
    height: 208px;
    @include respond(sm-dn) {
      display: block;
      margin: 0 auto;
      margin-bottom: 16px;
    }
  }
  &__content {
    flex: 1;
  }
  &__title {
    margin: 0;
    font-size: 24px;
    font-weight: $font-weight--normal;
  }
  &__subtitle {
    font-size: 18px;
    display: block;
    margin: 14px 0;
  }
  &__footer {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $color--fds-disabled1;
  }
}
</style>
