export default {
  title: '[NT] Reports and Field Tools',
  business: {
    title: '[NT] Business Reports',
    description: '[NT] *Coming Soon*',
  },
  dealerVisits: {
    title: '[NT] Dealer Visit Reports',
    description: '[NT] *Coming Soon*',
  },
  dealerStatus: {
    title: 'Rapport sur l\'état du concessionnaire',
    description: '[NT] Dealer completion status of all enrolment and activation steps',
  },
  dealerReadiness: {
    title: '[NT] Dealer Readiness Reports',
    description: '[NT] *Coming Soon*',
  },
  customerHandlingTools: {
    title: '[NT] Customer Handling Tools',
    description: '[NT] *Coming Soon*',
  },
  activationTools: {
    title: '[NT] Activation Tools',
    description: '[NT] *Coming Soon*',
  },
};
