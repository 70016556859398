import activationSummary from './translations/activationSummary';
import dashboard from './translations/dashboard';
import help from './translations/help';
import home from './translations/home';
import reports from './translations/reports';
import resourceCenter from './translations/resourceCenter';
import stripe from './translations/stripe';

export default {
  activationSummary,
  dashboard,
  help,
  home,
  reports,
  resourceCenter,
  stripe,
};
