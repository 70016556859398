<template>
  <PromptDialog
    :open="browserIsOutOfDate && showModal"
    :title="$l('browserIsNotCompatible.title')"
  >
    <p>{{ $l('browserIsNotCompatible.paragraphOne') }}</p>
    <template #footer>
      <StandardButton
        :text="$l('modalFrame.buttonAcknowledge')"
        @click="closeModal"
      />
    </template>
  </PromptDialog>
</template>

<script>
import { ElementType, Event } from '@/constants/track';
import PromptDialog from './common/PromptDialog.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'BrowserOutdatedModal',
  components: {
    PromptDialog,
    StandardButton,
  },
  data() {
    return {
      showModal: true,
    };
  },
  computed: {
    browserIsOutOfDate() {
      const { userAgent } = window.navigator;
      const browserIsMicrosoftInternetExplorer = userAgent.indexOf('MSIE ') > 0;

      return browserIsMicrosoftInternetExplorer || !!navigator.userAgent.match(/Trident.*rv:11\./);
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;

      this.$store.dispatch('track/event', {
        event: Event.CLOSE_OUTDATED_BROWSER_MODAL,
        elementType: ElementType.MODAL,
        pageName: this.$route.meta.track.pageName,
      });
    },
  },
};
</script>
