<template>
  <label
    class="toggle"
    :class="[toggleClasses, $store.getters['user/activeBrandName'], $attrs.class]"
    :for="id"
  >
    <span
      v-if="hasOutsideText"
      class="toggle__label"
    >
      {{ labelText.inactive }}
    </span>
    <div :class="{'toggle__control': true, 'toggle__language-toggle': isLanguageToggle}">
      <input
        v-bind="$attrs"
        :id="id"
        ref="toggle"
        class="toggle__input"
        type="checkbox"
        :checked="modelValue"
        :disabled="disabled"
        @keypress.enter="$refs.toggle.click()"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <span
        v-show="!hasOutsideText && modelValue"
        v-text="labelText.active"
        class="toggle__control-label toggle__control-label--checked-value"
      />
      <span class="toggle__slider" />
      <span
        v-show="!hasOutsideText && !modelValue"
        v-text="labelText.inactive"
        class="toggle__control-label toggle__control-label--unchecked-value"
      />
    </div>
    <span
      v-if="hasOutsideText"
      class="toggle__label toggle__label--right"
    >
      {{ labelText.active }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'AppToggle',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    activeText: {
      type: String,
      default: '',
    },
    inactiveText: {
      type: String,
      default: '',
    },
    hasOutsideText: {
      type: Boolean,
      default: false,
    },
    floatLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isLanguageToggle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toggleClasses() {
      return {
        'toggle--checked': this.modelValue,
        'toggle--disabled': this.disabled,
        'toggle--float-label': this.floatLabel,
      };
    },
    labelText() {
      return {
        active: this.activeText || this.$l('general.yes'),
        inactive: this.inactiveText || this.$l('general.no'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  --outline-color: #{$color--fds-primary};
  --color-unchecked: #{$color--fds-gray2};
  --color-checked: #{$color--fds-secondary};
  --label-spacing: 16px;
  display: flex;
  align-items: center;
  gap: var(--label-spacing);
  width: fit-content;
  font-size: 12px;
  &.lincoln {
    --outline-color: #{$color--lds-primary};
    --color-unchecked: #{$color--fds-gray2};
    --color-checked: #{$color--lds-secondary};
  }
  &--checked {
    .toggle__control {
      background-color: var(--color-checked);
    }
    .toggle__slider {
      transform: translateX(100%);
      margin-left: 1.6rem;
    }
  }
  &--disabled {
    --color-unchecked: #{$color--fds-disabled3};
    --color-checked: #{$color--fds-disabled3};
    .toggle__slider {
      background-color: $color--fds-disabled1;
      background-image: none;
    }
    .toggle__control-label {
      display: none;
    }
  }
  &--float-label {
    position: relative;
    .toggle__label {
      position: absolute;
      left: 0;
      transform: translateX(calc(-100% - var(--label-spacing)));
      &--right {
        right: 0;
        transform: translateX(calc(100% + var(--label-spacing)));
      }
    }
  }
  &__input-wrapper {
    display: flex;
  }
  &__input {
    width: 0;
    height: 0;
    opacity: 0;
    &:focus-visible {
      + .toggle__control-wrapper {
        outline: 1px solid var(--outline-color);
        outline-offset: 10px;
      }
    }
  }
  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 82px;
    height: 40px;
    border-radius: 26.7px;
    background-color: var(--color-unchecked);
    box-shadow: 0 1px 0 -1px #ffffff, 0 -5px 10px 2px #ffffff,
      0 -5px 10px 2px #ffffff, 0 10px 10px 0 rgba(0, 0, 0, 0.2),
      inset 0 4px 7px 0 rgba(47, 47, 47, 0.35), inset 0 -2px 4px 0 #ababab;
    position: relative;
    cursor: pointer;
  }
  &__language-toggle {
    background-color: #6e6e6e !important;
  }
  &__control-label {
    color: $color--white;
    font-size: 1.2rem;
    letter-spacing: 1px;
    position: absolute;
    &--checked-value {
      left: 1rem;
    }
    &--unchecked-value {
      right: 1rem;
    }
  }
  &__slider {
    width: 30.7px;
    height: 30.7px;
    margin-left: 5px;
    border-radius: 25.3px;
    position: absolute;
    top: 4.7px;
    z-index: 1;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, #ffffff, #f6f6f6);
    transform: translateX(0);
    transition: background-color 0.2s cubic-bezier(0, 0, 1, 1),
      transform 0.2s cubic-bezier(0, 0, 1, 1);
  }
  &__label {
    white-space: nowrap;
  }
}
</style>
