<template>
  <ul>
    <TheHeaderSkipLink />
    <TheHeaderMenuItem
      v-if="reportsIsVisible"
      :path="{ name: 'ModelEReports' }"
      :name="$l('header.links.reports')"
    />
    <TheHeaderMenuItem
      :path="{ name: 'ModelEResourceCenter' }"
      :name="$l('header.links.resources')"
    />
    <TheHeaderMenuItem
      :path="{ name: 'ModelEDashboard' }"
      :name="$l('header.links.dashboard')"
    />
    <TheHeaderMenuItem
      v-if="$route.name === 'ModelEStripe'"
      :path="{ name: 'ModelEStripe' }"
      :name="$l('header.links.stripe')"
    />
    <TheHeaderMenuItem
      :path="{ name: 'ModelEFAQ' }"
      :name="$l('header.links.faqs')"
    />
    <TheHeaderMenuItem
      :path="{ name: 'ModelEHelp' }"
      icon="app-icon-help-chat"
      :name="$l('header.links.help')"
    />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import TheHeaderSkipLink from './TheHeaderSkipLink.vue';
import TheHeaderMenuItem from './TheHeaderMenuItem.vue';

export default {
  name: 'TheHeaderMenuListBlue',
  components: {
    TheHeaderSkipLink,
    TheHeaderMenuItem,
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/userProfile',
    }),
    reportsIsVisible() {
      return this.userProfile.reports.length;
    },
  },
};
</script>
