export default {
  tooltip: {
    management: 'Responsible for managing the program within the Store, and following up with other team members to ensure Store activation activities are completed and coordinating with F(ii) and owner to complete Stripe Account Setup (this may already be complete for Stores participation in online reservations & orders).',
    newCar: 'Responsible for setting vehicle pricing to appear in eCommerce experiences for the Store.',
    usedCar: 'Responsible for enroling the Store in ARS (if needed) and being aware of who has been assigned to receive these leads and coordinating with them.',
    eCommSales: 'Responsible for reviewing and acting upon Reservations, Order Requests and New Vehicle Purchase Requests and assisting the Client through the purchase process. You can assign individuals throughout the Store to have one or more of these responsibilities.',
  },
  item: {
    errorMessage: 'Store Information and Team Setup',
    reservations: {
      label: 'by checking this box this individual will receive alerts any time a client places a reservation with your Store.',
      tooltip: 'Individuals will require WBDO Submit and EMP_eComm_CustomerHandling permissions to view or accept Reservations and Orders in Client Handling Pages. WBDO and EMP Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your Store that have WBDO and EMP permissions set please click',
    },
    orders: {
      label: 'by checking this box this individual will receive alerts any time a client places an order request with your Store. Order requests will need to be approved and entered into WBDO by an individual with appropriate permissions.',
      tooltip: 'Individuals will require WBDO Submit permissions to enter Orders into WBDO Client Handling Pages. Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your Store that have WBDO and EMP permissions set please click',
    },
    vehicles: {
      label: 'by checking this box this individual will receive alerts any time a client places a new vehicle purchase request with your Store.',
      tooltip: 'Individuals will require WBDO Submit and EMP_eComm_CustomerHandling permissions to handle New Vehicle Purchase Requests. WBDO and EMP Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your Store that have WBDO and EMP permissions set please click',
    },
  },
};
