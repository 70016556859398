<template>
  <div>
    <p>{{ $l('readinessMeeting.title') }}</p>
    <ol class="list">
      <li>
        {{ $l('readinessMeeting.itemOne.title') }}
        <ol class="list list--lower-alpha">
          <li v-html="$l('readinessMeeting.itemOne.list.itemOne')" />
          <li>{{ $l('readinessMeeting.itemOne.list.itemTwo') }}</li>
          <li>{{ $l('readinessMeeting.itemOne.list.itemThree') }}</li>
        </ol>
      </li>
      <li>
        <span v-html="$l('readinessMeeting.itemTwo.title')" />
        <ol class="list list--lower-alpha">
          <li>{{ $l('readinessMeeting.itemTwo.list.itemOne') }}</li>
          <li>{{ $l('readinessMeeting.itemTwo.list.itemTwo') }}</li>
          <li>{{ $l('readinessMeeting.itemTwo.list.itemThree') }}</li>
          <li>{{ $l('readinessMeeting.itemTwo.list.itemFour') }}</li>
        </ol>
      </li>
      <li v-html="$l('readinessMeeting.itemThree')" />
      <li v-html="$l('readinessMeeting.itemFour')" />
      <li>{{ $l('readinessMeeting.itemFive') }}</li>
      <li v-html="$l('readinessMeeting.itemSix')" />
    </ol>
    <div
      v-if="documents.length"
      class="document-row"
    >
      <DocumentCard
        v-for="(document, index) in documents"
        :key="index"
        :title="document.title"
        :thumbnail="document.thumbnail"
        :description="document.longDesc"
      />
    </div>
  </div>
</template>

<script>
import DocumentCard from './DocumentCard.vue';

export default {
  name: 'ReadinessPlanModalDepartmentTeamMeeting',
  components: {
    DocumentCard,
  },
  computed: {
    documents() {
      return this.$store.getters['readinessPlan/activeDepartmentMetingDocuments'].documents;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding-inline-start: 20px;
  @include respond(xs-dn) {
    padding-inline-start: 20px;
  }
  &--lower-alpha {
    list-style: lower-alpha;
  }
  &--lower-roman {
    list-style: lower-roman;
  }
}
.document-row {
  display: flex;
  flex-wrap: wrap;
  gap: 100px 40px;
  width: 100%;
  padding: 0 20px;
  margin-top: 50px;
}
</style>
