<template>
  <h2 class="track__heading fds-headline__4">
    {{ $l('impersonate.track.title') }}
  </h2>
  <div
    class="track"
  >
    <div
      class="track__fields"
    >
      <TextField
        v-model="userId"
        id="userID"
        :label="$l('impersonate.track.text1')"
      />
      <StandardButton
        class="track__button"
        :text="$l('impersonate.track.button')"
        @click="getTrackList"
      />
    </div>
    <LoadingIndicator
      v-if="isLoadingTrackList"
      class="track__loading-indicator"
    />
    <div
      v-else
    >
      <table
        class="track__table | app-model-e-table"
      >
        <thead>
          <th>{{ $l('impersonate.track.table1.dealerId') }}</th>
          <th>{{ $l('impersonate.track.table1.pageName') }}</th>
          <th>{{ $l('impersonate.track.table1.parentControlName') }}</th>
          <th>{{ $l('impersonate.track.table1.pageControlName') }}</th>
          <th>{{ $l('impersonate.track.table1.controlType') }}</th>
          <th>{{ $l('impersonate.track.table1.userId') }}</th>
          <th>{{ $l('impersonate.track.table1.dateCreated') }}</th>
        </thead>
        <tbody>
          <tr
            v-for="item in trackList"
            :key="item.id"
          >
            <td>{{ item.dealerID }}</td>
            <td>{{ item.pageName }}</td>
            <td>{{ item.parentControlName }}</td>
            <td>{{ item.pageControlName }}</td>
            <td>{{ item.controlType }}</td>
            <td>{{ item.userID }}</td>
            <td>{{ item.dateCreated ? format(new Date(item.dateCreated), 'MM/dd/yyyy hh:mm:ss a') : '' }}</td>
          </tr>
        </tbody>
      </table>
      <table
        class="track__table | app-model-e-table"
      >
        <thead>
          <th>{{ $l('impersonate.track.table2.dealerId') }}</th>
          <th>{{ $l('impersonate.track.table2.videoId') }}</th>
          <th>{{ $l('impersonate.track.table2.videoAction') }}</th>
          <th>{{ $l('impersonate.track.table2.videoActionSeconds') }}</th>
          <th>{{ $l('impersonate.track.table2.videoPercentComplete') }}</th>
          <th>{{ $l('impersonate.track.table2.pageName') }}</th>
          <th>{{ $l('impersonate.track.table2.userId') }}</th>
          <th>{{ $l('impersonate.track.table2.createdOn') }}</th>
        </thead>
        <tbody>
          <tr
            v-for="item in videoList"
            :key="item.id"
          >
            <td>{{ item.dealerID }}</td>
            <td>{{ item.video_ID }}</td>
            <td>{{ item.video_Action }}</td>
            <td>{{ item.video_Action_Seconds }}</td>
            <td>{{ item.video_PercentComplete }}</td>
            <td>{{ item.pageName }}</td>
            <td>{{ item.userID }}</td>
            <td>{{ item.createdOn ? format(new Date(item.createdOn), 'MM/dd/yyyy hh:mm:ss a') : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import apiManager from '@/api';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import TextField from '@/components/common/TextField.vue';
import StandardButton from '@/components/common/StandardButton.vue';
export default {
  name: 'TrackView',
  components: {
    LoadingIndicator,
    TextField,
    StandardButton,
  },
  data() {
    return {
      isLoadingTrackList: true,
      trackList: [],
      videoList: [],
      userId: '',
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/userProfile',
    }),
  },
  mounted() {
    this.userId = this.$store.getters['user/userProfile'].userId;
    this.getTrackList();
  },
  methods: {
    async getTrackList() {
      this.isLoadingTrackList = true;

      try {
        const [trackList, videoList] = await Promise.all([
          apiManager.impersonate.getTrackList(this.userId),
          apiManager.impersonate.getVideoList(this.userId),
        ]);

        this.trackList = trackList || [];
        this.videoList = videoList || [];
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('impersonate.track.errorMessage'),
        });
      } finally {
        this.isLoadingTrackList = false;
      }
    },
    format,
  },
};
</script>

<style lang="scss" scoped>
.track {
  max-width: 1340px;
  margin: 0 auto;
  &__heading {
    margin: 20px 0;
    text-align: center;
  }
  &__loading-indicator {
    margin-top: 40px;
  }
  &__table {
    width: 100%;
    max-width: 1337px;
    margin-top: 40px;
    max-height: 450px;
    overflow-y: auto;
    display: block;
  }
  &__fields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px 32px;
    width: 100%;
    @include respond(md-up) {
      grid-template-columns: 0.3fr auto;
    }
  }
  &__button {
    margin-top: 5px;
  }
}
</style>