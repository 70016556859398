export default {
  title: 'Dealer Profile & Readiness',
  labelDropdown: 'Enter Dealership Name',
  fileDate: 'Eligible Dealer data based on last GOLDD file: {date}.',
  subtitle1: 'Dealer Readiness Profile',
  section1Label: 'eCommerce Readiness',
  meetingInfo: {
    admin: 'Administered by:',
    date: 'Meeting date:',
    role: 'Role:',
    note: 'Note:',
    getStarted: 'Get started',
    review: 'Review',
    resume: 'Resume',
  },
  subtitle2: 'eCommerce Dealer Profile',
  downloadButton: 'Download Dealer Profile',
  dealershipInformation: {
    title: 'Dealership Information',
    titleRow1: 'Dealer Name',
    titleRow2: 'Dealer Code',
    titleRow3: 'Dealer Added',
    titleRow4: 'Region',
    titleRow5: 'Zone',
    titleRow6: 'Contact/Select',
    titleRow7: 'Franchise Type',
    titleRow8: 'Address',
    titleRow9: 'City',
    titleRow10: 'Province',
    titleRow11: 'Postal Code',
    titleRow12: 'Phone Number',
    titleRow13: 'EV Certified',
  },
  contactInformation: {
    title: 'Contact Information',
    titleColumn1: 'Program Role',
    titleColumn2: 'Name',
    titleColumn3: 'Role/Title',
    titleColumn4: 'Hot Alerts',
    titleColumn5: 'Contact Info',
    labelEmail: 'Email >',
  },
  reservationActivation: {
    title: 'Reservation Activation Information',
    titleRow1: 'Enrolment',
    titleRow2: 'Stripe Account',
    titleRow3: 'Experience Status',
  },
  orderActivation: {
    title: 'Order Activation Information',
    titleRow1: '@:dealerProfile.reservationActivation.titleRow1',
    titleRow2: '@:dealerProfile.reservationActivation.titleRow2',
    titleRow3: '@:dealerProfile.reservationActivation.titleRow3',
  },
  evCartAndCheckout: {
    title: 'EV Cart and Checkout Activation Information',
    titleRow1: 'Order Activation Pre-requisite',
    titleRow2: '@:dealerProfile.reservationActivation.titleRow1',
    titleRow3: '@:dealerProfile.reservationActivation.titleRow2',
    titleRow4: 'Accessory Pricing',
    titleRow5: 'Trade-In',
    titleRow6: 'F&I (Coming Soon)',
    titleRow7: {
      ford: 'Customer Handling (Coming Soon)',
      lincoln: 'Client Handling (Coming Soon)',
    },
    titleRow8: 'Activation Readiness',
    titleRow9: '@:dealerProfile.reservationActivation.titleRow3',
  },
  cartAndCheckoutActivation: {
    title: 'Cart and Checkout Activation Information',
    titleRow1: '@:dealerProfile.reservationActivation.titleRow1',
    titleRow2: '@:dealerProfile.reservationActivation.titleRow2',
    titleRow3: 'Vehicle Pricing',
    titleRow4: '@:dealerProfile.evCartAndCheckout.titleRow4',
    titleRow5: '@:dealerProfile.evCartAndCheckout.titleRow5',
    titleRow6: '@:dealerProfile.evCartAndCheckout.titleRow6',
    titleRow7: {
      ford: '@:dealerProfile.evCartAndCheckout.titleRow7.ford',
      lincoln: '@:dealerProfile.evCartAndCheckout.titleRow7.lincoln',
    },
    titleRow8: '@:dealerProfile.evCartAndCheckout.titleRow8',
    titleRow9: '@:dealerProfile.reservationActivation.titleRow3',
  },
  cartAndCheckoutAdvanced: {
    title: 'Cart and Checkout Advanced Options',
    titleRow1: 'MaximTrak (F&I Products)',
    titleRow2: 'Alternate Lenders',
    titleRow3: 'Remote Delivery Preferences',
    titleRow4: 'Advertised Plan Pricing(MI, OH, and Ky Dealers Only)',
  },
};
