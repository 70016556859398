export default {
  title: 'Staging and QA Session Setter',
  label: {
    dealer: 'Select a Dealer',
    stripeStatus: 'Stripe Status',
    feature: 'Feature',
    featureStatus: 'Feature Status',
    steps: 'Steps',
  },
  button: {
    login: 'Login',
    reset: 'Clear Dealer Progress ( Blue )',
    resetModelE: 'Clear Dealer Progress ( ModelE )',
    clearTerms: 'Clear T&C only ( Blue )',
    clearTermsModelE: 'Clear T&C ( ModelE )',
    setStripeStatus: 'Set Stripe Account Status',
    activateCartCheckout: 'Activate Cart and Checkout',
    setFeatureStatus:'Set Feature Status',
    blueStep: 'Set Step Complete ( Blue )',
    modelEStep: 'Set Step Complete ( ModelE )',
    track:'Tracking Report',
  },
  success: {
    stripeStatus: 'Status updated successfully.',
    featureStatus: 'Feature Status updated successfully.',
    stepStatus: 'Step updated sucessfully',
    termsAndConditions: 'Terms and Conditions has been reset successfully.',
    cartCheckout: 'Cart and Checkout activated successfully.',
    resetDealerProgress: 'Dealer progress has been reset successfully.',
    impersonateLogin: 'Dealer impersonated successfully.',
  },
  error: {
    loadDealerList: 'Failed to load the dealer list. Please try again.',
    featureList: 'Failed to load the feature list. Please try again.',
    loadStepList: 'Failed to load the step list. Please try again.',
    stripeStatus: 'Failed to update Stripe status. Please try again.',
    termsAndConditions: 'Failed to reset the Terms and Conditions. Please try again.',
    cartCheckout: 'Failed to activate Cart and Checkout. Please try again.',
    DEALER_NOT_SELECTED: 'Please, select a dealer first.',
    DEALER_IMPERSONATE_DENIED: 'Dealer is not eligible to Impersonate.',
    DEALER_IMPERSONATE_NOTOKEN: 'Could not find token information.',
    DEALER_IMPERSONATE_RESET_FAILED: 'Failed to reset dealer progress. Please try again.',
  },
  track : {
    title: 'Tracking',
    button: 'Refresh',
    text1: 'Limit stats to this UserId',
    errorMessage: 'Failed to load track list, Please try again.',
    table1: {
      dealerId: 'DealerID',
      pageName: 'PageName',
      parentControlName: 'ParentControlName',
      pageControlName: 'PageControlName',
      controlType: 'ControlType',
      userId: 'UserID',
      dateCreated: 'DateCreated',
    },
    table2 : {
      dealerId: 'DealerID',
      videoId: 'VideoID',
      videoAction: 'Video Action',
      videoActionSeconds: 'Video Action Seconds',
      videoPercentComplete: 'Video Percent Complete',
      pageName: 'Page Name',
      userId: 'User ID',
      createdOn: 'Created On',
    },
  },
  modal: {
    login: {
      text: 'Do you want to login as the selected dealer?',
    },
    reset: {
      text: 'Do you want to reset the progress?',
    },
    clearTerms: {
      text: 'Do you want to clear the Terms and Conditions?',
    },
  },
};
