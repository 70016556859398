<template>
  <div class="terms-and-conditions | container--dashboard-accordion">
    <p>{{ $l('modelEDashboard.termsAndConditions.subtitle') }}</p>
    <p class="terms-and-conditions__description">
      {{ $l('modelEDashboard.termsAndConditions.description') }}
    </p>
    <ModelETermsAndConditionsView v-model="termsAreAccepted" />
    <div class="save-btn-wrapper">
      <StandardButton
        v-if="termsAreAccepted"
        size="lg"
        track-event-name="submitForm_acceptTermsConditions"
        track-section-name="termsConditions"
        :is-loading="isSavingTerms"
        :text="$l('general.save')"
        @click="saveTermsAndConditions"
      />
    </div>
    <ModelEDashboardKeyDecisionMaker />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import StandardButton from './common/StandardButton.vue';
import ModelEDashboardKeyDecisionMaker from './ModelEDashboardKeyDecisionMaker.vue';
import ModelETermsAndConditionsView from './ModelETermsAndConditionsView.vue';

export default {
  name: 'ModelEDashboardTermsAndConditions',
  components: {
    StandardButton,
    ModelEDashboardKeyDecisionMaker,
    ModelETermsAndConditionsView,
  },
  data() {
    return {
      isSavingTerms: false,
      termsAreAccepted: false,
      notSavedEditId: null,
    };
  },
  computed: {
    ...mapGetters({
      activeLocale: 'locale/activeLocale',
      activeDealership: 'user/activeDealership',
      userProfile: 'user/userProfile',
      termsAndConditions: 'modelE/termsAndConditions/terms',
    }),
  },
  watch: {
    activeLocale() {
      this.toggleEditsNotSavedAlert();
    },
    activeDealership() {
      this.termsAreAccepted = false;
    },
    termsAreAccepted() {
      this.toggleEditsNotSavedAlert();
    },
  },
  beforeUnmount() {
    this.closeEditsNotSavedAlert();
  },
  methods: {
    async toggleEditsNotSavedAlert() {
      await this.closeEditsNotSavedAlert();

      if (this.termsAreAccepted) {
        this.openEditsNotSavedAlert();
      }
    },
    async openEditsNotSavedAlert() {
      this.notSavedEditId = await this.$store.dispatch(
        'topBanner/addNotSavedEdit',
        this.$l('modelEDashboard.errors.TERMS_CONDITIONS_NOT_COMPLETED'),
      );
    },
    async closeEditsNotSavedAlert() {
      if (this.notSavedEditId) {
        await this.$store.dispatch('topBanner/removeNotSavedEdit', this.notSavedEditId);

        this.notSavedEditId = null;
      }
    },
    async saveTermsAndConditions() {
      this.isSavingTerms = true;

      this.closeEditsNotSavedAlert();

      try {
        const { success } = await apiManager.termsAndConditions.acceptTermsAndConditionsModelE(
          this.activeDealership.dealerId,
          this.termsAndConditions.latestAspx,
        );

        if (!success) {
          throw new Error(this.$l('modelEDashboard.termsAndConditions.acceptTerms.error'));
        }

        const { success: progressSuccess } = await this.$store.dispatch(
          'modelE/dashboard/enrollment/updateProgress',
          'TermsConditions_Completed',
        );

        if (!progressSuccess) {
          throw new Error(this.$l('modelEDashboard.termsAndConditions.acceptTerms.error'));
        }

        await this.$store.dispatch('modelE/termsAndConditions/load');
        await this.$store.dispatch('modelE/dashboard/notifications/getNotifications');

        this.$store.dispatch('topBanner/open', {
          message: this.$l('modelEDashboard.termsAndConditions.acceptTerms.success'),
        });

        this.termsAreAccepted = false;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isSavingTerms = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-and-conditions {
  .save-btn-wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
</style>
