<template>
  <section class="activation-summary">
    <ActivationSummaryReservation />
    <ActivationSummaryOrder />
    <ActivationSummaryCartCheckout />
  </section>
</template>

<script>
import ActivationSummaryReservation from './ActivationSummaryReservation.vue';
import ActivationSummaryOrder from './ActivationSummaryOrder.vue';
import ActivationSummaryCartCheckout from './ActivationSummaryCartCheckout.vue';

export default {
  name: 'ActivationSummary',
  components: {
    ActivationSummaryReservation,
    ActivationSummaryOrder,
    ActivationSummaryCartCheckout,
  },
  mounted() {
    this.$store.dispatch('activationSummary/load');
  },
};
</script>

<style lang="scss" scoped>
.activation-summary {
  display: grid;
  gap: 32px;
  margin-bottom: 56px;
  @include respond(sm-up) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(lg-up) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
