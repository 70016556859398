import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  state() {
    return {
      entitlements: [],
      isEnrolledInARS: false,
      optIn: false,
      hideSection: false,
    };
  },
  getters: {
    entitlements: (state) => [...state.entitlements],
    optIn: (state) => state.optIn,
    hideSection: (state) => state.hideSection,
    isEnrolledInARS: (state) => state.isEnrolledInARS,
    tradeInTeam(state, getters, rootState, rootGetters) {
      return rootGetters['modelE/dealerPersonnel/contacts']
        .filter(({ contactID, tradeTool }) => contactID && tradeTool)
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`);
    },
    resources(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['modelE/dashboard/resources'],
        resourceCenterId.section.MODEL_E_TRADE_IN,
        resourceCenterId.subsection.MODEL_E_ARS_ENROLLMENT_SECTION,
      );
    },
    formDocuments(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['modelE/dashboard/resources'],
        resourceCenterId.section.MODEL_E_TRADE_IN,
        resourceCenterId.subsection.MODEL_E_ARS_ENROLLMENT_SECTION2,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
    activeFormDocuments(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.formDocuments,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
  mutations: {
    setEntitlements(state, entitlements) {
      state.entitlements = entitlements;
    },
    setEnrolledStatus(state, enrolledStatus) {
      state.isEnrolledInARS = enrolledStatus;
    },
    setOptIn(state, optIn) {
      state.optIn = optIn;
    },
    setHideSection(state, hideSection) {
      state.hideSection = hideSection;
    },
  },
  actions: {
    async load({ commit , rootGetters }) {
      const { dealerId } = rootGetters['user/activeDealership'];
      const { tradeIn } = rootGetters['modelE/activationSummary/status'];

      const [
        { value: { data: entitlements } },
      ] = await Promise.allSettled([
        apiManager.tradeIn.getModelEEntitlements(),
      ]);
      const data = await apiManager.tradeIn.getModelETradeIn(dealerId);

      commit('setEntitlements', entitlements || []);
      commit('setEnrolledStatus', data.enrolledInARS);
      commit('setOptIn', data.tradeIn_OptIn);
      commit('setHideSection',(tradeIn === 'green' && !data.enrolledInARS));
    },
  },
};
