export default {
  title: 'Décisionnaire(s) clé(s)',
  description1: 'Pour que votre inscription soit optimale, vous devez assigner au moins un décisionnaire clé chez votre concessionnaire qui prendra les décisions relatives au programme commerce électronique.',
  description2: 'Une fois les Conditions générales mises à jour envoyées, votre concessionnaire devra les accepter afin de participer au programme. La fonctionnalité ci-dessous vous permet d’ajouter jusqu’à trois décisionnaires clés, en dehors de vous-même, qui seront autorisés à accepter les Conditions générales mises à jour.',
  tooltip: 'Le décisionnaire clé a le pouvoir d’accepter les Conditions générales et de gérer les membres de l’équipe commerce électronique pour votre concessionnaire.',
  department: {
    name: 'Département',
    titleOne: 'Gestion',
  },
  item: {
    toggleDetailsButtonAriaLabel: '@:dealerPersonnel.item.toggleDetailsButtonAriaLabel',
    checkboxLabel: 'En cochant la case, je reconnais que les renseignements fournis sont corrects et que je suis autorisé(e) (en vertu de l’alinéa F(ii) Pouvoir de gestion) à effectuer l’inscription pour mon concessionnaire.',
  },
  input: {
    position: '@:dealerPersonnel.input.position',
    phone: '@:dealerPersonnel.input.phone',
    extension: '@:dealerPersonnel.input.extension',
    wslx: 'Code Utilis. WSLX (identif. FMCDealer)',
  },
  messages: {
    savedSuccessfuly: 'Enregistrement de la personne-ressource réussi.',
    updatedSuccessfuly: 'Actualisation de la personne-ressource réussie.',
    deletedSuccessfuly: '[NT] Contact deleted successfully.',
    failedToSave: 'Échec de l’enregistrement de la personne-ressource. Veuillez réessayer.',
    failedToUpdate: 'Échec de la mise à jour de la personne-ressource. Veuillez réessayer.',
    failedToDelete: 'Échec de la suppression de la personne-ressource. Veuillez réessayer.',
    saveKdm: 'Décisionnaire clé',
    fourKeys: 'Seuls quatre décideurs clés peuvent être actifs à la fois. Veuillez désactiver un autre décideur clé avant d\'activer celui-ci.',
    disableKdm: 'Veuillez enregistrer un autre décideur clé comme actif avant de désactiver cet utilisateur.',
  },
  buttons: {
    add: 'Ajouter un décisionnaire clé',
    save: '@:dealerPersonnel.item.saveButtonText',
    delete: '@:dealerPersonnel.item.deleteButtonText',
  },
};
