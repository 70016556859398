<template>
  <DashboardTeamMember
    :open-on-mounted="openOnMounted"
    :information="memberInformation"
    :labels="memberLabels"
  >
    <MemberDetails
      :index="index"
      :is-loading="isLoading"
      :is-deleting-contact="isDeletingContact"
      :full-name="fullName"
      :contact-id="contactId"
      :email="email"
      :extension="extension"
      :phone-number="phoneNumber"
      :position="positionName ? position : ''"
      :email-language="emailLanguage || activeLocale"
      :user-id="userId"
      :is-active="isActive"
      @save-contact="saveContact"
      @delete-contact="deleteContact"
    />
  </DashboardTeamMember>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import DashboardTeamMember from './DashboardTeamMember.vue';
import MemberDetails from './DashboardKeyDecisionMakerMemberDetails.vue';

export default {
  name: 'DashboardKeyDecisionMakerMember',
  components: {
    DashboardTeamMember,
    MemberDetails,
  },
  emits: ['reload-contacts', 'clear-contact-to-be-added'],
  props: {
    index: { type: Number, required: true },
    firstName: { type: String, default: '' },
    lastName: { type: String, default: '' },
    contactId: { type: String, default: '' },
    dealerId: { type: String, default: '' },
    position: { type: String, default: '' },
    phoneNumber: { type: String, default: '' },
    email: { type: String, default: '' },
    extension: { type: String, default: '' },
    emailLanguage: { type: String, default: '' },
    userId: { type: String, default: '' },
    isActive: { type: Boolean, required: true },
    isKeyDecisionMaker: { type: Boolean, required: true },
    openOnMounted: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
      isDeletingContact: false,
    };
  },
  computed: {
    ...mapGetters({
      activeLocale: 'locale/activeLocale',
      kdmPositions: 'dealerPersonnel/keyDecisionMakerPositions',
      emailLanguages: 'dealerPersonnel/emailLanguages',
    }),
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },
    languageName() {
      return this.emailLanguages.find(({ value }) => value === this.emailLanguage)?.text;
    },
    positionName() {
      return this.kdmPositions.find((position) => position.value === this.position)?.text;
    },
    formattedPhoneNumber() {
      const number = this.phoneNumber;

      if (!number) return '';

      return `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6)}`;
    },
    formattedExtension() {
      if (!this.extension) return '';
      return `Ext: ${this.extension}`;
    },
    memberInformation() {
      return [
        this.fullName,
        this.positionName,
        this.formattedPhoneNumber,
        this.formattedExtension,
        this.email,
        this.languageName,
      ];
    },
    memberLabels() {
      return [{
        active: this.isActive,
        text: (this.isActive)
          ? this.$l('general.status.active')
          : this.$l('general.status.inactive'),
      }];
    },
    contactIsBeingAdded() {
      return !this.contactId;
    },
  },
  methods: {
    async saveContact(contact) {
      this.isLoading = true;

      try {
        const { success } = await apiManager.dealer.saveContact({
          contactId: this.contactId,
          dealerId: this.dealerId,
          contact_FirstName: this.firstName,
          contact_LastName: this.lastName,
          kdm: true,

          userID: contact.wslxId.toLowerCase(),
          contact_Position: contact.position,
          languageCode: contact.emailLanguage,
          contact_Phone: contact.phoneNumber,
          contact_Email: contact.email,
          contact_Ext: contact.extension,
          active: contact.isActive,
        });

        if (!success) {
          throw new Error((this.contactIsBeingAdded)
            ? this.$l('keyDecisionMaker.messages.failedToSave')
            : this.$l('keyDecisionMaker.messages.failedToUpdate'));
        }

        await this.$store.dispatch('topBanner/open', {
          message: (this.contactIsBeingAdded)
            ? this.$l('keyDecisionMaker.messages.savedSuccessfuly')
            : this.$l('keyDecisionMaker.messages.updatedSuccessfuly'),
        });

        if (this.contactIsBeingAdded) {
          this.$emit('clear-contact-to-be-added');
        }

        this.$emit('reload-contacts');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteContact() {
      if (this.contactIsBeingAdded) {
        this.$emit('clear-contact-to-be-added');
        return;
      }

      this.isDeletingContact = true;

      try {
        const { success } = await apiManager.dealer.deleteContact({
          dealerId: this.dealerId,
          contactId: this.contactId,
        });

        if (!success) {
          throw new Error(this.$l('keyDecisionMaker.messages.failedToDelete'));
        }

        await this.$store.dispatch('topBanner/open', {
          message: this.$l('keyDecisionMaker.messages.deletedSuccessfuly'),
        });

        this.$emit('reload-contacts');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isDeletingContact = false;
      }
    },
  },
};
</script>
