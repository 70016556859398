import apiManager from '@/api';
import resourceCenterId from '@/data/resourceCenter';

import accordions from './modules/accordions';
import accessoryPricing from './modules/accessoryPricing';
import notifications from './modules/notifications';
import enrollment from './modules/enrollment';
import tradeIn from './modules/tradeIn';
import stripe from './modules/stripe';
import tablets from './modules/tablets';
import customerHandling from './modules/customerHandling';
import vehiclePricing from './modules/vehiclePricing';

export default {
  namespaced: true,
  modules: {
    accessoryPricing,
    accordions,
    notifications,
    enrollment,
    tradeIn,
    stripe,
    tablets,
    customerHandling,
    vehiclePricing,
  },
  state() {
    return {
      resources: [],
    };
  },
  getters: {
    resources: (state) => state.resources,
    documentPath() {
      if (['local', 'development'].includes(process.env.VUE_APP_BASE_ENVIRONMENT)) {
        return 'https://aa2core2.jdstaging.com/Ford/FordLincolneCommGlobal/fesite/resources/static/documents';
      }
      return `${process.env.VUE_APP_BASE_URL}resources/static/documents`;
    },
  },
  mutations: {
    setResources(state, payload) {
      state.resources = payload;
    },
  },
  actions: {
    loadData: async ({ dispatch }) => {
      await Promise.allSettled([
        dispatch('accessoryPricing/load'),
        dispatch('tradeIn/load'),
        dispatch('customerHandling/load'),
        dispatch('vehiclePricing/load'),
      ]);
    },
    async getResources({ commit, getters, rootGetters }) {
      if (getters.resources.length) return;

      const [english, french] = await Promise.allSettled([
        // Get english resources
        apiManager.resourceCenter.getModelEResults({
          pageId: resourceCenterId.page.MODEL_E_DASHBOARD,
          language: rootGetters['locale/locales'].english,
        }),
        // Get french resources
        apiManager.resourceCenter.getModelEResults({
          pageId: resourceCenterId.page.MODEL_E_DASHBOARD,
          language: rootGetters['locale/locales'].french,
        }),
      ]);

      const { value: { data: englishResources } } = english;
      const { value: { data: frenchResources } } = french;

      commit('setResources', [
        ...englishResources.result,
        ...frenchResources.result,
      ]);
    },
  },
};
