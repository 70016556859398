<template>
  <div
    v-outside-click="closeCalendar"
    class="calendar-picker"
    :class="$store.getters['user/activeBrandName']"
  >
    <TextField
      v-bind="$attrs"
      v-model="displayDate"
      mask="00/00/0000"
      :active="calendarIsOpen"
      @click="openCalendar"
      @blur="handleBlur"
    >
      <div
        v-show="calendarIsOpen"
        class="calendar-picker__calendar"
      >
        <div class="calendar-picker__calendar-content">
          <div class="calendar-picker__controls">
            <button
              class="calendar-picker__control-button app-icon-chevron-left"
              aria-label="Previous month"
              @click="previousMonth"
            />
            <span class="calendar-picker__control-text">{{ calendarTitle }}</span>
            <button
              class="calendar-picker__control-button app-icon-chevron-right"
              aria-label="Next month"
              @click="nextMonth"
            />
          </div>
          <div class="calendar-picker__days-wrapper">
            <span
              v-for="(day, index) in $l('calendar.weekDays').split(',')"
              v-text="day"
              :key="index"
              class="calendar-picker__weekday"
            />
            <button
              v-for="day in calendarDays"
              v-text="day.text"
              :key="day.id"
              class="calendar-picker__day"
              :class="day.class"
              :disabled="day.class.blank"
              @click="selectDay(day.value), closeCalendar()"
            />
          </div>
        </div>
      </div>
    </TextField>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
  differenceInSeconds,
  addDays,
  format,
  getMonth,
  isSameDay,
  addMonths,
  subMonths,
  isExists,
  parseISO,
} from 'date-fns';
import { enCA, fr } from 'date-fns/locale';
import TextField from '@/components/common/TextField.vue';

export default {
  name: 'CalendarPicker',
  components: {
    TextField,
  },
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
  },
  data() {
    return {
      displayDate: '',
      initialDisplayDate: '',
      calendarStartingDate: null,
      calendarIsOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      activeLocaleIsFrench: 'locale/activeLocaleIsFrench',
    }),
    calendarTitle() {
      const locale = (this.activeLocaleIsFrench) ? fr : enCA;
      return format(this.calendarStartingDate, 'MMMM yyyy', { locale });
    },
    calendarDays() {
      const firstDayOfMonth = startOfMonth(this.calendarStartingDate);
      const lastDayOfMonth = endOfMonth(firstDayOfMonth);

      const calendarPageStart = startOfWeek(firstDayOfMonth);
      const calendarPageEnd = endOfWeek(lastDayOfMonth);

      const calendarDays = [];

      let day = new Date(calendarPageStart.getTime());

      while (differenceInSeconds(calendarPageEnd, day) > 0) {
        calendarDays.push({
          id: String(day),
          text: format(day, 'd'),
          value: day,
          class: {
            selected: this.modelValue && isSameDay(new Date(day), new Date(this.modelValue)),
            today: isSameDay(new Date(day), new Date()),
            blank: getMonth(new Date(day)) !== getMonth(new Date(firstDayOfMonth)),
          },
        });

        day = addDays(day, 1);
      }

      return calendarDays;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.calendarStartingDate = value ? new Date(value) : new Date();

        this.formatDisplayDate();
      },
    },
    activeLocaleIsFrench() {
      this.formatDisplayDate();
    },
  },
  methods: {
    handleBlur() {
      if (this.initialDisplayDate !== this.displayDate) {
        setTimeout(this.validateDisplayDate, 200);
      }
    },
    openCalendar() {
      this.calendarIsOpen = true;
    },
    closeCalendar() {
      this.calendarIsOpen = false;
    },
    selectDay(value) {
      this.$emit('update:modelValue', value);
    },
    previousMonth() {
      this.calendarStartingDate = subMonths(this.calendarStartingDate, 1);
    },
    nextMonth() {
      this.calendarStartingDate = addMonths(this.calendarStartingDate, 1);
    },
    formatDisplayDate() {
      if (!this.modelValue) {
        this.displayDate = '';
      } else {
        const mask = (this.activeLocaleIsFrench) ? 'ddMMyyyy' : 'MMddyyyy';
        this.displayDate = format(parseISO(new Date(this.modelValue).toISOString()), mask);
      }

      this.initialDisplayDate = this.displayDate;
    },
    validateDisplayDate() {
      const date = [
        this.displayDate.substr(0, 2),
        this.displayDate.substr(2, 2),
        this.displayDate.substr(4),
      ];

      // If active locale is french, assume that the day was typed in before the month
      const day = date[this.activeLocaleIsFrench ? 0 : 1];
      const month = date[this.activeLocaleIsFrench ? 1 : 0];
      const year = date[2];

      const displayDateIsValid = day && month && year && isExists(+year, +month - 1, +day);

      if (!displayDateIsValid) {
        this.selectDay('');
        return;
      }

      this.selectDay(new Date(year, month - 1, day));
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-picker {
  --calendar-text-color: #{$color--fds-primary};
  --calendar-border-color: #{$color--fds-gray2};
  --calendar-font-size: 12px;
  --day-border-radius: 3px;
  --day-hover-border-color: #{$color--fds-primary};
  --day-hover-background-color: #{$color--fds-primary-5};
  --day-today-background-color: #{$color--fds-secondary};
  --day-selected-border-color: #{$color--fds-primary};
  --day-disabled-color: #{$color--fds-disabled3};
  --day-disabled-background-color: #{$color--fds-gray1};
  &.lincoln {
    --calendar-text-color: #{$color--lds-primary};
    --calendar-font-size: 14px;
    --calendar-border-color: #{$color--lds-gray2};
    --day-today-background-color: #{$color--lds-secondary};
    --day-hover-border-color: #{$color--lds-primary};
    --day-selected-border-color: #{$color--lds-primary};
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    color: var(--calendar-text-color);
    background-color: $color--white;
    box-shadow: $fds-elevation__box-shadow--layer3;
    border: 1px solid var(--calendar-border-color);
    border-radius: 3px;
    font-size: var(--calendar-font-size);
    font-weight: 400;
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    z-index: 2;
    &::after {
      content: '';
      background-color: $color--white;
      border: 1px solid var(--calendar-border-color);
      border-left-color: $color--white;
      border-top-color: $color--white;
      position: absolute;
      left: calc(50% - 10px);
      top: -10px;
      width: 20px;
      height: 20px;
      transform: rotate(-135deg);
    }
  }
  &__calendar-content {
    width: fit-content;
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__control-button {
    width: 32px;
    height: 32px;
    border: none;
    background-color: transparent;
  }
  &__days-wrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-top: 16px;
  }
  &__weekday,
  &__day {
    width: 32px;
    height: 32px;
  }
  &__weekday {
    text-align: center;
    font-weight: $font-weight--bold;
    line-height: 1;
  }
  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    &:hover:not(.today):not(.blank):not(:disabled) {
      background-color: var(--day-hover-background-color);
      border: 1px solid var(--day-hover-border-color);
      border-radius: var(--day-border-radius);
    }
    &.blank {
      opacity: 0;
    }
    &.selected {
      border: 1px solid var(--day-selected-border-color);
      border-radius: var(--day-border-radius);
    }
    &:disabled {
      color: var(--day-disabled-color);
    }
    &.today {
      position: relative;
      color: $color--white;
      &::after {
        content: '';
        width: 75%;
        height: 75%;
        border-radius: 50%;
        background-color: var(--day-today-background-color);
        position: absolute;
        z-index: -1;
      }
    }
  }
}
</style>
