export default {
  title: 'Établissement de compte Stripe <sup>TM</sup> et des frais',
  subtitle: 'Faites la configuration pour permettre aux clients de soumettre des dépôts à votre concessionnaire à partir du site https://www.ford.ca/',
  sectionTitle: 'Établissement de compte Stripe et des frais',
  notEnrolled: 'Veuillez terminer le processus d’inscription avant d’accéder à la page Stripe.',
  accountSetup: {
    title: 'Établissement de compte Stripe',
    descriptionTop: {
      notStarted: `<p>Pour commencer votre demande de compte Stripe, cliquez sur le bouton « Demande Stripe ».</p>
      <p>Une fois que le compte de votre concessionnaire sera actif, le bouton passera à « Accéder à Stripe », ce qui vous mènera au tableau de bord de Stripe.</p>`,
      pending: '<p>Votre compte Stripe est en suspens, comme l’état l’indique ci-dessous.</p>',
      active: `<p>Votre compte Stripe est actif, comme l’état l’indique ci-dessous.</p>
      <p>Pour accéder à votre tableau de bord Stripe, cliquez sur le bouton « Accéder à Stripe ».</p>`,
      unknown: '<p>L’état de votre compte Stripe est inconnu, comme c’est indiqué ci-dessous. Votre compte devrait être à l’état actif dans environ 10 minutes. Cliquez sur « Vérifier état » pour voir l’évolution; les renseignements sur la dernière vérification d’état s’afficheront à la droite du bouton.</p>',
    },
    descriptionBottom: {
      notStarted: '<p>Une fois que vous aurez terminé votre demande de compte Stripe, un « En suspens »  s’affichera, et il faudra attendre environ 10 minutes pour que votre compte soit actif. Une fois que votre compte Stripe sera approuvé, vous reviendrez à cette page et confirmerez votre identifiant de compte Stripe ci-dessous.</p>',
      pending: `<p>Une fois que vous aurez terminé votre demande de compte Stripe, un état En suspens s’affichera, et il faudra attendre environ 10 minutes pour que votre compte soit actif. On peut cliquer sur le bouton bleu « Vérifier état » pour vérifier la progression, et les renseignements sur la dernière vérification d’état s’afficheront à sa droite.</p>
      <p>Une fois que votre compte Stripe sera actif, vous reviendrez à cette page et confirmerez votre identifiant de compte Stripe ci-dessous.</p>`,
      active: '<p>Veuillez confirmer votre identifiant de compte Stripe ci-dessous. Veuillez vous reporter au courriel envoyé par Ford commerce électronique dont la ligne d’objet est "Action requise: confirmez votre identifiant de compte Stripe dès aujourd\'hui" pour trouver votre identifiant de compte Stripe. Vous en aurez besoin pour confirmer votre identifiant ci-dessous.</p>',
      unknown: '<p>Une fois que votre compte Stripe sera actif, vous reviendrez à cette page et confirmerez votre identifiant de compte Stripe ci-dessous.</p>',
    },
    stripeLink: 'Demande Stripe',
    stripeLinkApproved: 'Accéder à Stripe',
    buttonTips: 'Conseils de configuration',
    statusBox: {
      application: 'État du compte Stripe',
      account: 'Confirmer l’identifiant et les frais pour le compte Stripe',
    },
    tooltip: {
      application: 'L’état du compte Stripe peut indiquer: Non commencé, En cours, En suspens, En attente ou Actif.',
      account: 'L’état « Confirmer l’identifiant et les frais pour le compte Stripe » peut être le suivant: Incomplet ou Complet.',
    },
    checkStatus: 'Vérifier état',
    lastCheck: 'Dernière vérification: {date}',
    instructions: '*Défiler vers le bas pour réviser/compléter',
    tipsModal: {
      title: 'Conseils de configuration Stripe',
      description: 'Conseils de configuration et de maintenance pour votre compte Stripe',
      item1: 'Avant de commencer votre demande Stripe, téléchargez la liste de configuration du compte Stripe pour le commerce électronique aux fins de consultation facile.',
      item2: 'Assurez-vous que vous avez recueilli les documents financiers nécessaires avant de commencer le processus de demande',
      item3: 'Lorsque vous recevez un courriel comprenant votre identifiant de compte Stripe, veillez l’imprimer ou le mettre dans vos favoris pour le retrouver quand vous en aurez besoin',
      item4Part1: 'Si vous êtes un concessionnaire des deux marques et que vous avez choisi un seul compte, mais que vous souhaitez plus tard vous renseigner sur des identifiants séparés, communiquez avec la gestion du programme commerce électronique par téléphone au',
      item4Part2: 'ou par courriel à l’adresse',
      item5: 'Pour toutes autres questions ou support additionnel lors de la création de votre compte Stripe, veuillez visiter:',
      item5Link: 'https://support.stripe.com/?locale=fr-CA',
      item6: 'Pour communiquer avec Stripe afin d’obtenir de l’assistance, remplissez le formulaire à l’adresse:',
      item6Link: 'https://support.stripe.com/contact/email?locale=fr-CA',
    },
    accountOnHold: {
      title: 'Stripe',
      description: 'Compte en attente',
      message: 'Nos registres indiquent que votre compte Stripe est actuellement en attente. Veuillez communiquer avec Stripe en accédant au formulaire par le lien suivant:',
    },
  },
  feeSetup: {
    title: 'Identifiant de Compte Stripe et Frais',
    paragraphOne: 'Une fois que votre compte Stripe sera actif, votre identifiant de compte Stripe sera inscrit ci-dessous. Lorsqu’il apparaîtra, faites la correspondance de l’identifiant de compte Stripe envoyé par courriel, cochez « Confirmer » et cliquez sur « Enregistrer ».',
    paragraphOneActive: 'L’identifiant de votre compte Stripe est affiché ci-dessous. Pour confirmer qu’il correspond à l’identifiant de compte Stripe fourni dans le courriel de confirmation, cochez « Confirmer » et cliquez sur « Enregistrer ».',
    paragraphTwo: 'Cliquez sur l’accordéon pour configurer les paramètres liés aux fonds acceptés par votre concessionnaire dans commerce électronique. Le « Dépôt de réservation » et le « Dépôt à la caisse Ford autre que Crédit Ford » sont tous les deux établis par Ford et ne peuvent être modifiés. Le « Dépôt de commande » et la « Somme maximale acceptée par carte de crédit » présentent des menus déroulants avec des sommes déjà inscrites qui peuvent être choisies, confirmées et enregistrées.',
    primaryContact: 'Personne-ressource principale:',
    card: {
      deposit: 'Dépôt',
      oem: 'Configuration équipementier',
      accountId: {
        input: 'Identifiant de compte Stripe',
        questions: 'Des questions au sujet de votre identifiant de compte Stripe?',
        tooltip: 'Votre identifiant de compte Stripe sera déjà inscrit ici lorsque votre demande aura été approuvée. Pour confirmer votre identifiant de compte Stripe, consultez le courriel envoyé par le Secrétariat du programme commerce électronique.',
        tooltip2: '[NT] The \'Reservation Deposit\' Is the amount your dealership will ask Customers to deposit for a Reservation. This amount has been set by Ford and can\'t be edited.',
        success: 'Votre identifiant de compte a été confirmé avec succès.',
        error: 'Échec de confirmation de l’identifiant du compte. Veuillez réessayer.',
      },
      reservation: {
        title: 'Réservation',
        description: 'Établies par Ford Canada',
      },
      order: {
        title: 'Commande',
        success: 'Confirmation de la valeur de dépôt réussie.',
        error: 'Échec de confirmation de la valeur de dépôt. Veuillez réessayer.',
      },
      cartCheckout: {
        title: 'Panier d’Achats et Paiement',
        select: 'Somme maximale acceptée par carte de crédit',
        input: 'Dépôt à la caisse Ford autre que Crédit Ford',
        success: 'Confirmation du montant réussie.',
        error: 'Échec de confirmation du montant. Veuillez réessayer.',
      },
    },
  },
  application: {
    title: 'Votre demande Stripe a été soumise!',
    message: 'Vous avez fait un pas important dans la configuration du compte Stripe de votre concessionnaire. Vous pouvez retourner à la page « Établissement de compte Stripe » à tout moment pour vérifier l’état de votre demande. Une fois votre compte Stripe approuvé, son état passera à « Actif », et vous recevrez un courriel du Secrétariat du programme commerce électronique qui comprendra l’identifiant de compte Stripe de votre concessionnaire. À ce moment-là, vous confirmerez votre identifiant de compte Stripe et configurerez les paramètres de votre concessionnaire pour les fonds acceptés des clients commerce électronique à la page \'Établissement de compte Stripe\'.',
  },
};
