<template>
  <div class="dealer-status | container--dashboard-accordion">
    <div class="dealer-status__row | report-section">
      <span>
        {{ $l('reports.accordion3.subtitle') }}
        <AppTooltip
          :title="activeFeatures.powerBi ? $l('reports.accordion3.tooltip1.title2') : $l('reports.accordion3.tooltip1.title')"
          :text="activeFeatures.powerBi ? $l('reports.accordion3.tooltip1.text2') : $l('reports.accordion3.tooltip1.text')"
        />
      </span>
      <StandardButton
        v-if="activeFeatures.powerBi"
        icon-right="chevron-right"
        :text="$l('general.view')"
        @click="$router.push({ name: 'ReportASDD' })"
      />
      <StandardButton
        v-else
        icon-right="chevron-right"
        track-event-name="download_asddReport"
        track-section-name="dealerStatus"
        :text="$l('general.download')"
        :is-loading="isDownloadingASDDReport"
        @click="downloadASDDReport"
      />
    </div>
    <div class="report-section">
      <p class="mb-4">
        {{ $l('reports.accordion3.subtitle2') }}
        <AppTooltip
          :title="$l('reports.accordion3.tooltip2.title')"
          :text="$l('reports.accordion3.tooltip2.text')"
        />
      </p>
      <div class="dealer-status__dropdown-column">
        <AppDropdown
          v-model="selectedMarketId"
          id="market-dealer-status"
          :label="$l('reports.accordion3.selectMarket')"
          :options="marketOptions"
        />
        <AppDropdown
          v-model="selectedRegionId"
          id="region-dealer-status"
          :label="$l('reports.accordion3.selectRegion')"
          :options="regionOptions"
        />
        <div class="dealer-status__button-wrapper">
          <StandardButton
            icon-right="chevron-right"
            track-section-name="dealerStatus"
            :track-event-name="zoneTrackerEventName"
            :text="$l('general.download')"
            :is-loading="isDownloadingRegionalTrackerReport"
            :disabled="!selectedMarketId || !selectedRegionId"
            @click="downloadRegionalTrackerReport"
          />
        </div>
      </div>
    </div>
    <div class="dealer-status__row">
      <span>
        {{ $l('reports.accordion3.subtitle3') }}
        <AppTooltip
          :title="$l('reports.accordion3.tooltip3.title')"
          :text="$l('reports.accordion3.tooltip3.text')"
        />
      </span>
      <StandardButton
        v-if="activeFeatures.powerBi"
        icon-right="chevron-right"
        :text="$l('general.view')"
        @click="$router.push({ name: 'Dealer Profile PowerBi' })"
      />
      <StandardButton
        v-else
        icon-right="chevron-right"
        :text="$l('general.view')"
        @click="$router.push({ name: 'Dealer Profile' })"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppDropdown from './common/AppDropdown.vue';
import StandardButton from './common/StandardButton.vue';
import AppTooltip from './common/AppTooltip.vue';

export default {
  name: 'ReportsDealerStatus',
  components: {
    AppDropdown,
    StandardButton,
    AppTooltip,
  },
  data() {
    return {
      selectedMarketId: '',
      selectedRegionId: '',
      isDownloadingASDDReport: false,
      isDownloadingRegionalTrackerReport: false,
    };
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
    }),
    marketOptions() {
      return this.$store.getters['reports/marketOptions'];
    },
    regionOptions() {
      return this.$store.getters['reports/regionOptions']
        .filter(({ marketValue }) => marketValue === this.selectedMarketId);
    },
    zoneTrackerEventName() {
      const market = this.marketOptions.find(
        ({ value }) => value === this.selectedMarketId,
      )?.text;
      const region = this.regionOptions.find(
        ({ value }) => value === this.selectedRegionId,
      )?.text;

      return `download_zoneTracker-${market}${region}`;
    },
  },
  watch: {
    selectedMarketId() {
      this.selectedRegionId = '';
    },
  },
  methods: {
    async downloadASDDReport() {
      this.isDownloadingASDDReport = true;

      try {
        await this.$store.dispatch('reports/downloadASDDReport');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('reports.errors.reportDownloadFailed'),
        });
      } finally {
        this.isDownloadingASDDReport = false;
      }
    },
    async downloadRegionalTrackerReport() {
      this.isDownloadingRegionalTrackerReport = true;

      try {
        await this.$store.dispatch('reports/downloadRegionalTrackerReport', {
          regionId: this.selectedRegionId,
          marketId: this.selectedMarketId,
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('reports.errors.reportDownloadFailed'),
        });
      } finally {
        this.isDownloadingRegionalTrackerReport = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-status {
  .report-section {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 2px solid #ddd;
  }
  &__row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    @include respond(sm-up) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__dropdown-column {
    display: grid;
    gap: 32px;
    @include respond(sm-up) {
      grid-template-columns: repeat(2, minmax(30%, 360px)) 1fr;
    }
  }
  &__button-wrapper {
    @include respond(sm-up) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
