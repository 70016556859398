import BaseAPI from './baseAPI';

export default class Help extends BaseAPI {
  async get() {
    const { data } = await this.getUnique('/HelpFormFeedback/getHelpFormData');
    return data;
  }

  async post(form) {
    const { data } = await this.postUnique('/HelpFormFeedback/sendFeedbackForm', form);
    return data;
  }

  async getModelE() {
    const { data } = await this.getUnique('/HelpFormFeedbackModelE/getHelpFormDataModelE');
    return data;
  }

  async postModelE(form) {
    const { data } = await this.postUnique('/HelpFormFeedbackModelE/sendFeedbackFormModelE', form);
    return data;
  }
}
