import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  getters: {
    resources(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['modelE/dashboard/resources'],
        resourceCenterId.section.MODEL_E_ENROLLMENT,
        resourceCenterId.subsection.MODEL_E_DEALER_INFORMATION_TEAM_SETUP,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
  actions: {
    async updateProgress({ rootGetters }, step) {
      const { dealerId } = rootGetters['user/activeDealership'];
      const { firstName, lastName, userId } = rootGetters['user/userProfile'];

      return apiManager.enrollment.updateModelEProgress({
        dealerId,
        step,
        firstName,
        lastName,
        userId,
      });
    },
  },
};
