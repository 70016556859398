export default {
  accordion3: {
    title: 'Downloadable Store Status Reports',
    subtitle: 'Activation Summary & Store Details',
    tooltip1: {
      title: 'Activation Summary & Store Details',
      text: 'This report includes the Full eCommerce Summary, the Store Details and Regional Rankings for each Territory.',
    },
    tooltip2: {
      title: 'Regional Tracker by Zone',
      text: 'This report includes a Cart and Checkout summary by Region/Territory and Zone with the total number of Stores, the number enroled and percent to target.',
    },
    subtitle3: 'Store Profile',
    tooltip3: {
      title: 'View Lincoln Store Profile',
      text: 'This is a mobile friendly view of a dealership\'s eCommerce readiness and activation details.',
    },
  },
  accordion4: {
    title: 'Store Readiness Reports <i style="font-size: 13px;">(Coming Soon)</i>',
    subtitle: 'Store Readiness',
    tooltip1: {
      title: 'Store Readiness',
      text: 'Click here to complete Store Readiness Check. This can also be accessed from the Store Profile.',
    },
    subtitle2: 'Store Readiness - Summary Report',
    tooltip2: {
      title: 'Store Readiness Summary Report',
      text: 'Provides a breakdown of eCommerce Store Readiness Plan completion nationally, by Region/Territory, and individually by Store.',
    },
    subtitle3: 'Store Readiness - Tracker (By Zone)',
    tooltip3: {
      title: 'Store Readiness Tracker by Zone',
      text: 'Provides a breakdown of eCommerce Store Readiness Plan by Zone.',
    },
  },
  accordion5: {
    title: 'Client Handling Tools <i style="font-size: 13px;">(Coming Soon)</i>',
  },
  dealerProfile: {
    title: 'Store Profile',
  },
  errors: {
    loadDealerListFailed: 'Failed to load the list of Stores.',
    loadDealerDataFailed: 'Failed to load data for the selected Store. Please try again.',
  },
};
