<template>
  <div class="notification">
    <div class="notification__content">
      <div class="notification__message-wrapper">
        <span
          class="notification__status"
          :class="status"
        />
        <p class="notification__message">
          {{ message }}
        </p>
      </div>
      <TextButton
        v-if="url"
        aria-label="Go to section"
        track-section-name="notifications"
        :track-event-name="`${Event.VIEW_NOTIFICATION}_${url}`"
        :text="$l('general.view')"
        @click="openAccordion"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { dashboardAccordions } from '@/data/accordion';
import { Event } from '@/constants/track';
import TextButton from './common/TextButton.vue';

const store = useStore();

const props = defineProps({
  message: { type: String, default: '' },
  status: { type: String, default: '' },
  url: { type: String, default: '' },
});

function openAccordion() {
  let accordion = '';
  let subcordion = '';

  if (props.url === 'stripe-account-setup') {
    accordion = 'fordStripePaySetup';
    subcordion = 'dashboardStripeAccountSetup';
  }

  dashboardAccordions.forEach(({ subcordion: subcordions }) => {
    subcordions.forEach((item) => {
      if (item.id === props.url) {
        accordion = item.parent;
        subcordion = item.id;
      }
    });
  });

  store.dispatch('modelE/dashboard/accordions/openAccordion', accordion);
  store.dispatch('modelE/dashboard/accordions/openSubcordion', subcordion);
}
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 0 16px;
  &:not(:last-child) {
    border-bottom: 1px solid $color--fds-disabled2;
  }
  @include respond(sm-up) {
    min-height: 56px;
    padding: 0 48px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__message-wrapper {
    display: grid;
    grid-template-columns: 32px auto;
    align-items: center;
  }
  &__message {
    margin: 0;
    margin-right: 16px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
  }
  &__status {
    font-family: $font-family--icons;
    font-size: 16px;
    &.green::before {
      content: $app-icon-Step-Complete;
      color: $color--fds-success1;
    }
    &.red::before {
      content: $app-icon-warning-filled;
      color: $color--fds-error1;
    }
  }
}
</style>
