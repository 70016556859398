import activationSummary from './translations/activationSummary';
import dashboard from './translations/dashboard';
import dealerInformation from './translations/dealerInformation';
import dealerPersonnel from './translations/dealerPersonnel';
import dealerProfile from './translations/dealerProfile';
import home from './translations/home';
import reports from './translations/reports';
import resourceCenter from './translations/resourceCenter';
import start from './translations/start';
import stripe from './translations/stripe';
import footer from './translations/footer';
import help from './translations/help';
import keyDecisionMaker from './translations/keyDecisionMaker';
import readinessCheck from './translations/readinessCheck';

export default {
  activationSummary,
  dashboard,
  dealerInformation,
  dealerPersonnel,
  dealerProfile,
  home,
  reports,
  resourceCenter,
  start,
  stripe,
  footer,
  help,
  keyDecisionMaker,
  readinessCheck,
  general: {
    scrollToTop: 'Back to top',
  },
  readinessPrep: {
    versionOne: {
      itemOne: {
        title: 'Before scheduling Manager\'s Meetings, visit the eCommerce Dealer Portal to review Store\'s:',
        list: {
          itemThree: 'Client Handling Team assignments',
        },
      },
      itemTwo: {
        list: {
          itemOne: {
            listItemTwo: 'Store roles to invite, and how to find each Store\'s assigned contacts for each role',
            listItemFour: 'Attachments to include in the meeting notice to help your Stores prepare',
          },
        },
      },
      itemThree: {
        title: 'Schedule and host Manager\'s Meetings with each of your Stores:',
        list: {
          itemTwo: 'Confirm Store has completed setup for the eCommerce experiences',
          itemThree: 'Use the <b>eCommerce Playbook</b> and <b>eCommerce Readiness Checklist</b> to activate/confirm Store\'s eCommerce Team',
          itemFour: 'Use the <b>eCommerce Department Team Worksheet</b> to review key roles and actions to discuss and define Store Client handling, coordination and CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
          itemFive: 'Review the eCommerce resources with Stores, show them where to locate them on the portal, and recommend they print and distribute the <b>eCommerce Department Team Worksheet</b> and <b>New Vehicle Purchase Request Checklist</b> to all team members during their department meetings',
          itemSix: 'Support the Store in scheduling their own Department Team Meetings, which will follow your session, informing teams, and delivering strategies',
        },
      },
      itemFour: 'Follow up with your Stores post-session to encourage them to complete their <b>Readiness Check</b>',
      itemFive: {
        title: 'Complete an <b>eCommerce Readiness Visit Report</b> for each Store, marking progress for each step of the Readiness Plan as completed <i>(do not mark \'Final\' until all actions are complete. The report will allow you to \'save\' your work and return to update until marked \'final\')</i>',
        list: {
        },
      },
    },
    versionTwo: {
      title: 'Schedule a Manager\'s Meeting to Confirm Program Setup and Define In-Store Strategies:',
      itemOne: 'Review/Confirm your Store has completed setup for the eCommerce experiences',
      itemTwo: {
        list: {
          itemThree: 'Define your Client Handling and CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
        },
      },
      itemFour: {
        list: {
          itemTwo: '<b>eCommerce New Vehicle Purchase Request Client Handling Checklist (NVPR)</b>',
        },
      },
    },
  },
  readinessMeeting: {
    itemTwo: {
      list: {
        itemTwo: 'Identify with whom in-Store coordination is needed',
      },
    },
    itemThree: 'Distribute the <b>eCommerce NVPR Client Handling Checklist</b> to be used as an easy reference to guide Client Handling Team as they work to honor Client requests',
    itemFour: 'Review Store CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
    itemSix: 'Once all Department Team meetings are complete, alert Management that the <b>eCommerce Readiness Plan</b> can be marked as final for eCommerce reporting <i>(this is how we will track your Store’s participation)</i>',
  },
  nonKeyDecisionMakerDisclaimer: {
    message: 'You are not assigned as an active Key Decision Maker with the ability to apply these settings for your Store. Please contact PHQ by visiting the help page on this portal to report any contact name and WSLX ID (FMCDealer login) mismatches that may have been created during initial enrolment. Active Key Decision Makers for your Store:',
  },
  resourcesPathJourneys: {
    title1: 'Client journeys',
    infoText1: 'To meet growing Client expectations and deliver a competitive advantage, Lincoln Motor Company has built an eCommerce Suite of tools:',
    infoList1: {
    },
    infoText2: 'These tools were developed with input from Lincoln Stores and operate 100% through the Lincoln Store network. The result is a convenient and guided end-to-end digital retailing solution that is paired with the value of the in-Store experience. This journey outlines the ideal eCommerce Client and Store Journey, beginning with Reservation, continuing through Order and Cart, and finishing with the Checkout process.',
    label1: 'Client',
    label2: 'Store',
    infoText4: 'Clients can Reserve an eCommerce-eligible vehicle online from their preferred Store. Reservation captures early Client interest, supports order and build prioritization, and provides Clients with a prioritized place in line.',
    infoText5: 'Client chooses their preferred Store',
    infoText6: 'Client views MSRP pricing and selects their vehicle configuration (detail available will be vehicle specific)',
    infoText7: 'Client submits their Reservation with a deposit to reserve their timestamp',
    infoP1Part1: 'Store receives the lead in their CRM (lead source:',
    infoP2: 'Store contacts assigned to receive alerts during eCommerce setup will also receive a Hot Alert Email at each client submission',
    infoText8: 'Client receives confirmation email',
    infoText9: 'Clients can configure an eCommerce-eligible vehicle online and submit an Order Request to their preferred Store. Order functionality brings early hand raisers a step closer to purchase and captures new online shoppers, providing them with the ability to place an Order Request from practically anywhere.',
    infoText10: 'Client receives an email invitation from https://www.ford.ca/ to return to complete their Order Request',
    infoText12: 'Client configures their vehicle',
    infoText13: 'Client views Store-set pricing (plan pricing capabilities available)',
    infoText14: 'Client evaluates a trade-in vehicle for an estimated value',
    infoText15: 'Client calculates and view estimated monthly payment with current available incentives',
    infoText16: 'Client reviews and submits their Order Request to their preferred Store with a deposit',
    infoP3Part1: 'Store receives the lead in their CRM (lead source:',
    infoP3Part2: ') and in the eCommerce Marketplace Portal (EMP).',
    infoP4: 'Store contacts assigned to receive alerts during eCommerce setup will also receive a Hot Alert Email at each client submission',
    infoP5: 'Store accepts in EMP to send Order to WBDO',
    infoList3: {
      item1: 'Store can edit/cancel in WBDO (until vehicle is scheduled)',
    },
    infoText17: 'Client receives confirmation email',
    infoText18: 'Clients can complete as much or as little of the purchase process as they would like online using the new Cart and Checkout experiences. Clients who have placed orders will be invited to return to continue their purchase journey online, and, later this year, Clients will be able to pull any eligible VIN from Store inventory directly into the Cart to begin their purchase.',
    infoText19: 'New Vehicle Purchase Requests will include a purchase type that can be found in the eCommerce Marketplace Portal (EMP) that indicates how much a Client completed online. This information is important to Stores as they prepare to finalize the purchase.',
  },
};
