<template>
  <AppAccordion
    :id="accordionId"
    :title="accordionTitle"
    :is-open="accordionIsOpen"
    @click="$store.dispatch('dashboard/accordions/openAccordion', accordionId)"
  >
    <div class="container--accordion-group">
      <DashboardNotification
        v-for="(notification, index) in notifications"
        :id="`notification--${index}`"
        :key="index"
        :message="$l(notification.message)"
        :status="notification.status"
        :url="notification.url"
        :accordion="notification.accordion"
        :subcordion="notification.subcordion"
      />
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import DashboardNotification from './DashboardNotification.vue';

export default {
  name: 'DashboardAccordionNotifications',
  components: {
    AppAccordion,
    DashboardNotification,
  },
  computed: {
    ...mapGetters('dashboard/accordions', {
      accordions: 'accordions',
      activeAccordionId: 'activeAccordionId',
    }),
    notifications() {
      return this.$store.getters['dashboard/notifications/notifications'];
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.accordionId;
    },
    accordionId() {
      return this.accordions.notifications.id;
    },
    accordionTitle() {
      return this.$l('dashboard.notifications.title', {
        count: this.notifications.length,
      });
    },
  },
};
</script>
