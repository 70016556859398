<template>
  <div class="route-one | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="fi-route-one-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <p>{{ $l('dashboard.fiSetup.routeOne.description') }}</p>
      </div>
      <div class="route-one__link-wrapper | col-xs-12 col-sm-5 col-lg-4">
        <StandardButton
          track-event-name="click_accessRouteOne"
          track-section-name="routeOne"
          :text="$l('dashboard.fiSetup.routeOne.buttonText')"
        />
      </div>
    </div>
    <DashboardDivider />
    <StatusBoxLabeled class="route-one__status-label">
      {{ $l('dashboard.fiSetup.routeOne.statusLabel') }}
    </StatusBoxLabeled>
    <p>{{ $l('dashboard.fiSetup.routeOne.paragraph1') }}</p>
    <form
      class="route-one__form"
      @submit.prevent
    >
      <AppDropdown
        id="route-one-dealer-id"
        :label="$l('dashboard.fiSetup.routeOne.inputLabel')"
      />
      <AppCheckbox
        v-model="userConfirmDealerId"
        id="route-one__checkbox"
        :label="$l('general.confirm')"
      />
      <StandardButton
        type="submit"
        track-event-name="submitForm_dealerId"
        track-section-name="routeOne"
        :disabled="!userConfirmDealerId"
        :text="$l('general.save')"
      />
    </form>
    <p>{{ $l('dashboard.fiSetup.routeOne.paragraph2') }}</p>
    <p>{{ $l('dashboard.fiSetup.routeOne.paragraph3') }}</p>
    <p>{{ $l('dashboard.fiSetup.routeOne.paragraph4') }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import AppDropdown from './common/AppDropdown.vue';
import AppCheckbox from './common/AppCheckbox.vue';
import DashboardDivider from './DashboardDivider.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';
import StatusBoxLabeled from './StatusBoxLabeled.vue';

export default {
  name: 'DashboardFIRouteOne',
  components: {
    AppDropdown,
    AppCheckbox,
    StandardButton,
    DashboardDivider,
    DashboardResourcesDisplay,
    StatusBoxLabeled,
  },
  data() {
    return {
      userConfirmDealerId: false,
    };
  },
  computed: {
    ...mapGetters({
      resources: 'dashboard/fiSetup/routeOneActiveResources',
    }),
  },
};
</script>

<style lang="scss" scoped>
.route-one {
  &__link-wrapper {
    display: flex;
    justify-content: center;
    @include respond(sm-up) {
      justify-content: flex-end;
    }
  }
  &__status-label {
    margin-bottom: 32px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    margin: 40px 0;
    @include respond(sm-up) {
      flex-direction: row;
      justify-content: unset;
      align-items: center;
    }
  }
}
</style>
