export default {
  title: 'Resource Center',
  experience: {
    title: 'Select by Customer Experience',
    search: 'Click here for keyword search',
    newContent: 'New Content >',
    reserve: {
      title: 'Reserve',
      bottomTitle: 'Alternate Reservation<br />Experiences',
    },
    order: {
      title: 'Order',
      bottomTitle: 'Alternate Order<br />Experiences',
    },
    cart: {
      title: 'Cart And Checkout',
      fullLine: 'Full Line (Coming Soon)',
    },
  },
  journey: {
    title: 'Customer Journey',
  },
  department: {
    title: 'Select by Department',
  },
  operationalize: {
    title: 'Operationalize eCommerce in Your Dealership',
    subtitle: 'Manager\'s toolkit',
    description: 'Three simple activities will help you operationalize eCommerce your way — within your process, with your team, to help reach your Customer Handling goals.',
    item1: 'Manager\'s Meeting',
    item2: 'Department Team Meetings',
    item3: 'eCommerce Readiness Confirmation',
    item4: 'Get Started',
  },
  menu: {
    link1: 'Select by Customer Experience',
    link2: 'Customer Journey',
    link3: ' eCommerce Marketplace Portal (EMP) Demo',
    link4: 'Operationalize eCommerce in Your Dealership',
    link5: 'Select by Department',
  },
};
