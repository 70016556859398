<template>
  <div class="fi-product | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="fi-product-document"
      :resources="resources"
    />
    <StatusBoxLabeled>
      {{ $l('dashboard.fiSetup.fiProduct.statusLabel') }}
    </StatusBoxLabeled>
    <p class="fi-product__description">
      {{ $l('dashboard.fiSetup.fiProduct.description') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';
import StatusBoxLabeled from './StatusBoxLabeled.vue';

export default {
  name: 'DashboardFIProduct',
  components: {
    DashboardResourcesDisplay,
    StatusBoxLabeled,
  },
  computed: {
    ...mapGetters({
      resources: 'dashboard/fiSetup/productActiveResources',
    }),
  },
};
</script>

<style lang="scss" scoped>
.fi-product__description {
  margin-top: 32px;
  margin-bottom: 0;
}
</style>
