import apiManager from '@/api';
import resourceCenterId from '@/data/resourceCenter';
import ResourceCenter from '@/helpers/resourceCenter';

export default {
  namespaced: true,
  state() {
    return {
      videos: [],
    };
  },
  getters: {
    videos: (state) => state.videos,
    activeVideo({ videos }, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: videos,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      }).videos[0] || {};
    },
  },
  mutations: {
    setVideos(state, videos) {
      state.videos = videos;
    },
  },
  actions: {
    async getVideo({ getters, commit, rootGetters }) {
      if (getters.videos.length) return;

      const [
        { value: { data: englishResources } },
        { value: { data: frenchResources } },
      ] = await Promise.allSettled([
        // Get english resources
        apiManager.resourceCenter.getResults({
          sectionId: resourceCenterId.section.WELCOME,
          subSectionId: resourceCenterId.subsection.WELCOME,
          language: rootGetters['locale/locales'].english,
        }),
        // Get french resources
        apiManager.resourceCenter.getResults({
          sectionId: resourceCenterId.section.WELCOME,
          subSectionId: resourceCenterId.subsection.WELCOME,
          language: rootGetters['locale/locales'].french,
        }),
      ]);

      commit('setVideos', [
        ...englishResources.result,
        ...frenchResources.result,
      ]);
    },
  },
};
