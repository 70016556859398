export default {
  experience: {
    title: 'Select by Client Experience',
  },
  journey: {
    title: 'Client Journey',
  },
  operationalize: {
    title: 'Operationalize eCommerce in Your Store',
    description: 'Three simple activities will help you operationalize eCommerce your way — within your process, with your team, to help reach your Client Handling goals.',
  },
};
