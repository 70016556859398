<template>
  <div class="member-details">
    <div class="member-details__departments">
      <div
        v-for="defaultDepartment in defaultDepartments"
        :key="defaultDepartment.id"
        class="member-details__departments-item"
      >
        <span class="member-details__departments-item-label">
          {{ $l(defaultDepartment.name) }}
          <AppTooltip :text="$l(defaultDepartment.tooltip)" />
        </span>
        <AppToggle
          v-model="member.departments[defaultDepartment.key]"
          :id="`member-details--toggle-${defaultDepartment.id}-${index}`"
          :active-text="$l('general.yes')"
          :inactive-text="$l('general.no')"
        />
      </div>
    </div>
    <form
      class="member-details__form"
      @submit.prevent="saveContact"
    >
      <div class="member-details__form-content">
        <div class="member-details__form-fields">
          <AppDropdown
            v-model="member.position"
            :id="`member-details--position-${index}`"
            :label="$l('dealerPersonnel.input.position')"
            :options="contactPositionOptions"
            :dirty="v$.member.position.$dirty"
            :error-message="v$.member.position.$errors[0]"
          />
          <TextField
            v-model.trim="member.email"
            :id="`member-details--email-${index}`"
            :label="$l('general.email')"
            :dirty="v$.member.email.$dirty"
            :error-message="v$.member.email.$errors[0]"
          />
          <AppDropdown
            v-model="member.emailLanguage"
            :id="`member-details--email-language-${index}`"
            :label="$l('general.preferredLanguage')"
            :options="emailLanguageOptions"
            :dirty="v$.member.emailLanguage.$dirty"
            :error-message="v$.member.emailLanguage.$errors[0]"
          />
          <TextField
            v-model="member.phoneNumber"
            :id="`member-details--phone-${index}`"
            mask="000-000-0000"
            :label="$l('dealerPersonnel.input.phone')"
            :dirty="v$.member.phoneNumber.$dirty"
            :error-message="v$.member.phoneNumber.$errors[0]"
          />
          <TextField
            v-model.trim="member.extension"
            :id="`member-details--extension-${index}`"
            mask="000000"
            :required="false"
            :max-length="6"
            :label="$l('dealerPersonnel.input.extension')"
          />
        </div>
        <div class="member-details__checkbox-wrapper">
          <div class="member-details__checkbox">
            <div class="member-details__checkbox-header">
              <AppCheckbox
                v-model="member.alerts.reservations"
                :id="`member-details--checkbox-reservations-${index}`"
              >
                <b
                  v-text="$l('dealerPersonnel.item.reservations.name')"
                  class="member-details__checkbox-name"
                />
              </AppCheckbox>
              <AppTooltip>
                <template #content>
                  {{ $l('dealerPersonnel.item.reservations.tooltip') }}
                  <button
                    type="button"
                    class="member-details__checkbox-button"
                    :class="$store.getters['user/activeBrandName']"
                    @click="scrollTo"
                  >
                    {{ $l('dealerPersonnel.item.tooltipButton') }}
                  </button>.
                </template>
              </AppTooltip>
            </div>
            <p
              v-text="$l('dealerPersonnel.item.reservations.label')"
              class="member-details__checkbox-description"
              :class="$store.getters['user/activeBrandName']"
            />
          </div>
          <div class="member-details__checkbox">
            <div class="member-details__checkbox-header">
              <AppCheckbox
                v-model="member.alerts.orders"
                :id="`member-details--checkbox-orders-${index}`"
              >
                <b
                  v-text="$l('dealerPersonnel.item.orders.name')"
                  class="member-details__checkbox-name"
                />
              </AppCheckbox>
              <AppTooltip>
                <template #content>
                  {{ $l('dealerPersonnel.item.orders.tooltip') }}
                  <button
                    type="button"
                    class="member-details__checkbox-button"
                    :class="$store.getters['user/activeBrandName']"
                    @click="scrollTo"
                  >
                    {{ $l('dealerPersonnel.item.tooltipButton') }}
                  </button>.
                </template>
              </AppTooltip>
            </div>
            <p
              v-text="$l('dealerPersonnel.item.orders.label')"
              class="member-details__checkbox-description"
              :class="$store.getters['user/activeBrandName']"
            />
          </div>
          <div class="member-details__checkbox">
            <div class="member-details__checkbox-header">
              <AppCheckbox
                v-model="member.alerts.cartCheckout"
                :id="`member-details--checkbox-cart-checkout-${index}`"
              >
                <b
                  v-text="$l('dealerPersonnel.item.vehicles.name')"
                  class="member-details__checkbox-name"
                />
              </AppCheckbox>
              <AppTooltip>
                <template #content>
                  {{ $l('dealerPersonnel.item.vehicles.tooltip') }}
                  <button
                    type="button"
                    class="member-details__checkbox-button"
                    :class="$store.getters['user/activeBrandName']"
                    @click="scrollTo"
                  >
                    {{ $l('dealerPersonnel.item.tooltipButton') }}
                  </button>.
                </template>
              </AppTooltip>
            </div>
            <p
              v-text="$l('dealerPersonnel.item.vehicles.label')"
              class="member-details__checkbox-description"
              :class="$store.getters['user/activeBrandName']"
            />
          </div>
        </div>
      </div>
      <div
        v-if="userIsKeyDecisionMaker"
        class="member-details__form-footer"
      >
        <StandardButton
          type="submit"
          track-event-name="submitForm_contact"
          track-section-name="teamSetup"
          :is-loading="isLoading"
          :text="$l('dealerPersonnel.item.saveButtonText')"
        />
        <StandardButton
          type="button"
          track-event-name="click_deleteContact"
          track-section-name="teamSetup"
          :is-loading="isDeletingContact"
          :text="$l('dealerPersonnel.item.deleteButtonText')"
          @click="deleteContact"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hideAllPoppers } from 'floating-vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, customMinLength } from '@/helpers/i18nValidators';
import defaultDepartments from '@/data/dealerPersonnelDepartments';
import AppToggle from './common/AppToggle.vue';
import AppTooltip from './common/AppTooltip.vue';
import AppCheckbox from './common/AppCheckbox.vue';
import AppDropdown from './common/AppDropdown.vue';
import TextField from './common/TextField.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'DashboardDealerPersonnelMemberDetails',
  components: {
    AppToggle,
    AppTooltip,
    AppCheckbox,
    AppDropdown,
    TextField,
    StandardButton,
  },
  emits: ['save-contact', 'delete-contact'],
  props: {
    index: { type: Number, required: true },
    isLoading: { type: Boolean, required: true },
    isDeletingContact: { type: Boolean, required: true },
    fullName: { type: String, default: '' },
    phoneNumber: { type: String, default: '' },
    email: { type: String, default: '' },
    position: { type: String, default: '' },
    emailLanguage: { type: String, default: '' },
    extension: { type: String, default: '' },
    departments: { type: Object, default: () => ({}) },
    alerts: { type: Object, default: () => ({}) },
  },
  setup() {
    return {
      v$: useVuelidate({ $autoDirty: true }),
    };
  },
  data() {
    return {
      topBannerMessageId: null,
      defaultDepartments,
      member: {
        position: this.position || '',
        email: this.email || '',
        emailLanguage: this.emailLanguage || '',
        phoneNumber: this.phoneNumber || '',
        extension: this.extension || '',
        departments: { ...this.departments },
        alerts: { ...this.alerts },
      },
    };
  },
  validations() {
    return {
      member: {
        position: { required },
        email: { required, email },
        emailLanguage: { required },
        phoneNumber: {
          required,
          minLength: customMinLength('validations.phoneNumber', 10),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      contactPositionOptions: 'dealerPersonnel/contactPositions',
      emailLanguageOptions: 'dealerPersonnel/emailLanguages',
    }),
    formHasChanges() {
      return this.position !== this.member.position
      || this.emailLanguage !== this.member.emailLanguage
      || this.email !== this.member.email
      || this.phoneNumber !== this.member.phoneNumber
      || this.extension !== this.member.extension
      || this.departments.accessoryPricing !== this.member.departments.accessoryPricing
      || this.departments.customerHandling !== this.member.departments.customerHandling
      || this.departments.fniSetup !== this.member.departments.fniSetup
      || this.departments.programManagement !== this.member.departments.programManagement
      || this.departments.tradeTool !== this.member.departments.tradeTool
      || this.departments.vehiclePricing !== this.member.departments.vehiclePricing
      || this.alerts.cartCheckout !== this.member.alerts.cartCheckout
      || this.alerts.orders !== this.member.alerts.orders
      || this.alerts.reservations !== this.member.alerts.reservations;
    },
  },
  watch: {
    formHasChanges() {
      this.handleFormChanges();
    },
  },
  beforeUnmount() {
    this.removeNotSavedEditMessage();
  },
  methods: {
    async saveContact() {
      if (this.isLoading || this.isDeletingContact) return;

      const formIsValid = await this.v$.$validate();

      if (!formIsValid) return;

      this.$emit('save-contact', this.member);
      this.removeNotSavedEditMessage();
    },
    deleteContact() {
      if (this.isLoading || this.isDeletingContact) return;

      this.$emit('delete-contact');
    },
    async scrollTo() {
      hideAllPoppers();

      const { customerHandling } = this.$store.getters['dashboard/accordions/accordions'];

      await this.$store.dispatch(
        'dashboard/accordions/openAccordion',
        customerHandling.id,
      );

      this.$store.dispatch(
        'dashboard/accordions/openSubcordion',
        customerHandling.reservationOrderRequests.id,
      );
    },
    async handleFormChanges() {
      if (
        this.userIsKeyDecisionMaker &&
        this.formHasChanges &&
        !this.topBannerMessageId
      ) {
        this.topBannerMessageId = await this.$store.dispatch(
          'topBanner/addNotSavedEdit',
          `${this.$l('dealerInformation.section2Title')} - ${this.fullName}`,
        );
      }
    },
    removeNotSavedEditMessage() {
      if (this.topBannerMessageId) {
        this.$store.dispatch('topBanner/removeNotSavedEdit', this.topBannerMessageId);
        this.topBannerMessageId = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.member-details {
  &__departments {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
  }
  &__departments-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 170px;
    height: 120px;
  }
  &__departments-item-label {
    margin-bottom: 12px;
  }
  &__checkbox-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &__checkbox {
    width: 100%;
  }
  &__checkbox-header {
    display: flex;
    gap: 8px;
  }
  &__checkbox-button {
    padding: 0;
    background-color: transparent;
    border: 0;
    text-transform: uppercase;
    text-decoration: underline;
    &.lincoln {
      --hover-color: #{$color--lds-secondary};
    }
    &:hover {
      color: var(--hover-color, #{$color--fds-secondary});
    }
  }
  &__checkbox-description {
    margin: 0;
    padding-left: 32px;
    &.ford {
      color: $color--fds-primary;
      font-weight: $font-weight--normal;
    }
  }
  &__form {
    margin-top: 32px;
  }
  &__form-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 40px;
    @include respond(sm-up) {
      flex-direction: row;
      gap: 48px;
    }
  }
  &__form-fields {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    @include respond(sm-up) {
      max-width: 380px;
    }
  }
  &__form-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
}
</style>
