<template>
  <AppDialog
    v-bind="$attrs"
    size="md"
    @close="$emit('close')"
  >
    <p>{{ subtitle }}</p>
    <p>{{ description }}</p>
    <p>{{ $l('activationSummary.deactivate.date') }}</p>
    <p class="opt-out__deactivation-date">
      {{ deactivationDate }}
    </p>
    <p class="mb-0">
      {{ $l('activationSummary.deactivate.cancel') }}
    </p>
    <div class="opt-out__buttons">
      <StandardButton
        size="sm"
        :text="$l('general.cancel')"
        @click="$emit('close')"
      />
      <StandardButton
        size="sm"
        :text="$l('general.accept')"
        :is-loading="isLoading"
        @click="$emit('accept')"
      />
    </div>
  </AppDialog>
</template>

<script>
import { format, addDays } from 'date-fns';
import { mapGetters } from 'vuex';
import { DAYS_UNTIL_DEACTIVATION } from '@/constants/activationSummary';
import AppDialog from './common/AppDialog.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'ActivationSummaryModalOptOut',
  components: {
    AppDialog,
    StandardButton,
  },
  emits: ['close', 'accept'],
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      dateFormatPattern: 'locale/dateFormatPattern',
    }),
    deactivationDate() {
      return format(addDays(new Date(), DAYS_UNTIL_DEACTIVATION), this.dateFormatPattern);
    },
  },
};
</script>

<style lang="scss" scoped>
.opt-out {
  &__deactivation-date {
    color: $color--fds-error1;
  }
  &__buttons {
    display: flex;
    gap: 32px;
    margin-top: 40px;
  }
}
</style>
