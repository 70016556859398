<template>
  <section class="stripe-fee-setup">
    <SubsectionHeader>{{ $l('stripe.feeSetup.title') }}</SubsectionHeader>
    <p>{{ paragraphOne }}</p>
    <p class="mb-0">
      {{ $l('stripe.feeSetup.paragraphTwo') }}
    </p>
    <PrimaryContact />
    <FeeSetupCard />
  </section>
</template>

<script>
import SubsectionHeader from './StripeSubsectionHeader.vue';
import PrimaryContact from './StripeFeeSetupPrimaryContact.vue';
import FeeSetupCard from './StripeFeeSetupCard.vue';

export default {
  name: 'StripeFeeSetup',
  components: {
    SubsectionHeader,
    PrimaryContact,
    FeeSetupCard,
  },
  computed: {
    paragraphOne() {
      if (this.$store.getters['stripe/aplicationStatus'].active) {
        return this.$l('stripe.feeSetup.paragraphOneActive');
      }
      return this.$l('stripe.feeSetup.paragraphOne');
    },
  },
};
</script>
