/* eslint-disable */

export const experiences = {
  BLUE: 'blue',
  MODEL_E: 'model-e',
};
export const programId = {
  BLUE: 1,
  MODEL_E: 2,
};

export const certificationLevels = {
  BLUE_ONLY: '',
  MODEL_E: 'Certified',
  MODEL_E_ELITE: 'Certified Elite',
};