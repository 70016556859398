<template>
  <div class="comms-center">
    <PageHeader :title="$l('commsCenter.title')" />
    <LoadingIndicator v-if="isLoading" />
    <div
      v-else
      class="comms-center__content"
    >
      <h3 class="comms-center__title">
        {{ $l('commsCenter.subtitle') }}
      </h3>
      <AppAccordion
        v-for="section in sections"
        :id="section.id"
        :key="section.id"
        :title="section.title"
        :is-important="section.alert"
        :is-open="activeAccordionId === section.id"
        @click="openAccordion(section)"
      >
        <p
          v-if="!section.documents.length"
          class="comms-center__no-documents"
        >
          {{ $l('commsCenter.noDocuments') }}
        </p>
        <DocumentList :documents="section.documents" />
      </AppAccordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from '@/components/common/AppAccordion.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PageHeader from '@/components/PageHeader.vue';
import DocumentList from '@/components/CommsCenterDocumentList.vue';

export default {
  name: 'CommsCenterView',
  components: {
    LoadingIndicator,
    AppAccordion,
    PageHeader,
    DocumentList,
  },
  data() {
    return {
      activeAccordionId: null,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      isLoading: 'commsCenter/isLoadingData',
      sections: 'commsCenter/sections',
    }),
  },
  methods: {
    openAccordion(section) {
      if (this.activeAccordionId === section.id) {
        this.activeAccordionId = null;
        return;
      }

      this.activeAccordionId = section.id;

      if (section.alert) {
        this.$store.dispatch('commsCenter/setSectionAsViewed', section.subSectionID);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comms-center {
  &__spinner {
    text-align: center;
  }
  &__content {
    max-width: 1340px;
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: $font-weight--light;
  }
  &__no-documents {
    display: grid;
    place-items: center;
    min-height: 150px;
    margin: 0;
    font-size: 24px;
  }
}
</style>
