import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  state() {
    return {
      jones: [],
    };
  },
  getters: {
    jones: (state) => state.jones,
    entitlements: (state) => state.jones.length > 0,
    sectionId(state, getters, rootState, rootGetters) {
      return rootGetters['user/activeBrandIsFord']
        ? resourceCenterId.section.CUSTOMER_HANDLING
        : resourceCenterId.section.CLIENT_HANDLING;
    },
    resources(state, getters, rootState, rootGetters) {
      const subsectionId = rootGetters['user/activeBrandIsFord']
        ? resourceCenterId.section.RESERVATIONS_JOB_AID
        : resourceCenterId.section.NEW_VEHICLE_PURCHASE_REQUESTS;

      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        getters.sectionId,
        subsectionId,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
    orderResources(state, getters, rootState, rootGetters) {
      const subsectionId = rootGetters['user/activeBrandIsFord']
        ? resourceCenterId.section.ORDER_JOB_AID : '';

      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        getters.sectionId,
        subsectionId,
      );
    },
    activeOrderResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.orderResources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
    cartCheckoutResources(state, getters, rootState, rootGetters) {
      const subsectionId = rootGetters['user/activeBrandIsFord']
        ? resourceCenterId.section.ECOMMERCE_CART_AND_CHECKOUT_JOB_AID : '';

      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        getters.sectionId,
        subsectionId,
      );
    },
    activeCartCheckoutResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.cartCheckoutResources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
};
