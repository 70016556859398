import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  state() {
    return {
      documents: [],
      managerMeetingDocuments: [],
      departmentMetingDocuments: [],
    };
  },
  getters: {
    managerMeetingDocuments: (state) => state.managerMeetingDocuments,
    departmentMetingDocuments: (state) => state.departmentMetingDocuments,
    activeManagerMeetingDocuments(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.managerMeetingDocuments,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
    activeDepartmentMetingDocuments(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.departmentMetingDocuments,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
  mutations: {
    setManagerMeetingDocuments(state, documents) {
      state.managerMeetingDocuments = documents;
    },
    setDepartmentMetingDocuments(state, documents) {
      state.departmentMetingDocuments = documents;
    },
  },
  actions: {
    async getDocuments({ rootGetters, commit }) {
      // Get english documents
      const { data: englishDocuments } = await apiManager.resourceCenter.getResults({
        experience: 7,
        language: rootGetters['locale/locales'].english,
      });

      // Get french documents
      const { data: frenchDocuments } = await apiManager.resourceCenter.getResults({
        experience: 7,
        language: rootGetters['locale/locales'].french,
      });

      const documents = [
        ...englishDocuments.result,
        ...frenchDocuments.result,
      ];
      const subsectionId = rootGetters['user/activeDealership'].pa === 'FORDC'
        ? resourceCenterId.subsection.FIELD_TAB
        : resourceCenterId.subsection.DEALER_TAB;

      commit('setManagerMeetingDocuments', ResourceCenter.findSectionResources(
        documents,
        resourceCenterId.section.READINESS_TAB1,
        subsectionId,
      ));

      commit('setDepartmentMetingDocuments', ResourceCenter.findSectionResources(
        documents,
        resourceCenterId.section.READINESS_TAB2,
        subsectionId,
      ));
    },
  },
};
