<template>
  <button
    type="button"
    class="tab"
    :class="[{ 'tab--active': isActive }, $store.getters['user/activeBrandName']]"
    @click="$emit('click')"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'AppTab',
  emits: ['click'],
  props: {
    text: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  --text-color: #{$color--fds-primary};
  --spacing: 16px;
  --border-color: transparent;
  --border-width: 1px;
  --outline-color: #{$color--fds-primary};
  &.ford {
    &:hover:not(.tab--active) {
      --text-color: #{$color--fds-secondary};
      --border-color: #{$color--fds-primary};
    }
    &.tab--active,
    &:focus-visible {
      --border-color: #{$color--fds-primary};
      --border-width: 3px;
    }
  }
  &.lincoln {
    --text-color: #{$color--lds-primary};
    --spacing: 20px;
    --border-color: #{$color--lds-gray2};
    --outline-color: #{$color--lds-primary};
    &:focus-visible,
    &.tab--active {
      --border-color: #{$color--lds-secondary};
      --border-width: 2px;
    }
  }

  margin: 0;
  padding: 0 var(--spacing) 10px var(--spacing);
  border: 0;
  color: var(--text-color);
  background-color: transparent;
  font-weight: $font-weight--normal;
  transition: color 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  outline: none;
  &::before {
    content: '';
    height: var(--border-width);
    background-color: var(--border-color);
    border-radius: 8px;
    position: absolute;
    bottom: calc(var(--border-width) * -1);
    left: 0;
    right: 0;
    transition: background-color 0.3s ease-in-out;
  }
  &:focus-visible::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: 1px solid;
    outline-offset: 4px;
    outline-color: var(--outline-color);
    z-index: 1;
  }
}
</style>
