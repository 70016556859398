export default {
  description: 'Expérience(s) actuellement offertes aux fins de sélection.',
  blue: {
    title: 'Ford Blue',
    subtitle: 'Expérience de commerce électronique',
    description: 'Parce que vous êtes un concessionnaire Ford Blue, votre inscription au commerce électronique permet à vos invités de réserver, de commander ou d’acheter leur véhicule en ligne. Elle permet aussi à votre concessionnaire de recevoir directement les paiements numériques.',
  },
  modelE: {
    title: '[NT] Ford Model e Certified',
    titleElite: 'Certifié Élite Model e Ford',
    subtitle: '[NT] eCommerce Experience',
    subtitleElite: 'Votre concessionnaire est certifié Élite Model e',
    subtitleElite2: '[NT] Elite',
    description: 'Grâce au commerce électronique, vos invités peuvent demander un accès avancé et soumettre une demande d’achat ou de location de leur véhicule électrique en ligne avec l’aide d’un concessionnaire Model e. Le commerce électronique vous permet aussi de répondre à une ou plusieurs normes du programme relatif aux véhicules électriques Model e. Comme il est mentionné dans les normes du programme, l’invité VE d’aujourd’hui recherche et exige une expérience de vente et d’après vente pratique, efficace, informative et transparente. Cette expérience doit également être facile à naviguer. Ces normes ont été conçues et élaborées selon le principe de l’invité d’abord, de manière à ce que les invités bénéficient d’une expérience d’achat et de propriétaire inégalée.',
  },
};
