export default {
  resourceCenter: {
    title: 'Centre de ressources',
    subtitle: 'En savoir plus',
    description: 'Voici où vous trouverez les outils de commerce électronique Model e. En explorant, vous découvrirez tout ce dont vous avez besoin pour soutenir votre mise en œuvre, ainsi que des ressources qui vous aideront à offrir la meilleure expérience possible à vos invités qui utilisent le commerce électronique.',
  },
  dashboard: {
    title: 'Tableau de bord',
    subtitle: 'Inscription, mise en œuvre et continuité',
    description: 'Il s’agit de votre guichet unique pour la mise en œuvre et la continuité de l’expérience de commerce électronique Model e. Ici vous pouvez :',
    text1: 'Inscrire et mettre en œuvre',
    text2: 'Désigner et mettre à jour l’équipe de votre concessionnaire',
    text3: 'Modifier les paramètres de votre concessionnaire',
    text4: 'Demander du soutien',
  },
};
