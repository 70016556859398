<template>
  <FloatingButton
    v-bind="$attrs"
    :show="show"
    :text=" $l('general.scrollToTop')"
    :icon="activeBrandIsFord
      ? 'chevron-up'
      : 'long-arrow-up'"
    @click="scrollUp"
  />
</template>
<script>
import FloatingButton from './FloatingButton.vue';

export default {
  name: 'ScrollToTopButton',
  components: { FloatingButton },
  emits: ['scroll-to-top'],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    activeBrandIsFord() {
      return this.$store.getters['user/activeBrandIsFord'];
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.show = document.body.scrollTop > 300
        || document.documentElement.scrollTop > 300;
    });
  },
  methods: {
    scrollUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      this.show = false;

      this.$emit('scroll-to-top');
    },
  },
};
</script>
