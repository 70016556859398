<template>
  <DealerProfileTable :title="$l('dealerProfile.cartAndCheckoutAdvanced.title')">
    <template #colgroup>
      <col />
      <col style="width: 200px;" />
    </template>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutAdvanced.titleRow3') }}</td>
      <td>
        <StatusBullet :status="profileInfo.remoteDelivery" />
      </td>
    </tr>
  </DealerProfileTable>
</template>

<script>
import DealerProfileTable from './DealerProfileTable.vue';
import StatusBullet from './DealerProfileStatusBullet.vue';

export default {
  name: 'DealerProfileTableCartCheckoutAdvanced',
  components: {
    DealerProfileTable,
    StatusBullet,
  },
  props: {
    profileInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>
