<template>
  <hr class="dashboard-divider" />
</template>

<script>
export default {
  name: 'DashboardDivider',
};
</script>

<style lang="scss" scoped>
.dashboard-divider {
  margin: 50px 0;
  background-color: #b8b8b8;
}
</style>
