export default class CookieManager {
  static delete(name) {
    this.set(name, '', -1);
  }

  static get(name) {
    let regex = '(^|;) ?';
    regex += name;
    regex += '=([^;]*)(;|$)';
    const val = document.cookie.match(regex);
    return val ? JSON.parse(val[2]) : null;
  }

  static set(name, value, expires = null) {
    let dateExpires = null;
    let cookieVal = {};
    let cookie = name;
    const exists = this.get(cookie);
    if (exists) {
      cookieVal = exists;
    }
    if (!cookieVal.active) {
      cookieVal.active = value.active;
    }
    if (!cookieVal.expires) {
      cookieVal.expires = value.expires;
    }
    if (!cookieVal.sessionId) {
      cookieVal.sessionId = value.sessionId;
    }
    if (!cookieVal.token) {
      cookieVal.token = value.token;
    }
    cookie += '=';
    cookie += JSON.stringify(cookieVal);
    cookie += ';path=/;expires=';
    if (!expires) {
      dateExpires = new Date();
      dateExpires.setTime(dateExpires.getTime() + 10 * 60 * 60 * 1000);
    } else {
      dateExpires = new Date(expires);
    }
    cookie += dateExpires.toUTCString();
    cookie += ';SameSite=Strict;';
    document.cookie = cookie;
  }
}
