<template>
  <div class="video-card">
    <p class="video-card__title">
      {{ title }}
    </p>
    <button
      class="video-card__thumbnail-wrapper"
      @click="toggleVideoModal"
    >
      <FontAwesomeIcon
        v-if="src"
        class="video-card__play-button"
        size="4x"
        :class="$store.getters['user/activeBrandName']"
        :icon="playIcon"
      />
      <img
        class="video-card__thumbnail"
        alt="Video thumbnail"
        :src="src ? require(`@/assets/images/${src}`) : thumbnail"
      />
    </button>
    <button
      v-if="transcriptUrl"
      v-text="$l('videoCard.link1')"
      class="video-card__transcript-button"
      type="button"
      :class="$store.getters['user/activeBrandName']"
      @click="openDocumentModal"
    />
    <p
      v-if="shortDescription"
      class="video-card__description"
    >
      {{ shortDescription }}
    </p>
    <VideoModal
      v-if="showVideoModal"
      :title="title"
      :video-src="videoSrc"
      @close="toggleVideoModal"
    />
  </div>
</template>

<script>
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import VideoModal from './VideoModal.vue';

export default {
  name: 'VideoCard',
  components: {
    VideoModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    videoId: {
      type: String,
      default: '',
    },
    videoSrc: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    transcriptUrl: {
      type: String,
      default: '',
    },
    shortDescription: {
      type: String,
      default: '',
    },
    parentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      playIcon: faYoutube,
      showVideoModal: false,
    };
  },
  methods: {
    toggleVideoModal() {
      this.showVideoModal = !this.showVideoModal;
    },
    openDocumentModal() {
      this.$store.dispatch('documentModal/open', {
        fileHref: this.transcriptUrl,
        title: this.title,
      });

      this.track('transcript');
    },
  },
};
</script>

<style lang="scss" scoped>
.video-card {
  max-width: 400px;
  &__title {
    min-height: 36px;
    margin-bottom: 4px;
  }
  &__thumbnail-wrapper,
  &__transcript-button {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    outline-offset: 5px;
  }
  &__thumbnail-wrapper {
    display: grid;
    place-items: center;
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.45);
    position: relative;
  }
  &__thumbnail {
    width: 100%;
    height: auto;
    max-height: 238px;
  }
  &__play-button {
    position: absolute;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
    &.ford {
      color: $color--fds-primary;
      &:hover {
        color: $color--fds-secondary;
      }
    }
    &.lincoln {
      color: $color--lds-primary;
      &:hover {
        color: $color--lds-secondary;
      }
    }
  }
  &__transcript-button {
    margin-top: 5px;
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: 0.68px;
    &:hover {
      text-decoration: underline;
    }
    &.ford {
      color: $color--fds-primary;
      &::after {
        font-family: $font-family--icons;
        content: $app-icon-right-caret-circle;
        margin-left: 10px;
      }
      &:hover {
        text-decoration: underline;
        color: $color--fds-secondary;
      }
    }
    &.lincoln {
      color: $color--lds-primary;
      border-bottom: 2px solid transparent;
      height: 30px;
      &::after {
        font-family: $font-family--icons;
        content: $app-icon-arrow-forward;
        margin-left: 0px;
        font-size: 2rem;
        position: relative;
        top: 4px;
      }
      &:hover {
        color: $color--lds-primary;
        text-decoration: none;
        border-bottom: 2px solid $color--lds-secondary;
      }
    }
  }
}
</style>
