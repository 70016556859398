import BaseAPI from './baseAPI';

export default class AccessoryPricing extends BaseAPI {
  async getEntitlements() {
    const { data } = await this.axios.get('/Dashboard/AccessoryPricing/Entitlements');
    return data;
  }

  async getModelEEntitlements() {
    const { data } = await this.axios.get('/DashboardModelE/AccessoryPricingModelE/EntitlementsModelE');
    return data;
  }

}
