<template>
  <AppDialog
    :open="modalIsOpen"
    :title="modal.title"
    @close="closeModal"
  >
    <div class="iframe-container">
      <iframe
        class="iframe"
        :src="modal.fileHref"
      />
    </div>
    <template #footer>
      <StandardButton
        track-section-name="documentModal"
        :track-event-name="`${Event.DOWNLOAD_DOCUMENT}-${modal.fileName}`"
        :text="$l('general.download')"
        :is-loading="isLoading"
        @click="downloadDocument"
      />
      <a
        class="share-link"
        target="_blank"
        :class="$store.getters['user/activeBrandName']"
        :href="mailTo"
        @click="trackShareByEmail"
      >
        <i class="share-link__icon app-icon-email" />
        <span>{{ $l('modalFrame.shareByEmail') }}</span>
      </a>
    </template>
  </AppDialog>
</template>

<script>
import axios from 'axios';
import { ElementType, Event } from '@/constants/track';
import AppDialog from './common/AppDialog.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'DocumentModal',
  components: {
    AppDialog,
    StandardButton,
  },
  data() {
    return {
      Event,
      isLoading: false,
    };
  },
  computed: {
    modal() {
      return this.$store.getters['documentModal/modal'];
    },
    modalIsOpen() {
      return !!this.modal.fileHref;
    },
    mailTo() {
      const { firstName, lastName } = this.$store.getters['user/userProfile'];
      const documentPath = `${window.location.origin}${process.env.VUE_APP_BASE_URL}resources/static/documents/${this.modal.fileName}`;
      const documentTitle = this.modal.title;

      const subject = this.$l('modalFrame.emailSubject', { firstName, lastName });

      const body = `${this.$l('modalFrame.emailBody.line1')}

        ${this.$l('modalFrame.emailBody.line2')}

        ${documentTitle}
        ${documentPath}
      `;

      return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    },
  },
  watch: {
    modalIsOpen() {
      if (this.modalIsOpen) {
        this.$store.dispatch('track/event', {
          event: `${Event.OPEN_DOCUMENT}-${this.modal.fileName}`,
          section: 'documentModal',
          elementType: ElementType.MODAL,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
  methods: {
    trackShareByEmail() {
      this.$store.dispatch('track/event', {
        event: `${Event.SHARE_DOCUMENT_BY_EMAIL}-${this.modal.fileName}`,
        section: 'documentModal',
        elementType: ElementType.HYPERLINK,
        pageName: this.$route.meta.track.pageName,
      });
    },
    async downloadDocument() {
      const response = await axios.get(this.modal.fileHref, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type, endings: 'native' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = this.modal.fileName;
      link.click();
    },
    closeModal() {
      this.$store.dispatch('documentModal/close');
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe-container {
  width: 100%;
  min-height: 57vh;
  position: relative;
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }
}
.share-link {
  align-self: center;
  &.ford {
    color: $color--fds-primary;
  }
  &.lincoln {
    color: $color--lds-secondary;
  }
  &__icon {
    font-size: 20px;
    margin-right: 10px;
  }
}
</style>
