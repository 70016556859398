<template>
  <DealerProfileTable :title="$l('dealerProfile.cartAndCheckoutActivation.title')">
    <template #colgroup>
      <col />
      <col style="width: 200px;" />
    </template>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow1') }}</td>
      <td>
        <StatusBullet :status="cartCheckoutInfo.enrollment" />
      </td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow2') }}</td>
      <td>
        <StatusBullet :status="cartCheckoutInfo.stripeAccount" />
      </td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow3') }}</td>
      <td>
        <StatusBullet :status="cartCheckoutInfo.vehiclePricing" />
      </td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow4') }}</td>
      <td>
        <StatusBullet :status="cartCheckoutInfo.accessoryPricing" />
      </td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow5') }}</td>
      <td>
        <StatusBullet :status="cartCheckoutInfo.tradeInSsetup" />
      </td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow6') }}</td>
      <td>
        <!-- <StatusBullet :status="cartCheckoutInfo.fandISetup" /> -->
        <StatusBullet status="comingsoon" />
      </td>
    </tr>
    <tr>
      <td>{{ rowText }}</td>
      <td>
        <!-- <StatusBullet :status="cartCheckoutInfo.customerHandling" /> -->
        <StatusBullet status="comingsoon" />
      </td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow8') }}</td>
      <td>
        <StatusBullet :status="cartCheckoutInfo.activationReadiness" />
      </td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.cartAndCheckoutActivation.titleRow9') }}</td>
      <td>
        <StatusBullet :status="cartCheckoutInfo.experienceStatus" />
      </td>
    </tr>
  </DealerProfileTable>
</template>

<script>
import DealerProfileTable from './DealerProfileTable.vue';
import StatusBullet from './DealerProfileStatusBullet.vue';

export default {
  name: 'DealerProfileTableCartCheckoutActivation',
  components: {
    DealerProfileTable,
    StatusBullet,
  },
  props: {
    cartCheckoutInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rowText() {
      const path = this.$store.getters['user/activeBrandName'];
      return this.$l(`dealerProfile.cartAndCheckoutActivation.titleRow7.${path}`);
    },
  },
};
</script>
