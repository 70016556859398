<template>
  <div>
    <ActivationSummaryModalOptOut
      :title="$l('activationSummary.reservation.optOut.title')"
      :subtitle="$l('activationSummary.reservation.optOut.subtitle')"
      :description="$l('activationSummary.reservation.optOut.description')"
      :open="showModalOptOut"
      :is-loading="isDeactivatingExperience"
      @close="showModalOptOut = false"
      @accept="deactivateExperience"
    />
    <ActivationSummaryModal
      :title="$l('activationSummary.reservation.cancelOptOut.title')"
      :subtitle="$l('activationSummary.reservation.cancelOptOut.subtitle')"
      :description="$l('activationSummary.reservation.cancelOptOut.description')"
      :open="showModalCancelOptOut"
      @close="showModalCancelOptOut = false"
    />
    <ActivationSummaryModal
      :title="$l('activationSummary.reservation.reactivate.title')"
      :subtitle="$l('activationSummary.reservation.reactivate.subtitle')"
      :description="$l('activationSummary.reservation.reactivate.description')"
      :open="showModalReactivate"
      @close="showModalReactivate = false"
    />
    <ActivationSummaryCard
      :title="$l('activationSummary.reservation.title')"
      :deactivation-date="reservation.deactivation.endDate"
    >
      <ActivationSummaryControl
        :status="reservation.status"
        :percentage-complete="reservation.percentageComplete"
        @deactivate-experience="showModalOptOut = true"
        @cancel-opt-out="cancelOptOut"
        @activate-experience="reactivateExperience"
      />
      <template #list>
        <ActivationSummaryItem
          :label="$l('activationSummary.reservation.item1')"
          :status="reservation.enrollmentStatus"
          :accordion-id="accordions.enrollment.id"
        />
        <ActivationSummaryItem
          :label="$l('activationSummary.reservation.item2')"
          :status="reservation.stripeSetupStatus"
          :disabled="!activeDealership.enrolled"
          :accordion-id="accordions.stripeAccountSetup.id"
          :subcordion-id="accordions.stripeAccountSetup.feeStripeAccount.id"
        />
      </template>
    </ActivationSummaryCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElementType, Event } from '@/constants/track';
import { experienceId } from '@/constants/activationSummary';
import ActivationSummaryItem from './ActivationSummaryItem.vue';
import ActivationSummaryCard from './ActivationSummaryCard.vue';
import ActivationSummaryControl from './ActivationSummaryControl.vue';
import ActivationSummaryModal from './ActivationSummaryModal.vue';
import ActivationSummaryModalOptOut from './ActivationSummaryModalOptOut.vue';

export default {
  name: 'ActivationSummaryReservation',
  components: {
    ActivationSummaryItem,
    ActivationSummaryCard,
    ActivationSummaryControl,
    ActivationSummaryModal,
    ActivationSummaryModalOptOut,
  },
  data() {
    return {
      showModalOptOut: false,
      showModalCancelOptOut: false,
      showModalReactivate: false,
      isDeactivatingExperience: false,
      isCancelingOptOut: false,
      isReactivatingExperience: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      reservation: 'activationSummary/reservation',
      accordions: 'dashboard/accordions/accordions',
    }),
  },
  watch: {
    showModalOptOut() {
      if (this.showModalOptOut) {
        this.$store.dispatch('track/event', {
          event: Event.OPEN_OPT_OUT_MODAL,
          section: 'reservation',
          elementType: ElementType.MODAL,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
  methods: {
    async deactivateExperience() {
      this.isDeactivatingExperience = true;

      this.$store.dispatch('track/event', {
        event: Event.DEACTIVATE_EXPERIENCE,
        section: 'reservation',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/deactivateExperience', {
          experienceId: experienceId.RESERVATION,
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.deactivate.errorMessage'),
        });
      } finally {
        this.showModalOptOut = false;
        this.isDeactivatingExperience = false;
      }
    },
    async cancelOptOut() {
      if (this.isCancelingOptOut) return;

      this.isCancelingOptOut = true;

      this.$store.dispatch('track/event', {
        event: Event.CANCEL_OPT_OUT,
        section: 'reservation',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/cancelExperienceOptOut', {
          experienceId: experienceId.RESERVATION,
        });

        this.showModalCancelOptOut = true;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.cancelOptOut.errorMessage'),
        });
      } finally {
        this.isCancelingOptOut = false;
      }
    },
    async reactivateExperience() {
      if (this.isReactivatingExperience) return;

      this.isReactivatingExperience = true;

      this.$store.dispatch('track/event', {
        event: Event.REACTIVATE_EXPERIENCE,
        section: 'reservation',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/reactivateExperience', {
          experienceId: experienceId.RESERVATION,
        });

        this.showModalReactivate = true;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.reactivate.errorMessage'),
        });
      } finally {
        this.isReactivatingExperience = false;
      }
    },
  },
};
</script>
