<template>
  <section
    id="customer-journey"
    class="customer-journey"
  >
    <h2 class="customer-journey__title | fds-headline__5">
      {{ $l('resourceJourney.title') }}
    </h2>
    <div class="row">
      <div class="customer-journey__content col-xs-12 col-lg-11">
        <img
          class="customer-journey__image"
          src="@/assets/images/journey.jpg"
          alt="Customer Journey"
        />
        <h3 class="customer-journey__message">
          {{ $l('general.comingSoon') }}
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ModelEResourceCenterJourney',
};
</script>

<style lang="scss">
.customer-journey {
  .customer-journey__title {
    margin: 0;
    margin-bottom: 40px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include respond(sm-up) {
      flex-direction: row;
    }
  }
  &__image {
    border: 1px solid rgba($color--black, 0.6);
    width: 100%;
    max-width: 350px;
  }
  &__message {
    margin: auto;
    color: $color--fds-primary;
  }
}
</style>
