<template>
  <transition
    mode="out-in"
    enter-active-class="scale-in-center"
    leave-active-class="scale-out-center"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionScaleCenter',
};
</script>

<style lang="scss" scoped>
.scale-in-center {
  animation: scale-in-center 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-out-center {
  animation: scale-out-center 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-out-center {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 1;
  }
}
</style>
