<template>
  <ul class="document-list">
    <li
      v-for="(document, index) in formatedDocumentList"
      :key="index"
      class="document-list__item"
    >
      <div class="document-list__item-date">
        <span>{{ document.publishedDate }}</span>
      </div>
      <DocumentDetailsPopUpTip
        :title="document.title"
        :description="document.shortdesc"
      >
        <TextButton
          :text="document.title"
          @click="openDocumentModal(document)"
        />
      </DocumentDetailsPopUpTip>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import TextButton from './common/TextButton.vue';
import DocumentDetailsPopUpTip from './DocumentDetailsPopUpTip.vue';

export default {
  name: 'CommsCenterDocumentList',
  components: {
    TextButton,
    DocumentDetailsPopUpTip,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      dateFormatPattern: 'locale/dateFormatPattern',
      documentPath: 'dashboard/documentPath',
    }),
    formatedDocumentList() {
      return this.documents.map((document) => ({
        ...document,
        publishedDate: format(new Date(document.publishedDate), this.dateFormatPattern),
      }));
    },
  },
  methods: {
    openDocumentModal(document) {
      this.$store.dispatch('documentModal/open', {
        fileHref: `${this.documentPath}/${document.document}`,
        title: document.title,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.document-list {
  margin: 0;
  padding: 0;
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
  }
  &__item-date {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid $color--lds-disabled2;
  }
}
</style>
