export default {
  namespaced: true,
  state() {
    return {
      applicant: ['RICHARD WOOLWINE'],
    };
  },
  getters: {
    primaryApplicants: (state) => state.applicant,
  },
};
