<template>
  <header class="stripe-subsection-header">
    <h3 class="stripe-subsection-header__title">
      <slot />
    </h3>
  </header>
</template>

<script>
export default {
  name: 'StripeSubsectionHeader',
};
</script>

<style lang="scss" scoped>
.stripe-subsection-header {
  margin-bottom: 30px;
  &__title {
    margin: 0;
    font-weight: $font-weight--light;
  }
}
</style>
