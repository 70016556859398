export default {
  subtitle: 'Complete setup to enable Clients to submit deposits to your Store from https://www.lincolncanada.com/',
  accountSetup: {
    descriptionTop: {
      notStarted: `<p>To get started on your Stripe account application, click on the ‘Stripe Application’ button.</p>
      <p>Once your Store's account is active, the button will update to ‘Access Stripe,’ which will take you to your Stripe dashboard.</p>`,
    },
  },
  feeSetup: {
    paragraphOneActive: 'Your Store’s Stripe Account ID is displayed below. To confirm it matches the Stripe Account ID provided in the confirmation email, check ‘Confirm’ and click ‘Save’.',
    paragraphTwo: 'Click the accordion to set parameters for funds accepted by your Store in eCommerce. The ‘Reservation Deposit’ and ‘Non-LAFS Lincoln Checkout Deposit’ are both set by Lincoln and can’t be edited. The ‘Order Deposit’ and ‘Max Amount Accepted on Credit Card’ have dropdown menus with prepopulated amounts that can be selected, confirmed and saved.',
    card: {
      reservation: {
        description: 'Set up by Lincoln of Canada',
      },
      cartCheckout: {
        input: 'Non-LAFS Lincoln Checkout Deposit',
      },
    },
  },
  application: {
    message: 'You’ve taken an important step in setting up your Store’s Stripe account. You can return to the \'Stripe Account Setup\' page at any time to check your application status. Once your Stripe Account is approved, your status will update to ‘Active,’ and you’ll receive an email from eCommerce Program Headquarters with your dealership’s Stripe Account ID. At that time, you’ll confirm your Stripe Account ID, and set up your Store’s parameters for funds accepted from eCommerce Clients on the \'Stripe Account Setup\' page.',
  },
};
