<template>
  <AppDialog
    v-bind="$attrs"
    size="md"
  >
    <p>{{ subtitle }}</p>
    <p>{{ description }}</p>
  </AppDialog>
</template>

<script>
import AppDialog from './common/AppDialog.vue';

export default {
  name: 'ActivationSummaryModal',
  components: {
    AppDialog,
  },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
