<template>
  <div class="customer-activity | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="customer-activity-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-md-8">
        <p>{{ $l('modelEDashboard.customer.activity.description') }}</p>
      </div>
      <div class="customer-activity__button-wrapper | col-md-4">
        <StandardButton
          to="https://dashboard.dealerconnection.com/"
          track-event-name="click_enhancedDealerDashboard"
          track-section-name="customerActivity"
          :text="$l('modelEDashboard.customer.activity.button')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEDashboardCustomerActivity',
  components: {
    StandardButton,
    DashboardResourcesDisplay,
  },
  computed: {
    ...mapGetters({
      resources: 'modelE/dashboard/customerHandling/modelECustomerActivityActiveResources',
    }),
  },
};
</script>

<style lang="scss" scoped>
.customer-activity {
  &__button-wrapper {
    margin-top: 24px;
    @include respond(md-up) {
      margin-top: 0;
      text-align: right;
    }
  }
}
</style>
