<template>
  <ul class="breadcrumbs">
    <li
      v-for="breadcrumb in breadcrumbs"
      :key="breadcrumb.id"
      class="breadcrumbs__item"
    >
      <button
        v-text="breadcrumb.title"
        class="breadcrumbs__button"
        type="button"
        @click="$emit('set-active-category', { level: breadcrumb.level, categoryId: breadcrumb.id })"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FAQsBreadcrumb',
  emits: ['set-active-category'],
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  &__item {
    display: flex;
    align-items: center;
    &:not(:last-child)::after {
      font-family: $font-family--icons;
      content: $app-icon-chevron-right;
      font-size: 12px;
      margin: 0 5px;
    }
  }
  &__button {
    background: transparent;
    border: 0;
    padding: 0;
    outline-offset: 3px;
    color: $color--fds-secondary;
  }
}
.lincoln .breadcrumbs {
  &__item {
    display: flex;
    align-items: center;
  }
  &__button {
    color: $color--lds-secondary;
  }
}
</style>
