<template>
  <AppAccordion
    :id="accordionId"
    color="primary-light"
    :status="activationSummaryStatus.tablets"
    :title="$l('modelEDashboard.tablets.title')"
    :description="$l('modelEDashboard.tablets.accordionDescription')"
    :is-open="accordionIsOpen"
    @click="$store.dispatch('modelE/dashboard/accordions/openAccordion', accordionId)"
  >
    <div class="container--accordion-group">
      <AppAccordion
        :id="tabletsId"
        color="secondary-light"
        :track-section-name="accordionId"
        :title="$l('modelEDashboard.tablets.title')"
        :is-open="activeSubcordionId === tabletsId"
        @click="openSubcordion(tabletsId)"
      >
        <ModelEDashboardTablets />
      </AppAccordion>
      <AppAccordion
        v-if="dummyCondition"
        :id="menuUrlId"
        color="secondary-light"
        :track-section-name="accordionId"
        :title="$l('modelEDashboard.tablets.menuUrl.title')"
        :is-open="activeSubcordionId === menuUrlId"
        @click="openSubcordion(menuUrlId)"
      >
        <ModelEDashboardMenuUrl />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import ModelEDashboardTablets from './ModelEDashboardTablets.vue';
import ModelEDashboardMenuUrl from './ModelEDashboardMenuUrl.vue';

export default {
  name: 'ModelEAccordionTablets',
  components: {
    AppAccordion,
    ModelEDashboardTablets,
    ModelEDashboardMenuUrl,
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      activeDealership: 'user/activeDealership',
      accordions: 'modelE/dashboard/accordions/accordions',
      activeAccordionId: 'modelE/dashboard/accordions/activeAccordionId',
      activeSubcordionId: 'modelE/dashboard/accordions/activeSubcordionId',
      activationSummaryStatus: 'modelE/activationSummary/status',
    }),
    accordionId() {
      return this.accordions.tablets.id;
    },
    tabletsId() {
      return this.accordions.tablets.tablets.id;
    },
    menuUrlId() {
      return this.accordions.tablets.menuUrl.id;
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.accordionId;
    },
    dummyCondition () {
      return true;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('modelE/dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
