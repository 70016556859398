<template>
  <div class="terms-conditions-view">
    <iframe
      class="terms-conditions-view__iframe"
      :title="$l('termsAndConditions.title')"
      :src="termsAndConditionsFileSrc.html"
    />
    <div class="terms-conditions-view__content">
      <TextButton
        class="terms-conditions-view__download-button"
        :text="$l('termsAndConditions.linkTitle')"
        @click="openPdfModal"
      />
      <AppCheckbox
        v-if="userIsKeyDecisionMaker && !termsAreUpToDate"
        v-model="checkboxValue"
        id="terms-conditions-view--checkbox"
        :label="$l('dashboard.enrollment.terms.checkbox')"
      />
      <span v-if="termsAreUpToDate && lastAcceptedDate">
        {{ $l('termsAndConditions.acceptedOn') }}
        {{ lastAcceptedDate }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import AppCheckbox from './common/AppCheckbox.vue';
import TextButton from './common/TextButton.vue';

export default {
  name: 'TermsAndConditionsView',
  components: {
    AppCheckbox,
    TextButton,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      dateFormatPattern: 'locale/dateFormatPattern',
      termsAndConditions: 'termsAndConditions/terms',
      filePath: 'termsAndConditions/src',
      termsAreUpToDate: 'termsAndConditions/userHasAcceptedTerms',
      userIsFromQuebec: 'user/userIsFromQuebec',
    }),
    termsAndConditionsFileSrc() {
      const aspxKey = this.userIsFromQuebec ? 'latestAspxQuebec' : 'latestAspx';
      const pdfKey = this.userIsFromQuebec ? 'latestPdfQuebec' : 'latestPdf';

      return {
        html: `${this.filePath}/${this.termsAndConditions[aspxKey]}`,
        pdf: `${this.filePath}/${this.termsAndConditions[pdfKey]}`,
      };
    },
    lastAcceptedDate() {
      const { lastAcceptedDate } = this.termsAndConditions;

      return (lastAcceptedDate) ? format(new Date(lastAcceptedDate), this.dateFormatPattern) : '';
    },
    checkboxValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    openPdfModal() {
      this.$store.dispatch('documentModal/open', {
        fileHref: this.termsAndConditionsFileSrc.pdf,
        title: this.$l('termsAndConditions.linkTitle'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-conditions-view {
  &__iframe {
    width: 100%;
    height: 350px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    margin-top: 8px;
    width: 100%;
  }
  &__download-button {
    font-size: 14px;
  }
}
</style>
