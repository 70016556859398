<template>
  <AppDialog
    style="--footer-justify-content: center;"
    :open="open"
    @close="closeModal"
  >
    <div class="kdm-modal__header">
      <FontAwesomeIcon
        size="2x"
        :icon="iconWarning"
      />
      <h3 class="kdm-modal__title fds-subtitle__1">
        {{ $l('home.terms.modalTitle') }}
      </h3>
    </div>
    <div class="kdm-modal__content">
      <p>{{ $l('home.terms.modalNotAllowed.paragraphOne') }}</p>
      <p>{{ kdmNames }}</p>
      <p>{{ $l('home.terms.modalNotAllowed.paragraphTwo') }}</p>
      <span
        v-if="terminationDate"
        v-text="terminationDate"
        class="kdm-modal__termination-date"
      />
      <p>{{ $l('home.terms.modalNotAllowed.paragraphThree') }}</p>
    </div>
    <template #footer>
      <StandardButton
        size="lg"
        track-event-name="click_continue"
        track-section-name="notAllowedModal"
        :text="$l('general.continue')"
        :to="{ name: 'Dashboard' }"
      />
    </template>
  </AppDialog>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { ElementType, Event } from '@/constants/track';
import AppDialog from './common/AppDialog.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'HomeTermsAndConditionsModalNotAllowed',
  components: {
    AppDialog,
    StandardButton,
  },
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iconWarning: faExclamationTriangle,
    };
  },
  computed: {
    terminationDate() {
      return null;
    },
    kdmNames() {
      return this.$store.getters['dealerPersonnel/activeKeyDecisionMakerNames'].join(', ');
    },
  },
  watch: {
    open() {
      if (this.open) {
        this.$store.dispatch('track/event', {
          event: Event.OPEN_NOT_ALLOWED_TO_ACCEPT_TERMS_MODAL,
          elementType: ElementType.MODAL,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.kdm-modal {
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    color: $color--fds-error1;
    margin-bottom: 32px;
  }
  &__title {
    margin: 0;
    text-transform: uppercase;
  }
  &__content {
    text-align: center;
  }
  &__termination-date {
    display: inline-block;
    margin: 24px 0;
    color: $color--fds-error1;
    font-weight: $font-weight--medium;
  }
}
</style>
