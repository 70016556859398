<template>
  <section class="dealer-profile-section">
    <h2
      class="dealer-profile-section__title fds-subtitle__2"
      :class="$store.getters['user/activeBrandName']"
    >
      {{ title }}
    </h2>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'DealerProfileSection',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-profile-section {
  &__title {
    margin: 0;
    margin-bottom: 40px;
    color: $color--fds-primary;
    font-weight: $font-weight--medium;
    text-align: left;
    border-bottom: 1px solid #dadada;
    &.lincoln {
      color: $color--lds-primary;
    }
  }
}
</style>
