<template>
  <div
    class="faqs"
    :class="$store.getters['user/activeBrandName']"
  >
    <PageHeader :title="$l('faqs.title')" />
    <div class="faqs__content">
      <AppSearchBar
        id="faq-search-bar"
        :label="$l('faqs.searchLabel')"
        :button-text="$l('general.submit')"
        @submit="getQuestionsBySearch"
      />
      <div class="faqs__search-results-wrapper">
        <FAQsAside
          class="faqs__aside"
          :category-levels="CATEGORY_LEVELS"
          :categories="categories"
          :active-categories="activeCategories"
          @set-active-category="setActiveCategory"
        />
        <section class="faqs__search-results">
          <header class="faqs__search-results-header">
            <FAQsBreadcrumb
              class="faqs__breadcrumb"
              :breadcrumbs="breadcrumbs"
              @set-active-category="setActiveCategory"
            />
            <span class="faqs__results-counter">{{ resultsCounterText }}</span>
          </header>
          <div class="faqs__search-results-content">
            <FAQsLoader v-if="isLoading" />
            <FAQsEmptyState v-else-if="questions.length < 1" />
            <template v-else>
              <FAQsCard
                v-for="(question, index) in questions"
                :id="question.questionID"
                :key="`question-${index}`"
                :question="question.question"
                :answer="question.answer"
                :is-open="question.questionID === openedQuestionId"
                @toggle-is-open="toggleOpenedQuestion(question.questionID)"
              />
            </template>
          </div>
        </section>
      </div>
    </div>
    <ScrollToTopButton />
  </div>
</template>

<script>
import apiManager from '@/api';
import { ElementType, Event } from '@/constants/track';
import AppSearchBar from '@/components/common/AppSearchBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import FAQsAside from '@/components/FAQsAside.vue';
import FAQsBreadcrumb from '@/components/FAQsBreadcrumb.vue';
import FAQsCard from '@/components/FAQsCard.vue';
import FAQsLoader from '@/components/FAQsLoader.vue';
import FAQsEmptyState from '@/components/FAQsEmptyState.vue';
import ScrollToTopButton from '@/components/common/ScrollToTopButton.vue';

const CATEGORY_LEVELS = {
  one: 'levelOne',
  two: 'levelTwo',
  three: 'levelThree',
};

const DEFAULT_CATEGORY = {
  id: 0,
  title: 'All Categories',
  children: [],
};

export default {
  name: 'FAQsView',
  components: {
    AppSearchBar,
    PageHeader,
    FAQsAside,
    FAQsBreadcrumb,
    FAQsCard,
    FAQsLoader,
    FAQsEmptyState,
    ScrollToTopButton,
  },
  data() {
    return {
      CATEGORY_LEVELS,
      categories: [DEFAULT_CATEGORY],
      activeCategories: {
        levelOne: DEFAULT_CATEGORY.id,
        levelTwo: null,
        levelThree: null,
      },
      questions: [],
      openedQuestionId: null,
      isLoading: false,
      searchQuestion: '',
    };
  },
  computed: {
    activeBrand() {
      return this.$store.getters['user/activeBrand'];
    },
    activeLocale() {
      return this.$store.getters['locale/activeLocale'];
    },
    resultsCounterText() {
      return `${this.questions.length} result${this.questions.length === 1 ? '' : 's'}`;
    },
    breadcrumbs() {
      const { levelOne, levelTwo, levelThree } = this.activeCategories;
      const breadcrumbs = [];

      // Finds level one category
      const categoryLevelOne = this.categories.filter((category) => levelOne === category.id)[0];

      if (!categoryLevelOne) return breadcrumbs;

      breadcrumbs.push({
        id: categoryLevelOne.id,
        title: categoryLevelOne.title,
        level: CATEGORY_LEVELS.one,
      });

      // Finds level two category
      const categoryLevelTwo = categoryLevelOne.children
        .filter((category) => levelTwo === category.id)[0];

      if (!categoryLevelTwo) return breadcrumbs;

      breadcrumbs.push({
        id: categoryLevelTwo.id,
        title: categoryLevelTwo.title,
        level: CATEGORY_LEVELS.two,
      });

      // Finds level three category
      const categoryLevelThree = categoryLevelTwo.children
        .filter((category) => levelThree === category.id)[0];

      if (!categoryLevelThree) return breadcrumbs;

      breadcrumbs.push({
        id: categoryLevelThree.id,
        title: categoryLevelThree.title,
        level: CATEGORY_LEVELS.three,
      });

      return breadcrumbs;
    },
  },
  watch: {
    activeBrand() {
      this.resetActiveCategories();
      this.getCategories();
      this.getQuestionsByCategoryId(this.activeCategories.levelOne);
    },
    activeLocale() {
      this.getCategories();

      const { levelOne, levelTwo, levelThree } = this.activeCategories;

      if (levelThree) {
        this.getQuestionsByCategoryId(levelThree);
      } else if (levelTwo) {
        this.getQuestionsByCategoryId(levelTwo);
      } else {
        this.getQuestionsByCategoryId(levelOne);
      }
    },
  },
  mounted() {
    this.getCategories();
    this.getQuestionsByCategoryId(this.activeCategories.levelOne);
  },
  methods: {
    resetActiveCategories() {
      this.activeCategories.levelOne = DEFAULT_CATEGORY.id;
      this.activeCategories.levelTwo = null;
      this.activeCategories.levelThree = null;
    },
    setActiveCategory({ level, categoryId }) {
      this.searchQuestion = '';
      this.activeCategories[level] = categoryId;

      if (level !== CATEGORY_LEVELS.three) {
        this.activeCategories.levelThree = null;
      }

      if (level === CATEGORY_LEVELS.one) {
        this.activeCategories.levelTwo = null;
      }

      this.getQuestionsByCategoryId(categoryId);
    },
    async getCategories() {
      const { data } = await apiManager.faqs.getCategoryListByDivision({
        brand: this.activeBrand,
        language: this.activeLocale,
      });

      this.categories = [DEFAULT_CATEGORY, ...data];
    },
    async getQuestionsBySearch(searchQuestion) {
      if (this.isLoading) return;

      this.isLoading = true;

      this.$store.dispatch('track/event', {
        event: `${Event.SUBMIT_FAQS_SEARCH}-${searchQuestion}`,
        elementType: ElementType.FORM,
        pageName: this.$route.meta.track.pageName,
      });

      const { data } = await apiManager.faqs.getSearchResults({
        searchQuestion,
        division: this.activeBrand,
        language: this.activeLocale,
      });

      this.questions = data;
      this.searchQuestion = searchQuestion;

      this.resetActiveCategories();

      this.isLoading = false;
    },
    async getQuestionsByCategoryId(categoryId) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.openedQuestionId = null;

      this.$store.dispatch('track/event', {
        event: `${Event.SELECT_FAQS_CATEGORY}-${categoryId}`,
        section: 'categories',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      const { data } = await apiManager.faqs.getQuestionsByCategory({
        division: this.activeBrand,
        language: this.activeLocale,
        categoryId,
      });

      this.questions = data;
      this.isLoading = false;
    },
    async toggleOpenedQuestion(questionId) {
      if (this.openedQuestionId === questionId) {
        this.openedQuestionId = null;
        return;
      }

      this.openedQuestionId = questionId;

      this.$store.dispatch('track/event', {
        event: `${Event.OPEN_FAQS_QUESTION}-${questionId}`,
        elementType: ElementType.CARD,
        pageName: this.$route.meta.track.pageName,
      });

      if (this.searchQuestion) {
        await apiManager.faqs.addSearchFeedback({
          openedQuestionId: questionId,
          searchText: this.searchQuestion,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.faqs {
  &__content {
    width: 93%;
    margin: 0 auto;
  }
  &__search-results-wrapper {
    display: grid;
    gap: 30px;
    width: 100%;
    margin-top: 40px;
    @media screen and (min-width: $sm-breakpoint) {
      display: flex;
    }
  }
  &__search-results {
    width: 100%;
  }
  &__search-results-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
    @media screen and (min-width: $xs-breakpoint) {
      justify-content: space-between;
    }
  }
  &__results-counter {
    color: $color--fds-secondary;
  }
  @media screen and (max-width: $xs-breakpoint) {
    &__aside, &__breadcrumb {
      display: none;
    }
  }
  &.lincoln {
    .faqs__results-counter {
      color: $color--lds-secondary;
    }
  }
}
</style>
