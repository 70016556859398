<template>
  <div class="brand">
    <p class="brand__title">
      {{ $l('brand.text') }}
    </p>
    <div class="brand__content">
      <BrandButton
        :is-disabled="isLoading || hasErrorOnLoad"
        :is-active="selectedBrand === brands.FORD"
        @click="selectBrand(brands.FORD)"
      />
      <BrandButton
        brand="lincoln"
        :is-disabled="isLoading || hasErrorOnLoad"
        :is-active="selectedBrand === brands.LINCOLN"
        @click="selectBrand(brands.LINCOLN)"
      />
    </div>
    <LoadingIndicator v-show="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { brands } from '@/constants/brands';
import BrandButton from '@/components/BrandButton.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default {
  name: 'BrandView',
  components: {
    BrandButton,
    LoadingIndicator,
  },
  data() {
    return {
      brands,
      selectedBrand: '',
      isLoading: true,
      hasErrorOnLoad: false,
    };
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      userProfile: 'user/userProfile',
      activeBrand: 'user/activeBrand',
      userCanChangeBetweenBrands: 'user/userCanChangeBetweenBrands',
      userIsFromQuebec: 'user/userIsFromQuebec',
    }),
  },
  async mounted() {
    try {
      this.$store.dispatch('features/getFeatures');
      await this.$store.dispatch('user/loadUser');
    } catch (error) {
      this.openErrorMessage();
    } finally {
      this.isLoading = false;
    }

    if (this.hasErrorOnLoad) return;

    if (!this.userCanChangeBetweenBrands) {
      // If user doesn't have more than one dealership,
      // get its dealership's division and set it as active.
      this.selectBrand(this.userProfile.dealerships[0].division);
    }
  },
  methods: {
    openErrorMessage() {
      this.hasErrorOnLoad = true;

      this.$store.dispatch('topBanner/open', {
        type: 'error',
        duration: 150000,
        message: this.$l('brand.failedToLoad'),
      });
    },
    async selectBrand(selectedBrand) {
      this.isLoading = true;
      this.selectedBrand = selectedBrand;

      try {
        await this.$store.dispatch('user/changeActiveBrand', selectedBrand);

        // Change active locale
        if (this.userIsFromQuebec) {
          this.$store.dispatch('locale/change', this.$store.getters['locale/locales'].french);
        }

        await Promise.all([
          this.$store.dispatch('activationSummary/load'),
          // Model e store
          this.activeFeatures.modelE && this.$store.dispatch('modelE/activationSummary/load'),
          this.activeFeatures.modelE && this.$store.dispatch('modelE/termsAndConditions/load'),
          this.activeFeatures.modelE && this.$store.dispatch('modelE/dealerPersonnel/loadContacts'),
        ]);

        const lastVisitedPage = sessionStorage.getItem(process.env.VUE_APP_LAST_VISITED_PAGE);

        this.$router.push(lastVisitedPage || {
          name: (this.activeFeatures.modelE) ? 'SelectExperience' : 'Start',
        }).catch(() => {}); // eslint-disable-line
      } catch (error) {
        this.openErrorMessage();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.brand {
  &__title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 12px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;
    margin-bottom: 24px;
    @include respond(sm-up) {
      flex-direction: row;
      gap: 40px;
    }
  }
}
</style>
