<template>
  <div
    class="results-card"
    :class="$store.getters['user/activeBrandName']"
    :data-theme="theme"
  >
    <div class="results-card__column">
      <button
        v-text="title"
        class="results-card__title"
        @click="handleTitleClick"
      />
      <a
        v-if="documentHasVideo"
        class="results-card__action-button | results-card__share-video-button"
        target="_blank"
        :href="mailTo"
        @click="trackShareByEmail"
      >
        <span class="app-icon-email" />
        <span class="results-card__action-button-text">
          {{ $l('searchResultPdf.link2') }}
        </span>
      </a>
      <button
        v-else
        class="results-card__document"
        @click="openDocumentModal"
      >
        <img
          class="results-card__document-thumbnail"
          :src="thumbnail"
          :alt="title"
        />
        <span class="results-card__document-readtime results-card__playtime">
          {{ readPlayTime }} {{ $l('searchResultPdf.readTime') }}
        </span>
      </button>
    </div>
    <div class="results-card__column results-card__details">
      <p class="results-card__description">
        {{ longDescription }}
      </p>
      <ul class="results-card__label-list">
        <li class="results-card__label">
          {{ $l('searchResultVideo.filterText1') }} {{ departmentNames }}
        </li>
        <li class="results-card__label">
          {{ $l('searchResultVideo.filterText2') }} {{ roleNames }}
        </li>
        <li class="results-card__label">
          {{ $l('searchResultVideo.filterText3') }} {{ topicNames }}
        </li>
      </ul>
    </div>
    <div class="results-card__column results-card__actions">
      <div
        v-if="documentHasVideo"
        class="results-card__video"
      >
        <button
          class="results-card__video-thumbnail-wrapper"
          @click="toggleVideoModal"
        >
          <img
            class="results-card__video-thumbnail"
            :src="thumbnail"
            :alt="title"
          />
          <span class="results-card__video-playtime results-card__playtime">
            {{ $l('searchResultVideo.playTime', { time: readPlayTime }) }}
          </span>
        </button>
        <TextButton
          class="results-card__text-button"
          :text="$l('videoCard.link1')"
          @click="openDocumentModal"
        />
      </div>
      <div v-else>
        <button
          class="results-card__action-button"
          @click="openDocumentModal"
        >
          <span class="app-icon-download" />
          <span class="results-card__action-button-text">
            {{ $l('searchResultPdf.link1') }}
          </span>
        </button>
        <a
          class="results-card__action-button"
          target="_blank"
          :href="mailTo"
          @click="trackShareByEmail"
        >
          <span class="app-icon-email" />
          <span class="results-card__action-button-text">
            {{ $l('searchResultPdf.link2') }}
          </span>
        </a>
        <template v-if="relatedLinks.length">
          <p class="results-card__related-links-title">
            {{ $l('searchResultPdf.relatedLinks') }}
          </p>
          <ul class="results-card__related-links-list">
            <li
              v-for="(link, index) in relatedLinks"
              :key="index"
            >
              <TextButton
                class="results-card__text-button"
                :text="link.title"
                :to="link.url"
              />
            </li>
          </ul>
        </template>
      </div>
      <span class="results-card__published-date">
        {{ $l('searchResultPdf.publishText') }}
        {{ formattedPublishedDate }}
      </span>
    </div>
    <VideoModal
      v-if="showVideoModal"
      :title="title"
      :video-src="videoUrl"
      @close="toggleVideoModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { ElementType, Event } from '@/constants/track';
import apiManager from '@/api';
import { experiences } from '@/constants/experiences';
import TextButton from './common/TextButton.vue';
import VideoModal from './VideoModal.vue';

export default {
  name: 'ResourceResultsCard',
  components: {
    TextButton,
    VideoModal,
  },
  props: {
    theme: {
      type: String,
      default: experiences.BLUE,
      validator: (value) => Object.values(experiences).includes(value),
    },
    title: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    document: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    readPlayTime: {
      type: String,
      default: '',
    },
    longDescription: {
      type: String,
      default: '',
    },
    meta: {
      type: Object,
      default: () => ({
        departments: [],
        roles: [],
        topics: [],
      }),
    },
    publishedDate: {
      type: String,
      default: '',
    },
    relatedLinks: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      showVideoModal: false,
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/userProfile',
      activeBrand: 'user/activeBrand',
      dateFormatPattern: 'locale/dateFormatPattern',
      documentPath: 'dashboard/documentPath',
    }),
    documentHasVideo() {
      return Boolean(this.videoUrl);
    },
    videoId() {
      return (this.videoUrl) ? this.videoUrl.split('/').reverse()[0] : '';
    },
    formattedPublishedDate() {
      return (this.publishedDate)
        ? format(new Date(this.publishedDate), this.dateFormatPattern)
        : '';
    },
    departmentNames() {
      return this.meta.departments.map(({ text }) => text).join(', ');
    },
    roleNames() {
      return this.meta.roles.map(({ text }) => text).join(', ');
    },
    topicNames() {
      return this.meta.topics.map(({ text }) => text).join(', ');
    },
    mailTo() {
      const { firstName, lastName } = this.userProfile;
      const subject = this.$l('modalFrame.emailSubject', { firstName, lastName });
      const filePath = (this.documentHasVideo)
        ? `Video/${this.videoId}?brand=${this.activeBrand}`
        : `resources/static/documents/${this.document}`;

      const body = `${this.$l('modalFrame.emailBody.line1')}

        ${this.$l('modalFrame.emailBody.line2')}

        ${this.title}
        ${window.location.origin}${process.env.VUE_APP_BASE_URL}${filePath}
      `;

      return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    },
  },
  methods: {
    handleTitleClick() {
      if (this.documentHasVideo) {
        this.toggleVideoModal();
        return;
      }

      this.openDocumentModal();
    },
    toggleVideoModal() {
      this.showVideoModal = !this.showVideoModal;
    },
    openDocumentModal() {
      const fileExtension = this.document.split('.').at(-1);

      // Downloads any file that is not a PDF
      if (fileExtension.toLowerCase() !== 'pdf') {
        this.downloadDocument(this.document);
        return;
      }

      this.$store.dispatch('documentModal/open', {
        fileHref: `${this.documentPath}/${this.document}`,
        title: this.title,
      });
    },
    trackShareByEmail() {
      this.$store.dispatch('track/event', {
        event: (this.documentHasVideo)
          ? `${Event.SHARE_VIDEO_BY_EMAIL}-${this.videoId}`
          : `${Event.SHARE_DOCUMENT_BY_EMAIL}-${this.document}`,
        section: 'resourceResultsCard',
        elementType: ElementType.HYPERLINK,
        pageName: this.$route.meta.track.pageName,
      });
    },
    async downloadDocument(fileName) {
      this.$store.dispatch('track/event', {
        event: `${Event.DOWNLOAD_DOCUMENT}_${fileName}`,
        section: 'resourceResultsCard',
        elementType: ElementType.CARD,
        pageName: this.$route.meta.track.pageName,
      });

      const link = document.createElement('a');

      link.href = await apiManager.document.getBase64(fileName);
      link.download = fileName;
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.results-card {
  --title-color: #{$color--fds-primary};
  --playtime-background-color: #{$color--fds-primary};
  --action-button-color: #{$color--fds-primary};
  --action-button-hover-color: #{$color--fds-secondary};
  &.lincoln {
    --title-color: #{$color--lds-primary};
    --playtime-background-color: #{$color--lds-primary};
    --action-button-color: #{$color--lds-primary};
    --action-button-hover-color: #{$color--lds-primary};
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  min-height: 300px;
  padding: 20px 0;
  background-color: $color--white;
  box-shadow: 0px 0px 20px 0px rgba($color--black, 0.3);
  @include respond(md-up) {
    display: grid;
    gap: unset;
    grid-template-columns: 1fr 2fr 1fr;
  }
  &[data-theme="model-e"] {
    border-radius: 4px;
  }
  .results-card__text-button {
    font-size: 14px;
  }
  &__column {
    padding: 0 20px;
    @include respond(md-up) {
      &:not(:last-of-type) {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ccc;
      }
    }
  }
  &__title {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
    border: 0;
    color: var(--title-color);
    background-color: transparent;
    line-height: 1.3;
    text-align: center;
  }
  &__playtime {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    color: $color--white;
    border: 1px solid #aaa;
    font-size: 12px;
    box-shadow: 5px 5px 15px 0px rgba($color--black, 0.35);
    background-color: var(--playtime-background-color);
  }
  &__document {
    display: flex;
    padding: 0;
    border: 0;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
    &:hover .results-card__document-thumbnail {
      box-shadow: 0px 6px 12px 0px rgba($color--black, 0.35);
      transform: scale(1.01);
    }
  }
  &__document-thumbnail {
    width: auto;
    height: 200px;
    box-shadow: 0px 2px 5px 0px rgba($color--black, 0.35);
  }
  &__document-readtime {
    position: absolute;
    bottom: -18px;
    right: -30px;
  }
  &__details {
    @include respond(md-up) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__description {
    margin-bottom: 24px;
    font-size: 14px;
  }
  &__label-list {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 12px;
    font-style: italic;
  }
  &__video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &__video-thumbnail-wrapper {
    width: 100%;
    max-width: 300px;
    position: relative;
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    border: 0;
    background-color: transparent;
    &:hover .results-card__video-thumbnail {
      box-shadow: 0px 6px 12px 0px rgba($color--black, 0.35);
      transform: scale(1.01);
    }
  }
  &__video-thumbnail {
    width: inherit;
    height: auto;
    box-shadow: 0px 3px 8px 0px rgba($color--black, 0.25);
  }
  &__video-playtime {
    position: absolute;
    bottom: -20px;
    right: -8px;
  }
  &__actions {
    @include respond(md-up) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__action-button {
    display: flex;
    align-items: baseline;
    gap: 4px;
    padding: 0;
    margin-bottom: 8px;
    border: 0;
    background-color: transparent;
    color: var(--action-button-color);
    font-size: 16px;
    [class^=app-icon-] {
      font-size: 20px;
    }
    &:hover {
      color: var(--action-button-hover-color);
      .results-card__action-button-text {
        text-decoration: underline;
      }
    }
  }
  &__share-video-button {
    margin-top: auto;
    margin-bottom: 0;
    @include respond(md-up) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__related-links-title {
    margin-bottom: 8px;
  }
  &__related-links-list {
    margin: 0;
    padding-left: 32px;
    list-style: none;
  }
  &__published-date {
    font-size: 12px;
    @include respond(md-up) {
      text-align: right;
    }
  }
}
</style>
