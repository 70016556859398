<template>
  <BaseButton
    class="text-button"
    :to="to"
    :class="[buttonClasses, $store.getters['user/activeBrandName']]"
    @click="handleClick"
  >
    <i
      v-if="iconLeft"
      class="text-button__icon text-button__icon--left"
      :class="`app-icon-${iconLeft}`"
    />
    <span class="text-button__text">{{ text }}</span>
    <i
      class="text-button__icon text-button__icon--right"
      :class="`app-icon-${defaultIconRight}`"
    />
  </BaseButton>
</template>

<script>
import { ElementType } from '@/constants/track';
import BaseButton from '@/components/common/BaseButton.vue';

export default {
  name: 'TextButton',
  components: {
    BaseButton,
  },
  emits: ['click'],
  props: {
    secondary: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    trackEventName: {
      type: String,
      default: '',
    },
    trackSectionName: {
      type: String,
      default: '',
    },
  },
  computed: {
    activeBrandIsFord() {
      return this.$store.getters['user/activeBrandIsFord'];
    },
    defaultIconRight() {
      const defaultIconRight = (this.activeBrandIsFord) ? 'right-caret-circle' : 'arrow-forward';
      return this.iconRight || defaultIconRight;
    },
    buttonClasses() {
      return {
        'text-button--primary': !this.secondary,
        'text-button--secondary': this.secondary,
      };
    },
  },
  methods: {
    handleClick() {
      this.trackClick();
      this.$emit('click');
    },
    trackClick() {
      if (!this.trackEventName) return;

      this.$store.dispatch('track/event', {
        event: this.trackEventName,
        section: this.trackSectionName,
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-button {
  display: inline-block;
  align-items: center;
  height: fit-content;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  outline: none;
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:focus-visible::after {
    outline: 1px solid transparent;
    outline-offset: 8px;
  }
  &__icon {
    font-size: 14px;
    font-style: normal;
    &::before {
      font-family: $font-family--icons;
    }
  }
}
.text-button.ford {
  &.text-button--primary {
    color: $color--fds-primary;
    &:hover,
    &:focus-visible {
      color: $color--fds-secondary;
      .text-button__text {
        text-decoration: underline;
      }
    }
    &:focus-visible::after {
      outline-color: $color--fds-primary;
    }
  }
  &.text-button--secondary {
    color: $color--white;
    &:hover .text-button__text,
    &:focus-visible .text-button__text {
      text-decoration: underline;
    }
    &:focus-visible::after {
      outline-color: $color--white;
    }
  }
  .text-button__icon {
    &--left {
      margin-right: 16px;
    }
    &--right {
      margin-left: 16px;
    }
  }
}
.text-button.lincoln {
  &:hover .text-button__text,
  &:focus-visible .text-button__text {
    border-bottom-color: $color--lds-secondary;
  }
  &.text-button--primary {
    color: $color--lds-primary;
    &:focus-visible::after {
      outline-color: $color--lds-primary;
    }
  }
  &.text-button--secondary {
    color: $color--white;
    &:focus-visible::after {
      outline-color: $color--white;
    }
  }
  .text-button__text {
    border-bottom: 2px solid transparent;
    transition: border-color .3s ease-out;
  }
  .text-button__icon {
    font-size: 20px;
    vertical-align: text-bottom;
    &--left {
      margin-right: 0px;
    }
    &--right {
      margin-left: 0px;
    }
  }
}
</style>
