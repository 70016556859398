import apiManager from '@/api';
import stringFormat from '@/helpers/stringFormat';

export default {
  namespaced: true,
  state() {
    return {
      names: [],
      feedbackTopics: [],
      feedbackCategories: [],
    };
  },
  getters: {
    names: (state) => state.names,
    feedbackTopics(state, getters, rootState, rootGetters) {
      return state.feedbackTopics
        .filter(({ language }) => language === rootGetters['locale/activeLocale']);
    },
    feedbackCategories(state, getters, rootState, rootGetters) {
      return state.feedbackCategories
        .filter(({ language }) => language === rootGetters['locale/activeLocale']);
    },
  },
  mutations: {
    setNames(state, names) {
      state.names = names;
    },
    setFeedbackTopics(state, feedbackTopics) {
      state.feedbackTopics = feedbackTopics;
    },
    setFeedbackCategories(state, feedbackCategories) {
      state.feedbackCategories = feedbackCategories;
    },
  },
  actions: {
    async loadFormOptions({ commit }) {
      const { data } = await apiManager.help.getModelE();

      commit('setNames', data.starsData.map((name) => ({
        id: name.student_ID_Number,
        firstName: stringFormat.capitalize(name.first_Name),
        lastName: stringFormat.capitalize(name.last_Name),
      })));

      commit('setFeedbackTopics', data.feedbackTopic);
      commit('setFeedbackCategories', data.feedbackCategory);
    },
    sendHelpFormData({ rootGetters }, payload) {
      const activeDealership = rootGetters['user/activeDealership'];
      const { userId } = rootGetters['user/userProfile'];

      return apiManager.help.postModelE({
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phoneNumber,
        email: payload.email,
        note: payload.notes,
        categoryId: payload.feedback,
        topicId: payload.topic,
        dealerID: activeDealership.dealerId,
        userID: userId,
        pa: activeDealership.pa,
        division: activeDealership.division,
        language: rootGetters['locale/activeLocale'],
      });
    },
  },
};
