<template>
  <div>
    <PageHeader :title="$l('reports.asdd.title')" />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'ModelEReportASDDView',
  components: {
    PageHeader,
  },
};
</script>
