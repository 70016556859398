<template>
  <AppAccordion
    :id="fiSetupId"
    :title="accordionTitle"
    :is-open="accordionIsOpen"
    @click="$store.dispatch('dashboard/accordions/openAccordion', fiSetupId)"
  >
    <div class="container--accordion-group">
      <template v-if="activeFeatures.fiAccordion">
        <AppAccordion
          :id="routeOneId"
          color="secondary"
          :track-section-name="fiSetupId"
          :title="$l('dashboard.fiSetup.routeOne.title')"
          :is-open="activeSubcordionId === routeOneId"
          @click="openSubcordion(routeOneId)"
        >
          <DashboardFIRouteOne />
        </AppAccordion>
        <AppAccordion
          :id="taxesAndFeesId"
          color="secondary"
          :track-section-name="fiSetupId"
          :title="$l('dashboard.fiSetup.taxesAndFees.title')"
          :is-open="activeSubcordionId === taxesAndFeesId"
          @click="openSubcordion(taxesAndFeesId)"
        >
          <DashboardFITaxesAndFees />
        </AppAccordion>
        <AppAccordion
          :id="documentFeesId"
          color="secondary"
          :track-section-name="fiSetupId"
          :title="$l('dashboard.fiSetup.documentFees.title')"
          :is-open="activeSubcordionId === documentFeesId"
          @click="openSubcordion(documentFeesId)"
        >
          <DashboardFIDocumentFees />
        </AppAccordion>
        <AppAccordion
          :id="fiProductId"
          color="secondary"
          :track-section-name="fiSetupId"
          :title="$l('dashboard.fiSetup.fiProduct.title')"
          :is-open="activeSubcordionId === fiProductId"
          @click="openSubcordion(fiProductId)"
        >
          <DashboardFIProduct />
        </AppAccordion>
        <AppAccordion
          :id="eSignId"
          color="secondary"
          :track-section-name="fiSetupId"
          :title="$l('dashboard.fiSetup.eSign.title')"
          :is-open="activeSubcordionId === eSignId"
          @click="openSubcordion(eSignId)"
        >
          <DashboardFIeSign />
        </AppAccordion>
      </template>
      <AppAccordion
        v-else
        :id="routeOneId"
        color="secondary"
        :track-section-name="fiSetupId"
        :title="$l('dashboard.fiSetup.subtitleComingSoon')"
        :is-open="activeSubcordionId === routeOneId"
        @click="openSubcordion(routeOneId)"
      >
        <ComingSoonMessage />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import ComingSoonMessage from './ComingSoonMessage.vue';
import DashboardFIRouteOne from './DashboardFIRouteOne.vue';
import DashboardFITaxesAndFees from './DashboardFITaxesAndFees.vue';
import DashboardFIDocumentFees from './DashboardFIDocumentFees.vue';
import DashboardFIProduct from './DashboardFIProduct.vue';
import DashboardFIeSign from './DashboardFIeSign.vue';

export default {
  name: 'DashboardAccordionFISetup',
  components: {
    AppAccordion,
    ComingSoonMessage,
    DashboardFIRouteOne,
    DashboardFITaxesAndFees,
    DashboardFIDocumentFees,
    DashboardFIProduct,
    DashboardFIeSign,
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      accordions: 'dashboard/accordions/accordions',
      activeAccordionId: 'dashboard/accordions/activeAccordionId',
      activeSubcordionId: 'dashboard/accordions/activeSubcordionId',
    }),
    activeBrandIsFord() {
      return this.$store.getters['user/activeBrandIsFord'];
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.fiSetupId;
    },
    fiSetupId() {
      return this.accordions.fiSetup.id;
    },
    routeOneId() {
      return this.accordions.fiSetup.routeOne.id;
    },
    taxesAndFeesId() {
      return this.accordions.fiSetup.taxesAndFees.id;
    },
    documentFeesId() {
      return this.accordions.fiSetup.documentFees.id;
    },
    fiProductId() {
      return this.accordions.fiSetup.fiProduct.id;
    },
    eSignId() {
      return this.accordions.fiSetup.eSign.id;
    },
    accordionTitle() {
      return this.activeFeatures.fiAccordion
        ? this.$l('dashboard.fiSetup.title')
        : this.$l('dashboard.fiSetup.titleComingSoon');
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
