import BaseAPI from './baseAPI';

export default class TermsAndConditions extends BaseAPI {
  async get(dealerId) {
    const { data } = await this.axios.get('/TermsAndConditions', {
      params: { dealerId },
    });
    return data;
  }

  async acceptTermsAndConditions(dealerId) {
    const { data } = await this.axios.post('/TermsAndConditions', { dealerId });
    return data;
  }

  async getModelETerms(dealerId) {
    const { data } = await this.axios.get('/TermsAndConditionsModelE', {
      params: { dealerId },
    });
    return data;
  }

  async acceptTermsAndConditionsModelE(dealerId,fileName) {
    const { data } = await this.axios.post('/TermsAndConditionsModelE', { dealerId,fileName });
    return data;
  }
}
