<template>
  <button
    class="brand-button"
    :class="{ active: isActive }"
    :aria-label="ariaLabel"
    :disabled="isDisabled"
    @click="$emit('click')"
  >
    <img
      class="brand-button__image"
      :alt="imageAlt"
      :src="require(`@/assets/images/logos/${imageName}`)"
    />
  </button>
</template>

<script>
const VALID_BRANDS = ['ford', 'lincoln'];

export default {
  name: 'BrandButton',
  emits: ['click'],
  props: {
    brand: {
      type: String,
      default: VALID_BRANDS[0],
      validator(brand) {
        return VALID_BRANDS.includes(brand.toLowerCase());
      },
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    brandIsFord() {
      return this.brand === 'ford';
    },
    ariaLabel() {
      return `Select ${this.brandIsFord ? 'Ford' : 'Lincoln'} brand`;
    },
    imageAlt() {
      return `${this.brandIsFord ? 'Ford' : 'Lincoln'} logo`;
    },
    imageName() {
      return (this.brandIsFord) ? 'fordBlueOval200.png' : 'lincolnLogo.png';
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-button {
  width: 300px;
  height: 75px;
  padding: 0;
  border: 0;
  background-color: #f9f9f9;
  box-shadow: 0 0 25px 0 rgba($color--black, 0.4);
  outline-offset: 5px;
  &:hover {
    box-shadow: 0 0 25px 0 rgba($color--black, 0.5);
  }
  &:active, &.active {
    box-shadow: 0 0 5px 0 rgba($color--black, 0.2);
    background-color: $color--white;
  }
  &.active {
    cursor: default;
  }
  &__image {
    width: 100px;
    height: 35px;
  }
}
</style>
