import apiManager from '@/api';
import { Report, UPDATE_ACCESS_TOKEN_INTERVAL } from '@/constants/powerbi';

const getReportId = (reportList, report) => {
  const fordId = reportList.find(({ reportName }) => report.name === reportName)?.reportID;
  const lincolnId = reportList.find(
    ({ reportName }) => report.lincolnName === reportName,
  )?.reportID;
  const frenchId = reportList.find(
    ({ reportName }) => report.frenchName === reportName,
  )?.reportID;
  const frenchLincolnId = reportList.find(
    ({ reportName }) => report.frenchLincolnName === reportName,
  )?.reportID;

  return {
    id: fordId || lincolnId,
    lincolnId,
    frenchId,
    frenchLincolnId,
  };
};

export default {
  namespaced: true,
  state() {
    return {
      accessToken: null,
      accessTokenExpiration: null,
      reports: [],
    };
  },
  getters: {
    reports: (state) => state.reports,
    accessToken: (state) => state.accessToken,
    accessTokenExpiration: (state) => state.accessTokenExpiration,
    dealerProfileReport: ({ reports }) => getReportId(reports, Report.DEALER_PROFILE),
    asddReport: ({ reports }) => getReportId(reports, Report.ASDD),
  },
  mutations: {
    setReports(state, reports) {
      state.reports = reports;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setAccessTokenExpiration(state, expiration) {
      state.accessTokenExpiration = expiration;
    },
  },
  actions: {
    async load({ dispatch, getters, commit }) {
      if (getters.accessToken && getters.accessTokenExpiration && getters.reports.length) return;

      const {
        expiration,
        token,
        powerBIReportList,
      } = await apiManager.powerbi.getAccessToken();

      commit('setReports', powerBIReportList);
      commit('setAccessToken', token);
      commit('setAccessTokenExpiration', expiration);

      setInterval(() => {
        dispatch('updateAccessToken');
      }, UPDATE_ACCESS_TOKEN_INTERVAL);
    },
    async updateAccessToken({ getters, commit }) {
      const currentTime = Date.now();
      const timeUntilExpiration = Date.parse(getters.accessTokenExpiration) - currentTime;
      const timeToUpdate = 1 * 60 * 1000;

      const shouldUpdateToken = timeToUpdate > timeUntilExpiration;

      if (!shouldUpdateToken) return;

      const { expiration, token } = await apiManager.powerbi.getAccessToken();

      commit('setAccessToken', token);
      commit('setAccessTokenExpiration', expiration);
    },
  },
};
