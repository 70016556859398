import apiManager from '@/api';

export default {
  namespaced: true,
  state() {
    return {
      notifications: [],
    };
  },
  getters: {
    notifications: (state) => state.notifications,
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
  },
  actions: {
    async getNotifications({ commit, rootGetters }) {
      const { dealerId } = rootGetters['user/activeDealership'];

      const notifications = await apiManager.notifications.getModelENotifications(dealerId);

      commit('setNotifications', notifications);
    },
  },
};
