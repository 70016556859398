import ApiManager from '@/api';

export default {
  namespaced: true,
  actions: {
    event({ rootGetters }, payload) {
      const { dealerId } = rootGetters['user/activeDealership'];

      return ApiManager.track.trackEvent({
        dealerId,
        pageName: payload.pageName,
        controlType: payload.elementType,
        controlName: payload.event,
        parentControlName: payload.section || '',
      });
    },
    async video({ rootGetters }, options) {
      const { dealerId } = rootGetters['user/activeDealership'];

      await ApiManager.track.video({
        dealerId,
        action: options.action,
        id: options.id,
        pageName: options.pageName,
        percent: options.percent,
        seconds: options.seconds,
      });
    },
  },
};
