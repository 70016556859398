import BaseAPI from './baseAPI';

export default class Impersonate extends BaseAPI {
  async getDealerList() {
    const { data } = await this.axios.get('/Dealer');
    return data;
  }

  async login(dealerID) {
    const { data } = await this.axios.post('/Dealer/Impersonate', {
      dealerID,
    });

    return data;
  }

  async resetProgress(dealerID) {
    const { data } = await this.axios.post('/Dealer/ResetProgress', {
      dealerID,
    });

    return data;
  }

  async clearTermsAndConditions(dealerID) {
    const { data } = await this.axios.post('/Dealer/ClearTermsAndConditions', {
      dealerID,
    });

    return data;
  }

  async setStripeStatus({ dealerID, status: stripeStatus }) {
    const { data } = await this.axios.post('/Dealer/StripeRequestStatus', null, {
      params: {
        dealerID,
        stripeStatus,
      },
    });

    return data;
  }

  async activateDealerCartCheckout(dealerID) {
    const { data } = await this.axios.post('/Dealer/ActivateDealerCartCheckout', null, {
      params: {
        dealerID,
      },
    });

    return data;
  }

  async resetProgressModelE(dealerID) {
    const { data } = await this.axios.post('/DealerModelE/ResetProgressModelE', {
      dealerID,
    });

    return data;
  }

  async clearTermsAndConditionsModelE(dealerID) {
    const { data } = await this.axios.post('/DealerModelE/ClearTermsAndConditionsModelE', {
      dealerID,
    });

    return data;
  }

  async getFeatureList() {
    const { data } = await this.axios.get('/FeatureFlag/AllFeatureFlags');
    return data;
  }

  async setFeatureStatus(dealerID, featureId, IsActive) {
    const { data } = await this.axios.post('/DealerModelE/SetFeatureFlagStatus', null, {
      params: {
        dealerID,
        featureId,
        IsActive,
      },
    });

    return data;
  }

  async getStepList() {
    const { data } = await this.axios.get('/Dealer/ProgressSteps');
    return data;
  }

  async getModelEStepList() {
    const { data } = await this.axios.get('/DealerModelE/ProgressStepsModelE');
    return data;
  }

  async setStep(dealerID, step ) {
    const { data } = await this.axios.post('/Dealer/CompleteStep', null, {
      params: {
        dealerID,
        step,
      },
    });

    return data;
  }

  async setModelEStep(dealerID, step ) {
    const { data } = await this.axios.post('/DealerModelE/CompleteStepModelE', null, {
      params: {
        dealerID,
        step,
      },
    });

    return data;
  }

  async getTrackList(userId) {
    const { data } = await this.axios.get('/Track',{
      params: {
        userId,
      },
    });

    return data;
  }

  async getVideoList(userId) {
    const { data } = await this.axios.get('/Track/VideoStats',{
      params: {
        userId,
      },
    });

    return data;
  }

}
