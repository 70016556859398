<template>
  <header
    id="header"
    class="header"
    :data-theme="activeExperience"
    :data-style-controls="styleControls"
  >
    <nav class="header__navbar">
      <HeaderMenu
        :show-menu-content="showControls"
        :is-open="menuIsOpen"
      />
      <div class="header__content">
        <HeaderLogo :render-link="showControls" />
        <template v-if="showControls">
          <HeaderMenuButton
            :menu-is-open="menuIsOpen"
            @toggle-menu="menuIsOpen = !menuIsOpen"
          />
          <div class="header__controls">
            <BrandSelector />
            <ExperienceSelector />
            <LanguageSelector />
            <div class="header__user-info-wrapper">
              <HeaderUserInfo />
            </div>
          </div>
        </template>
      </div>
    </nav>
    <HeaderTopBanner />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderLogo from './TheHeaderLogo.vue';
import HeaderMenu from './TheHeaderMenu.vue';
import HeaderMenuButton from './TheHeaderMenuButton.vue';
import HeaderUserInfo from './TheHeaderUserInfo.vue';
import BrandSelector from './TheHeaderBrandSelector.vue';
import LanguageSelector from './TheHeaderLanguageSelector.vue';
import ExperienceSelector from './TheHeaderExperienceSelector.vue';
import HeaderTopBanner from './TheHeaderTopBanner.vue';

export default {
  name: 'TheHeader',
  components: {
    HeaderLogo,
    HeaderMenu,
    HeaderMenuButton,
    HeaderUserInfo,
    BrandSelector,
    LanguageSelector,
    ExperienceSelector,
    HeaderTopBanner,
  },
  props: {
    showControls: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menuIsOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      activeExperience: 'user/activeExperience',
      activeBrandIsFord: 'user/activeBrandIsFord',
    }),
    routeName() {
      return this.$route.name;
    },
    styleControls() {
      const { disableBrandSelector, disableExperienceSelector } = this.$route.meta.header || {};

      return (
        this.activeFeatures.modelE &&
        this.activeBrandIsFord &&
        !disableBrandSelector &&
        !disableExperienceSelector
      );
    },
  },
  watch: {
    routeName() {
      this.menuIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: sticky;
  z-index: $z-index__the-header;
  top: 0;
  left: 0;
  right: 0;
  &[data-theme="model-e"] {
    .header__navbar {
      border: 0;
    }
    .header__content {
      border-radius: 6px;
      box-shadow: $fds-elevation__box-shadow--layer1;
    }
  }
  &[data-theme="blue"][data-style-controls="true"] {
    .header__user-info-wrapper {
      padding-left: 12px;
    }
    .header__controls {
      gap: 12px;
      max-width: 920px;
      @media (max-width: 1080px) {
        :deep(.toggle) {
          --label-spacing: 6px;
        }
      }
    }
    :deep(.header-logo__app-name) {
      display: none;
      @media (min-width: 1240px) {
        display: block;
        font-size: 16px;
      }
    }
  }
  &__navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: $color--white;
    border-bottom: 1px solid $color--black;
    position: relative;
    @media screen and (min-width: $breakpoint__the-header-desktop) {
      border: 1px solid #e7e7e7;
      background-color: $color--fds-gray1;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 16px;
    background-color: $color--white;
    @include respond(lg-up) {
      padding: 0 40px 0 40px;
    }
    @media screen and (min-width: $breakpoint__the-header-desktop) {
      height: 78px;
      box-shadow: 0 0 10px 0 rgba($color--black, 0.2);
    }
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    max-width: 750px;
    @media screen and (max-width: $breakpoint__the-header-desktop) {
      display: none;
    }
    @include respond(lg-up) {
      gap: 40px;
    }
  }
  &__user-info-wrapper {
    padding-left: 32px;
    white-space: nowrap;
    border-left: 1px solid #CCC;
  }
}
</style>
