<template>
  <section class="stripe-account-setup">
    <SubsectionHeader>{{ $l('stripe.accountSetup.title') }}</SubsectionHeader>
    <div class="stripe-account-setup__content">
      <div>
        <div v-html="descriptionTop" />
        <AccountSetupStatus />
        <div v-html="descriptionBottom" />
      </div>
      <div class="stripe-account-setup__column-buttons">
        <StandardButton
          track-event-name="click_stripeApplication"
          :text="buttonText"
          :disabled="!userIsKeyDecisionMaker"
          :is-loading="isLoading"
          @click="redirectToStripePage"
        />
        <TextButton
          track-event-name="click_setupTips"
          :text="$l('stripe.accountSetup.buttonTips')"
          @click="showModalSetupTips = true"
        />
      </div>
    </div>
    <div
      v-if="documents.length"
      class="stripe-account-setup__documents"
    >
      <DocumentCard
        v-for="document in documents"
        :id="`stripe-account-document--${document.documentID}`"
        :key="document.documentID"
        :title="document.title"
        :document-name="document.document"
        :file-path="`${documentPath}/${document.document}`"
        :thumbnail="document.thumbnail"
        :description="document.longdesc"
      />
    </div>
    <hr class="stripe-account-setup__divider" />
    <ModalSetupTips
      :open="showModalSetupTips"
      @close="showModalSetupTips = false"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import TextButton from './common/TextButton.vue';
import DocumentCard from './DocumentCard.vue';
import SubsectionHeader from './StripeSubsectionHeader.vue';
import AccountSetupStatus from './StripeAccountSetupStatus.vue';
import ModalSetupTips from './StripeModalSetupTips.vue';

export default {
  name: 'StripeAccountSetup',
  components: {
    StandardButton,
    TextButton,
    SubsectionHeader,
    DocumentCard,
    ModalSetupTips,
    AccountSetupStatus,
  },
  data() {
    return {
      isLoading: false,
      showModalSetupTips: false,
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      documentPath: 'dashboard/documentPath',
      activeResources: 'stripe/activeResources',
      aplicationStatus: 'stripe/aplicationStatus',
      accountUrl: 'stripe/accountUrl',
    }),
    documents() {
      return this.activeResources.documents;
    },
    buttonText() {
      if (this.aplicationStatus.active) {
        return this.$l('stripe.accountSetup.stripeLinkApproved');
      }
      return this.$l('stripe.accountSetup.stripeLink');
    },
    descriptionTop() {
      if (this.aplicationStatus.pending) {
        return this.$l('stripe.accountSetup.descriptionTop.pending');
      }
      if (this.aplicationStatus.active) {
        return this.$l('stripe.accountSetup.descriptionTop.active');
      }
      if (this.aplicationStatus.unknown) {
        return this.$l('stripe.accountSetup.descriptionTop.unknown');
      }
      return this.$l('stripe.accountSetup.descriptionTop.notStarted');
    },
    descriptionBottom() {
      const {
        pending, inprogress, onhold, active, unknown,
      } = this.aplicationStatus;

      if (pending || inprogress || onhold) {
        return this.$l('stripe.accountSetup.descriptionBottom.pending');
      }
      if (active) {
        return this.$l('stripe.accountSetup.descriptionBottom.active');
      }
      if (unknown) {
        return this.$l('stripe.accountSetup.descriptionBottom.unknown');
      }
      return this.$l('stripe.accountSetup.descriptionBottom.notStarted');
    },
  },
  methods: {
    async redirectToStripePage() {
      this.isLoading = true;

      try {
        const url = await this.$store.dispatch('stripe/getAccountUrl');

        window.open(url, '_blank');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-account-setup {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    @include respond(md-up) {
      grid-template-columns: 1fr auto;
    }
  }
  &__column-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 0;
  }
  &__documents {
    display: flex;
    gap: 40px;
    margin-top: 40px;
  }
  &__divider {
    margin-top: 50px;
    margin-bottom: 40px;
    background-color: #707070;
  }
}
</style>
