export default {
  title: 'Stripe <sup>TM</sup> Account & Fee Setup',
  subtitle: 'Complete setup to enable Guests to submit deposits to your dealership from https://www.ford.ca/',
  sectionTitle: 'Stripe Account & Fee Setup',
  notEnrolled: 'Please complete the enrolment process before accessing the Stripe page',
  accountSetup: {
    title: 'Stripe Account Setup',
    descriptionTop: {
      notStarted: `<p>To get started on your Stripe account application, click on the ‘Stripe Application’ button.</p>
      <p>Once your dealership’s account is active, the button will update to ‘Access Stripe,’ which will take you to your Stripe dashboard.</p>`,
      pending: '<p>Your Stripe account is pending, as the status below indicates.</p>',
      active: `<p>Your Stripe account is active, as the status below indicates.</p>
      <p>To access your Stripe dashboard, click on the ‘Access Stripe’ button.</p>`,
      unknown: '<p>Your Stripe account status is unknown, as the status below indicates. It should take approximately 10 minutes until your account status is active. Click \'Check Status\' for an update, the details of the last status check are displayed to the right of the button.</p>',
    },
    descriptionBottom: {
      notStarted: '<p>Once you have completed your Stripe account application, a pending status will appear, and it should take approximately 10 minutes until your account is active. Once your Stripe account is active, you’ll return to this page and confirm your Stripe Account ID below.</p>',
      pending: `<p>Once you have completed your Stripe account application, a pending status will appear, and it should take approximately 10 minutes until your account is active. The blue ‘Check Status’ button can be clicked to check progress and the details on the last status check will be displayed to its right.</p>
      <p>Once your Stripe account is active, you’ll return to this page and confirm your Stripe Account ID below.</p>`,
      active: '<p>Please confirm your Stripe Account ID below. Please reference the email sent from Ford eCommerce with the subject line "Action Requested: Confirm Your Stripe Account ID Today" to locate your Stripe Account ID. You will need this to confirm your ID below.</p>',
      unknown: '<p>Once your Stripe account is active, you’ll return to this page and confirm your Stripe Account ID below.</p>',
    },
    stripeLink: 'Stripe Application',
    stripeLinkApproved: 'Access Stripe',
    buttonTips: 'Setup Tips',
    statusBox: {
      application: 'Stripe Account Status',
      account: 'Confirm Stripe Account ID and fees',
    },
    checkStatus: 'Check Status',
    lastCheck: 'Last Check: {date}',
    instructions: '*Scroll down to review/complete',
    tooltip: {
      application: '‘Stripe Account Status’ may indicate: Not Started, In Progress, Pending, On Hold, or Active',
      account: '’Confirm Stripe Account ID and Fees’ status may indicate: Incomplete or Complete',
    },
    tipsModal: {
      title: 'Stripe Setup Tips',
      description: 'Setup & Maintenance Tips for Your Stripe Account',
      item1: 'Before beginning your Stripe Application, download the Stripe Enrolment for eCommerce Checklist for easy reference',
      item2: 'Make sure you’ve gathered the necessary financial documents before beginning the application process',
      item3: 'When you receive an email with your Stripe Account ID, be sure to print it or favorite it to locate again when needed',
      item4Part1: 'If you’re a dual Dealer and chose one account, but later would like to inquire about separate account IDs, contact eCommerce program HQ at',
      item4Part2: 'or',
      item5: 'For questions or additional support while setting up your Stripe account, visit:',
      item5Link: 'https://support.stripe.com/?locale=en-US',
      item6: 'To contact Stripe for assistance, fill out the form at:',
      item6Link: 'https://support.stripe.com/contact/email?locale=en-US',
    },
    accountOnHold: {
      title: 'Stripe',
      description: 'Account On Hold',
      message: 'Our records indicate your Stripe account is currently on hold. Please contact Stripe by using the form at this link:',
    },
  },
  feeSetup: {
    title: 'Stripe Account ID & Fees',
    paragraphOne: 'Once your Stripe account is active, your Stripe Account ID will populate below. When it appears, you’ll cross reference the Stripe Account ID sent via email, check ‘Confirm’ and click ‘Save’.',
    paragraphOneActive: 'Your dealership’s Stripe Account ID is displayed below. To confirm it matches the Stripe Account ID provided in the confirmation email, check ‘Confirm’ and click ‘Save’.',
    paragraphTwo: 'Click the accordion to set parameters for funds accepted by your dealership in eCommerce. The ‘Reservation Deposit’ and ‘Non-FMCC Ford Checkout Deposit’ are both set by Ford and can’t be edited. The ‘Order Deposit’ and ‘Max Amount Accepted on Credit Card’ have dropdown menus with prepopulated amounts that can be selected, confirmed and saved.',
    primaryContact: 'Primary Contact:',
    card: {
      deposit: 'Deposit',
      oem: 'OEM Set',
      accountId: {
        input: 'Stripe account ID',
        tooltip: 'Your Stripe Account ID will prepopulate here once your application has been approved. To confirm your Stripe Account ID reference the email sent from eCommerce Program Headquarters.',
        tooltip2: 'The \'Reservation Deposit\' Is the amount your dealership will ask Customers to deposit for a Reservation. This amount has been set by Ford and can\'t be edited.',
        questions: 'Questions about your account ID?',
        success: 'Your account ID has been confirmed successfully.',
        error: 'Failed to confirm account ID. Please try again.',
      },
      reservation: {
        title: 'Reservation',
        description: 'Set up by Ford of Canada',
      },
      order: {
        title: 'Order',
        success: 'Deposit value was confirmed successfully.',
        error: 'Failed to confirm deposit value. Please try again.',
      },
      cartCheckout: {
        title: 'Cart and Checkout',
        select: 'Max Amount Accepted on Credit Card',
        input: 'Non-FMCC Ford Checkout Deposit',
        success: 'Amount value was confirmed successfully.',
        error: 'Failed to confirm amount. Please try again.',
      },
    },
  },
  application: {
    title: 'Your Stripe application has been submitted!',
    message: 'You’ve taken an important step in setting up your dealership’s Stripe account. You can return to the \'Stripe Account Setup\' page at any time to check your application status. Once your Stripe Account is approved, your status will update to ‘Active,’ and you’ll receive an email from eCommerce Program Headquarters with your dealership’s Stripe Account ID. At that time, you’ll confirm your Stripe Account ID, and set up your dealership’s parameters for funds accepted from eCommerce Customers on the \'Stripe Account Setup\' page.',
  },
};
