import BaseAPI from './baseAPI';

export default class Track extends BaseAPI {
  async trackEvent({
    dealerId = '',
    pageName = '',
    controlName = '',
    controlType = '',
    parentControlName = '',
  }) {
    const { data } = await this.postUnique('/Track', {
      dealerID: dealerId,
      srcPageName: pageName,
      pageControlName: controlName,
      controlType,
      parentControlName,
      userAgent: navigator.userAgent,
    });

    return data;
  }

  async video(options) {
    const { data } = await this.postUnique('/Track/Video', {
      video_ID: options.id,
      video_Action: options.action,
      video_Action_Seconds: options.seconds,
      video_PercentComplete: options.percent,
      pageName: options.pageName,
      dealerID: options.dealerId,
      // userAgent: navigator.userAgent,
    });
    return data;
  }
}
