<template>
  <BaseButton
    class="navigation-card__link"
    :to="readonly ? null : path"
    :aria-label="ariaLabel"
    @click="trackClick"
  >
    <section
      class="navigation-card"
      :class="{ 'navigation-card--readonly': readonly }"
    >
      <header
        class="navigation-card__header"
        :class="$store.getters['user/activeBrandName']"
      />
      <div class="navigation-card__body">
        <h2 class="navigation-card__title">
          {{ title }}
          <i
            v-if="showAlertIcon"
            class="navigation-card__icon app-icon-exclamation-triangle"
          />
        </h2>
        <span class="navigation-card__subtitle">
          <slot name="subtitle">
            {{ subtitle }}
          </slot>
        </span>
        <slot />
      </div>
      <div class="navigation-card__footer">
        <span
          v-if="readonly"
          v-text="$l('general.comingSoon')"
          class="navigation-card__coming-soon-message"
        />
        <StandardButton
          v-else
          icon-right="chevron-right"
          :aria-label="ariaLabel"
          :text="$l('general.select')"
        />
      </div>
    </section>
  </BaseButton>
</template>

<script>
import { ElementType } from '@/constants/track';
import BaseButton from './common/BaseButton.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'NavigationCard',
  components: {
    BaseButton,
    StandardButton,
  },
  props: {
    path: {
      type: [String, Object],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    showAlertIcon: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    trackEventName: {
      type: String,
      default: '',
    },
  },
  methods: {
    trackClick() {
      if (!this.trackEventName) return;

      this.$store.dispatch('track/event', {
        event: this.trackEventName,
        elementType: ElementType.CARD,
        pageName: this.$route.meta.track.pageName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-card {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  min-height: inherit;
  height: 100%;
  background-color: $color--white;
  box-shadow: 0 0 10px 0 rgba($color--black, 0.3);
  &:hover:not(.navigation-card--readonly) {
    box-shadow: 0 0 15px 0 rgba($color--black, 0.4);
  }
  &--readonly {
    cursor: default;
  }
  &__link {
    display: block;
    width: 100%;
    max-width: var(--max-width, 400px);
    min-height: 550px;
    color: inherit;
    border: 0;
    background-color: transparent;
    padding: 0;
    text-align: left;
    &:focus {
      text-decoration: none;
    }
  }
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    &.ford {
      background: $color--fds-primary;
    }
    &.lincoln {
      background: $color--lds-primary;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 15px;
    color: $color--fds-tertiary;
    font-size: 14px;
    font-weight: $font-weight--normal;
    :deep(ul) {
      padding-left: 17px;
      list-style-type: square;

      li {
        padding-bottom: 15px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  &__title {
    position: relative;
    margin: 0;
    font-size: 24px;
    font-weight: $font-weight--normal;
    letter-spacing: 0;
  }
  &__subtitle {
    display: block;
    margin: 12px 0;
    font-size: 16px;
    font-weight: $font-weight--normal;
  }
  &__icon {
    position: absolute;
    top: -3px;
    right: -5px;
    &.app-icon-exclamation-triangle {
      color: $color--fds-caution1;
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    margin: 0 40px;
    padding: 40px 0;
    border-top: 1px solid $color--black-40;
  }
  &__coming-soon-message {
    font-size: 20px;
    font-weight: $font-weight--medium;
  }
}
</style>
