export default {
  title: 'Reports & Field tools',
  accordion1: {
    title: 'Business Reports',
    button1: 'Vehicle & DIO Pricing Counts',
    button2: 'Activation Summary and Dealer Details',
    button3: 'Vehicle Pricing Rules',
    button4: 'Vehicle Configuration Pricing',
    button5: 'Activation Ranking',
  },
  accordion2: {
    title: 'ARS Enrolment Reports',
    button1: 'ARS Enrolment Status',
  },
  accordion3: {
    title: 'Downloadable Dealer Status Reports',
    title2: 'Dealer Status Reports',
    subtitle: 'Activation Summary & Dealer Details',
    tooltip1: {
      title: 'Activation Summary & Dealer Details',
      text: 'This report includes the Full eCommerce Summary, the Dealer Details and Regional Rankings for each Territory.',
      title2: 'Activation Summary & Dealer Details',
      text2: 'This report includes a full summary of eCommerce enrollment and onboarding completion with the ability to filter down by Market Area, Region, Zone, and additional Dealer details including Dealer name, P&A code, and sales code.',
    },
    subtitle2: 'Regional Tracker (by zone)',
    tooltip2: {
      title: 'Regional Tracker by Zone',
      text: 'This report includes a Cart and Checkout summary by Region/Territory and Zone with the total number of Dealers, the number enroled and percent to target.',
    },
    selectMarket: 'Market Area',
    selectRegion: 'Region',
    subtitle3: 'Dealer Profile',
    tooltip3: {
      title: 'View Ford Dealer Profile',
      text: 'This is a mobile friendly view of a dealership\'s eCommerce readiness and activation details.',
    },
    buttonTest: 'eComm Readiness Test',
  },
  accordion4: {
    title: 'Dealer Readiness Reports <i style="font-size: 13px;">(Coming Soon)</i>',
    subtitle: 'Dealer Readiness',
    tooltip1: {
      title: 'Dealer Readiness',
      text: 'Click here to complete Dealer Readiness Check. This can also be accessed from the Dealer Profile.',
    },
    subtitle2: 'Dealer Readiness - Summary Report',
    tooltip2: {
      title: 'Dealer Readiness Summary Report',
      text: 'Provides a breakdown of eCommerce Dealer Readiness Plan completion nationally, by Region/Territory, and individually by Dealer.',
    },
    subtitle3: 'Dealer Readiness - Tracker (By Zone)',
    tooltip3: {
      title: 'Dealer Readiness Tracker by Zone',
      text: 'Provides a breakdown of eCommerce Dealer Readiness Plan by Zone.',
    },
    select1: '@:reports.accordion3.selectMarket',
    select2: '@:reports.accordion3.selectRegion',
  },
  accordion5: {
    title: 'Customer Handling Tools <i style="font-size: 13px;">(Coming Soon)</i>',
  },
  accordion6: {
    title: 'Activation Tools',
  },
  asdd: {
    title: 'Activation Summary & Dealer Details',
  },
  dealerProfile: {
    title: 'Dealer Profile',
  },
  errors: {
    loadDealerListFailed: 'Failed to load the list of dealerships.',
    loadDealerDataFailed: 'Failed to load data for the selected dealership. Please try again.',
    reportDownloadFailed: 'Failed to download the report. Please try again.',
  },
};
