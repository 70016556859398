<template>
  <div class="ars container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="ars-document"
      :resources="resources"
    />
    <div class="ars__toast-wrapper">
      <AppToast
        v-if="isEnrolledInARS"
        type="success"
        :text="$l('dashboard.tradeIn.sectionOne.enrolledInARS')"
      />
      <AppToast
        v-else
        type="error"
        :text="$l('dashboard.tradeIn.sectionOne.notEnrolledInARS')"
      />
    </div>
    <p>{{ $l('dashboard.tradeIn.sectionOne.paragraphOne') }}</p>
    <DashboardDivider />
    <p>{{ $l('dashboard.tradeIn.sectionOne.paragraphTwo') }}</p>
    <p>{{ $l('dashboard.tradeIn.sectionOne.paragraphThree') }}</p>
    <p>{{ $l('dashboard.tradeIn.sectionOne.paragraphFour') }}</p>
    <p>{{ $l('dashboard.tradeIn.sectionOne.paragraphFive') }}</p>
    <ol class="ars__form-list">
      <li><b>{{ $l('dashboard.tradeIn.sectionOne.documents.itemOne') }}</b></li>
      <li><b>{{ $l('dashboard.tradeIn.sectionOne.documents.itemTwo') }}</b></li>
      <li>
        <span v-html="$l('dashboard.tradeIn.sectionOne.documents.itemThree')" />
        <ul>
          <li>{{ $l('dashboard.tradeIn.sectionOne.documents.subItem1') }}</li>
          <li>{{ $l('dashboard.tradeIn.sectionOne.documents.subItem2') }}</li>
          <li>{{ $l('dashboard.tradeIn.sectionOne.documents.subItem3') }}</li>
        </ul>
      </li>
      <li><b>{{ $l('dashboard.tradeIn.sectionOne.documents.itemFour') }}</b></li>
    </ol>
    <div class="ars__form-documents-wrapper">
      <DocumentCard
        v-for="document in formDocuments.documents"
        :id="`ars-form-${document.documentID}`"
        :key="document.documentID"
        :title="document.title"
        :document-name="document.document"
        :file-path="`${documentPath}/${document.document}`"
        :thumbnail="document.thumbnail"
        :description="document.longdesc"
      />
    </div>
    <p>
      {{ $l('dashboard.tradeIn.sectionOne.paragraphSix.partOne') }}
      <AppLink
        to="mailto:cars4@ford.com"
        track-event-name="click_mailto:cars4@ford.com"
        track-section-name="ars"
      >
        Cars4@ford.com
      </AppLink>
      {{ $l('dashboard.tradeIn.sectionOne.paragraphSix.partTwo') }}
    </p>
    <DashboardDivider />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <p>{{ $l('dashboard.tradeIn.sectionOne.paragraphSeven') }}</p>
      </div>
      <div class="sq-btn-wrapper col-xs-12 col-sm-5 col-lg-4 d-flex justify-center">
        <StandardButton
          track-event-name="click_updateTradeInTeam"
          track-section-name="tradeIn"
          size="lg"
          :text="$l('dashboard.tradeIn.sectionOne.linkOne')"
          @click="openTeamSetupAccordion"
        />
      </div>
    </div>
    <div class="d-flex justify-center mt-4">
      <AppToast
        v-if="tradeInTeam.length > 0"
        :text="tradeInTeam.join(', ')"
      />
      <AppToast
        v-else
        type="error"
        :text="$l('dashboard.tradeIn.sectionOne.noTradeInMembers')"
      />
    </div>
    <DashboardDivider />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <p>
          {{ $l('dashboard.tradeIn.sectionOne.paragraphEight.partOne') }}
          <AppLink
            to="https://www.dsps.dealerconnection.com"
            track-event-name="click_https://www.dsps.dealerconnection.com"
            track-section-name="ars"
          >
            www.dsps.dealerconnection.com.
          </AppLink>
          {{ $l('dashboard.tradeIn.sectionOne.paragraphEight.partTwo') }}
        </p>
      </div>
      <div class="sq-btn-wrapper col-xs-12 col-sm-5 col-lg-4 d-flex justify-center">
        <StandardButton
          track-event-name="click_setEntitlements"
          track-section-name="tradeIn"
          size="lg"
          to="https://www.dsps.dealerconnection.com/"
          :text="$l('dashboard.tradeIn.sectionOne.linkTwo')"
        />
      </div>
    </div>
    <div class="d-flex justify-center mt-4">
      <AppToast
        v-if="entitlements.length > 0"
        :text="entitlements.join(', ')"
      />
      <AppToast
        v-else
        type="error"
        :text="$l('dashboard.tradeIn.sectionOne.noEntitlements')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import AppLink from './common/AppLink.vue';
import AppToast from './common/AppToast.vue';
import DocumentCard from './DocumentCard.vue';
import DashboardDivider from './DashboardDivider.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ARS',
  components: {
    AppToast,
    StandardButton,
    DocumentCard,
    DashboardDivider,
    DashboardResourcesDisplay,
    AppLink,
  },
  computed: {
    ...mapGetters({
      documentPath: 'dashboard/documentPath',
      resources: 'dashboard/tradeIn/activeResources',
      formDocuments: 'dashboard/tradeIn/activeFormDocuments',
      entitlements: 'dashboard/tradeIn/entitlements',
      isEnrolledInARS: 'dashboard/tradeIn/isEnrolledInARS',
      tradeInTeam: 'dashboard/tradeIn/tradeInTeam',
    }),
  },
  methods: {
    async openTeamSetupAccordion() {
      const { enrollment } = this.$store.getters['dashboard/accordions/accordions'];

      await this.$store.dispatch(
        'dashboard/accordions/openAccordion',
        enrollment.id,
      );

      this.$store.dispatch(
        'dashboard/accordions/openSubcordion',
        enrollment.dealerInformationTeamSetup.id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ars {
  &__toast-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
  }
  &__form-documents-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 100%;
    margin: 32px 0;
  }
  .sq-btn-wrapper {
    @include respond(sm-up) {
      justify-content: flex-end;
    }
  }
  &__form-list {
    padding-left: 20px;
    font-stretch: condensed;
    :deep(b) {
      font-weight: 500;
    }
  }
}
</style>
