<template>
  <BaseButton
    class="search-button"
    :class="$store.getters['user/activeBrandName']"
    :to="to"
    @click="$emit('click')"
  >
    <span
      class="search-button__icon"
      :class="`app-icon-${icon}`"
    />
    <span class="search-button__text">{{ text }}</span>
  </BaseButton>
</template>

<script>
import BaseButton from './common/BaseButton.vue';

export default {
  name: 'ResourceCenterSearchButton',
  components: {
    BaseButton,
  },
  emits: ['click'],
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'search',
    },
  },
};
</script>

<style lang="scss" scoped>
.search-button {
  --color: #{$color--fds-primary};
  --hover-color: #{$color--fds-secondary};
  &.lincoln {
    --color: #{$color--lds-primary};
    --hover-color: #{$color--lds-secondary};
  }

  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color);
  font-size: 18px;
  &:hover {
    color: var(--hover-color);
    .search-button__text {
      text-decoration: underline;
    }
  }
  &__icon {
    margin-right: 8px;
  }
}
</style>
