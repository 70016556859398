<template>
  <form
    class="search-bar"
    :class="$store.getters['user/activeBrandName']"
    @submit.prevent="submitForm"
  >
    <span
      v-text="displayedLabel"
      class="search-bar__input-label"
      :class="{ 'search-bar__input-label--visible': inputIsFocused || inputValue }"
    />
    <div class="search-bar__content">
      <label
        v-outside-click="closeList"
        class="search-bar__input-wrapper"
        :for="id"
      >
        <span class="search-bar__input-search-icon" />
        <input
          v-model.trim="inputValue"
          ref="searchInput"
          class="search-bar__input"
          type="search"
          :for="id"
          :placeholder="inputIsFocused ? '' : displayedLabel"
          @keyup.up="moveCursor(-1)"
          @keyup.down="moveCursor(1)"
          @keyup.esc="closeList"
          @focus="inputIsFocused = true"
          @blur="inputIsFocused = false"
        />
        <div
          v-if="showListbox"
          ref="listbox"
          class="search-bar__list"
          role="listbox"
        >
          <a
            v-for="(result, index) in suggestedResults"
            v-text="result"
            :key="JSON.stringify(result)"
            class="search-bar__list-option"
            href="#"
            role="option"
            @focus="activeOptionIndex = index"
            @keyup.up="moveCursor(-1)"
            @keyup.down="moveCursor(1)"
            @click.prevent="handleSelection(result)"
          />
        </div>
        <button
          class="search-bar__clear-button"
          type="button"
          aria-label="Clear search"
          :class="{ 'search-bar__clear-button--show': inputValue }"
          @click="inputValue = ''"
        />
      </label>
      <div class="search-bar__submit-button-wrapper">
        <StandardButton
          type="submit"
          :disabled="disabled"
          :text="displayedButtonText"
        />
      </div>
    </div>
  </form>
</template>

<script>
import _debounce from 'lodash/debounce';
import StandardButton from './StandardButton.vue';

const MIN_CHARACTERS = 3;

export default {
  name: 'AppSearchBar',
  components: {
    StandardButton,
  },
  emits: ['submit', 'get-suggested-results', 'clear-suggested-results'],
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    suggestedResults: {
      type: Array,
      default: () => ([]),
    },
    checkMinCharacters: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: '',
      inputIsFocused: false,
      shouldGetSuggestedResults: true,
      activeOptionIndex: -1,
    };
  },
  computed: {
    displayedLabel() {
      if (this.label) return this.label;

      return this.$l('general.search');
    },
    displayedButtonText() {
      if (this.buttonText) return this.buttonText;

      return this.$l('general.search');
    },
    showListbox() {
      return this.suggestedResults.length > 0 && this.inputValue.length >= MIN_CHARACTERS;
    },
  },
  watch: {
    inputValue() {
      if (this.inputValue.length < MIN_CHARACTERS || !this.shouldGetSuggestedResults) {
        this.clearSuggestedResults();
        this.shouldGetSuggestedResults = true;
        return;
      }

      this.getSuggestedResults();
    },
  },
  methods: {
    moveCursor(increment) {
      const newIndex = this.activeOptionIndex + increment;

      if (newIndex > -1 && newIndex < this.$refs.listbox.children.length) {
        this.$refs.listbox.children[newIndex].focus();
      }
    },
    getSuggestedResults: _debounce(function () {
      this.$emit('get-suggested-results', this.inputValue);
    }, 500),
    clearSuggestedResults() {
      this.$emit('clear-suggested-results');
    },
    handleSelection(value) {
      if (value === this.inputValue) {
        this.clearSuggestedResults();
        return;
      }

      this.shouldGetSuggestedResults = false;

      this.inputValue = value;
    },
    submitForm() {
      if (this.disabled) return;

      if (this.inputValue.length === 0) return;

      if (this.checkMinCharacters && this.inputValue.length < MIN_CHARACTERS) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('resourcesSearchBar.submitError'),
        });

        return;
      }

      this.$emit('submit', this.inputValue);
      this.$refs.searchInput.blur();
    },
    closeList() {
      this.clearSuggestedResults();
      this.activeOptionIndex = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  --border-color: #{$color--fds-gray2};
  --icon-color: #{$color--fds-primary};
  --list-color: #{$color--fds-primary};
  --list-option-height: 48px;
  --list-option--hover-background: #{$color--fds-gray2};
  &.lincoln {
    --border-color: #{$color--lds-disabled1};
    --icon-color: #{$color--lds-primary};
    --list-color: #{$color--lds-primary};
    --list-option--hover-background: #{$color--lds-gray4};
  }

  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 32px;
    @include respond(sm-up) {
      flex-direction: row;
      align-items: center;
    }
  }
  &__input-label {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &--visible {
      opacity: 1;
    }
  }
  &__input-wrapper {
    width: 100%;
    position: relative;
    margin: 0;
  }
  &__input-search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    color: var(--icon-color);
    font-size: 16px;
    font-family: $fds-font--ford-icons;
    position: absolute;
    left: 0;
    top: 0;
    &::before {
      content: $fds-font--ford-icons__search;
    }
  }
  &__input {
    width: 100%;
    height: 48px;
    color: var(--list-color);
    background-color: $color--white;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    padding: 0 48px;
    transition: box-shadow 0.3s ease-in-out;
    outline: none;
    &:not(::placeholder) {
      font-weight: $font-weight--normal;
    }
    &:focus {
      box-shadow: $fds-elevation__box-shadow--layer2;
    }
  }
  &__clear-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: var(--list-color);
    font-size: 16px;
    font-family: $fds-font--ford-icons;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &--show {
      opacity: 1;
    }
    &::before {
      content: $fds-font--ford-icons__clear;
    }
  }
  &__list {
    width: 100%;
    max-height: calc(5 * var(--list-option-height));
    padding: 0;
    background-color: $color--white;
    border: 1px solid var(--border-color);
    border-radius: 0 0 3px 3px;
    box-shadow: $fds-elevation__box-shadow--layer2;
    position: absolute;
    top: calc(100% - 2px);
    z-index: 1;
    overflow-y: auto;
  }
  &__list-option {
    display: flex;
    align-items: center;
    height: var(--list-option-height);
    padding: 0 16px;
    color: var(--list-color);
    cursor: pointer;
    outline: none;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $color--white;
      background-color: var(--list-option--hover-background);
    }
  }
  &__submit-button-wrapper {
    text-align: center;
  }
}
</style>
