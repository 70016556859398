export default {
  namespaced: true,
  state() {
    return {
      modal: {
        title: '',
        fileHref: '',
        fileName: '',
      },
    };
  },
  getters: {
    modal: (state) => state.modal,
  },
  mutations: {
    setModal(state, modal) {
      state.modal.title = modal.title;
      state.modal.fileHref = modal.fileHref;
      state.modal.fileName = modal.fileName;
    },
  },
  actions: {
    open({ commit }, payload) {
      const file = payload.fileHref.split('/');

      commit('setModal', {
        title: payload.title,
        fileHref: payload.fileHref,
        fileName: file[file.length - 1],
      });
    },
    close({ commit }) {
      commit('setModal', {
        title: '',
        fileHref: '',
        fileName: '',
      });
    },
  },
};
