import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

const getActiveResources = (rootGetters, subsectionId) => {
  const resources = ResourceCenter.findSectionResources(
    rootGetters['dashboard/resources'],
    resourceCenterId.section.FI_SETUP,
    subsectionId,
  );

  return ResourceCenter.getActiveVideosAndDocuments({
    resources,
    brand: rootGetters['user/activeBrand'],
    locale: rootGetters['locale/activeLocale'],
  });
};

export default {
  namespaced: true,
  state() {
    return {
      selectedId: null,
    };
  },
  getters: {
    routeOneActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.ROUTEONE_SETUP);
    },
    taxesAndFeesActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.FI_TAXES_AND_FEES);
    },
    documentFeesActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.FI_DOCUMENT_FEES);
    },
    productActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.FI_PRODUCT);
    },
    eSignActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.FI_E_SIGN);
    },
  },
};
