<template>
  <div>
    <LoadingIndicator v-show="isLoading" />
    <section
      v-show="!isLoading"
      ref="reportContainer"
      class="report-container"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as pbi from 'powerbi-client';
import LoadingIndicator from './LoadingIndicator.vue';

export default {
  name: 'PowerbiReport',
  components: {
    LoadingIndicator,
  },
  props: {
    reportId: {
      type: String,
      default: '',
    },
    lincolnReportId: {
      type: String,
      default: '',
    },
    frenchReportId: {
      type: String,
      default: '',
    },
    frenchLincolnReportId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      powerbiInstance: null,
      reportInstance: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      activeLocaleIsFrench: 'locale/activeLocaleIsFrench',
      activeBrandIsLincoln: 'user/activeBrandIsLincoln',
      accessToken: 'powerbi/accessToken',
    }),
    reportEmbedURL() {
      const fordId = (this.activeLocaleIsFrench && this.frenchReportId)
        ? this.frenchReportId
        : this.reportId;

      const lincolnId = (this.activeLocaleIsFrench && this.frenchLincolnReportId)
        ? this.frenchLincolnReportId
        : this.lincolnReportId;

      const reportId = (this.activeBrandIsLincoln && lincolnId) ? lincolnId : fordId;

      if (!reportId) return '';

      return `https://app.powerbi.com/reportEmbed?reportId=${reportId}`;
    },
  },
  watch: {
    reportEmbedURL(_, oldURL) {
      if (!oldURL) return;

      this.isLoading = true;

      if (this.reportInstance) {
        this.powerbiInstance.reset(this.$refs.reportContainer);
      }

      this.embedReport();
    },
    accessToken() {
      if (this.reportInstance) {
        this.reportInstance.setAccessToken(this.accessToken);
      }
    },
  },
  mounted() {
    this.initializePowerbi();
  },
  methods: {
    async initializePowerbi() {
      try {
        this.powerbiInstance = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory,
        );

        await this.$store.dispatch('powerbi/load');

        this.embedReport();
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: 'Failed to load the report. Please refresh the page and try again.',
        });
      }
    },
    async embedReport() {
      if (!this.reportEmbedURL) return;

      this.reportInstance = this.powerbiInstance.embed(this.$refs.reportContainer, {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        permissions: pbi.models.Permissions.All,
        embedUrl: this.reportEmbedURL,
        accessToken: this.accessToken,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
      });

      this.reportInstance.off('rendered');

      this.reportInstance.on('rendered', () => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.report-container {
  height: 600px;
  &:deep(iframe) {
    border: none !important;
  }
}
</style>
