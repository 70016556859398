export default {
  notifications: {
    title: 'Notifications',
    arsNotSet: 'Your Store does not have ARS entitlements set',
    customerHandling: 'Your Store does not have Client Handling entitlements set',
    accessoryPricingNotSet: 'Your Store does not have Accessory Pricing entitlements set',
    evCertified: 'Your Store is EV (Electric Vehicle) certified',
  },
  enrollment: {
    terms: {
      paragraphOne: 'In order to successfully enrol you must assign at least one Key Decision Maker (KDM) within your Store that will have authority to make eCommerce Program decisions.',
      paragraphTwo: 'When updated Terms & Conditions are sent, your Store will need to agree to them to remain in the program. The functionality below allows you to add up to three Key Decision Makers, in addition to yourself, who will be authorized to agree to the updated Terms & Conditions.',
    },
    teamSetup: {
      title: 'Store Information & Team Setup',
    },
    submit: {
      successMessage: 'Congratulations, you have successfully enroled your Store.',
    },
  },
  remoteDelivery: {
    description: 'Please choose whether your Store would like to present an option for clients to indicate their interest in remote delivery in the eCommerce experience. If offered, clients can check a box to indicate their remote delivery interest which will appear in their deal submission. All remote delivery details will be handled offline between your Store and your client. Store agrees to remain compliant with federal and local guidelines.',
    label: 'Would your Store like to present remote delivery as on option to clients in the eCommerce experience?',
  },
  stripe: {
    title: 'Stripe Account (Required for Direct-to-Store Payments)',
    sectionOne: {
      videoTitle: 'LincolnPay Sales MID Setup for eCommerce',
      description: 'The new Lincoln eCommerce experiences introduce the ability for Clients to submit Reservations, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Stores. To accept eCommerce Client-submitted funds, Lincoln stores must set up a Stripe account. To begin the Stripe account application process, click on the ‘Stripe Account Setup’ button.',
      descriptionActive: 'The new Lincoln eCommerce experiences introduce the ability for Clients to submit Reservations, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Stores. To view your Stripe account details, click on the ‘Stripe Account & Fees’ button.',
      modal: {
        subtitle: 'Account IDs for Dual Stores',
      },
      modalKdm: {
        paragraphOne: 'Our records indicate you are not a Key Decision Maker for your Store. The following people are Key Decision Makers: {names}',
      },
      modalAccounts: {
        paragraphOne: 'Our records indicate you are setting up Stripe for a dual Store, which provides you the choice to set up and receive one or two Stripe ID numbers. Click your choice below.',
      },
    },
  },
  accessoryPricing: {
    sectionOne: {
      paragraphOne: 'The eCommerce Accessory Module provides Stores the opportunity to set pricing structure and labor rates for accessories. Clients will then be able to select the priced accessories and add them to their new vehicle in the eCommerce experiences.',
      paragraphThree: 'Setting accessory pricing entitlements is a mandatory step that must be completed before entering the accessory module. Before pricing can be completed, your administrator must assign EMP_eComm_Accessories entitlement at www.dsps.dealerconnection.com. Only authorized Store users with authority to set pricing should be granted accessory pricing module entitlements.',
      errorToast: 'Your Store does not have entitlements set.',
    },
  },
  vehiclePricing: {
    sectionOne: {
      description: `
        <p>The Vehicle Locator Plus (VL Plus) eCommerce Pricing Module provides Stores the ability to complete vehicle pricing to include Store-installed options (DIOs) to be presented to Clients within the eCommerce experiences. The Store Set Price will be the price shown for your eligible vehicles, prior to any incentives, rebates, taxes and/or fees. Click: ‘SET VEHICLE PRICING’ to access the module and complete pricing.<p/>
        <p>Please note: work done in VL Plus can take up to 24 hours to register in the Dealer Portal while systems are transferring information.</p>
      `,
      setEntitlements: 'Setting vehicle pricing entitlements is a mandatory step that must be completed before entering the vehicle pricing module. Before pricing can be completed, your administrator must assign the VL_PRICE entitlement at www.dsps.dealerconnection.com. Only authorized Store users with authority to set pricing should be granted vehicle pricing module entitlements.',
      errorToast: 'Your Store does not have entitlements set.',
    },
  },
  fiSetup: {},
  tradeIn: {
    sectionOne: {
      enrolledInARS: 'Your Store is enroled in ARS.',
      notEnrolledInARS: 'Your Store is not enroled in ARS.',
      noTradeInMembers: 'Your Store does not have a trade-in team member set. Please click ‘Update Trade-In Team.’',
      noEntitlements: 'Your Store does not have entitlements set.',
      paragraphOne: 'Lincoln has a built a vehicle trade-in appraisal process that allows a Client to enter their trade-in vehicle information to receive a transactional offer from Lincoln. Your Lincoln Store will maintain full control of confirming the Client has represented the vehicle accurately and making decisions about accepting the vehicle to retain for your inventory, rejecting, or sending the vehicle to auction through the Automotive Remarketing Services (ARS) Dealer Trade Program (DTP) to redeem the Lincoln offer. Stores must be enroled in ARS DTP to redeem Lincoln offers.',
      paragraphThree: 'The ARS program provides Ford of Canada Dealers the opportunity to sell their pre-owned vehicles, of any brand, at Lincoln-sponsored auctions intermixed with other Lincoln company vehicles, assisting with Store profitability and inventory relief by offering an alternative wholesale outlet for selling pre-owned vehicles.',
      paragraphSix: {
        partTwo: ' with the Subject: "<your Store name> - ARS Enrolment Docs.". Enrolment can take up to 10 business days to complete.',
      },
      paragraphEight: {
        partTwo: ' Only authorized Store users with authority to manage trade-ins should be granted ARS Trade-In module entitlements.',
      },
    },
  },
  customerHandling: {
    title: 'Client Handling <i style="font-size: 13px;">(Coming Soon)</i>',
    sectionOne: {
      videoTitle: 'eCommerce Client Handling Overview',
      paragraphOne: 'The eCommerce experiences provide your Clients the ability to submit New Vehicle Purchase Requests with direct Client-to-store deposits. Any requests a Client submits with your Lincoln store will flow directly into your CRM and into the new Client Handling Pages. The contact elected to receive these alerts during enrolment will also receive a hot email alert, notifying them of the new request and directing them to the Client Handling Pages to take action. The Client Handling Pages provide you access to the selections the Client has made in the online experience. Here you will be able to make decisions on things like accepting the submission, deposits, down payments, accepting trade-in requests and responding to inquiries for online paperwork.',
      paragraphTwo: 'The overview below provides job aids and guides for review before accessing the Client Handling Pages to view and action upon requests.',
      paragraphThree: 'The EMP_eComm_CustomerHandling permission is required to access and manage New Vehicle Purchase Requests in the eCommerce Client Handling Pages. Your Lincoln store administrator can set entitlements at www.dsps.dealerconnection.com. Only authorized Store users with authority to manage New Vehicle Purchase Requests should be granted this entitlement.',
      linkOne: 'client handling pages',
      errorToast: 'Your Store does not have entitlements set.',
      reservationRequests: {
        paragraphOne: 'Clients will be able to submit a reservation for a new vehicle for vehicle lines offering the reservation experience. The reservation will flow to the Store of their choice and contain a deposit to reserve their vehicle. Reservations placed with your Store can be viewed in your CRM and in the eCommerce Client Handling Pages.',
        paragraphTwo: 'The EMP_eComm_CustomerHandling and OrderMgt View permissions are required to access and manage Reservations in the Client Handling Pages.',
        pdfOne: 'Reservation Client Experience Overview',
        pdfTwo: 'Reservation & Order Client Handling Guide',
        pdfThree: 'Mustang Mach-e Client Handling Guide',
        pdfFour: 'Bronco Client Handling Guide',
        pdfFive: 'eCommerce Client Communications',
        pdfSix: 'eCommerce Deposits In Store',
      },
      orderRequests: {
        paragraphOne: 'The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage Order Requests in the Client Handling Pages.',
        pdfOne: 'Order Client Experience Overview',
        pdfTwo: 'Reservation & Order Client Handling Guide',
        pdfFour: 'eCommerce Client Communications',
        pdfFive: 'eCommerce Deposits In Store',
      },
      fordCartAndCheckoutRequests: {
        paragraphOne: 'eCommerce cart and checkout introduces the ability for clients to select a preferred Store in Showroom and complete the cart and checkout experience. Clients can search Store-priced inventory by payment options/methods, generate trade-in values backed by Lincoln, select from a menu of Store-priced accessories and protection plans, view personalized taxes and fees, submit a credit application with decisioning and request online paperwork with eSign. New Vehicle Purchase Requests can be viewed in your Store’s CRM and in the eCommerce Client Handling Pages. You, as the Store, will maintain full control over accepting New Vehicle Purchase Requests and making decisions in reaction to client requests. Clients will be able to submit New Vehicle Purchase Requests beginning with the Mustang Mach-E, with the expansion of full line of vehicles to follow.',
        paragraphTwo: 'The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage New Vehicle Purchase Requests in the Client Handling Pages.',
        pdfTwo: 'ecommerce Cart And Checkout Client Experience Overview',
        pdfFour: 'Client Handling Overview Job Aid',
        pdfFive: 'Client Handling Accessories Job Aid',
        pdfSix: 'Client Handling Trade-in Job Aid',
        pdfSeven: 'Client Handling F&I Job Aid',
        pdfEight: 'Client Communications',
        pdfTen: 'eCommerce Deposits In Store',
      },
      lincolnCartAndCheckoutRequests: {
        paragraphOne: 'Cart and Checkout introduces the ability for Clients to select a preferred Lincoln store in Showroom and complete the Cart and Checkout experience. Clients can search Lincoln store-priced inventory by payment options/methods, generate trade-in values backed by Lincoln, select from a menu of store-priced accessories and protection plans, view personalized taxes and fees, submit a credit application with decisioning and request online paperwork with eSign. New Vehicle Purchase Requests can be viewed in your store’s CRM and in the eCommerce Client Handling Pages. You, as the store, will maintain full control over accepting New Vehicle Purchase Requests and making decisions in reaction to Client requests. Clients will be able to submit New Vehicle Purchase Requests on your full vehicle line in Mid-Year 2021.',
        paragraphTwo: 'The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage New Vehicle Purchase Requests in the Client Handling Pages.',
        pdfOne: 'Reservation Client Experience Overview',
        pdfThree: 'Client Handling Overview Job Aid',
        pdfFour: 'Client Handling Accessories Job Aid',
        pdfFive: 'Client Handling Trade-in Job Aid',
        pdfSix: 'Client Handling F&I Job Aid',
      },
    },
  },
  customerActivity: {
    title: 'Client Activity <i style="font-size: 13px;">(Coming Soon)</i>',
    sectionOne: {
      paragraph: 'Your Store will have access to eCommerce client activity reporting in Enhanced Dealer Dashboard (EDD) . The eCommerce views will be available in the EDD menu and include rolled up metrics on the "eCommerce Reporting" view and client level activity on the "eCommerce Lead Detail" view. You can access these views with your existing credentials.  If you do not have access to EDD, contact your Store administrator to grant access at www.dsps.dealerconnection.com.',
      link: 'enhanced dealer dashboard',
    },
  },
  errors: {
    DEALER_INFO_NOT_COMPLETED: 'Add a Store phone and save before continue.',
  },
};
