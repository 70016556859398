import apiManager from '@/api';
import stringFormat from '@/helpers/stringFormat';

export default {
  namespaced: true,
  state() {
    return {
      isLoadingContacts: false,
      contacts: [],
      contactPositions: [],
      keyDecisionMakerPositions: [],
      emailLanguages: [],
    };
  },
  getters: {
    isLoadingContacts: (state) => state.isLoadingContacts,
    contacts: (state) => state.contacts,
    emailLanguages({ emailLanguages }, getters, rootState, rootGetters) {
      return emailLanguages.filter(
        ({ language }) => language === rootGetters['locale/activeLocale'],
      );
    },
    contactPositions(state, getters, rootState, rootGetters) {
      return state.contactPositions
        .filter((pos) => pos.language === rootGetters['locale/activeLocale']);
    },
    keyDecisionMakerPositions(state, getters, rootState, rootGetters) {
      return state.keyDecisionMakerPositions
        .filter((pos) => pos.language === rootGetters['locale/activeLocale']);
    },
    activeKeyDecisionMakers(_, getters) {
      return getters.contacts.filter((contact) => contact.kdm && contact.active);
    },
    activeKeyDecisionMakerNames(_, getters) {
      return getters.activeKeyDecisionMakers
        .map(({ firstName, lastName, userID }) => `${firstName} ${lastName} (${userID})`);
    },
  },
  mutations: {
    setContacts(state, contacts) {
      state.contacts = contacts;
    },
    setEmailLanguages(state, emailLanguages) {
      state.emailLanguages = emailLanguages;
    },
    setContactPositions(state, positions) {
      state.contactPositions = positions;
    },
    setKeyDecisionMakerPositions(state, positions) {
      state.keyDecisionMakerPositions = positions;
    },
    setIsLoadingContacts(state, isLoading) {
      state.isLoadingContacts = isLoading;
    },
  },
  actions: {
    async loadContacts({ commit, rootGetters }) {
      commit('setIsLoadingContacts', true);

      const activeDealership = rootGetters['user/activeDealership'];

      const { data } = await apiManager.dealer.getPersonnelData(activeDealership.dealerId);

      data.contacts = data.contacts.map((contact) => ({
        ...contact,
        firstName: stringFormat.capitalize(contact.firstName),
        lastName: stringFormat.capitalize(contact.lastName),
      }));

      commit('setContacts', data.contacts);
      commit('setEmailLanguages', data.languages);
      commit('setContactPositions', data.contactPositions);
      commit('setKeyDecisionMakerPositions', data.kdmPositions);

      commit('setIsLoadingContacts', false);
    },
  },
};
