<template>
  <ExperienceCard
    title="Advanced Access"
    bottom-title="Alternate Reservation <br /> Experiences"
    :experience-id="1"
  >
    <template #top>
      <TextButton
        text="Mustang Mach-E"
        class="text-button"
        track-event-name="click_mustangMachE"
        track-section-name="advancedAccess"
        :to="{ name: 'ModelEResourceResults', query: { experience: 9, topic: 185 }}"
      />
      <TextButton
        text="All-Electric F-150 Lightning"
        track-event-name="click_allElectricF150Lightning"
        track-section-name="advancedAccess"
        :to="{ name: 'ModelEResourceResults', query: { experience: 9, topic: 189 }}"
      />
    </template>
    <template #topFooter>
      <StandardButton
        icon-right="chevron-right"
        track-event-name="select_advancedAccess"
        track-section-name="advancedAccess"
        :to="{ name: 'ModelEResourceResults', query: { experience: 9, topic: 195 }}"
        :text="$l('experienceCard.btnText')"
      />
    </template>
  </ExperienceCard>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import TextButton from './common/TextButton.vue';
import ExperienceCard from './ModelEResourceCenterExperienceCard.vue';

export default {
  name: 'ModelEResourceCenterCardReserve',
  components: {
    StandardButton,
    TextButton,
    ExperienceCard,
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
    }),
  },
};
</script>

<style lang="scss" scoped>
.text-button{
  padding-block: 10px;
}
</style>
