<template>
  <div
    class="contact-info"
    :class="$store.getters['user/activeBrandName']"
  >
    <h3 class="contact-info__title fds-headline__6">
      {{ $l('dealerProfile.contactInformation.title') }}
    </h3>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th
              scope="col"
              style="width: 25%;"
            >
              {{ $l('dealerProfile.contactInformation.titleColumn1') }}
            </th>
            <th
              scope="col"
              style="width: 18%;"
            >
              {{ $l('dealerProfile.contactInformation.titleColumn2') }}
            </th>
            <th
              scope="col"
              style="width: 15%;"
            >
              {{ $l('dealerProfile.contactInformation.titleColumn3') }}
            </th>
            <th
              scope="col"
              style="width: 30%;"
            >
              {{ $l('dealerProfile.contactInformation.titleColumn4') }}
            </th>
            <th
              scope="col"
              style="width: 12%;"
            >
              {{ $l('dealerProfile.contactInformation.titleColumn5') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(contact, index) in formattedContacts"
            :key="index"
          >
            <td>{{ contact.title }}</td>
            <td>{{ contact.name }}</td>
            <td>{{ contact.role_Title }}</td>
            <td>{{ contact.hotAlerts }}</td>
            <td>
              <div class="contact-info d-flex justify-space-between">
                {{ contact.email }}
                <a
                  class="link"
                  :class="$store.getters['user/activeBrandName']"
                  :href="`mailto:${contact.email}`"
                >
                  {{ $l('dealerProfile.contactInformation.labelEmail') }}
                </a>
              </div>
              {{ formatPhoneNumber(contact.phone) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import stringFormat from '@/helpers/stringFormat';

export default {
  name: 'DealerProfileTableContactInformation',
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedContacts() {
      return this.contacts.map((contact) => {
        const [phone, email] = contact.contactInfo.trim().split('/');
        return {
          ...contact,
          phone,
          email: email.toLowerCase(),
          name: stringFormat.capitalize(contact.name),
        };
      });
    },
  },
  methods: {
    formatPhoneNumber(number) {
      const cleaned = (`${number}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) return `${match[1]}-${match[2]}-${match[3]}`;

      return number;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-info {
  --link-color: #{$color--fds-primary};
  &.lincoln {
    --link-color: #{$color--lds-primary};
  }
  &__title {
    margin: 0;
    margin-bottom: 24px;
  }
}
h3 {
  text-align: center;
}
.table {
  border: 1px solid #c0c0c0;
  a {
    color: var(--link-color);
  }
  thead {
    background-color: #d6d6d6;
  }
  tbody {
    text-align: left;
    tr {
      td {
        border: none;
        border-right: 1px solid #c0c0c0;
        border-bottom: 1px solid #c0c0c0;
        vertical-align: middle;

        &:last-child,
        .contact-info {
          border-bottom: 1px solid #c0c0c0;
        }

        .contact-info {
          margin-bottom: 10px;
          padding-bottom: 10px;

          .link {
            margin-left: 20px;
            white-space: nowrap;
            text-transform: uppercase;
            color: $color--fds-primary;
            &.lincoln {
              color: $color--lds-primary;
            }
          }
        }
      }
    }
  }
}
</style>
