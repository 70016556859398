<template>
  <div v-if="video || resources.documents.length">
    <div
      class="resources-display"
      :data-columns="video && resources.documents.length > 0"
    >
      <div
        v-if="video"
        class="resources-display__video-column"
      >
        <VideoCard
          v-for="video, index in resources.videos"
          :key="index"
          :title="video.title"
          :video-src="video.video"
          :thumbnail="video.thumbnail"
          :transcript-url="`${documentPath}/${video.document}`"
        />
      </div>
      <div
        v-if="resources.documents.length"
        class="resources-display__documents-column"
      >
        <DocumentCard
          v-for="document in resources.documents"
          :id="`${id}-${document.documentID}`"
          :key="document.documentID"
          :title="document.title"
          :document-name="document.document"
          :file-path="`${documentPath}/${document.document}`"
          :thumbnail="document.thumbnail"
          :description="document.longdesc"
        />
      </div>
    </div>
    <DashboardDivider v-if="renderDivider" />
  </div>
</template>

<script>
import VideoCard from './VideoCard.vue';
import DocumentCard from './DocumentCard.vue';
import DashboardDivider from './DashboardDivider.vue';

export default {
  name: 'DashboardResourcesDisplay',
  components: {
    VideoCard,
    DocumentCard,
    DashboardDivider,
  },
  props: {
    resources: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    renderDivider: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    documentPath() {
      return this.$store.getters['dashboard/documentPath'];
    },
    video() {
      return this.resources.videos[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.resources-display {
  display: grid;
  gap: 24px;
  &[data-columns="true"] {
    @include respond(sm-up) {
      grid-template-columns: 1fr 1.3fr;
    }
  }
  &__video-column {
    display: grid;
    gap: 24px;
    align-content: baseline;
  }
  &__documents-column {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
}
</style>
