<template>
  <AppDialog
    open
    :title="title"
    @close="$emit('close')"
  >
    <div class="iframe-container">
      <VimeoPlayer :video-url="videoSrc" />
    </div>
    <a
      class="share-link"
      target="_blank"
      :class="$store.getters['user/activeBrandName']"
      :href="mailTo"
      @click="trackShareByEmail"
    >
      <i class="share-link__icon app-icon-email" />
      <span>{{ $l('modalFrame.shareByEmail') }}</span>
    </a>
  </AppDialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElementType, Event } from '@/constants/track';
import AppDialog from './common/AppDialog.vue';
import VimeoPlayer from './VimeoPlayer.vue';

export default {
  name: 'VideoModal',
  components: {
    AppDialog,
    VimeoPlayer,
  },
  emits: ['close'],
  props: {
    title: {
      type: String,
      default: '',
    },
    videoSrc: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/userProfile',
      activeBrand: 'user/activeBrand',
    }),
    videoId() {
      return (this.videoSrc) ? this.videoSrc.split('/').reverse()[0] : '';
    },
    mailTo() {
      const { firstName, lastName } = this.userProfile;
      const subject = this.$l('modalFrame.emailSubject', { firstName, lastName });
      const filePath = `Video/${this.videoId}?brand=${this.activeBrand}`;

      const body = `${this.$l('modalFrame.emailBody.line1')}

        ${this.$l('modalFrame.emailBody.line2')}

        ${this.title}
        ${window.location.origin}${process.env.VUE_APP_BASE_URL}${filePath}
      `;

      return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    },
  },
  mounted() {
    this.$store.dispatch('track/event', {
      event: `${Event.OPEN_VIDEO}-${this.videoSrc.split('/').reverse()[0]}`,
      elementType: ElementType.MODAL,
      pageName: this.$route.meta.track.pageName,
    });
  },
  methods: {
    trackShareByEmail() {
      this.$store.dispatch('track/event', {
        event: `${Event.SHARE_VIDEO_BY_EMAIL}-${this.videoId}`,
        section: 'videoModal',
        elementType: ElementType.HYPERLINK,
        pageName: this.$route.meta.track.pageName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe-container {
  width: 100%;
  min-height: 0 !important;
  border: 0;
  position: relative;
}
:deep(iframe) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
:deep(div[data-vimeo-initialized]) {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border: 1px solid #cccccc;
  position: relative;
}
.share-link {
  display: inline-block;
  margin-top: 16px;
  align-self: center;
  &.ford {
    color: $color--fds-primary;
  }
  &.lincoln {
    color: $color--lds-secondary;
  }
  &__icon {
    font-size: 20px;
    margin-right: 10px;
  }
}
</style>
