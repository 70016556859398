<template>
  <div class="dealer-info-setup | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="vehicle-pricing-document"
      :resources="resources"
    />
    <p>{{ $l('dealerInformation.instructions') }}</p>
    <h3 class="dealer-info-setup__title">
      {{ $l('dealerInformation.section1Title') }}
    </h3>
    <p>
      {{ activeDealership.dealerName }} <br />
      {{ activeDealership.address }} <br />
      {{ activeDealership.city }}, {{ activeDealership.state }}
      {{ activeDealership.zip }}
    </p>
    <form
      class="dealer-info-setup__form"
      @submit.prevent="submitPhoneNumber"
    >
      <TextField
        v-model="phoneNumber"
        id="dealer-information--phone"
        mask="000-000-0000"
        :label="$l('general.phone')"
      />
      <StandardButton
        v-if="userIsKeyDecisionMaker"
        size="sm"
        track-event-name="submitForm_phoneNumber"
        track-section-name="teamSetup"
        :is-loading="isLoading"
        :disabled="phoneNumber.length !== 10"
        :text="$l('dealerInformation.saveButtonText')"
      />
    </form>
    <DashboardDealerPersonnel />
  </div>
</template>

<script>
import apiManager from '@/api';
import { mapGetters } from 'vuex';
import TextField from './common/TextField.vue';
import StandardButton from './common/StandardButton.vue';
import DashboardDealerPersonnel from './DashboardDealerPersonnel.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'DashboardTeamSetup',
  components: {
    StandardButton,
    TextField,
    DashboardDealerPersonnel,
    DashboardResourcesDisplay,
  },
  data() {
    return {
      isLoading: false,
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
      activeDealership: 'user/activeDealership',
      userProfile: 'user/userProfile',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      resources: 'dashboard/enrollment/activeResources',
    }),
  },
  watch: {
    activeDealership: {
      immediate: true,
      handler() {
        this.phoneNumber = this.activeDealership.dealershipPhone;

        if (!this.activeDealership.enrolled) {
          this.confirmPhoneNumber();
        }
      },
    },
  },
  methods: {
    async confirmPhoneNumber() {
      try {
        await this.savePhoneNumber();
        this.updateProgress();
      } catch (error) {
        console.error(error);
      }
    },
    async submitPhoneNumber() {
      this.isLoading = true;

      try {
        await this.savePhoneNumber();
        await this.updateProgress();

        this.$store.dispatch('topBanner/open', {
          message: this.$l('dealerInformation.enrollmentProgress.success'),
        });

        this.$store.dispatch('user/loadUser');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async savePhoneNumber() {
      const { success } = await apiManager.dealer.savePhoneNumber(
        this.activeDealership.dealerId,
        this.phoneNumber,
      );

      if (!success) {
        throw new Error(this.$l('dealerInformation.phoneNumber.error'));
      }
    },
    async updateProgress() {
      const { success } = await this.$store.dispatch(
        'dashboard/enrollment/updateProgress',
        'DealerInfo_Completed',
      );

      if (!success) {
        throw new Error(this.$l('dealerInformation.enrollmentProgress.error'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-info-setup {
  &__title {
    margin-top: 32px;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 40px;
    border-bottom: 1px solid #b8b8b8;
    font-weight: $font-weight--light;
  }
  &__form {
    display: grid;
    gap: 32px;
    align-items: center;
    grid-template-columns: 300px auto;
    margin-top: 48px;
    margin-bottom: 32px;
    @include respond(sm-dn) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
