import { topBannerTypes } from '@/constants/topBanner';

export default {
  namespaced: true,
  state() {
    return {
      notificationQueue: [],
      notSavedEdits: {
        notificationId: null,
        edits: [],
      },
    };
  },
  getters: {
    notificationQueue: (state) => state.notificationQueue,
    notSavedEdits: (state) => state.notSavedEdits.edits,
    notSavedEditsNotificationId: (state) => state.notSavedEdits.notificationId,
  },
  mutations: {
    setNotificationQueue(state, payload) {
      state.notificationQueue = payload;
    },
    setNotSavedEdits(state, payload) {
      state.notSavedEdits.edits = payload;
    },
    setNotSavedEditsNotificationId(state, id) {
      state.notSavedEdits.notificationId = id;
    },
  },
  actions: {
    open(context, payload) {
      const notificationQueue = Object.assign([], context.getters.notificationQueue);

      if (notificationQueue.length >= 2) return false;

      const notification = {
        id: new Date().getTime(),
        message: payload.message,
        messageKey: payload.messageKey,
        type: payload.type || topBannerTypes.SUCCESS,
        persistent: payload.persistent,
        details: payload.details || '',
        duration: payload.duration || 4000,
      };

      notificationQueue.push(notification);

      context.commit('setNotificationQueue', notificationQueue);

      if (notification.persistent) {
        return notification.id;
      }

      setTimeout(() => {
        context.dispatch('close', notification.id);
      }, notification.duration);

      return true;
    },
    close(context, notificationId) {
      const notificationQueue = Object.assign([], context.getters.notificationQueue);

      const notificationIndex = notificationQueue
        .findIndex((notification) => notification.id === notificationId);

      if (notificationIndex > -1) {
        notificationQueue.splice(notificationIndex, 1);
        context.commit('setNotificationQueue', notificationQueue);
      }
    },
    async addNotSavedEdit(context, message) {
      const notSavedEdits = Object.assign([], context.getters.notSavedEdits);
      const edit = {
        id: new Date().getTime(),
        message,
      };

      notSavedEdits.push(edit);

      context.commit('setNotSavedEdits', notSavedEdits);

      await context.dispatch('updateNotSavedEditsNotification');

      return edit.id;
    },
    async removeNotSavedEdit(context, editId) {
      const notSavedEdits = Object.assign([], context.getters.notSavedEdits);

      const editIndex = notSavedEdits.findIndex((edit) => edit.id === editId);

      if (editIndex > -1) {
        notSavedEdits.splice(editIndex, 1);

        context.commit('setNotSavedEdits', notSavedEdits);

        await context.dispatch('updateNotSavedEditsNotification');
      }
    },
    async removeManyNotSavedEdits(context, payload) {
      payload.forEach(async (editId) => {
        await context.dispatch('removeNotSavedEdit', editId);
      });
    },
    async updateNotSavedEditsNotification(context) {
      await context.dispatch('close', context.getters.notSavedEditsNotificationId);

      context.commit('setNotSavedEditsNotificationId', null);

      const { notSavedEdits } = context.getters;

      if (notSavedEdits.length < 1) return;

      let details = '<ul>';

      notSavedEdits.forEach((edit) => {
        details += `<li>${edit.message}</li>`;
      });

      details += '</ul>';

      const notificationId = await context.dispatch('open', {
        messageKey: 'general.editsNotSaved',
        persistent: true,
        type: topBannerTypes.WARNING,
        details,
      });

      context.commit('setNotSavedEditsNotificationId', notificationId);
    },
  },
};
