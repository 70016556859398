import apiManager from '@/api';
import { brands } from '@/constants/brands';
import { experiences, programId, certificationLevels } from '@/constants/experiences';

export default {
  namespaced: true,
  state() {
    return {
      activeBrand: '',
      activeExperience: experiences.BLUE,
      userProfile: {
        userId: '',
        isAllowedToImpersonate: false,
        firstName: '',
        lastName: '',
        programs: [],
      },
    };
  },
  getters: {
    userProfile({ userProfile }, getters) {
      const activeProgram = userProfile.programs.find(
        ({ programId: id }) => id === getters.activeExperienceProgramId,
      );
      return {
        userId: userProfile.userId,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        demoMessage: userProfile.demoMessage,
        dealerships: activeProgram?.dealerships || [],
        reports: activeProgram?.reports || [],
        roles: activeProgram?.roles || [],
        program: {
          id: activeProgram?.programId || '',
          name: activeProgram?.programName || '',
          progressStatus: activeProgram?.programProgressStatus || '',
        },
      };
    },
    activeDealership(_, { userProfile, activeBrand }) {
      const activeDealership = userProfile.dealerships
        .filter((dealership) => dealership.division === activeBrand)[0];

      return activeDealership || {};
    },
    userIsKeyDecisionMaker(_, { userProfile, activeBrand }) {
      const { roles } = userProfile;
      return !!roles.filter((role) => role.roleID === 1 && role.division === activeBrand)[0];
    },
    userCanChangeBetweenBrands(_, { userProfile }) {
      const { dealerships } = userProfile;
      const fordDealerships = dealerships.filter((d) => d.division === brands.FORD);
      const lincolnDealerships = dealerships.filter((d) => d.division === brands.LINCOLN);

      // If there is at least one ford dealership and one lincoln dealership
      return fordDealerships.length > 0 && lincolnDealerships.length > 0;
    },
    userCanAccessImpersonate({ userProfile }) {
      return (
        userProfile.isAllowedToImpersonate
        && process.env.VUE_APP_BASE_ENVIRONMENT !== 'production'
      );
    },
    activeBrand: (state) => (state.activeBrand),
    activeBrandName: (_, getters) => (getters.activeBrandIsLincoln ? 'lincoln' : 'ford'),
    activeBrandIsFord: ({ activeBrand }) => activeBrand === brands.FORD,
    activeBrandIsLincoln: ({ activeBrand }) => activeBrand === brands.LINCOLN,
    activeExperience: (state) => state.activeExperience,
    activeExperienceIsBlue: (_, getters) => getters.activeExperience === experiences.BLUE,
    activeExperienceIsModelE: (_, getters) => getters.activeExperience === experiences.MODEL_E,
    dealershipIsAnytown(_, { activeDealership }) {
      const anytownFordId = '22222FORDC';
      const anytownLincolnId = '33333FORDC';
      const { dealerId } = activeDealership;

      return dealerId === anytownFordId || dealerId === anytownLincolnId;
    },
    activeExperienceProgramId(_, getters) {
      return (getters.activeExperienceIsModelE) ? programId.MODEL_E : programId.BLUE;
    },
    programs: ({ userProfile }) => userProfile.programs,
    blueProgram(_, { programs }) {
      return programs.find(({ programId: id }) => id === programId.BLUE) || {};
    },
    modelEProgram(_, { programs }) {
      return programs.find(({ programId: id }) => id === programId.MODEL_E) || {};
    },
    certificationLevel(_, getters) {
      const { modelECertification: level } = getters.activeDealership;

      return {
        blueOnly: !level || level === certificationLevels.BLUE_ONLY,
        modelE: level === certificationLevels.MODEL_E,
        modelEElite: level === certificationLevels.MODEL_E_ELITE,
      };
    },
    userIsFromQuebec: (_, { activeDealership }) => activeDealership.state === 'QC',
  },
  mutations: {
    setActiveBrand(state, brand) {
      // Checks if brand is valid
      if (!Object.values(brands).includes(brand)) return;

      state.activeBrand = brand;
    },
    setActiveExperience: (state, experience) => {
      // Checks if experience is valid
      if (!Object.values(experiences).includes(experience)) return;

      state.activeExperience = experience;
    },
    setUserProfile(state, userProfile) {
      state.userProfile = {
        isAllowedToImpersonate: userProfile.isAllowedToImpersonate,
        userId: userProfile.userId,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        programs: userProfile.programs,
      };
    },
  },
  actions: {
    async loadUser({ commit }) {
      const { success, data: user } = await apiManager.login.getUser();

      if (!success) return;

      // Maps user dealerships to rename some keys
      const programs = user.dealershipPrograms.map((program) => ({
        programId: program.programID,
        programName: program.programName,
        programProgressStatus: program.program_Progress_Status,
        reports: program.reports,
        roles: program.roles,
        dealerships: program.dealership.map((d) => (
          {
            modelECertification: d.modelE_Certification,
            acceptedTC: d.acceptedTC,
            activationId: d.activationID,
            address: d.address,
            city: d.city,
            dealerCode: d.dealerCode,
            dealerId: d.dealerID,
            dealerName: d.dealerName,
            dealershipPhone: d.dealershipPhone,
            division: d.division,
            enrolled: d.enrolled,
            insurance: d.insurance,
            kdmCount: d.kdmCount,
            latestTCFileName: d.latestTCFileName,
            pa: d.pa,
            remoteDelivery: d.remoteDelivery,
            state: d.state,
            zip: d.zip,
            stripeAccountId: d.stripeAccountNumber,
          })),
        }));

      commit('setUserProfile', {
        isAllowedToImpersonate: user.isAllowedToImpersonate,
        demoMessage: user.demoMessage,
        firstName: user.firstName,
        lastName: user.lastName,
        programs,
        userId: user.userID,
      });
    },
    async changeActiveBrand({ commit, dispatch }, newActiveBrand) {
      if (!newActiveBrand) return;

      commit('setActiveBrand', newActiveBrand);

      await Promise.allSettled([
        dispatch('dealerPersonnel/loadContacts', null, { root: true }),
        dispatch('termsAndConditions/load', null, { root: true }),
        dispatch('commsCenter/load', null, { root: true }),
      ]);
    },
    changeActiveExperience({ commit }, experience) {
      commit('setActiveExperience', experience);
    },
  },
};
