import apiManager from '@/api';

export default {
  namespaced: true,
  state() {
    return {
      isLoadingData: false,
      sections: [],
    };
  },
  getters: {
    isLoadingData: (state) => state.isLoadingData,
    sections(state, getters, rootState, rootGetters) {
      const activeLocale = rootGetters['locale/activeLocale'];

      return [...state.sections].map((section) => ({
        ...section,
        title: section.titles.find(({ language }) => language === activeLocale)?.text || '',
        documents: section.documents.filter((doc) => doc.language === activeLocale),
      }));
    },
    hasUnreadContent(_, getters) {
      return getters.sections.filter(({ alert }) => alert).length > 0;
    },
  },
  mutations: {
    setSections(state, sections) {
      state.sections = sections;
    },
    setIsLoadingData(state, isLoadingData) {
      state.isLoadingData = isLoadingData;
    },
  },
  actions: {
    async load({ commit, rootGetters }) {
      commit('setIsLoadingData', true);

      const { data } = await apiManager.commsCenter.getDocuments(rootGetters['user/activeBrand']);

      commit('setSections', data.commCenter_Sections);
      commit('setIsLoadingData', false);
    },
    async setSectionAsViewed({ commit, getters, rootGetters }, subSectionId) {
      await apiManager.commsCenter.setSectionAsViewed({
        subSectionId,
        division: rootGetters['user/activeBrand'],
      });

      commit('setSections', getters.sections.map((section) => {
        if (section.subSectionID !== subSectionId) {
          return section;
        }

        return {
          ...section,
          alert: false,
        };
      }));
    },
  },
};
