<template>
  <div class="model-e-start">
    <ModelENavigationCard
      image-name="settings.svg"
      aria-label="Go to the Model e Resource Center page"
      :title="$l('modelEStart.resourceCenter.title')"
      :subtitle="$l('modelEStart.resourceCenter.subtitle')"
      :path="{ name: 'ModelEResourceCenter' }"
    >
      <p>{{ $l('modelEStart.resourceCenter.description') }}</p>
    </ModelENavigationCard>
    <ModelENavigationCard
      image-name="left-click.svg"
      aria-label="Go to the Model e Dashboard page"
      :title="$l('modelEStart.dashboard.title')"
      :subtitle="$l('modelEStart.dashboard.subtitle')"
      :path="{ name: 'ModelEDashboard' }"
    >
      <p>{{ $l('modelEStart.dashboard.description') }}</p>
      <ul class="app-model-e-list">
        <li>{{ $l('modelEStart.dashboard.text1') }}</li>
        <li>{{ $l('modelEStart.dashboard.text2') }}</li>
        <li>{{ $l('modelEStart.dashboard.text3') }}</li>
        <li>{{ $l('modelEStart.dashboard.text4') }}</li>
      </ul>
    </ModelENavigationCard>
  </div>
</template>

<script>
import ModelENavigationCard from '@/components/ModelENavigationCard.vue';

export default {
  name: 'ModelEStartView',
  components: {
    ModelENavigationCard,
  },
};
</script>

<style lang="scss" scoped>
.model-e-start {
  display: grid;
  gap: 24px;
  max-width: 800px;
  margin: 0 auto;
  p {
    font-size: 14px;
    line-height: 1.3;
  }
  ul {
    font-size: 14px;
    line-height: 2.3;
  }
}
</style>
