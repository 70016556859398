<template>
  <AppToggle
    v-bind="$attrs"
    v-model="isFrenchLocale"
    id="header-language-selector"
    has-outside-text
    :is-language-toggle="true"
    :active-text="locales.french"
    :inactive-text="locales.english"
  />
</template>

<script>
import { ElementType, Event } from '@/constants/track';
import AppToggle from './common/AppToggle.vue';

export default {
  name: 'TheHeaderLanguageSelector',
  components: {
    AppToggle,
  },
  computed: {
    locales() {
      return this.$store.getters['locale/locales'];
    },
    isFrenchLocale: {
      get() {
        return this.$store.getters['locale/activeLocale'] === this.locales.french;
      },
      set(isFrenchLocale) {
        const newLocale = isFrenchLocale ? this.locales.french : this.locales.english;

        this.$store.dispatch('locale/change', newLocale);

        this.$store.dispatch('track/event', {
          event: (isFrenchLocale)
            ? Event.SELECT_LANGUAGE_FRENCH
            : Event.SELECT_LANGUAGE_ENGLISH,
          elementType: ElementType.TOGGLE,
          pageName: this.$route.meta.track.pageName,
        });
      },
    },
  },
};
</script>
