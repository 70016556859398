<template>
  <AppDialog
    size="sm"
    centered
    style="--footer-justify-content: center;"
    :open="isActive"
    :title="title"
    @close="onClick('no')"
  >
    <p>{{ text }}</p>
    <template #footer>
      <StandardButton
        outline
        size="sm"
        :text="textCancel"
        @click="onClick('no')"
      />
      <StandardButton
        size="sm"
        :text="textContinue"
        @click="onClick('yes')"
      />
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from './common/AppDialog.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'ModalConfirmation',
  components: {
    AppDialog,
    StandardButton,
  },
  emits: ['response'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: true,
    },
    textCancel: {
      type: String,
      required: true,
    },
    textContinue: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick(type) {
      this.$emit('response', type);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-confirmation__footer {
  display: none;
  --footer-justify-content: center;
}
</style>
