export default class BaseAPI {
  constructor(axios) {
    this.axios = axios;
  }

  static addUniqueParam(url) {
    return `${url}?unique=${Math.round(+new Date())}`;
  }

  getUnique(url, options) {
    return this.axios.get(BaseAPI.addUniqueParam(url), options);
  }

  postUnique(url, data, options) {
    return this.axios.post(BaseAPI.addUniqueParam(url), data, options);
  }
}
