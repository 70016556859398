<template>
  <div class="default-layout">
    <div class="default-layout__content | container-large">
      <TheHeader v-if="shouldRenderHeaderAndFooter" />
      <TheMobileAppControls />
      <main
        id="main"
        class="container--page"
      >
        <slot />
      </main>
      <DocumentModal />
      <BrowserOutdatedModal />
      <TheFooter v-if="shouldRenderHeaderAndFooter" />
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheMobileAppControls from '@/components/TheMobileAppControls.vue';
import DocumentModal from '@/components/DocumentModal.vue';
import BrowserOutdatedModal from '@/components/BrowserOutdatedModal.vue';

export default {
  name: 'DefaultLayout',
  components: {
    TheHeader,
    TheFooter,
    TheMobileAppControls,
    DocumentModal,
    BrowserOutdatedModal,
  },
  computed: {
    shouldRenderHeaderAndFooter() {
      return this.$route.name !== 'ActivationSummary' && this.$route.name !== 'Video';
    },
  },
};
</script>

<style lang="scss" scoped>
.default-layout {
  background-color: $color--fds-gray1;
  &__content {
    padding-bottom: 60px;
    background-color: $color--white;
  }
}
</style>
