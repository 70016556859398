<template>
  <div
    class="header__menu"
    :class="[{ 'header__menu--open': isOpen }, $store.getters['user/activeBrandName']]"
  >
    <div
      v-if="showMenuContent"
      class="header__menu-content"
    >
      <TheHeaderUserInfo class="header__menu-user-info" />
      <template v-if="navbarIsVisible">
        <hr class="header__menu-divider" />
        <TheHeaderMenuListModelE
          v-if="activeExperienceIsModelE"
          class="header__list"
        />
        <TheHeaderMenuListBlue
          v-else
          class="header__list"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheHeaderUserInfo from './TheHeaderUserInfo.vue';
import TheHeaderMenuListBlue from './TheHeaderMenuListBlue.vue';
import TheHeaderMenuListModelE from './TheHeaderMenuListModelE.vue';

export default {
  name: 'TheHeaderMenu',
  components: {
    TheHeaderUserInfo,
    TheHeaderMenuListBlue,
    TheHeaderMenuListModelE,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    showMenuContent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      activeExperienceIsModelE: 'user/activeExperienceIsModelE',
    }),
    navbarIsVisible() {
      return !this.$route.meta.header?.disableNavigation;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 100px);
    position: fixed;
    left: 0;
    right: 0;
    top: 100vh;
    z-index: $z-index__the-header-menu;
    opacity: 0;
    padding: 35px 0;
    transition: all 0.3s ease-in-out;
    &.ford {
      background-color: $color--fds-primary;
    }
    &.lincoln {
      background-color: $color--lds-primary;
    }
    &--open {
      top: 100px;
      opacity: 1;
    }
  }
  &__menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  &__menu-divider {
    width: 95px;
    margin: 30px 0 20px 0;
    background-color: $color--white;
  }
  &__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  @media screen and (min-width: $breakpoint__the-header-desktop) {
    .header__menu {
      position: initial;
      justify-content: center;
      background-color: transparent;
      padding: 0;
      height: 50px;
      padding: 0 21px;
      opacity: 1;
    }
    .header__menu-content {
      max-width: unset;
      align-items: unset;
    }
    .header__menu-user-info, .header__menu-divider {
      display: none;
    }
    .header__list {
      flex-direction: row;
    }
  }
}
</style>
