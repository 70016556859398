export default {
  experience: {
    title: 'Sélectionnez par expérience client',
  },
  journey: {
    title: 'Parcours client',
  },
  operationalize: {
    title: 'Opérationnaliser commerce électronique à votre détaillant',
    description: '[NT] Three simple activities will help you operationalize commerce électronique your way — within your process, with your team, to help reach your Client Handling goals.',
  },
};
