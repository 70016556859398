export default {
  ACCESSORY_PRICING: 39,
  ACTIVATION_TOOLS: 40,
  CLIENT_HANDLING: 41,
  CLIENT_HANDLING_BY_EXPERIENCE: 42,
  CLIENT_HANDLING_TOOLS: 43,
  CUSTOMER_ACTIVITY: 44,
  CUSTOMER_HANDLING: 45,
  CUSTOMER_HANDLING_BY_EXPERIENCE: 46,
  CUSTOMER_HANDLING_TOOLS: 47,
  ENROLLMENT: 48,
  FI_SETUP: 50,
  FORDPAY: 53,
  FORDPAY_SETUP: 54,
  LINCOLN_PAY: 56,
  LINCOLN_PAY_SETUP: 57,
  PROGRAM_PERFORMANCE: 60,
  SELECT_BY_ONBOARDING_MAINTENANCE_ACTIVITY: 62,
  TRADE_IN: 64,
  VEHICLE_PRICING: 66,
  READINESS_TAB1: 67,
  READINESS_TAB2: 68,
  RESERVE: 70,
  ORDER: 71,
  CART: 72,
  CHECKOUT: 73,
  WELCOME: 74,
  CLIENT_ACTIVITY: 75,
  LATEST_COMMUNICATIONS: 76,
  STRIPE_ACCOUNT: 77,
  MODEL_E_ACCESSORY_PRICING: 92,
  MODEL_E_ENROLLMENT: 80,
  MODEL_E_TRADE_IN: 91,
  MODEL_E_STRIPE_ACCOUNT: 82,
  MODEL_E_VEHICLE_PRICING: 83,
  MODEL_E_TABLETS: 86,
  MODEL_E_CUSTOMER_HANDLING: 93,
};
