<template>
  <div
    class="row"
  >
    <div class="col-md-3">
      <div style="min-width: 180px;">
        <DropDown
          id="OrderAmount"
          :label="$l('stripeAccountIdFees.text8')"
          :list="mid.opts"
          :required="true"
          @selection="setSelection($event)"
        >
          <PopupTip
            class="stripe-account__tip"
            :text="$l('stripeAccountFeeSetup.text8')"
          />
        </DropDown>
      </div>
    </div>
    <div>
      <template v-if="!mid.deposit_Confirmed || mid.deposit == 0">
        <div class="col-md-2 d-flex align-center">
          <AppCheckbox
            v-model="mid.localConfirm"
            id="StripeOrderConfirmCheckbox"
            class="position-1"
            :label="$l('stripeAccountIdFees.text4')"
          />
        </div>
        <div class="col-md-4 d-flex align-center">
          <StandardButton
            text="Save"
            track-event-name="submitForm_order"
            track-section-name="stripeAccountOrder"
            :disabled="!userIsKeyDecisionMaker || !mid.localConfirm || mid.deposit == 0"
            class="position-2"
            @click="action"
          />
        </div>
      </template>
      <div
        v-else
        class="col-md-2"
      >
        <div class="mid-confirmed">
          {{ $l("stripeAccountIdFees.text6") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DropDown from '@/components/common/DropDownFds.vue';
import StandardButton from './common/StandardButton.vue';
import PopupTip from './common/AppTooltip.vue';
import AppCheckbox from './common/AppCheckbox.vue';


export default {
  name: 'StripeAccountIdFeesOrder',
  components: {
    DropDown,
    StandardButton,
    PopupTip,
    AppCheckbox,
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
    }),
    mid() {
      return this.$store.state.modelE.stripe.mid.order;
    },
  },
  // emits: ["selection", "action"],
  methods: {
    setSelection(selection) {
      this.$emit('selection', selection);
    },
    action() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
  .position-1 {
    margin-top: 57px;

    @include respond(xs-dn) {
      margin-top: 10px;
    }
  }
  .carryOver {
    color: $color--fds-primary;
  }
  .position-2 {
    margin-top: 44px;
    @include respond(xs-dn) {
      margin-top: 10px;
    }
  }
.stripe-account__tip {
  color: var(--app-color--secondary);
  float: right;
  position: relative;
  z-index: 1;
  margin-right: 5px;
}
.mid-confirmed {
  margin-top: 52px;
  font-size: fds-rem(16px);
  color: $color--fds-success2;
}
</style>
