<template>
  <div class="stripe">
    <header class="stripe__header">
      <h2
        v-html="$l('modelEStripe.title')"
        class="stripe__title"
      />
      <p class="stripe__subtitle">
        {{ $l('theStripe.p1') }}
      </p>
    </header>
    <ModelEStripeSection>
      <ModelEStripeAccountSetup />
      <ModelEStripeAccountFeeSetup />
    </ModelEStripeSection>
  </div>
</template>

<script>
import ModelEStripeSection from '../components/ModelEStripeSection.vue';
import ModelEStripeAccountSetup from '../components/ModelEStripeAccountSetup.vue';
import ModelEStripeAccountFeeSetup from '../components/ModelEStripeAccountFeeSetup.vue';

export default {
  name: 'ModelEStripeView',
  components: {
    ModelEStripeSection,
    ModelEStripeAccountSetup,
    ModelEStripeAccountFeeSetup,
  },
  async mounted() {
    await this.$store.dispatch('modelE/stripe/getStripeData', 'F');
  },
};
</script>

<style lang="scss" scoped>
.stripe {
  max-width: 1340px;
  margin: auto;
  margin-bottom: 100px;
  &__header {
    margin-bottom: 40px;
    text-align: center;
    color: $color--fds-primary;
  }
  &__title {
    margin: 20px 0;
    font-weight: $font-weight--light;
    :deep(sup) {
      font-size: 32%;
      top: -1.4em;
      left: -0.7em;
    }
  }
  &__subtitle {
    margin: 0;
  }
}
</style>
