<template>
  <TheHeaderMenuItem
    class="header__skip-link"
    external
    path="#main"
    :name="$l('general.skipContent')"
    :class="{ 'header__skip-link--active': showSkipLink }"
    @focus="showSkipLink = true"
    @blur="showSkipLink = false"
  />
</template>

<script>
import TheHeaderMenuItem from './TheHeaderMenuItem.vue';

export default {
  name: 'TheHeaderSkipLink',
  components: {
    TheHeaderMenuItem,
  },
  data() {
    return {
      showSkipLink: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.header__skip-link {
  opacity: 0;
  position: absolute;
  top: -50vh;
  &--active {
    opacity: 1;
    position: relative;
    top: 0;
  }
  &:not(.header__skip-link--active) + :deep(.header__list-item) {
    padding-left: 0;
  }
}
</style>
