import axios from 'axios';
import CookieManager from '@/helpers/cookieManager';

import ActivationSummary from './modules/activationSummary';
import Dealer from './modules/dealer';
import DealerAccountability from './modules/dealerAccountability';
import Document from './modules/document';
import Enrollment from './modules/enrollment';
import FAQs from './modules/faqs';
import Help from './modules/help';
import Impersonate from './modules/impersonate';
import Login from './modules/login';
import Powerbi from './modules/powerbi';
import Reports from './modules/reports';
import ResourceCenter from './modules/resourceCenter';
import Stripe from './modules/stripe';
import TermsAndConditions from './modules/termsAndConditions';
import Track from './modules/track';
import CommsCenter from './modules/commsCenter';
import VehiclePricing from './modules/vehiclePricing';
import AccessoryPricing from './modules/accessoryPricing';
import TradeIn from './modules/tradeIn';
import Notifications from './modules/notifications';
import Tablets from './modules/tablets';
import CustomerHandling from './modules/customerHandling';

const handleSuccess = (response) => response;

const handleFailure = (error) => {
  // Unauthorized error. Session possibly has expired.
  if (error.response.status === 401) {
    // Clears session cookie
    CookieManager.delete(process.env.VUE_APP_COOKIE_NAME);

    // Deletes axios header tokens
    this.setSessionHeader();
    this.setTokenHeader();

    // Pushes user to login page
    window.location.href = `${process.env.VUE_APP_BASE_URL}login`;

    throw new Error('Unauthorized');
  } else if (error.response.status === 403) {
    window.location = `${process.env.VUE_APP_BASE_URL}ineligible`;
  } else {
    throw error;
  }
};

class ApiManager {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_URL,
    });

    const cookie = CookieManager.get(process.env.VUE_APP_COOKIE_NAME) || {};

    this.setSessionHeader(cookie.sessionId);
    this.setTokenHeader(cookie.token);

    this.axiosInstance.interceptors.response.use(handleSuccess, handleFailure);

    // API data
    this.activationSummary = new ActivationSummary(this.axiosInstance);
    this.dealer = new Dealer(this.axiosInstance);
    this.enrollment = new Enrollment(this.axiosInstance);
    this.faqs = new FAQs(this.axiosInstance);
    this.help = new Help(this.axiosInstance);
    this.impersonate = new Impersonate(this.axiosInstance);
    this.login = new Login(this.axiosInstance);
    this.reports = new Reports(this.axiosInstance);
    this.termsAndConditions = new TermsAndConditions(this.axiosInstance);
    this.document = new Document(this.axiosInstance);
    this.resourceCenter = new ResourceCenter(this.axiosInstance);
    this.stripe = new Stripe(this.axiosInstance);
    this.commsCenter = new CommsCenter(this.axiosInstance);
    this.vehiclePricing = new VehiclePricing(this.axiosInstance);
    this.accessoryPricing = new AccessoryPricing(this.axiosInstance);
    this.tradeIn = new TradeIn(this.axiosInstance);
    this.dealerAccountability = new DealerAccountability(this.axiosInstance);
    this.track = new Track(this.axiosInstance);
    this.notifications = new Notifications(this.axiosInstance);
    this.tablets = new Tablets(this.axiosInstance);
    this.customerHandling = new CustomerHandling(this.axiosInstance);
    this.powerbi = new Powerbi(this.axiosInstance);
  }

  setSessionHeader(sessionId) {
    if (!sessionId) {
      delete this.axiosInstance.defaults.headers.common.sessionId;
    } else {
      this.axiosInstance.defaults.headers.common.sessionId = sessionId;
    }
  }

  setTokenHeader(token) {
    if (!token) {
      delete this.axiosInstance.defaults.headers.common.token;
    } else {
      this.axiosInstance.defaults.headers.common.token = token;
    }
  }
}

export default new ApiManager();
