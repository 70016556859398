<template>
  <section class="key-decision-maker">
    <header class="key-decision-maker__header">
      <h3 class="key-decision-maker__title">
        {{ $l('keyDecisionMaker.title') }}
        <AppTooltip
          class="key-decision-maker__tooltip"
          :text="$l('keyDecisionMaker.tooltip')"
        />
      </h3>
    </header>
    <p>{{ $l('keyDecisionMaker.description1') }}</p>
    <p class="mb-4">
      {{ $l('keyDecisionMaker.description2') }}
    </p>
    <div :key="keyDecisionMakerKey">
      <DashboardKeyDecisionMakerMember
        v-for="(member, index) in renderedContactsList"
        :key="JSON.stringify(member)"
        :index="index"
        :contact-id="member.contactID"
        :dealer-id="member.dealerID"
        :first-name="member.firstName"
        :last-name="member.lastName"
        :email="member.email"
        :position="member.position"
        :extension="member.ext"
        :phone-number="member.phone"
        :email-language="member.languageCode"
        :user-id="member.userID"
        :is-active="member.active"
        :is-key-decision-maker="member.kdm"
        :open-on-mounted="Boolean(contactToBeAdded)"
        @clear-contact-to-be-added="contactToBeAdded = null"
        @reload-contacts="getContacts"
      />
      <AddContact
        id="key-decision-maker-add-contact"
        track-event-name="click_addKeyDecisionMaker"
        :button-text="$l('keyDecisionMaker.buttons.add')"
        :contacts="contacts"
        :selected-contacts="selectedContacts"
        :disable-add-button="Boolean(contactToBeAdded)"
        @add-contact="contactToBeAdded = $event"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import AppTooltip from './common/AppTooltip.vue';
import DashboardKeyDecisionMakerMember from './DashboardKeyDecisionMakerMember.vue';
import AddContact from './DashboardAddContact.vue';

export default {
  name: 'DashboardKeyDecisionMaker',
  components: {
    AppTooltip,
    DashboardKeyDecisionMakerMember,
    AddContact,
  },
  data() {
    return {
      keyDecisionMakerKey: 0,
      contactToBeAdded: null,
    };
  },
  computed: {
    ...mapGetters({
      activeBrand: 'user/activeBrand',
      contacts: 'dealerPersonnel/contacts',
    }),
    selectedContacts() {
      return this.contacts.filter((contact) => contact.contactID && contact.kdm);
    },
    renderedContactsList() {
      const sortedContacts = [...this.selectedContacts].sort((a, b) => {
        const dateA = new Date(a.lastUpdated);
        const dateB = new Date(b.lastUpdated);

        if (dateA < dateB) {
          return -1;
        }

        if (dateA > dateB) {
          return 1;
        }

        return 0;
      });

      if (this.contactToBeAdded) {
        return [...sortedContacts, this.contactToBeAdded];
      }
      return sortedContacts;
    },
  },
  watch: {
    activeBrand() {
      this.contactToBeAdded = null;
    },
    contacts: {
      deep: true,
      handler() {
        this.keyDecisionMakerKey += 1;
      },
    },
  },
  methods: {
    async getContacts() {
      this.$store.dispatch('dealerPersonnel/loadContacts');
    },
  },
};
</script>

<style lang="scss" scoped>
.key-decision-maker {
  &__title {
    margin: 0;
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 40px;
  }
  &__tooltip {
    margin-left: 4px;
    font-size: 22px;
    position: relative;
    top: -5px;
  }
}
</style>
