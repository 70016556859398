<template>
  <AppAccordion
    :id="accordionId"
    color="primary-light"
    :status="activationSummaryStatus.customerHandling"
    :title="$l('modelEDashboard.customer.title')"
    :description="$l('modelEDashboard.customer.description')"
    :is-open="accordionIsOpen"
    @click="$store.dispatch('modelE/dashboard/accordions/openAccordion', accordionId)"
  >
    <div class="container--accordion-group">
      <AppAccordion
        :id="handlingId"
        color="secondary-light"
        :track-section-name="accordionId"
        :title="$l('modelEDashboard.customer.handling.title')"
        :is-open="activeSubcordionId === handlingId"
        @click="openSubcordion(handlingId)"
      >
        <ModelEDashboardCustomerHandling />
      </AppAccordion>
      <AppAccordion
        :id="activityId"
        color="secondary-light"
        :track-section-name="accordionId"
        :title="$l('modelEDashboard.customer.activity.title')"
        :is-open="activeSubcordionId === activityId"
        @click="openSubcordion(activityId)"
      >
        <ModelEDashboardCustomerActivity />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import ModelEDashboardCustomerHandling from './ModelEDashboardCustomerHandling.vue';
import ModelEDashboardCustomerActivity from './ModelEDashboardCustomerActivity.vue';

export default {
  name: 'ModelEAccordionCustomerHandling',
  components: {
    AppAccordion,
    ModelEDashboardCustomerHandling,
    ModelEDashboardCustomerActivity,
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      accordions: 'modelE/dashboard/accordions/accordions',
      activeAccordionId: 'modelE/dashboard/accordions/activeAccordionId',
      activeSubcordionId: 'modelE/dashboard/accordions/activeSubcordionId',
      activationSummaryStatus: 'modelE/activationSummary/status',
    }),
    accordionId() {
      return this.accordions.customerHandling.id;
    },
    handlingId() {
      return this.accordions.customerHandling.handling.id;
    },
    activityId() {
      return this.accordions.customerHandling.activity.id;
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.accordionId;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('modelE/dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
