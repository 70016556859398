<template>
  <div
    class="text-field"
    :class="[textFieldClasses, $store.getters['user/activeBrandName'], $attrs.class]"
  >
    <div class="text-field__input-wrapper">
      <span
        class="text-field__icon text-field__icon--lincoln"
        :class="iconLincolnClasses"
      />
      <label
        class="text-field__label"
        :for="id"
      >
        {{ label }} <template v-if="!required">(optional)</template>
        <slot name="labelTooltip" />
        <span
          class="text-field__icon text-field__icon--ford"
          :class="iconFordClasses"
        />
      </label>
      <textarea
        v-if="showTextareaInput"
        v-bind="$attrs"
        v-model="inputValue"
        :id="id"
        class="text-field__input text-field__input--textarea"
        :disabled="disabled"
        @keyup="$emit('keyup', $event)"
        @click="$emit('click', $event)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <input
        v-else-if="mask"
        v-bind="$attrs"
        :id="id"
        ref="input"
        class="text-field__input"
        type="text"
        :disabled="disabled"
        @keyup="$emit('keyup', $event)"
        @click="$emit('click', $event)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <input
        v-else
        v-bind="$attrs"
        v-model="inputValue"
        :id="id"
        class="text-field__input"
        :type="type"
        :disabled="disabled"
        @keyup="$emit('keyup', $event)"
        @click="$emit('click', $event)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <slot />
    </div>
    <small class="text-field__message">{{ fieldErrorMessage }}</small>
    <small
      v-if="!!description"
      v-text="description"
      class="text-field__description"
    />
  </div>
</template>

<script>
import IMask from 'imask';

export default {
  name: 'TextField',
  inheritAttrs: false,
  emits: ['update:modelValue', 'focus', 'blur', 'click', 'keyup'],
  props: {
    modelValue: { type: [String, Number], default: '' },
    id: { type: String, required: true },
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
    active: { type: Boolean, default: false },
    errorMessage: { type: [String, Object], default: '' },
    dirty: { type: Boolean, default: false },
    description: { type: String, default: '' },
    type: { type: String, default: 'text' },
    mask: { type: [String, Function], default: '' },
  },
  data() {
    return {
      imaskInstance: null,
      isActive: false,
    };
  },
  computed: {
    fieldErrorMessage() {
      // Get error message from vuelidate error message object
      if (typeof this.errorMessage === 'object') {
        return this.errorMessage.$message;
      }

      return this.errorMessage;
    },
    showTextareaInput() {
      return this.type === 'textarea';
    },
    inputIsActive() {
      return this.active || this.isActive;
    },
    inputIsFilled() {
      return Boolean(this.inputValue);
    },
    inputIsValid() {
      return !this.inputIsActive
        && !this.disabled
        && this.dirty
        && !this.fieldErrorMessage;
    },
    inputIsInvalid() {
      return !this.inputIsActive
        && !this.disabled
        && this.dirty
        && this.fieldErrorMessage;
    },
    iconFordClasses() {
      return {
        'app-icon-success': this.inputIsValid,
        'app-icon-warning-outline': this.inputIsInvalid,
      };
    },
    iconLincolnClasses() {
      return {
        'app-icon-success': this.inputIsValid,
        'app-icon-exclamation-circle-solid': this.inputIsInvalid,
      };
    },
    textFieldClasses() {
      return {
        'text-field--active': this.inputIsActive,
        'text-field--valid': this.inputIsValid && this.inputIsFilled,
        'text-field--invalid': this.inputIsInvalid,
        'text-field--filled': !this.isActive && this.inputIsFilled,
        'text-field--disabled': this.disabled,
        'text-field--textarea': this.showTextareaInput,
      };
    },
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    modelValue() {
      // Update IMask instance when prop modelValue changes
      if (this.imaskInstance) {
        this.imaskInstance.value = this.modelValue;
      }
    },
  },
  mounted() {
    this.setupIMask();
  },
  methods: {
    setupIMask() {
      if (!this.mask) return;

      this.imaskInstance = IMask(this.$refs.input, {
        mask: this.mask,
      });

      this.imaskInstance.value = this.modelValue;

      this.imaskInstance.on('accept', () => {
        this.$emit('update:modelValue', this.imaskInstance.unmaskedValue);
      });
    },
    handleFocus(event) {
      this.isActive = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.isActive = false;
      this.$emit('blur', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field {
  --animation-duration: 0.3s;
  --input-spacing: 16px;
  --input-min-width: 296px;
  --input-height: 48px;
  --icon-color: transparent;
  --icon-ford-display: none;
  --icon-lincoln-display: none;
  --message-display: none;
  --message-color: inherit;
  &.text-field--valid,
  &.text-field--invalid {
    --message-display: block;
    &.ford {
      --icon-ford-display: block;
    }
    &.lincoln {
      --icon-lincoln-display: block;
    }
  }
  &:not(.text-field--active) {
    .text-field__label {
      cursor: inherit;
    }
  }
  &__input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  &__label {
    display: flex;
    width: fit-content;
    white-space: nowrap;
    margin-left: var(--input-spacing);
  }
  &__input {
    height: var(--input-height);
    text-align: left;
    min-width: var(--input-min-width);
    padding: 0 var(--input-spacing);
    border: 0;
    outline: none;
    &--textarea {
      height: calc(var(--input-height) * 3);
      resize: vertical;
    }
  }
  &__icon {
    color: var(--icon-color);
    &--ford {
      display: var(--icon-ford-display);
    }
    &--lincoln {
      display: var(--icon-lincoln-display);
    }
  }
  &__message {
    display: var(--message-display);
    color: var(--message-color);
  }
  &__message,
  &__description {
    font-size: 12px;
  }
}
.text-field.ford {
  --input-height: 48px;
  --text-color: #{$color--fds-primary};
  --border-color: #{$color--fds-gray2};
  --background-color: #{$color--fds-gray1};
  --box-shadow: none;
  margin-top: -30px;
  .text-field__label {
    align-items: center;
    min-height: 30px;
    transform: translateY(4rem);
    will-change: font-size, transform;
    transition:
      font-size var(--animation-duration) ease-in-out,
      transform var(--animation-duration) ease-in-out;
  }
  .text-field__input {
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    transition:
      box-shadow var(--animation-duration) ease-in-out,
      background-color var(--animation-duration) ease-in-out;
  }
  .text-field__icon--ford {
    font-size: 16px;
    margin-left: 8px;
  }
  &.text-field--textarea .text-field__input {
    padding: calc(var(--input-spacing) / 2) var(--input-spacing);
  }
  &.text-field--active,
  &.text-field--filled {
    --background-color: #{$color--white};
    .text-field__label {
      font-size: 11px;
      transform: translate(calc(var(--input-spacing) * -1), 0);
    }
  }
  &.text-field--active {
    --box-shadow: #{$fds-elevation__box-shadow--layer2};
  }
  &.text-field--valid {
    --icon-color: #{$color--fds-success1};
    --border-color: #{$color--fds-success1};
    --message-color: #{$color--fds-success1};
  }
  &.text-field--invalid {
    --icon-color: #{$color--fds-error1};
    --border-color: #{$color--fds-error1};
    --message-color: #{$color--fds-error1};
  }
  &.text-field--disabled {
    --text-color: #{$color--white};
    --background-color: #{$color--fds-disabled3};
    &:not(.text-field--filled) {
      .text-field__label {
        color: $color--white;
      }
    }
  }
}
.text-field.lincoln {
  --input-height: 60px;
  --input-padding-top: 23px;
  --color: #{$color--lds-primary};
  --label-color: #{$color--lds-primary};
  --border-color: #{$color--lds-primary};
  --border-width: 1px;
  .text-field__label {
    color: var(--label-color);
    position: absolute;
    transition:
      color var(--animation-duration) linear,
      font-size calc(var(--animation-duration) * 0.75) ease-in-out,
      transform calc(var(--animation-duration) * 0.75) ease-in-out;
  }
  .text-field__input {
    padding-top: var(--input-padding-top);
    background-color: $color--lds-gray2;
    color: var(--color);
    box-shadow: 0 var(--border-width) 0 var(--border-color);
    transition: box-shadow var(--animation-duration) linear;
    &:hover {
      --border-width: 2px;
    }
  }
  .text-field__icon--lincoln {
    font-size: 16px;
    position: absolute;
    right: 16px;
  }
  &.text-field--textarea {
    --input-padding-top: 38px;
    --icon-lincoln-display: none;
    .text-field__input-wrapper {
      justify-content: flex-start;
    }
    .text-field__label {
      margin-top: calc(var(--input-padding-top) / 2);
    }
  }
  &.text-field--active,
  &.text-field--valid,
  &.text-field--invalid {
    --border-width: 2px;
  }
  &.text-field--active,
  &.text-field--filled {
    .text-field__label {
      font-size: 13px;
      transform: translateY(-12px);
    }
  }
  &.text-field--active {
    --border-color: #{$color--lds-secondary};
  }
  &.text-field--valid {
    --icon-color: #{$color--lds-success1};
    --border-color: #{$color--lds-success1};
    --label-color: #{$color--lds-success1};
    --message-color: #{$color--lds-success1};
  }
  &.text-field--invalid {
    --icon-color: #{$color--lds-error1};
    --border-color: #{$color--lds-error1};
    --label-color: #{$color--lds-error1};
    --message-color: #{$color--lds-error1};
  }
  &.text-field--disabled {
    --color: #{$color--lds-disabled1};
    .text-field__input {
      box-shadow: none;
    }
  }
}
</style>
