<template>
  <div>
    <AppAccordion
      :id="enrollmentId"
      :title="$l('dashboard.enrollment.title')"
      :is-open="accordionIsOpen"
      @click="$store.dispatch('dashboard/accordions/openAccordion', enrollmentId)"
    >
      <div class="container--accordion-group">
        <AppAccordion
          :id="termsAndConditionsId"
          color="secondary"
          :track-section-name="enrollmentId"
          :title="$l('dashboard.enrollment.terms.title')"
          :is-open="activeSubcordionId === termsAndConditionsId"
          @click="openSubcordion(termsAndConditionsId)"
        >
          <DashboardTermsAndConditions />
        </AppAccordion>
        <AppAccordion
          :id="teamSetupId"
          color="secondary"
          :track-section-name="enrollmentId"
          :title="$l('dashboard.enrollment.teamSetup.title')"
          :is-open="activeSubcordionId === teamSetupId"
          @click="openSubcordion(teamSetupId)"
        >
          <DashboardTeamSetup />
        </AppAccordion>
        <AppAccordion
          :id="remoteDeliveryId"
          color="secondary"
          :track-section-name="enrollmentId"
          :title="$l('dashboard.remoteDelivery.title')"
          :is-open="activeSubcordionId === remoteDeliveryId"
          @click="openSubcordion(remoteDeliveryId)"
        >
          <DashboardRemoteDelivery />
        </AppAccordion>
      </div>
    </AppAccordion>
    <div
      v-if="showSubmitEnrollmentButton"
      class="submit-enrollment"
    >
      <StandardButton
        :text="$l('dashboard.enrollment.submit.buttonText')"
        :is-loading="isLoading"
        @click="submitEnrollment"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import AppAccordion from './common/AppAccordion.vue';
import StandardButton from './common/StandardButton.vue';
import DashboardTermsAndConditions from './DashboardTermsAndConditions.vue';
import DashboardTeamSetup from './DashboardTeamSetup.vue';
import DashboardRemoteDelivery from './DashboardRemoteDelivery.vue';

export default {
  name: 'DashboardAccordionEnrollment',
  components: {
    AppAccordion,
    StandardButton,
    DashboardTermsAndConditions,
    DashboardTeamSetup,
    DashboardRemoteDelivery,
  },
  emits: ['enrollment-complete'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      activeBrandIsFord: 'user/activeBrandIsFord',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      accordions: 'dashboard/accordions/accordions',
      activeAccordionId: 'dashboard/accordions/activeAccordionId',
      activeSubcordionId: 'dashboard/accordions/activeSubcordionId',
      activeKeyDecisionMakers: 'dealerPersonnel/activeKeyDecisionMakers',
    }),
    accordionIsOpen() {
      return this.activeAccordionId === this.enrollmentId;
    },
    enrollmentId() {
      return this.accordions.enrollment.id;
    },
    termsAndConditionsId() {
      return this.accordions.enrollment.termsAndConditions.id;
    },
    teamSetupId() {
      return this.accordions.enrollment.dealerInformationTeamSetup.id;
    },
    remoteDeliveryId() {
      return this.accordions.enrollment.remoteDeliveryPreferences.id;
    },
    showSubmitEnrollmentButton() {
      return this.userIsKeyDecisionMaker && !this.activeDealership.enrolled;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('dashboard/accordions/openSubcordion', subcordionId);
    },
    enrollmentIsComplete(payload) {
      const errors = [];

      if (!payload.termsAndConditionsComplete) {
        errors.push(this.$l('dashboard.errors.TERMS_CONDITIONS_NOT_COMPLETED'));
      }
      if (this.activeKeyDecisionMakers.length < 1) {
        errors.push(this.$l('dashboard.errors.kdmNotSet'));
      }
      if (!payload.dealerInfoComplete) {
        errors.push(this.$l('dashboard.errors.DEALER_INFO_NOT_COMPLETED'));
      }
      if (!payload.teamSetupComplete) {
        errors.push(this.$l('dashboard.errors.teamSetupNotCompleted'));
      }

      if (!payload.remoteDeliverySet) {
        errors.push(this.$l('dashboard.errors.remoteDeliveryNotSet'));
      }

      if (errors.length) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: errors.join(' '),
          duration: 10000,
        });

        return false;
      }

      return true;
    },
    async submitEnrollment() {
      this.isLoading = true;

      try {
        const { success: submitSuccess, data } = await apiManager.enrollment.submit({
          ford: true,
          lincoln: true,
          dealerId: this.activeDealership.dealerId,
        });

        if (!submitSuccess) {
          throw new Error();
        }

        if (!this.enrollmentIsComplete(data)) {
          return;
        }

        const { success: updateProgress } = await this.$store.dispatch(
          'activationSummary/updateEnrollmentProgress',
          'EnrollmentSection_Completed',
        );

        if (!updateProgress) {
          throw new Error();
        }

        this.$emit('enrollment-complete');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('dashboard.enrollment.submit.errorMessage'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-enrollment {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 40px 0;
  padding-right: 20px;
}
</style>
