<template>
  <button
    type="button"
    class="summary-control-button"
    :class="[buttonClasses, $store.getters['user/activeBrandName']]"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span class="summary-control-button__text">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'ActivationSummaryControlButton',
  emits: ['click'],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonClasses() {
      return {
        'summary-control-button--active': this.isActive,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-control-button {
  --color: #{$color--fds-primary};
  --active-background-color: #{$color--fds-primary};
  &.lincoln {
    --active-background-color: #{$color--lds-primary};
    --color: #{$color--lds-primary};
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0 12px;
  background-color: transparent;
  color: var(--color);
  border: 0;
  position: relative;
  outline-offset: 4px;
  &--active {
    color: $color--white;
    &::before {
      content: '';
      height: 44px;
      transform: translateY(-3px);
      background-color: var(--active-background-color);
      border-radius: 32px;
      box-shadow: $fds-elevation__box-shadow--layer2;
      position: absolute;
      inset: 0;
    }
    &:first-of-type::before {
      transform: translate(-3px, -3px);
    }
    &:last-of-type::before {
      transform: translate(3px, -3px);
    }
  }
  &:not(
    :first-of-type,
    .summary-control-button--active,
    .summary-control-button--active + .summary-control-button
  ) {
    &::after {
      position: absolute;
      left: 0;
      content: '';
      width: 1px;
      height: 20px;
      background-color: $color--fds-gray2;
    }
  }
  &__text {
    position: relative;
  }
}
</style>
