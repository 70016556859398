import VueScrollTo from 'vue-scrollto';
import DASHBOARD_ACCORDIONS from '@/data/dashboardAccordions';

const findAccordionById = (accordionId) => {
  let accordion = null;

  Object.keys(DASHBOARD_ACCORDIONS).forEach((key) => {
    if (DASHBOARD_ACCORDIONS[key].id === accordionId) {
      accordion = DASHBOARD_ACCORDIONS[key];
    }
  });

  return accordion;
};

export default {
  namespaced: true,
  state: {
    accordions: DASHBOARD_ACCORDIONS,
    activeAccordionId: null,
    activeSubcordionId: null,
  },
  getters: {
    accordions: (state) => (state.accordions),
    activeAccordionId: (state) => (state.activeAccordionId),
    activeSubcordionId: (state) => (state.activeSubcordionId),
  },
  mutations: {
    setActiveAccordionId(state, accordionId) {
      state.activeAccordionId = accordionId;
    },
    setActiveSubcordionId(state, accordionId) {
      state.activeSubcordionId = accordionId;
    },
  },
  actions: {
    openAccordion({ commit, getters, dispatch }, accordionId) {
      const shouldCloseAccordion = getters.activeAccordionId === accordionId;

      const accordion = findAccordionById(accordionId);

      if (!accordion) return;

      // Enrollment accordions needs to request a different endpoint
      // to update its status
      if (accordion.id === getters.accordions.enrollment.id) {
        dispatch('activationSummary/updateEnrollmentProgress',
          accordion.step, { root: true });
      }

      if (accordion.step) {
        dispatch('activationSummary/updateProgress', accordion.step, { root: true });
      }

      dispatch('resetActiveSubcordion');

      if (shouldCloseAccordion) {
        dispatch('resetActiveAccordion');
        return;
      }
      commit('setActiveAccordionId', accordionId);
    },
    openSubcordion({ commit, getters, dispatch }, subcordionId) {
      if (!getters.activeAccordionId || getters.activeSubcordionId === subcordionId) {
        dispatch('resetActiveSubcordion');
        return;
      }

      commit('setActiveSubcordionId', subcordionId);
      dispatch('scrollToAccordion', subcordionId);
    },
    resetActiveAccordion({ commit }) {
      commit('setActiveAccordionId', null);
    },
    resetActiveSubcordion({ commit }) {
      commit('setActiveSubcordionId', null);
    },
    scrollToAccordion(_, accordionId) {
      const headerHeight = document.querySelector('#header').clientHeight;

      if (!document.getElementById(accordionId)) {
        return;
      }

      setTimeout(() => {
        VueScrollTo.scrollTo(`#${accordionId}`, 1000, {
          ease: 'ease-in-out',
          offset: (headerHeight + 15) * -1,
        });
      }, 200);
    },
  },
};
