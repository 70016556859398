<template>
  <div class="dealer-profile-table">
    <h3 class="dealer-profile-table__title fds-headline__6">
      {{ title }}
    </h3>
    <slot name="header" />
    <div class="table-responsive">
      <table class="table table-striped">
        <colgroup>
          <slot name="colgroup" />
        </colgroup>
        <tbody>
          <slot />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DealerProfileTable',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-profile-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 705px;
  margin: 0 auto;
  &__title {
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
  }
  .table-responsive {
    width: 100%;
  }
  .table {
    width: 100%;
    margin-bottom: 0;
    border: 1px solid #c0c0c0;
    thead {
      background-color: #d6d6d6;
    }
    tbody {
      text-align: left;
      tr {
        position: relative;
        td {
          border: none;
          border-right: 1px solid #c0c0c0;
          border-bottom: 1px solid #c0c0c0;
          vertical-align: middle;
          &.status {
            width: 30%;
          }
          &:last-child {
            border-bottom: 1px solid #c0c0c0;
          }
        }
      }
    }
  }
  .table-striped {
    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: #d6d6d6;
        }

        &:nth-of-type(odd) {
          background-color: transparent;
        }
      }
    }
  }
}

</style>
