<template>
  <svg
    class="spinner"
    viewBox="0 0 50 50"
    :class="[{ 'spinner--light': light }, $store.getters['user/activeBrandName']]"
    :width="size"
    :height="size"
  >
    <circle
      class="indicator"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="1"
    />
    <circle
      class="activity-line"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="3"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppSpinner',
  props: {
    size: {
      type: String,
      default: '80',
      validator(size) {
        return size >= '64';
      },
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  animation: rotate 2s linear infinite;
  &.ford {
    stroke: $color--fds-primary;
    .indicator {
      stroke: $color--fds-gray2;
    }
  }
  &.lincoln {
    stroke: $color--lds-secondary;
    .indicator {
      stroke: $color--lds-gray4;
    }
  }
  &--light .activity-line {
    stroke: $color--white;
  }
  .activity-line {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
