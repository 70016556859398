<template>
  <DealerProfileTable :title="$l('dealerProfile.dealershipInformation.title')">
    <template #header>
      <StandardButton
        class="download-button"
        size="xl"
        :text="$l('dealerProfile.downloadButton')"
        :is-loading="isDownloadingReport"
        @click="downloadDealerProfileReport"
      />
    </template>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow1') }}</td>
      <td>{{ profileInfo.dealerName }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow2') }}</td>
      <td>{{ profileInfo.dealerCode }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow3') }}</td>
      <td>{{ profileInfo.dealerAdded }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow4') }}</td>
      <td>{{ profileInfo.region }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow5') }}</td>
      <td>{{ profileInfo.zone }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow6') }}</td>
      <td>{{ profileInfo.contactSelect }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow7') }}</td>
      <td>{{ profileInfo.dealerType }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow8') }}</td>
      <td>{{ profileInfo.address }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow9') }}</td>
      <td>{{ profileInfo.city }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow10') }}</td>
      <td>{{ profileInfo.state }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow11') }}</td>
      <td>{{ profileInfo.zip }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow12') }}</td>
      <td>{{ profileInfo.phoneNum }}</td>
    </tr>
    <tr>
      <td>{{ $l('dealerProfile.dealershipInformation.titleRow13') }}</td>
      <td>{{ profileInfo.evCertified }}</td>
    </tr>
  </DealerProfileTable>
</template>

<script>
import StandardButton from './common/StandardButton.vue';
import DealerProfileTable from './DealerProfileTable.vue';

export default {
  name: 'DealerProfileTableDealershipInformation',
  components: {
    StandardButton,
    DealerProfileTable,
  },
  props: {
    selectedDealerId: {
      type: String,
      required: true,
    },
    profileInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDownloadingReport: false,
    };
  },
  methods: {
    async downloadDealerProfileReport() {
      this.isDownloadingReport = true;

      try {
        await this.$store.dispatch('reports/downloadDealerProfileReport', {
          dealerId: this.selectedDealerId,
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('reports.errors.reportDownloadFailed'),
        });
      } finally {
        this.isDownloadingReport = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-button {
  margin-bottom: 32px;
}
</style>
