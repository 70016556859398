<template>
  <header
    class="model-e-header"
    :data-columns="renderSlots"
  >
    <div
      v-if="renderSlots"
      class="model-e-header__slot--left"
    >
      <slot name="left" />
    </div>
    <h1 class="model-e-header__title | fds-headline__4">
      <slot>{{ title }}</slot>
    </h1>
    <div
      v-if="renderSlots"
      class="model-e-header__slot--right"
    >
      <slot name="right">
        <router-link
          v-if="homePath"
          class="model-e-header__button-home"
          aria-label="Go to home"
          :to="homePath"
        >
          <span class="app-icon-home-o" />
        </router-link>
      </slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'ModelEPageHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    homePath: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    renderSlots() {
      return !!(this.$slots.left || this.$slots.right || !!this.homePath);
    },
  },
};
</script>

<style lang="scss" scoped>
.model-e-header {
  display: grid;
  align-items: center;
  gap: 16px;
  padding: 0 24px;
  margin-top: 24px;
  margin-bottom: 40px;
  &[data-columns="true"] {
    grid-template-columns: minmax(56px, 1fr) 5fr minmax(56px, 1fr);
  }
  &__title {
    margin: 0;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
    text-align: center;
  }
  &__slot--right {
    place-self: center flex-end;
  }
  &__button-home {
    background-color: transparent;
    color: $color--fds-primary;
    border: none;
    font-size: 40px;
    &:hover {
      color: $color--fds-secondary;
    }
  }
}
</style>
