import VueScrollTo from 'vue-scrollto';
import DASHBOARD_ACCORDIONS from '@/data/dashboardAccordions';

const findAccordionById = (accordionId) => {
  let accordion = null;

  Object.keys(DASHBOARD_ACCORDIONS).forEach((key) => {
    if (DASHBOARD_ACCORDIONS[key].id === accordionId) {
      accordion = DASHBOARD_ACCORDIONS[key];
    }
  });

  return accordion;
};

export default {
  namespaced: true,
  state() {
    return {
      accordions: DASHBOARD_ACCORDIONS,
      activeAccordionId: null,
      activeSubcordionId: null,
    };
  },
  getters: {
    accordions: (state) => (state.accordions),
    activeAccordionId: (state) => (state.activeAccordionId),
    activeSubcordionId: (state) => (state.activeSubcordionId),
  },
  mutations: {
    setActiveAccordionId(state, accordionId) {
      state.activeAccordionId = accordionId;
    },
    setActiveSubcordionId(state, accordionId) {
      state.activeSubcordionId = accordionId;
    },
  },
  actions: {
    openAccordion({ commit, getters, dispatch }, accordionId) {
      const shouldCloseAccordion = getters.activeAccordionId === accordionId;

      const accordion = findAccordionById(accordionId);

      if (!accordion) return;

      dispatch('resetActiveSubcordion');

      if (shouldCloseAccordion) {
        dispatch('resetActiveAccordion');
        return;
      }

      if (accordion.step) {
        dispatch('modelE/activationSummary/updateProgress', accordion.step, { root: true });
      }
      commit('setActiveAccordionId', accordionId);
    },
    openSubcordion({ commit, getters, dispatch }, subccordionId) {
      if (!getters.activeAccordionId || getters.activeSubcordionId === subccordionId) {
        dispatch('resetActiveSubcordion');
        return;
      }

      const accordion = findAccordionById(getters.activeAccordionId);

      if (accordion) {
        let subccordion = null;

        Object.keys(accordion).forEach((key) => {
          if (accordion[key].id === subccordionId) {
            subccordion = accordion[key];
          }
        });

        if (subccordion && subccordion.step) {
          dispatch('modelE/activationSummary/updateProgress', subccordion.step, { root: true });
        }
      }

      commit('setActiveSubcordionId', subccordionId);
      dispatch('scrollToAccordion', subccordionId);
    },
    resetActiveAccordion({ commit }) {
      commit('setActiveAccordionId', null);
    },
    resetActiveSubcordion({ commit }) {
      commit('setActiveSubcordionId', null);
    },
    scrollToAccordion(_, accordionId) {
      if (!document.getElementById(accordionId)) return;

      const headerHeight = document.querySelector('#header').clientHeight;

      setTimeout(() => {
        VueScrollTo.scrollTo(`#${accordionId}`, 1000, {
          easing: 'ease-in-out',
          offset: (headerHeight + 15) * -1,
        });
      }, 200);
    },
  },
};
