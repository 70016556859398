<template>
  <div>
    <div
      class="row"
    >
      <div class="col-md-6">
        <DropDownFds
          id="CartCheckoutAmount"
          label="Max Amount Accepted on Credit Card"
          :required="true"
          :list="mid.opts"
          @selection="setSelection($event)"
        >
          <PopupTip
            :text="$l('stripeAccountFeeSetup.text9')"
            class="stripe-account__tip"
          />
        </DropDownFds>
      </div>
      <template v-if="!mid.deposit_Confirmed || mid.deposit == 0 ">
        <div class="col-md-2 d-flex align-center">
          <AppCheckbox
            v-model="mid.localConfirm"
            id="StripeCartcheckoutConfirmCheckbox"
            class="position-1"
            :label="$l('stripeAccountIdFees.text4')"
          />
        </div>
        <div class="col-md-4 d-flex align-center">
          <StandardButton
            text="Save"
            track-event-name="submitForm_creditCard"
            track-section-name="stripeAccountCartCheckout"
            :disabled="!userIsKeyDecisionMaker || !mid.localConfirm || mid.deposit == 0 "
            class="position-2"
            @click="action"
          />
        </div>
      </template>
      <div
        v-else
        class="col-md-2"
      >
        <div class="mid-confirmed">
          {{ $l("stripeAccountIdFees.text6") }}
        </div>
      </div>
    </div>
    <div
      class="row"
    >
      <div class="col-md-6">
        <DropDownFds
          id="CartCheckoutAmount"
          label="Max Amount Accepted with ACH"
          :list="mid.opts_ACH"
          :required="true"
          @selection="setACHSelection($event)"
        >
          <PopupTip
            :text="$l('stripeAccountFeeSetup.text10')"
            class="stripe-account__tip"
          />
        </DropDownFds>
      </div>
      <template v-if=" !mid.deposit_ACH_Confirmed || mid.deposit_ACH == 0">
        <div class="col-md-2 d-flex align-center">
          <AppCheckbox
            v-model="mid.localConfirmACH"
            id="StripeCartcheckoutConfirmCheckboxACH"
            class="position-1"
            :label="$l('stripeAccountIdFees.text4')"
          />
        </div>
        <div class="col-md-4 d-flex align-center">
          <StandardButton
            text="Save"
            track-event-name="submitForm_creditACH"
            track-section-name="stripeAccountCartCheckout"
            :disabled="!userIsKeyDecisionMaker || !mid.localConfirmACH || mid.deposit_ACH == 0"
            class="position-2"
            @click="actionACH"
          />
        </div>
      </template>
      <div
        v-else
        class="col-md-2"
      >
        <div class="mid-confirmed">
          {{ $l("stripeAccountIdFees.text6") }}
        </div>
      </div>
    </div>
    <div
      class="row"
    >
      <div class="col-md-6">
        <DropDownFds
          id="CartCheckoutAmount"
          label="All Payment Types Checkout Deposit"
          :list="mid.opts_AllPayments"
          :required="true"
          @selection="setAllPaymentsSelection($event)"
        >
          <PopupTip
            :text="$l('stripeAccountFeeSetup.text11')"
            class="stripe-account__tip"
          />
        </DropDownFds>
      </div>
      <template
        v-if="!mid.deposit_AllPaymentTypes_Confirmed || mid.deposit_AllPaymentTypes == 0"
      >
        <div class="col-md-2 d-flex align-center">
          <AppCheckbox
            v-model="mid.localConfirmAllPayments"
            id="StripeCartcheckoutConfirmCheckboxAllPayments"
            class="position-1"
            :label="$l('stripeAccountIdFees.text4')"
          />
        </div>
        <div class="col-md-4 d-flex align-center">
          <StandardButton
            text="Save"
            track-event-name="submitForm_checkoutDeposit"
            track-section-name="stripeAccountCartCheckout"
            :disabled="!userIsKeyDecisionMaker || !mid.localConfirmAllPayments || mid.deposit_AllPaymentTypes == 0"
            class="position-2"
            @click="actionAllPayments"
          />
        </div>
      </template>
      <div
        v-else
        class="col-md-2"
      >
        <div class="mid-confirmed">
          {{ $l("stripeAccountIdFees.text6") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PopupTip from './common/AppTooltip.vue';
import StandardButton from './common/StandardButton.vue';
import DropDownFds from '@/components/common/DropDownFds.vue';
import AppCheckbox from './common/AppCheckbox.vue';


export default {
  name: 'StripeAccountIdFeesCartCheckout',
  components: {
    DropDownFds,
    StandardButton,
    PopupTip,
    AppCheckbox,
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
    }),
    mid() {
      return this.$store.state.modelE.stripe.mid.cartcheckout;
    },
  },
  // emits: ["selection", "action"],
  methods: {
    setSelection(selection) {
      this.$emit('selection', selection);
    },
    setACHSelection(selection) {
      this.$emit('selectionACH', selection);
    },
    setAllPaymentsSelection(selection) {
      this.$emit('selectionAllPayment', selection);
    },
    action() {
      this.$emit('action');
    },
    actionACH() {
      this.$emit('actionACH');
    },
    actionAllPayments() {
      this.$emit('actionAllPayments');
    },
  },
};
</script>

<style lang="scss" scoped>
.position-1 {
  margin-top: 57px;
  @include respond(xs-dn) {
    margin-top: 10px;
  }
}
.carryOver{
  color: $color--fds-primary;
}
.position-2 {
  margin-top: 44px;

  @include respond(xs-dn) {
    margin-top: 10px;
  }
}
.stripe-account__tip {
  color: var(--app-color--secondary);
  float: right;
  position: relative;
  z-index: 1;
  margin-right: 5px;
}
.mid-confirmed {
  margin-top: 52px;
  font-size: fds-rem(16px);
  color: $color--fds-success2;
}
</style>
