<template>
  <AppDialog
    :open="open"
    :title="$l('stripe.accountSetup.tipsModal.title')"
    @close="$emit('close')"
  >
    <p>{{ $l('stripe.accountSetup.tipsModal.description') }}</p>
    <ul>
      <li>{{ $l('stripe.accountSetup.tipsModal.item1') }}</li>
      <li>{{ $l('stripe.accountSetup.tipsModal.item2') }}</li>
      <li>{{ $l('stripe.accountSetup.tipsModal.item3') }}</li>
      <li>
        {{ $l('stripe.accountSetup.tipsModal.item4Part1') }}
        <AppLink
          is-highlighted
          to="tel:18552256341"
          track-event-name="click_tel:18552256341"
          track-section-name="stripeSetupTips"
        >
          1-855-225-6341
        </AppLink>
        {{ $l('stripe.accountSetup.tipsModal.item4Part2') }}
        <AppLink
          is-highlighted
          to="mailto:helpdesk@ecommdealersetup.ca"
          track-event-name="click_helpdesk@ecommdealersetup.ca"
          track-section-name="stripeSetupTips"
        >
          helpdesk@ecommdealersetup.ca
        </AppLink>
      </li>
      <li>
        {{ $l('stripe.accountSetup.tipsModal.item5') }}
        <AppLink
          class="setup-tips__link"
          is-highlighted
          track-section-name="stripeSetupTips"
          :track-event-name="`click_${$l('stripe.accountSetup.tipsModal.item5Link')}`"
          :to="$l('stripe.accountSetup.tipsModal.item5Link')"
        >
          {{ $l('stripe.accountSetup.tipsModal.item5Link') }}
        </AppLink>
      </li>
      <li>
        {{ $l('stripe.accountSetup.tipsModal.item6') }}
        <AppLink
          class="setup-tips__link"
          is-highlighted
          track-section-name="stripeSetupTips"
          :track-event-name="`click_${$l('stripe.accountSetup.tipsModal.item6Link')}`"
          :to="$l('stripe.accountSetup.tipsModal.item6Link')"
        >
          {{ $l('stripe.accountSetup.tipsModal.item6Link') }}
        </AppLink>
      </li>
    </ul>
  </AppDialog>
</template>

<script>
import AppLink from './common/AppLink.vue';
import AppDialog from './common/AppDialog.vue';

export default {
  name: 'StripeModalSetupTips',
  components: {
    AppLink,
    AppDialog,
  },
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.setup-tips__link {
  word-break: break-word;
}
</style>
