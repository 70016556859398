<template>
  <AppToggle
    v-if="showToggle"
    v-bind="$attrs"
    v-model="toggleValue"
    id="header-brand-selector"
    has-outside-text
    style="--color-unchecked: #1700F4;"
    active-text="Lincoln"
    inactive-text="Ford"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { brands } from '@/constants/brands';
import { ElementType, Event } from '@/constants/track';
import AppToggle from './common/AppToggle.vue';

export default {
  name: 'TheHeaderBrandSelector',
  components: {
    AppToggle,
  },
  computed: {
    ...mapGetters({
      activeBrandIsLincoln: 'user/activeBrandIsLincoln',
      userCanChangeBetweenBrands: 'user/userCanChangeBetweenBrands',
    }),
    showToggle() {
      return this.userCanChangeBetweenBrands && !this.$route.meta.header?.disableBrandSelector;
    },
    toggleValue: {
      get() {
        return this.activeBrandIsLincoln;
      },
      set(newBrandIsLincoln) {
        const brandValue = (newBrandIsLincoln) ? brands.LINCOLN : brands.FORD;

        this.$store.dispatch('user/changeActiveBrand', brandValue);
        this.$store.dispatch('track/event', {
          event: `${Event.SELECT_BRAND}_${brandValue}`,
          elementType: ElementType.TOGGLE,
          pageName: this.$route.meta.track.pageName,
        });
      },
    },
  },
};
</script>
