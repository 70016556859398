export default {
  deactivate: {
    date: 'Votre détaillant sera désactivé le:',
    warning: '[NT] Your Store will be deactivated on:<br>{date}<br>[NT] Click on ‘Active’ to remain in the experience',
  },
  optOutPermission: {
    description: 'Vous n’avez pas l’autorisation de vous désinscrire des expériences de commerce électronique. Les personnes suivantes de votre détaillant ont l’autorisation de procéder à la désinscription:',
  },
  cancelOptOutPermission: {
    description: 'Vous pas l’autorisation d’annuler la désinscription d’une expérience de commerce électronique. Les personnes suivantes de votre détaillant ont l’autorisation nécessaire:',
  },
  reactivatePermission: {
    description: 'Vous n’avez pas l’autorisation de réactiver une expérience de commerce électronique. Les personnes suivantes de votre détaillant ont l’autorisation nécessaire:',
  },
  reservation: {
    cancelOptOut: {
      description: 'Vous avez réussi l’annulation du processus de désinscription des expériences de réservation et de commande du programme de commerce électronique pour votre détaillant.',
    },
    reactivate: {
      description: 'Vous avez réussi la réactivation de l’expérience de réservation du programme de commerce électronique pour votre détaillant.',
    },
  },
  order: {
    cancelOptOut: {
      description: 'Vous avez réussi l’annulation du processus de désinscription de l’expérience de commande du programme de commerce électronique pour votre détaillant.',
    },
    reactivate: {
      description: 'Vous avez lancé le processus de réactivation de l’expérience de commande du programme de commerce électronique pour votre détaillant. Veuillez passer en revue toutes les exigences relatives à l’activation pour le terminer; la réactivation pourrait prendre jusqu’à 24 heures.',
    },
  },
  cartCheckout: {
    cancelOptOut: {
      description: 'Vous avez réussi l’annulation du processus de désinscription de l’expérience de panier d’achats et de paiement du programme de commerce électronique pour votre détaillant.',
    },
    reactivate: {
      description: 'Vous avez lancé le processus de réactivation de l’expérience de panier d’achats et de paiement du programme de commerce électronique pour votre détaillant. Veuillez passer en revue toutes les exigences relatives à l’activation pour le terminer; la réactivation pourrait prendre jusqu’à 24 heures.',
    },
  },
};
