<template>
  <li
    class="header__list-item"
    :class="$store.getters['user/activeBrandName']"
  >
    <a
      v-if="external"
      class="header__link"
      :href="path"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <span v-html="name" />
      <i
        v-if="icon"
        class="header__link-icon"
        :class="icon"
      />
    </a>
    <router-link
      v-else
      class="header__link"
      :to="path"
    >
      <span v-html="name" />
      <i
        v-if="icon"
        class="header__link-icon"
        :class="icon"
      />
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'TheHeaderMenuItem',
  emits: ['focus', 'blur'],
  props: {
    name: {
      type: String,
      required: true,
    },
    path: {
      type: [String, Object],
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__list-item {
    font-size: 28px;
    text-align: center;
    font-weight: $font-weight--normal;
    letter-spacing: 1px;
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }
  &__link {
    color: $color--white;
    transition: border-color 0.2s ease-in-out;
    &.router-link-active {
      border-bottom: 3px solid $color--white;
    }
  }
  &__link-icon {
    display: inline-block;
    margin-left: 4px;
    font-size: 18px;
    &.app-icon-exclamation-triangle {
      color: $color--fds-caution1;
    }
  }
  @media screen and (min-width: $breakpoint__the-header-desktop) {
    .header__list-item {
      font-size: 16px;
      padding: 0 21px;
      margin-bottom: 0;
      &:first-child {
        padding-left: 0;
      }
      &:not(:last-child) {
        border-right: 1px solid #C8C8C8;
      }
      &.ford .header__link {
        color: $color--fds-primary;
        &:hover,
        &.router-link-active {
          border-color: $color--fds-secondary;
        }
        &.router-link-active {
          color: $color--fds-secondary;
        }
      }
      &.lincoln .header__link {
        color: $color--lds-primary;
        &:hover,
        &.router-link-active {
          border-color: $color--lds-secondary;
        }
        &.router-link-active {
          color: $color--lds-secondary
        }
      }
    }
    .header__link {
      display: block;
      font-size: 12px;
      border-bottom: 1px solid transparent;
      text-transform: uppercase;
      line-height: 25px;
      outline-offset: 5px;
      &:hover {
        border-bottom: 1px solid $color--fds-secondary;
      }
      &:focus {
        text-decoration: none;
      }
    }
    .header__link-icon {
      display: initial;
    }
  }
}
</style>
