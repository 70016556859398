<template>
  <li class="summary-item">
    <StatusIcon :status="status" />
    <button
      v-html="label"
      class="summary-item__label"
      type="button"
      :disabled="buttonIsDisabled"
      @click="handleButtonClick"
    />
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import StatusIcon from './StatusIcon.vue';

export default {
  name: 'ActivationSummaryItem',
  components: {
    StatusIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    accordionId: {
      type: String,
      default: '',
    },
    subcordionId: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('dashboard/accordions/', [
      'activeAccordionId',
      'activeSubcordionId',
    ]),
    buttonIsDisabled() {
      return this.disabled || !this.accordionId;
    },
  },
  methods: {
    handleButtonClick() {
      if (!this.accordionId) return;

      if (!this.subcordionId) {
        this.openAccordion();
        return;
      }

      this.openAccordionAndSubcordion();
    },
    openAccordion() {
      if (this.activeAccordionId !== this.accordionId) {
        this.$store.dispatch('dashboard/accordions/openAccordion', this.accordionId);
      }

      this.$store.dispatch('dashboard/accordions/scrollToAccordion', this.accordionId);
    },
    async openAccordionAndSubcordion() {
      if (this.activeAccordionId !== this.accordionId) {
        this.$store.dispatch('dashboard/accordions/openAccordion', this.accordionId);
      }

      if (this.activeSubcordionId === this.subcordionId) {
        this.$store.dispatch('dashboard/accordions/scrollToAccordion', this.subcordionId);
        return;
      }

      this.$store.dispatch('dashboard/accordions/openSubcordion', this.subcordionId);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-item {
  display: flex;
  list-style: none;
  &__label {
    padding: 0;
    margin-left: 8px;
    background-color: transparent;
    border: 0;
    outline-offset: 5px;
  }
}
</style>
