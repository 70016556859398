<template>
  <div class="row d-flex justify-center">
    <div class="col-sm-10 col-lg-8 text-center">
      <h3 class="fds-headline__5 welcome__heading">
        {{ $l('home.title') }}
      </h3>
      <p>{{ $l('home.welcome.paragraph1') }}</p>
      <p class="mt-4">
        {{ $l('home.welcome.paragraph2') }}
      </p>
      <LoadingIndicator
        v-if="isLoading"
        class="welcome__loading-indicator"
      />
      <div
        v-else-if="video.video"
        class="text-center d-flex justify-center mt-2"
      >
        <VideoCard
          :title="video.title"
          :video-src="video.video"
          :thumbnail="video.thumbnail"
          :transcript-url="`${documentPath}/${video.document}`"
        />
      </div>
      <StandardButton
        class="mt-2"
        track-event-name="click_getStarted"
        to="/Dashboard"
        size="lg"
        icon-right="chevron-right"
        :text="$l('home.buttonStart')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import LoadingIndicator from './LoadingIndicator.vue';
import VideoCard from './VideoCard.vue';

export default {
  name: 'HomeWelcome',
  components: {
    StandardButton,
    LoadingIndicator,
    VideoCard,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
      documentPath: 'dashboard/documentPath',
      video: 'welcome/activeVideo',
    }),
  },
  async mounted() {
    try {
      await this.$store.dispatch('welcome/getVideo');
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  &__heading {
    font-weight: $font-weight--normal;
  }
  &__loading-indicator {
    align-items: center;
    min-height: 200px;
  }
}
</style>
