<template>
  <AppAccordion
    :id="customerActivityId"
    :title="$l('dashboard.customerActivity.title')"
    :is-open="accordionIsOpen"
    @click="$store.dispatch('dashboard/accordions/openAccordion', customerActivityId)"
  >
    <div class="container--accordion-group">
      <AppAccordion
        :id="subcordionId"
        color="secondary"
        :track-section-name="customerActivityId"
        :title="$l('dashboard.customerActivity.sectionOne.title')"
        :is-open="activeSubcordionId === subcordionId"
        @click="openSubcordion(subcordionId)"
      >
        <!-- <DashboardCustomerActivity /> -->
        <ComingSoonMessage />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
// import DashboardCustomerActivity from './DashboardCustomerActivity.vue';
import ComingSoonMessage from './ComingSoonMessage.vue';

export default {
  name: 'DashboardAccordionCustomerActivity',
  components: {
    AppAccordion,
    // DashboardCustomerActivity,
    ComingSoonMessage,
  },
  computed: {
    ...mapGetters('dashboard/accordions', {
      accordions: 'accordions',
      activeAccordionId: 'activeAccordionId',
      activeSubcordionId: 'activeSubcordionId',
    }),
    activeBrandIsFord() {
      return this.$store.getters['user/activeBrandIsFord'];
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.customerActivityId;
    },
    customerActivityId() {
      return this.accordions.customerActivity.id;
    },
    subcordionId() {
      return this.accordions.customerActivity.dealerDashboard.id;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
