export default {
  title: 'Outils de production de rapports et pour le terrain',
  accordion1: {
    title: 'Rapports commerciaux',
    button1: 'Sommaire des prix de véhicules et accessoires en options',
    button2: 'Sommaire de mise en œuvre et renseignements sur les concessionnaires',
    button3: 'Règles d’établissement des prix des véhicules',
    button4: 'Prix de configuration du véhicule',
    button5: 'Classement de mise en œuvre',
  },
  accordion2: {
    title: 'Rapports sur l’inscription aux ARS',
    button1: 'État de l’inscription aux ARS',
  },
  accordion3: {
    title: 'Rapports téléchargeables sur l’état des concessionnaires',
    title2: 'Rapport sur l\'état du concessionnaire',
    subtitle: 'Sommaire de mise en œuvre et renseignements sur les concessionnaires',
    tooltip1: {
      title: 'Sommaire de mise en œuvre et renseignements sur les concessionnaires',
      text: 'Ce rapport comprend le résumé commerce électronique complet, les renseignements sur le concessionnaire et les classements régionaux pour chaque territoire.',
      title2: 'Sommaire de mise en œuvre et renseignements sur les concessionnaires',
      text2: '[NT] This report includes a full summary of eCommerce enrollment and onboarding completion with the ability to filter down by Market Area, Region, Zone, and additional Dealer details including Dealer name, P&A code, and sales code.',
    },
    subtitle2: 'Outil de suivi régional (par zone)',
    tooltip2: {
      title: 'Outil de suivi régional par zone',
      text: 'Ce rapport comprend un résumé de panier d’achats et de paiement par région ou territoire et par zone. Il comprend aussi le nombre total de concessionnaires, le nombre de ceux qui ont fait leur inscription et le pourcentage de l’objectif.',
    },
    selectMarket: 'Zone de marché totale',
    selectRegion: 'Région',
    subtitle3: 'Profil du concessionnaire',
    tooltip3: {
      title: 'Voir le profil du concessionnaire Ford',
      text: 'Il s’agit d’un affichage, adapté aux appareils mobiles, des renseignements sur l’état de préparation et la mise en œuvre d’commerce électronique par un concessionnaire.',
    },
    buttonTest: '[NT] eComm Readiness Test',
  },
  accordion4: {
    title: 'Rapports téléchargeables sur l’état de préparation des concessionnaires <i style="font-size: 13px;">(à venir bientôt)</i>',
    subtitle: '[NT] Dealer Readiness',
    tooltip1: {
      title: '[NT] Dealer Readiness',
      text: 'Cliquez ici pour exécuter la vérification de l’état de préparation du concessionnaire. Vous pouvez également y accéder à partir du profil du concessionnaire.',
    },
    subtitle2: '[NT] Dealer Readiness - Summary Report',
    tooltip2: {
      title: '[NT] Dealer Readiness Summary Report',
      text: 'Fournit une répartition du parachèvement du plan de préparation des concessionnaires pour commerce électronique à l’échelle nationale, par région ou territoire et par concessionnaire individuel.',
    },
    subtitle3: '[NT] Dealer Readiness - Tracker (By Zone)',
    tooltip3: {
      title: '[NT] Dealer Readiness Tracker by Zone',
      text: 'Fournit une répartition du plan de préparation des concessionnaires pour commerce électronique par zone.',
    },
    select1: '@:reports.accordion3.selectMarket',
    select2: '@:reports.accordion3.selectRegion',
  },
  accordion5: {
    title: 'Outils de gestion des clients <i style="font-size: 13px;">(à venir bientôt)</i>',
  },
  accordion6: {
    title: 'Outils de mise en œuvre',
  },
  asdd: {
    title: 'Sommaire de mise en œuvre et renseignements sur les concessionnaires',
  },
  dealerProfile: {
    title: 'Profil du concessionnaire',
  },
  errors: {
    loadDealerListFailed: 'Échec du chargement de la liste des concessionnaires.',
    loadDealerDataFailed: 'Échec du chargement des données pour le concessionnaire sélectionné. Veuillez réessayer.',
    reportDownloadFailed: 'Échec du téléchargement du rapport. Veuillez réessayer.',
  },
};
