import BaseAPI from './baseAPI';

export default class Report extends BaseAPI {
  async getDealers() {
    const { data } = await this.getUnique('/Report/DealerProfile');
    return data;
  }

  async getMarket() {
    const { data } = await this.getUnique('/Report/Market');
    return data;
  }

  async getRegion() {
    const { data } = await this.getUnique('/Report/Region');
    return data;
  }

  async getVehicleDIOPricingReport({ language }) {
    const { headers, data } = await this.axios.get('/Report/VehicleDIOPricing', {
      params: {
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getVehicleConfigurationPricingReport({ language }) {
    const { headers, data } = await this.axios.get('/Report/VehicleConfigurationPricing', {
      params: {
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getActivationRankingReport({ division, language }) {
    const { headers, data } = await this.axios.get('/Report/ActivationRanking', {
      params: {
        division,
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getMustangMachEPricingReport({ language }) {
    const { headers, data } = await this.axios.get('/Report/MachePricing', {
      params: {
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getPricingRulesReport({ language }) {
    const { headers, data } = await this.axios.get('/Report/PriceRules', {
      params: {
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getARSEnrollmentReport({ language }) {
    const { headers, data } = await this.axios.get('/Report/ARS', {
      params: {
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getASDDReport({ division, language }) {
    const { headers, data } = await this.axios.get('/Report/ASDD', {
      params: {
        division,
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getRegionalTrackerReport({
    division, language, regionId, marketId,
  }) {
    const { headers, data } = await this.axios.get('/Report/Zone', {
      params: {
        region: regionId,
        market: marketId,
        division,
        language,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getDealerProfile({ dealerId, division }) {
    const { data } = await this.axios.get('/Report/DealerProfile/WebReport', {
      params: {
        dealerId,
        divisionType: division === 'ford' ? 1 : 2,
      },
    });

    return data;
  }

  async getDealerProfileReport({ dealerId, division, language }) {
    const { headers, data } = await this.axios.get('/Report/DealerProfile/ExcelReport', {
      params: {
        dealerId,
        language,
        division,
      },
      headers: {
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });

    return {
      filename: Report.getFilename(headers),
      data,
    };
  }

  async getReport(type, division = 'A', market = 'A', region = '1', dealerId = '1') {
    try {
      const reportResponse = await this.getUnique('/Report', {
        params: {
          report: type,
          division,
          market,
          region,
          dealerId,
        },
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      });

      Report.prepareFileDownload(reportResponse);
    } catch (error) {
      console.log(error);
    }
  }

  static getFilename(headers) {
    const contentDisposition = headers['content-disposition'];

    return (!contentDisposition) ? 'eCommerce_report.xlsx' : contentDisposition
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim();
  }

  static prepareFileDownload(reportData, fileInfo) {
    const filename = `${fileInfo.fileName}.${fileInfo.fileExtension}`;
    const url = window.URL.createObjectURL(new Blob([reportData.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);

    link.click();
  }
}
