<template>
  <form
    class="feedback-form"
    :class="$store.getters['user/activeBrandName']"
    @submit.prevent="submitForm"
  >
    <section class="feedback-form__section">
      <p class="feedback-form__section-title">
        {{ $l('readinessCheck.feedback.titleOne') }}
      </p>
      <div class="feedback-form__row">
        <AppCheckbox
          v-model="form.hasCompletedManagerMeeting"
          id="readiness-feedback--meeting-completed"
          class="feedback-form__checkbox"
          :label="$l('readinessCheck.feedback.checkboxOne')"
        />
        <div class="feedback-form__radio-wrapper feedback-form__padding-left">
          <RadioButton
            v-model="form.managerMeetingCompletedWithFordField"
            id="readiness-feedback--radio-with-field"
            class="feedback-form__checkbox"
            :radio-value="true"
          >
            <span v-html="$l('readinessCheck.feedback.radioOne')" />
          </RadioButton>
          <RadioButton
            v-model="form.managerMeetingCompletedWithFordField"
            id="readiness-feedback--radio-without-field"
            class="feedback-form__checkbox"
            :radio-value="false"
          >
            <span v-html="$l('readinessCheck.feedback.radioTwo')" />
          </RadioButton>
        </div>
      </div>
      <div class="feedback-form__row">
        <AppCheckbox
          v-model="form.hasCompletedDepartmentMeeting"
          id="readiness-feedback--department-meeting"
          class="feedback-form__checkbox"
        >
          {{ $l('readinessCheck.feedback.checkboxTwo.pieceOne') }}
          <br />
          {{ $l('readinessCheck.feedback.checkboxTwo.pieceTwo') }}
        </AppCheckbox>
      </div>
      <div class="feedback-form__row row">
        <div class="col-md-7">
          <AppCheckbox
            v-model="form.hasCompletedReadinessPlan"
            id="readiness-feedback--readiness-plan-completed"
            class="feedback-form__checkbox"
          >
            <span v-html="$l('readinessCheck.feedback.checkboxThree', { name: dealer.name })" />
          </AppCheckbox>
        </div>
        <div class="col-md-5">
          <TextField
            v-model.trim="form.wslxId"
            id="readiness-feedback--wslx-id"
            :label="$l('readinessCheck.feedback.wslxID')"
          />
        </div>
      </div>
    </section>
    <section class="feedback-form__section">
      <p class="feedback-form__section-title">
        {{ $l('readinessCheck.feedback.titleTwo') }}
      </p>
      <div class="feedback-form__row">
        <p>{{ $l('readinessCheck.feedback.labelOne') }}</p>
        <div class="feedback-form__radio-wrapper feedback-form__padding-left">
          <RadioButton
            v-model="form.resourcesAreHelpful"
            id="readiness-feedback--resources-helpful-yes"
            class="feedback-form__checkbox"
            :radio-value="true"
            :label="$l('general.yes')"
          />
          <RadioButton
            v-model="form.resourcesAreHelpful"
            id="readiness-feedback--resources-helpful-no"
            class="feedback-form__checkbox"
            :radio-value="false"
            :label="$l('general.no')"
          />
        </div>
      </div>
      <div class="feedback-form__row">
        <p>{{ $l('readinessCheck.feedback.labelTwo') }}</p>
        <TextareaField
          v-model.trim="form.feedback"
          id="readiness-feedback--notes"
          class="feedback-form__textarea"
          label="Comment"
        />
      </div>
    </section>
    <AppToast
      v-if="errorToastMessage"
      class="feedback-form__toast"
      type="error"
      :text="errorToastMessage"
    />
    <AppToast
      v-if="successToastMessage"
      class="feedback-form__toast"
      type="success"
      :text="successToastMessage"
    />
    <div class="feedback-form__footer">
      <StandardButton
        class="feedback-form__footer-button"
        type="submit"
        text="Submit"
        :is-loading="isLoading"
      />
      <span class="feedback-form__footer-text">Or</span>
      <StandardButton
        class="feedback-form__footer-button"
        :to="{ name: 'Help' }"
        :text="$l('readinessCheck.feedback.buttonRequestSupport')"
      />
    </div>
  </form>
</template>

<script>
import apiManager from '@/api';
import AppCheckbox from './common/AppCheckbox.vue';
import RadioButton from './common/RadioButton.vue';
import TextField from './common/TextField.vue';
import StandardButton from './common/StandardButton.vue';
import TextareaField from './common/TextareaField.vue';
import AppToast from './common/AppToast.vue';

export default {
  name: 'ReadinessPlanModalReadinessCheckFormFeedback',
  components: {
    AppCheckbox,
    RadioButton,
    TextField,
    StandardButton,
    TextareaField,
    AppToast,
  },
  props: {
    dealer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      errorToastMessage: '',
      successToastMessage: '',
      meeting: {
        id: '',
        campaignId: '',
        date: '',
        dealerId: '',
        metWithZM: false,
        completedByName: '',
        departmentManagersPresent: false,
        departmentTeamHostingCompleted: false,
        lastSavedOn: '',
        markedFinalOn: '',
        fieldReviewedPlanWithDealer: false,
      },
      form: {
        hasCompletedManagerMeeting: false,
        managerMeetingCompletedWithFordField: false,
        hasCompletedDepartmentMeeting: false,
        hasCompletedReadinessPlan: false,
        wslxId: '',
        resourcesAreHelpful: null,
        feedback: '',
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    clearForm() {
      this.form.hasCompletedManagerMeeting = false;
      this.form.managerMeetingCompletedWithFordField = false;
      this.form.hasCompletedDepartmentMeeting = false;
      this.form.hasCompletedReadinessPlan = false;
      this.form.wslxId = '';
      this.form.resourcesAreHelpful = null;
      this.form.feedback = '';
    },
    validateForm() {
      const errors = [];

      if (!this.form.hasCompletedManagerMeeting) {
        errors.push('Manager\'s Meeting is required.');
      }
      if (!this.form.hasCompletedDepartmentMeeting) {
        errors.push('Department Team Meetings is required.');
      }
      if (!this.form.hasCompletedReadinessPlan) {
        errors.push('Readiness Plan not certified.');
      }
      if (this.form.wslxId === '') {
        errors.push('WSLX ID is required.');
      } else if (this.form.wslxId !== this.$store.getters['user/userProfile'].userId) {
        errors.push('WSLX ID is not correct');
      }

      if (errors.length) {
        this.errorToastMessage = errors.join(' ');
        return false;
      }

      return true;
    },
    async getFormData() {
      const { data } = await apiManager.dealerAccountability
        .getDealerMeeting(this.dealer.dealerId);

      this.meeting.id = data.meetingID;
      this.meeting.date = data.meeting_Date;
      this.meeting.campaignId = data.meetingCampaignID;
      this.meeting.dealerId = data.dealerID;
      this.meeting.metWithZM = data.metWithZM;
      this.meeting.completedByName = data.completedBy_Name;
      this.meeting.departmentManagersPresent = data.department_Managers_Present;
      this.meeting.departmentTeamHostingCompleted = data.department_TeamHosting_Completed;
      this.meeting.strategyImplementationCompleted = data.strategyImplementation_Completed;
      this.meeting.lastSavedOn = data.lastSavedOn;
      this.meeting.markedFinalOn = data.markedFinalOn;
      this.meeting.fieldReviewedPlanWithDealer = data.fieldReviewedPlanWithDealer;
    },
    async submitForm() {
      this.errorToastMessage = '';
      this.successToastMessage = '';

      if (!this.validateForm()) {
        return;
      }

      this.isLoading = true;

      try {
        await apiManager.dealerAccountability.saveDealerMeeting({
          meetingId: this.meeting.id,
          dealerId: this.dealer.dealerId,
          note: this.form.feedback,
          completedByUserId: this.form.wslxId,
          meetingDate: this.meeting.date,
          meetingCampaignId: this.meeting.campaignId,
          metWithZM: this.meeting.metWithZM,
          completedByName: this.meeting.completedByName,
          departmentTeamMeetingsCompleted: this.form.hasCompletedDepartmentMeeting,
          departmentManagersPresent: this.meeting.departmentManagersPresent,
          departmentTeamHostingCompleted: this.meeting.departmentTeamHostingCompleted,
          strategyImplementationCompleted: this.meeting.strategyImplementationCompleted,
          managerMeetingCompleted: this.form.hasCompletedManagerMeeting,
          lastSavedOn: this.meeting.lastSavedOn,
          markedFinalOn: this.meeting.markedFinalOn,
          fieldReviewedPlanWithDealer: this.meeting.fieldReviewedPlanWithDealer,
          providedResourcesAdequate: this.form.resourcesAreHelpful,
        });

        this.successToastMessage = this.$l('readinessCheck.feedback.formSaveSuccess');
        this.clearForm();
      } catch (error) {
        this.errorToastMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-form {
  --section-title-color: #{$color--fds-primary};
  &.lincoln {
    --section-title-color: #{$color--lds-primary};
  }

  &__checkbox {
    --text-color: #{$color--fds-gray3};
  }
  &__section {
    margin-bottom: 20px;
  }
  &__section-title {
    color: var(--section-title-color);
    font-weight: bold;
  }
  &__row {
    padding-left: 17px;
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
  &__padding-left {
    padding-left: 24px;
  }
  &__radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__textarea {
    margin-top: 24px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    @media (min-width: 500px) {
      flex-direction: row;
      justify-content: center;
    }
  }
  &__footer-button,
  &__footer-text {
    text-transform: uppercase;
  }
  &__toast {
    margin-bottom: 20px;
  }
  .error {
    border: 2px solid $color--fds-error1;
    box-shadow: inset 1px 1px 3px 0 rgba($color--fds-error1, 0.5);
  }
}
</style>
