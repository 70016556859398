<template>
  <div class="dealer-status | container--dashboard-accordion">
    <div class="dealer-status__row">
      <span>
        {{ $l('reports.accordion3.subtitle') }}
        <AppTooltip
          :title="$l('reports.accordion3.tooltip1.title2')"
          :text="$l('reports.accordion3.tooltip1.text2')"
        />
      </span>
      <StandardButton
        icon-right="chevron-right"
        track-event-name="download_modelEASDDReport"
        track-section-name="dealerStatus"
        :text="$l('general.view')"
        @click="$router.push({ name: 'ModelEReportASDD' })"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import AppTooltip from './common/AppTooltip.vue';

export default {
  name: 'ModelEReportsDealerStatus',
  components: {
    StandardButton,
    AppTooltip,
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
    }),
  },
}
</script>

<style lang="scss" scoped>
.dealer-status {
  &__row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    @include respond(sm-up) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
