<template>
  <AppDialog
    size="md"
    :open="open"
    :title="$l('stripe.accountSetup.accountOnHold.title')"
    @close="$emit('close')"
  >
    <p v-if="showDescription">
      {{ $l('stripe.accountSetup.accountOnHold.description') }}
    </p>
    <p>
      {{ $l('stripe.accountSetup.accountOnHold.message') }} <br />
      <a
        class="stripe-link"
        rel="noopener"
        target="_blank"
        href="https://support.stripe.com/contact/email"
        :class="$store.getters['user/activeBrandName']"
      >
        https://support.stripe.com/contact/email
      </a>
    </p>
  </AppDialog>
</template>

<script>
import AppDialog from './common/AppDialog.vue';

export default {
  name: 'StripeModalAccountOnHold',
  components: {
    AppDialog,
  },
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDescription() {
      return this.$store.getters['user/activeBrandIsFord'];
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-link {
  &.ford {
    color: $color--fds-secondary;
  }
  &.lincoln {
    color: $color--lds-secondary;
  }
}
</style>
