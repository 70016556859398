import store from '@/store';
import HomeView from '@/views/HomeView.vue';
import DashboardView from '@/views/DashboardView.vue';
import StripeView from '@/views/StripeView.vue';
import HelpView from '@/views/HelpView.vue';
import CommsCenterView from '@/views/CommsCenterView.vue';
import ReportsView from '@/views/ReportsView.vue';
import StartView from '@/views/StartView.vue';
import FAQsView from '@/views/FAQsView.vue';
import ResourcesView from '@/views/ResourceCenterView.vue';
import ResourceResultsView from '@/views/ResourceResultsView.vue';
import DealerProfileView from '@/views/DealerProfileView.vue';
import ReportASDDView from '@/views/ReportASDDView.vue';
import DealerProfilePowerBi from '@/views/DealerProfilePowerBiView.vue';

const userIsEnrolledGuard = (to, from, next) => {
  const { enrolled } = store.getters['user/activeDealership'];
  const userHasAcceptedTerms = store.getters['termsAndConditions/userHasAcceptedTerms'];

  if (!enrolled && !userHasAcceptedTerms) {
    next({ name: 'Home' });
  } else {
    next();
  }
};

export default [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      header: {
        disableNavigation: true,
        disableBrandSelector: true,
      },
      track: {
        pageName: 'Home',
      },
    },
  },
  {
    path: '/index.html',
    redirect: { name: 'Home' },
  },
  {
    path: '/Start',
    name: 'Start',
    component: StartView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Start',
      },
    },
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: FAQsView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'FAQs',
      },
    },
  },
  {
    path: '/ResourceCenter',
    name: 'ResourceCenter',
    component: ResourcesView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Resource_Center',
      },
    },
  },
  {
    path: '/ResourceResults',
    name: 'ResourceResults',
    component: ResourceResultsView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Resource_Results',
      },
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Dashboard',
      },
    },
    beforeEnter: (_, from, next) => {
      const { enrolled } = store.getters['user/activeDealership'];
      const newTermsAvailable = store.getters['termsAndConditions/newTermsAvailable'];

      if (from.name !== 'Home' && (!enrolled || newTermsAvailable)) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/Stripe',
    name: 'Stripe',
    component: StripeView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Stripe',
      },
    },
    beforeEnter: userIsEnrolledGuard,
  },
  {
    path: '/Help',
    name: 'Help',
    component: HelpView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Help',
      },
    },
  },
  {
    path: '/CommsCenter',
    name: 'Communications Center',
    component: CommsCenterView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Communications_Center',
      },
    },
  },
  {
    path: '/Reports',
    name: 'Reports',
    component: ReportsView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Reports',
      },
    },
  },
  {
    path: '/Reports/DealerProfile',
    name: 'Dealer Profile',
    component: DealerProfileView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Dealer_Profile_Report',
      },
    },
  },
  {
    path: '/Reports/ReportASDDView',
    name: 'ReportASDD',
    component: ReportASDDView,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'ASDD_Report',
      },
    },
  },
  {
    path: '/Reports/DealerProfilePowerBi',
    name: 'Dealer Profile PowerBi',
    component: DealerProfilePowerBi,
    meta: {
      requiresAuth: true,
      track: {
        pageName: 'Dealer_Profile_Report',
      },
    },
  },
];
