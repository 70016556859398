<template>
  <section class="help-resources">
    <h2 class="help-resources__title">
      {{ $l('help.resources.heading1') }}
    </h2>
    <div class="help-resources__section">
      <h3 class="help-resources__subtitle">
        {{ $l('modelEHelp.resources.text1') }}
      </h3>
      <p>
        {{ $l('modelEHelp.resources.text2') }}
        <AppLink
          to="https://support.stripe.com/contact/email"
          track-event-name="click_https://support.stripe.com/contact/email"
          track-section-name="additionalSupport"
        >
          https://support.stripe.com/contact/email
        </AppLink>
        <br />
        {{ $l('modelEHelp.resources.text3') }}
        <AppLink
          to="https://support.stripe.com/"
          track-event-name="click_https://support.stripe.com/"
          track-section-name="additionalSupport"
        >
          https://support.stripe.com/
        </AppLink>
        <br />
      </p>
    </div>
    <div class="help-resources__section">
      <h3 class="help-resources__subtitle">
        {{ $l('modelEHelp.resources.text7') }}
      </h3>
      <p>
        <AppLink
          to="mailto:ics@ford.com"
          track-event-name="click_mailto:ics@ford.com"
          track-section-name="additionalSupport"
        >
          ics@ford.com
        </AppLink>
      </p>
      <p>
        <AppLink
          to="tel:18004678925"
          track-event-name="click_tel:18004678925"
          track-section-name="additionalSupport"
        >
          800-467-8925
        </AppLink>
      </p>
    </div>
    <div class="help-resources__section">
      <h3 class="help-resources__subtitle">
        {{ $l('modelEHelp.resources.text8') }}
      </h3>
      <p>
        <AppLink
          to="mailto:cars4@ford.com"
          track-event-name="click_mailto:cars4@ford.com"
          track-section-name="additionalSupport"
        >
          cars4@ford.com
        </AppLink>
      </p>
      <p>
        <AppLink
          to="tel:18667446801"
          track-event-name="click_tel:18667446801"
          track-section-name="additionalSupport"
        >
          866-744-6801
        </AppLink>
      </p>
    </div>
    <div class="help-resources__section">
      <h3 class="help-resources__subtitle">
        {{ $l('modelEHelp.resources.text11') }}
      </h3>
      <p>
        {{ $l('modelEHelp.resources.brand') }}
        <AppLink
          to="tel:18005653673"
          track-event-name="click_tel:18005653673"
          track-section-name="additionalSupport"
        >
        800-565-3673
        </AppLink>
      </p>
    </div>
  </section>
</template>

<script>
import AppLink from './common/AppLink.vue';

export default {
  name: 'ModelEHelpResources',
  components: {
    AppLink,
  },
};
</script>

<style lang="scss" scoped>
.help-resources {
  margin-top: 16px;
  border-top: 1px solid $color--fds-gray2;
  text-align: center;
  &__title {
    margin: 32px 0 24px 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 400;
    color: $color--fds-primary;
  }
  &__subtitle {
    margin: 0;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: $font-weight--medium;
  }
  &__section {
    margin-bottom: 24px;
  }
  p {
    margin: 0;
  }
}
</style>
