export default {
  title: 'Organisateur de session de mise en scène et d\'assurance qualité',
  label: {
    dealer: 'Sélectionnez un concessionnaire',
    stripeStatus: '[NT] Stripe Status',
    feature: '[NT] Feature',
    featureStatus: '[NT] Feature Status',
    steps: '[NT] Steps',
  },
  button: {
    login: 'Connexion',
    reset: 'Effacer la progression du concessionnaire ( Blue )',
    resetModelE: '[NT] Clear Dealer Progress ( ModelE )',
    clearTerms: '[NT] Clear T&C only ( Blue )',
    clearTermsModelE: '[NT] Clear T&C ( ModelE )',
    setStripeStatus: '[NT] Set Stripe Account Status',
    activateCartCheckout: '[NT] Activate Cart and Checkout',
    setFeatureStatus:'[NT] Set Feature Status',
    blueStep: '[NT] Set Step Complete ( Blue )',
    modelEStep: '[NT] Set Step Complete ( ModelE )',
    track:'[NT] Tracking Report',
  },
  success: {
    stripeStatus: '[NT] Status updated successfully.',
    featureStatus: '[NT] Feature Status updated successfully.',
    stepStatus: '[NT] Step updated sucessfully',
    termsAndConditions: '[NT] Terms and Conditions has been reset successfully.',
    cartCheckout: '[NT] Cart and Checkout activated successfully.',
    resetDealerProgress: 'La progression du concessionnaire a été réinitialisée avec succès.',
    impersonateLogin: 'Concessionnaire usurpé avec succès.',
  },
  error: {
    loadDealerList: '[NT] Failed to load the dealer list. Please try again.',
    featureList: '[NT] Failed to load the feature list. Please try again.',
    loadStepList: '[NT] Failed to load the step list. Please try again.',
    stripeStatus: '[NT] Failed to update Stripe status. Please try again.',
    termsAndConditions: '[NT] Failed to reset the Terms and Conditions. Please try again.',
    cartCheckout: '[NT] Failed to activate Cart and Checkout. Please try again.',
    DEALER_NOT_SELECTED: 'Veuillez d\'abord sélectionner un revendeur.',
    DEALER_IMPERSONATE_DENIED: 'Le revendeur n\'est pas éligible pour usurper l\'identité.',
    DEALER_IMPERSONATE_NOTOKEN: 'Impossible de trouver les informations du jeton.',
    DEALER_IMPERSONATE_RESET_FAILED: '[NT] Failed to reset dealer progress. Please try again.',
  },
  track : {
    title: '[NT] Tracking',
    button: '[NT] Refresh',
    text1: '[NT] Limit stats to this UserId',
    errorMessage: '[NT] Failed to load track list, Please try again.',
    table1: {
      dealerId: '[NT] DealerID',
      pageName: '[NT] PageName',
      parentControlName: '[NT] ParentControlName',
      pageControlName: '[NT] PageControlName',
      controlType: '[NT] ControlType',
      userId: '[NT] UserID',
      dateCreated: '[NT] DateCreated',
    },
    table2 : {
      dealerId: '[NT] DealerID',
      videoId: '[NT] VideoID',
      videoAction: '[NT] Video Action',
      videoActionSeconds: '[NT] Video Action Seconds',
      videoPercentComplete: '[NT] Video Percent Complete',
      pageName: '[NT] Page Name',
      userId: '[NT] User ID',
      createdOn: '[NT] Created On',
    },
  },
  modal: {
    login: {
      text: 'Voulez-vous vous connecter en tant que revendeur sélectionné?',
    },
    reset: {
      text: 'Oulez-vous vraiment réinitialiser la progression?',
    },
    clearTerms: {
      text: '[NT] Do you want to clear the Terms and Conditions?',
    },
  },
};
