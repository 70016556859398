export default {
  deactivate: {
    date: 'Your dealer will be deactivated on:',
    cancel: 'Click \'Cancel\' to remain active in these experiences or \'Accept\' to continue.',
    warning: 'Your dealership will be deactivated on:<br>{date}<br>Click on ‘Active’ to remain in the experience',
    errorMessage: 'Failed to initiate the deactivation process. Please try again.',
  },
  optOutPermission: {
    title: 'Experience Opt-out',
    subtitle: 'Initiate opt-out of an eCommerce experience',
    description: 'You do not have permissions to opt out of eCommerce experiences. The following people have permissions to opt-out in your dealership:',
  },
  cancelOptOut: {
    errorMessage: 'Failed to cancel the opt-out process. Please try again.',
  },
  cancelOptOutPermission: {
    title: 'Cancel Opt-out',
    subtitle: 'Cancel opt-out of an eCommerce experience',
    description: 'You do not have permissions to cancel the opt-out of an eCommerce experience. The following people have permissions in your dealership:',
  },
  reactivate: {
    errorMessage: 'Failed to initiate the re-activation process. Please try again.',
  },
  reactivatePermission: {
    title: 'Re-activate Experience',
    subtitle: 'Re-activate an eCommerce experience',
    description: 'You do not have permissions to re-activate an eCommerce experience. The following people have permissions in your dealership:',
  },
  reservation: {
    title: 'Reservation',
    item1: 'Enrolment',
    item2: 'Stripe Account',
    optOut: {
      title: 'Reservation and Order',
      subtitle: 'Initiate opt-out for the Reservation and Order experiences',
      description: 'You have initiated the deactivation process for the Reservation experience, and as a result, initiated the deactivation process for the Order experience as well.',
    },
    cancelOptOut: {
      title: 'Reservation and Order',
      subtitle: 'Cancel opt-out for the Reservation and Order experiences',
      description: 'You have succesfully canceled the opt out process for the reservation and order eCommerce experiences for your dealership.',
    },
    reactivate: {
      title: 'Reservation',
      subtitle: 'Re-activate the Reservation experience',
      description: 'You have succesfully re-activated the reservation eCommerce experience for your dealership.',
    },
  },
  order: {
    title: 'Order',
    item1: 'Enrolment',
    item2: 'Stripe Account',
    optOut: {
      title: 'Order',
      subtitle: 'Initiate opt-out for the Order experience',
      description: 'You have initiated the deactivation process for the Order experience.',
    },
    cancelOptOut: {
      title: 'Order',
      subtitle: 'Cancel opt-out for the Order experience',
      description: 'You have succesfully canceled the opt out process for the order eCommerce experience for your dealership.',
    },
    reactivate: {
      title: 'Order',
      subtitle: 'Re-activate the Order experience',
      description: 'You have initiated re-activation of the order eCommerce experience for your dealership. Please review all activation requirements for completion; reactivation may take up to 24 hours.',
    },
    reactivateReservation: {
      title: 'Order',
      subtitle: 'Re-activate the Order experience',
      description: 'Reservation activation is required to re-activate the Order experience. Please re-activate Reservation by clicking on \'Active\' and then re-activate the Order experience.',
    },
  },
  cartCheckout: {
    title: 'Cart and Checkout',
    item1: 'Enrolment',
    item2: 'Stripe Account',
    item3: 'Vehicle Pricing',
    item4: 'Accessory Pricing',
    item5: 'Trade-In',
    item6: 'F&I',
    item6ComingSoon: 'F&I <i style=\'font-size: 13px;\'>(Coming Soon)</i>',
    item7: 'Customer Handling <i style=\'font-size: 13px;\'>(Coming Soon)</i>',
    optOut: {
      title: 'Cart and Checkout',
      subtitle: 'Initiate opt-out for the Cart and Checkout experience',
      description: 'You have initiated the deactivation process for the Cart and Checkout experience.',
    },
    cancelOptOut: {
      title: 'Cart and Checkout',
      subtitle: 'Cancel opt-out for the Cart and Checkout experience',
      description: 'You have succesfully canceled the opt out process for the cart and checkout eCommerce experience for your dealership.',
    },
    reactivate: {
      title: 'Cart and Checkout',
      subtitle: 'Re-activate the Cart and Checkout experience',
      description: 'You have initiated re-activation of the cart and checkout eCommerce experience for your dealership. Please review all activation requirements for completion; reactivation may take up to 24 hours.',
    },
  },
};
