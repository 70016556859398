import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  state() {
    return {
      entitlements: [],
      pricingSources: [],
    };
  },
  getters: {
    entitlements: (state) => state.entitlements,
    pricingSource({ pricingSources }, getters, rootState, rootGetters) {
      return pricingSources.find(
        (pricingSource) => pricingSource.division === rootGetters['user/activeBrand'],
      ) || {};
    },
    pricingIsBelowInventory(state, getters, rootState, rootGetters) {
      const cartCheckoutIsActive = rootGetters['activationSummary/cartCheckout']
        ?.percentageComplete === 100;

      if (!cartCheckoutIsActive) {
        return false;
      }

      return getters.pricingSource.percentage < 50;
    },
    resources(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['modelE/dashboard/resources'],
        resourceCenterId.section.MODEL_E_VEHICLE_PRICING,
        resourceCenterId.subsection.MODEL_E_VEHICLE_PRICING,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
  mutations: {
    setEntitlements(state, entitlements) {
      state.entitlements = entitlements;
    },
    setPricingSources(state, pricingSources) {
      state.pricingSources = pricingSources;
    },
  },
  actions: {
    async load({ commit }) {
      const [
        { value: { data: entitlements } },
        { value: { data: { pricingSources } } },
      ] = await Promise.allSettled([
        apiManager.vehiclePricing.getModelEEntitlements(),
        apiManager.vehiclePricing.getModelEPricingSource(),
      ]);

      commit('setEntitlements', entitlements);
      commit('setPricingSources', pricingSources);
    },
  },
};
