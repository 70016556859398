export default {
  title: '[NT] Help',
  heading: 'Des experts hautement qualifiés sont prêts à vous aider',
  callHeadquarters: 'Appelez le Secrétariat du programme de commerce électronique de Ford  :',
  resources: {
    text1: 'Stripe (outil de traitement des paiements) pour le soutien à la configuration de compte',
    text2: 'Pour communiquer avec',
    text3: 'Questions générales :',
    text4: '[NT] Fiserv/First Data (Previous Payment Processor)',
    text5: '[NT] FordPay/Lincoln Pay Setup:',
    text6: '[NT] Customer Service:',
    text7: 'Solutions Technologies Ford (STF) pour du soutien concernant VLPlus',
    text8: 'Services de remise en marché des véhicules (ARS) pour du soutien concernant l’inscription et les véhicules d’échange',
    text9: '[NT] Enhanced Dealer Dashboard for Program Reporting',
    text10: '[NT] Contact Your FordDirect Digital Experience Trainer (DET)',
    text11: 'Centre de relations avec la clientèle (CRC)',
    brand: 'Ford:',
  },
};
