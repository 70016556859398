<template>
  <AppAccordion
    :id="tradeInId"
    :is-open="accordionIsOpen"
    :title="$l('dashboard.tradeIn.title')"
    @click="$store.dispatch('dashboard/accordions/openAccordion', tradeInId)"
  >
    <div class="container--accordion-group">
      <AppAccordion
        :id="subcordionId"
        color="secondary"
        :track-section-name="tradeInId"
        :title="$l('dashboard.tradeIn.sectionOne.title')"
        :is-open="activeSubcordionId === subcordionId"
        @click="openSubcordion(subcordionId)"
      >
        <DashboardARS />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import DashboardARS from './DashboardARS.vue';

export default {
  name: 'DashboardAccordionTradeIn',
  components: {
    AppAccordion,
    DashboardARS,
  },
  computed: {
    ...mapGetters('dashboard/accordions', {
      accordions: 'accordions',
      activeAccordionId: 'activeAccordionId',
      activeSubcordionId: 'activeSubcordionId',
    }),
    accordionIsOpen() {
      return this.activeAccordionId === this.tradeInId;
    },
    tradeInId() {
      return this.accordions.tradeIn.id;
    },
    subcordionId() {
      return this.accordions.tradeIn.ars.id;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
