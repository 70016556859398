const dashboardAccordions = [
  {
    order: 0,
    enrolled: false,
    id: 'notifications',
    step: null,
    name: '',
    subName: '',
    subCount: true,
    expanded: false,
    subcordion: [],
  },
  {
    order: 1,
    enrolled: false,
    id: 'enrollment',
    step: 'Enrollment_Open',
    name: '',
    subName: '',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'termsAndConditions',
        name: '',
        subName: '',
        template: '',
        parent: 'enrollment',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
      {
        id: 'dealerInformationTeamSetup',
        name: '',
        subName: '',
        template: '',
        parent: 'enrollment',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
      {
        id: 'remoteDeliveryPreferences',
        name: '',
        subName: '',
        template: '',
        parent: 'enrollment',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 2,
    enrolled: true,
    id: 'fordPaySetup',
    step: 'FordPaySetup_Open',
    name: 'theDashboard.fordPaySetup.title',
    subName: 'theDashboard.fordPaySetup.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'feeSetupMerchantSetup',
        name: 'theDashboard.fordPaySetup.section0.title',
        subName: '',
        template: '',
        parent: 'fordPaySetup',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 3,
    enrolled: true,
    id: 'fordStripePaySetup',
    step: 'FordStripePaySetup_Open',
    name: 'theDashboard.fordStripePaySetup.title',
    subName: 'theDashboard.fordStripePaySetup.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'dashboardStripeAccountSetup',
        name: 'theDashboard.stripeAccountSetup.section0.title',
        subName: '',
        template: '',
        parent: 'stripeAccountSetup',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 11,
    enrolled: true,
    id: 'stripeAccountSetup',
    step: 'StripeAccountSetup_Open',
    name: 'theDashboard.stripeAccountSetup.title',
    subName: 'theDashboard.stripeAccountSetup.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'dashboardStripeAccountSetup',
        name: 'theDashboard.stripeAccountSetup.section0.title',
        subName: '',
        template: '',
        parent: 'stripeAccountSetup',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 4,
    enrolled: true,
    id: 'vehiclePricing',
    step: 'vehiclePricing_Open',
    name: 'theDashboard.vehiclePricing.title',
    subName: 'theDashboard.vehiclePricing.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'vehiclePricing',
        name: 'theDashboard.vehiclePricing.section0.title',
        subName: '',
        template: '',
        parent: 'vehiclePricing',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 5,
    enrolled: true,
    id: 'accessoryPricing',
    step: 'accessoryPricing_Open',
    name: 'theDashboard.accessoryPricing.title',
    subName: 'theDashboard.accessoryPricing.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'accessoryPricing',
        name: 'theDashboard.accessoryPricing.section0.title',
        subName: '',
        template: '',
        parent: 'accessoryPricing',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 6,
    enrolled: true,
    id: 'tradeIn',
    step: 'TradeIn_Open',
    name: 'theDashboard.tradeIn.title',
    subName: 'theDashboard.tradeIn.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'automotiveRemarketingService',
        name: 'theDashboard.tradeIn.section0.title',
        subName: '',
        template: '',
        parent: 'tradeIn',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 7,
    enrolled: true,
    id: 'fNiSetup',
    step: 'FNI_Open',
    name: 'theDashboard.fNiSetup.title',
    subName: 'theDashboard.fNiSetup.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'routeOneSetup',
        name: 'modelEDashboard.fNi.section0.title',
        subName: 'modelEDashboard.fNi.section0.subtitle',
        template: '',
        parent: 'fNiSetup',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
      {
        id: 'rateMarkup',
        name: 'modelEDashboard.fNi.section1.title',
        subName: 'modelEDashboard.fNi.section1.subtitle',
        template: '',
        parent: 'fNiSetup',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
      {
        id: 'taxesAndFees',
        name: 'modelEDashboard.fNi.section2.title',
        subName: 'modelEDashboard.fNi.section2.subtitle',
        template: '',
        parent: 'fNiSetup',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
      {
        id: 'paperWorkESign',
        name: 'modelEDashboard.fNi.section3.title',
        subName: 'modelEDashboard.fNi.section3.subtitle',
        template: '',
        parent: 'fNiSetup',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 8,
    enrolled: true,
    id: 'ecommerceCustomerHandling',
    step: 'CustomerHandling_Open',
    name: 'theDashboard.customerHandling.title',
    subName: 'theDashboard.customerHandling.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'reservationOrderRequests',
        name: 'theDashboard.customerHandling.section0.title',
        subName: '',
        template: '',
        parent: 'ecommerceCustomerHandling',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 9,
    enrolled: true,
    id: 'ecommerceCustomerActivity',
    step: null,
    name: 'theDashboard.customerActivity.title',
    subName: 'theDashboard.customerActivity.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'dealerDashboardReporting',
        name: 'theDashboard.customerActivity.section0.title',
        subName: '',
        template: '',
        parent: 'ecommerceCustomerActivity',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
  {
    order: 10,
    enrolled: true,
    id: 'ecommerceAdvancedOptions',
    step: null,
    name: 'theDashboard.advancedOptions.title',
    subName: 'theDashboard.advancedOptions.subtitle',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'maximTrakSetup',
        name: 'theDashboard.fNiSetup.section1.title',
        subName: 'theDashboard.fNiSetup.section1.subtitle',
        template: '',
        parent: 'ecommerceAdvancedOptions',
        expanded: false,
        indicator: 'gray',
        additionalStatus: false,
      },
      {
        id: 'alternateLenders',
        name: 'dashboard.alternateLenders.title',
        subName: '',
        template: '',
        parent: 'ecommerceAdvancedOptions',
        expanded: false,
        indicator: 'gray',
        additionalStatus: null,
      },
      {
        id: 'remoteDeliveryPreferences',
        name: '',
        subName: '',
        template: '',
        parent: 'ecommerceAdvancedOptions',
        expanded: false,
        indicator: 'gray',
        additionalStatus: null,
      },
      {
        id: 'plannedPricing',
        name: 'theDashboard.vehiclePricing.section1.title',
        subName: 'theDashboard.vehiclePricing.section1.subtitle',
        template: '',
        parent: 'ecommerceAdvancedOptions',
        expanded: false,
        indicator: 'gray',
        additionalStatus: null,
      },
      {
        id: 'insurance',
        name: 'dashboard.fordInsure.title',
        subName: '',
        template: '',
        parent: 'ecommerceAdvancedOptions',
        expanded: false,
        indicator: 'gray',
        additionalStatus: null,
      },
    ],
  },
].sort((a, b) => a.order - b.order);

const fordPayAccordions = [
  {
    order: 0,
    enrolled: true,
    id: 'fordPay',
    step: null,
    name: 'fordPay.accordion0.title',
    subName: '',
    subCount: false,
    expanded: false,
    subcordion: [
      {
        id: 'midMerchantSetup',
        name: 'fordPay.accordion0.section0.title',
        subName: '',
        template: 'FordPayMidSetup',
        parent: 'fordPay',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
      {
        id: 'midSalesTips',
        name: 'fordPay.accordion0.section1.title',
        subName: '',
        template: 'FordPayMidTips',
        parent: 'fordPay',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
      {
        id: 'midConfirmation',
        name: 'fordPay.accordion0.section2.title',
        subName: '',
        template: 'FordPayMidConfirm',
        parent: 'fordPay',
        expanded: false,
        indicator: null,
        additionalStatus: null,
      },
    ],
  },
].sort((a, b) => a.order - b.order);

export { dashboardAccordions, fordPayAccordions };
