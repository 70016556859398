import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  getters: {
    resources(state, getters, rootState, rootGetters) {
      const sectionId = rootGetters['user/activeBrandIsFord']
        ? resourceCenterId.section.CUSTOMER_ACTIVITY
        : resourceCenterId.section.CLIENT_ACTIVITY;

      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        sectionId,
        resourceCenterId.subsection.EDD_REPORTING_SECTION1,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
    sectionDocuments(state, getters, rootState, rootGetters) {
      const sectionId = rootGetters['user/activeBrandIsFord']
        ? resourceCenterId.section.CUSTOMER_ACTIVITY
        : resourceCenterId.section.CLIENT_ACTIVITY;

      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        sectionId,
        resourceCenterId.subsection.EDD_REPORTING_SECTION2,
      );
    },
    activeSectionDocuments(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.sectionDocuments,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
};
