<template>
  <div class="dealer-personnel">
    <header class="dealer-personnel__header">
      <h3 class="dealer-personnel__title">
        {{ $l('dealerInformation.section2Title') }}
      </h3>
    </header>
    <div :key="dealerPersonnelListKey">
      <DashboardDealerPersonnelMember
        v-for="(member, index) in renderedContactList"
        :key="member.index"
        :index="index"
        :contact-id="member.contactID"
        :dealer-id="member.dealerID"
        :stars-id="member.starsID"
        :user-id="member.userID"
        :first-name="member.firstName"
        :last-name="member.lastName"
        :phone-number="member.phone"
        :cell-phone="member.cellPhone"
        :email="member.email"
        :position="member.position"
        :language-code="member.languageCode"
        :extension="member.ext"
        :is-key-decision-maker="member.kdm"
        :open-on-mounted="Boolean(contactToBeAdded)"
        :departments="{
          programManagement: member.programManagement,
          vehiclePricing: member.vehiclePricing,
          tradeTool: member.tradeTool,
          accessoryPricing: member.accessoryPricing,
          fniSetup: member.fniSetup,
          customerHandling: member.customerHandling,
        }"
        :alerts="{
          reservations: member.receiveReservationAlert,
          orders: member.receiveOrderAlert,
          cartCheckout: member.receiveCartCheckoutAlert,
        }"
        @clear-contact-to-be-added="contactToBeAdded = null"
        @contact-updated="handleContactUpdated"
      />
      <AddContact
        id="dealer-personnel-add-contact"
        track-event-name="click_addContact"
        :button-text="$l('dealerPersonnel.addContact.buttonText')"
        :contacts="contacts"
        :selected-contacts="selectedContacts"
        :disable-add-button="Boolean(contactToBeAdded)"
        @add-contact="contactToBeAdded = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardDealerPersonnelMember from './DashboardDealerPersonnelMember.vue';
import AddContact from './DashboardAddContact.vue';

export default {
  name: 'DealerPersonnel',
  components: {
    DashboardDealerPersonnelMember,
    AddContact,
  },
  data() {
    return {
      contactToBeAdded: null,
      dealerPersonnelListKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      activeBrand: 'user/activeBrand',
      contacts: 'dealerPersonnel/contacts',
    }),
    selectedContacts() {
      return this.contacts.filter((contact) => contact.contactID && !contact.kdm);
    },
    renderedContactList() {
      const sortedContacts = [...this.selectedContacts].sort((a, b) => {
        const dateA = new Date(a.lastUpdated);
        const dateB = new Date(b.lastUpdated);

        if (dateA < dateB) {
          return -1;
        }

        if (dateA > dateB) {
          return 1;
        }

        return 0;
      });

      if (this.contactToBeAdded) {
        return [...sortedContacts, this.contactToBeAdded];
      }
      return sortedContacts;
    },
  },
  watch: {
    activeBrand() {
      this.contactToBeAdded = null;
    },
    contacts: {
      deep: true,
      handler() {
        this.dealerPersonnelListKey += 1;
      },
    },
  },
  methods: {
    async handleContactUpdated() {
      await this.$store.dispatch('dealerPersonnel/loadContacts');
      await this.$store.dispatch('activationSummary/updateProgress', 'TeamSetup');
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-personnel {
  &__title {
    font-size: 24px;
    line-height: 40px;
    border-bottom: 1px solid #b8b8b8;
    font-weight: 300;
  }
}
</style>
