<template>
  <div class="fee-setup-card">
    <AccountId />
    <AppAccordion
      :id="accordionId.reservation"
      class="fee-setup-card__accordion"
      color="secondary"
      :title="$l('stripe.feeSetup.card.reservation.title')"
      :is-open="activeAccordionId === accordionId.reservation"
      @click="openAccordion(accordionId.reservation)"
    >
      <Reservation />
    </AppAccordion>
    <AppAccordion
      :id="accordionId.order"
      class="fee-setup-card__accordion"
      color="secondary"
      :title="$l('stripe.feeSetup.card.order.title')"
      :is-open="activeAccordionId === accordionId.order"
      @click="openAccordion(accordionId.order)"
    >
      <Order />
    </AppAccordion>
    <AppAccordion
      :id="accordionId.cartCheckout"
      class="fee-setup-card__accordion"
      color="secondary"
      :title="$l('stripe.feeSetup.card.cartCheckout.title')"
      :is-open="activeAccordionId === accordionId.cartCheckout"
      @click="openAccordion(accordionId.cartCheckout)"
    >
      <CartCheckout />
    </AppAccordion>
  </div>
</template>

<script>
import AppAccordion from './common/AppAccordion.vue';
import AccountId from './StripeFeeSetupCardAccountId.vue';
import Reservation from './StripeFeeSetupCardReservation.vue';
import Order from './StripeFeeSetupCardOrder.vue';
import CartCheckout from './StripeFeeSetupCardCartCheckout.vue';

const ACCORDION_ID = {
  reservation: 'stripe-accordion__reservation',
  order: 'stripe-accordion__order',
  cartCheckout: 'stripe-accordion__cart-checkout',
};

export default {
  name: 'StripeFeeSetupCard',
  components: {
    AppAccordion,
    AccountId,
    Reservation,
    Order,
    CartCheckout,
  },
  data() {
    return {
      accordionId: ACCORDION_ID,
      activeAccordionId: null,
    };
  },
  methods: {
    openAccordion(accordionId) {
      if (this.activeAccordionId === accordionId) {
        this.activeAccordionId = null;
      } else {
        this.activeAccordionId = accordionId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fee-setup-card {
  width: 100%;
  padding: 16px;
  padding-top: 56px;
  margin-top: 30px;
  box-shadow: 0px 5px 10px #{$color--fds-gray3 + '8c'};
  @include respond(sm-up) {
    padding: 56px;
  }
  &__accordion {
    :deep(.accordion__body) {
      padding-top: 50px;
      padding-bottom: 40px;
    }
  }
}
</style>
