<template>
  <ExperienceCard
    v-if="activeBrandIsFord"
    :experience-id="2"
    :title="$l('resourceCenter.experience.order.title')"
    :bottom-title="$l('resourceCenter.experience.order.bottomTitle')"
  >
    <template #top>
      <!-- This code wasn't deleted because the links might be needed in the future -->
      <!-- <TextButton
        :to="{ name:'ResourceResults', query:{ experience: 2, topic: 23 }}"
        text="Mustang Mach-E"
      /> -->
    </template>
    <template #topFooter>
      <StandardButton
        icon-right="chevron-right"
        track-event-name="select_order"
        track-section-name="order"
        :to="{ name: 'ResourceResults', query: { experience: 2 }}"
        :text="$l('general.select')"
      />
    </template>
    <!-- This code wasn't deleted because the links might be needed in the future -->
    <!-- <template #bottom>
      <TextButton
        :to="{ name:'ResourceResults', query:{ experience: 5, topic: 9 }}"
        text="Custom Orders"
      />
    </template>
    <template #bottomFooter>
      <StandardButton
        outline
        iconRight="chevron-right"
        :to="{ name:'ResourceResults', query:{ experience: 5 }}"
        :text="$l('general.select')"
      />
    </template> -->
  </ExperienceCard>
  <ExperienceCard
    v-else
    :title="$l('resourceCenter.experience.order.title')"
  >
    <template #topFooter>
      <StandardButton
        outline
        icon-right="chevron-right"
        track-event-name="select_order"
        track-section-name="order"
        :to="{ name: 'ResourceResults', query: { experience: 2 }}"
        :text="$l('general.select')"
      />
    </template>
  </ExperienceCard>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import ExperienceCard from './ResourceCenterExperienceCard.vue';

export default {
  name: 'ResourceCenterCardOrder',
  components: {
    StandardButton,
    ExperienceCard,
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
    }),
  },
};
</script>
