<template>
  <DealerProfileSection
    class="dealer-profile-info"
    :title="$l('dealerProfile.subtitle2')"
  >
    <div class="dealer-profile-info__content">
      <TableDealershipInformation
        :selected-dealer-id="selectedDealer.value"
        :profile-info="dealerData.profileInfo"
      />
      <TableContactInformation
        :contacts="dealerData.profileContacts"
      />
      <TableReservationActivation
        :reservation-info="dealerData.reservationActivationInfo"
      />
      <TableOrderActivation
        :order-info="dealerData.orderActivationInfo"
      />
      <TableCartCheckoutActivation
        :cart-checkout-info="dealerData.cartCheckoutActivationInfo"
      />
      <TableCartCheckoutAdvanced
        :profile-info="dealerData.profileInfo"
      />
    </div>
  </DealerProfileSection>
</template>

<script>
import DealerProfileSection from './DealerProfileSection.vue';
import TableDealershipInformation from './DealerProfileTableDealershipInformation.vue';
import TableContactInformation from './DealerProfileTableContactInformation.vue';
import TableReservationActivation from './DealerProfileTableReservationActivation.vue';
import TableOrderActivation from './DealerProfileTableOrderActivation.vue';
import TableCartCheckoutActivation from './DealerProfileTableCartCheckoutActivation.vue';
import TableCartCheckoutAdvanced from './DealerProfileTableCartCheckoutAdvanced.vue';

export default {
  name: 'DealerProfileInfo',
  components: {
    DealerProfileSection,
    TableDealershipInformation,
    TableContactInformation,
    TableReservationActivation,
    TableOrderActivation,
    TableCartCheckoutActivation,
    TableCartCheckoutAdvanced,
  },
  props: {
    selectedDealer: {
      type: Object,
      default: () => ({}),
    },
    dealerData: {
      type: Object,
      default: () => ({
        profileInfo: {},
        profileContacts: [],
        reservationActivationInfo: {},
        orderActivationInfo: {},
        cartCheckoutActivationInfo: {},
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-profile-info {
  margin-top: 64px;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 96px;
    width: 100%;
  }
}
</style>
