<template>
  <div class="model-e-sheet">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ModelESheet',
};
</script>

<style lang="scss" scoped>
.model-e-sheet {
  width: 100%;
  padding: 10px 24px;
  background-color: $color--white;
  border-radius: 4px;
  box-shadow: $fds-elevation__box-shadow--layer1;
}
</style>
