<template>
  <section
    class="summary-card"
    :class="$store.getters['user/activeBrandName']"
  >
    <h3 class="summary-card__title">
      {{ title }}
    </h3>
    <p
      v-if="deactivationDate"
      v-html="$l('activationSummary.deactivate.warning', { date: formattedDeactivationDate })"
      class="summary-card__deactivation-warning | text-center"
    />
    <slot />
    <ul class="summary-card__list">
      <slot name="list" />
    </ul>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  name: 'ActivationSummaryCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    deactivationDate: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      dateFormatPattern: 'locale/dateFormatPattern',
    }),
    formattedDeactivationDate() {
      return format(new Date(this.deactivationDate), this.dateFormatPattern);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-card {
  --background-color: #{$color--fds-primary};
  &.lincoln {
    --background-color: #{$color--lds-primary};
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    margin: 0;
    margin-bottom: 16px;
    color: $color--white;
    background-color: var(--background-color);
    border: 1px solid $color--fds-gray2;
    font-size: 16px;
    font-weight: $font-weight--light;
    text-transform: uppercase;
  }
  &__deactivation-warning {
    color: $color--fds-error1;
    font-size: 13px;
    line-height: 2;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    margin-top: 32px;
    padding: 0;
    padding-left: 24px;
  }
}
</style>
