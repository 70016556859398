<template>
  <div
    v-if="userIsKeyDecisionMaker"
    class="add-dealer-contact"
  >
    <AppDropdown
      v-if="showSelectContactInput"
      v-model="selectedContact"
      :id="id"
      ref="contactDropdown"
      searchable
      :label="$l('general.name')"
      :options="contactOptions"
    />
    <StandardButton
      v-else
      size="lg"
      :text="buttonText"
      :track-event-name="trackEventName"
      :disabled="disableAddButton"
      @click="showSelectContactInput = true"
    />
  </div>
</template>

<script>
import StandardButton from './common/StandardButton.vue';
import AppDropdown from './common/AppDropdown.vue';

export default {
  name: 'DashboardAddContact',
  components: {
    StandardButton,
    AppDropdown,
  },
  emits: ['add-contact'],
  props: {
    id: { type: String, required: true },
    buttonText: { type: String, required: true },
    trackEventName: { type: String, required: true },
    contacts: { type: Array, default: () => ([]) },
    selectedContacts: { type: Array, default: () => ([]) },
    disableAddButton: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedContact: '',
      showSelectContactInput: false,
    };
  },
  computed: {
    userIsKeyDecisionMaker() {
      return this.$store.getters['user/userIsKeyDecisionMaker'];
    },
    contactOptions() {
      let options = this.contacts.filter((contact) => !contact.contactID && !contact.kdm);

      options = options.map((option) => ({
        text: `${option.firstName} ${option.lastName}`,
        value: option,
      }));

      // Filters out selected contacts
      this.selectedContacts.forEach((contact) => {
        options = options
          .filter((option) => option.text.trim() !== `${contact.firstName} ${contact.lastName}`);
      });

      return options.sort((a, b) => {
        if (a.text < b.text) return -1;

        if (a.text > b.text) return 1;

        return 0;
      });
    },
  },
  watch: {
    showSelectContactInput(show) {
      if (show) {
        document.body.addEventListener('keyup', this.handleKeyupEvent);

        this.$nextTick(() => {
          const input = this.$refs.contactDropdown.$el.querySelector('input');

          if (input) {
            input.focus();
          }
        });
      } else {
        document.body.removeEventListener('keyup', this.handleKeyupEvent);
      }
    },
    selectedContact(contact) {
      if (!contact) return;

      // Clears contact's position value in order to prevent it from
      // being pre-selected when visualizing "MemberDetails.vue"
      this.$emit('add-contact', {
        ...contact,
        position: '',
      });

      this.selectedContact = '';

      this.showSelectContactInput = false;
    },
  },
  methods: {
    handleKeyupEvent(event) {
      if (event.key === 'Escape') {
        this.showSelectContactInput = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-dealer-contact {
  max-width: 300px;
  margin-top: 40px;
}
</style>
