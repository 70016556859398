<template>
  <div class="resource-center">
    <ResourceCenterMenu />
    <div class="resource-center__content">
      <ModelEPageHeader :title="$l('modelEResourceCenter.title')" />
      <ModelESheet>
        <ResourceCenterSearchButton
          :to="{ name: 'ModelEResourceResults', query: { search: true } }"
          :text="$l('modelEResourceCenter.experience.search')"
        />
      </ModelESheet>
      <ModelEResourceCenterExperience />
      <hr class="resource-center__divider" />
      <ModelEResourceCenterJourney />
      <hr class="resource-center__divider" />
      <ModelEResourceCenterEMP />
      <hr class="resource-center__divider" />
      <ModelEResourceCenterOperationalize />
      <hr class="resource-center__divider" />
      <ModelEResourceCenterDepartment />
    </div>
  </div>
</template>

<script>
import ResourceCenterSearchButton from '@/components/ResourceCenterSearchButton.vue';
import ModelESheet from '@/components/ModelESheet.vue';
import ModelEPageHeader from '@/components/ModelEPageHeader.vue';
import ResourceCenterMenu from '@/components/ResourceCenterMenu.vue';
import ModelEResourceCenterJourney from '@/components/ModelEResourceCenterJourney.vue';
import ModelEResourceCenterOperationalize from '@/components/ModelEResourceCenterOperationalize.vue';
import ModelEResourceCenterDepartment from '@/components/ModelEResourceCenterDepartment.vue';
import ModelEResourceCenterExperience from '../components/ModelEResourceCenterExperience.vue';
import ModelEResourceCenterEMP from '../components/ModelEResourceCenterEMP.vue';

export default {
  name: 'ModelEResourceCenterView',
  components: {
    ResourceCenterMenu,
    ModelEResourceCenterJourney,
    ModelEResourceCenterEMP,
    ModelEResourceCenterOperationalize,
    ModelEResourceCenterDepartment,
    ModelEResourceCenterExperience,
    ModelESheet,
    ModelEPageHeader,
    ResourceCenterSearchButton,
  },
};
</script>

<style lang="scss" scoped>
.resource-center {
  &__title {
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: 300;
  }

  &__content {
    width: 95%;
    margin: 0 auto;
  }

  &__divider {
    height: 2px;
    margin: 40px 0;
    background-color: #ddd;
  }
}
</style>
