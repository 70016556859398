<template>
  <div
    class="header-user-info"
    :class="$store.getters['user/activeBrandName']"
  >
    <span class="header-user-info__name">{{ user.fullName }}</span>
    <small class="header-user-info__address">
      {{ user.dealerName }} <br />
      <span v-if="user.city"> {{ user.city }}, {{ user.state }} {{ user.postalCode }}</span>
    </small>
  </div>
</template>

<script>
export default {
  name: 'TheHeaderUserInfo',
  computed: {
    user() {
      const user = this.$store.getters['user/userProfile'];
      const dealership = this.$store.getters['user/activeDealership'];
      return {
        fullName: `${user.firstName} ${user.lastName}`,
        dealerName: dealership.dealerName,
        city: dealership.city,
        state: dealership.state,
        postalCode: dealership.zip,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.header-user-info {
  display: flex;
  flex-direction: column;
  color: $color--white;
  &__name {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
  }
  &__address {
    text-align: center;
    line-height: 1.4;
    font-size: 10px;
    letter-spacing: 1px;
  }
  @media screen and (min-width: $breakpoint__the-header-desktop) {
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
    &.lincoln {
      color: $color--lds-primary;
    }
    .header-user-info__address {
      font-size: 12px;
    }
  }
}
</style>
