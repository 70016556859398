<template>
  <div class="terms-and-conditions | container--dashboard-accordion">
    <p>
      {{ $l('dashboard.enrollment.terms.subtitle') }}
      <AppTooltip :text="$l('dashboard.enrollment.terms.tooltip')" />
    </p>
    <TermsAndConditionsView v-model="termsAreAccepted" />
    <div class="save-btn-wrapper">
      <StandardButton
        v-if="termsAreAccepted"
        size="lg"
        track-event-name="submitForm_acceptTermsConditions"
        track-section-name="termsConditions"
        :is-loading="isSavingTerms"
        :text="$l('general.save')"
        @click="saveTermsAndConditions"
      />
    </div>
    <DashboardKeyDecisionMaker />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import AppTooltip from './common/AppTooltip.vue';
import StandardButton from './common/StandardButton.vue';
import DashboardKeyDecisionMaker from './DashboardKeyDecisionMaker.vue';
import TermsAndConditionsView from './TermsAndConditionsView.vue';

export default {
  name: 'DashboardTermsAndConditions',
  components: {
    StandardButton,
    DashboardKeyDecisionMaker,
    TermsAndConditionsView,
    AppTooltip,
  },
  data() {
    return {
      isSavingTerms: false,
      termsAreAccepted: false,
      notSavedEditId: null,
    };
  },
  computed: {
    ...mapGetters({
      activeLocale: 'locale/activeLocale',
      activeDealership: 'user/activeDealership',
      userProfile: 'user/userProfile',
    }),
  },
  watch: {
    activeLocale() {
      this.toggleEditsNotSavedAlert();
    },
    activeDealership() {
      this.termsAreAccepted = false;
    },
    termsAreAccepted() {
      this.toggleEditsNotSavedAlert();
    },
  },
  beforeUnmount() {
    this.closeEditsNotSavedAlert();
  },
  methods: {
    async toggleEditsNotSavedAlert() {
      await this.closeEditsNotSavedAlert();

      if (this.termsAreAccepted) {
        this.openEditsNotSavedAlert();
      }
    },
    async openEditsNotSavedAlert() {
      this.notSavedEditId = await this.$store.dispatch(
        'topBanner/addNotSavedEdit',
        this.$l('dashboard.errors.TERMS_CONDITIONS_NOT_COMPLETED'),
      );
    },
    async closeEditsNotSavedAlert() {
      if (this.notSavedEditId) {
        await this.$store.dispatch('topBanner/removeNotSavedEdit', this.notSavedEditId);

        this.notSavedEditId = null;
      }
    },
    async saveTermsAndConditions() {
      this.isSavingTerms = true;

      this.closeEditsNotSavedAlert();

      try {
        const { dealerId } = this.activeDealership;
        const { success } = await apiManager.termsAndConditions.acceptTermsAndConditions(dealerId);

        if (!success) {
          throw new Error(this.$l('dashboard.enrollment.terms.acceptTerms.error'));
        }

        const { success: progressSuccess } = await this.$store.dispatch(
          'dashboard/enrollment/updateProgress',
          'TermsConditions_Completed',
        );

        if (!progressSuccess) {
          throw new Error(this.$l('dashboard.enrollment.terms.acceptTerms.error'));
        }

        await this.$store.dispatch('termsAndConditions/load');

        this.$store.dispatch('topBanner/open', {
          message: this.$l('dashboard.enrollment.terms.acceptTerms.success'),
        });

        this.termsAreAccepted = false;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isSavingTerms = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-and-conditions {
  .save-btn-wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
</style>
