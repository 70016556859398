<template>
  <div
    class="status-box"
    :class="[statusBoxClasses, $store.getters['user/activeBrandName']]"
  >
    <span class="status-box__text">{{ statusText }}</span>
  </div>
</template>

<script>
const VALID_STATUSES = {
  unknown: 'unknown',
  notstarted: 'notstarted',
  incomplete: 'incomplete',
  complete: 'complete',
  active: 'active',
  pending: 'pending',
  inprogress: 'inprogress',
  onhold: 'onhold',
};

const normalizeStatus = (s) => s.toLowerCase().replaceAll(/[^a-z]/g, '');

export default {
  name: 'StatusBox',
  props: {
    status: {
      type: String,
      default: VALID_STATUSES.incomplete,
      validator(status) {
        const values = Object.values(VALID_STATUSES);
        return [...values, ''].includes(normalizeStatus(status));
      },
    },
  },
  computed: {
    normalizedStatus() {
      return normalizeStatus(this.status);
    },
    statusText() {
      const {
        unknown, notstarted, incomplete, complete, active, pending, inprogress, onhold,
      } = VALID_STATUSES;

      switch (this.normalizedStatus) {
        case notstarted:
          return this.$l('general.status.notStarted');
        case complete:
          return this.$l('general.status.complete');
        case active:
          return this.$l('general.status.active');
        case pending:
          return this.$l('general.status.pending');
        case inprogress:
          return this.$l('general.status.inProgress');
        case onhold:
          return this.$l('general.status.onHold');
        case incomplete:
          return this.$l('general.status.incomplete');
        case unknown:
        default:
          return this.$l('general.status.unknown');
      }
    },
    statusBoxClasses() {
      const {
        unknown, notstarted, incomplete, complete, active, pending, inprogress, onhold,
      } = VALID_STATUSES;
      const status = this.normalizedStatus;

      return {
        'status-box--unknown': status === unknown || !status,
        'status-box--not-started': status === notstarted,
        'status-box--incomplete': status === incomplete,
        'status-box--complete': status === complete,
        'status-box--active': status === active,
        'status-box--pending': status === pending,
        'status-box--in-progress': status === inprogress,
        'status-box--on-hold': status === onhold,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.status-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 130px;
  height: 38px;
  padding: 0 15px;
  border: 2px solid transparent;
  text-align: center;
  &--complete,
  &--active {
    border-color: $color--fds-success1;
    color: $color--fds-success1;
  }
  &--unknown {
    border-color: $color--fds-error1;
    color: $color--fds-error1;
  }
  &--incomplete,
  &--not-started {
    border-color: #B8B8B8;
    &.ford {
      color: $color--fds-gray2;
    }
    &.lincoln {
      color: $color--lds-primary;
    }
  }
  &--pending,
  &--in-progress,
  &--on-hold {
    border-color: $color--fds-caution1;
    color: $color--fds-caution1;
    &.lincoln {
      border-color: $color--lds-secondary;
      color: $color--lds-secondary;
    }
  }
}
</style>
