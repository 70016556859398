export default {
  instructions: 'Veuillez réviser les renseignements sur votre concessionnaire et créer votre équipe. Vous pouvez ajouter plusieurs membres d’équipe à un rôle et chaque rôle peut s’appliquer à plusieurs personnes. Veillez désigner la personne chez votre concessionnaire qui est la mieux placée pour effectuer les étapes de configuration requises pour chaque rôle. Pour en savoir plus sur chacun des rôles, cliquez sur le cercle Information situé à côté du titre.',
  section1Title: 'Renseignements du concessionnaire',
  section2Title: 'Création de l\'équipe chez le concessionnaire',
  saveButtonText: 'Enregistrer',
  phoneNumber: {
    error: '[NT] Failed to save phone number. Please try again.',
  },
  enrollmentProgress: {
    success: 'Données enregistrées avec succès.',
    error: '[NT] Failed to update the enrolment progress. Please try again.',
  },
};
