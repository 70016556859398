<template>
  <div
    class="document-card"
    :class="$store.getters['user/activeBrandName']"
  >
    <span class="document-card__title">{{ title }}</span>
    <DocumentDetailsPopUpTip
      :title="title"
      :description="description"
      :document-name="documentName"
      @start-download="isLoading = true"
      @end-download="isLoading = false"
    >
      <button
        class="document-card__image-wrapper"
        aria-label="Open document"
        @click="openDocumentModal"
      >
        <div
          class="document-card__loader"
          :class="{ 'show': isLoading}"
        >
          <AppSpinner light />
        </div>
        <img
          class="document-card__image"
          :alt="title"
          :src="thumbnail"
        />
      </button>
    </DocumentDetailsPopUpTip>
  </div>
</template>

<script>
import apiManager from '@/api';
import { ElementType, Event } from '@/constants/track';
import AppSpinner from './common/AppSpinner.vue';
import DocumentDetailsPopUpTip from './DocumentDetailsPopUpTip.vue';

export default {
  name: 'DocumentCard',
  components: {
    AppSpinner,
    DocumentDetailsPopUpTip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    filePath: {
      type: String,
      default: '',
    },
    documentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    openDocumentModal() {
      const fileName = this.filePath.split('/').at(-1);
      const fileExtension = fileName.split('.').at(-1);

      // Downloads any file that is not a PDF
      if (fileExtension.toLowerCase() !== 'pdf') {
        this.downloadDocument(fileName);

        return;
      }

      this.$store.dispatch('documentModal/open', {
        fileHref: this.filePath,
        title: this.title,
      });
    },
    async downloadDocument(fileName) {
      this.isLoading = true;

      this.$store.dispatch('track/event', {
        event: `${Event.DOWNLOAD_DOCUMENT}_${this.documentName}`,
        section: 'documentCard',
        elementType: ElementType.CARD,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        const link = document.createElement('a');

        link.href = await apiManager.document.getBase64(fileName);
        link.download = fileName;
        link.click();
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.document-card {
  --width: 180px;
  --title-min-height: 36px;
  --title-margin: 4px;

  display: flex;
  flex-direction: column;
  width: var(--width);
  margin-top: calc(var(--title-min-height) + var(--title-margin));
  color: $color--fds-primary;
  font-size: 13px;
  font-weight: 100 !important;
  position: relative;
  &.lincoln {
    font-size: 16px;
    color: $color--lds-primary;
    font-weight: 400 !important;
  }
  &__title {
    width: 100%;
    min-height: var(--title-min-height);
    text-align: center;
    line-height: 16px;
    position: absolute;
    bottom: calc(100% + var(--title-margin));
    z-index: 1;
  }
  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width);
    padding: 0;
    background-color: transparent;
    border: 0;
    outline-offset: 5px;
    position: relative;
    box-shadow: 0 8px 20px 0 rgba($color--black, 0.25);
    border: 1px solid $color--white;
    overflow: hidden;
    &:hover {
      border-color: $color--fds-disabled2;
      box-shadow: 0 10px 30px 0 rgba($color--black, 0.25);
    }
  }
  &__image {
    max-width: var(--width);
  }
  &__loader {
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    &.show {
      opacity: 1;
    }
  }
}
</style>
