<template>
  <div>
    <ActivationSummaryModalOptOut
      :title="$l('activationSummary.order.optOut.title')"
      :subtitle="$l('activationSummary.order.optOut.subtitle')"
      :description="$l('activationSummary.order.optOut.description')"
      :open="showModalOptOut"
      :is-loading="isDeactivatingExperience"
      @close="showModalOptOut = false"
      @accept="deactivateExperience"
    />
    <ActivationSummaryModal
      :title="$l('activationSummary.order.cancelOptOut.title')"
      :subtitle="$l('activationSummary.order.cancelOptOut.subtitle')"
      :description="$l('activationSummary.order.cancelOptOut.description')"
      :open="showModalCancelOptOut"
      @close="showModalCancelOptOut = false"
    />
    <ActivationSummaryModal
      :title="$l('activationSummary.order.reactivateReservation.title')"
      :subtitle="$l('activationSummary.order.reactivateReservation.subtitle')"
      :description="$l('activationSummary.order.reactivateReservation.description')"
      :open="showModalReactivateReservation"
      @close="showModalReactivateReservation = false"
    />
    <ActivationSummaryModal
      :title="$l('activationSummary.order.reactivate.title')"
      :subtitle="$l('activationSummary.order.reactivate.subtitle')"
      :description="$l('activationSummary.order.reactivate.description')"
      :open="showModalReactivate"
      @close="showModalReactivate = false"
    />
    <ActivationSummaryCard
      :title="$l('activationSummary.order.title')"
      :deactivation-date="order.deactivation.endDate"
    >
      <ActivationSummaryControl
        :status="order.status"
        :percentage-complete="order.percentageComplete"
        @deactivate-experience="showModalOptOut = true"
        @cancel-opt-out="cancelOptOut"
        @activate-experience="reactivateExperience"
      />
      <template #list>
        <ActivationSummaryItem
          :label="$l('activationSummary.order.item1')"
          :status="order.enrollmentStatus"
          :accordion-id="accordions.enrollment.id"
        />
        <ActivationSummaryItem
          :label="$l('activationSummary.order.item2')"
          :status="order.stripeSetupStatus"
          :disabled="!activeDealership.enrolled"
          :accordion-id="accordions.stripeAccountSetup.id"
          :subcordion-id="accordions.stripeAccountSetup.feeStripeAccount.id"
        />
      </template>
    </ActivationSummaryCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElementType, Event } from '@/constants/track';
import { experienceId, status } from '@/constants/activationSummary';
import ActivationSummaryCard from './ActivationSummaryCard.vue';
import ActivationSummaryControl from './ActivationSummaryControl.vue';
import ActivationSummaryItem from './ActivationSummaryItem.vue';
import ActivationSummaryModal from './ActivationSummaryModal.vue';
import ActivationSummaryModalOptOut from './ActivationSummaryModalOptOut.vue';

export default {
  name: 'ActivationSummaryOrder',
  components: {
    ActivationSummaryCard,
    ActivationSummaryControl,
    ActivationSummaryItem,
    ActivationSummaryModal,
    ActivationSummaryModalOptOut,
  },
  data() {
    return {
      showModalOptOut: false,
      showModalCancelOptOut: false,
      showModalReactivateReservation: false,
      showModalReactivate: false,
      isDeactivatingExperience: false,
      isCancelingOptOut: false,
      isReactivatingExperience: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      order: 'activationSummary/order',
      reservation: 'activationSummary/reservation',
      accordions: 'dashboard/accordions/accordions',
    }),
    reservationIsActive() {
      return [status.ACTIVE, status.IN_PROGRESS].includes(this.reservation.status);
    },
  },
  watch: {
    showModalOptOut() {
      if (this.showModalOptOut) {
        this.$store.dispatch('track/event', {
          event: Event.OPEN_OPT_OUT_MODAL,
          section: 'order',
          elementType: ElementType.MODAL,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
  methods: {
    async deactivateExperience() {
      this.isDeactivatingExperience = true;

      this.$store.dispatch('track/event', {
        event: Event.DEACTIVATE_EXPERIENCE,
        section: 'order',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/deactivateExperience', {
          experienceId: experienceId.ORDER,
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.deactivate.errorMessage'),
        });
      } finally {
        this.showModalOptOut = false;
        this.isDeactivatingExperience = false;
      }
    },
    async cancelOptOut() {
      if (this.isCancelingOptOut) return;

      if (!this.reservationIsActive) {
        this.showModalReactivateReservation = true;
        return;
      }

      this.isCancelingOptOut = true;

      this.$store.dispatch('track/event', {
        event: Event.CANCEL_OPT_OUT,
        section: 'order',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/cancelExperienceOptOut', {
          experienceId: experienceId.ORDER,
        });

        this.showModalCancelOptOut = true;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.cancelOptOut.errorMessage'),
        });
      } finally {
        this.isCancelingOptOut = false;
      }
    },
    async reactivateExperience() {
      if (this.isReactivatingExperience) return;

      if (!this.reservationIsActive) {
        this.showModalReactivateReservation = true;
        return;
      }

      this.isReactivatingExperience = true;

      this.$store.dispatch('track/event', {
        event: Event.REACTIVATE_EXPERIENCE,
        section: 'order',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/reactivateExperience', {
          experienceId: experienceId.ORDER,
        });

        this.showModalReactivate = true;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.reactivate.errorMessage'),
        });
      } finally {
        this.isReactivatingExperience = false;
      }
    },
  },
};
</script>
