<template>
  <label
    class="checkbox"
    :class="[$store.getters['user/activeBrandName'], $attrs.class]"
    :for="id"
  >
    <input
      v-bind="$attrs"
      :id="id"
      ref="checkbox"
      class="checkbox__input"
      type="checkbox"
      :checked="modelValue"
      @keypress.enter="$refs.checkbox.click()"
      @change="handleChange"
    />
    <div class="checkbox__control app-icon-tick" />
    <div class="checkbox__content">
      <slot>
        <span>{{ label }}</span>
      </slot>
    </div>
  </label>
</template>

<script>
export default {
  name: 'AppCheckbox',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleChange(event) {
      this.$emit('update:modelValue', event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  --size: 16px;
  --icon-size: 10px;
  --color: #{$color--fds-gray2};
  --outline-color: #{$color--fds-primary};
  --text-color: #{$color--fds-primary};
  --border-width: 2px;
  --spacing-top: 5px;

  display: grid;
  grid-template-columns: var(--size) 1fr;
  gap: 16px;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  &__control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    color: var(--color);
    border: var(--border-width) solid var(--color);
    border-radius: 3px;
    position: relative;
    font-size: var(--icon-size);
    transform: translateY(var(--spacing-top));
    cursor: pointer;
    &::before {
      display: none;
      margin-top: 2px;
      margin-left: 2px;
    }
    &::after {
      content: '';
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      outline: 1px solid var(--outline-color);
      outline-offset: 8px;
    }
  }
  &__input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    &:checked + .checkbox__control {
      --color: #{$color--fds-primary};
      &::before {
        display: block;
      }
    }
    &:focus-visible + .checkbox__control::after {
      display: block;
    }
  }
  &__content {
    color: var(--text-color);
    font-weight: $font-weight--normal;
  }
}
.checkbox.lincoln {
  --size: 20px;
  --icon-size: 13px;
  --outline-color: #{$color--lds-primary};
  --text-color: #{$color--lds-primary};
  --border-width: 1px;
  --spacing-top: 6px;
  .checkbox__control {
    border-radius: 0;
  }
  .checkbox__input:checked + .checkbox__control {
    --color: #{$color--white};
    --border-width: 0;
    background-color: $color--lds-secondary;
  }
}
</style>
