<template>
  <div class="search-bar-wrapper">
    <AppSearchBar
      id="resource-results-search-bar"
      check-min-characters
      :label="$l('resourcesSearchBar.label')"
      :button-text="$l('general.submit')"
      :disabled="disabled"
      :suggested-results="keywordsList"
      @get-suggested-results="getKeywordsList"
      @clear-suggested-results="keywordsList = []"
      @submit="$emit('search', $event)"
    />
  </div>
</template>

<script>
import apiManager from '@/api';
import AppSearchBar from './common/AppSearchBar.vue';

export default {
  name: 'ResourceResultsSearchBar',
  components: {
    AppSearchBar,
  },
  emits: ['search'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keywordsList: [],
    };
  },
  methods: {
    async getKeywordsList(keyword) {
      const { data } = await apiManager.resourceCenter.getKeywords({
        keyword,
        language: this.$store.getters['locale/activeLocale'],
      });

      this.keywordsList = data?.result || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar-wrapper {
  max-width: 750px;
  margin: 0 auto;
  margin-bottom: 40px;
}
</style>
