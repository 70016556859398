export default {
  title: 'Help',
  heading: 'Highly Trained Experts are Ready To Assist',
  callHeadquarters: 'Call Ford eCommerce Program Headquarters:',
  resources: {
    text1: 'Stripe (Payment Processor) for Account Setup Support',
    text2: 'To contact Stripe:',
    text3: 'For general questions:',
    text4: 'Fiserv/First Data (Previous Payment Processor)',
    text5: 'FordPay/Lincoln Pay Setup:',
    text6: 'Customer Service:',
    text7: 'Ford Technology Solutions (FTS) for VL Plus Support',
    text8: 'Automotive Remarketing Services (ARS) for Trade-In Enrolment Support',
    text9: 'Enhanced Dealer Dashboard for Program Reporting',
    text10: 'Contact Your FordDirect Digital Experience Trainer (DET)',
    text11: 'Customer Relations Center (CRC)',
    brand: 'Ford:',
  },
};
