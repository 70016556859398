import BaseAPI from './baseAPI';

export default class FAQs extends BaseAPI {
  async getCategoryListByDivision({ brand, language }) {
    const { data } = await this.axios.get('/Faq/CategoryList', {
      params: {
        division: brand,
        language,
      },
    });
    return data;
  }

  async getSearchResults({ division, searchQuestion, language }) {
    const { data } = await this.axios.get('/Faq/Search', {
      params: {
        division,
        searchQuestion,
        language,
      },
    });

    return data;
  }

  async getQuestionsByCategory({ division, categoryId, language }) {
    const { data } = await this.axios.get('/Faq/Questions', {
      params: {
        division,
        categoryId,
        language,
      },
    });

    return data;
  }

  async registerUserFeedback({ questionId, helpful }) {
    const { data } = await this.axios.post('/Faq/UserFeedback', null, {
      params: {
        questionId,
        helpful,
      },
    });

    return data;
  }

  async addSearchFeedback({ openedQuestionId, searchText }) {
    const { data } = await this.axios.post('/Faq/SearchFeedback', null, {
      params: {
        openedQuestionId,
        searchText,
      },
    });

    return data;
  }

  async getCategoryListByDivisionModelE({ brand, language }) {
    const { data } = await this.axios.get('/FaqModelE/CategoryListModelE', {
      params: {
        division: brand,
        language,
      },
    });
    return data;
  }

  async getSearchResultsModelE({ division, searchQuestion, language }) {
    const { data } = await this.axios.get('/FaqModelE/SearchModelE', {
      params: {
        division,
        searchQuestion,
        language,
      },
    });

    return data;
  }

  async getQuestionsByCategoryModelE({ division, categoryId, language }) {
    const { data } = await this.axios.get('/FaqModelE/QuestionsModelE', {
      params: {
        division,
        categoryId,
        language,
      },
    });

    return data;
  }

  async registerUserFeedbackModelE({ questionId, helpful }) {
    const { data } = await this.axios.post('/FaqModelE/UserFeedbackModelE', null, {
      params: {
        questionId,
        helpful,
      },
    });

    return data;
  }

  async addSearchFeedbackModelE({ openedQuestionId, searchText }) {
    const { data } = await this.axios.post('/FaqModelE/SearchFeedbackModelE', null, {
      params: {
        openedQuestionId,
        searchText,
      },
    });

    return data;
  }

}
