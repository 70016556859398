<template>
  <PromptDialog
    v-bind="$attrs"
    size="md"
    :title="$l('dashboard.stripe.sectionOne.modal.title')"
  >
    <p>{{ $l('dashboard.stripe.sectionOne.modal.subtitle') }}</p>
    <p>{{ $l('dashboard.stripe.sectionOne.modalAccounts.paragraphOne') }}</p>
    <p v-html="$l('dashboard.stripe.sectionOne.modalAccounts.paragraphTwo')" />
    <p
      v-html="$l('dashboard.stripe.sectionOne.modalAccounts.paragraphThree')"
      class="mb-0"
    />
    <template #footer>
      <StandardButton
        track-event-name="click_setupOneId"
        track-section-name="stripeAccount"
        :text="$l('dashboard.stripe.sectionOne.modalAccounts.buttonOneId')"
        @click="setupOneAccount"
      />
      <StandardButton
        track-event-name="click_setupTwoIds"
        track-section-name="stripeAccount"
        :text="$l('dashboard.stripe.sectionOne.modalAccounts.buttonTwoIds')"
        @click="setupTwoAccounts"
      />
    </template>
  </PromptDialog>
</template>

<script>
import PromptDialog from './common/PromptDialog.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'StripeAccountModalAccount',
  components: {
    PromptDialog,
    StandardButton,
  },
  methods: {
    async setupOneAccount() {
      await this.$store.dispatch('stripe/setupOneAccount');

      this.$router.push({ name: 'Stripe' });
    },
    async setupTwoAccounts() {
      await this.$store.dispatch('stripe/setupTwoAccounts');

      this.$router.push({ name: 'Stripe' });
    },
  },
};
</script>
