import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

const getActiveResources = (rootGetters, subsectionId) => {
  const resources = ResourceCenter.findSectionResources(
    rootGetters['modelE/dashboard/resources'],
    resourceCenterId.section.MODEL_E_CUSTOMER_HANDLING,
    subsectionId,
  );

  return ResourceCenter.getActiveVideosAndDocuments({
    resources,
    brand: rootGetters['user/activeBrand'],
    locale: rootGetters['locale/activeLocale'],
  });
};

export default {
  namespaced: true,
  state() {
    return {
      entitlements: [],
    };
  },
  getters: {
    entitlements: (state) => [...state.entitlements],
    modelECustomerHandlingActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.MODEL_E_CUSTOMER_HANDLING);
    },
    modelECustomerActivityActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.MODEL_E_CUSTOMER_ACTIVITY);
    },
  },
  mutations: {
    setEntitlements(state, entitlements) {
      state.entitlements = entitlements;
    },
  },
  actions: {
    async load({ commit }) {
      const { data: customerEntitlements } = await apiManager.customerHandling.getModelEEntitlements();

      commit('setEntitlements', customerEntitlements || []);
    },
  },
};

