<template>
  <div
    :id="id"
    class="accordion"
    :class="$store.getters['user/activeBrandName']"
    :data-open="isOpen"
    :data-color="color"
  >
    <h3 class="accordion__header">
      <button
        class="accordion__button"
        @click="$emit('click')"
      >
        <div class="accordion__button-content">
          <span
            v-html="title"
            class="accordion__title"
          />
          <div
            v-if="status"
            class="accordion__status"
          >
            <StatusIcon :status="status" />
            <span class="accordion__status-text">
              {{ displayedStatusText }}
            </span>
          </div>
          <span
            v-if="description"
            v-html="description"
            class="accordion__description"
          />
        </div>
        <div class="accordion__icon-wrapper">
          <i
            v-if="isImportant"
            class="accordion__alert-icon app-icon-exclamation-triangle"
          />
          <span
            class="accordion__expand-icon"
            :data-open="isOpen"
          />
        </div>
      </button>
    </h3>
    <TransitionExpandCollapse>
      <div
        v-show="isOpen"
        class="accordion__body"
      >
        <slot />
      </div>
    </TransitionExpandCollapse>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store';
import { ElementType, Event } from '@/constants/track';
import TransitionExpandCollapse from '@/components/TransitionExpandCollapse.vue';
import StatusIcon from '@/components/StatusIcon.vue';

const store = useStore();
const route = useRoute();

defineEmits(['click']);
const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'primary',
    validator: (color) => [
      'primary', 'secondary', 'primary-light', 'secondary-light',
    ].includes(color),
  },
  title: {
    type: String,
    required: true,
  },
  isImportant: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: '',
  },
  statusText: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  trackable: {
    type: Boolean,
    default: true,
  },
  trackSectionName: {
    type: String,
    default: '',
  },
});

const displayedStatusText = computed(() => {
  if (props.statusText) {
    return props.statusText;
  }

  switch (props.status) {
    case 'green':
      return 'Completed';
    case 'yellow':
      return 'In Progress';
    case 'no-dot':
      return 'Not Available';
    case 'comingsoon':
      return '*Coming Soon*';
    case 'gray':
    default:
      return 'Not Started';
  }
});

watch(() => props.isOpen, (isOpen) => {
  if (!props.trackable || !isOpen) return;

  store.dispatch('track/event', {
    pageName: route.meta.track.pageName,
    elementType: ElementType.ACCORDION,
    event: `${Event.OPEN_ACCORDION}_${props.id}`,
    section: props.trackSectionName,
  });
});
</script>

<style lang="scss" scoped>
.accordion {
  width: 100%;
  border-bottom: 1px solid transparent;
  &__header {
    width: 100%;
    margin: 0 !important;
  }
  &__button {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding: 16px;
    background-color: var(--bg-color);
    border: 0;
    color: var(--text-color, #{$color--white});
    font-size: 16px;
    font-weight: var(--font-weight, #{$font-weight--normal});
    transition: background-color 0.3s cubic-bezier(0.22, 0.61, 0.35, 1);
    &:hover {
      background-color: var(--hover-bg-color);
    }
    &:focus {
      outline-style: double;
      outline-offset: -7px;
      outline-width: thin;
      outline-color: var(--outline-color);
    }
    @include respond(md-up) {
      gap: 40px;
    }
  }
  &__button-content {
    display: grid;
    width: 100%;
    gap: 8px;
    text-align: left;
    @include respond(sm-up) {
      grid-template-columns: 1fr minmax(25%, 200px);
      gap: 16px;
    }
  }
  &__description {
    font-size: 12px;
  }
  &__status {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__alert-icon {
    color: $color--fds-caution1;
    font-size: 24px;
  }
  &__expand-icon {
    font-family: $font-family--icons;
    font-weight: var(--expand-icon-weight, inherit);
    transition: transform 0.3s ease-in-out;
  }
  &__body {
    margin-top: 1px;
    padding: 16px;
    background-color: var(--body-bg-color, unset);
  }
  &[data-open="true"] {
    .accordion__button {
      background-color: var(--open-bg-color);
    }
  }
}
.accordion.ford {
  .accordion__expand-icon::after {
    content: $app-icon-chevron-down;
  }
  &[data-color="primary"] {
    --bg-color: #{$color--fds-primary};
    --body-bg-color: #{$color--white};
    --hover-bg-color: #{$color--fds-secondary};
    --open-bg-color: #{$color--fds-secondary};
    --outline-color: #{$color--white};
    --font-weight: #{$font-weight--light};
  }
  &[data-color="secondary"] {
    --text-color: #{$color--fds-primary};
    --bg-color: #{$color--fds-fourth};
    --hover-bg-color: #{$color--fds-fourth};
    --open-bg-color: #{$color--fds-fourth};
    --outline-color: #{$color--fds-primary};
    --font-weight: #{$font-weight--light};
  }
  &[data-color="primary-light"] {
    --text-color: #{$color--fds-primary};
    --bg-color: #{$color--white};
    --body-bg-color: #{$color--fds-gray1};
    --hover-bg-color: #{$color--white};
    --open-bg-color: #{$color--white};
    --expand-icon-weight: #{$font-weight--bold};
    border-top: 1px solid $color--fds-disabled2;
    &:last-child {
      border-bottom: 1px solid $color--fds-disabled2;
    }
  }
  &[data-color="secondary-light"] {
    --text-color: #{$color--fds-primary};
    --bg-color: #{$color--fds-gray1};
    --body-bg-color: #{$color--fds-gray1};
    --hover-bg-color: #{$color--fds-gray1};
    --open-bg-color: #{$color--fds-gray1};
    --expand-icon-weight: #{$font-weight--bold};
    &:not(:last-child) {
      border-bottom: 1px solid $color--fds-disabled2;
    }
  }
  &[data-color="primary-light"],
  &[data-color="secondary-light"] {
    .accordion__button-content {
      @include respond(sm-up) {
        grid-template-columns: unset;
        gap: 8px;
      }
    }
    .accordion__title {
      font-size: 18px;
    }
    .accordion__status {
      grid-row-start: 1;
      gap: 8px;
      flex-direction: row-reverse;
      justify-content: flex-end;
      font-size: 12px;
    }
    .accordion__status-icon {
      font-size: 16px;
    }
  }
  .accordion__expand-icon[data-open="true"] {
    transform: rotate(-180deg);
  }
}
.accordion.lincoln {
  .accordion__button {
    font-size: 18px;
    letter-spacing: 1px;
  }
  .accordion__expand-icon::after {
    content: $app-icon-add;
  }
  .accordion__expand-icon[data-open="true"]::after {
    content: $app-icon-minimize;
  }
  &[data-color="primary"] {
    --bg-color: #{$color--lds-primary};
    --hover-bg-color: #{$color--lds-secondary};
    --open-bg-color: #{$color--lds-secondary};
    --outline-color: #{$color--white};
  }
  &[data-color="secondary"] {
    --text-color: #{$color--lds-primary};
    --bg-color: #{$color--fds-fourth};
    --hover-bg-color: #{$color--fds-fourth};
    --open-bg-color: #{$color--fds-fourth};
    --outline-color: #{$color--lds-primary};
    .accordion__button {
      border-bottom: 1px solid $color--lds-disabled2;
    }
    .accordion__expand-icon {
      color: $color--lds-secondary;
    }
  }
}
</style>
