const DASHBOARD_ACCORDIONS = {
  notifications: {
    id: 'notifications',
  },
  enrollment: {
    id: 'enrollment',
    step: 'Enrollment_Open',
    termsAndConditions: {
      id: 'termsAndConditions',
    },
    dealerInformationTeamSetup: {
      id: 'dealerInformationTeamSetup',
    },
    remoteDeliveryPreferences: {
      id: 'remoteDeliveryPreferences',
    },
  },
  stripeAccountSetup: {
    id: 'stripeAccountSetup',
    step: 'FordPaySetup_Open',
    feeStripeAccount: { id: 'dashboardStripeAccountSetup' },
  },
  modelEStripeAccountSetup: {
    id: 'modelEStripeAccountSetup',
    step: 'StripeAccountSetup_Open',
    feeStripeAccount: { id: 'dashboardStripeAccountSetup' },
  },
  vehiclePricing: {
    id: 'vehiclePricing',
    step: 'vehiclePricing_Open',
    vehiclePricing: { id: 'vehiclePricing' },
  },
  accessoryPricing: {
    id: 'accessoryPricing',
    step: 'accessoryPricing_Open',
    accessoryPricing: { id: 'accessoryPricing' },
  },
  tradeIn: {
    id: 'tradeIn',
    step: 'TradeIn_Open',
    ars: { id: 'automotiveRemarketingService' },
    tradeIn: { id: 'tradeIn' },
  },
  fordInsure: {
    id: 'fordInsure',
    step: 'FordInsure_Open',
    fordInsure: { id: 'fordInsure' },
  },
  fiSetup: {
    id: 'fNiSetup',
    step: 'FNI_Open',
    routeOne: { id: 'routeOneSetup' },
    taxesAndFees: { id: 'taxesAndFees' },
    documentFees: { id: 'documentFees' },
    fiProduct: { id: 'product' },
    eSign: { id: 'paperWorkESign' },
  },
  standardCompliance: {
    id: 'standardCompliance',
    step: 'StandardsCompliance_Open',
  },
  customerHandling: {
    id: 'ecommerceCustomerHandling',
    step: 'CustomerHandling_Open',
    reservationOrderRequests: { id: 'reservationOrderRequests' },
    handling: { id: 'customerHandling' },
    activity: { id: 'customerActivity' },
  },
  customerActivity: {
    id: 'ecommerceCustomerActivity',
    dealerDashboard: { id: 'dealerDashboardReporting' },
  },
  protectionPlans: {
    id: 'protectionPlans',
    step: 'ProtectionPlans_Open',
  },
  tablets: {
    id: 'tablets',
    step: 'Tablet_open',
    tablets: { id: 'reviewTablets' },
    menuUrl: { id: 'modelEMenuUrl' },
  },
};

export default DASHBOARD_ACCORDIONS;
