export default {
  title: 'Store Profile & Readiness',
  labelDropdown: 'Enter Store Name',
  fileDate: 'Eligible Store data based on last GOLDD file: {date}.',
  subtitle1: 'Store Readiness Profile',
  subtitle2: 'eCommerce Store Profile',
  downloadButton: 'Download Store Profile',
  dealershipInformation: {
    title: 'Store Information',
    titleRow1: 'Store Name',
    titleRow2: 'Store Code',
    titleRow3: 'Store Added',
  },
  evCartAndCheckout: {
    titleRow7: {
      lincoln: 'Client Handling (Coming Soon)',
    },
  },
  cartAndCheckoutActivation: {
    titleRow7: {
      lincoln: '@:dealerProfile.evCartAndCheckout.titleRow7.lincoln',
    },
  },
};
