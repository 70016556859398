/** This plugin wraps the i18n translate plugin
 * to allow greater flexiablilty if/when
 * a different language plugin is required
 */
export default {
  install(app) {
    // NOTE: had to use a named function vs an arrow function
    // to get the correct `this` vue instance
    function localization(phase, params) {
      if (phase) return this.$t(phase, params);
      return '';
    }

    app.config.globalProperties.$l = localization;
  },
};
