<template>
  <div class="not-found">
    <p>{{ $l('notFound.text') }}</p>
    <StandardButton
      :text="$l('notFound.button')"
      :to="{ name: 'Brand' }"
    />
  </div>
</template>

<script>
import StandardButton from '@/components/common/StandardButton.vue';

export default {
  name: 'NotFoundView',
  components: {
    StandardButton,
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: grid;
  place-items: center;
  gap: 16px;
  font-size: 24px;
  font-weight: $font-weight--medium;
}
</style>
