export default {
  title: 'Expériences Commerce électronique Ford',
  buttonStart: 'Commencer',
  welcome: {
    paragraph1: 'Ford offre désormais des expériences de réservation, de commande, de panier d\'achats et de paiement avec des règlements directement avec le concessionnaire. L\'inscription du concessionnaire se fait en quelques clics. Ce portail fournit des instructions, un accès et du contenu de formation pour guider votre concessionnaire tout au long des étapes d\'activation. Un soutien du programme est disponible à tout moment par le biais de la page d\'aide.',
    paragraph2: 'L\'expérience d\'activation se déroulera à mesure que les activités de configuration seront exécutées pour votre concessionnaire. Cliquez sur l\'onglet Outils ci-dessus pour accéder à une liste des points à vérifier pour l\'inscription, des guides de configuration, des FAQ et d\'autres outils du programme.',
  },
  terms: {
    description: 'Veuillez accepter les Conditions générales mises à jour pour accéder au portail commerce électronique des concessionnaires',
    errorMessage: 'Veuillez accepter Conditions générales.',
    modalTitle: 'Conditions générales',
    modalAccept: {
      paragraphOne: 'Vous essayez d’aller de l’avant sans avoir accepté les Conditions générales mises à jour, ce qui est requis pour fournir la fonctionnalité à jour. Veuillez cliquer sur le bouton « Retour » pour revenir à la page précédente afin d’accepter les Conditions générales et de poursuivre.',
      paragraphTwo: 'Veuillez noter que votre concessionnaire a jusqu’à la date suivante pour accepter les Conditions générales mises à jour :',
      paragraphThree: 'Advenant que les Conditions générales mises à jour ne soient pas acceptées d’ici cette date, votre concessionnaire sera désactivé du programme commerce électronique de véhicules neufs.',
    },
    modalNotAllowed: {
      paragraphOne: 'Vous n’avez pas l’autorisation d’accepter les Conditions générales. Les personnes suivantes ont l’autorisation d’accepter les Conditions générales :',
      paragraphTwo: 'Cliquez sur « Continuer » pour accéder au portail commerce électronique des concessionnaires. Veuillez noter : Votre concessionnaire a jusqu’à la date suivante pour accepter les Conditions générales mises à jour.',
      paragraphThree: 'Advenant que les Conditions générales mises à jour ne soient pas acceptées d’ici cette date, votre concessionnaire sera désactivé du programme commerce électronique de véhicules neufs.',
    },
  },
};
