export default {
  subtitle: 'Faites la configuration pour permettre aux clients de soumettre des dépôts à votre concessionnaire à partir du site https://www.lincolncanada.com/',
  accountSetup: {
    descriptionTop: {
      notStarted: `<p>Pour commencer votre demande de compte Stripe, cliquez sur le bouton « Demande Stripe ».</p>
      <p>Une fois que le compte de votre détaillant sera actif, le bouton passera à « Accéder à Stripe », ce qui vous mènera au tableau de bord de Stripe.</p>`,
    },
    descriptionBottom: {
      pending: `<p>Une fois que vous aurez terminé votre demande de compte Stripe, un état En suspens s’affichera, et il faudra attendre environ 10 minutes pour que votre compte soit actif. On peut cliquer sur le bouton gris « Vérifier état » pour vérifier la progression, et les renseignements sur la dernière vérification d’état s’afficheront à sa droite.</p>
      <p>Une fois que votre compte Stripe sera actif, vous reviendrez à cette page et confirmerez votre identifiant de compte Stripe ci-dessous.</p>`,
    },
  },
  feeSetup: {
    paragraphTwo: 'Cliquez sur l’accordéon pour configurer les paramètres liés aux fonds acceptés par votre détaillant dans commerce électronique. Le « Dépôt de réservation » et le « Dépôt à la caisse Lincoln autre que les Services financiers automobiles Lincoln » sont tous les deux établis par Lincoln et ne peuvent être modifiés. Le « Dépôt de commande » et la « Somme maximale acceptée par carte de crédit » présentent des menus déroulants avec des sommes déjà inscrites qui peuvent être choisies, confirmées et enregistrées.',
    card: {
      oem: 'Configuration équipementier',
      reservation: {
        description: 'Établies par Lincoln Canada',
      },
      cartCheckout: {
        input: 'Dépôt à la caisse Lincoln autre que les Services financiers automobiles Lincoln',
      },
    },
  },
  application: {
    message: 'Vous avez fait un pas important dans la configuration du compte Stripe de votre détaillant. Vous pouvez retourner à la page « Établissement de compte Stripe » à tout moment pour vérifier l’état de votre demande. Une fois votre compte Stripe approuvé, son état passera à « Actif », et vous recevrez un courriel du Secrétariat du programme commerce électronique qui comprendra l’identifiant de compte Stripe de votre concessionnaire. À ce moment-là, vous confirmerez votre identifiant de compte Stripe et configurerez les paramètres de votre détaillant pour les fonds acceptés des clients commerce électronique à la page \'Établissement de compte Stripe\'.',
  },
};
