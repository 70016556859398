<template>
  <section
    id="customer-experience"
    class="customer-experience"
  >
    <h2 class="customer-experience__title | fds-headline__5">
      {{ $l("modelEResourceCenter.experience.title") }}
    </h2>
    <div class="resource-center__content">
      <div class="customer-experience__content">
        <ModelEResourceCenterCardReserve />
        <ModelEResourceCenterCardOrder />
        <ModelEResourceCenterCardCart />
      </div>
    </div>
  </section>
</template>

<script>
import ModelEResourceCenterCardReserve from './ModelEResourceCenterCardReserve.vue';
import ModelEResourceCenterCardOrder from './ModelEResourceCenterCardOrder.vue';
import ModelEResourceCenterCardCart from './ModelEResourceCenterCardCart.vue';

export default {
  name: 'ModelEResourceCenterExperience',
  components: {
    ModelEResourceCenterCardReserve,
    ModelEResourceCenterCardOrder,
    ModelEResourceCenterCardCart,
  },
};
</script>

<style lang="scss" scoped>
.customer-experience {
  margin-top: 40px;
  &__header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin-bottom: 40px;
    @include respond(sm-up) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .customer-experience__title {
    margin: 0;
    margin-bottom: 40px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
  }
}
</style>
