<template>
  <div
    v-outside-click="closeDropdown"
    :id="id"
    class="app-dropdown"
    :class="modifiers"
    data-brand="ford"
  >
    <span
      class="app-dropdown__focus-outline"
      :style="{ height: focusHeight }"
    />
    <div class="app-dropdown__eyebrow">
      <div class="app-dropdown__label-wrapper">
        <label class="app-dropdown__label">{{ label }}</label>
        <div style="margin-top: 10px;">
          <slot />
        </div>
        <span
          class="app-icon app-dropdown__label-icon"
          :class="{
            'app-icon-font__error': modifiers['app-dropdown--invalid'],
            'app-icon-success': modifiers['app-dropdown--valid'],
          }"
        />
      </div>
    </div>
    <div class="app-dropdown__button-wrapper">
      <span class="app-dropdown__icon" />
      <span
        class="app-dropdown__placeholder"
        :class="{
          'app-hide':
            modifiers['app-dropdown--active'] ||
            modifiers['app-dropdown--dirty'],
        }"
        aria-hidden="true"
      >{{ label }}</span>
      <button
        :id="`${id}Button`"
        type="button"
        class="app-dropdown__button"
        :data-label="selectedText"
        data-req-message="Please select some data."
        data-options="[]"
        aria-haspopup="listbox"
        :aria-expanded="modifiers['app-dropdown__list--show']"
        :aria-label="label"
        :title="selectedText"
        autocomplete="off"
        @focus="setFocus"
        @blur="setBlur"
        @click="toggleActive"
        @keyup="setCursor"
      >
        {{ selectedText }}
      </button>
      <select
        :id="`${id}-Select`"
        class="app-dropdown__select"
        aria-labelledby="VehicleModelLabel"
      >
        <template
          v-for="item in list"
          :key="item.value"
        >
          <option
            class="app-dropdown__select-option"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </template>
      </select>
    </div>
    <span
      id="VehicleModelInstructions"
      class="app-dropdown__instructions"
    >Select some data.</span>
    <div class="app-dropdown__status">
      <span
        id="DropdownVehicleModelMessage"
        class="app-dropdown__message"
        role="alert"
        aria-live="assertive"
      >This field is required</span>
    </div>
    <div class="app-dropdown__list-wrapper">
      <ul
        class="app-dropdown__list"
        role="listbox"
        aria-labelledby="VehicleModelLabel"
        tabindex="-1"
        :aria-activedescendant="`${id}-Option-0`"
        @click="setSelected"
      >
        <template
          v-for="(item, idx) in list"
          :key="item.value"
        >
          <li
            :id="`${id}-Option-${item.value}`"
            class="app-dropdown__list-option"
            :class="{
              'app-dropdown__list-option--focused': idx === selectedIdx,
            }"
            role="option"
            :aria-selected="idx === selectedIdx"
            @mouseover="setCursor"
          >
            {{ item.text }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDownFds',
  props: {
    id: {
      type: String,
      default: 'FDSDropdown1',
    },
    label: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => ([]),
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  // emits: ["selection"],
  data() {
    return {
      selectedIdx: -1,
      focusHeight: '77px',
      modifiers: {
        'app-dropdown--hover': false,
        'app-dropdown--active': false,
        'app-dropdown--focused': false,
        'app-dropdown--dirty': false,
        'app-dropdown--valid': false,
        'app-dropdown--invalid': false,
        'app-dropdown__list--show': false,
      },
    };
  },
  computed: {
    selectedText() {
      return this.list.find((x) => x.selected)?.text;
    },
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler: function onText(arr) {
        const hasSelection = arr.filter((x) => x.selected).length > 0;
        this.modifiers['app-dropdown--dirty'] = hasSelection;
        this.modifiers['app-dropdown--valid'] = hasSelection;

        if (!this.modifiers['app-dropdown--dirty']) {
          this.selectedIdx = -1;
        }
      },
    },
  },
  methods: {
    closeDropdown() {
      if (!this.$attrs.devTesting) {
        this.modifiers['app-dropdown--active'] = false;
        this.modifiers['app-dropdown--focused'] = false;
        this.modifiers['app-dropdown__list--show'] = false;
        this.focusHeight = '77px';
      }
    },
    setFocus() {
      this.modifiers['app-dropdown--focused'] = true;
    },
    setBlur() {
      // this.modifiers["app-dropdown--active"] = false;
      // this.modifiers["app-dropdown--focused"] = false;
      // this.modifiers["app-dropdown__list--show"] = false;

      this.checkValidation();
    },
    toggleActive() {
      this.modifiers['app-dropdown--active'] = true;
      this.modifiers['app-dropdown__list--show'] =
        !this.modifiers['app-dropdown__list--show'];
      if (this.modifiers['app-dropdown__list--show']) {
        // read list element height and calculate
        this.$nextTick(() => {
          const div = document.querySelector(
            `#${this.id} .app-dropdown__list-wrapper`,
          );
          const h = div.clientHeight || 327;
          this.focusHeight = `${h + 77}px`;
        });
      } else {
        this.focusHeight = '77px';
      }
    },
    setCursor(e) {
      if (this.list.length < 1) return;

      if (e.type === 'mouseover') {
        const li = e.target;
        this.selectedIdx = this.list.findIndex((x) => x.text === li.innerText);
      } else {
        switch (e.code) {
          // case "Space":
          //   e.stopPropagation();
          //   e.preventDefault();
          //   this.modifiers["app-dropdown__list--show"] =
          //     !this.modifiers["app-dropdown__list--show"];
          //   break;
          case 'ArrowDown':
            this.selectedIdx += 1;

            // Jump back top once reached beyond bottom
            if (this.selectedIdx > this.list.length - 1) {
              this.selectedIdx = 0;
            }
            break;
          case 'ArrowUp':
            this.selectedIdx -= 1;

            // Jump to bottom once reached above top
            if (this.selectedIdx < 0) {
              this.selectedIdx = this.list.length - 1;
            }
            break;
          default:
            break;
        }

        // set the highlighted item
        let idx = 0;
        this.list.forEach((x) => {
          const item = x;
          if (this.selectedIdx === idx) {
            item.selected = true;
            this.modifiers['app-dropdown--dirty'] = true;
            this.$emit('selection', item);
          } else {
            item.selected = false;
          }
          idx += 1;
        });
      }
    },
    setSelected(e) {
      const li = e.target;
      this.list.forEach((x) => {
        const item = x;
        item.selected = false;
        if (item.text == li.innerText) {
          item.selected = true;
          this.modifiers['app-dropdown--dirty'] = true;
          this.modifiers['app-dropdown__list--show'] = false;
          this.modifiers['app-dropdown--active'] = false;
          this.focusHeight = '77px';
          this.$emit('selection', item);
        }
      });

      this.checkValidation();
    },
    checkValidation() {
      this.modifiers['app-dropdown--valid'] = false;
      this.modifiers['app-dropdown--invalid'] = false;
      const hasSelection = this.list.filter((x) => x.selected).length;

      if (this.required && hasSelection === 0) {
        this.modifiers['app-dropdown--invalid'] = true;
      }
      if (this.required && hasSelection > 0) {
        this.modifiers['app-dropdown--valid'] = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$app-dropdown__option-height: fds-rem(50px);
$app-dropdown__option-max: 5;

.app-dropdown {
  display: flex;
  font-weight: $font-weight--normal;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: fds-rem(27px);
  overflow: visible;
  margin-top: 15px; // add some spacing between components
}

.app-dropdown--valid {
  & .app-dropdown__button,
  & .app-dropdown__select {
    border-color: var(--app-color--success1);
  }

  & .app-dropdown__status {
    display: flex;
  }

  & .app-dropdown__message {
    opacity: 0.01;
    height: 0;
  }

  & .app-dropdown__label-icon {
    display: block;
  }
}

.app-dropdown--invalid {
  & .app-dropdown__button,
  & .app-dropdown__select {
    border-color: var(--app-color--error1);
  }

  & .app-dropdown__list {
    border-color: var(--app-color--error1);
  }

  & .app-dropdown__status {
    display: flex;
  }

  & .app-dropdown__message {
    opacity: 1;
    color: var(--app-color--error1);
  }

  & .app-dropdown__label-icon {
    display: block;
  }
}

.app-dropdown__focus-outline {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

.app-dropdown--touch {
  & .app-dropdown__select {
    display: block;
    opacity: 0;
  }
  &.app-dropdown--dirty {
    & .app-dropdown__select {
      opacity: 1;
    }
    & .app-dropdown__button {
      display: none;
    }
  }
}

.app-dropdown__icon {
  position: absolute;
  top: fds-rem(15px);
  right: fds-rem(15px);
  transition: transform 0.3s ease-in-out;

  &::after {
    font-family: $font-family--icons;
    /*font-size: fds-rem(16px);*/
    line-height: fds-rem(16px);
    color: var(--app-color--primary);
    content: $app-icon-chevron-down;
  }
}

.app-dropdown__placeholder {
  position: absolute;
  left: fds-rem(15px);
  top: fds-rem(18px);
  /*font-size: fds-rem(16px);*/
  line-height: fds-rem(16px);
  letter-spacing: 1px;
  @media only screen and (max-width: 430px)  {
    font-size: 9px;
    width: 130px;
    white-space: pre-wrap;
  }
}

.app-dropdown--dirty {
  & .app-dropdown__button,
  & .app-dropdown__select {
    color: var(--app-color--gray3);
  }

  & .app-dropdown__placeholder {
    display: none;
  }
}

.app-dropdown__label-wrapper {
  display: flex;
  align-items: center;
}

.app-dropdown--active,
.app-dropdown--dirty {
  & .app-dropdown__label {
    position: relative;
    opacity: 1;
    margin-right: fds-rem(5px);
  }
}

.app-dropdown--active,
.app-dropdown--hover {
  & .app-dropdown__button-wrapper {
    box-shadow: inset 0px 0px 0px transparent,
      $fds-elevation__box-shadow--layer2;
    background-color: var(--app-color--white);
    color: var(--app-color--primary);
  }
}

.app-dropdown--focused {
  & .app-dropdown__focus-outline {
    outline: 1px solid var(--app-color--primary);
    outline-offset: fds-rem(10px);
  }
}

.app-dropdown__list--show {
  & .app-dropdown__icon {
    transform: rotate(-180deg);
  }

  &:not(.app-dropdown--touch) {
    & .app-dropdown__list-wrapper {
      opacity: 1;
      display: block;
    }

    & .app-dropdown__button {
      border-radius: fds-rem(3px 3px 0 0);
    }
  }
}

.app-dropdown__instructions {
  display: none;
}

.app-dropdown__eyebrow {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fds-rem(16px);
  margin-bottom: fds-rem(13px);
}

.app-dropdown__label {
  font-size: fds-rem(12px);
  line-height: fds-rem(12px);
  letter-spacing: 1px;

  position: absolute;
  white-space: nowrap;
  opacity: 0;
  color: var(--app-color--gray3);
  transition: opacity 0.3s ease-in-out;
  margin-bottom: -12px;
  font-weight: 500;
  @media only screen and (max-width: 430px)  {
    font-size: 10px;
    width: 150px;
    white-space: pre-wrap;
  }
}

.app-dropdown__label-icon {
  display: none;
}

.app-dropdown__button-wrapper {
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: fds-rem(50px);
  width: 100%;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.app-dropdown__select-option--hide {
  display: none;
}

.app-dropdown__button,
.app-dropdown__select {
  /*font-size: fds-rem(16px);*/
  line-height: fds-rem(16px);
  letter-spacing: 1px;

  position: absolute;
  top: 0;
  -webkit-appearance: none;
  display: none;
  background: none;
  color: var(--app-color--gray3);
  resize: none;
  width: 100%;
  height: fds-rem(50px);
  padding: fds-rem(15px);
  border: solid 1px;
  border-color: $color--fds-gray2;
  border-radius: fds-rem(3px);
}

.app-dropdown__button {
  display: flex;
  align-items: center;
}

.app-dropdown__select {
  height: 100%;
  z-index: 3;
}

.app-dropdown__list-wrapper {
  display: none;
  width: 100%;
  top: fds-rem(77px);
  left: 0;
  opacity: 0;
  position: absolute;
  z-index: 3;
  box-shadow: inset 0px 0px 0px transparent, $fds-elevation__box-shadow--layer2;
  transition: opacity 0.3s ease-in-out;
}

.app-dropdown__list {
  width: 100%;
  max-height: calc(
    #{$app-dropdown__option-max} * #{$app-dropdown__option-height}
  );
  overflow-y: auto;
  border-radius: 0 0 fds-rem(3px) fds-rem(3px);
  background-color: #fff;
  color: var(--app-color--primary);
  border: solid 1px var(--app-color--gray2);
  border-top: none;
  list-style: none;
  padding: 0;
  outline: none;
  margin-bottom: 0px;
}

.app-dropdown__list-option {
  /*font-size: fds-rem(16px);*/
  line-height: fds-rem(16px);
  letter-spacing: 1px;

  color: var(--app-color--primary);
  line-height: $app-dropdown__option-height;
  padding: fds-rem(0 16px);
  cursor: pointer;
}

.app-dropdown__list-option--focused,
.app-dropdown__list-option:focus {
  background-color: var(--app-color--gray2);
  color: var(--app-color--white);
  outline: none;
}

.app-dropdown--disabled {
  & .app-dropdown__button-wrapper {
    cursor: auto;

    & .app-dropdown__placeholder,
    & .app-dropdown__icon {
      color: var(--app-color--white);
      z-index: 1;

      &::after {
        color: var(--app-color--white);
      }
    }

    & .app-dropdown__button {
      background-color: var(--app-color--disabled3);
      color: var(--app-color--white);
      cursor: not-allowed;
    }
  }
}

.app-dropdown__message {
  opacity: 0;
  width: 100%;
  font-size: fds-rem(12px);
  line-height: fds-rem(18px);
  letter-spacing: fds-rem(1px);
}

.app-dropdown__status {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: fds-rem(7px);
}
</style>
