<template>
  <aside class="aside">
    <ul class="aside__list">
      <li
        v-for="category in categories"
        :key="category.id"
        class="aside__item"
        :class="{ 'aside__item--active': activeCategories.levelOne === category.id }"
      >
        <button
          v-text="category.title"
          type="button"
          class="aside__button"
          @click="setActiveCategory(categoryLevels.one, category.id)"
        />
        <ul
          v-if="category.children.length"
          class="aside__list aside__list--second"
        >
          <li
            v-for="secondCategory in category.children"
            :key="secondCategory.id"
            class="aside__item aside__item--second"
            :class="{ 'aside__item--active': activeCategories.levelTwo === secondCategory.id }"
          >
            <button
              v-text="secondCategory.title"
              type="button"
              class="aside__button aside__button--second"
              @click="setActiveCategory(categoryLevels.two, secondCategory.id)"
            />
            <ul
              v-if="secondCategory.children.length"
              class="aside__list aside__list--third"
            >
              <li
                v-for="thirdCategory in secondCategory.children"
                :key="thirdCategory.id"
                class="aside__item aside__item--third"
                :class="{ 'aside__item--active': activeCategories.levelThree === thirdCategory.id }"
              >
                <button
                  v-text="thirdCategory.title"
                  type="button"
                  class="aside__button aside__button--third"
                  @click="setActiveCategory(categoryLevels.three, thirdCategory.id)"
                />
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'FAQsAside',
  emits: ['set-active-category'],
  props: {
    categoryLevels: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    activeCategories: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setActiveCategory(level, categoryId) {
      this.$emit('set-active-category', {
        level,
        categoryId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  width: 100%;
  max-width: 400px;
  height: fit-content;
  background: #f7f7f7;
  padding: 40px;
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    &--second, &--third {
      display: none;
      padding-left: 16px;
    }
  }
  &__item {
    &--active {
      > .aside__button {
        color: $color--fds-secondary;
        &::before {
          display: flex;
          content: '\e932';
        }
        &--second::before {
          content: '\e933';
        }
        &--third::before {
          content: '\e934';
        }
      }
      > .aside__list {
        display: block;
      }
    }
  }
  &__button {
    width: 100%;
    border: 0;
    color: $color--fds-primary;
    background-color: transparent;
    font-size: 15px;
    font-weight: $font-weight--normal;
    text-align: left;
    padding: 0 5px;
    position: relative;
    letter-spacing: 0px;
    &:hover {
      color: $color--white;
      background-color: #b4b4b4;
      margin-left: -30px;
      padding-left: 30px;
      &::before {
        display: block;
        margin-left: 30px;
      }
    }
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -20px;
      font-family: $font-family--icons;
    }
  }
}
.lincoln .aside {
  &__item {
    &--active {
      > .aside__button {
        color: $color--lds-secondary;
      }
    }
  }
  &__button {
    color: $color--lds-primary;
    &:hover {
      color: $color--white;
    }
  }
}
</style>
