<template>
  <div
    class="stripe-account-fee-setup"
  >
    <ModelEStripeAccountIdFees />
  </div>
</template>

<script>
import ModelEStripeAccountIdFees from './ModelEStripeAccountIdFees.vue';

export default {
  name: 'ModelEStripeAccountFeeSetup',
  components: {
    ModelEStripeAccountIdFees,
  },
};
</script>
