<template>
  <PopUpTip :title="title">
    <template #popupTipContent>
      <p class="description">
        {{ description }}
      </p>
      <button
        class="download-button"
        :class="$store.getters['user/activeBrandName']"
        @click="downloadDocument"
      >
        {{ $l('general.download') }} <span class="app-icon-download" />
      </button>
    </template>
    <slot />
  </PopUpTip>
</template>

<script>
import apiManager from '@/api';
import { ElementType, Event } from '@/constants/track';
import PopUpTip from './common/PopUpTip.vue';

export default {
  name: 'DocumentDetailsPopUpTip',
  components: {
    PopUpTip,
  },
  emits: ['start-download', 'end-download'],
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    documentName: {
      type: String,
      default: '',
    },
  },
  methods: {
    async downloadDocument() {
      this.$emit('start-download');

      try {
        const link = document.createElement('a');

        this.$store.dispatch('track/event', {
          event: `${Event.DOWNLOAD_DOCUMENT}_${this.documentName}`,
          section: 'documentDetailsTip',
          elementType: ElementType.CARD,
          pageName: this.$route.meta.track.pageName,
        });

        link.href = await apiManager.document.getBase64(this.documentName);
        link.download = this.title;

        link.click();
      } catch (error) {
        console.error(error.message);
      } finally {
        this.$emit('end-download');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  margin: 0;
}
.download-button {
  --color: #{$color--fds-primary};
  --hover-color: #{$color--fds-secondary};
  padding: 0;
  margin-top: 32px;
  margin-bottom: 16px;
  border: 0;
  background-color: transparent;
  color: var(--color);
  font-size: 18px;
  outline-offset: 5px;
  &:hover {
    color: var(--hover-color);
    text-decoration: underline;
  }
  &.lincoln {
    --color: #{$color--white};
    --hover-color: #{$color--lds-secondary};
  }
  .app-icon-download {
    margin-left: 12px;
    font-size: 24px;
  }
}
</style>
