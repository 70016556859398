<template>
  <form
    class="stripe-order"
    @submit.prevent="confirmOrderDeposit"
  >
    <AppDropdown
      v-model="depositValue"
      id="stripe-order__deposit"
      class="stripe-order__dropdown"
      :label="$l('stripe.feeSetup.card.deposit')"
      :disabled="!userIsKeyDecisionMaker"
      :options="dropdownOptions"
    />
    <span
      v-if="showDepositIsConfirmedMessage"
      v-text="$l('general.confirmed')"
      class="stripe-order__confirmed-message"
    />
    <template v-else-if="userIsKeyDecisionMaker">
      <AppCheckbox
        v-model="userConfirmDeposit"
        id="stripe-order__checkbox"
        :label="$l('general.confirm')"
      />
      <StandardButton
        type="submit"
        track-event-name="submitForm_order"
        track-section-name="stripeAccountOrder"
        :is-loading="isLoading"
        :disabled="!userConfirmDeposit"
        :text="$l('general.save')"
      />
    </template>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import AppCheckbox from './common/AppCheckbox.vue';
import StandardButton from './common/StandardButton.vue';
import AppDropdown from './common/AppDropdown.vue';

export default {
  name: 'StripeFeeSetupCardOrder',
  components: {
    AppCheckbox,
    StandardButton,
    AppDropdown,
  },
  data() {
    return {
      isLoading: false,
      depositValue: '',
      userConfirmDeposit: false,
    };
  },
  computed: {
    ...mapGetters({
      activeBrand: 'user/activeBrand',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      depositOptions: 'stripe/orderDepositOptions',
      records: 'stripe/records',
    }),
    dropdownOptions() {
      return this.depositOptions.map((option) => ({
        text: `$${option.amount}`,
        value: +option.amount,
      }));
    },
    showDepositIsConfirmedMessage() {
      return this.records.order.depositConfirmed
        && this.records.order.deposit === this.depositValue;
    },
  },
  watch: {
    activeBrand: {
      immediate: true,
      handler() {
        this.userConfirmDeposit = false;
        this.getDepositValue();
      },
    },
  },
  methods: {
    getDepositValue() {
      const defaultDeposit = this.depositOptions.filter(({ isDefault }) => isDefault)[0]?.amount;
      const defaultValue = +(defaultDeposit || this.depositOptions[0].amount);
      const { deposit } = this.records.order;

      this.depositValue = deposit || defaultValue;
    },
    async confirmOrderDeposit() {
      if (!this.userConfirmDeposit) return;

      this.isLoading = true;

      try {
        await this.$store.dispatch('stripe/confirmOrderDeposit', {
          depositValue: this.depositValue,
        });

        this.$store.dispatch('topBanner/open', {
          message: this.$l('stripe.feeSetup.card.order.success'),
        });

        this.userConfirmDeposit = false;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('stripe.feeSetup.card.order.error'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  @include respond(sm-up) {
    flex-direction: row;
    justify-content: unset;
    align-items: center;
  }
  &__confirmed-message {
    color: $color--fds-success2;
  }
}
</style>
