<template>
  <div
    v-outside-click="closeDropdown"
    class="dropdown"
    :class="[dropdownClasses, $store.getters['user/activeBrandName']]"
  >
    <TextField
      v-bind="$attrs"
      v-model="inputText"
      spellcheck="false"
      :type="searchable ? 'text' : 'button'"
      :active="dropdownIsOpen"
      @click="handleClick"
      @keyup.up="moveCursor(-1)"
      @keyup.down="moveCursor(1)"
      @keyup.esc="closeDropdown"
      @keyup.enter="handleEnter"
    >
      <span
        class="dropdown__icon-arrow app-icon-chevron-down"
        @click="handleClick"
      />
      <div
        v-show="dropdownIsOpen"
        ref="listbox"
        class="dropdown__list"
        role="listbox"
      >
        <a
          v-for="(option, index) in filteredOptions"
          v-text="option.text"
          :key="JSON.stringify(option)"
          class="dropdown__list-option"
          href="#"
          @focus="activeOptionIndex = index"
          @click.prevent="handleSelection(option)"
          @keyup.up="moveCursor(-1)"
          @keyup.down="moveCursor(1)"
          @keyup.esc="closeDropdown"
        />
      </div>
    </TextField>
  </div>
</template>

<script>
import TextField from './TextField.vue';

export default {
  name: 'AppDropdown',
  components: {
    TextField,
  },
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    searchable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      activeOptionIndex: -1,
      dropdownIsOpen: false,
      searchableText: '',
      initialSearchableText: '',
    };
  },
  computed: {
    dropdownClasses() {
      return {
        'dropdown--active': this.dropdownIsOpen,
      };
    },
    selectedOptionText() {
      return this.options.find(({ value }) => value === this.modelValue)?.text || '';
    },
    inputText: {
      get() {
        if (this.searchable) {
          return this.searchableText;
        }

        return this.selectedOptionText;
      },
      set(value) {
        this.openDropdown();
        this.searchableText = value;
      },
    },
    filteredOptions() {
      if (!this.searchable) {
        return this.options;
      }

      return this.options
        .filter(({ text }) => text.toLowerCase().includes(this.searchableText.toLowerCase()));
    },
  },
  watch: {
    selectedOptionText() {
      this.searchableText = this.selectedOptionText;
      this.initialSearchableText = this.selectedOptionText;
    },
  },
  methods: {
    moveCursor(increment) {
      const newIndex = this.activeOptionIndex + increment;

      if (newIndex > -1 && newIndex < this.$refs.listbox.children.length) {
        this.$refs.listbox.children[newIndex].focus();
      }
    },
    handleEnter() {
      if (this.searchable) {
        this.handleClick();
      }
    },
    handleClick() {
      if (this.searchable && this.dropdownIsOpen) {
        return;
      }

      // Clears text when opening dropdown
      if (this.searchable && !this.dropdownIsOpen) {
        this.searchableText = '';
      }

      if (this.dropdownIsOpen) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },
    handleSelection(option) {
      this.initialSearchableText = option.text;
      this.$emit('update:modelValue', option.value);
      this.closeDropdown();
    },
    openDropdown() {
      this.dropdownIsOpen = true;
    },
    closeDropdown() {
      if (this.searchable && this.initialSearchableText !== this.searchableText) {
        this.searchableText = this.initialSearchableText;
      }

      this.dropdownIsOpen = false;
      this.activeOptionIndex = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  --list-color: #{$color--fds-primary};
  --list-border-color: #{$color--fds-gray2};
  --list-box-shadow: #{$fds-elevation__box-shadow--layer2};
  --list-option-height: 5rem;
  --list-option--hover-background: #{$color--fds-gray2};
  &__icon-arrow {
    position: absolute;
    right: 16px;
    bottom: 16px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  &__list {
    width: 100%;
    max-height: calc(5 * var(--list-option-height));
    background-color: $color--white;
    border: 1px solid var(--list-border-color);
    border-radius: 0 0 3px 3px;
    box-shadow: var(--list-box-shadow);
    list-style: none;
    padding: 0;
    position: absolute;
    top: calc(100% - 2px);
    z-index: 1;
    overflow-y: auto;
  }
  &__list-option {
    display: flex;
    align-items: center;
    height: var(--list-option-height);
    padding: 0 16px;
    color: var(--list-color);
    cursor: pointer;
    outline: none;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $color--white;
      background-color: var(--list-option--hover-background);
    }
  }
  &--active {
    .dropdown__icon-arrow {
      transform: rotate(-180deg);
    }
  }
  &:not(.dropdown--active) {
    :deep(.text-field__label) {
      cursor: pointer;
    }
  }
}
.dropdown.lincoln {
  --list-color: #{$color--lds-primary};
  --list-border-color: #{$color--lds-gray2};
  --list-box-shadow: none;
  --list-option--hover-background: #{$color--lds-gray4};
  .dropdown__list {
    top: calc(100% + 1px);
  }
  :deep(.text-field__icon--lincoln) {
    display: none;
  }
}
</style>
