<template>
  <div class="iframe-container">
    <VimeoPlayer :video-url="$route.params.id" />
  </div>
</template>

<script>
import VimeoPlayer from '@/components/VimeoPlayer.vue';

export default {
  name: 'VideoView',
  components: { VimeoPlayer },
  mounted() {
    sessionStorage.removeItem(process.env.VUE_APP_LAST_VISITED_PAGE);
  },
};
</script>

<style lang="scss" scoped>
.iframe-container {
  width: 85%;
  min-height: 0 !important;
  margin: 0 auto;
  border: 0;
  position: relative;
}
:deep(iframe) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
:deep(div[data-vimeo-initialized]) {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border: 1px solid #cccccc;
  position: relative;
}
</style>
