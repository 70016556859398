<template>
  <AppToggle
    v-if="showToggle"
    v-bind="$attrs"
    id="header-experience-selector"
    has-outside-text
    style="--color-checked: #6e6e6e"
    active-text="Model e"
    inactive-text="Blue"
    :model-value="activeExperienceIsModelE"
    @update:model-value="changeActiveExperience"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { ElementType, Event } from '@/constants/track';
import AppToggle from './common/AppToggle.vue';

export default {
  name: 'TheHeaderExperienceSelector',
  components: {
    AppToggle,
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      activeBrandIsFord: 'user/activeBrandIsFord',
      activeExperienceIsModelE: 'user/activeExperienceIsModelE',
      certificationLevel: 'user/certificationLevel',
    }),
    showToggle() {
      return (
        this.activeFeatures.modelE &&
        !this.certificationLevel.blueOnly &&
        this.activeBrandIsFord &&
        !this.$route.meta.header?.disableExperienceSelector
      );
    },
  },
  methods: {
    changeActiveExperience() {
      this.$store.dispatch('track/event', {
        event: this.activeExperienceIsModelE
          ? Event.SELECT_EXPERIENCE_BLUE
          : Event.SELECT_EXPERIENCE_MODEL_E,
        elementType: ElementType.TOGGLE,
        pageName: this.$route.meta.track.pageName,
      });

      if (this.activeExperienceIsModelE) {
        this.$router.push({ name: 'Start' });
      } else {
        this.$router.push({ name: 'ModelEStart' });
      }
    },
  },
};
</script>
