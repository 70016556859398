import apiManager from '@/api';
import resourceCenterId from '@/data/resourceCenter';

import accessoryPricingModule from './modules/accessoryPricing';
import customerActivityModule from './modules/customerActivity';
import customerHandlingModule from './modules/customerHandling';
import feeAndSalesSetupModule from './modules/feeAndSalesSetup';
import fiSetupModule from './modules/fiSetup';
import accordionsModule from './modules/accordions';
import tradeInModule from './modules/tradeIn';
import vehiclePricingModule from './modules/vehiclePricing';
import enrollmentModule from './modules/enrollment';
import notificationsModule from './modules/notifications';
import stripeModule from './modules/stripe';

export default {
  namespaced: true,
  modules: {
    accessoryPricing: accessoryPricingModule,
    accordions: accordionsModule,
    customerActivity: customerActivityModule,
    customerHandling: customerHandlingModule,
    enrollment: enrollmentModule,
    feeAndSalesSetup: feeAndSalesSetupModule,
    fiSetup: fiSetupModule,
    notifications: notificationsModule,
    stripe: stripeModule,
    tradeIn: tradeInModule,
    vehiclePricing: vehiclePricingModule,
  },
  state() {
    return {
      resources: [],
    };
  },
  getters: {
    resources: (state) => state.resources,
    documentPath() {
      if (['local', 'development'].includes(process.env.VUE_APP_BASE_ENVIRONMENT)) {
        return 'https://aa2core2.jdstaging.com/Ford/FordLincolneCommGlobal/fesite/resources/static/documents';
      }
      return `${process.env.VUE_APP_BASE_URL}resources/static/documents`;
    },
  },
  mutations: {
    setResources(state, payload) {
      state.resources = payload;
    },
  },
  actions: {
    loadData: async ({ dispatch }) => {
      await Promise.allSettled([
        dispatch('vehiclePricing/load'),
        dispatch('accessoryPricing/load'),
        dispatch('tradeIn/load'),
      ]);
    },
    async getResources({ commit, getters, rootGetters }) {
      if (getters.resources.length) return;

      const [english, french] = await Promise.allSettled([
        // Get english resources
        apiManager.resourceCenter.getResults({
          pageId: resourceCenterId.page.DASHBOARD,
          language: rootGetters['locale/locales'].english,
        }),
        // Get french resources
        apiManager.resourceCenter.getResults({
          pageId: resourceCenterId.page.DASHBOARD,
          language: rootGetters['locale/locales'].french,
        }),
      ]);

      const { value: { data: englishResources } } = english;
      const { value: { data: frenchResources } } = french;

      commit('setResources', [
        ...englishResources.result,
        ...frenchResources.result,
      ]);
    },
  },
};
