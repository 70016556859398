export default {
  title: 'Lincoln eCommerce Experiences',
  welcome: {
    paragraph1: 'eCommerce Store Enrolment is just a few clicks away.',
    paragraph2: 'Lincoln will be offering a suite of eCommerce experiences, leading with Cart and Checkout for new vehicle purchases with direct-to-Store payments. This portal hosts instruction, access, and training content to guide your Store through activation. Program support is available at any time via the Help page. Click ‘Resource Center’ above to access all checklists, setup guides, FAQs, and other program tools.',
  },
  terms: {
    modalAccept: {
      paragraphTwo: 'Please note your Store has until the following date to accept the updated Terms & Conditions:',
      paragraphThree: 'In the event the updated Terms & Conditions are not accepted by this date, your Store will be deactivated from New Vehicle eCommerce.',
    },
    modalNotAllowed: {
      paragraphTwo: 'Click "Continue" to navigate to the eCommerce Dealer Portal. Please Note: Your Store has until the following date to accept the updated Terms & Conditions.',
      paragraphThree: 'In the event the updated Terms & Conditions are not accepted by this date, your Store will be deactivated from New Vehicle eCommerce.',
    },
  },
};
