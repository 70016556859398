<template>
  <div class="accessory-pricing container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="accessory-pricing-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <p>{{ $l('dashboard.accessoryPricing.sectionOne.paragraphOne') }}</p>
        <p>{{ $l('dashboard.accessoryPricing.sectionOne.paragraphTwo') }}</p>
      </div>
      <div class="accessory-pricing__link-wrapper col-xs-12 col-sm-5 col-lg-4">
        <StandardButton
          track-event-name="click_setAccessoryPrincing"
          track-section-name="accessoryPricing"
          :to="linkPath"
          :text="$l('dashboard.accessoryPricing.sectionOne.linkOne')"
        />
      </div>
    </div>
    <DashboardDivider />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <p>{{ $l('dashboard.accessoryPricing.sectionOne.paragraphThree') }}</p>
      </div>
      <div class="accessory-pricing__link-wrapper col-xs-12 col-sm-5 col-lg-4">
        <StandardButton
          track-event-name="click_setEntitlements"
          track-section-name="accessoryPricing"
          to="https://www.accessmgmt.dealerconnection.com/CspsWebJSF/xhtml/requestAccess/requestAccess.faces"
          :text="$l('dashboard.accessoryPricing.sectionOne.linkTwo')"
        />
      </div>
    </div>
    <div class="accessory-pricing__toast-wrapper">
      <AppToast
        v-if="entitlements.length > 0"
        :text="entitlements.join(', ')"
      />
      <AppToast
        v-else
        type="error"
        :text="$l('dashboard.accessoryPricing.sectionOne.errorToast')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import AppToast from './common/AppToast.vue';
import DashboardDivider from './DashboardDivider.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'DashboardAccessoryPricing',
  components: {
    StandardButton,
    AppToast,
    DashboardDivider,
    DashboardResourcesDisplay,
  },
  computed: {
    ...mapGetters({
      activeBrand: 'user/activeBrand',
      resources: 'dashboard/accessoryPricing/activeResources',
      entitlements: 'dashboard/accessoryPricing/entitlements',
    }),
    linkPath() {
      return `https://www.emp.dealerconnection.com/#page=accessories&brand=${this.activeBrand}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.accessory-pricing {
  &__link-wrapper {
    display: flex;
    justify-content: center;
    @include respond(sm-up) {
      justify-content: flex-end;
    }
  }
  &__toast-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}
</style>
