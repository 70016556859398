import { createApp } from 'vue';
import FloatingVue from 'floating-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import directives from '@/directives';
import i18n from './plugins/i18n';
import Localization from './plugins/localization';

import 'floating-vue/dist/style.css';
import '@/scss/main.scss';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(i18n);
app.use(Localization);
app.use(directives);
app.use(FloatingVue, {
  themes: {
    'ford-tooltip': {
      $extend: 'dropdown',
      $resetCss: true,
    },
    'lincoln-tooltip': {
      $extend: 'dropdown',
      $resetCss: true,
    },
  },
});
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.mount('#app');
