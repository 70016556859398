<template>
  <ul>
    <TheHeaderSkipLink />
    <TheHeaderMenuItem
      v-if="reportsIsVisible"
      path="/reports"
      :name="$l('header.links.reports')"
    />
    <TheHeaderMenuItem
      :path="{ name: 'ResourceCenter' }"
      :name="$l('header.links.resources')"
    />
    <TheHeaderMenuItem
      :path="{ name: 'Communications Center' }"
      :name="$l('header.links.commsCenter')"
      :icon="alertIcon"
    />
    <TheHeaderMenuItem
      :path="{ name: 'Dashboard' }"
      :name="$l('header.links.dashboard')"
    />
    <TheHeaderMenuItem
      v-if="$route.name === 'Stripe'"
      :path="{ name: 'Stripe' }"
      :name="$l('header.links.stripe')"
    />
    <TheHeaderMenuItem
      :path="{ name: 'Help' }"
      icon="app-icon-help-chat"
      :name="$l('header.links.help')"
    />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import TheHeaderSkipLink from './TheHeaderSkipLink.vue';
import TheHeaderMenuItem from './TheHeaderMenuItem.vue';

export default {
  name: 'TheHeaderMenuListBlue',
  components: {
    TheHeaderSkipLink,
    TheHeaderMenuItem,
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/userProfile',
      commsCenterHasUnreadContent: 'commsCenter/hasUnreadContent',
    }),
    alertIcon() {
      return (this.commsCenterHasUnreadContent) ? 'app-icon-exclamation-triangle' : '';
    },
    reportsIsVisible() {
      return this.userProfile.reports.length;
    },
  },
};
</script>
