<template>
  <div class="dashboard">
    <h2 class="dashboard__heading fds-headline__4">
      {{ dashboardTitle }}
    </h2>
    <ActivationSummary />
    <div :key="dashboardKey">
      <h2 class="dashboard__heading fds-headline__4">
        {{ $l('dashboard.heading') }}
      </h2>
      <AccordionNotifications />
      <AccordionEnrollment
        @enrollment-complete="handleEnrollmentComplete"
      />
      <template v-if="activeDealership.enrolled">
        <AccordionStripeAccount />
        <AccordionVehiclePricing />
        <AccordionAccessoryPricing />
        <AccordionTradeIn />
        <AccordionFISetup />
        <AccordionCustomerHandling />
        <AccordionCustomerActivity />
      </template>
      <ModalEnrollmentSuccessful
        :open="showEnrollmentModal"
        @close="closeEnrollmentModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import vehiclePricingAlertMixin from '@/mixins/vehiclePricingAlertMixin';
import keyDecisionMakerAlertMixin from '@/mixins/keyDecisionMakerAlertMixin';
import ActivationSummary from '@/components/ActivationSummary.vue';
import ModalEnrollmentSuccessful from '@/components/DashboardModalEnrollmentSuccessful.vue';
import AccordionNotifications from '@/components/DashboardAccordionNotifications.vue';
import AccordionEnrollment from '@/components/DashboardAccordionEnrollment.vue';
import AccordionStripeAccount from '@/components/DashboardAccordionStripeAccount.vue';
import AccordionVehiclePricing from '@/components/DashboardAccordionVehiclePricing.vue';
import AccordionAccessoryPricing from '@/components/DashboardAccordionAccessoryPricing.vue';
import AccordionTradeIn from '@/components/DashboardAccordionTradeIn.vue';
import AccordionFISetup from '@/components/DashboardAccordionFISetup.vue';
import AccordionCustomerHandling from '@/components/DashboardAccordionCustomerHandling.vue';
import AccordionCustomerActivity from '@/components/DashboardAccordionCustomerActivity.vue';

export default {
  name: 'DashboardView',
  components: {
    ActivationSummary,
    ModalEnrollmentSuccessful,
    AccordionNotifications,
    AccordionEnrollment,
    AccordionStripeAccount,
    AccordionVehiclePricing,
    AccordionAccessoryPricing,
    AccordionTradeIn,
    AccordionFISetup,
    AccordionCustomerHandling,
    AccordionCustomerActivity,
  },
  mixins: [keyDecisionMakerAlertMixin],
  data() {
    return {
      dashboardKey: 0,
      renderComponent: true,
      showEnrollmentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      activeBrandIsFord: 'user/activeBrandIsFord',
      activeDealership: 'user/activeDealership',
      dashboardAccordions: 'dashboard/accordions/accordions',
      notifications: 'dashboard/notifications/notifications',
    }),
    dashboardTitle() {
      return (this.activeFeatures.modelE && this.activeBrandIsFord)
        ? this.$l('dashboard.blueTitle')
        : this.$l('dashboard.title');
    },
  },
  async mounted() {
    this.$store.dispatch('stripe/load');
    this.$store.dispatch('dashboard/loadData');
    this.$store.dispatch('dashboard/getResources');

    this.openNotificationsAccordion();

    await this.$store.dispatch('activationSummary/load');
  },
  beforeUnmount() {
    this.$store.dispatch('dashboard/accordions/resetActiveAccordion');
    this.$store.dispatch('dashboard/accordions/resetActiveSubcordion');
  },
  methods: {
    openEnrollmentModal() {
      this.showEnrollmentModal = true;
    },
    closeEnrollmentModal() {
      this.showEnrollmentModal = false;

      // Reloads dashboard components
      this.dashboardKey += 1;
    },
    handleEnrollmentComplete() {
      this.openEnrollmentModal();

      this.$store.dispatch('dealerPersonnel/loadContacts');
      this.$store.dispatch('user/loadUser');

      this.openNotificationsAccordion();
    },
    async openNotificationsAccordion() {
      if (this.notifications.length < 1) {
        this.$store.dispatch(
          'dashboard/accordions/openAccordion',
          this.dashboardAccordions.enrollment.id,
        );
        return;
      }

      this.$store.dispatch(
        'dashboard/accordions/openAccordion',
        this.dashboardAccordions.notifications.id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  max-width: 1340px;
  margin: 0 auto;
  &__heading {
    margin: 20px 0;
    text-align: center;
  }
}
</style>
