<template>
  <div class="dealer-profile">
    <PageHeader :title="$l('reports.dealerProfile.title')" />
    <PowerbiReport
      :report-id="dealerProfileReport.id"
      :lincoln-report-id="dealerProfileReport.lincolnId"
      :french-report-id="dealerProfileReport.frenchId"
      :french-lincoln-report-id="dealerProfileReport.frenchLincolnId"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import PowerbiReport from '@/components/PowerbiReport.vue';

export default {
  name: 'DealerProfileView',
  components: {
    PageHeader,
    PowerbiReport,
  },
  computed: {
    dealerProfileReport() {
      return this.$store.getters['powerbi/dealerProfileReport'];
    },
  },
};
</script>
