/*eslint-disable */

export const UPDATE_ACCESS_TOKEN_INTERVAL = 30000;

export const Report = {
  ASDD: {
    name: 'Ford_BLUE_ASDD_Report',
    frenchName: 'Ford_BLUE_ASDD_Report_French',
    lincolnName: 'Lincoln_ASDD_Report',
    frenchLincolnName: 'Lincoln_ASDD_Report_French',
  },
  DEALER_PROFILE: {
    name: 'Ford_BLUE_Dealer_Profile',
    frenchName: 'Ford_BLUE_Dealer_Profile_French',
    lincolnName: 'Lincoln_Dealer_Profile',
    frenchLincolnName: 'Lincoln_Dealer_Profile_French',
  },
};
