<template>
  <div>
    <ModelEPageHeader :title="$l('modelEReports.title')" />
    <AppAccordion
      id="business-reports"
      color="primary-light"
      :title="$l('modelEReports.business.title')"
      :description="$l('modelEReports.business.description')"
    />
    <AppAccordion
      id="dealer-visits-reports"
      color="primary-light"
      :title="$l('modelEReports.dealerVisits.title')"
      :description="$l('modelEReports.dealerVisits.description')"
    />
    <AppAccordion
      id="dealer-status-reports"
      color="primary-light"
      :title="$l('modelEReports.dealerStatus.title')"
      :description="$l('modelEReports.dealerStatus.description')"
      :is-open="activeAccordionId === 3"
      @click="openAccordion(3)"
    >
      <ModelEReportsDealerStatus />
    </AppAccordion>
    <!-- <AppAccordion
      id="dealer-status-reports"
      color="primary-light"
      :title="$l('modelEReports.dealerStatus.title')"
      :description="$l('modelEReports.dealerStatus.description')"
    /> -->
    <AppAccordion
      id="dealer-readiness-reports"
      color="primary-light"
      :title="$l('modelEReports.dealerReadiness.title')"
      :description="$l('modelEReports.dealerReadiness.description')"
    />
    <AppAccordion
      id="customer-handling-tools"
      color="primary-light"
      :title="$l('modelEReports.customerHandlingTools.title')"
      :description="$l('modelEReports.customerHandlingTools.description')"
    />
    <AppAccordion
      id="activation-tools"
      color="primary-light"
      :title="$l('modelEReports.activationTools.title')"
      :description="$l('modelEReports.activationTools.description')"
    />
  </div>
</template>

<script>
import AppAccordion from '@/components/common/AppAccordion.vue';
import ModelEPageHeader from '@/components/ModelEPageHeader.vue';
import ModelEReportsDealerStatus from '@/components/ModelEReportsDealerStatus.vue';

export default {
  name: 'ModelEReportsView',
  components: {
    AppAccordion,
    ModelEPageHeader,
    ModelEReportsDealerStatus,
  },
  data() {
    return {
      activeAccordionId: null,
    };
  },
  methods: {
    openAccordion(accordionId) {
      if (this.activeAccordionId === accordionId) {
        this.activeAccordionId = null;
      } else {
        this.activeAccordionId = accordionId;
      }
    },
  },
};
</script>
