<template>
  <div class="remote-delivery container--dashboard-accordion">
    <p class="remote-delivery__description">
      {{ $l('modelEDashboard.remoteDelivery.description') }}
    </p>
    <div class="remote-delivery__form">
      <AppToggle
        v-model="remoteDelivery"
        id="remote-delivery-toggle"
        class="remote-delivery__toggle"
        :active-text="$l('general.yes')"
        :inactive-text="$l('general.no')"
      />
      <p>{{ $l('modelEDashboard.remoteDelivery.label') }}</p>
    </div>
    <StandardButton
      v-if="userIsKeyDecisionMaker"
      class="remote-delivery__submit-button"
      size="sm"
      track-event-name="submitForm_remoteDelivery"
      track-section-name="remoteDelivery"
      :text="$l('general.save')"
      :is-loading="isLoading"
      @click="submit"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import StandardButton from './common/StandardButton.vue';
import AppToggle from './common/AppToggle.vue';

export default {
  name: 'ModelEDashboardRemoteDelivery',
  components: {
    StandardButton,
    AppToggle,
  },
  data() {
    return {
      isLoading: false,
      remoteDelivery: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'activeDealership',
      'userIsKeyDecisionMaker',
    ]),
  },
  watch: {
    activeDealership() {
      this.remoteDelivery = this.activeDealership.remoteDelivery;
    },
  },
  mounted() {
    this.remoteDelivery = this.activeDealership.remoteDelivery;
  },
  methods: {
    async submit() {
      this.isLoading = true;

      try {
        const { success } = await apiManager.dealer.saveModelEDeliveryPreference(
          this.activeDealership.dealerId,
          this.remoteDelivery,
        );

        if (!success) {
          throw new Error();
        }

        await this.$store.dispatch('topBanner/open', {
          message: this.$l('modelEDashboard.remoteDelivery.successMessage'),
        });

        this.$store.dispatch('user/loadUser');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('modelEDashboard.remoteDelivery.errorMessage'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.remote-delivery {
  &__description {
    margin-bottom: 48px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include respond(sm-up) {
      flex-direction: row;
      align-items: initial;
    }
  }
  &__toggle {
    @include respond(sm-up) {
      margin-right: 24px;
    }
  }
  &__submit-button {
    margin-top: 24px;
  }
}
</style>
