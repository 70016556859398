<template>
  <span
    class="status-icon"
    :data-icon="icon"
    :data-label="showLabel"
  >
    <slot>
      <template v-if="showLabel">
        {{ label }}
      </template>
    </slot>
  </span>
</template>

<script>
const status = {
  GREEN: 'green',
  GRAY: 'gray',
  GREY: 'grey',
  YELLOW: 'yellow',
  NOT_STARTED: 'notstarted',
  COMPLETED: 'completed',
  ACTIVE: 'active',
  IN_PROGRESS: 'inprogress',
  COMING_SOON: 'comingsoon',
  YES: 'yes',
  NO: 'no',
  NO_DOT: 'nodot',
};

export default {
  name: 'StatusIcon',
  props: {
    status: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    normalizedStatus() {
      return this.status.toLowerCase().replaceAll(/[^a-z]/g, '');
    },
    icon() {
      switch (this.normalizedStatus) {
        case status.GREEN:
        case status.ACTIVE:
        case status.YES:
          return 'complete';
        case status.IN_PROGRESS:
        case status.YELLOW:
          return 'progress';
        case status.COMING_SOON:
        case status.NO:
        case status.NO_DOT:
          return 'not-available';
        case status.NOT_STARTED:
        case status.GRAY:
        case status.GREY:
        default:
          return 'not-started';
      }
    },
    label() {
      switch (this.icon) {
        case 'complete':
          return 'Completed';
        case 'progress':
          return 'In Progress';
        case 'not-availablet':
          return 'Not Available';
        default:
          return 'Not Started';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status-icon {
  display: flex;
  align-items: center;
  &::after {
    font-family: $font-family--icons;
    font-size: 18px;
  }
  &[data-label="true"] {
    gap: 8px;
  }
  &[data-icon="complete"]::after {
    content: $app-icon-Step-Complete;
    color: $color--fds-success1;
  }
  &[data-icon="progress"]::after {
    content: $app-icon-in-progress;
    color: $color--fds-caution1;
  }
  &[data-icon="not-started"]::after {
    content: $app-icon-not-started;
    color: $color--fds-gray2;
  }
  &[data-icon="not-available"]::after {
    content: $app-icon-Not-Available;
    color: $color--fds-gray2;
  }
}
</style>
