<template>
  <div class="file-input">
    <span class="file-input__label">
      File Name
    </span>
    <label
      class="file-input__input-wrapper"
      :for="id"
    >
      <input
        :id="id"
        :key="displayedFileName"
        class="file-input__input"
        type="file"
        accept="image/*"
        @change="handleChange"
      />
      <span class="file-input__file-name">
        {{ displayedFileName }}
      </span>
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue', 'select-file']);
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    default: '',
  },
});

const displayedFileName = computed(() => props.modelValue || 'No file selected');

function handleChange(event) {
  const { files } = event.target;

  if (files && files.length) {
    emit('update:modelValue', files[0].name);
    emit('select-file', files[0]);
  }
}
</script>

<style lang="scss" scoped>
.file-input {
  position: relative;
  &__label {
    font-size: 11px;
    position: absolute;
    top: 0;
    transform: translateY(-28px);
  }
  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 40px;
    background-color: $color--white;
    border: 1px solid $color--fds-gray2;
    border-radius: 4px;
    cursor: pointer;
  }
  &__input {
    width: 0;
    height: 0;
    opacity: 0;
  }
  &__file-name {
    padding: 8px 12px;
    word-break: break-all;
  }
}
</style>
