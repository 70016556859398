<template>
  <div
    class="circle-plus closed"
    :class="{opened: open}"
  >
    <div
      v-if="type == 'ford'"
      class="chevron"
    />
    <div
      v-else
      class="circle d-flex align-center justify-center"
      :class="{'txt-white': isWhite, borderless: type == 'lincoln'}"
    >
      <div
        class="horizontal"
        :class="{'bg-white': isWhite, 'bg-lincoln-orange': type == 'lincoln'}"
      />
      <div
        class="vertical"
        :class="{'bg-white': isWhite, 'bg-lincoln-orange': type == 'lincoln'}"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppChevron',
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.circle-plus {
  &:hover {
    cursor: pointer;
  }

  &.closed {
    .vertical,
    .horizontal {
      transition: all 0.5s ease-in-out;
    }

    .horizontal {
      opacity: 1;
    }
  }

  &.opened {
    opacity: 1;

    .vertical,
    .horizontal {
      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
    }

    .horizontal {
      opacity: 0;
    }

    .chevron {
      transform: rotate(180deg);
    }
  }

  .circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: $color--fds-primary;
    box-shadow: 0px 0px 0px 1.75px;
    display: flex;
    justify-content: center;

    &.txt-white {
      color: $color--white;
      box-shadow: 0px 0px 0px 1.6px;
    }

    &.borderless {
      box-shadow: none;
    }

    .horizontal,
    .vertical {
      position: absolute;
      background-color: $color--fds-primary;
      border-radius: 1px;

      &.bg-white {
        background-color: $color--white;
      }

      &.bg-lincoln-orange {
        background-color: $color--lds-secondary;
      }
    }

    .horizontal {
      width: 10px;
      height: 2px;
    }

    .vertical {
      width: 2px;
      height: 10px;
    }
  }

  .chevron {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;

    &::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      left: 4px;
      top: 2px
    }

    transition: all 0.5s ease;
  }
}

.ford .circle {
  color: $color--fds-primary;

  .horizontal,
  .vertical {
    background-color: $color--fds-primary;
  }
}

.lincoln .circle {
  color: $color--lds-primary;

  .horizontal,
  .vertical {
    background-color: $color--lds-primary;
  }
}
.fordPay .circle {
  color: #4d4d4d;
  .horizontal,
  .vertical {
    background-color: #4d4d4d;
  }
}
</style>
