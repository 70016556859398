<template>
  <div>
    <PageHeader :title="$l('reports.asdd.title')" />
    <PowerbiReport
      :report-id="asddReport.id"
      :lincoln-report-id="asddReport.lincolnId"
      :french-report-id="asddReport.frenchId"
      :french-lincoln-report-id="asddReport.frenchLincolnId"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import PowerbiReport from '@/components/PowerbiReport.vue';

export default {
  name: 'ReportASDDView',
  components: {
    PageHeader,
    PowerbiReport,
  },
  computed: {
    asddReport() {
      return this.$store.getters['powerbi/asddReport'];
    },
  },
};
</script>
