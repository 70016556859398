const DEFAULT_LOCALES = {
  english: 'en-ca',
  french: 'fr-ca',
};

export default {
  namespaced: true,
  state() {
    return {
      locales: DEFAULT_LOCALES,
      activeLocale: DEFAULT_LOCALES.english,
    };
  },
  getters: {
    locales: (state) => state.locales,
    activeLocale: (state) => state.activeLocale,
    activeLocaleIsEnglish: (_, getters) => getters.locales.english === getters.activeLocale,
    activeLocaleIsFrench: (_, getters) => getters.locales.french === getters.activeLocale,
    dateFormatPattern: (_, getters) => (getters.activeLocaleIsFrench ? 'dd/MM/yyyy' : 'MM/dd/yyyy'),
  },
  mutations: {
    setActiveLocale: (state, locale) => {
      // Checks if locale is valid
      if (!Object.values(state.locales).includes(locale)) return;

      state.activeLocale = locale;
    },
  },
  actions: {
    change({ commit }, locale) {
      commit('setActiveLocale', locale);
    },
  },
};
