<template>
  <div
    class="readiness-check"
    :class="$store.getters['user/activeBrandName']"
  >
    <!-- Meeting Date and Manager Information -->
    <section class="readiness-check__section">
      <h3 class="readiness-check__section-title fds-subtitle__2">
        {{ $l('readinessCheck.meeting.sectionOne.title') }}
      </h3>
      <div class="readiness-check__section-content">
        <CalendarPicker
          v-model="form.reviewedDate"
          id="readiness-meeting--date"
          :label="$l('readinessCheck.meeting.sectionOne.labelOne')"
        />
        <AppDropdown
          v-model="form.roleId"
          id="readiness-meeting--role"
          :label="$l('readinessCheck.meeting.sectionOne.labelTwo')"
          :options="roleOptions"
        />
        <TextField
          v-model.trim="form.cdsId"
          id="readiness-meeting--csd-id"
          :label="$l('readinessCheck.meeting.sectionOne.labelThree')"
        />
      </div>
    </section>
    <!-- Confirm Completion of Readiness Plan -->
    <section class="readiness-check__section">
      <h3 class="readiness-check__section-title fds-subtitle__2">
        {{ $l('readinessCheck.meeting.sectionTwo.title') }}
      </h3>
      <p v-html="$l('readinessCheck.meeting.sectionTwo.p1')" />
      <!-- Manager's Meeting -->
      <div class="readiness-check__card">
        <h4 class="readiness-check__card-title fds-subtitle__2">
          {{ $l("readinessCheck.meeting.sectionTwo.subtitle1") }}
        </h4>
        <p>{{ $l("readinessCheck.meeting.sectionTwo.p2") }}</p>
        <AppDropdown
          v-model="form.managerMeetingId"
          id="readiness-meeting--manager-meeting"
          class="readiness-check__card-field"
          label="Has been completed"
          :options="managerMeetingOptions"
        />
        <p>{{ $l("readinessCheck.meeting.sectionTwo.p3") }}</p>
        <div class="readiness-check__card-checkbox-wrapper">
          <AppCheckbox
            v-for="option in form.managerPresenceOptions"
            v-model="option.selected"
            :id="`readiness-checkbox-manager-presence--${option.itemId}`"
            :key="option.itemId"
            class="readiness-check__checkbox"
            :label="option.itemText"
          />
        </div>
      </div>
      <!-- Who Did You Meet? -->
      <!-- <section>
        <p>
          {{ $l("readinessCheck.meeting.sectionTwo.subtitle2") }}
          <small>{{ $l("readinessCheck.meeting.sectionTwo.small1") }}</small>
        </p>
        <div class="row">
          <div class="col-lg-6">
            <AppDropdown searchable
              class="mb-2 form-color"
              :placeholder="$l('readinessCheck.meeting.sectionTwo.label1')"
              :options="model.attendeeList"
              @model="contactSelection"
            />
          </div>
          <div class="col-lg-6">
            <div class="box-of-names">
              <template v-for="(contact, idx) in model.attendeeList">
                <span :key="idx" v-if="contact.selected"
                  >{{ contact.text }},&nbsp;</span
                >
              </template>
            </div>
          </div>
        </div>
      </section> -->
      <!-- Was There Any one Else in Attendance? -->
      <!-- <section>
        <p>
          {{ $l("readinessCheck.meeting.sectionTwo.subtitle3") }}
          <small>{{ $l("readinessCheck.meeting.sectionTwo.small2") }}</small>
        </p>
        <div class="row">
          <div class="col-lg-6">
            <TextField
              id="txt-first-name"
              v-model="firstName"
              :label="$l('readinessCheck.meeting.sectionTwo.label2')"
            />
            <TextField
              id="txt-last-name"
              v-model="lastName"
              :label="$l('readinessCheck.meeting.sectionTwo.label3')"
              class="lastName"
            />
          </div>
          <div class="col-lg-6">
            <Select
              id="selectCustomContacts"
              :label="$l('readinessCheck.meeting.sectionTwo.label4')"
              :options="customContacts"
              :required="false"
              @model="selectAttendee"
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-xs-6 col-lg-3 d-flex justify-space-around">
            <StandardButton
              size="lg"
              :text="$l('readinessCheck.meeting.sectionTwo.btn1')"
              @click="saveContact"
            />
          </div>
          <div class="col-xs-6 col-lg-3 d-flex justify-space-around">
            <StandardButton
              size="lg"
              :text="$l('readinessCheck.meeting.sectionTwo.btn2')"
              @click="removeContact"
            />
          </div>
        </div>
      </section> -->
      <!-- Departments Team Meetings -->
      <div class="readiness-check__card">
        <h4 class="readiness-check__card-title fds-subtitle__2">
          {{ $l("readinessCheck.meeting.sectionThree.title") }}
        </h4>
        <p>{{ $l("readinessCheck.meeting.sectionThree.p1") }}</p>
        <AppDropdown
          v-model="form.departmentMeetingId"
          id="readiness-meeting--department-meeting"
          class="readiness-check__card-field"
          label="Has been completed"
          :options="departmentMeetingOptions"
        />
        <p>{{ $l("readinessCheck.meeting.sectionThree.p2") }}</p>
        <div class="readiness-check__card-checkbox-wrapper">
          <AppCheckbox
            v-for="option in form.teamMeetingOptions"
            v-model="option.selected"
            :id="`readiness-checkbox-team-meeting--${option.itemId}`"
            :key="option.itemId"
            class="readiness-check__checkbox"
            :label="option.itemText"
          />
        </div>
      </div>
      <!-- Strategy Implementation -->
      <div class="readiness-check__card">
        <h4 class="readiness-check__card-title fds-subtitle__2">
          {{ $l("readinessCheck.meeting.sectionFour.title") }}
        </h4>
        <p>{{ $l("readinessCheck.meeting.sectionFour.p1") }}</p>
        <AppDropdown
          v-model="form.strategyImplementationId"
          id="readiness-meeting--strategy-implementation"
          class="readiness-check__card-field"
          label="Strategies implemented"
          :options="strategyImplementationOptions"
        />
      </div>
    </section>
    <!-- Follow Up Requests -->
    <section class="readiness-check__section">
      <h3 class="readiness-check__section-title fds-subtitle__2">
        {{ $l("readinessCheck.meeting.sectionFive.title") }}
      </h3>
      <p>{{ $l("readinessCheck.meeting.sectionFive.p1") }}</p>
      <div class="readiness-check__follow-up-content">
        <AppCheckbox
          v-for="option in form.followUpOptions"
          v-model="option.selected"
          :id="`readiness-checkbox-follow-up--${option.itemId}`"
          :key="option.itemId"
          class="readiness-check__checkbox"
        >
          <span>
            {{ option.itemText }}
            <br />
            <small>{{ option.itemInstruction }}</small>
          </span>
        </AppCheckbox>
      </div>
    </section>
    <!-- Notes & Misc -->
    <section class="readiness-check__section">
      <TextareaField
        v-model.trim="form.notes"
        id="readiness-check--notes"
        class="readiness-check__textarea"
        :label="$l('readinessCheck.meeting.sectionSix.title')"
      />
      <AppCheckbox
        v-model="form.reviewedPlan"
        id="readiness-meeting--reviewed-plan"
        class="readiness-check__checkbox"
        :label="$l('readinessCheck.meeting.sectionSix.label1', { name: dealer.name })"
      />
    </section>
    <section class="readiness-check__section">
      <AppToast
        v-if="errorToastMessage"
        class="feedback-form__error-toast"
        type="error"
        :text="errorToastMessage"
      />
      <AppToast
        v-if="successToastMessage"
        class="feedback-form__error-toast"
        type="success"
        :text="successToastMessage"
      />
      <div class="readiness-check__submit-buttons">
        <StandardButton
          :text="$l('readinessCheck.meeting.sectionSix.btn1')"
          :is-loading="isSavingFieldMeeting"
          @click="saveFieldMeeting"
        />
        <StandardButton
          size="lg"
          :text="$l('readinessCheck.meeting.sectionSix.btn2')"
          :is-loading="isSavingMakedAsFinal"
          @click="markAsFinal"
        />
      </div>
      <small>{{ $l("readinessCheck.meeting.sectionSix.label2") }}</small>
    </section>
    <section>
      <h3 class="readiness-check__section-title fds-subtitle__2">
        {{ $l("readinessCheck.meeting.sectionSeven.title") }}
      </h3>
      <div
        v-for="meeting in meetings"
        :key="meeting.meetingID"
        class="readiness-check__previous-meeting"
      >
        {{ formatTitle(meeting.meetingDate) }}
        <ul>
          <li>
            {{ $l("readinessCheck.meeting.sectionSeven.label1") }}
            <b>{{ meeting.completedByName }}</b>
          </li>
          <li>
            {{ $l("readinessCheck.meeting.sectionSeven.label2") }}
            <b>{{ meeting.completedByRole }}</b>
          </li>
          <li>
            {{ $l("readinessCheck.meeting.sectionSeven.label3") }}
            <b>{{ formatDateOfMeeting(meeting.meetingDate) }}</b>
          </li>
          <li>
            {{ $l("readinessCheck.meeting.sectionSeven.label4") }}
            <b>{{ meeting.attendees }}</b>
          </li>
          <li>
            {{ $l("readinessCheck.meeting.sectionSeven.label5") }}
            <b>{{ meeting.followupGroup }}</b>
          </li>
          <li>
            {{ $l("readinessCheck.meeting.sectionSeven.label6") }}
            <b>{{ meeting.note }}</b>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import apiManager from '@/api';
import AppDropdown from './common/AppDropdown.vue';
import TextField from './common/TextField.vue';
import TextareaField from './common/TextareaField.vue';
import AppCheckbox from './common/AppCheckbox.vue';
import StandardButton from './common/StandardButton.vue';
import CalendarPicker from './common/CalendarPicker.vue';
import AppToast from './common/AppToast.vue';

export default {
  name: 'ReadinessPlanModalReadinessCheckFormMeeting',
  components: {
    AppDropdown,
    TextField,
    TextareaField,
    AppCheckbox,
    StandardButton,
    CalendarPicker,
    AppToast,
  },
  props: {
    dealer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSavingFieldMeeting: false,
      isSavingMakedAsFinal: false,
      errorToastMessage: '',
      successToastMessage: '',
      roles: [],
      managerMeetings: [],
      departmentMeetings: [],
      strategyImplementations: [],
      attendees: [],
      form: {
        meetingId: '',
        meetingCampaignId: '',
        reviewedDate: '',
        roleId: '',
        cdsId: '',
        managerPresenceOptions: [],
        teamMeetingOptions: [],
        followUpOptions: [],
        managerMeetingId: '',
        departmentMeetingId: '',
        strategyImplementationId: '',
        notes: '',
        reviewedPlan: false,
      },
      meetings: [],
    };
  },
  computed: {
    ...mapGetters({
      dateFormatPattern: 'locale/dateFormatPattern',
    }),
    roleOptions() {
      return this.roles.map((role) => ({
        text: role.itemText,
        value: role.itemId,
      }));
    },
    managerMeetingOptions() {
      return this.managerMeetings.map((meeting) => ({
        text: meeting.itemText,
        value: meeting.itemId,
      }));
    },
    departmentMeetingOptions() {
      return this.departmentMeetings.map((meeting) => ({
        text: meeting.itemText,
        value: meeting.itemId,
      }));
    },
    strategyImplementationOptions() {
      return this.strategyImplementations.map((strategy) => ({
        text: strategy.itemText,
        value: strategy.itemId,
      }));
    },
  },
  async mounted() {
    this.getMeetings();
    this.getFormData();
  },
  methods: {
    formatDateOfMeeting(date) {
      return format(new Date(date), this.dateFormatPattern);
    },
    formatTitle(date) {
      return format(new Date(date), `${this.dateFormatPattern} hh:mm:ss a`);
    },
    clearToastMessages() {
      this.successToastMessage = '';
      this.errorToastMessage = '';
    },
    async getMeetings() {
      this.meetings = await apiManager.dealerAccountability
        .getMeetings(this.dealer.dealerId);
    },
    async getFormData() {
      const data = await apiManager.dealerAccountability
        .getFieldMeeting(this.dealer.dealerId);

      this.roles = data.roles;
      this.managerMeetings = data.managerMeetingCompletedOptions;
      this.departmentMeetings = data.departmentMeetingCompletedOptions;
      this.strategyImplementations = data.strategyImplementationOptions;
      this.attendees = data.attendeeList;

      this.form.meetingId = data.meetingID;
      this.form.meetingCampaignId = data.meetingCampaignID;
      this.form.cdsId = data.completedBy_UserID;
      this.form.reviewedDate = data.meeting_Date;
      this.form.notes = data.note;
      this.form.managerPresenceOptions = data.departmentManagerPresenceOptions;
      this.form.teamMeetingOptions = data.teamMeetingHostingDepartments;
      this.form.followUpOptions = data.meetingFollowUpGroupOptions;

      this.form.roleId = data.roles.find(({ selected }) => selected)?.itemId;
      this.form.managerMeetingId = data.managerMeetingCompletedOptions
        .find(({ selected }) => selected)?.itemId;
      this.form.departmentMeetingId = data.departmentMeetingCompletedOptions
        .find(({ selected }) => selected)?.itemId;
      this.form.strategyImplementationId = data.strategyImplementationOptions
        .find(({ selected }) => selected)?.itemId;
    },
    async saveFieldMeeting() {
      this.clearToastMessages();

      this.isSavingFieldMeeting = true;

      try {
        await this.saveFormData();

        this.successToastMessage = this.$l('readinessCheck.meeting.messages.formSaveSuccess');
      } catch (error) {
        this.errorToastMessage = error.message;
      } finally {
        this.isSavingFieldMeeting = false;
      }
    },
    async markAsFinal() {
      this.clearToastMessages();

      const SECTION_COMPLETED_ID = 1;
      const errors = [];

      if (!this.form.roleId) {
        errors.push(this.$l('readinessCheck.meeting.messages.selectRole'));
      }
      if (!this.form.cdsId) {
        errors.push(this.$l('readinessCheck.meeting.messages.cdsRequired'));
      } else if (this.form.cdsId !== this.$store.getters['user/userProfile'].userId) {
        errors.push(this.$l('readinessCheck.meeting.messages.cdsNotMatch'));
      }
      if (this.form.managerPresenceOptions.filter(({ selected }) => !selected).length > 0) {
        errors.push('All department managers must be present in Manager\'s Meeting');
      }
      if (this.form.teamMeetingOptions.filter(({ selected }) => !selected).length > 0) {
        errors.push('All Department Team Meetings must be hosted');
      }
      if (this.form.managerMeetingId !== SECTION_COMPLETED_ID) {
        errors.push('Manager\'s Meeting must be Yes');
      }
      if (this.form.departmentMeetingId !== SECTION_COMPLETED_ID) {
        errors.push('Department Team Meeting must be Yes');
      }
      if (this.form.strategyImplementationId !== SECTION_COMPLETED_ID) {
        errors.push('Strategy Implementation must be Yes');
      }
      if (!this.form.notes) {
        errors.push('Notes is required');
      }
      if (!this.form.reviewedPlan) {
        errors.push(this.$l('readinessCheck.meeting.messages.hadReviewedPlan'));
      }

      if (errors.length) {
        this.errorToastMessage = errors.join('. ');
        return;
      }

      this.isSavingMakedAsFinal = true;

      try {
        await this.saveFormData(true);

        this.successToastMessage = this.$l('readinessCheck.meeting.messages.formMarkedFinal');

        this.getMeetings();
        this.getFormData();
      } catch (error) {
        this.errorToastMessage = error.message;
      } finally {
        this.isSavingMakedAsFinal = false;
      }
    },
    async saveFormData(markedFinal = false) {
      const { data } = await apiManager.dealerAccountability.saveFieldMeeting({
        meetingID: this.form.meetingId,
        dealerID: this.dealer.dealerId,
        completedBy_UserID: this.form.cdsId,
        meeting_Date: this.form.reviewedDate,
        meetingCampaignID: this.form.meetingCampaignId,
        departmentManagerPresenceOptions: this.form.managerPresenceOptions,
        attendeeList: this.attendees,
        teamMeetingHostingDepartments: this.form.teamMeetingOptions,
        meetingFollowUpGroupOptions: this.form.followUpOptions,
        note: this.form.notes,
        markedFinal,
        reviewedPlanWithDealer: this.form.reviewedPlan,
        roles: this.roles.map((role) => ({
          ...role,
          selected: role.itemId === this.form.roleId,
        })),
        managerMeetingCompletedOptions: this.managerMeetings.map((meeting) => ({
          ...meeting,
          selected: meeting.itemId === this.form.managerMeetingId,
        })),
        departmentMeetingCompletedOptions: this.departmentMeetings.map((meeting) => ({
          ...meeting,
          selected: meeting.itemId === this.form.departmentMeetingId,
        })),
        strategyImplementationOptions: this.strategyImplementations.map((item) => ({
          ...item,
          selected: item.itemId === this.form.strategyImplementationId,
        })),
      });

      this.form.meetingId = data.meetingID;
    },
  },
};
</script>

<style lang="scss" scoped>
.readiness-check {
  --title-color: #{$color--fds-primary};
  &.lincoln {
    --title-color: #{$color--lds-primary};
  }
  &__section {
    margin-bottom: 32px;
  }
  &__section-title {
    margin-top: 0;
    margin-bottom: 16px;
    color: var(--title-color);
    font-weight: $font-weight--medium;
  }
  &__section-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px 24px;
    margin-top: 32px;
    @include respond(md-up) {
      grid-template-columns: 35% 1fr;
    }
  }
  &__checkbox {
    --text-color: #{$color--fds-gray3};
  }
  &__card {
    margin-bottom: 24px;
    padding: 12px 16px;
    background-color: $color--white;
    border: 2px lightgray solid;
  }
  &__card-title {
    margin: 0;
    margin-bottom: 16px;
    color: var(--title-color);
    text-decoration: underline;
    font-weight: $font-weight--medium;
  }
  &__card-field {
    width: 50%;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  &__card-checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
  &__follow-up-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  &__textarea {
    margin-bottom: 32px;
  }
  &__submit-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin: 24px 0;
  }
  &__previous-meeting {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    ul {
      margin-bottom: 0;
      list-style: none;
    }
  }
}
</style>
