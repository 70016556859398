<template>
  <div class="empty-state">
    <p class="empty-state__text">
      No results found
    </p>
    <router-link :to="{ name: 'Help' }">
      <span class="app-icon-help-chat" />
      Need help?
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'FAQsEmptyState',
};
</script>

<style lang="scss" scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__text {
    font-size: 45px;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 1;
    text-align: center;
  }
}
.ford {
  .empty-state {
    color: $color--fds-primary;
    a {
      .app-icon-help-chat {
        color: $color--fds-primary;
      }
    }
    a {
      font-size: 16px;
      color: $color--fds-primary;
      &:hover {
        color: $color--fds-secondary;
        .app-icon-help-chat {
          color: $color--fds-secondary;
        }
      }
    }
  }
}
.lincoln {
  .empty-state {
    color: $color--lds-primary;
    a {
      .app-icon-help-chat {
        color: $color--lds-primary;
      }
    }
    a {
      font-size: 16px;
      color: $color--lds-primary;
      &:hover {
        color: $color--lds-secondary;
        .app-icon-help-chat {
          color: $color--lds-secondary;
        }
      }
    }
  }
}
</style>
