export default {
  meeting: {
    sectionFour: {
      p1: 'eCommerce CRM response strategies with Client response teams/BDC have been implemented',
    },
  },
  feedback: {
    checkboxTwo: {
      pieceTwo: '(by Store for Store teams)',
    },
    labelOne: 'Do you feel the provided resources have adequately prepared your Store to support eCommerce Clients?',
  },
};
