<template>
  <button
    v-if="!to"
    @click="$emit('click')"
  >
    <slot />
  </button>
  <a
    v-else-if="renderExternalLink"
    target="_blank"
    rel="noopener"
    :href="to"
    @click="$emit('click')"
  >
    <slot />
  </a>
  <router-link
    v-else
    :to="to"
    @click="$emit('click')"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'BaseButton',
  emits: ['click'],
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    renderExternalLink() {
      return typeof this.to === 'string' && (
        this.to.startsWith('http') ||
        this.to.startsWith('tel') ||
        this.to.startsWith('mailto')
      );
    },
  },
};
</script>
