export default {
  title: 'Help',
  heading: 'Highly Trained Experts are Ready to Assist',
  callHeadquarters: 'Call Ford eCommerce Program Headquarters:',
  email: 'Email:',
  submitSuccess: 'Form submitted successfully.',
  submitError: 'Unable to submit form',
  form: {
    inputPhone: 'Primary phone',
    selectFeedback: 'Feedback',
    selectTopic: 'Feedback topic',
    inputComment: 'Please provide feedback details',
    submitButton: 'Submit',
  },
  resources: {
    heading1: 'Additional Support',
    text1: 'Stripe (Payment Processor) for Account Setup Support',
    text2: 'To contact Stripe: ',
    text3: 'For general questions: ',
    text4: 'Ford Technology Solutions (FTS) for VL Plus Support',
    text5: 'Automotive Remarketing Services (ARS) for Trade-In Enrolment Support',
    text6: 'Customer Relations Centre (CRC)',
    text7: 'Ford: ',
    text8: 'Lincoln: ',
  },
  about: {
    appVersion: 'App Version: {version}',
    apiVersion: 'API Version: {version}',
    modal: {
      title: 'About eCommerce Experiences',
      environment: 'Environment',
      app: 'App',
      api: 'API',
    },
  },
};
