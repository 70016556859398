<template>
  <div
    class="textarea"
    :class="$attrs.class"
  >
    <TextField
      v-bind="$attrs"
      v-model="textareaValue"
      type="textarea"
      :maxlength="maxlength > 0 ? maxlength : null"
    />
    <p
      v-if="maxlength > 0"
      class="textarea__characters-remaining"
    >
      {{ $l('textArea.charText') }} {{ charactersRemaining }}
    </p>
  </div>
</template>

<script>
import TextField from './TextField.vue';

export default {
  name: 'TextareaField',
  components: {
    TextField,
  },
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    maxlength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    textareaValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    charactersRemaining() {
      return this.maxlength - (this.textareaValue.length || 0);
    },
  },
};
</script>
