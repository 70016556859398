export default {
  meeting: {
    sectionOne: {
      title: 'Meeting date and manager information',
      labelOne: 'Date of Meeting',
      labelTwo: 'Your Role',
      labelThree: 'Your CDS ID',
    },
    sectionTwo: {
      title: 'Confirm completion of readiness plan',
      p1: 'The below activites may not occur in the same meeting. Please be sure to return to this <b>eCommerce Readiness Visit Report</b> to make updates before marking as final.',
      subtitle1: 'Manager\'s meeting',
      p2: 'eCommerce Manager\'s Meeting has been completed',
      p3: 'Confirm department manager presence',
      subtitle2: 'Who did you meet?',
      small1: '(must select at least one)',
      label1: 'Name',
      subtitle3: 'Was there any on else in attendance?',
      small2: '(not listed in the Name dropdown above)',
      label2: 'First Name',
      label3: 'Last Name',
      label4: 'Attendance List (Select to edit or delete)',
      btn1: 'Save Contact',
      btn2: 'Delete Contact',
    },
    sectionThree: {
      title: 'Department team meeting',
      p1: 'eCommerce Department Team Meetings has been completed',
      p2: 'Confirm each department has hosted their Team Meeting',
    },
    sectionFour: {
      title: 'Strategy implementation',
      p1: 'eCommerce CRM response strategies with Customer response teams/BDC have been implemented',
    },
    sectionFive: {
      title: 'Follow up requests',
      p1: 'If follow-up assistance is needed, please select a requested group:',
    },
    sectionSix: {
      title: 'Notes',
      label1: 'I have reviewed the eCommerce Readiness Plan with {name}.',
      label2: '**If you mark this visit as final, you will not be able to make any changes',
      btn1: 'Save and return',
      btn2: 'Mark as final',
    },
    sectionSeven: {
      title: 'Previous meetings',
      label1: 'Completed by:',
      label2: 'Role:',
      label3: 'Date of meeting:',
      label4: 'Met with:',
      label5: 'Follow up request:',
      label6: 'Notes:',
    },
    messages: {
      selectOneAttendee: 'Must select at least one attendee',
      hadReviewedPlan: 'Please select that you had reviewed the plan',
      dateMeetRequired: 'Date of Meeting is required',
      selectRole: 'Must select a role',
      cdsRequired: 'CDS ID is required',
      cdsNotMatch: 'CDS ID is not correct',
      checkReviewedPlan: 'You must checked that you reviewed the plan',
      formSaveSuccess: 'Form saved successfully.',
      completeEntireForm: 'Please complete the entire form.',
      formMarkedFinal: 'The form has been marked final',
    },
  },
  feedback: {
    titleOne: 'Readiness Plan Check',
    checkboxOne: 'eCommerce Manager\'s Meeting has been completed',
    radioOne: '<b>With</b> Ford Field Representative',
    radioTwo: '<b>Without</b> Ford Field Representative',
    checkboxTwo: {
      pieceOne: 'eCommerce Department Team Meeting has been completed',
      pieceTwo: '(by Dealership for Dealership teams)',
    },
    wslxID: 'Your WSLX ID (FMCDealer Login)',
    checkboxThree: '{name} has completed the <b>eCommerce Readiness Plan</b> and has implemented <b>CRM response strategies</b> (with Customer response teams/BDC)',
    titleTwo: 'Readiness Plan Feedback',
    labelOne: 'Do you feel the provided resources have adequately prepared your dealership to support eCommerce Customers?',
    labelTwo: 'In which areas of eCommerce would you like to see more support?',
    buttonRequestSupport: 'Request Support',
    formSaveSuccess: 'Form saved successfully.',
  },
};
