export default {
  title: '[NT] Resource Center',
  experience: {
    title: '[NT] Select by Customer Experience',
    search: '[NT] Click here for keyword search',
  },
  menu: {
    link1: '[NT] Select by Customer Experience',
    link2: '[NT] Customer Journey',
    link3: '[NT] eCommerce Marketplace Portal (EMP) Demo',
    link4: '[NT] Operationalize eCommerce in Your Dealership',
    link5: '[NT] Select by Department',
  },
  resourceCenterEMP: {
    p1: '[NT] New to eCommerce? No problem! Click through for the Customer and Dealer ecomrnerce journey to learn more about the new Customer submissions that may be coming your way!',
    p2: '[NT] Every New Vehicle eCommerce submission w ill be unique, much like every Customer. This Journey provides a look at how the Customer and Dealer take turns on line, driving the deal fo rward, and t he new tools that will help your dealership deliver a seamless ecommerce Customer experience.',
  },
};
