export default {
  title: 'Ford eCommerce Experiences',
  buttonStart: 'Get Started',
  welcome: {
    paragraph1: 'eCommerce Dealer Enrolment is just a few clicks away!',
    paragraph2: 'Ford now offers Reservation, Order, and Cart and Checkout experiences with direct-to-Dealer payments. This portal hosts instruction, access, and training content to guide your dealership through activation. Program support is available at any time via the Help Page. Experience activation will take place as setup activities are completed for your dealership. Click ‘Resource Center’ above to access all checklists, setup guides, FAQs, and other program tools.',
  },
  terms: {
    description: 'Please accept the updated Terms and Conditions to access the eCommerce Dealer Portal.',
    errorMessage: 'Please accept the Terms & Conditions.',
    modalTitle: 'Terms & Conditions',
    modalAccept: {
      paragraphOne: 'You are attempting to proceed without accepting the updated Terms & Conditions required to provide updated functionality. Please click the "Go Back" button to return to the previous page to accept and continue.',
      paragraphTwo: 'Please note your dealership has until the following date to accept the updated Terms & Conditions:',
      paragraphThree: 'In the event the updated Terms & Conditions are not accepted by this date, your dealership will be deactivated from New Vehicle eCommerce.',
    },
    modalNotAllowed: {
      paragraphOne: 'You do not have permissions to accept Terms & Conditions. The following people have permissions to accept Terms & Conditions:',
      paragraphTwo: 'Click "Continue" to navigate to the eCommerce Dealer Portal. Please Note: Your dealership has until the following date to accept the updated Terms & Conditions.',
      paragraphThree: 'In the event the updated Terms & Conditions are not accepted by this date, your dealership will be deactivated from New Vehicle eCommerce.',
    },
  },
};
