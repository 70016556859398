import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

const getActiveResources = (rootGetters, subsectionId) => {
  const resources = ResourceCenter.findSectionResources(
    rootGetters['modelE/dashboard/resources'],
    resourceCenterId.section.MODEL_E_TABLETS,
    subsectionId,
  );

  return ResourceCenter.getActiveVideosAndDocuments({
    resources,
    brand: rootGetters['user/activeBrand'],
    locale: rootGetters['locale/activeLocale'],
  });
};

export default {
  namespaced: true,
  state() {
    return {
      selectedId: null,
    };
  },
  getters: {
    modelETabletsActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.MODEL_E_TABLETS);
    },
    modelETabletsURLActiveResources(state, getters, rootState, rootGetters) {
      return getActiveResources(rootGetters, resourceCenterId.subsection.MODEL_E_TABLETS_URL);
    },
  },
};
