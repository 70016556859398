<template>
  <button
    type="button"
    class="header__menu-button"
    @click="$emit('toggle-menu')"
  >
    <TransitionScaleCenter>
      <FontAwesomeIcon
        v-if="menuIsOpen"
        key="1"
        size="2x"
        :icon="iconCloseMenu"
      />
      <FontAwesomeIcon
        v-else
        key="2"
        size="2x"
        :icon="iconOpenMenu"
      />
    </TransitionScaleCenter>
  </button>
</template>

<script>
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import TransitionScaleCenter from './TransitionScaleCenter.vue';

export default {
  name: 'TheHeaderMenuButton',
  components: {
    TransitionScaleCenter,
  },
  emits: ['toggle-menu'],
  props: {
    menuIsOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iconOpenMenu: faBars,
      iconCloseMenu: faTimes,
    };
  },
};
</script>

<style lang="scss" scoped>
.header__menu-button {
  background: transparent;
  border: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: $breakpoint__the-header-desktop) {
    display: none;
  }
  :deep(svg) {
    fill: $color--fds-primary;
  }
}
</style>
