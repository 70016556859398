export default class StringFormat {
  static camelCase(name) {
    let returnStr = name;
    if (returnStr.split(' ').length === 1) {
      returnStr = name.toLowerCase();
    } else {
      returnStr = name.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');
    }
    return returnStr;
  }

  static capitalize(name) {
    const splitStr = name.toLowerCase().split(' ');
    for (let a = 0; a < splitStr.length; a += 1) {
      splitStr[a] = `${splitStr[a].charAt(0).toUpperCase()}${splitStr[a].substring(1)}`;
    }
    return splitStr.join(' ');
  }
}
