import * as validators from '@vuelidate/validators';
import i18n from '@/plugins/i18n';

export const withI18nMessage = validators.createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required, { withArguments: true });

export const email = withI18nMessage(validators.email, { withArguments: true });

export const minLength = withI18nMessage(validators.minLength, { withArguments: true });

export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });

const customMessage = (validator, path) => withI18nMessage(validator, {
  messagePath: () => path,
  withArguments: true,
});

export const customMinLength = (...args) => {
  const validator = customMessage(validators.minLength, args[0]);

  const params = [...args];

  params.shift();

  return validator(...params);
};
