<template>
  <img
    v-if="showModelELogo"
    class="logo logo--model-e"
    alt="Ford Model e logo"
    :src="require('@/assets/images/logos/modelELight.png')"
  />
  <img
    v-else-if="$store.getters['user/activeBrandIsLincoln']"
    class="logo"
    alt="Lincoln logo"
    :src="require('@/assets/images/logos/lincolnLogo.png')"
  />
  <img
    v-else
    class="logo"
    alt="Ford logo"
    :src="require('@/assets/images/logos/fordBlueOval200.png')"
  />
</template>

<script setup>
import { computed } from 'vue';
import { experiences } from '@/constants/experiences';

const props = defineProps({
  theme: {
    type: String,
    default: experiences.BLUE,
    validator: (value) => Object.values(experiences).includes(value),
  },
});

const showModelELogo = computed(() => props.theme === experiences.MODEL_E);
</script>

<style lang="scss" scoped>
.logo {
  width: auto;
  height: 38px;
  &--model-e {
    height: 52px;
  }
}
</style>
