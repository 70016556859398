<template>
  <header class="dashboard-header">
    <h1 class="dashboard-header__title | fds-headline__4">
      {{ $l('modelEDashboard.title') }}
    </h1>
    <ActivationSummaryControl
      class="dashboard-header__summary-control"
      :status="activationSummaryStatus.activation"
      :percentage-complete="percentageComplete"
      @deactivate-experience="showOptOutModal = true"
    />
    <p
      v-if="$store.getters['user/certificationLevel'].modelEElite"
      class="dashboard-header__elite"
    >
      {{ $l('modelEDashboard.elite.text1') }}
      <span class="dashboard-header__elite-label">
        {{ $l('modelEDashboard.elite.text2') }}
      </span>
    </p>
  </header>
  <ModelEOptOutModal
    :open="showOptOutModal"
    :is-loading="isDeactivatingExperience"
    @accept="deactivateExperience"
    @close="showOptOutModal = false"
  />
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { ElementType, Event } from '@/constants/track';
import { experienceId } from '@/constants/activationSummary';
import ActivationSummaryControl from './ActivationSummaryControl.vue';
import ModelEOptOutModal from './ModelEOptOutModal.vue';

const store = useStore();
const route = useRoute();

// Store getters
const activationSummaryStatus = computed(() => store.getters['modelE/activationSummary/status']);
const percentageComplete = computed(() => store.getters['modelE/activationSummary/percentageComplete']);

// Opt-out modal
const showOptOutModal = ref(false);
const isDeactivatingExperience = ref(false);

async function deactivateExperience() {
  isDeactivatingExperience.value = true;

  store.dispatch('track/event', {
    event: Event.DEACTIVATE_EXPERIENCE,
    section: 'cartCheckout',
    elementType: ElementType.BUTTON,
    pageName: route.meta.track.pageName,
  });

  try {
    await store.dispatch('modelE/activationSummary/deactivateExperience', {
      experienceId: experienceId.MODEL_E_CART_CHECKOUT,
    });
  } catch (error) {
    store.dispatch('topBanner/open', {
      type: 'error',
      message: 'Failed to initiate the deactivation process. Please try again.',
    });
  } finally {
    showOptOutModal.value = false;
    isDeactivatingExperience.value = false;
  }
}
</script>

<style lang="scss" scoped>
.dashboard-header {
  display: grid;
  place-items: center;
  gap: 24px;
  margin-bottom: 40px;
  &__title {
    margin: 0;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
  }
  &__summary-control {
    width: 100%;
    max-width: 425px;
  }
  &__elite {
    margin: 0;
    color: $color--fds-primary;
    font-weight: $font-weight--medium;
  }
}
</style>
