<template>
  <div
    class="app-tooltip"
    :class="activeBrandName"
  >
    <VDropdown
      placement="top"
      :theme="activeBrandIsLincoln ? 'lincoln-tooltip' : 'ford-tooltip'"
    >
      <slot>
        <span class="app-tooltip__icon app-icon-info" />
      </slot>
      <template #popper="{ hide }">
        <div
          class="app-tooltip__content"
          :class="activeBrandName"
        >
          <span
            v-if="title"
            v-text="title"
            class="app-tooltip__title"
          />
          <button
            class="app-tooltip__close-button"
            type="button"
            @click="hide"
          >
            <i class="app-tooltip__close-button-icon | app-icon-clear" />
          </button>
          <div class="app-tooltip__text-wrapper">
            <slot name="content">
              <p class="app-tooltip__text">
                {{ text }}
              </p>
            </slot>
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppTooltip',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      activeBrandIsLincoln: 'user/activeBrandIsLincoln',
      activeBrandName: 'user/activeBrandName',
    }),
  },
};
</script>

<style lang="scss" scoped>
.app-tooltip {
  --icon-color: #{$color--fds-secondary};
  &.lincoln {
    --icon-color: #{$color--lds-secondary};
  }
  display: inline-flex;
  &__icon {
    color: var(--icon-color, #{$color--fds-secondary}) !important;
    cursor: pointer;
  }
  &__content {
    max-width: 352px;
    padding-right: 12px;
    position: relative;
    &.lincoln {
      --button-font-size: 12px;
      --button-weight: #{$font-weight--normal};
      .app-tooltip__text-wrapper {
        font-weight: $font-weight--light;
      }
    }
  }
  &__title {
    display: block;
    margin-bottom: 8px;
  }
  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    font-size: var(--button-font-size, 14px);
    position: absolute;
    top: -6px;
    right: -8px;
    outline: none;
    &:focus-visible::after {
      content: '';
      outline: 1px solid transparent;
      position: absolute;
      inset: 0;
    }
  }
  &__close-button-icon {
    font-weight: var(--button-weight, #{$font-weight--bold}) !important;
  }
  &__text {
    margin: 0;
  }
}
</style>
