import BaseAPI from './baseAPI';

export default class CustomerHandling extends BaseAPI {

  async getModelEEntitlements() {
    const { data } = await this.axios.get('/DashboardModelE/CustomerHandlingModelE/EntitlementsModelE');
    return data;
  }

}
