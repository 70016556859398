<template>
  <div class="stripe-application">
    <h1 class="stripe-application__title">
      {{ $l('stripe.application.title') }}
    </h1>
    <p class="stripe-application__card">
      {{ $l('stripe.application.message') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'StripeApplicationView',
  mounted() {
    const { brand, language } = this.$route.query;

    this.$store.commit('user/setActiveBrand', brand);
    this.$store.commit('locale/setActiveLocale', language);
  },
};
</script>

<style lang="scss" scoped>
.stripe-application {
  max-width: 1095px;
  margin: 0 auto;
  margin-top: min(10%, 96px);
  &__title {
    margin: 0;
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: $font-weight--light;
    font-family: $font-family--antenna-condensed;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  &__card {
    margin: 0;
    padding: max(40px, 6.5%) max(32px, 5%);
    box-shadow: 0 5px 10px 0 rgba($color--black, 0.3);
  }
}
</style>
