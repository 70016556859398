<template>
  <div class="stripe">
    <header class="stripe__header">
      <h2
        v-html="$l('stripe.title')"
        class="stripe__title"
      />
      <p class="stripe__subtitle">
        {{ $l('stripe.subtitle') }}
      </p>
    </header>
    <StripeSection>
      <LoadingIndicator v-if="isLoading" />
      <template v-else>
        <StripeAccountSetup />
        <StripeFeeSetup />
      </template>
    </StripeSection>
    <ModalAccountOnHold
      :open="showModalAccountOnHold"
      @close="showModalAccountOnHold = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import StripeSection from '@/components/StripeSection.vue';
import StripeAccountSetup from '@/components/StripeAccountSetup.vue';
import StripeFeeSetup from '@/components/StripeFeeSetup.vue';
import ModalAccountOnHold from '@/components/StripeModalAccountOnHold.vue';

export default {
  name: 'StripeView',
  components: {
    LoadingIndicator,
    StripeSection,
    StripeAccountSetup,
    StripeFeeSetup,
    ModalAccountOnHold,
  },
  data() {
    return {
      isLoading: true,
      showModalAccountOnHold: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      lastLoadingAt: 'stripe/lastLoadingAt',
    }),
    aplicationStatusIsOnhold() {
      return this.$store.getters['stripe/aplicationStatus'].onhold;
    },
  },
  watch: {
    aplicationStatusIsOnhold() {
      this.showDialog();
    },
    async activeDealership() {
      if (this.activeDealership.enrolled) {
        return;
      }

      await this.$store.dispatch('topBanner/open', {
        type: 'error',
        message: this.$l('stripe.notEnrolled'),
      });

      this.$router.push({ name: 'Dashboard' });
    },
  },
  async mounted() {
    if (!this.lastLoadingAt) {
      await this.$store.dispatch('stripe/load');
    }

    this.isLoading = false;

    // Stripe resources are inside of Dashboard resources
    this.$store.dispatch('dashboard/getResources');

    this.showDialog();
  },
  methods: {
    showDialog() {
      if (this.aplicationStatusIsOnhold) {
        this.showModalAccountOnHold = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe {
  max-width: 1340px;
  margin: auto;
  margin-bottom: 100px;
  &__header {
    margin-bottom: 40px;
    text-align: center;
  }
  &__title {
    margin: 20px 0;
    font-weight: $font-weight--light;
    :deep(sup) {
      font-size: 32%;
      top: -1.4em;
      left: -0.7em;
    }
  }
  &__subtitle {
    margin: 0;
  }
}
</style>
