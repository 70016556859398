export default {
  resourceCenter: {
    title: 'Resource Center',
    subtitle: 'Learn more',
    description: 'This is the place to go to find Model e eCommerce tools. As you explore, you’ll discover everything you need to support your activation and resources that will help you provide the best experience for your eCommerce Guests.',
  },
  dashboard: {
    title: 'Dashboard',
    subtitle: 'Enrol, activate, and maintain',
    description: 'This is your one-stop shop for activating and maintaining your Model E eCommerce experience.',
    text1: 'Enrol and activate',
    text2: 'Assign and update your in-dealership team',
    text3: 'Modify your dealership settings',
    text4: 'Request support',
  },
};
