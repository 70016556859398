import BaseAPI from './baseAPI';

export default class Dealer extends BaseAPI {
  async getPersonnelData(dealerId) {
    const { data } = await this.axios.get('/Dashboard', {
      params: { dealerId },
    });

    return data;
  }

  async saveContact(contact) {
    const { data } = await this.axios.post('/Dashboard/Contacts', contact);
    return data;
  }

  async deleteContact(payload) {
    const { data } = await this.axios.delete('/Dashboard/Contacts', {
      data: {
        dealerId: payload.dealerId,
        contactId: payload.contactId,
      },
    });
    return data;
  }

  async savePhoneNumber(dealerId, phoneNumber) {
    const { data } = await this.postUnique('/Dashboard/Phone', {
      dealerID: dealerId,
      phone: phoneNumber,
    });
    return data;
  }

  async saveDeliveryPreference(dealerID, remoteDeliveryPreference) {
    const { data } = await this.postUnique('/Dashboard/RemoteDelivery', {
      dealerID,
      remoteDeliveryPreference,
    });

    return data;
  }

  async getPersonnelDataModelE(dealerId) {
    const { data } = await this.axios.get('/DashboardModelE', {
      params: { dealerId },
    });
    return data;
  }
  async getContactsModelE(dealerId) {
    const { data } = await this.axios.get('/DashboardModelE/ContactsModelE', {
      params: { dealerId },
    });
    return data.data;
  }

  async saveModelEContacts(contacts) {
    const { data } = await this.axios.post('/DashboardModelE/ContactsModelE', contacts);
    return data;
  }

  async deleteModelEContact(payload) {
    const { data } = await this.axios.delete('/DashboardModelE/ContactsModelE', {
      data: {
        dealerId: payload.dealerId,
        contactId: payload.contactId,
      },
    });
    return data;
  }

  async saveModelEDeliveryPreference(dealerID, remoteDeliveryPreference) {
    const { data } = await this.postUnique('/DashboardModelE/RemoteDeliveryModelE', {
      dealerID,
      remoteDeliveryPreference,
    });

    return data;
  }

  async saveModelEPhoneNumber(dealerId, phoneNumber) {
    const { data } = await this.postUnique('/DashboardModelE/PhoneModelE', {
      dealerID: dealerId,
      phone: phoneNumber,
    });
    return data;
  }

}
