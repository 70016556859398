<template>
  <div
    id="resource-departments"
    class="select-department"
  >
    <h2 class="select-department__title | fds-headline__5">
      {{ $l('resourceDepartment.title') }}
    </h2>
    <div class="select-department__content">
      <StandardButton
        v-for="department in activeDepartments"
        :key="department.value"
        track-section-name="selectByDepartment"
        :track-event-name="`select_department-${department.text.replaceAll(' ', '')}`"
        :to="{ name: 'ModelEResourceResults', query: { department: department.id } }"
        :text="department.text"
      />
    </div>
  </div>
</template>

<script>
import apiManager from '@/api';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'ModelEResourceCenterDepartment',
  components: {
    StandardButton,
  },
  data() {
    return {
      departmentList: [],
    };
  },
  computed: {
    activeDepartments() {
      const activeLocale = this.$store.getters['locale/activeLocale'];
      return this.departmentList.filter(({ language }) => language === activeLocale);
    },
  },
  async mounted() {
    const { departments } = await apiManager.resourceCenter.getModelEFilters('comp');

    this.departmentList = departments;
  },
};
</script>

<style lang="scss" scoped>
.select-department {
  .select-department__title {
    margin: 0;
    margin-bottom: 40px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
}
</style>
