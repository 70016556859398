<template>
  <div class="stripe-reservation">
    <TextField
      v-model="reservationText"
      id="stripe__reservation-input"
      class="stripe-reservation__input"
      disabled
      :label="$l('stripe.feeSetup.card.deposit')"
      :description="$l('stripe.feeSetup.card.reservation.description')"
    />
  </div>
</template>

<script>
import TextField from './common/TextField.vue';

export default {
  name: 'StripeFeeSetupCardReservation',
  components: {
    TextField,
  },
  computed: {
    activeBrandIsFord() {
      return this.$store.getters['user/activeBrandIsFord'];
    },
    reservationText() {
      const { amount } = this.$store.getters['stripe/reservationDepositOptions']
        .filter((option) => option.isDefault)[0] || {};

      return (this.activeBrandIsFord)
        ? `$${amount}`
        : `${this.$l('stripe.feeSetup.card.oem')} $${amount}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-reservation {
  &__input {
    max-width: 300px;
  }
}
</style>
