/* eslint-disable */

export const DAYS_UNTIL_DEACTIVATION = 10;

export const experienceId = {
  RESERVATION: 'reservation',
  ORDER: 'order',
  CART_CHECKOUT: 'cartcheckout',
  MODEL_E_CART_CHECKOUT: 'cartcheckout',
};

export const status = {
  IN_PROGRESS: 'inprogress',
  OPT_OUT: 'optOut',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  NOT_AVAILABLE: 'notAvailable',
};
