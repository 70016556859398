import { brands } from '@/constants/brands';

const setFavicon = (store) => {
  const favicon = document.querySelector('link#favicon[rel=icon]');

  if (store.getters['user/activeBrand'] === brands.LINCOLN) {
    favicon.setAttribute('href', `${process.env.VUE_APP_BASE_URL}faviconLincoln.ico`);
  } else {
    favicon.setAttribute('href', `${process.env.VUE_APP_BASE_URL}favicon.ico`);
  }
};

const setPageTitle = (store) => {
  let pageTitle = '';

  if (store.getters['user/activeBrandIsLincoln']) {
    pageTitle = (store.getters['locale/activeLocaleIsFrench'])
      ? 'Expériences eCommerce Lincoln'
      : 'Lincoln eCommerce Experiences';
  } else {
    pageTitle = (store.getters['locale/activeLocaleIsFrench'])
      ? 'Expériences eCommerce Ford'
      : 'Ford eCommerce Experiences';
  }

  document.querySelector('title').innerHTML = pageTitle;
};

const setPageLanguage = (store) => {
  const html = document.querySelector('html');

  if (store.getters['locale/activeLocaleIsFrench']) {
    html.setAttribute('lang', 'fr');
  } else {
    html.setAttribute('lang', 'en');
  }
};

export default function watchers(store) {
  store.watch((_, getters) => getters['user/activeBrand'], () => {
    setFavicon(store);
    setPageTitle(store);
  });

  store.watch((_, getters) => getters['locale/activeLocale'], () => {
    setPageLanguage(store);
    setPageTitle(store);
  });
}
