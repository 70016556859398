<template>
  <div>
    <ActivationSummaryModalOptOut
      :title="$l('activationSummary.cartCheckout.optOut.title')"
      :subtitle="$l('activationSummary.cartCheckout.optOut.subtitle')"
      :description="$l('activationSummary.cartCheckout.optOut.description')"
      :open="showModalOptOut"
      :is-loading="isDeactivatingExperience"
      @close="showModalOptOut = false"
      @accept="deactivateExperience"
    />
    <ActivationSummaryModal
      :title="$l('activationSummary.cartCheckout.cancelOptOut.title')"
      :subtitle="$l('activationSummary.cartCheckout.cancelOptOut.subtitle')"
      :description="$l('activationSummary.cartCheckout.cancelOptOut.description')"
      :open="showModalCancelOptOut"
      @close="showModalCancelOptOut = false"
    />
    <ActivationSummaryModal
      :title="$l('activationSummary.cartCheckout.reactivate.title')"
      :subtitle="$l('activationSummary.cartCheckout.reactivate.subtitle')"
      :description="$l('activationSummary.cartCheckout.reactivate.description')"
      :open="showModalReactivate"
      @close="showModalReactivate = false"
    />
    <ActivationSummaryCard
      :title="$l('activationSummary.cartCheckout.title')"
      :deactivation-date="cartCheckout.deactivation.endDate"
    >
      <ActivationSummaryControl
        :status="cartCheckout.status"
        :percentage-complete="cartCheckout.percentageComplete"
        @deactivate-experience="showModalOptOut = true"
        @cancel-opt-out="cancelOptOut"
        @activate-experience="reactivateExperience"
      />
      <template #list>
        <ActivationSummaryItem
          :label="$l('activationSummary.cartCheckout.item1')"
          :status="cartCheckout.enrollmentStatus"
          :accordion-id="accordions.enrollment.id"
        />
        <ActivationSummaryItem
          :label="$l('activationSummary.cartCheckout.item2')"
          :status="cartCheckout.stripeSetupStatus"
          :disabled="!activeDealership.enrolled"
          :accordion-id="accordions.stripeAccountSetup.id"
          :subcordion-id="accordions.stripeAccountSetup.feeStripeAccount.id"
        />
        <ActivationSummaryItem
          :label="$l('activationSummary.cartCheckout.item3')"
          :status="cartCheckout.vehiclePricingStatus"
          :disabled="!activeDealership.enrolled"
          :accordion-id="accordions.vehiclePricing.id"
          :subcordion-id="accordions.vehiclePricing.vehiclePricing.id"
        />
        <ActivationSummaryItem
          :label="$l('activationSummary.cartCheckout.item4')"
          :status="cartCheckout.accessoryPricingStatus"
          :disabled="!activeDealership.enrolled"
          :accordion-id="accordions.accessoryPricing.id"
          :subcordion-id="accordions.accessoryPricing.accessoryPricing.id"
        />
        <ActivationSummaryItem
          :label="$l('activationSummary.cartCheckout.item5')"
          :status="cartCheckout.tradeInStatus"
          :disabled="!activeDealership.enrolled"
          :accordion-id="accordions.tradeIn.id"
          :subcordion-id="accordions.tradeIn.ars.id"
        />
        <ActivationSummaryItem
          v-if="activeFeatures.fiAccordion"
          :status="cartCheckout.fniSetupStatus"
          :label="$l('activationSummary.cartCheckout.item6')"
          :disabled="!activeDealership.enrolled"
          :accordion-id="accordions.fiSetup.id"
        />
        <ActivationSummaryItem
          v-else
          status="no-dot"
          :label="$l('activationSummary.cartCheckout.item6ComingSoon')"
        />
        <ActivationSummaryItem
          status="no-dot"
          :label="$l('activationSummary.cartCheckout.item7')"
        />
      </template>
    </ActivationSummaryCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElementType, Event } from '@/constants/track';
import { experienceId } from '@/constants/activationSummary';
import ActivationSummaryCard from './ActivationSummaryCard.vue';
import ActivationSummaryControl from './ActivationSummaryControl.vue';
import ActivationSummaryItem from './ActivationSummaryItem.vue';
import ActivationSummaryModal from './ActivationSummaryModal.vue';
import ActivationSummaryModalOptOut from './ActivationSummaryModalOptOut.vue';

export default {
  name: 'ActivationSummaryCartCheckout',
  components: {
    ActivationSummaryCard,
    ActivationSummaryControl,
    ActivationSummaryItem,
    ActivationSummaryModal,
    ActivationSummaryModalOptOut,
  },
  data() {
    return {
      showModalOptOut: false,
      showModalCancelOptOut: false,
      showModalReactivate: false,
      isDeactivatingExperience: false,
      isCancelingOptOut: false,
      isReactivatingExperience: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      activeFeatures: 'features/activeFeatures',
      cartCheckout: 'activationSummary/cartCheckout',
      accordions: 'dashboard/accordions/accordions',
    }),
  },
  watch: {
    showModalOptOut() {
      if (this.showModalOptOut) {
        this.$store.dispatch('track/event', {
          event: Event.OPEN_OPT_OUT_MODAL,
          section: 'cart-checkout',
          elementType: ElementType.MODAL,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
  methods: {
    async deactivateExperience() {
      this.isDeactivatingExperience = true;

      this.$store.dispatch('track/event', {
        event: Event.DEACTIVATE_EXPERIENCE,
        section: 'cartCheckout',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/deactivateExperience', {
          experienceId: experienceId.CART_CHECKOUT,
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.deactivate.errorMessage'),
        });
      } finally {
        this.showModalOptOut = false;
        this.isDeactivatingExperience = false;
      }
    },
    async cancelOptOut() {
      if (this.isCancelingOptOut) return;

      this.isCancelingOptOut = true;

      this.$store.dispatch('track/event', {
        event: Event.CANCEL_OPT_OUT,
        section: 'cartCheckout',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/cancelExperienceOptOut', {
          experienceId: experienceId.CART_CHECKOUT,
        });

        this.showModalCancelOptOut = true;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.cancelOptOut.errorMessage'),
        });
      } finally {
        this.isCancelingOptOut = false;
      }
    },
    async reactivateExperience() {
      if (this.isReactivatingExperience) return;

      this.isReactivatingExperience = true;

      this.$store.dispatch('track/event', {
        event: Event.REACTIVATE_EXPERIENCE,
        section: 'cartCheckout',
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('activationSummary/reactivateExperience', {
          experienceId: experienceId.CART_CHECKOUT,
        });

        this.showModalReactivate = true;
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('activationSummary.reactivate.errorMessage'),
        });
      } finally {
        this.isReactivatingExperience = false;
      }
    },
  },
};
</script>
