<template>
  <form
    class="help-form"
    @submit.prevent="submit"
  >
    <div class="help-form__fields">
      <AppDropdown
        v-model="name"
        id="help-form--name"
        aria-label="Show name options"
        :label="$l('general.name')"
        :options="nameOptions"
        :dirty="v$.name.$dirty"
        :error-message="v$.name.$errors[0]"
      />
      <TextField
        v-model="phoneNumber"
        id="help-form--phone-number"
        class="help-form__phone-number"
        mask="000-000-0000"
        :label="$l('help.form.inputPhone')"
        :dirty="v$.phoneNumber.$dirty"
        :error-message="v$.phoneNumber.$errors[0]"
      />
      <TextField
        v-model.trim="email"
        id="help-form--email"
        :label="$l('general.email')"
        :dirty="v$.email.$dirty"
        :error-message="v$.email.$errors[0]"
      />
      <AppDropdown
        v-model="feedback"
        id="help-form--feedback"
        aria-label="Show feedback options"
        :label="$l('help.form.selectFeedback')"
        :options="feedbackCategoryOptions"
        :dirty="v$.feedback.$dirty"
        :error-message="v$.feedback.$errors[0]"
      />
      <AppDropdown
        v-model="topic"
        id="help-form--feedback-topic"
        aria-label="Show feedback topic options"
        :label="$l('help.form.selectTopic')"
        :options="feedbackTopicOptions"
        :dirty="v$.topic.$dirty"
        :error-message="v$.topic.$errors[0]"
      />
      <TextareaField
        v-model.trim="notes"
        id="help-form--notes"
        class="help-form__textarea"
        :label="$l('help.form.inputComment')"
        :maxlength="250"
      />
    </div>
    <div class="help-form__footer">
      <StandardButton
        type="submit"
        :text="$l('help.form.submitButton')"
        :is-loading="isLoading"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { ElementType, Event } from '@/constants/track';
import { required, email, customMinLength } from '@/helpers/i18nValidators';
import AppDropdown from './common/AppDropdown.vue';
import TextField from './common/TextField.vue';
import TextareaField from './common/TextareaField.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'HelpForm',
  components: {
    AppDropdown,
    TextField,
    TextareaField,
    StandardButton,
  },
  setup() {
    return {
      v$: useVuelidate({ $autoDirty: true }),
    };
  },
  data() {
    return {
      isLoading: false,
      name: '',
      phoneNumber: '',
      email: '',
      feedback: '',
      notes: '',
      topic: '',
    };
  },
  computed: {
    ...mapGetters({
      names: 'help/names',
      feedbackTopics: 'help/feedbackTopics',
      feedbackCategories: 'help/feedbackCategories',
    }),
    nameOptions() {
      return this.names
        .map((name) => ({
          text: `${name.firstName} ${name.lastName} (*****${name.id.substr(-4)})`,
          value: name,
        }))
        .sort((a, b) => {
          if (a.text < b.text) return -1;

          if (a.text > b.text) return 1;

          return 0;
        });
    },
    feedbackTopicOptions() {
      return this.feedbackTopics.map((category) => ({
        text: category.text,
        value: category.id,
      }));
    },
    feedbackCategoryOptions() {
      return this.feedbackCategories.map((topic) => ({
        text: topic.text,
        value: topic.id,
      }));
    },
  },
  validations() {
    return {
      name: { required },
      phoneNumber: {
        required,
        minLength: customMinLength('validations.phoneNumber', 10),
      },
      email: {
        required,
        email,
      },
      feedback: { required },
      topic: { required },
    };
  },
  mounted() {
    this.loadForm();
  },
  methods: {
    async loadForm() {
      await this.$store.dispatch('help/loadFormOptions');

      const { feedback, topic } = this.$route.query;

      if (feedback && !Number.isNaN(+feedback)) {
        this.feedback = +feedback;
      }

      if (topic && !Number.isNaN(+topic)) {
        this.topic = +topic;
      }
    },
    resetForm() {
      this.name = '';
      this.phoneNumber = '';
      this.email = '';
      this.feedback = '';
      this.notes = '';
      this.topic = '';

      this.v$.$reset();
    },
    async submit() {
      const formIsValid = await this.v$.$validate();

      if (!formIsValid) return;

      this.isLoading = true;

      this.$store.dispatch('track/event', {
        event: Event.SUBMIT_HELP_FORM,
        elementType: ElementType.FORM,
        pageName: this.$route.meta.track.pageName,
      });

      try {
        await this.$store.dispatch('help/sendHelpFormData', {
          firstName: this.name.firstName,
          lastName: this.name.lastName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          notes: this.notes,
          feedback: this.feedback,
          topic: this.topic,
        });

        this.$store.dispatch('topBanner/open', {
          message: this.$l('help.submitSuccess'),
        });

        this.resetForm();
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('help.submitError'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.help-form {
  margin-top: 32px;
  &__fields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px 32px;
    width: 100%;
    @include respond(md-up) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__phone-number {
    @include respond(md-up) {
      grid-column: 1 / 2;
    }
  }
  &__textarea {
    @include respond(md-up) {
      grid-column: 1 / 3;
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }
}
</style>
