<template>
  <BaseButton
    class="link"
    :class="[linkClasses, $store.getters['user/activeBrandName']]"
    :to="to"
    @click="handleClick"
  >
    <slot />
  </BaseButton>
</template>

<script>
import { ElementType } from '@/constants/track';
import BaseButton from './BaseButton.vue';

export default {
  name: 'AppLink',
  components: {
    BaseButton,
  },
  emits: ['click'],
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    trackEventName: {
      type: String,
      default: '',
    },
    trackSectionName: {
      type: String,
      default: '',
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkClasses() {
      return {
        'link--highlighted': this.isHighlighted,
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');

      if (this.trackEventName) {
        this.$store.dispatch('track/event', {
          event: this.trackEventName,
          section: this.trackSectionName,
          elementType: ElementType.HYPERLINK,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  --color: #{$color--fds-primary};
  --highlighted-color: #{$color--fds-secondary};
  &--highlighted {
    color: var(--highlighted-color);
  }

  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--color);
  outline-offset: 5px;
  text-decoration: none;
  &.ford {
    &:hover {
      color: $color--fds-secondary;
      text-decoration: underline !important;
    }
  }
  &.lincoln {
    --color: #{$color--lds-primary};
    --highlighted-color: #{$color--lds-secondary};
    &:hover {
      position: relative;
      &::after {
        content: '';
        height: 2px;
        background-color: $color--lds-secondary;
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
