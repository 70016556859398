import BaseAPI from './baseAPI';

export default class ActivationSummary extends BaseAPI {
  async get() {
    const { data } = await this.getUnique('/ActivationSummary');
    return data;
  }

  async post({
    type, from, to, dealerId,
  }) {
    const { data } = await this.axios.post('/ActivationSummary', {
      dealerID: dealerId,
      fromStatus: from,
      toStatus: to,
      type,
    });

    return data;
  }

  async updateProgress({ dealerId, step }) {
    const { data } = await this.axios.post('/ProgressStep/UpdateProgress', {
      dealerID: dealerId,
      step,
    });

    return data;
  }

  async getModelEData() {
    const { data } = await this.getUnique('/ActivationSummaryModelE');
    const activationSummary = data.data;
    return activationSummary;
  }

  async postModelE({ dealerId, from, to, type }) {
    const { data } = await this.postUnique('/ActivationSummaryModelE', {
      dealerID: dealerId,
      fromStatus: from,
      toStatus: to,
      type,
    });
    return data;
  }

  async updateModelEProgress({ dealerId, step }) {
    const { data } = await this.axios.post('/ProgressStepModelE/UpdateProgressModelE', {
      dealerID: dealerId,
      step,
    });

    return data;
  }

}
