<template>
  <BaseButton
    class="standard-button"
    :to="to"
    :class="[buttonClasses, $store.getters['user/activeBrandName']]"
    @click="handleClick"
  >
    <span class="standard-button__text">
      <FontAwesomeIcon
        v-show="isLoading"
        class="standard-button__icon-loader"
        spin
        :icon="iconLoader"
      />
      <i
        v-if="iconLeft"
        class="standard-button__icon standard-button__icon--left"
        :class="`app-icon-${iconLeft}`"
      />
      {{ text }}
      <i
        v-if="iconRight"
        class="standard-button__icon standard-button__icon--right"
        :class="`app-icon-${iconRight}`"
      />
    </span>
  </BaseButton>
</template>

<script>
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { ElementType } from '@/constants/track';
import BaseButton from '@/components/common/BaseButton.vue';

export default {
  name: 'StandardButton',
  components: {
    BaseButton,
  },
  emits: ['click'],
  props: {
    secondary: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
      validator(size) {
        return ['', 'sm', 'lg', 'xl'].includes(size);
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: '',
    },
    trackEventName: {
      type: String,
      default: '',
    },
    trackSectionName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconLoader: faSpinnerThird,
    };
  },
  computed: {
    buttonClasses() {
      return {
        'standard-button--primary': !this.secondary,
        'standard-button--secondary': this.secondary,
        'standard-button--outline': this.outline,
        'standard-button--small': this.size === 'sm',
        'standard-button--large': this.size === 'lg',
        'standard-button--extra-large': this.size === 'xl',
      };
    },
  },
  methods: {
    handleClick() {
      if (this.isLoading) return;

      this.trackClick();
      this.$emit('click');
    },
    trackClick() {
      if (!this.trackEventName) return;

      this.$store.dispatch('track/event', {
        event: this.trackEventName,
        section: this.trackSectionName,
        elementType: ElementType.BUTTON,
        pageName: this.$route.meta.track.pageName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.standard-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: $color--white;
  background-color: transparent;
  width: fit-content;
  text-decoration: none;
  position: relative;
  outline: none;
  white-space: nowrap;

  &--small {
    min-width: 150px;
  }
  &--large {
    min-width: 200px;
  }
  &--extra-large {
    min-width: 300px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:focus-visible::after {
    outline: 1px solid;
    outline-offset: 5px;
  }
  &__text {
    position: relative;
    text-transform: capitalize;
  }
  &__icon {
    font-size: 14px;
    font-style: normal;
    &::before {
      font-family: $font-family--icons;
    }
    &--left {
      padding-right: 16px;
    }
    &--right {
      padding-left: 16px;
    }
  }
  &__icon-loader {
    margin-right: 15px;
    animation-duration: 1s
  }
}
.standard-button.ford {
  height: 40px;
  padding: 0 32px;
  font-size: 18px;
  font-weight: $font-weight--light;
  transition: all 0.3s ease-in-out;
  font-family: $font-family--antenna-condensed;
  &:focus-visible::after {
    outline: 1px solid;
    outline-offset: 10px;
  }
  &::before {
    content: '';
    border: 1px solid transparent;
    border-radius: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: $fds-elevation__box-shadow--layer2;
    transition:
      box-shadow 0.3s ease-in-out,
      transform 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }
  &:disabled::before {
    box-shadow: $fds-elevation__box-shadow--layer1;
  }
  &:not(:disabled):focus-visible::before,
  &:not(:disabled):hover::before {
    transform: scale(1.07, 1.04);
    box-shadow: $fds-elevation__box-shadow--layer3;
  }
  &.standard-button--primary {
    &:focus-visible::after {
      outline-color: $color--fds-primary;
    }
    &::before {
      background-color: $color--fds-primary;
    }
    &:disabled::before {
      background-color: $color--fds-disabled3;
    }
    &:not(:disabled):focus-visible::before,
    &:not(:disabled):hover::before {
      background-color: $color--fds-secondary;
    }
    &.standard-button--outline {
      color: $color--fds-primary;
      &::before {
        box-shadow: none !important;
        background-color: transparent;
        border-color: $color--fds-primary;
      }
      &:not(:disabled):focus-visible,
      &:not(:disabled):hover {
        color: $color--fds-secondary;
        &::before {
          background-color: transparent;
          border-color: $color--fds-secondary;
        }
      }
      &:disabled {
        color: $color--fds-disabled3;
        &::before {
          border-color: $color--fds-disabled3;
        }
      }
    }
  }
  &.standard-button--secondary {
    color: $color--fds-primary;
    &:focus-visible::after {
      outline-color: $color--white;
    }
    &::before {
      background-color: $color--white;
    }
    &:disabled {
      color: $color--black;
      &::before {
        background-color: $color--fds-disabled2;
      }
    }
    &:not(:disabled):focus-visible::before,
    &:not(:disabled):hover::before {
      background-color: $color--fds-gray1;
    }
    &.standard-button--outline {
      color: $color--white;
      &::before {
        box-shadow: none !important;
        background-color: transparent;
        border-color: $color--white;
      }
      &:not(:disabled):focus-visible::before,
      &:not(:disabled):hover::before {
        background-color: rgba($color--white, 0.05);
      }
      &:disabled {
        color: $color--fds-disabled2;
        &::before {
          border-color: $color--fds-disabled2;
        }
      }
    }
  }
}
.standard-button.lincoln {
  height: 50px;
  padding: 0 20px;
  border: 2px solid transparent;
  font-size: 16px;
  transition: color 0.3s ease-in-out,
    border-color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  &::before {
    content: '';
    height: 2px;
    position: absolute;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background-color: $color--lds-secondary;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &.standard-button--primary {
    background-color: $color--lds-primary;
    &:focus-visible::after {
      outline-color: $color--lds-primary;
    }
    &:not(:disabled):focus-visible::before,
    &:not(:disabled):hover::before {
      opacity: 1;
    }
    &:active {
      background-color: $color--lds-black;
    }
    &:disabled {
      background-color: $color--lds-gray4;
    }
    &.standard-button--outline {
      color: $color--lds-primary;
      border-color: $color--lds-primary;
      background-color: transparent;
      &:focus-visible::after {
        outline-color: $color--lds-primary;
      }
      &:not(:disabled):focus-visible,
      &:not(:disabled):hover {
        color: $color--white;
        background-color: $color--lds-primary;
      }
      &:active {
        background-color: $color--lds-black !important;
        border-color: $color--lds-black;
      }
      &:disabled {
        color: $color--lds-gray4;
        border-color: $color--lds-gray4;
        background-color: transparent !important;
      }
    }
  }
  &.standard-button--secondary {
    color: $color--lds-primary;
    background-color: $color--white;
    &:focus-visible::after {
      outline-color: $color--white;
    }
    &:not(:disabled):focus-visible::before,
    &:not(:disabled):hover::before {
      opacity: 1;
    }
    &:disabled {
      color: $color--white;
      background-color: $color--lds-gray4;
    }
    &.standard-button--outline {
      background-color: transparent;
      color: $color--white;
      border-color: $color--white;
      &:not(:disabled):focus-visible,
      &:not(:disabled):hover {
        color: $color--lds-primary;
        background-color: $color--white;
      }
      &:disabled {
        color: $color--lds-gray4;
        border-color: $color--lds-gray4;
        background-color: transparent;
      }
    }
  }
}
</style>
