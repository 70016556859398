<template>
  <TeamMember
    :open-on-mounted="openOnMounted"
    :information="memberInformation"
    :labels="memberLabels"
    :should-be-reviewed="contactSourceIsBlue && isValidStarsContact"
    :should-be-deleted="contactSourceIsBlue && !isValidStarsContact"
    :is-loading="isDeletingContact || isLoading"
    @save-contact="handleSaveContact"
    @delete-contact="deleteContact"
  >
    <MemberDetails
      :index="index"
      :is-loading="isLoading"
      :is-deleting-contact="isDeletingContact"
      :full-name="fullName"
      :phone-number="phoneNumber"
      :email="email"
      :position="positionName ? position : ''"
      :email-language="languageCode || activeLocale"
      :extension="extension"
      :departments="departments"
      :alerts="alerts"
      @save-contact="saveContact"
      @delete-contact="deleteContact"
    />
  </TeamMember>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import defaultDepartments from '@/data/dealerPersonnelDepartments';
import TeamMember from './DashboardTeamMember.vue';
import MemberDetails from './ModelEDashboardDealerPersonnelMemberDetails.vue';

export default {
  name: 'ModelEDashboardDealerPersonnelMember',
  components: {
    TeamMember,
    MemberDetails,
  },
  emits: ['clear-contact-to-be-added', 'contact-updated'],
  props: {
    index: { type: Number, required: true },
    contactId: { type: String, default: '' },
    dealerId: { type: String, default: '' },
    starsId: { type: String, default: '' },
    userId: { type: String, default: '' },
    firstName: { type: String, default: '' },
    lastName: { type: String, default: '' },
    phoneNumber: { type: String, default: '' },
    cellPhone: { type: String, default: '' },
    email: { type: String, default: '' },
    position: { type: String, default: '' },
    languageCode: { type: String, default: '' },
    extension: { type: String, default: '' },
    isKeyDecisionMaker: { type: Boolean, default: false },
    source: { type: String, default: '' },
    isValidStarsContact: { type: Boolean, default: false },
    openOnMounted: { type: Boolean, default: false },
    departments: { type: Object, default: () => ({}) },
    alerts: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      isLoading: false,
      isDeletingContact: false,
      emailLanguage: '',
    };
  },
  computed: {
    ...mapGetters({
      activeLocale: 'locale/activeLocale',
      contactPositions: 'modelE/dealerPersonnel/contactPositions',
      emailLanguages: 'modelE/dealerPersonnel/emailLanguages',
    }),
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },
    formattedPhoneNumber() {
      const number = this.phoneNumber;

      if (!number) return '';

      return `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6)}`;
    },
    formattedExtension() {
      if (!this.extension) return '';
      return `Ext: ${this.extension}`;
    },
    positionName() {
      return this.contactPositions.find((position) => position.value === this.position)?.text;
    },
    emailLanguageName() {
      return this.emailLanguages.find(({ value }) => value === this.languageCode)?.text;
    },
    memberInformation() {
      return [
        this.fullName,
        this.positionName,
        this.formattedPhoneNumber,
        this.formattedExtension,
        this.email,
        this.emailLanguageName,
      ];
    },
    memberLabels() {
      return defaultDepartments.map((department) => ({
        text: this.$l(department.name),
        active: this.departments[department.key],
      }));
    },
    contactIsBeingAdded() {
      return !this.contactId;
    },
    contactSourceIsBlue() {
      return this.source.toLowerCase() === 'blue';
    },
  },
  methods: {
    handleSaveContact() {
      this.saveContact({
        position: this.positionName ? this.position : '',
        email: this.email || '',
        phoneNumber: this.phoneNumber || '',
        extension: this.extension || '',
        departments: this.departments,
        alerts: this.alerts,
      });
    },
    async saveContact(contact) {
      this.isLoading = true;
      try {
        const { success } = await apiManager.dealer.saveModelEContacts([{
          contactID: this.contactId,
          dealerID: this.dealerId,
          firstName: this.firstName,
          lastName: this.lastName,
          cellPhone: this.cellPhone,
          starsID: this.starsID,
          position: contact.position,
          phone: contact.phoneNumber,
          email: contact.email,
          ext: contact.extension,
          programManagement: contact.departments.programManagement,
          vehiclePricing: contact.departments.vehiclePricing,
          accessoryPricing: contact.departments.accessoryPricing,
          tradeTool: contact.departments.tradeTool,
          fniSetup: contact.departments.fniSetup,
          customerHandling: contact.departments.customerHandling,
          receiveReservationAlert: contact.alerts.reservations,
          receiveOrderAlert: contact.alerts.orders,
          receiveCartCheckoutAlert: contact.alerts.cartCheckout,
          source: 'modelE',
          status: (this.contactIsBeingAdded) ? 'c' : 'u',
        }]);

        if (!success) {
          throw new Error((this.contactIsBeingAdded)
            ? this.$l('keyDecisionMaker.messages.failedToSave')
            : this.$l('keyDecisionMaker.messages.failedToUpdate'));
        }

        await this.$store.dispatch('topBanner/open', {
          message: (this.contactIsBeingAdded)
            ? this.$l('keyDecisionMaker.messages.savedSuccessfuly')
            : this.$l('keyDecisionMaker.messages.updatedSuccessfuly'),
        });

        if (this.contactIsBeingAdded) {
          this.$emit('clear-contact-to-be-added');
        }
        await this.$store.dispatch('modelE/dealerPersonnel/loadContacts');
        this.$emit('contact-updated');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteContact() {
      if (this.contactIsBeingAdded) {
        this.$emit('clear-contact-to-be-added');
        return;
      }

      this.isDeletingContact = true;

      try {
        const { success } = await apiManager.dealer.deleteModelEContact({
          dealerId: this.dealerId,
          contactId: this.contactId,
        });

        if (!success) {
          throw new Error(this.$l('keyDecisionMaker.messages.failedToDelete'));
        }

        await this.$store.dispatch('topBanner/open', {
          message: this.$l('keyDecisionMaker.messages.deletedSuccessfuly'),
        });

        this.$emit('contact-updated');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isDeletingContact = false;
      }
    },
  },
};
</script>
