export default {
  departament: {
    name: 'Department',
    management: 'Management',
    newCar: 'New Vehicle',
    accessories: 'Accessories',
    usedCar: 'Used Vehicle',
    fAndI: 'F&I',
    eCommSales: 'eComm Sales',
  },
  tooltip: {
    management: 'Responsible for managing the program within the dealership, and following up with other team members to ensure dealership activation activities are completed and coordinating with F(ii) and owner to complete Stripe Account Setup (this may already be complete for dealers participation in online reservations & orders).',
    newCar: 'Responsible for setting vehicle pricing to appear in eCommerce experiences for the dealership.',
    usedCar: 'Responsible for enroling the dealership in ARS (if needed) and being aware of who has been assigned to receive these leads and coordinating with them.',
    accessories: 'Responsible for setting accessory pricing to appear in the experience.',
    fAndI: 'Responsible for setting up all pieces of F&I (when it becomes available).',
    eCommSales: 'Responsible for reviewing and acting upon Reservations, Order Requests and New Vehicle Purchase Requests and assisting the Customer through the purchase process. You can assign individuals throughout the dealership to have one or more of these responsibilities.',
  },
  addContact: {
    buttonText: 'Add a Contact',
  },
  item: {
    toggleDetailsButtonAriaLabel: 'Open contact details',
    saveButtonText: 'Save Contact',
    deleteButtonText: 'Delete Contact',
    errorMessage: 'Dealer Information and Team Setup',
    tooltipButton: 'Here',
    reservations: {
      name: 'Reservations:',
      label: 'by checking this box this individual will receive alerts any time a customer places a reservation with your dealership.',
      tooltip: 'Individuals will require WBDO Submit and EMP_eComm_CustomerHandling permissions to view or accept Reservations and Orders in Customer Handling Pages. WBDO and EMP Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click',
    },
    orders: {
      name: 'Orders:',
      label: 'by checking this box this individual will receive alerts any time a customer places an order request with your dealership. Order requests will need to be approved and entered into WBDO by an individual with appropriate permissions.',
      tooltip: 'Individuals will require WBDO Submit permissions to enter Orders into WBDO Customer Handling Pages. Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click',
    },
    vehicles: {
      name: 'New Vehicle Purchase Requests:',
      label: 'by checking this box this individual will receive alerts any time a customer places a new vehicle purchase request with your dealership.',
      tooltip: 'Individuals will require WBDO Submit and EMP_eComm_CustomerHandling permissions to handle New Vehicle Purchase Requests. WBDO and EMP Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click',
    },
  },
  input: {
    position: 'Role / Title',
    phone: 'Direct Phone or Cell',
    extension: 'Extension',
  },
};
