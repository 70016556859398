export default {
  title: 'Création de l’équipe chez le concessionnaire',
  form: {
    position: '[NT] Role / Title',
    phone: '[NT] Direct Phone or Cell',
    extension: '[NT] Extension',
    addButton: '[NT] Add A Contact',
    saveButton: '[NT] Save Contact',
    deleteButton: '[NT] Delete Contact',
    reservations: {
      name: '[NT] Reservations:',
      label: '[NT] by checking this box this individual will receive alerts any time a customer places a reservation with your dealership.',
      tooltip: '<div>[NT] Individuals will require WBDO Submit and EMP_eComm_CustomerHandling permissions to view or accept Reservations and Orders in Customer Handling Pages. WBDO and EMP Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click <button type="button" class="btn-link" @click="emitBack" style="padding:0px;">HERE</button>.</div>',
    },
    orders: {
      name: '[NT] Orders:',
      label: '[NT] by checking this box this individual will receive alerts any time a customer places an order request with your dealership. Order requests will need to be approved and entered into WBDO by an individual with appropriate permissions.',
      tooltip: '[NT] <div>Individuals will require WBDO Submit permissions to enter Orders into WBDO Customer Handling Pages. Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click <button type="button" class="btn-link" @click="emitBack" style="padding:0px;">HERE</button>.</div>',
    },
    vehicles: {
      name: '[NT] New Vehicle Purchase Requests:',
      label: '[NT] by checking this box this individual will receive alerts any time a customer places a new vehicle purchase request with your dealership.',
      tooltip: '<div>[NT] Individuals will require WBDO Submit permissions to enter Orders into WBDO Customer Handling Pages. Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click <button type="button" class="btn-link" @click="emitBack" style="padding:0px;">HERE</button>.</div>',
    },
  },
};
