export default {
  accordion3: {
    tooltip3: {
      title: 'Voir le profil du concessionnaire Lincoln',
      text: 'Il s’agit d’un affichage, adapté aux appareils mobiles, des renseignements sur l’état de préparation et la mise en œuvre d’commerce électronique par un concessionnaire.',
    },
  },
  accordion5: {
    title: 'Outils de gestion des clients <i style="font-size: 13px;">(à venir bientôt)</i>',
  },
  errors: {
    loadDealerListFailed: 'Échec du chargement de la liste des détaillants.',
    loadDealerDataFailed: 'Échec du chargement des données pour le détaillant sélectionné. Veuillez réessayer.',
  },
};
