import activationSummaryModule from './modules/activationSummary';
import dashboardModule from './dashboard';
import dealerPersonnelModule from './modules/dealerPersonnel';
import termsAndConditionsModule from './modules/termsAndConditions';
import stripeModule from './modules/stripe';
import helpModule from './modules/help';

export default {
    namespaced: true,
    modules: {
      activationSummary: activationSummaryModule,
      dashboard: dashboardModule,
      dealerPersonnel: dealerPersonnelModule,
      termsAndConditions: termsAndConditionsModule,
      stripe: stripeModule,
      help: helpModule,
    },
  };