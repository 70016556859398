<template>
  <span
    class="pill-label"
    :class="[{ 'pill-label--active': active }, $store.getters['user/activeBrandName']]"
  >
    <FontAwesomeIcon
      class="pill-label__icon pill-label__icon--check"
      :icon="faCheck"
    />
    <FontAwesomeIcon
      class="pill-label__icon pill-label__icon--ban"
      :icon="faBan"
    />
    {{ text }}
  </span>
</template>

<script>
import { faCheck, faBan } from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'PillLabel',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      faCheck,
      faBan,
    };
  },
};
</script>

<style lang="scss" scoped>
.pill-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  height: 40px;
  padding: 0 27px;
  color: $color--white;
  background-color: $color--fds-disabled1;
  border-radius: 2rem;
  border: 2px solid transparent;
  position: relative;
  &--active {
    &.ford {
      background-color: $color--fds-secondary;
    }
    &.lincoln {
      background-color: $color--lds-primary;
    }
    .pill-label__icon--ban {
      display: none;
    }
    .pill-label__icon--check {
      display: block;
    }
  }
  &__icon {
    position: absolute;
    left: 7px;
    &--check {
      display: none;
    }
  }
}
</style>
