export default {
  dashboard: {
    title: 'Tableau de bord',
    subtitle: 'Inscription, mise en œuvre et continuité',
    description: 'Il s’agit de votre guichet unique pour la mise en œuvre et la continuité des expériences commerce électronique. Vous pouvez :',
    list: {
      item1: 'Inscrire et mettre en œuvre chaque expérience commerce électronique indépendamment',
      item2: 'Attitrer et mettre à jour votre équipe de direction',
      item3: 'Modifier les paramètres de votre concessionnaire',
      item4: 'Demander du soutien',
    },
  },
  commsCenter: {
    title: 'Centre de communications',
    subtitle: 'Soyez informé des plus récentes mises à jour d’commerce électronique.',
    description: 'Dans le Centre de communications se trouvent les plus récentes nouvelles et mises à jour sur le programme commerce électronique, les éléments les plus actuels figurant en haut de la liste. Cette ressource offre des éléments opportuns qui peuvent être repérés et examinés rapidement pour vous assurer que vous restez informé à mesure que le programme commerce électronique se développe.',
  },
  resourceCenter: {
    title: 'Centre de ressources',
    subtitle: 'En savoir plus',
    description: 'Il s’agit de votre guichet unique pour les outils commerce électronique. Pendant que vous l’explorez, vous recueillerez tout ce dont vous avez besoin pour vous aider à mettre en œuvre et à offrir la meilleure expérience pour vos clients commerce électronique.',
  },
};
