import { format } from 'date-fns';
import apiManager from '@/api';
import resourceCenterId from '@/data/resourceCenter';

const normalizeDealerList = (dealerList) => (
  [...dealerList]
    .map(({ dealership }) => ({ ...dealership }))
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    })
);

const downloadReport = ({ data, filename }) => {
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(new Blob([data]));

  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();
};

export default {
  namespaced: true,
  state() {
    return {
      dealerList: {
        ford: [],
        lincoln: [],
      },
      resources: [],
      regionOptions: [],
      marketOptions: [],
    };
  },
  getters: {
    resources: (state) => state.resources,
    activeDealerList(state, getters, rootState, rootGetters) {
      if (rootGetters['user/activeBrandIsLincoln']) {
        return state.dealerList.lincoln;
      }

      return state.dealerList.ford;
    },
    lastUpdate(state, getters, rootState, rootGetters) {
      const lastUpdate = getters.activeDealerList[0]?.goldD;

      if (!lastUpdate) {
        return '';
      }

      const mask = `${rootGetters['locale/dateFormatPattern']} HH:mm:ss`;

      return format(new Date(lastUpdate), mask);
    },
    regionOptions(state, getters, rootState, rootGetters) {
      return state.regionOptions.filter(
        ({ language }) => language === rootGetters['locale/activeLocale'],
      );
    },
    marketOptions(state, getters, rootState, rootGetters) {
      return state.marketOptions.filter(
        ({ language }) => language === rootGetters['locale/activeLocale'],
      );
    },
  },
  mutations: {
    setResources(state, payload) {
      state.resources = payload;
    },
    setDealerList(state, { ford, lincoln }) {
      state.dealerList.ford = ford;
      state.dealerList.lincoln = lincoln;
    },
    setRegionOptions: (state, regionOptions) => {
      state.regionOptions = regionOptions;
    },
    setMarketOptions: (state, marketOptions) => {
      state.marketOptions = marketOptions;
    },
  },
  actions: {
    async getResources({ commit, getters, rootGetters }) {
      if (getters.resources.length) return;

      const [
        { value: { data: { result: englishResources } } },
        { value: { data: { result: frenchResources } } },
      ] = await Promise.allSettled([
        // Get english resources
        apiManager.resourceCenter.getResults({
          pageId: resourceCenterId.page.REPORTS,
          language: rootGetters['locale/locales'].english,
        }),
        // Get french resources
        apiManager.resourceCenter.getResults({
          pageId: resourceCenterId.page.REPORTS,
          language: rootGetters['locale/locales'].french,
        }),
      ]);

      commit('setResources', [
        ...englishResources,
        ...frenchResources,
      ]);
    },
    async getDealerList({ commit }) {
      const { data } = await apiManager.reports.getDealers();

      commit('setDealerList', {
        ford: normalizeDealerList(data.fordDealerList),
        lincoln: normalizeDealerList(data.lincolnDealerList),
      });
    },
    async getRegionOptions({ commit }) {
      const { data } = await apiManager.reports.getRegion();

      commit('setRegionOptions', data.regionOptions);
    },
    async getMarketOptions({ commit }) {
      const { data } = await apiManager.reports.getMarket();

      commit('setMarketOptions', data.marketOptions);
    },
    async downloadVehicleDIOPricingReport({ rootGetters }) {
      const { data, filename } = await apiManager.reports.getVehicleDIOPricingReport({
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadActivationRankingReport({ rootGetters }) {
      const { data, filename } = await apiManager.reports.getActivationRankingReport({
        division: rootGetters['user/activeBrand'],
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadVehicleConfigurationPricingReport({ rootGetters }) {
      const { data, filename } = await apiManager.reports.getVehicleConfigurationPricingReport({
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadMustangMachEPricingReport({ rootGetters }) {
      const { data, filename } = await apiManager.reports.getMustangMachEPricingReport({
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadPricingRulesReport({ rootGetters }) {
      const { data, filename } = await apiManager.reports.getPricingRulesReport({
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadARSEnrollmentReport({ rootGetters }) {
      const { data, filename } = await apiManager.reports.getARSEnrollmentReport({
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadASDDReport({ rootGetters }) {
      const { data, filename } = await apiManager.reports.getASDDReport({
        division: rootGetters['user/activeBrand'],
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadRegionalTrackerReport({ rootGetters }, { regionId, marketId }) {
      const { data, filename } = await apiManager.reports.getRegionalTrackerReport({
        regionId,
        marketId,
        division: rootGetters['user/activeBrand'],
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
    async downloadDealerProfileReport({ rootGetters }, { dealerId }) {
      const { data, filename } = await apiManager.reports.getDealerProfileReport({
        dealerId,
        division: rootGetters['user/activeBrand'],
        language: rootGetters['locale/activeLocale'],
      });

      downloadReport({ data, filename });
    },
  },
};
