import store from '@/store';
import SelectExperienceView from '@/views/SelectExperienceView.vue';
import ModelEStartView from '@/views/ModelEStartView.vue';
import ModelEDashboardView from '@/views/ModelEDashboardView.vue';
import ModelEStripeView from '@/views/ModelEStripeView.vue';
import ModelEFAQsView from '@/views/ModelEFAQsView.vue';
import ModelEHelpView from '@/views/ModelEHelpView.vue';
import ModelEResourceCenterView from '@/views/ModelEResourceCenterView.vue';
import ModelEResourceResultsView from '@/views/ModelEResourceResultsView.vue';
import ModelEReportsView from '@/views/ModelEReportsView.vue';
import ModelEReportASDDView from '@/views/ModelEReportASDDView.vue';

const getModelERedirectRoute = () => {
  const certificationLevel = store.getters['user/certificationLevel'];
  let redirectRoute = null;

  if(!store.getters['features/activeFeatures'].modelE) {
    redirectRoute = { name: 'Not Found' };
  } else if (certificationLevel.blueOnly || store.getters['user/activeBrandIsLincoln']) {
    redirectRoute = { name: 'Start' };
  }

  return redirectRoute;
};

const modelEGuard = (to, from, next) => {
  const redirectRoute = getModelERedirectRoute();

  if (redirectRoute) {
    next(redirectRoute);
  } else {
    next();
  }
};

export default [
  {
    path: '/SelectExperience',
    name: 'SelectExperience',
    component: SelectExperienceView,
    meta: {
      requiresAuth: true,
      header: {
        disableNavigation: true,
        disableBrandSelector: true,
        disableExperienceSelector: true,
        disableCustomAppName: true,
      },
      track: {
        pageName: 'Select_Experience',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/Start',
    name: 'ModelEStart',
    component: ModelEStartView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_Start',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/Dashboard',
    name: 'ModelEDashboard',
    component: ModelEDashboardView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_Dashboard',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/Stripe',
    name: 'ModelEStripe',
    component: ModelEStripeView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_Stripe',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/ResourceCenter',
    name: 'ModelEResourceCenter',
    component: ModelEResourceCenterView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_Resource_Center',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/FAQ',
    name: 'ModelEFAQ',
    component: ModelEFAQsView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_FAQs',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/Help',
    name: 'ModelEHelp',
    component: ModelEHelpView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_Help',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/ResourceResults',
    name: 'ModelEResourceResults',
    component: ModelEResourceResultsView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_Resource_Results',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/Reports',
    name: 'ModelEReports',
    component: ModelEReportsView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_Reports',
      },
    },
    beforeEnter: modelEGuard,
  },
  {
    path: '/ModelE/Reports/ASDD',
    name: 'ModelEReportASDD',
    component: ModelEReportASDDView,
    meta: {
      requiresAuth: true,
      layout: 'ModelE',
      header: {
        disableBrandSelector: true,
      },
      track: {
        pageName: 'ModelE_ASDD_Report',
      },
    },
    beforeEnter: modelEGuard,
  },
];
