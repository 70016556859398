import apiManager from '@/api';
import sessionCookie from '@/helpers/sessionCookie';

export default {
  namespaced: true,
  actions: {
    login({ dispatch }) {
      const { sessionId } = sessionCookie.get();

      if (!sessionId) {
        return dispatch('startOAuthAuthentication');
      }

      return dispatch('completeOAuthAuthentication');
    },
    authenticateUserWithoutOAuth() {
      sessionCookie.set({
        active: 2,
        expires: '2025-12-31T02:08:35.793',
        sessionId: '20d00da3-e9fb-4e1b-84f6-7c42eaf8f544',
        token: 'bdb8af9c-7373-4b53-8d74-83cea3e61061',
      });
    },
    async startOAuthAuthentication() {
      const { success, data } = await apiManager.login.authorize();

      if (!success) {
        throw new Error('Failed to start oAuth authentication');
      }

      sessionCookie.set({
        active: 1,
        expires: data.expireOn,
        sessionId: data.sessionId,
        token: data.token,
      });

      document.location.href = data.url;
    },
    async completeOAuthAuthentication({ dispatch }) {
      const { success, data } = await apiManager.login.authorize();

      sessionCookie.clear();

      if (!success || !data.token || data.status === 403) {
        throw new Error('Failed to complete oAuth Authentication');
      }

      dispatch('locale/change', data.preferredLanguage, { root: true });

      sessionCookie.set({
        active: 2,
        expires: data.expireOn,
        sessionId: data.sessionId,
        token: data.token,
      });
    },
  },
};
