import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  state() {
    return {
      entitlements: [],
    };
  },
  getters: {
    entitlements: (state) => [...state.entitlements],
    resources(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['modelE/dashboard/resources'],
        resourceCenterId.section.MODEL_E_ACCESSORY_PRICING,
        resourceCenterId.subsection.MODEL_E_ACCESSORY_PRICING,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
  mutations: {
    setEntitlements(state, entitlements) {
      state.entitlements = entitlements;
    },
  },
  actions: {
    async load({ commit }) {
      const { data: entitlements } = await apiManager.accessoryPricing.getModelEEntitlements();

      commit('setEntitlements', entitlements);
    },
  },
};
