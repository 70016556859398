<template>
  <div class="status-box-labeled">
    <StatusBox
      v-bind="$attrs"
    />
    <p class="status-box-labeled__label">
      <slot>
        {{ label }}
      </slot>
    </p>
  </div>
</template>

<script>
import StatusBox from './StatusBox.vue';

export default {
  name: 'StatusBoxLabeled',
  components: {
    StatusBox,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.status-box-labeled {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @include respond(sm-up) {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  &__label {
    display: flex;
    margin: 0;
  }
}
</style>
