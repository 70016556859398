<template>
  <div class="business-reports">
    <StandardButton
      track-event-name="download_vehicleDioPricingCounts"
      track-section-name="businessReports"
      :text="$l('reports.accordion1.button1')"
      :is-loading="isDownloadingVehicleDIOPricingReport"
      @click="downloadVehicleDIOPricingReport"
    />
    <StandardButton
      track-event-name="download_vehicleConfigurationPricing"
      track-section-name="businessReports"
      :text="$l('reports.accordion1.button4')"
      :is-loading="isDownloadingVehicleConfigurationPricingReport"
      @click="downloadVehicleConfigurationPricingReport"
    />
    <StandardButton
      track-event-name="download_vehiclePricingRules"
      track-section-name="businessReports"
      :text="$l('reports.accordion1.button3')"
      :is-loading="isDownloadingPricingRulesReport"
      @click="downloadPricingRulesReport"
    />
    <StandardButton
      track-event-name="download_activationRanking"
      track-section-name="businessReports"
      :text="$l('reports.accordion1.button5')"
      :is-loading="isDownloadingActivationRankingReport"
      @click="downloadActivationRankingReport"
    />
  </div>
</template>

<script>
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'ReportsBusiness',
  components: {
    StandardButton,
  },
  data() {
    return {
      isDownloadingVehicleDIOPricingReport: false,
      isDownloadingVehicleConfigurationPricingReport: false,
      isDownloadingPricingRulesReport: false,
      isDownloadingActivationRankingReport: false,
    };
  },
  methods: {
    showErrorBanner() {
      this.$store.dispatch('topBanner/open', {
        type: 'error',
        message: this.$l('reports.errors.reportDownloadFailed'),
      });
    },
    async downloadVehicleDIOPricingReport() {
      this.isDownloadingVehicleDIOPricingReport = true;

      try {
        await this.$store.dispatch('reports/downloadVehicleDIOPricingReport');
      } catch (error) {
        this.showErrorBanner();
      } finally {
        this.isDownloadingVehicleDIOPricingReport = false;
      }
    },
    async downloadVehicleConfigurationPricingReport() {
      this.isDownloadingVehicleConfigurationPricingReport = true;

      try {
        await this.$store.dispatch('reports/downloadVehicleConfigurationPricingReport');
      } catch (error) {
        this.showErrorBanner();
      } finally {
        this.isDownloadingVehicleConfigurationPricingReport = false;
      }
    },
    async downloadPricingRulesReport() {
      this.isDownloadingPricingRulesReport = true;

      try {
        await this.$store.dispatch('reports/downloadPricingRulesReport');
      } catch (error) {
        this.showErrorBanner();
      } finally {
        this.isDownloadingPricingRulesReport = false;
      }
    },
    async downloadActivationRankingReport() {
      this.isDownloadingActivationRankingReport = true;

      try {
        await this.$store.dispatch('reports/downloadActivationRankingReport');
      } catch (error) {
        this.showErrorBanner();
      } finally {
        this.isDownloadingActivationRankingReport = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.business-reports {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 48px 32px;
  margin: 40px 0;
}
</style>
