<template>
  <p class="coming-soon">
    <i>({{ $l('general.comingSoon') }})</i>
  </p>
</template>

<script>
export default {
  name: 'ComingSoonMessage',
};
</script>

<style lang="scss" scoped>
.coming-soon {
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
}
</style>
