import apiManager from '@/api';

export default {
  namespaced: true,
  state() {
    return {
      terms: {
        allowPhase1: false,
        latestAspx: '',
        latestAspxQuebec: '',
        latestPdf: '',
        latestPdfQuebec:'',
        lastAcceptedDate: '',
        lastAcceptedAspx: '',
        lastAcceptedPdf: '',
      },
      src: `${process.env.VUE_APP_WEBFORMS_APP_BASE_URL}static/documents`,
    };
  },
  getters: {
    src: (state) => state.src,
    terms: (state) => state.terms,
    newTermsAvailable: ({ terms }) => terms.lastAcceptedPdf !== terms.latestPdf,
    userHasAcceptedTerms(_, getters) {
      return !getters.newTermsAvailable && !!getters.terms.lastAcceptedDate;
    },
  },
  mutations: {
    setTerms(state, payload) {
      state.terms = {
        allowPhase1: payload.allowPhase1,
        latestAspx: payload.latestFileName,
        latestAspxQuebec: payload.latestFileName_Quebec,
        latestPdf: payload.latestPdf,
        latestPdfQuebec: payload.latestPdF_Quebec,
        lastAcceptedDate: payload.lastAcceptedDate,
        lastAcceptedAspx: payload.lastAcceptedFileName,
        lastAcceptedPdf: payload.lastAcceptedPdf,
      };
    },
  },
  actions: {
    async load({ rootGetters, commit }) {
      const { dealerId } = rootGetters['user/activeDealership'];
      const { success, data } = await apiManager.termsAndConditions.getModelETerms(dealerId);
      if (!success) {
        return;
      }
      if (Array.isArray(data.terms) && data.terms.length > 0) {
        commit('setTerms', data.terms[0]);
      }
    },
  },
};
