<template>
  <AppDialog
    centered
    size="md"
    :open="open"
    :title="$l('modelEDashboard.enrollment.modal.title')"
    @close="$emit('close')"
  >
    <p>{{ $l('modelEDashboard.enrollment.modal.subtitle') }}</p>
    <p>{{ $l('modelEDashboard.enrollment.modal.description') }}</p>
  </AppDialog>
</template>

<script>
import AppDialog from './common/AppDialog.vue';

export default {
  name: 'ModelEDashboardModalEnrollmentSuccessful',
  components: {
    AppDialog,
  },
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
