<template>
  <div
    class="stripe-account-status"
    :class="$store.getters['user/activeBrandName']"
  >
    <div class="stripe-account-status__application">
      <StatusBoxLabeled :status="stripeAccount.applicationStatus">
        <span
          v-text="$l('stripe.accountSetup.statusBox.application')"
          class="stripe-account-status__label"
        />
        <AppTooltip :text="$l('stripe.accountSetup.tooltip.application')" />
      </StatusBoxLabeled>
      <div
        v-if="showStatusCheck"
        class="stripe-account-status__check"
      >
        <StandardButton
          track-event-name="click_checkStatus"
          :is-loading="isLoading"
          :text="$l('stripe.accountSetup.checkStatus')"
          @click="checkStatus"
        />
        <span class="stripe-account-status__timestamp">{{ timestamp }}</span>
      </div>
    </div>
    <StatusBoxLabeled :status="stripeAccount.accountStatus">
      <div>
        <span
          v-text="$l('stripe.accountSetup.statusBox.account')"
          class="stripe-account-status__label"
        />
        <AppTooltip :text="$l('stripe.accountSetup.tooltip.account')" />
        <span
          v-text="$l('stripe.accountSetup.instructions')"
          class="stripe-account-status__instructions"
        />
      </div>
    </StatusBoxLabeled>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import StandardButton from './common/StandardButton.vue';
import StatusBoxLabeled from './StatusBoxLabeled.vue';
import AppTooltip from './common/AppTooltip.vue';

export default {
  name: 'StripeAccountSetupStatus',
  components: {
    AppTooltip,
    StandardButton,
    StatusBoxLabeled,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      dateFormatPattern: 'locale/dateFormatPattern',
      stripeAccount: 'stripe/account',
      lastStripeLoadingAt: 'stripe/lastLoadingAt',
      stripeApplicationStatus: 'stripe/aplicationStatus',
    }),
    timestamp() {
      return this.$l('stripe.accountSetup.lastCheck', {
        date: format(new Date(this.lastStripeLoadingAt), `${this.dateFormatPattern} h:ma`),
      });
    },
    showStatusCheck() {
      return this.stripeApplicationStatus.pending
        || this.stripeApplicationStatus.inprogress
        || this.stripeApplicationStatus.unknown;
    },
  },
  methods: {
    async checkStatus() {
      if (this.isLoading) return;

      this.isLoading = true;

      await this.$store.dispatch('stripe/load');

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-account-status {
  --timestamp-color: #{$fordColorPrimary2};
  --instructions-color: #{$color--fds-secondary};
  &.lincoln {
    --timestamp-color: #102139;
    --instructions-color: #{$color--lds-secondary};
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  margin: 38px 0;
  &__application {
    display: flex;
    @media (max-width: $lg-breakpoint) {
      flex-direction: column;
      gap: 32px;
    }
  }
  &__label {
    margin-right: 4px;
  }
  &__instructions {
    display: block;
    color: var(--instructions-color);
    font-size: 14px;
    font-weight: $font-weight--medium;
    font-style: italic;
    line-height: 1;
  }
  &__check {
    display: flex;
    align-items: center;
    gap: 24px;
    @include respond(lg-up) {
      border-left: 1px solid #C8C8C8;
      padding-left: 24px;
      margin-left: 24px;
    }
  }
  &__timestamp {
    color: var(--timestamp-color);
    font-size: 14px;
    font-weight: $font-weight--normal;
  }
}
</style>
