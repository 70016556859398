<template>
  <div class="fee-sales-setup container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="stripe-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <p>{{ description }}</p>
      </div>
      <div class="col-xs-12 col-sm-5 col-lg-4 fee-sales-setup__link-wrapper">
        <StandardButton
          track-event-name="click_stripeAccountSetup"
          track-section-name="stripeAccount"
          :text="buttonText"
          @click="handleStripeButtonClick"
        />
      </div>
    </div>
    <ModalAccount
      :open="showAccountModal"
      @close="showAccountModal = false"
    />
    <ModalKeyDecisionMaker
      :open="showKeyDecisionMakerModal"
      @close="showKeyDecisionMakerModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElementType, Event } from '@/constants/track';
import StandardButton from './common/StandardButton.vue';
import ModalAccount from './DashboardStripeAccountModalAccount.vue';
import ModalKeyDecisionMaker from './DashboardStripeAccountModalKeyDecisionMaker.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'DashboardStripeAccount',
  components: {
    StandardButton,
    DashboardResourcesDisplay,
    ModalAccount,
    ModalKeyDecisionMaker,
  },
  data() {
    return {
      showAccountModal: false,
      showKeyDecisionMakerModal: false,
    };
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
      activeDealership: 'user/activeDealership',
      brandName: 'user/activeBrandName',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      userCanChangeBetweenBrands: 'user/userCanChangeBetweenBrands',
      resources: 'dashboard/stripe/activeResources',
      aplicationStatus: 'stripe/aplicationStatus',
    }),
    description() {
      if (this.aplicationStatus.active) {
        return this.$l('dashboard.stripe.sectionOne.descriptionActive');
      }
      return this.$l('dashboard.stripe.sectionOne.description');
    },
    buttonText() {
      if (this.aplicationStatus.active) {
        return this.$l('dashboard.stripe.sectionOne.linkActive');
      }
      return this.$l('dashboard.stripe.sectionOne.link');
    },
  },
  watch: {
    showKeyDecisionMakerModal() {
      if (this.showKeyDecisionMakerModal) {
        this.$store.dispatch('track/event', {
          event: Event.OPEN_STRIPE_NON_KDM_MODAL,
          section: 'stripeAccount',
          elementType: ElementType.MODAL,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
  methods: {
    async handleStripeButtonClick() {
      if (this.activeDealership.stripeAccountId) {
        this.$router.push({ name: 'Stripe' });
        return;
      }

      if (!this.userIsKeyDecisionMaker) {
        this.showKeyDecisionMakerModal = true;
        return;
      }

      if (this.userCanChangeBetweenBrands) {
        this.showAccountModal = true;
        return;
      }

      await this.$store.dispatch('stripe/setupOneAccount');

      this.$router.push({ name: 'Stripe' });
    },
  },
};
</script>

<style lang="scss" scoped>
.fee-sales-setup {
  &__link-wrapper {
    display: flex;
    justify-content: center;
    @include respond(sm-up) {
      justify-content: flex-end;
    }
  }
}
</style>
