<template>
  <div class="loader">
    <div class="skeleton skeleton-text" />
  </div>
</template>

<script>
export default {
  name: 'FAQsLoader',
};
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  padding: 40px 64px;
  background-color: #f7f7f7;
  border-left: 5px solid $fordColorPrimary2;
}
</style>
