const status = {
  red: 'red',
  green: 'green',
};

export default {
  termsAndConditions: {
    message: 'dashboard.notifications.termsAndConditions',
    url: 'termsAndConditions',
    status: status.red,
    accordion: 'enrollment',
    subcordion: 'termsAndConditions',
  },
  arsNotSet: {
    message: 'dashboard.notifications.arsNotSet',
    url: 'automotiveRemarketingService',
    status: status.red,
    accordion: 'tradeIn',
    subcordion: 'ars',
  },
  customerHandling: {
    message: 'dashboard.notifications.customerHandling',
    url: 'reservationOrderRequests',
    status: status.red,
    accordion: 'customerHandling',
    subcordion: 'reservationOrderRequests',
  },
  accessoryPricingNotSet: {
    message: 'dashboard.notifications.accessoryPricingNotSet',
    url: 'accessoryPricing',
    status: status.red,
    accordion: 'accessoryPricing',
    subcordion: 'accessoryPricing',
  },
  evCertified: {
    message: 'dashboard.notifications.evCertified',
    url: '',
    status: status.green,
    accordion: '',
    subcordion: '',
  },
};
