<template>
  <div class="loading-indicator">
    <AppSpinner />
  </div>
</template>

<script>
import AppSpinner from './common/AppSpinner.vue';

export default {
  name: 'LoadingIndicator',
  components: {
    AppSpinner,
  },
};
</script>

<style lang="scss" scoped>
.loading-indicator {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
