import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { ElementType, Event } from '@/constants/track';
import { brands } from '@/constants/brands';
import sessionCookie from '@/helpers/sessionCookie';
import BrandView from '@/views/BrandView.vue';
import IneligibleView from '@/views/IneligibleView.vue';
import LoginView from '@/views/LoginView.vue';
import ImpersonateView from '@/views/ImpersonateView.vue';
import VideoView from '@/views/VideoView.vue';
import StripeApplicationView from '@/views/StripeApplicationView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import TrackView from '@/views/TrackView.vue';
import blueRoutes from './blue';
import modelERoutes from './modelE';

const routes = [
  ...blueRoutes,
  ...modelERoutes,
  {
    path: '/Brand',
    name: 'Brand',
    component: BrandView,
    meta: {
      requiresAuth: true,
      disableSaveAsLastVisited: true,
      disableCheckForBrandSelection: true,
      disableAccessTracking: true,
      header: {
        disableNavigation: true,
        disableBrandSelector: true,
        disableExperienceSelector: true,
        disableCustomAppName: true,
      },
      track: {
        pageName: 'Select_Brand',
      },
    },
  },
  {
    path: '/Ineligible',
    name: 'Ineligible',
    component: IneligibleView,
    meta: {
      disableSaveAsLastVisited: true,
      header: {
        disableNavigation: true,
        disableExperienceSelector: true,
        disableCustomAppName: true,
      },
      track: {
        pageName: 'Ineligible',
      },
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: LoginView,
    meta: {
      disableSaveAsLastVisited: true,
      disableAccessTracking: true,
      header: {
        disableNavigation: true,
        disableExperienceSelector: true,
        disableCustomAppName: true,
      },
    },
  },
  {
    path: '/Track',
    name: 'Track',
    component: TrackView,
    meta: {
      requiresAuth: true,
      disableSaveAsLastVisited: true,
      clearLastVisitedPage: true,
      disableAccessTracking: true,
    },
  },
  {
    path: '/Impersonate',
    name: 'Impersonate',
    component: ImpersonateView,
    meta: {
      requiresAuth: true,
      disableSaveAsLastVisited: true,
      clearLastVisitedPage: true,
      disableAccessTracking: true,
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['user/userCanAccessImpersonate']) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/Video/:id',
    name: 'Video',
    component: VideoView,
    meta: {
      requiresAuth: true,
      disableAccessTracking: true,
      disableCheckForBrandSelection: true,
    },
  },
  {
    path: '/StripeApplication',
    name: 'StripeApplication',
    component: StripeApplicationView,
    meta: {
      layout: 'StripeApplication',
      disableSaveAsLastVisited: true,
      clearLastVisitedPage: true,
      disableAccessTracking: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFoundView,
    meta: {
      disableSaveAsLastVisited: true,
      clearLastVisitedPage: true,
      header: {
        disableExperienceSelector: true,
        disableCustomAppName: true,
      },
      track: {
        pageName: 'Not_Found',
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, _, next) => {
  if (to.meta.clearLastVisitedPage) {
    sessionStorage.removeItem(process.env.VUE_APP_LAST_VISITED_PAGE);
  }

  if (!to.meta.disableSaveAsLastVisited) {
    sessionStorage.setItem(process.env.VUE_APP_LAST_VISITED_PAGE, to.fullPath);
  }

  const { sessionId, token } = sessionCookie.get();

  const userIsAuthenticated = sessionId && token;

  const userHasChosenBrand = store.getters['user/activeBrand'];

  const { requiresAuth, disableCheckForBrandSelection } = to.meta;

  if (to.query.brand && [brands.FORD, brands.LINCOLN].includes(to.query.brand)) {
    sessionStorage.setItem(process.env.VUE_APP_LAST_SELECTED_BRAND, to.query.brand);
  }

  if (requiresAuth && !userIsAuthenticated) {
    next({ name: 'Login' });
  } else if (requiresAuth && !userHasChosenBrand && !disableCheckForBrandSelection) {
    next({ name: 'Brand' });
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.meta.disableAccessTracking) return;

  store.dispatch('track/event', {
    event: `${Event.ACCESS_PAGE}_${to.meta.track.pageName}`,
    elementType: ElementType.NAVIGATION,
    pageName: to.meta.track.pageName,
  });
});

export default router;
