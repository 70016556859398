import { createI18n } from 'vue-i18n';
import store from '@/store';
import enCaMessages from '@/locales/en-ca';
import enCaLincolnMessages from '@/locales/en-ca-lincoln';
import frCaMessages from '@/locales/fr-ca';
import frCaLincolnMessages from '@/locales/fr-ca-lincoln';

const i18n = new createI18n({
  locale: store.getters['locale/locales'].english,
  fallbackLocale: store.getters['locale/locales'].english,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  warnHtmlInMessage: 'off',
  messages: {
    'en-ca': enCaMessages,
    'en-ca-lincoln': enCaLincolnMessages,
    'fr-ca': frCaMessages,
    'fr-ca-lincoln': frCaLincolnMessages,
  },
});

export default i18n;

const setLocale = () => {
  let locale = store.getters['locale/activeLocale'];

  if (store.getters['user/activeBrandIsLincoln']) {
    locale = `${locale}-lincoln`;
  }

  i18n.global.locale = locale;
  i18n.global.fallbackLocale = store.getters['locale/activeLocale'];
};

store.watch((state, getters) => getters['locale/activeLocale'], setLocale);
store.watch((state, getters) => getters['user/activeBrandIsLincoln'], setLocale);
