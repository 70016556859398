export default {
  title: 'Profil du concessionnaire et état de préparation',
  labelDropdown: 'Entrer le nom du concessionnaire',
  fileDate: 'Données sur les concessionnaires admissibles selon le dernier fichier GOLDD: {date}.',
  subtitle1: '[NT] Dealer Readiness Profile',
  section1Label: '[NT] eCommerce Readiness',
  meetingInfo: {
    admin: '[NT] Administered by:',
    date: '[NT] Meeting date:',
    role: 'Rôle',
    note: '[NT] Note:',
    getStarted: '[NT] Get started',
    review: '[NT] Review',
    resume: '[NT] Resume',
  },
  subtitle2: 'Profil du concessionnaire commerce électronique',
  downloadButton: 'Télécharger le profil du concessionnaire',
  dealershipInformation: {
    title: 'Renseignements sur le concessionnaire',
    titleRow1: 'Nom du concessionnaire',
    titleRow2: 'Indicatif concessionnaire',
    titleRow3: 'Concessionnaire ajouté',
    titleRow4: 'Région',
    titleRow5: 'Zone',
    titleRow6: 'Personne-ressource/personne désignée',
    titleRow7: 'Type de franchise',
    titleRow8: 'Adresse',
    titleRow9: 'Ville',
    titleRow10: 'Province',
    titleRow11: 'Code postal',
    titleRow12: 'Numéro de téléphone',
    titleRow13: 'Certifié VE',
  },
  contactInformation: {
    title: 'Personnes-ressources',
    titleColumn1: 'Rôle dans le programme',
    titleColumn2: 'Nom',
    titleColumn3: 'Rôle/titre',
    titleColumn4: 'Alertes urgentes',
    titleColumn5: 'Coordonnées',
    labelEmail: 'Courriel >',
  },
  reservationActivation: {
    title: 'Information sur la mise en œuvre des réservations',
    titleRow1: 'Inscription',
    titleRow2: 'Compte Stripe',
    titleRow3: 'État de l’expérience',
  },
  orderActivation: {
    title: 'Information sur la mise en œuvre des commandes',
    titleRow1: '@:dealerProfile.reservationActivation.titleRow1',
    titleRow2: '@:dealerProfile.reservationActivation.titleRow2',
    titleRow3: '@:dealerProfile.reservationActivation.titleRow3',
  },
  evCartAndCheckout: {
    title: '[NT] EV Cart and Checkout Activation Information',
    titleRow1: '[NT] Order Activation Pre-requisite',
    titleRow2: '@:dealerProfile.reservationActivation.titleRow1',
    titleRow3: {
      ford: '@:dealerProfile.reservationActivation.titleRow2.ford',
      lincoln: '@:dealerProfile.reservationActivation.titleRow2.lincoln',
    },
    titleRow4: 'Prix des accessoires',
    titleRow5: 'Configuration des véhicules d’échange',
    titleRow6: 'Financement et assurance (à venir bientôt)',
    titleRow7: {
      ford: 'Configuration du traitement de la clientèle (à venir bientôt)',
      lincoln: 'Configuration du traitement de la clientèle (à venir bientôt)',
    },
    titleRow8: 'Préparation de la mise en œuvre',
    titleRow9: '@:dealerProfile.reservationActivation.titleRow3',
  },
  cartAndCheckoutActivation: {
    title: 'Information sur la mise en œuvre du panier d’achats et du paiement',
    titleRow1: '@:dealerProfile.reservationActivation.titleRow1',
    titleRow2: '@:dealerProfile.reservationActivation.titleRow2',
    titleRow3: 'Prix des véhicules',
    titleRow4: '@:dealerProfile.evCartAndCheckout.titleRow4',
    titleRow5: '@:dealerProfile.evCartAndCheckout.titleRow5',
    titleRow6: '@:dealerProfile.evCartAndCheckout.titleRow6',
    titleRow7: {
      ford: '@:dealerProfile.evCartAndCheckout.titleRow7.ford',
      lincoln: '@:dealerProfile.evCartAndCheckout.titleRow7.lincoln',
    },
    titleRow8: '@:dealerProfile.evCartAndCheckout.titleRow8',
    titleRow9: '@:dealerProfile.reservationActivation.titleRow3',
  },
  cartAndCheckoutAdvanced: {
    title: 'Options avancées du panier d’achats et du paiement',
    titleRow1: '[NT] MaximTrak (F&I Products)',
    titleRow2: '[NT] Alternate Lenders',
    titleRow3: 'Préférence de livraison à distance',
    titleRow4: '[NT] Advertised Plan Pricing(MI, OH, and Ky Dealers Only)',
  },
};
