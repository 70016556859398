<template>
  <div
    class="stripe-account-setup"
  >
    <SubsectionHeader>{{ $l('stripeAccountFeeSetup.text1') }}</SubsectionHeader>
    <div class="row">
      <div class="col-md-9">
        <p v-if="applicationStatus === 'pending'">
          {{ $l("stripeAccountFeeSetup.p5") }}
        </p>
        <p v-else-if="applicationStatus === 'unknown'">
          {{ $l("stripeAccountFeeSetup.p10") }}
        </p>
        <template v-else-if="applicationStatus === 'active'">
          <p>{{ $l("stripeAccountFeeSetup.p6") }}</p>
          <p>{{ $l("stripeAccountFeeSetup.p7") }}</p>
        </template>
        <template v-else>
          <p>{{ $l("stripeAccountFeeSetup.p1") }}</p>
          <p>{{ $l("stripeAccountFeeSetup.p2") }}</p>
        </template>
        <div class="stripe-account-fee-setup__status-container">
          <div
            class="status"
            style="display: flex; flex-wrap: nowrap;"
          >
            <StatusBox
              theme="model-e"
              :status="applicationStatus"
            />
            <span class="status-text">{{
              $l("stripeAccountFeeSetup.text2")
            }}</span>
            <PopupTip
              title="Stripe Application"
              :text="$l('stripeAccountFeeSetup.text3')"
              class="status-tip"
            />
          </div>
          <template
            v-if="
              applicationStatus === 'inprogress' ||
                applicationStatus === 'pending' ||
                applicationStatus === 'unknown'
            "
          >
            <div class="divider" />
            <div
              class="lastChecked"
              style="display: flex; flex-wrap: nowrap;"
            >
              <StandardButton
                text="Check Status"
                track-event-name="click_checkStatus"
                :disabled="checkingStatus"
                :is-loading="checkingStatus"
                @click="checkStatus"
              />
              <span
                class="status-check-text"
              >{{ $l("stripeAccountFeeSetup.text4") }}
                {{ lastChecked }} EST</span>
            </div>
          </template>
        </div>
        <div
          class="stripe-account-fee-setup__status-container"
          style="display: flex; flex-wrap: nowrap;"
        >
          <StatusBox
            theme="model-e"
            :status="accountStatus"
          />
          <span class="status-text">
            {{ $l("stripeAccountFeeSetup.text5") }}<br />
            <a> {{ $l("stripeAccountFeeSetup.text7") }}</a>
          </span>
          <PopupTip
            title="Stripe Account and Fees"
            :text="$l('stripeAccountFeeSetup.text6')"
            class="status-tip"
          />
        </div>
        <div v-if=" applicationStatus === 'active' && accountStatus === 'incomplete'">
          <p class="mt-4">
            {{ $l("stripeAccountFeeSetup.p8") }}
          </p>
        </div>
        <div v-if=" applicationStatus !== 'active' && applicationStatus !== 'notstarted'">
          <p
            v-if="applicationStatus !== 'unknown'"
            class="mt-4"
          >
            {{ $l("stripeAccountFeeSetup.p3") }}
          </p>
          <p class="mt-2">
            {{ $l("stripeAccountFeeSetup.p4") }}
          </p>
        </div>
        <div v-if="applicationStatus === 'notstarted'">
          <p class="mt-4">
            {{ $l("stripeAccountFeeSetup.p9") }}
          </p>
          <p class="mt-2">
            {{ $l("stripeAccountFeeSetup.p4") }}
          </p>
        </div>
      </div>
      <div class="col-md-3">
        <div style="text-align: center">
          <StandardButton
            track-event-name="click_stripeApplication"
            :text="
              applicationStatus !== 'active'
                ? $l('stripeAccountFeeSetup.btn1')
                : $l('stripeAccountFeeSetup.btn2')
            "
            :disabled="!userIsKeyDecisionMaker || isLoading"
            :is-loading="isLoading"
            @click="startStripeApplication()"
          />
          <br />
          <br />
          <TextButton
            track-event-name="click_setupTips"
            :text="$l('stripeAccountFeeSetup.btn3')"
            @click="dlgStripeTip = true"
          />
        </div>
      </div>
    </div>
    <DashboardResourcesDisplay
      id="stripe-document"
      :resources="resources"
      :render-divider="false"
    />
    <hr class="stripe-account-setup__divider" />
    <AppDialog
      size="md"
      :open="dlgOnHold"
      :title="$l('stripeAccountFeeSetup.dlgtext1')"
      @close="dlgOnHold = false"
    >
      <p>{{ $l('stripeAccountFeeSetup.dlgtext2') }}</p>
      {{ $l("stripeAccountFeeSetup.dlgtext3") }}
      <br />
      <a
        class="stripe-account-setup__link"
        href="https://support.stripe.com/contact/email"
        target="_blank"
      >https://support.stripe.com/contact/email</a>
    </AppDialog>
    <AppDialog
      size="md"
      :open="dlgStripeTip"
      :title="$l('stripeAccountFeeSetup.dlgtext4')"
      @close="dlgStripeTip = false"
    >
      <p>{{ $l('stripeAccountFeeSetup.dlgtext5') }}</p>
      <ul class="prompt-stripe-tip">
        <li>{{ $l("stripeAccountFeeSetup.dlgtext6") }}</li>
        <li>{{ $l("stripeAccountFeeSetup.dlgtext7") }}</li>
        <li>{{ $l("stripeAccountFeeSetup.dlgtext8") }}</li>
        <li>{{ $l("stripeAccountFeeSetup.dlgtext9") }}</li>
        <li>{{ $l("stripeAccountFeeSetup.dlgtext10") }}</li>
      </ul>
    </AppDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import TextButton from './common/TextButton.vue';
import AppDialog from './common/AppDialog.vue';
import PopupTip from './common/PopUpTip.vue';
import StandardButton from './common/StandardButton.vue';
import StatusBox from './StatusBox.vue';
import SubsectionHeader from './StripeSubsectionHeader.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEStripeAccountSetup',
  components: {
    StandardButton,
    StatusBox,
    TextButton,
    SubsectionHeader,
    PopupTip,
    AppDialog,
    DashboardResourcesDisplay,
  },
  data() {
    return {
      timer: 0,
      intervalId: 0,
      dlgOnHold: false,
      dlgStripeTip: false,
      isLoading: false,
      checkingStatus: false,
      documents: [],
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      resources: 'modelE/dashboard/stripe/activeResources',
    }),
    applicationStatus() {
      return this.$store.state.modelE.stripe.accountInfo.applicationStatus;
    },
    accountStatus() {
      return this.$store.state.modelE.stripe.accountInfo.accountStatus;
    },
  },
  async mounted() {
    this.$store.dispatch('modelE/dashboard/getResources');
  },
  created() {
    this.getDocuments();
    this.lastChecked = window.localStorage.getItem('stripeCheck');
    this.$store.dispatch('modelE/stripe/getStripeData', 'F');
    // // wait 2 sec BEFORE pinging api every 30 sec
    window.setTimeout(() => {
      if (this.applicationStatus === 'pending') {
        this.$store.dispatch('modelE/stripe/getStripeData', 'F')
          .then(() => {
            this.dlgOnHold = this.applicationStatus === 'onhold';
          });
      } else if (this.applicationStatus === 'onhold') {
        this.dlgOnHold = true;
      }
    }, 2000);
  },
  beforeUnmount() {
    window.clearInterval(this.intervalId);
    this.intervalId = 0;
  },
  methods: {
    async getDocuments() {
      this.documents = await apiManager.resourceCenter.getResults();
    },
    checkStatus() {
      this.checkingStatus = true;
      this.checkDate();
      window.localStorage.setItem('stripeCheck', this.lastChecked);
      this.$store
        .dispatch('modelE/stripe/getStripeData', 'F')
        .then(() => {
          this.checkingStatus = false;

          if (this.applicationStatus === 'complete') {
            window.localStorage.removeItem('stripeCheck');
          }
          if (this.applicationStatus === 'onhold') {
            window.localStorage.removeItem('stripeCheck');
            this.dlgOnHold = true;
          }
        });
    },
    checkDate() {
      const date = new Date();
      // eslint-disable-next-line prefer-template
      const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      // eslint-disable-next-line prefer-template
      const day = (date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate());
      const hh = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      const hours = date.getHours() === 0 ? '12' : hh;
      const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
      const ampm = date.getHours() < 12 ? 'AM' : 'PM';
      // eslint-disable-next-line prefer-template
      const formattedTime = hours + ':' + minutes + '' + ampm;
      // eslint-disable-next-line prefer-template
      this.lastChecked = month + '/' + day + ' ' + formattedTime;
    },

    async startStripeApplication() {
      this.isLoading = true;
      try {
        const url = await this.$store.dispatch('modelE/stripe/getAccountUrl');

        window.open(url, '_blank');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }

      this.checkStatus();
    },
  },
};
</script>

<style lang="scss" scoped>
a, a:hover {
  color: var(--app-color--secondary);
  font-weight: bold;
  font-style: italic;
}
.stripe-account-setup {
  &__divider {
    margin-top: 50px;
    margin-bottom: 40px;
    background-color: #707070;
  }
  .status-text {
    font-weight: $font-weight--medium;
    font-size: fds-rem(16px);
    letter-spacing: 1px;
    line-height: 30px;
  }
  .lastChecked {
    @media only screen and (max-width: 430px)  {
      display: flex;
      margin-top: 20px;
    }
    @include respond(md-dn) {
      margin-top: 20px;
    }
  }
  .stripe-account-fee-setup__status-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: nowrap;
    @media only screen and (max-width: 430px)  {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .status-text {
    margin-left: 10px;
  }
  .status-tip {
    color: var(--app-color--secondary);
  }
  .divider {
    border-left: 1px solid #c8c8c8;
    margin-left: 10px;
    margin-right: 15px;
    @media only screen and (max-width: 430px)  {
        display: none;
    }
  }
  .status-timer {
    color: $color--fds-caution1;
    margin-left: 20px;
  }

  .status-check-text {
    margin-left: 15px;
    color: var(--app-color--primary);
    font-size: fds-rem(16px);
    font-weight: $font-weight--normal;
  }

  .status-text-small {
    font-size: fds-rem(14px);
    font-weight: $font-weight--medium;
  }

  .prompt-stripe-tip {
    li {
      margin: 20px 0px;
    }
  }
  .mt-4 {
    margin-top: 40px;
  }
  .stripe-account-setup__link {
    color: var(--app-color--secondary);

    &:hover {
      text-decoration: underline !important;
    }
  }
}
</style>
