<template>
  <section class="stripe-section">
    <header
      class="stripe-section__header"
      :class="$store.getters['user/activeBrandName']"
    >
      {{ $l('stripe.sectionTitle') }}
    </header>
    <div class="stripe-section__content">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'StripeSection',
};
</script>

<style lang="scss" scoped>
.stripe-section {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 14px;
    color: $color--white;
    font-weight: $font-weight--normal;
    margin-bottom: 37px;
    &.ford {
      background-color: $color--fds-primary;
    }
    &.lincoln {
      background-color: $color--lds-primary;
    }
  }
  &__content {
    width: 100%;
    max-width: 1170px;
    margin: auto;
    padding: 0 16px;
  }
}
</style>
