export default {
  title: 'Reports and Field Tools',
  business: {
    title: 'Business Reports',
    description: '*Coming Soon*',
  },
  dealerVisits: {
    title: 'Dealer Visit Reports',
    description: '*Coming Soon*',
  },
  dealerStatus: {
    title: 'Dealer Status Reports',
    description: 'Dealer completion status of all enrolment and activation steps',
  },
  dealerReadiness: {
    title: 'Dealer Readiness Reports',
    description: '*Coming Soon*',
  },
  customerHandlingTools: {
    title: 'Customer Handling Tools',
    description: '*Coming Soon*',
  },
  activationTools: {
    title: 'Activation Tools',
    description: '*Coming Soon*',
  },
};
