<template>
  <div class="ars-reports">
    <StandardButton
      track-event-name="download_arsEnrollmentStatus"
      track-section-name="arsReports"
      :text="$l('reports.accordion2.button1')"
      :is-loading="isDownloadingReport"
      @click="downloadReport"
    />
  </div>
</template>

<script>
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'ReportsARSEnrollment',
  components: {
    StandardButton,
  },
  data() {
    return {
      isDownloadingReport: false,
    };
  },
  methods: {
    async downloadReport() {
      this.isDownloadingReport = true;

      try {
        await this.$store.dispatch('reports/downloadARSEnrollmentReport');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('reports.errors.reportDownloadFailed'),
        });
      } finally {
        this.isDownloadingReport = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ars-reports {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
</style>
