import apiManager from '@/api';
import { status } from '@/constants/activationSummary';

const normalizeStatus = (value) => {
  switch (value) {
    case 'Active':
      return status.ACTIVE;
    case 'Opt-Out initiated':
      return status.OPT_OUT;
    case 'In Active':
      return status.INACTIVE;
    case 'not available':
      return status.NOT_AVAILABLE;
    case 'In Progress':
    default:
      return status.IN_PROGRESS;
  }
};

const normalizeData = (data) => ({
  enrollmentStatus: data.enrollmentStatus,
  stripeSetupStatus: data.stripeSetupStatus,
  percentageComplete: data.percentageComplete,
  status: normalizeStatus(data.overallStatusText),
  deactivation: {
    startDate: data.deactivationStartDate,
    endDate: data.deactivationEndDate,
  },
});

const normalizeCartCheckoutData = (data) => ({
  enrollmentStatus: data.enrollmentStatus,
  stripeSetupStatus: data.stripeSetupStatus,
  vehiclePricingStatus: data.vehiclePricingStatus,
  accessoryPricingStatus: data.accessoryPricingStatus,
  routeOneStatus: data.routeOneStatus,
  tradeInStatus: data.tradeInSetupStatus,
  fniSetupStatus: data.fniSetupStatus,
  customerHandlingStatus: data.customerHandlingSetupStatus,
  percentageComplete: data.percentageComplete,
  status: normalizeStatus(data.overallStatusText),
  deactivation: {
    startDate: data.deactivationStartDate,
    endDate: data.deactivationEndDate,
  },
});

export default {
  namespaced: true,
  state() {
    return {
      reservation: {
        ford: {},
        lincoln: {},
      },
      order: {
        ford: {},
        lincoln: {},
      },
      cartCheckout: {
        ford: {},
        lincoln: {},
      },
    };
  },
  getters: {
    reservation({ reservation }, getters, rootState, rootGetters) {
      return rootGetters['user/activeBrandIsFord'] ? reservation.ford : reservation.lincoln;
    },
    order({ order }, getters, rootState, rootGetters) {
      return rootGetters['user/activeBrandIsFord'] ? order.ford : order.lincoln;
    },
    cartCheckout({ cartCheckout }, getters, rootState, rootGetters) {
      return rootGetters['user/activeBrandIsFord'] ? cartCheckout.ford : cartCheckout.lincoln;
    },
  },
  mutations: {
    setReservation(state, { ford, lincoln }) {
      if (ford) {
        state.reservation.ford = normalizeData(ford);
      }

      if (lincoln) {
        state.reservation.lincoln = normalizeData(lincoln);
      }
    },
    setOrder(state, { ford, lincoln }) {
      if (ford) {
        state.order.ford = normalizeData(ford);
      }

      if (lincoln) {
        state.order.lincoln = normalizeData(lincoln);
      }
    },
    setCartCheckout(state, { ford, lincoln }) {
      if (ford) {
        state.cartCheckout.ford = normalizeCartCheckoutData(ford);
      }

      if (lincoln) {
        state.cartCheckout.lincoln = normalizeCartCheckoutData(lincoln);
      }
    },
  },
  actions: {
    async load({ commit }) {
      const { data: [fordData, lincolnData] } = await apiManager.activationSummary.get();

      commit('setReservation', {
        ford: fordData?.dealerReservationActivation,
        lincoln: lincolnData?.dealerReservationActivation,
      });

      commit('setOrder', {
        ford: fordData?.dealerOrderActivation,
        lincoln: lincolnData?.dealerOrderActivation,
      });

      commit('setCartCheckout', {
        ford: fordData?.dealerCartCheckout,
        lincoln: lincolnData?.dealerCartCheckout,
      });
    },
    async updateProgress({ dispatch, rootGetters }, step) {
      const { dealerId } = rootGetters['user/activeDealership'];

      await apiManager.activationSummary.updateProgress({
        dealerId,
        step,
      });

      dispatch('load');
    },
    async updateEnrollmentProgress({ dispatch }, step) {
      const progress = await dispatch(
        'dashboard/enrollment/updateProgress',
        step,
        { root: true },
      );

      dispatch('load');

      return progress;
    },
    async deactivateExperience({ rootGetters, dispatch }, { experienceId }) {
      const { dealerId } = rootGetters['user/activeDealership'];

      await apiManager.activationSummary.post({
        type: experienceId,
        from: 'active',
        to: 'inactive',
        dealerId,
      });

      return dispatch('load');
    },
    async cancelExperienceOptOut({ rootGetters, dispatch }, { experienceId }) {
      const { dealerId } = rootGetters['user/activeDealership'];

      await apiManager.activationSummary.post({
        type: experienceId,
        from: 'optoutinitiated',
        to: 'active',
        dealerId,
      });

      return dispatch('load');
    },
    async reactivateExperience({ rootGetters, dispatch }, { experienceId }) {
      const { dealerId } = rootGetters['user/activeDealership'];

      await apiManager.activationSummary.post({
        type: experienceId,
        from: 'inactive',
        to: 'active',
        dealerId,
      });

      return dispatch('load');
    },
  },
};
