<template>
  <div class="vehicle-pricing | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="vehicle-pricing-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-sm-9">
        <p>{{ $l('modelEDashboard.vehiclePricing.p1') }}</p>
      </div>
      <div class="vehicle-pricing__button-wrapper | col-md-3">
        <StandardButton
          to=""
          track-event-name="click_setVehiclePricing"
          track-section-name="vehiclePricing"
          :text="$l('modelEDashboard.vehiclePricing.button')"
        />
      </div>
    </div>
    <DashboardDivider />
    <div class="row">
      <div class="col-sm-9">
        <p>{{ $l('modelEDashboard.vehiclePricing.entitlements.p1') }}</p>
      </div>
      <div class="vehicle-pricing__button-wrapper | col-md-3">
        <StandardButton
          track-event-name="click_setEntitlements"
          track-section-name="vehiclePricing"
          to="https://www.accessmgmt.dealerconnection.com/CspsWebJSF/xhtml/requestAccess/requestAccess.faces"
          :text="$l('modelEDashboard.vehiclePricing.entitlements.button')"
        />
      </div>
    </div>
    <div class="vehicle-pricing__sheet-wrapper">
      <ModelESheet>
        <b>{{ $l('modelEDashboard.vehiclePricing.entitlements.label') }}</b>
      </ModelESheet>
      <AppToast
        v-if="entitlements.length === 0"
        class="vehicle-pricing__toast--error"
        theme="model-e"
        type="error"
        :text="$l('modelEDashboard.vehiclePricing.entitlements.empty')"
      />
      <span v-else>
        {{ entitlements.join(', ') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppToast from './common/AppToast.vue';
import StandardButton from './common/StandardButton.vue';
import DashboardDivider from './DashboardDivider.vue';
import ModelESheet from './ModelESheet.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEDashboardVehiclePricing',
  components: {
    AppToast,
    StandardButton,
    DashboardDivider,
    ModelESheet,
    DashboardResourcesDisplay,
  },
  computed: {
    ...mapGetters({
      resources: 'modelE/dashboard/vehiclePricing/activeResources',
      entitlements: 'modelE/dashboard/vehiclePricing/entitlements',
      pricingSource: 'modelE/dashboard/vehiclePricing/pricingSource',
    }),
    msrpPercentage() {
      return (this.pricingSource.pricingPercentage)
        ? 100 - this.pricingSource.pricingPercentage
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicle-pricing {
  &__button-wrapper {
    margin-top: 24px;
    @include respond(md-up) {
      margin-top: 0;
      text-align: right;
    }
  }
  &__data {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    > p {
      margin: 0;
    }
    .color-success {
      color: #008000;
    }
  }
  &__sheet-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 32px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
    text-align: center;
  }
  &__toast--error {
    border: none;
  }
}
</style>
