<template>
  <AppAccordion
    :id="accordionId"
    :status="accordionStatus"
    :title="$l('modelEDashboard.protectionPlans.title')"
    :description="$l('modelEDashboard.protectionPlans.description')"
    color="primary-light"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';

export default {
  name: 'ModelEAccordionProtectionPlans',
  components: {
    AppAccordion,
  },
  computed: {
    ...mapGetters({
      accordions: 'modelE/dashboard/accordions/accordions',
    }),
    accordionId() {
      return this.accordions.protectionPlans.id;
    },
    accordionStatus() {
      return 'comingsoon';
    },
  },
};
</script>
