export default {
  DASHBOARD: 1,
  RESOURCE_CENTER: 2,
  REPORTS: 3,
  MEETING_CAMPAIGN: 4,
  CUSTOMER_JOURNEY: 5,
  HOME: 6,
  COMMCENTER: 7,
  MODEL_E_DASHBOARD: 17,
  MODEL_E_RESOURCE_CENTER: 18,
  MODEL_E_REPORTS: 19,
  MODEL_E_MEETING_CAMPAIGN: 20,
  MODEL_E_CUSTOMER_JOURNEY: 21,
  MODEL_E_HOME: 22,
  MODEL_E_COMMCENTER: 23,
  MODEL_E_STRIPE: 24,
};
