<template>
  <div class="vehicle-pricing container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="vehicle-pricing-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <div v-html="$l('dashboard.vehiclePricing.sectionOne.description')" />
      </div>
      <div class="sq-btn-wrapper col-xs-12 col-sm-5 col-lg-4 d-flex justify-center">
        <StandardButton
          to="https://www.vlplus.dealerconnection.com/"
          track-event-name="click_setVehiclePricing"
          track-section-name="vehiclePricing"
          :text="$l('dashboard.vehiclePricing.sectionOne.linkOne')"
        />
      </div>
    </div>
    <div class="vehicle-pricing__data">
      <p>
        {{ $l('dashboard.vehiclePricing.sectionOne.labelOne') }}
        <span
          v-text="pricingSourceText"
          :class="sourceClasses"
        />
      </p>
      <div>
        <span>{{ $l('dashboard.vehiclePricing.sectionOne.labelTwo') }}</span>
        <StatusBox
          class="vehicle-pricing__validation-status"
          :status="pricingSource.isValid ? 'complete' : 'incomplete'"
        />
      </div>
      <p>
        {{ $l('dashboard.vehiclePricing.sectionOne.labelThree') }}
        <span :class="pricingSource.percentage > 69 ? 'color-success' : 'color-danger'">
          {{ pricingSource.percentage }}%
        </span>
      </p>
      <small>{{ $l('dashboard.vehiclePricing.sectionOne.smallOne') }}</small>
    </div>
    <DashboardDivider />
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-lg-8">
        <p>{{ $l('dashboard.vehiclePricing.sectionOne.setEntitlements') }}</p>
      </div>
      <div class="sq-btn-wrapper col-xs-12 col-sm-5 col-lg-4 d-flex justify-center">
        <StandardButton
          track-event-name="click_setEntitlements"
          track-section-name="vehiclePricing"
          to="https://www.accessmgmt.dealerconnection.com/CspsWebJSF/xhtml/requestAccess/requestAccess.faces"
          :text="$l('dashboard.vehiclePricing.sectionOne.linkTwo')"
        />
      </div>
    </div>
    <div class="vehicle-pricing__entitlements-wrapper">
      <AppToast
        v-if="entitlements.length > 0"
        :text="entitlements.join(', ')"
      />
      <AppToast
        v-else
        type="error"
        :text="$l('dashboard.vehiclePricing.sectionOne.errorToast')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import AppToast from './common/AppToast.vue';
import DashboardDivider from './DashboardDivider.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';
import StatusBox from './StatusBox.vue';

export default {
  name: 'DashboardVehiclePricing',
  components: {
    AppToast,
    StandardButton,
    DashboardDivider,
    DashboardResourcesDisplay,
    StatusBox,
  },
  computed: {
    ...mapGetters({
      resources: 'dashboard/vehiclePricing/activeResources',
      entitlements: 'dashboard/vehiclePricing/entitlements',
      pricingSource: 'dashboard/vehiclePricing/pricingSource',
    }),
    pricingSourceText() {
      if (this.pricingSource.isPricingDataInterrupted) {
        return this.$l('dashboard.vehiclePricing.sectionOne.interruption');
      }

      return this.pricingSource.source;
    },
    sourceClasses() {
      if (this.pricingSource.isPricingDataInterrupted) {
        return ['color-danger'];
      }

      if (!this.pricingSource.source) {
        return ['color-neutral'];
      }

      return ['color-success'];
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicle-pricing {
  .color-danger {
    color: $color--fds-error1;
  }
  .color-neutral {
    color: $color--fds-gray2;
  }
  .color-success {
    color: $color--fds-success1;
  }
  &__data {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    > p {
      margin: 0;
    }
  }
  &__validation-status {
    display: inline-flex;
    margin-left: 8px;
  }
  &__entitlements-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }
}
</style>
