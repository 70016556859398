<template>
  <div class="accessory-pricing | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="accessory-pricing-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-md-9">
        <p>{{ $l('modelEDashboard.accessoryPricing.p1') }}</p>
        <p>{{ $l('modelEDashboard.accessoryPricing.p2') }}</p>
      </div>
      <div class="accessory-pricing__button-wrapper | col-md-3">
        <StandardButton
          track-event-name="click_setAccessoryPrincing"
          track-section-name="accessoryPricing"
          :to="linkPath"
          :text="$l('modelEDashboard.accessoryPricing.buttonPricing')"
        />
      </div>
    </div>
    <DashboardDivider />
    <div class="row">
      <div class="col-md-9">
        <p>{{ $l('modelEDashboard.accessoryPricing.p3') }}</p>
      </div>
      <div class="accessory-pricing__button-wrapper | col-md-3">
        <StandardButton
          track-event-name="click_setEntitlements"
          track-section-name="accessoryPricing"
          to="https://www.accessmgmt.dealerconnection.com/CspsWebJSF/xhtml/requestAccess/requestAccess.faces"
          :text="$l('modelEDashboard.accessoryPricing.buttonEntitlements')"
        />
      </div>
    </div>
    <div class="accessory-pricing__sheet-wrapper">
      <ModelESheet>
        <b>{{ $l('modelEDashboard.accessoryPricing.entitlementsTitle') }}</b>
      </ModelESheet>
      <AppToast
        v-if="entitlements.length === 0"
        class="accessory-pricing__toast--error"
        theme="model-e"
        type="error"
        :text="$l('modelEDashboard.accessoryPricing.noEntitlements')"
      />
      <span v-else>
        {{ entitlements.join(', ') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppToast from './common/AppToast.vue';
import StandardButton from './common/StandardButton.vue';
import DashboardDivider from './DashboardDivider.vue';
import ModelESheet from './ModelESheet.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEDashboardAccessoryPricing',
  components: {
    AppToast,
    StandardButton,
    DashboardDivider,
    ModelESheet,
    DashboardResourcesDisplay,
  },
  computed: {
    ...mapGetters({
      activeBrand: 'user/activeBrand',
      activeDealership: 'user/activeDealership',
      resources: 'modelE/dashboard/accessoryPricing/activeResources',
      entitlements: 'modelE/dashboard/accessoryPricing/entitlements',
    }),
    linkPath() {
      return `https://www.emp.dealerconnection.com/#page=accessories&brand=${this.activeBrand}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.accessory-pricing {
  &__button-wrapper {
    margin-top: 24px;
    @include respond(md-up) {
      margin-top: 0;
      text-align: right;
    }
  }
  &__sheet-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 32px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
    text-align: center;
  }
  &__toast--error {
    border: none;
  }
}
</style>
