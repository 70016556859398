<template>
  <section
    id="operationalize"
    class="operationalize"
  >
    <h2 class="operationalize__title fds-headline__5">
      {{ $l('resourceOperationalize.title') }}
    </h2>
    <div class="row">
      <div class="operationalize__content col-xs-12 col-lg-11">
        <div class="operationalize__image-wrapper">
          <img
            class="operationalize__image"
            alt="Manager's toolkit"
            src="@/assets/images/toolkit1.png"
          />
          <img
            class="operationalize__image"
            alt="Manager's toolkit"
            src="@/assets/images/toolkit2.png"
          />
        </div>
        <h3 class="operationalize__coming-soon">
          {{ $l('general.comingSoon') }}
        </h3>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.operationalize {
  &__title {
    margin: 0;
    margin-bottom: 40px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include respond(sm-up) {
      flex-direction: row;
    }
  }
  &__image-wrapper {
    max-width: 350px;
  }
  &__image {
    width: 100%;
    max-width: inherit;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
  &__coming-soon {
    margin: auto;
    color: $color--fds-primary;
  }
}
</style>
