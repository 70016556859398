<template>
  <div>
    <PageHeader :title="$l('reports.title')" />
    <div class="reports-page">
      <AppAccordion
        id="business-reports"
        :title="$l('reports.accordion1.title')"
        :is-open="activeAccordionId === 1"
        @click="openAccordion(1)"
      >
        <ReportsBusiness />
      </AppAccordion>
      <AppAccordion
        id="ars-enrollment-reports"
        :title="$l('reports.accordion2.title')"
        :is-open="activeAccordionId === 2"
        @click="openAccordion(2)"
      >
        <ReportsARSEnrollment />
      </AppAccordion>
      <AppAccordion
        id="dealer-status-reports"
        :title="activeFeatures.powerBi ? $l('reports.accordion3.title2') : $l('reports.accordion3.title')"
        :is-open="activeAccordionId === 3"
        @click="openAccordion(3)"
      >
        <ReportsDealerStatus />
      </AppAccordion>
      <AppAccordion
        id="dealer-readiness-reports"
        :title="$l('reports.accordion4.title')"
        :is-open="activeAccordionId === 4"
        @click="openAccordion(4)"
      >
        <ComingSoonMessage />
        <!-- <ReportsDealerReadiness
          :activeBrandIsFord="activeBrandIsFord"
          :currentLocale="currentLocale"
        /> -->
      </AppAccordion>
      <AppAccordion
        id="customer-handling-tools"
        :title="$l('reports.accordion5.title')"
        :is-open="activeAccordionId === 5"
        @click="openAccordion(5)"
      >
        <ComingSoonMessage />
        <!-- <ReportsCustomerHandlingTools /> -->
      </AppAccordion>
      <AppAccordion
        id="activation-tools"
        :title="$l('reports.accordion6.title')"
        :is-open="activeAccordionId === 6"
        @click="openAccordion(6)"
      >
        <ReportsActivationTools />
      </AppAccordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import AppAccordion from '@/components/common/AppAccordion.vue';
import ReportsBusiness from '@/components/ReportsBusiness.vue';
import ReportsARSEnrollment from '@/components/ReportsARSEnrollment.vue';
import ReportsDealerStatus from '@/components/ReportsDealerStatus.vue';
// import ReportsDealerReadiness from '@/components/ReportsDealerReadiness.vue';
// import ReportsCustomerHandlingTools from '@/components/ReportsCustomerHandlingTools.vue';
import ReportsActivationTools from '@/components/ReportsActivationTools.vue';
import ComingSoonMessage from '@/components/ComingSoonMessage.vue';

export default {
  name: 'ReportsView',
  components: {
    PageHeader,
    ReportsBusiness,
    ReportsARSEnrollment,
    ReportsDealerStatus,
    // ReportsDealerReadiness,
    // ReportsCustomerHandlingTools,
    ReportsActivationTools,
    AppAccordion,
    ComingSoonMessage,
  },
  data() {
    return {
      activeAccordionId: null,
    };
  },
  computed: {
    ...mapGetters({
      currentLocale: 'locale/activeLocale',
      activeBrandIsFord: 'user/activeBrandIsFord',
      activeFeatures: 'features/activeFeatures',
    }),
    permissions() {
      const profile = this.$store.getters['user/userProfile'];
      const permissions = {
        businessReport: false,
        arsReport: false,
        dealerProfile: false,
      };
      for (let a = 0; a < profile.reports.length; a += 1) {
        if (profile.reports[a] === 'BusinessReport') {
          permissions.businessReport = true;
        }
        if (profile.reports[a] === 'ARSReport') {
          permissions.arsReport = true;
        }
        if (profile.reports[a] === 'DealerProfile') {
          permissions.dealerProfile = true;
        }
      }
      return permissions;
    },
  },
  mounted() {
    const { reports } = this.$store.getters['user/userProfile'];

    if (reports.length === 0) {
      this.$router.push({ name: 'Login' });
    }

    this.$store.dispatch('reports/getResources');
    this.$store.dispatch('reports/getRegionOptions');
    this.$store.dispatch('reports/getMarketOptions');
  },
  methods: {
    openAccordion(accordionId) {
      if (this.activeAccordionId === accordionId) {
        this.activeAccordionId = null;
      } else {
        this.activeAccordionId = accordionId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reports-page {
  max-width: 1340px;
  margin: 0 auto;
}
</style>
