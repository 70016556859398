<template>
  <section class="customer-journey">
    <h2 class="customer-journey__title fds-headline__5">
      {{ $l('resourceCenter.journey.title') }}
    </h2>
    <div class="row">
      <div class="customer-journey__content col-xs-12 col-lg-11">
        <img
          class="customer-journey__image"
          src="@/assets/images/journey.jpg"
          alt="Customer Journey"
        />
        <h3 class="customer-journey__coming-soon">
          {{ $l('general.comingSoon') }}
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ResourceCenterJourney',
};
</script>

<style lang="scss">
.customer-journey {
  &__title {
    margin: 0;
    margin-bottom: 40px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include respond(sm-up) {
      flex-direction: row;
    }
  }
  &__image {
    border: 1px solid rgba($color--black, 0.6);
    width: 100%;
    max-width: 350px;
  }
  &__coming-soon {
    margin: auto;
  }
}
</style>
