import BaseAPI from './baseAPI';

export default class TradeIn extends BaseAPI {
  async getEntitlements() {
    const { data } = await this.axios.get('/TradeIn/Entitlements');
    return data;
  }

  async getEnrolledStatus() {
    const { data } = await this.axios.get('/TradeIn/EnrolledStatus');
    return data;
  }

  async getModelEEntitlements() {
    const { data } = await this.axios.get('/TradeInModelE/Entitlements');
    return data;
  }

  async getModelEEnrolledStatus() {
    const { data } = await this.axios.get('/TradeInModelE/EnrolledStatusModelE');
    return data;
  }

  async getModelETradeIn(dealerID) {
    const { data } = await this.getUnique('/TradeInModelE', {
      params: { dealerID },
    });
    return data;
  }

  async saveModelETradeInPreference({ dealerId, optIn }) {
    const { data } = await this.postUnique('TradeInModelE', {
      dealerID: dealerId,
      trade_OptIn: optIn,
    });
    return data;
  }
}
