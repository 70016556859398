import activationSummary from './translations/activationSummary';
import commsCenter from './translations/commsCenter';
import dashboard from './translations/dashboard';
import dealerInformation from './translations/dealerInformation';
import dealerPersonnel from './translations/dealerPersonnel';
import dealerProfile from './translations/dealerProfile';
import faqs from './translations/faqs';
import footer from './translations/footer';
import header from './translations/header';
import help from './translations/help';
import home from './translations/home';
import impersonate from './translations/impersonate';
import keyDecisionMaker from './translations/keyDecisionMaker';
import readinessCheck from './translations/readinessCheck';
import reports from './translations/reports';
import resourceCenter from './translations/resourceCenter';
import resourcesPathJourneys from './translations/resourcesPathJourneys';
import selectExperience from './translations/selectExperience';
import start from './translations/start';
import stripe from './translations/stripe';
// Model e translations
import modelEDashboard from './translations/modelEDashboard';
import modelEStart from './translations/modelEStart';
import modelEKeyDecisionMaker from './translations/modelEKeyDecisionMaker';
import modelEDealerPersonnel from './translations/modelEDealerPersonnel';
import modelEStripe from './translations/modelEStripe';
import modelEHelp from './translations/modelEHelp';
import modelEResourceCenter from './translations/modelEResourceCenter';
import modelEReports from './translations/modelEReports';

export default {
  activationSummary,
  commsCenter,
  dashboard,
  dealerInformation,
  dealerPersonnel,
  dealerProfile,
  faqs,
  footer,
  header,
  help,
  home,
  impersonate,
  keyDecisionMaker,
  readinessCheck,
  reports,
  resourceCenter,
  resourcesPathJourneys,
  selectExperience,
  start,
  stripe,
  modelEDashboard,
  modelEStart,
  modelEKeyDecisionMaker,
  modelEDealerPersonnel,
  modelEStripe,
  modelEHelp,
  modelEResourceCenter,
  modelEReports,
  general: {
    accept: 'Accepter',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    confirmed: 'Confirmé',
    continue: 'Continuer',
    comingSoon: 'à venir bientôt',
    download: 'Télécharger',
    editsNotSaved: 'Modifications non enregistrées',
    email: 'Courriel',
    goBack: 'Retourner',
    name: 'Nom',
    no: 'Non',
    none: 'Rien',
    phone: 'Téléphone',
    preferredLanguage: 'Langue préférée',
    search: 'Chercher',
    select: 'Sélectionner',
    submit: 'Soumettre',
    view: 'Afficher',
    yes: 'Oui',
    save: 'Enregistrer',
    status: {
      notStarted: 'Non commencé',
      complete: 'Complet',
      completed: 'Complété',
      incomplete: 'Incomplet',
      active: 'Actif',
      inactive: 'Inactif',
      pending: 'En suspens',
      inProgress: 'En cours',
      onHold: 'En attente',
      unknown: 'Inconnu',
      optOut: 'Se désinscrire',
    },
    skipContent: 'Passer au contenu principal',
    scrollToTop: 'Haut de la page',
  },
  validations: {
    required: 'Obligatoire',
    email: 'L\'adresse courriel n\'est pas valide',
    minLength: '[NT] This field must have at least {min} characters',
    phoneNumber: 'Le Numéro de Téléphone n\'est pas valide',
    extension: 'Le Poste n\'est pas valide',
  },
  calendar: {
    weekDays: 'D, L, M, M, J, V, S',
  },
  notFound: {
    text: 'Désolé, l\'URL demandée est introuvable',
    button: 'Emmène moi chez toi',
  },
  brand: {
    text: 'Veuillez sélectionner une franchise pour continuer',
    failedToLoad: '[NT] Failed to load user profile. Please refresh the page and try again.',
  },
  videoCard: {
    link1: 'transcription',
  },
  termsAndConditions: {
    title: 'Conditions générales',
    linkTitle: 'Télécharger les Conditions générales',
    acceptedOn: 'Accepté le ',
    text1: '[NT] Download the @:footer.tc',
    text2: '[NT] I Agree to the @:footer.tc',
    text3: '[NT] Please accept the @:footer.tc',
  },
  buttons: {
    backToTop: 'Haut de la page',
    loading: 'Chargement...',
  },
  textArea: {
    charText: 'Caractères restants: ',
  },
  resourceResults: {
    noResultText: 'Aucun résultat trouvé',
  },
  searchResultPdf: {
    readTime: 'Temps de lecture',
    filterText1: 'Départements:',
    filterText2: 'Les rôles:',
    filterText3: 'Les sujets:',
    link1: 'Afficher/Télécharger',
    link2: 'Partager par Courriel',
    relatedLinks: 'Liens connexes',
    publishText: 'Publié',
  },
  searchResultVideo: {
    playTime: 'Durée de {time} minutes',
    filterText1: 'Départements:',
    filterText2: 'Les rôles:',
    filterText3: 'Les sujets:',
    transcriptLink: 'Transcription',
    publishText: 'Publié',
  },
  resourcesSearchBar: {
    label: 'Recherche par Mots-Clés',
    submitError: 'Vous devez avoir au moins trois caractères avant de soumettre',
    filterText1: 'Filtrer le contenu par département',
    filterText2: 'Filtrer le contenu par rôle',
    filterText3: 'Filtrer le contenu par sujet',
    printPage: 'Imprimer Cette Page',
    keywordSearch: 'Cliquez ici pour la recherche par Mot-Clé',
  },
  multiSelectDropdown: {
    label1: '-- Sélectionner --',
    label2: '{count} choisi',
    label3: 'Tout Sélectionner',
  },
  readinessPlan: {
    title: 'Plan de préparation au Commerce électronique',
    subtitle: 'Ventes de véhicules neufs',
    tabOne: 'Rencontre du Directeur',
    tabTwo: 'Réunions de Départment',
    tabThree: 'Confirmation de Préparation',
  },
  readinessPrep: {
    versionOne: {
      itemOne: {
        title: '[NT] Before scheduling Manager\'s Meetings, visit the Commerce électronique Dealer Portal to review Dealer\'s:',
        list: {
          itemOne: '[NT] Activation statuses',
          itemTwo: '[NT] Department Lead assignments',
          itemThree: '[NT] Customer Handling Team assignments',
        },
      },
      itemTwo: {
        title: '[NT] Review the resources to be used when hosting Manager\'s Meetings:',
        list: {
          itemOne: {
            title: '[NT] <b>Manager\'s Meeting Template</b> with valuable best practices and tools to help save you time and run a successful meeting:',
            listItemOne: '[NT] Webex invitation to copy and paste when setting meetings',
            listItemTwo: '[NT] Dealership roles to invite, and how to find each dealership\'s assigned contacts for each role',
            listItemThree: '[NT] Step-by-step guidance to run your meetings, navigating between training presentation and the commerce électronique Dealer Portal',
            listItemFour: '[NT] Attachments to include in the meeting notice to help your dealers prepare',
          },
          itemTwo: '[NT] <b>Commerce électronique Dealer Readiness Training</b> presentation',
          itemThree: '[NT] Supporting documents',
          itemFour: '[NT] Follow up items and next steps',
        },
      },
      itemThree: {
        title: '[NT] Schedule and host Manager\'s Meetings with each of your Dealers:',
        list: {
          itemOne: '[NT] Present the<b>Commerce électronique Dealer Readiness Training</b> presentation',
          itemTwo: '[NT] Confirm dealership has completed setup for the eCommerce experiences',
          itemThree: '[NT] Use the <b>eCommerce Playbook</b> and <b>eCommerce Readiness Checklist</b> to activate/confirm dealership\'s eCommerce Team',
          itemFour: '[NT] Use the <b>eCommerce Department Team Worksheet</b> to review key roles and actions to discuss and define dealership Customer handling, coordination and CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
          itemFive: '[NT] Review the eCommerce resources with Dealers, show them where to locate them on the portal, and recommend they print and distribute the <b>eCommerce Department Team Worksheet</b> and <b>New Vehicle Purchase Request Checklist</b> to all team members during their department meetings',
          itemSix: '[NT] Support the Dealer in scheduling their own Department Team Meetings, which will follow your session, informing teams, and delivering strategies',
        },
      },
      itemFour: '[NT] Follow up with your Dealers post-session to encourage them to complete their <b>Readiness Check</b>',
      itemFive: {
        title: '[NT] Complete an <b>eCommerce Readiness Visit Report</b> for each Dealer, marking progress for each step of the Readiness Plan as completed <i>(do not mark \'Final\' until all actions are complete. The report will allow you to \'save\' your work and return to update until marked \'final\')</i>',
        list: {
          itemOne: '[NT] Manager\'s Meeting completion',
          itemTwo: '[NT] Department Team Meeting completion',
          itemThree: '[NT] Strategy Implementation',
        },
      },
    },
    versionTwo: {
      title: '[NT] Schedule a Manager\'s Meeting to Confirm Program Setup and Define In-Dealership Strategies:',
      itemOne: '[NT] Review/Confirm your dealership has completed setup for the eCommerce experiences',
      itemTwo: {
        title: '[NT] Download the <strong>eCommerce Playbook</strong> and <b>eCommerce Readiness Checklist</b> to:',
        list: {
          itemOne: {
            pieceOne: '[NT] Confirm/',
            pieceTwo: 'activate your eCommerce Team',
          },
          itemTwo: '[NT] Define your coordination strategy',
          itemThree: '[NT] Define your Customer Handling and CRM response strategies using the <b>eCommerce CRM Response Templates</b>',
        },
      },
      itemThree: '[NT] Plan and schedule department team meetings to inform teams and deliver strategies',
      itemFour: {
        title: '[NT] Download and print enough copies of the below documents to distribute to each team member during the Department Team Meetings (tab 2):',
        list: {
          itemOne: '[NT] <b>eCommerce Department Team Meeting Worksheet</b>',
          itemTwo: '[NT] <b>eCommerce New Vehicle Purchase Request Customer Handling Checklist (NVPR)</b>',
        },
      },
    },
  },
  readinessMeeting: {
    title: 'Organiser les réunions d\'équipe du département Commerce électronique:',
    itemOne: {
      title: 'Informer les équipes',
      list: {
        itemOne: 'Utilisez le <b>playbook du c-Commerce</b> pour fournir un aperçu du programme de commerce électronique',
        itemTwo: 'Livrer les missions de l\'équipe',
        itemThree: 'Délivrer des stratégies clés',
      },
    },
    itemTwo: {
      title: 'Distribuez et remplissez la feuille de travail pour la réunion de l\'équipe du département de Commerce électronique',
      list: {
        itemOne: 'Passez en revue les actions et les ressources clés pour chaque rôle',
        itemTwo: 'Identifier avec qui une coordination en concession est nécessaire',
        itemThree: 'Discuter de la stratégie de coordination et s\'aligner sur le plan de mise en œuvre',
        itemFour: 'Identifier où trouver les informations client dans EMP pour soutenir chaque action',
      },
    },
    itemThree: 'Distribuez la <b>liste de contrôle NVPR du commerce électronique</b> à utiliser comme référence facile pour guider l\'équipe de gestion des clients dans leur travail pour honorer les demandes des clients',
    itemFour: 'Examiner les stratégies de réponse CRM des concessionnaires à l\'aide des <b>modèles de réponse CRM commerce électronique</b>',
    itemFive: 'Établir un processus dans lequel le point de contact unique identifié est la seule personne à répondre aux prospects du commerce électronique',
    itemSix: 'Une fois toutes les réunions de l\'équipe du service terminées, avertissez la direction que le <b>plan de préparation au commerce électronique</b> peut être marqué comme final pour les rapports sur le commerce électronique <i>(c\'est ainsi que nous suivrons la participation de votre concession)',
  },
  modalFrame: {
    shareByEmail: 'Partager par Courriel',
    buttonBack: 'Retour',
    buttonAcknowledge: 'Je reconnais',
    emailSubject: '{firstName} {lastName} vous transmet du contenu commerce électronique.',
    emailBody: {
      line1: 'Bonjour,',
      line2: 'J’ai pensé que vous pourriez aimer l’élément de contenu commerce électronique que j’ai trouvé dans le portail commerce électronique des concessionnaires (cliquez sur le lien fourni ci-dessous).',
    },
  },
  nonKeyDecisionMakerDisclaimer: {
    title: 'Avis d’autorisation',
    message: 'Vous n’êtes pas assigné en tant que décisionnaire clé et, par conséquent, vous ne pouvez pas appliquer ces paramètres à votre concessionnaire. Veuillez communiquer avec le gestionnaire du programme sur la page d’aide de ce portail pour signaler tout cas de non-correspondance entre le nom de la personne-ressource et le code d’utilisateur WSLX (identifiant FMCDealer) ayant pu être généré lors de l’inscription initiale. Décisionnaires clés actifs chez votre concessionnaire :',
    message2: '[NT] You are not assigned as an active Key Decision Maker with the ability to apply these settings for your dealership. Please contact PHQ by visiting the help page on this portal to report any contact name and WSLX ID (InFord Login) mismatches that may have been created during initial enrolment. Active Key Decision Makers for your dealership:',
  },
  toasts: {
    feedback: {
      errorTextToDisplay: 'Erreur de transfert de données. Veuillez vérifier votre connexion réseau.',
      successTextToDisplay: 'Données enregistrées avec succès',
    },
  },
  browserIsNotCompatible: {
    title: '[NT] Your browser is out of date',
    paragraphOne: 'Le portail commerce électronique des concessionnaires a détecté que vous utilisez un navigateur qui n’est pas à jour ou qui n’est pas compatible, ce qui vous empêchera d’accéder à certaines fonctions ou certains sites Web. Afin de vous assurer une expérience harmonieuse, il est recommandé d’utiliser la plus récente version de Google Chrome ou de Microsoft Edge.',
  },
  dashboardStripeAccountSetup: {
    p1: '[NT] The new Ford eCommerce experiences introduce the ability for Customers to submit Reservations, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Dealers. To accept eCommerce Customer-submitted funds, dealerships must set up a Stripe account. To begin the Stripe account application process, click on the ‘Stripe Account Setup’ button.',
    p2: '[NT] The new Ford eCommerce experiences introduce the ability for Customers to submit Reservations, Order Requests, and New Vehicle Purchase Requests (NVPRs) directly to Dealers. To accept eCommerce Customer-submitted funds, dealerships must set up a Stripe account.',
    btn1: 'Configuration de compte Stripe',
    btn2: '[NT] Stripe Account & Fees',
    text1: '[NT] Stripe',
    text2: '[NT] Stripe Account IDs for Dual Dealers',
    p3: '[NT] Our records indicate you are setting up Stripe for a dual dealership, which provides you the choice to set up and receive one or two Stripe Account IDs. Click your choice below.',
    text3: '[NT] Set Up One Stripe Account ID: ',
    text4: '[NT] Ford and Lincoln credit transactions will be posted under one Stripe Account ID number with distinct franchise indicators.',
    text5: '[NT] Set Up Two Stripe Account IDs:',
    text6: '[NT] Ford and Lincoln credit transactions will be posted to two unique Stripe Account IDs with separate reporting for each ID number.',
    btn3: '[NT] Set Up One ID',
    btn4: '[NT] Set Up Two IDs',
    btn5: '[NT] Confirm',
    btn6: '[NT] Cancel',
    p6: '[NT] Your dealership’s deposit and fee settings have carried over from your previous selections, and any changes you make will be applied to both payment processors. You can return to update your selections at any time.',
    text9: '[NT] Deposit and Fee Settings',
    p4: '[NT] Our records indicate you are not a Key Decision Maker for your dealership. The following people are Key Decision Makers:',
  },
  theStripe: {
    text1: '[NT] Stripe <sup>TM</sup> Account & Fee Setup',
    p1: 'Faire la configuration pour permettre aux invités de soumettre des dépôts à votre concessionnaire à partir du site Ford.ca',
    text3: '[NT] Stripe Account & Fee Setup',
  },
  stripeAccountFeeSetup: {
    text1: '[NT] STRIPE ACCOUNT SETUP',
    p1: '[NT] To get started on your Stripe account application, click on the \'Stripe Application\' button.',
    p2: '[NT] Once your dealership\'s account is approved, the button will update to \'Access Stripe,\' which will take you to your Stripe dashboard.',
    text2: '[NT] Stripe Account Status',
    text3: '[NT] Stripe Account Status\' may indicate: Not Started, Pending, On Hold, or Active',
    text4: '[NT] Last checked:',
    text5: '[NT] Confirm Stripe Account ID and Fees',
    text6: '[NT] \'Confirm Stripe Account ID and Fees\' status may indicate: Incomplete or Complete',
    p3: '[NT] Once you have completed your Stripe account application, a pending status will appear, and it should take approximately 10 minutes until your account is active. The \'Check Status\' button can be clicked to check progress and the details on the last status check will be displayed to its right.',
    p9: '[NT] Once you have completed your Stripe account application, a pending status will appear, and it should take approximately 10 minutes until your account is active.',
    text7: '[NT] *Scroll down to review/complete',
    p10: '[NT] Your Stripe account status is unknown, as the status below indicates. It should take approximately 10 minutes until your account status is active. Click Check Status for an update, the details of the last status check are displayed to the right of the button.',
    p4: '[NT] Once your Stripe account is approved, you\'ll return to this page and confirm your Stripe Account ID below.',
    btn1: '[NT] Stripe Application',
    btn2: '[NT] Access Stripe',
    btn3: '[NT] Setup Tips',
    text8: 'Le « Dépôt de commande » est la somme que votre concessionnaire demandera aux invités de déposer pour une demande de commande. Sélectionnez une valeur dans le menu déroulant, cliquez sur « Confirmer », puis sur « Enregistrer ».',
    text9: 'La « Somme maximale acceptée par carte de crédit » est la somme maximale que votre concessionnaire peut accepter par carte de crédit pour un client de Crédit Ford. Sélectionnez une valeur dans le menu déroulant, cliquez sur « Confirmer », puis sur « Enregistrer ».',
    text10: 'La « Somme maximale acceptée au moyen de l’ACH » est la somme maximale que votre concessionnaire peut accepter par l’entremise de la chambre de compensation automatisée, qui utilisera un transfert électronique de fonds (TEF) provenant de l’institution financière du client. Sélectionnez une valeur dans le menu déroulant, cliquez sur « Confirmer », puis sur « Enregistrer ».',
    text11: 'Le « Dépôt à la caisse de tous les types de paiement » est la somme que votre concessionnaire demandera aux clients de déposer pour l’achat de leur véhicule. Sélectionnez une valeur dans le menu déroulant, cliquez sur « Confirmer », puis sur « Enregistrer ».',
    p5: '[NT] Your Stripe account is pending, as the status below indicates.',
    p6: '[NT] Your Stripe account is active, as the status below indicates.',
    p7: '[NT] To access your Stripe dashboard, click on the \'Access Stripe\' button.',
    p8: '[NT] Please confirm your Stripe Account ID below. Please reference the email sent from Ford eCommerce with the subject line (include the subject line of the email in the subject line variable) to locate your Stripe Account ID. You will need this to confirm your ID below:',
    dlgtext1: '[NT] Stripe',
    dlgtext2: '[NT] Account On Hold',
    dlgtext3: '[NT] Our records indicate your Stripe account is currently on hold. Please contact Stripe by using the form at this link:',
    dlgtext4: '[NT] Stripe Setup Tips',
    dlgtext5: '[NT] Setup & Maintenance Tips for Your Stripe Account',
    dlgtext6: '[NT] Before beginning your Stripe Application, download the Stripe Account Setup checklist for easy reference',
    dlgtext7: '[NT] Make sure you\'ve gathered the necessary financial documents before beginning the application process',
    dlgtext8: '[NT] When you receive an email with your Stripe Account ID, be sure to print it or favorite it to locate again when needed',
    dlgtext9: '[NT] Ensure your dealership is prepared to receive eCommerce funds by removing the debit blocker with your financial institution. Request removal of debit blocker by adding Stripe company IDs (1800948598 and 4270465600)',
    dlgtext10: '[NT] If you\'re a dual Dealer and chose one account, but later would like to inquire about separate account IDs, contact eCommerce program HQ at 1-800-647-7268 or helpdesk@eCommDealerSetup.com',
  },
  stripeAccountIdFees: {
    text1: 'IDENTIFIANT DE COMPTE STRIPE et FRAIS',
    p1: '[NT] Once your Stripe account is active, your Stripe Account ID will populate below. When it appears, you’ll cross reference the Stripe Account ID sent via email, check ‘Confirm’ and click ‘Save.’',
    p2: '[NT] Click the accordion to set parameters for funds accepted by your dealership in eCommerce. The \'Reservation Deposit\' is set by Ford and can\'t be edited. The \'Order Deposit,\' \'Max Amount Accepted on Credit Card,\' \'Max Amount Accepted with ACH,\' and \'All Payment Types Checkout Deposit\' have dropdown menus with pre-populated amounts.',
    modelE: 'Cliquez sur l’accordéon pour configurer les paramètres liés aux fonds acceptés par votre concessionnaire dans la plateforme de commerce électronique. Le « Dépôt avec accès avancé » est configuré par Ford, et on ne peut pas le modifier. Le « Dépôt de commande », la « Somme maximale acceptée par carte de crédit », la « Somme maximale acceptée par la chambre de compensation automatisée (ACH) » et le « Dépôt à la caisse de tous les types de paiement » comportent des menus déroulants assortis de montants déjà inscrits.',
    p3: 'L’identifiant de votre compte Stripe est affiché ci-dessous. Pour confirmer qu’il correspond à l’identifiant de compte Stripe fourni dans le courriel de confirmation, cochez « Confirmer » et cliquez sur « Enregistrer ».',
    text2: '[NT] Primary Contact:',
    text3: '[NT] Stripe account ID',
    text4: '[NT] Confirm',
    text5: '[NT] Save',
    text6: '[NT] Confirmed',
    text7: '[NT] Questions about your Stripe account ID?',
    text8: '[NT] Deposit',
    text9: '[NT] This value was carried over from a previous selection, you may change it at any time.',
    modelEReservationTooltip: 'Le « Dépôt avec accès avancé » est la somme que votre concessionnaire demandera aux invités de déposer pour obtenir un accès avancé. Cette somme est établie par Ford, et on ne peut pas la modifier.',
  },
  theFaqs: {
    title: '[NT] FAQs',
    label1: '[NT] Type your question here',
    btn1: '[NT] SUBMIT',
    p1: '[NT] Additional Information:',
    label2: '[NT] Helpful?',
    label3: '[NT] No Results Found',
    label4: '[NT] @:resourceHeader.help',
  },
  resourceDepartment: {
    title: '[NT] Select by Department',
  },
  experienceCard: {
    newContent: '[NT] New Content >',
    btnText: '[NT] Select',
  },
  resourceJourney: {
    title: '[NT] Customer Journey',
    p1: '[NT] New to eCommerce? No problem! Click through for the Customer and Dealer eCommerce journey to learn more about the new Customer submissions that may be coming your way!',
    p2: '[NT] Every New Vehicle eCommerce submission will be unique, much like every Customer. This journey provides a look at how the Customer and Dealer take turns online, driving the deal forward, and the new tools that will help your dealership deliver a seamless eCommerce Customer experience.',
    btn1: '[NT] Start the Journey',
  },
  resourceEmp: {
    title: '[NT] eCommerce Marketplace Portal (EMP) Demo',
    subtitle: '[NT] Navigating The eCommerce Command Center',
    p1: '[NT] EMP is the command center for monitoring and managing eCommerce Customers—it is where your dealership can act on Customer-submitted Reservations, Orders, and New Vehicle Purchase Requests (NVPRs).',
    p2: '[NT] This EMP Demo provides a user-friendly walkthrough, from start to finish, to give you an understanding of the layout and accordions, and locations to find Customer-submissions (deposits and trade-in vehicles) and Customer-requests (protection plans and accessories).',
    btn1: '[NT] Get Started',
  },
  resourceOperationalize: {
    title: '[NT] Operationalize eCommerce in Your Dealership',
    subtitle: '[NT] Manager\'s Toolkit',
    p1: '[NT] Three simple activities will help you operationalize eCommerce your way — within your current process, with your team, to help reach your Customer Handling goals.',
    text1: '[NT] Manager\'s Meeting',
    text2: '[NT] Department Team Meetings',
    text3: '[NT] Strategy Implementation',
    btn1: '[NT] Get Started',
  },
};
