import BaseAPI from './baseAPI';

export default class Document extends BaseAPI {
  async updateProgress({
    dealerId, userId, step, firstName, lastName,
  }) {
    const { data } = await this.axios.post('/Enrollment', {
      programId: 1,
      firstName,
      lastName,
      dealerID: dealerId,
      stepInfo: step,
      userID: userId,
    });

    return data;
  }

  async submit(payload) {
    const { data } = await this.axios.post('Dealer/SubmitEnrollment', {
      ford: payload.ford,
      lincoln: payload.lincoln,
      dealerId: payload.dealerId,
    });

    return data;
  }

  async updateModelEProgress({
    dealerId, userId, step, firstName, lastName,
  }) {
    const { data } = await this.axios.post('/EnrollmentModelE', {
      programId: 2,
      firstName,
      lastName,
      dealerID: dealerId,
      stepInfo: step,
      userID: userId,
    });

    return data;
  }

  async submitModelE(payload) {
    const { data } = await this.axios.post('/DealerModelE/SubmitEnrollmentModelE', {
      ford: payload.ford,
      lincoln: payload.lincoln,
      dealerId: payload.dealerId,
      programId: 2,
    });

    return data;
  }
}
