export default {
  brandText: 'basculer entre les franchises',
  blueTitle: 'Conditions d\'activation',
  heading1: 'Conditions d\'activation',
  heading: 'intégration et maintenance',
  remoteDelivery: {
    title: 'Préférence de livraison à distance',
    description: 'Veuillez choisir si votre concessionnaire souhaite offrir aux clients une option pour indiquer qu’ils sont intéressés par la livraison à distance dans le cadre de l’expérience eCommerce. Si cette option est activée, les clients pourront cocher une case pour indiquer qu’ils sont intéressés par la livraison à distance, qui apparaîtra lors de la soumission de leur transaction. Tous les détails relatifs à la livraison à distance seront traités hors ligne entre votre concessionnaire et votre client. Le concessionnaire convient de respecter les lignes directrices fédérales et locales.',
    label: 'Votre concessionnaire souhaite-t-il offrir aux clients l’option de livraison à distance dans le cadre de l’expérience eCommerce?',
  },
  notifications: {
    title: 'Notifications ({count})',
    termsAndConditions: 'Vous n’avez pas accepté les plus récentes Conditions générales.',
    ars: 'Aucune autorisation ARS n’est définie pour votre concessionnaire',
    arsNotSet: 'Aucune autorisation ARS n’est définie pour votre concessionnaire',
    customerHandling: 'Aucune autorisation pour le traitement de la clientèle n’est définie pour votre concessionnaire',
    accessoryPricing: 'Aucune autorisation pour les prix des accessoires n’est définie pour votre concessionnaire',
    accessoryPricingNotSet: 'Aucune autorisation pour les prix des accessoires n’est définie pour votre concessionnaire',
    evCertified: 'Votre concessionnaire est certifié VE (véhicules électriques)',
  },
  enrollment: {
    title: 'Inscription (F(ii) Pouvoir de gestion)',
    sectionOne: {
      title: '@:footer.tc',
      subtitle: 'Conditions générales d\'inscription',
      checkbox: 'J\'accepte les Conditions générales',
      buttonParentName: '@:footer.tc',
      successMessage: 'Sauver succès',
      heading: 'Décisionnaire(s) clé(s)',
      paragraphOne: 'Pour que votre inscription soit optimale, vous devez affecter au moins un décisionnaire clé chez votre concessionnaire qui prendra les décisions relatives au programme eCommerce.',
      paragraphTwo: 'Une fois les Conditions générales mises à jour envoyées, votre concessionnaire devra les accepter afin de participer au programme. La fonctionnalité ci-dessous vous permet d\'ajouter jusqu\'à trois décisionnaires clés, en dehors de vous-même, qui seront autorisés à accepter les Conditions générales mises à jour.',
    },
    terms: {
      title: 'Conditions générales',
      checkbox: 'J’accepte les Conditions générales',
      subtitle: 'Conditions générales d’inscription',
      tooltip: 'Vous trouverez ci-dessous la version anglaise des Conditions générales, suivie de la traduction française exacte.',
    },
    teamSetup: {
      title: 'Renseignements du concessionnaire et création de l\'équipe',
    },
    submit: {
      buttonText: 'Soumettre l’inscription',
    },
    sectionTwo: {
      title: 'Renseignements du concessionnaire et création de l\'équipe',
    },
    sectionThree: {
      title: 'Préférences de Livraison à Distance',
      submitEnrollment: 'Soumettre Inscription',
      successMessage: 'Préférence de livraison à distance enregistrée!',
      errorMessage: 'Échec de l’enregistrement de la préférence de livraison à distance',
      enrollmentFailed: '[NT] Failed to submit the enrolment. Please try again.',
    },
  },
  stripe: {
    title: 'Compte Stripe (requis pour les règlements directement avec le concessionnaire)',
    sectionOne: {
      title: 'Établissement de compte Stripe et des frais',
      videoTitle: '[NT] FordPay Sales MID Setup for eCommerce',
      description: 'Les nouvelles expériences Ford eCommerce présentent aux clients la capacité de soumettre des réservations, des demandes de commande et des demandes d’achat de véhicules neufs directement aux concessionnaires. Pour accepter les fonds soumis par le client par eCommerce, les concessionnaires doivent configurer un compte Stripe. Pour commencer le processus de demande de compte Stripe, cliquez sur le bouton « Établissement de compte Stripe ».',
      descriptionActive: 'Les nouvelles expériences Ford eCommerce présentent aux clients la capacité de soumettre des réservations, des demandes de commande et des demandes d’achat de véhicules neufs directement aux concessionnaires. Pour afficher les renseignements de votre compte Stripe, cliquez sur le bouton « Compte Stripe et frais ».',
      link: 'Établissement de compte Stripe',
      linkActive: 'Compte Stripe et frais',
      modal: {
        title: 'Stripe',
        subtitle: 'Identifiants de compte Stripe pour les concessionnaires des deux marques',
      },
      modalKdm: {
        paragraphOne: 'Nos registres indiquent que vous n’êtes pas un décideur principal pour votre concessionnaire. Les personnes suivantes sont des décideurs principaux: {names}',
      },
      modalAccounts: {
        paragraphOne: 'Nos registres indiquent que vous établissez Stripe pour un concessionnaire des deux marques, ce qui vous donne le choix de configurer et de recevoir un ou deux identifiants de compte Stripe. Cliquez sur votre choix ci-dessous.',
        paragraphTwo: 'Établir un identifiant de compte Stripe: <br /> les transactions de crédit Ford et Lincoln seront affichées sous un identifiant de compte Stripe avec des indicateurs de franchise distincts.',
        paragraphThree: 'Établir deux identifiants de compte Stripe: <br /> les transactions de crédit Ford et Lincoln seront affichées sous deux identifiants de compte Stripe avec des relevés séparés pour chaque identifiant.',
        buttonOneId: 'Configurer un identifiant',
        buttonTwoIds: 'Configurer deux identifiants',
      },
    },
  },
  accessoryPricing: {
    title: 'Prix des Accessoires',
    sectionOne: {
      title: 'Prix des Accessoires',
      paragraphOne: 'Le module des accessoires du portail eCommerce permet aux concessionnaires de définir une structure de prix et des taux de main-d\'œuvre pour les accessoires. Les clients seront ensuite en mesure de sélectionner des accessoires avec des prix et de les ajouter à leur véhicule neuf dans les expériences eCommerce.',
      paragraphTwo: 'Cliquez sur "Définir le prix des accessoires" pour accéder au module et définir les prix.',
      paragraphThree: 'Il faut définir les autorisations au sujet des prix des accessoires avant d\'entrer dans le module des accessoires. Pour définir les autorisations sur les prix, cliquez sur « DÉFINIR LES AUTORISATIONS ». Seuls les utilisateurs du concessionnaire autorisés à définir les prix devraient obtenir les autorisations propres au module des accessoires.',
      linkOne: 'Définir le prix des accessoires',
      linkTwo: 'Définir les autorisations',
      linkURL: 'https://www.emp.dealerconnection.com/#page=accessories&brand=F',
      errorToast: 'Aucune autorisation n\'est définie pour votre concessionnaire',
    },
  },
  vehiclePricing: {
    title: 'Prix des Véhicules',
    sectionOne: {
      title: 'Prix des Véhicules',
      description: `
        <p>Le module de définition des prix eCommerce de l'outil Vehicle Locator Plus (VLPlus) permet aux concessionnaires de définir le prix des véhicules pour inclure l'équipement en option installé par le concessionnaire et le présenter aux clients durant les expériences eCommerce. Le prix défini par le concessionnaire est le prix indiqué pour vos véhicules admissibles avant les encouragements, remises, taxes ou frais. Cliquez sur « DÉFINIR LE PRIX DES VÉHICULES » pour accéder au module et définir les prix.</p>
        <p>Remarque: Les tâches exécutées dans VLPlus peuvent demander jusqu'à 24 heures pour figurer dans le portail des concessionnaires en raison du transfert des données entre les systèmes.</p>
      `,
      setEntitlements: 'Il faut définir les autorisations au sujet du prix des véhicules avant d\'accéder au module de définition des prix des véhicules. Pour définir les autorisations sur les prix, cliquez sur « DÉFINIR LES AUTORISATIONS ». Seuls les utilisateurs du concessionnaire autorisés à définir les prix devraient obtenir les autorisations propres au module de définition des prix.',
      linkOne: 'Définir le prix des véhicules',
      linkTwo: 'Définir les autorisations',
      labelOne: 'Source de prix actuelle:',
      labelTwo: 'Validation de la source des prix:',
      labelThree: 'Pourcentage des stocks avec un prix:',
      complete: 'Complet',
      incomplete: 'Incomplet',
      smallOne: '**Doit être supérieur à 70 % pour l\'activation',
      errorToast: 'Aucune autorisation n\'est définie pour votre concessionnaire',
      interruption: '[NT] Pricing data interruption detected, please contact VLPlus: fdvlplus@forddirect.com',
    },
    pricingBelowInventory: '[NT] VIN-based pricing has fallen below 50% of total inventory. Navigate to Dashboard > Vehicle Pricing > Set Vehicle Pricing to increase your percentage.',
    pricingBelowInventoryDashboard: '[NT] VIN-based pricing has fallen below 50% of total inventory. Navigate to Vehicle Pricing > Set Vehicle Pricing to increase your percentage.',
  },
  fiSetup: {
    title: 'F et A <i style="font-size: 13px;">(à venir bientôt)</i>',
    routeOne: {
      title: '[NT] RouteOne ID',
      description: '[NT] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      buttonText: '[NT] Access RouteOne',
      statusLabel: '[NT] Confirm RouteOne ID',
      inputLabel: '[NT] RouteOne Dealer ID',
      paragraph1: '[NT] Confirm this is the same RouteOne ID your dealership will use within the RouteOne website. Dual dealerships should read the examples below before confirming.',
      paragraph2: '[NT] Settings will be based on the individual RouteOne dealer ID selected.',
      paragraph3: '[NT] Example 1: ABC Ford/Lincoln uses one RouteOne ID of AB7CD. Their settings for ePA, tax/fee and online paperwork will apply for both the Ford and Lincoln transactions. If ABC Ford/Lincoln is presented with 2 RouteOne IDs in the drop down, select the one ID that they will use for eCommerce transactions.',
      paragraph4: '[NT] Example 2: ABC Ford uses RouteOne ID of EF7GH and ABC Lincoln uses JK8LM. They will both need to create settings based on these RouteOne IDs.',
    },
    taxesAndFees: {
      title: '[NT] Taxes & Fees',
      statusLabel: '[NT] Taxes & Fees',
      description: '[NT] The RouteOne tax & fee module has been designed to provide a quick setup by connecting to Carlton & ATC to provide zip code + 4 level taxes and to include state required fees. Please review "sample fee" when accessing RouteOne to review the work that\'s already been done to save you time before adding any additional taxes & fees you see fit.',
    },
    documentFees: {
      title: '[NT] Document Fees',
      statusLabel: '[NT] Document Fees',
      description: '[NT] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    fiProduct: {
      title: '[NT] F&I Product',
      statusLabel: '[NT] F&I Product',
      description: '[NT] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia dese',
    },
    eSign: {
      title: '[NT] Online Paperwork with eSign',
      statusLabel: '[NT] Online Paperwork with eSign',
      description: '[NT] Dealers need to upload their dealership\'s privacy policy in PDF format to enable eContracting and Remote eSign capabilities to allow Ford Credit-specific documents to be generated through RouteOne at customer request. Dealers have control to work with their customers the way they prefer to complete Ford Credit paperwork and should remain compliant with federal and local laws when deciding whether to support a customer\'s request for online paperwork. Functionality will continue to grow to also include dealer-specific documents as we move further down the product cycle. Some dealers may already be enrolled and will not need to complete this step.',
    },
    sectionOne: {
      title: 'RouteOne Configuration <i style="font-size: 13px;">(à venir bientôt)</i>',
      videoTitle: '[NT] F&I Setup for eCommerce',
      pdfName: '[NT] F&I setup checklist',
      paragraphOne: '[NT] RouteOne modules provides dealers the ability to approve and set taxes & fees, complete F&I product selection and pricing, and set up online paperwork with remote eSign/eContracting capabilities to be presented online in the eCommerce cart & checkout experience. Please review training materials below for instructions on completing each category, then click the Access RouteOne button to agree to the RouteOne electronic Participation Agreement (ePA) and begin setup.',
      paragraphTwo: '[NT] If you have questions regarding RouteOne setup or troubleshooting for Lincoln eCommerce, please contact the RouteOne Service Desk at 866-768-8301.',
      paragraphThree: '[NT] In order to use the ORS Module, your Dealer System Administrator (DSA) in RouteOne will first need to accept the amendment to the Dealer Participation Agreement. To identify your DSA, click on My RouteOne, in the upper right-hand corner of the RouteOne website. If you need to set or reassign a DSA, contact 866-768-8301.',
      linkOne: '[NT] Access RouteOne',
      titleOne: '[NT] Confirm RouteOne Dealer ID',
      paragraphFour: '[NT] Confirm this is the same RouteOne ID your dealership will use within the RouteOne website. Dual dealerships should read the examples below before confirming.',
      selectDealerLabel: '[NT] RouteOne Dealer ID',
      paragraphFive: '[NT] Settings will be based on the individual RouteOne dealer ID selected.',
      paragraphSix: '[NT] Example 1: ABC Ford/Lincoln uses one RouteOne ID of AB7CD. Their settings for ePA, tax/fee and online paperwork will apply for both the Ford and Lincoln transactions. If ABC Ford/Lincoln is presented with 2 RouteOne IDs in the drop down, select the one ID that they will use for eCommerce transactions.',
      paragraphSeven: '[NT] Example 2: ABC Ford uses RouteOne ID of EF7GH and ABC Lincoln uses JK8LM. They will both need to create settings based on these RouteOne IDs.',
      titleTwo: '[NT] Rate Markup',
      paragraphEight: '[NT] RouteOne provides your dealership the ability to customize Rate Markup (if your dealership does not charge rate markup, 0 can be entered) to be displayed with the customer\'s approved interest rate. This rate will only appear to a customer once they have completed their credit application and been approved.',
      titleThree: '[NT] Taxes & Fees',
      paragraphNine: '[NT] The RouteOne tax & fee module has been designed to provide a quick setup by connecting to Carlton & ATC to provide zip code + 4 level taxes and to include state required fees. Please review "sample fee" when accessing RouteOne to review the work that\'s already been done to save you time before adding any additional taxes & fees you see fit.',
      titleFour: '[NT] F&I Product',
      paragraphTen: '[NT] Today, Dealers can select from Brand or four third-party providers to present to Clients in the eCommerce Cart and Checkout experience. If the Dealer selects a third-party provider, they must have an existing contract with the provider in order for the product to display properly in the Client experience.',
      paragraphEleven: '@:general.comingSoon',
      paragraphTwelve: '[NT] Summary <br> [NT] In response to Dealer feedback, we are happy to announce that Lincoln is partnering with MaximTrak, an established F&I products menu provider from RouteOne, to offer an expanded provider and product portfolio in conjunction with the full vehicle line launch planned for Q4 2021. You will be notified when the MaximTrak enrolment/set-up process is launched. This partnership will provide:',
      listOne: {
        itemOne: '[NT] Portfolio expansion to 100+ providers with 75+ products for Dealers to select from, including Dealer-branded products',
        itemTwo: '[NT] The new ability for Dealers to request to have additional providers added to the MaximTrak platform',
        itemThree: '[NT] The new ability to create menus with Dealer-set pricing by payment method type: finance, lease, cash and balloon/options',
      },
      paragraphThirteen: '[NT] Dealer Action:',
      listTwo: {
        itemOne: '[NT] <strong>Ford Dealers who are EV-certified</strong> – Complete the F&I products selection or opt-out of presenting F&I products in the RouteOne module now (if you haven\'t already done so) and you will be notified when the MaximTrak enrolment/set-up process is launched.',
        itemTwo: '[NT] <strong>Ford Dealers who are not EV-certified and Lincoln stores</strong> – You do not need to set up Protection Plans in the current eCommerce tool and should wait until the MaximTrak enrolment/set-up process is launched. <br> If you would like to complete set-up today, you may select to opt-out of presenting F&I products in the RouteOne module now, and return to update your settings once you are notified that MaximTrak enrolment/set-up is available.',
      },
      titleFive: '[NT] Online Paperwork with eSign',
      paragraphFourteen: '[NT] Dealers need to upload their dealership\'s privacy policy in PDF format to enable eContracting and Remote eSign capabilities to allow LAFS-specific documents to be generated through RouteOne at customer request. Dealers have control to work with their clients the way they prefer to complete LAFS paperwork and should remain compliant with federal and local laws when deciding whether to support a client\'s request for online paperwork. Functionality will continue to grow to also include dealer-specific documents as we move further down the product cycle. Some dealers may already be enroled and will not need to complete this step.',
      saveButtonText: 'Enregistrer',
    },
  },
  tradeIn: {
    title: 'Véhicule d’échange',
    sectionOne: {
      title: 'Inscription au programme des Services de remise en marché des véhicules (ARS)',
      enrolledInARS: 'Votre concessionnaire est inscrit au programme ARS.',
      notEnrolledInARS: 'Votre concessionnaire n’est pas inscrit au programme ARS.',
      noTradeInMembers: 'Aucun membre de l’équipe consacrée aux échanges n’est défini pour votre concessionnaire. Veuillez cliquer sur « Mettre à jour l’équipe consacrée aux échanges ».',
      noEntitlements: 'Aucune autorisation n’est définie pour votre concessionnaire.',
      documents: {
        itemOne: 'ARS – Formulaire de renseignements sur le client',
        itemTwo: 'Entente de remise en marché du programme ARS',
        itemThree: '<b>Formulaire(s) de procuration propre(s)</b> à la province ou à la ville:',
        itemFour: 'Lettre de demande de code de fournisseur et de transfert électronique de fonds',
        subItem1: 'Procuration – ADESA Colombie-Britannique',
        subItem2: 'Procuration – Manheim Toronto et Manheim Montréal',
        subItem3: 'Procuration – ADESA (toutes les autres provinces et villes)',
      },
      linkOne: 'Mettre à jour l’équipe consacrée aux échanges',
      linkTwo: 'Définir les autorisations',
      paragraphOne: 'Ford a établi un programme d’évaluation des véhicules d’échange qui permet aux clients d’entrer les données sur leur véhicule d’échange afin de recevoir une offre transactionnelle de Ford. Votre concessionnaire pourra entièrement confirmer que le client a fourni des renseignements précis sur son véhicule et décider d’ajouter le véhicule à ses stocks, de le refuser ou de l’envoyer aux enchères par l’intermédiaire du programme d’échange entre concessionnaires des Services de remise en marché des véhicules (ARS) pour réclamer l’offre de Ford. Les concessionnaires doivent être inscrits au programme d’échange entre concessionnaires ARS pour réclamer les offres de Ford.',
      paragraphTwo: 'Le programme ARS de Ford Canada offre un éventail complet de services de remise en marché aux concessionnaires Ford Canada, aux entreprises de location et aux parcs commerciaux.',
      paragraphThree: 'Le programme ARS permet aux concessionnaires Ford Canada de vendre leurs véhicules d’occasion, quelle qu’en soit la marque, lors de ventes aux enchères commanditées par Ford. Ces véhicules sont mélangés à d’autres véhicules d’entreprise Ford. Une telle pratique améliore la rentabilité des concessionnaires et allège les stocks en offrant une autre option pour la vente en gros de véhicules d’occasion.',
      paragraphFour: 'L’inscription au programme ARS est gratuite.',
      paragraphFive: 'Pour vous inscrire au programme ARS, téléchargez et remplissez les formulaires suivants:',
      paragraphSix: {
        partOne: 'Veuillez envoyer les documents à l’adresse ',
        partTwo: ' en indiquant ceci comme objet : « <nom de votre concessionnaire> – Documents d’inscription ARS ». L’enregistrement de l’inscription peut prendre jusqu’à 10 jours ouvrables.',
      },
      paragraphSeven: 'Les noms énumérés ci-dessous ont été attribués durant l’inscription pour gérer les véhicules d’échange soumis durant les expériences eCommerce et pour choisir les véhicules à conserver et à inscrire au programme ARS. Cliquez sur « Mettre à jour l’équipe consacrée aux échanges » pour modifier les membres de l’équipe.',
      paragraphEight: {
        partOne: 'Il faut définir les autorisations au sujet des échanges avant d’accéder au module des véhicules d’échange. Afin de gérer les échanges de véhicules à l’aide du programme ARS, votre administrateur doit accorder les autorisations ARSInvoice et ARSVehicleNotification à l’adresse ',
        partTwo: ' Seuls les utilisateurs du concessionnaire autorisés détenant la permission de gérer les véhicules d’échange doivent obtenir les autorisations propres au module de véhicules d’échange du programme ARS.',
      },
    },
  },
  customerActivity: {
    title: 'Activité Client <i style="font-size: 13px;">(à venir bientôt)</i>',
    sectionOne: {
      title: 'Tableau de Bord Amélioré du Concessionnaire (EDD) Rapports <i style="font-size: 13px;">(à venir bientôt)</i>',
      paragraph: '[NT] Your dealership will have access to eCommerce customer activity reporting in Enhanced Dealer Dashboard (EDD) . The eCommerce views will be available in the EDD menu and include rolled up metrics on the "eCommerce Reporting" view and customer level activity on the "eCommerce Lead Detail" view. You can access these views with your existing credentials.  If you do not have access to EDD, contact your dealership administrator to grant access at www.dsps.dealerconnection.com.',
      link: '[NT] enhanced dealer dashboard',
    },
  },
  customerHandling: {
    title: 'Gestion du Client <i style="font-size: 13px;">(à venir bientôt)</i>',
    sectionOne: {
      title: 'Réservations, Commandes, Demandes d’achat de véhicules neufs <i style="font-size: 13px;">(à venir bientôt)</i>',
      videoTitle: '[NT] eCommerce Customer Handling Overview',
      paragraphOne: '[NT] The eCommerce experiences provide your customers the ability to submit Reservations, Order Requests and New Vehicle Purchase Requests with direct customer-to-dealer payments. Any requests a customer submits to your dealership will flow directly into your CRM and into the new Customer Handling Pages. The contact elected to receive these alerts during enrolment will also receive a hot email alert, notifying them of the new request and directing them to the Customer Handling Pages to take action. The Customer Handling Pages provide you access to the selections the customer has made in the online experience. Here you will be able to make decisions on things like accepting the submission, deposits, down payments, accepting trade-in requests and responding to inquiries for online paperwork.',
      paragraphTwo: '[NT] Each overview below provides experience-specific job aids and guides for review before accessing the Customer Handling Pages to view and action upon requests.',
      paragraphThree: '[NT] Assigning EMP_eComm_CustomerHandling and WBDO View and/or Submit permissions is required to manage reservations, order requests and new vehicle purchase requests from your customers. Your dealer administrator can set entitlements at www.dsps.dealerconnection.com. Only authorized dealer users with authority to manage reservations, order requests and new vehicle purchase requests should be granted entitlements.',
      linkOne: '[NT] customer handling pages',
      linkTwo: 'Définir les autorisations',
      errorToast: '[NT] Your dealership does not have entitlements set.',
      reservationRequests: {
        title: '[NT] How do Reservations Requests work?',
        documentsTitle: '[NT] Reservation Job Aids',
        paragraphOne: '[NT] Customers will be able to submit a reservation for a new vehicle for vehicle lines offering the reservation experience. The reservation will flow to the dealership of their choice and contain a deposit to reserve their vehicle. Reservations placed with your dealership can be viewed in your CRM and in the eCommerce Customer Handling Pages.',
        paragraphTwo: '[NT] The EMP_eComm_CustomerHandling and OrderMgt View permissions are required to access and manage Reservations in the Customer Handling Pages.',
        pdfOne: '[NT] Reservation Customer Experience Overview',
        pdfTwo: '[NT] Reservation & Order Customer Handling Guide',
        pdfThree: '[NT] Mustang Mach-e Customer Handling Guide',
        pdfFour: '[NT] Bronco Customer Handling Guide',
        pdfFive: '[NT] eCommerce Customer Communications',
        pdfSix: '[NT] eCommerce Deposits In Dealership',
      },
      orderRequests: {
        title: '[NT] How do Order Requests work?',
        documentsTitle: '[NT] Order Job Aids',
        paragraphOne: '[NT] The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage Order Requests in the Customer Handling Pages.',
        pdfOne: '[NT] Order Customer Experience Overview',
        pdfTwo: '[NT] Reservation & Order Customer Handling Guide',
        pdfThree: '[NT] Payment & Reporting Guide',
        pdfFour: '[NT] eCommerce Customer Communications',
        pdfFive: '[NT] eCommerce Deposits In Dealership',
      },
      fordCartAndCheckoutRequests: {
        title: '[NT] How do New Vehicle Purchase Requests work?',
        documentsTitle: '[NT] eCommerce Cart And Checkout Job Aids',
        paragraphOne: '[NT] eCommerce cart and checkout introduces the ability for customers to select a preferred dealer in Showroom and complete the cart and checkout experience. Customers can search dealer-priced inventory by payment options/methods, generate trade-in values backed by Ford, select from a menu of dealer-priced accessories and protection plans, view personalized taxes and fees, submit a credit application with decisioning and request online paperwork with eSign. New Vehicle Purchase Requests can be viewed in your dealership\'s CRM and in the eCommerce Customer Handling Pages. You, as the dealer, will maintain full control over accepting New Vehicle Purchase Requests and making decisions in reaction to customer requests. Customers will be able to submit New Vehicle Purchase Requests beginning with the Mustang Mach-E, with the expansion of full line of vehicles to follow.',
        paragraphTwo: '[NT] The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage New Vehicle Purchase Requests in the Customer Handling Pages.',
        pdfOne: '[NT] eCommerce Cart And Checkout Readiness Checklist',
        pdfTwo: '[NT] ecommerce Cart And Checkout Customer Experience Overview',
        pdfThree: '[NT] eCommerce Cart And Checkout Playbook',
        pdfFour: '[NT] Customer Handling Overview Job Aid',
        pdfFive: '[NT] Customer Handling Accessories Job Aid',
        pdfSix: '[NT] Customer Handling Trade-in Job Aid',
        pdfSeven: '[NT] Customer Handling F&I Job Aid',
        pdfEight: '[NT] Customer Customer Communications',
        pdfNine: '[NT] crm Templates',
        pdfTen: '[NT] eCommerce Deposits In Dealership',
      },
      lincolnCartAndCheckoutRequests: {
        title: '[NT] How do New Vehicle Purchase Requests work?',
        documentsTitle: '[NT] Cart And Checkout Job Aids',
        paragraphOne: '[NT] Cart and Checkout introduces the ability for Clients to select a preferred Lincoln store in Showroom and complete the Cart and Checkout experience. Clients can search Lincoln store-priced inventory by payment options/methods, generate trade-in values backed by Lincoln, select from a menu of store-priced accessories and protection plans, view personalized taxes and fees, submit a credit application with decisioning and request online paperwork with eSign. New Vehicle Purchase Requests can be viewed in your store\'s CRM and in the eCommerce Client Handling Pages. You, as the store, will maintain full control over accepting New Vehicle Purchase Requests and making decisions in reaction to Client requests. Clients will be able to submit New Vehicle Purchase Requests on your full vehicle line in Mid-Year 2021.',
        paragraphTwo: '[NT] The EMP_eComm_CustomerHandling, OrderMgt View, and OrderMgt Submit permissions are required to access and manage New Vehicle Purchase Requests in the Client Handling Pages.',
        pdfOne: '[NT] Reservation Client Experience Overview',
        pdfTwo: '[NT] Cart and Checkout Playbook',
        pdfThree: '[NT] Client Handling Overview Job Aid',
        pdfFour: '[NT] Client Handling Accessories Job Aid',
        pdfFive: '[NT] Client Handling Trade-in Job Aid',
        pdfSix: '[NT] Client Handling F&I Job Aid',
        pdfSeven: '[NT] CRM Templates',
        pdfEight: '[NT] eCommerce Marketplace Portal Overview',
      },
    },
  },
  success: {
    submitEnrollment: 'Félicitations, votre concessionnaire est maintenant inscrit!',
  },
  errors: {
    TERMS_CONDITIONS_NOT_COMPLETED: 'Accepter les Termes et Conditions.',
    DEALER_INFO_NOT_COMPLETED: 'Ajoutez un téléphone de revendeur et enregistrez avant de continuer.',
    teamSetupNotCompleted: 'Le formulaire Création d’équipe n’est pas rempli.',
    kdmNotSet: 'Au moins un Décisionnaire clé est requis. [NT] More details on KDM\'s can be found in the Terms & Conditions section of enrolment.',
    remoteDeliveryNotSet: 'Option de livraison à distance non définie.',
  },
};
