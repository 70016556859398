export default {
  title: 'Centre de ressources',
  experience: {
    title: 'Sélectionner par expérience client',
    search: 'Cliquez ici pour la recherche par Mot-Clé',
    newContent: 'Nouveau contenu >',
    reserve: {
      title: 'Réserver',
      bottomTitle: '[NT] Alternate Reservation<br />Experiences',
    },
    order: {
      title: 'Commander',
      bottomTitle: '[NT] Alternate Order<br />Experiences',
    },
    cart: {
      title: 'Panier d’achats et paiement',
      fullLine: 'Ligne complète (à venir bientôt)',
    },
  },
  journey: {
    title: 'Parcours du client',
  },
  department: {
    title: 'Sélectionner par service',
  },
  operationalize: {
    title: 'Opérationnaliser commerce électronique à votre concessionnaire',
    subtitle: 'Boîte à outils des directeurs',
    description: '[NT] Three simple activities will help you operationalize eCommerce your way — within your process, with your team, to help reach your Customer Handling goals.',
    item1: '[NT] Manager\'s Meeting',
    item2: '[NT] Department Team Meetings',
    item3: '[NT] eCommerce Readiness Confirmation',
    item4: 'Commencer',
  },
  menu: {
    link1: '[NT] Select by Customer Experience',
    link2: '[NT] Customer Journey',
    link3: '[NT]  eCommerce Marketplace Portal (EMP) Demo',
    link4: '[NT] Operationalize eCommerce in Your Dealership',
    link5: '[NT] Select by Department',
  },
};
