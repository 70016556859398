<template>
  <div
    class="toast"
    :class="toastClasses"
  >
    <FontAwesomeIcon
      v-if="icon"
      class="mr-15"
      size="2x"
      :icon="icon"
    />
    <slot>
      <span>{{ text }}</span>
    </slot>
  </div>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const validTypes = {
  error: 'error',
  success: 'success',
  valid: '',
};

export default {
  name: 'AppToast',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: validTypes.valid,
      validator(type) {
        return Object.values(validTypes).includes(type);
      },
    },
  },
  computed: {
    toastClasses() {
      return {
        'toast--success': this.type === validTypes.success,
        'toast--error': this.type === validTypes.error,
        'toast--valid': this.type === validTypes.valid,
      };
    },
    icon() {
      if (this.type === validTypes.success) {
        return faCheckCircle;
      }

      if (this.type === validTypes.error) {
        return faExclamationTriangle;
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  display: flex;
  align-items: center;
  border-radius: 4px;
  &--error {
    padding: 12px;
    color: $color--fds-error1;
    border: 1px solid $color--fds-error1;
  }
  &--success {
    padding: 12px;
    color: $color--fds-success1;
    border: 1px solid $color--fds-success1;
  }
  &--valid {
    padding: 32px 16px;
    box-shadow: rgba($color--black, 0.25) 0px 0px 20px 0px;
  }
}
</style>
