<template>
  <div
    v-outside-click="closeDropdown"
    class="multiple-dropdown"
    :class="[{ 'multiple-dropdown--active': dropdownIsOpen }, $store.getters['user/activeBrandName']]"
  >
    <label
      v-text="label"
      class="multiple-dropdown__label"
      :for="id"
    />
    <div class="multiple-dropdown__input-wrapper">
      <input
        v-model="inputText"
        class="multiple-dropdown__input"
        type="button"
        @click="toggleDropdownIsOpen"
      />
      <span
        class="multiple-dropdown__icon-arrow app-icon-chevron-down"
        @click="toggleDropdownIsOpen"
      />
      <ul
        v-show="dropdownIsOpen"
        class="multiple-dropdown__list"
      >
        <li class="multiple-dropdown__list-item--sticky">
          <label class="multiple-dropdown__option">
            <input
              v-model="selectAllModelValue"
              class="multiple-dropdown__option-checkbox"
              type="checkbox"
              value="all"
            />
            {{ $l('multiSelectDropdown.label3') }}
          </label>
        </li>
        <li
          v-for="(option, index) in options"
          :key="JSON.stringify(option)"
        >
          <label
            :for="`${id}--option-${index + 1}`"
            class="multiple-dropdown__option"
          >
            <input
              v-model="inputValue"
              :id="`${id}--option-${index + 1}`"
              class="multiple-dropdown__option-checkbox"
              type="checkbox"
              :value="option.value"
            />
            {{ option.text }}
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleDropdown',
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Array, required: true },
    id: { type: String, required: true },
    label: { type: String, required: true },
    options: { type: Array, required: true },
  },
  data() {
    return {
      dropdownIsOpen: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    selectAllModelValue: {
      get() {
        return this.options.length && this.inputValue.length === this.options.length;
      },
      set(selectAll) {
        if (selectAll) {
          this.$emit('update:modelValue', this.options.map(({ value }) => value));
        } else {
          this.$emit('update:modelValue', []);
        }
      },
    },
    inputText() {
      if (this.modelValue.length === 0) {
        return this.$l('multiSelectDropdown.label1');
      }

      return this.$l('multiSelectDropdown.label2', { count: this.modelValue.length });
    },
  },
  methods: {
    toggleDropdownIsOpen() {
      this.dropdownIsOpen = !this.dropdownIsOpen;
    },
    closeDropdown() {
      this.dropdownIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-dropdown {
  --dropwdown-height: 40px;
  --option-hover-color: #{$fordColorSecondary2};
  &.lincoln {
    --option-hover-color: #{$color--lds-secondary};
  }
  &--active {
    .multiple-dropdown__icon-arrow {
      transform: rotate(-180deg);
    }
  }
  &__label {
    margin-bottom: 4px;
  }
  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  &__input {
    width: 100%;
    height: var(--dropwdown-height);
    padding: 4px 32px 4px 8px;
    background-color: $color--white;
    color: #555;
    border: 1px solid #979797;
    border-radius: 2px;
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 50%);
    font-size: 14px;
    font-weight: $font-weight--normal;
    letter-spacing: 1px;
    text-align: left;
    cursor: pointer;
  }
  &__icon-arrow {
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    right: 12px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  &__list {
    max-height: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: var(--dropwdown-height);
    left: 0;
    right: 0;
    background-color: $color--white;
    border: 1px solid #ddd;
    overflow-y: hidden;
    z-index: 2;
    overflow-y: auto;
  }
  &__list-item--sticky {
    position: sticky;
    top: 0;
    background-color: var(--option-hover-color);
    color: $color--white;
  }
  &__option {
    width: 100%;
    padding: 4px 16px;
    cursor: pointer;
    &:hover {
      background-color: var(--option-hover-color);
      color: $color--white;
    }
  }
  &__option-checkbox {
    margin: 0;
    margin-right: 8px;
  }
}
</style>
