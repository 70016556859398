<template>
  <FormMeeting
    v-if="activeDealership.pa === 'FORDC'"
    :dealer="dealer"
  />
  <FormFeedback
    v-else
    :dealer="dealer"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import FormMeeting from './ReadinessPlanModalReadinessCheckFormMeeting.vue';
import FormFeedback from './ReadinessPlanModalReadinessCheckFormFeedback.vue';

export default {
  name: 'ReadinessPlanModalReadinessCheck',
  components: {
    FormMeeting,
    FormFeedback,
  },
  props: {
    dealer: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
    }),
  },
};
</script>
