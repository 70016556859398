export default {
  appName: 'eCommerce',
  links: {
    dashboard: 'Dashboard',
    faqs: 'FAQs',
    help: 'Help',
    lincolnPay: 'Lincoln Pay',
    reports: 'Reports',
    resources: 'Resource Center',
    stripe: 'Stripe <sup>TM</sup>',
    tools: 'Tools',
    commsCenter: 'Comms Center',
  },
};
