export default {
  title: 'Aider',
  heading: 'Des experts hautement formés sont prêts à aider.',
  callHeadquarters: 'Appelez le siège du programme Ford commerce électronique:',
  email: 'Courriel:',
  submitSuccess: 'Soumi commerce électroniqueformulaire réussie.',
  submitError: 'Impossible de soumettre le formulaire',
  form: {
    inputPhone: 'Téléphone primaire',
    selectFeedback: 'Retour',
    selectTopic: 'Sujet de rétroaction',
    inputComment: 'Veuillez fournir les détails de vos commentaires',
    submitButton: 'Soumettre',
  },
  resources: {
    heading1: 'Support supplémentaire',
    text1: 'Fiserv/First Data for FordPay / Lincoln Pay Sales MID Configuration et assistance',
    text2: 'Pour communiquer avec Stripe: ',
    text3: 'Pour des questions générales: ',
    text4: 'Solutions Technologies Ford (STF) pour du soutien concernant VLPlus',
    text5: 'Services de remise en marché des véhicules (ARS) pour du soutien concernant l’inscription et les véhicules d’échange',
    text6: 'Centre de relations avec la clientèle (CRC)',
    text7: 'Ford: ',
    text8: 'Lincoln: ',
  },
  about: {
    appVersion: 'Version de l\'application: {version}',
    apiVersion: 'Version de l\'API: {version}',
    modal: {
      title: 'À propos des expériences commerce électronique',
      environment: 'Environnement',
      app: 'Application',
      api: 'API',
    },
  },
};
