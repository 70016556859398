import CookieManager from '@/helpers/cookieManager';
import BaseAPI from './baseAPI';

export default class Document extends BaseAPI {
  /** Fetch document full URL and extension type
   * @param doc filename of document
   */
  async getDocument(doc) {
    const cookie = CookieManager.get(process.env.VUE_APP_COOKIE_NAME);

    if (cookie.token === '') return { type: 'authRequired', url: '' };
    const { data } = await this.getUnique('/Document', {
      params: {
        doc,
      },
    });
    return data;
  }

  /** Fetch document object
   * @param doc filename of document
   */
  async getDownload(doc) {
    const cookie = CookieManager.get(process.env.VUE_APP_COOKIE_NAME);

    if (cookie.token === '') return '';

    const { data } = await this.getUnique('/DocumentDownload', {
      params: {
        doc,
      },
    });
    return data;
  }

  /** Fetch document in a Base64 encoded string
   * @param doc filename of document
   */
  async getBase64(doc) {
    const { data } = await this.getUnique('/Document/DownloadBase64', {
      params: { doc },
    });
    return data;
  }
}
