<template>
  <section class="customer-experience">
    <div class="customer-experience__header">
      <h2 class="customer-experience__title fds-headline__5">
        {{ $l('resourceCenter.experience.title') }}
      </h2>
      <SearchButton
        :to="{ name: 'ResourceResults', query: { search: true } }"
        :text="$l('resourceCenter.experience.search')"
      />
    </div>
    <div class="customer-experience__content">
      <ResourceCenterCardReserve />
      <ResourceCenterCardOrder />
      <ResourceCenterCardCart />
    </div>
  </section>
</template>

<script>
import SearchButton from './ResourceCenterSearchButton.vue';
import ResourceCenterCardReserve from './ResourceCenterCardReserve.vue';
import ResourceCenterCardOrder from './ResourceCenterCardOrder.vue';
import ResourceCenterCardCart from './ResourceCenterCardCart.vue';

export default {
  name: 'ResourceCenterExperience',
  components: {
    SearchButton,
    ResourceCenterCardReserve,
    ResourceCenterCardOrder,
    ResourceCenterCardCart,
  },
};
</script>

<style lang="scss" scoped>
.customer-experience {
  &__header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin-bottom: 40px;
    @include respond(sm-up) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__title {
    margin: 0;
    text-align: center;
    @include respond(sm-up) {
      text-align: left;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
  }
}
</style>
