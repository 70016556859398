<template>
  <AppAccordion
    :id="accordionId"
    :status="activationSummaryStatus.accessoryPricing"
    :title="$l('modelEDashboard.accessoryPricing.title')"
    :description="$l('modelEDashboard.accessoryPricing.description')"
    :is-open="accordionIsOpen"
    color="primary-light"
    @click="$store.dispatch('modelE/dashboard/accordions/openAccordion', accordionId)"
  >
    <div class="container--accordion-group">
      <AppAccordion
        :id="accessoryPricingId"
        color="secondary-light"
        :track-section-name="accordionId"
        :title="$l('modelEDashboard.accessoryPricing.title')"
        :is-open="activeSubcordionId === accessoryPricingId"
        @click="openSubcordion(accessoryPricingId)"
      >
        <ModelEDashboardAccessoryPricing />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import ModelEDashboardAccessoryPricing from './ModelEDashboardAccessoryPricing.vue';

export default {
  name: 'ModelEAccordionAccessoryPricing',
  components: {
    AppAccordion,
    ModelEDashboardAccessoryPricing,
  },
  computed: {
    ...mapGetters({
      accordions: 'modelE/dashboard/accordions/accordions',
      activeAccordionId: 'modelE/dashboard/accordions/activeAccordionId',
      activeSubcordionId: 'modelE/dashboard/accordions/activeSubcordionId',
      activationSummaryStatus: 'modelE/activationSummary/status',
    }),
    accordionId() {
      return this.accordions.accessoryPricing.id;
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.accordionId;
    },
    accessoryPricingId() {
      return this.accordions.accessoryPricing.accessoryPricing.id;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('modelE/dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
