import BaseAPI from './baseAPI';

export default class Notifications extends BaseAPI {
  async getModelENotifications(dealerId) {
    const { data } = await this.getUnique('/NotificationModelE/NotificationsModelE', {
      params: { dealerId },
    });
    const { notifications } = data.data;
    return notifications;
  }
}
