<template>
  <div class="member-details">
    <div class="member-details__departments">
      <div class="member-details__departments-item">
        <span class="member-details__departments-item-label">
          {{ $l('general.status.active') }}
        </span>
        <AppToggle
          v-model="member.isActive"
          :id="`key-decision-maker--toggle-is-active-${index}`"
          :active-text="$l('general.yes')"
          :inactive-text="$l('general.no')"
        />
      </div>
    </div>
    <form
      class="member-details__form"
      @submit.prevent="submitForm"
    >
      <div class="member-details__form-content">
        <AppDropdown
          v-model="member.position"
          :id="`key-decision-maker--position-${index}`"
          :label="$l('keyDecisionMaker.input.position')"
          :options="kdmPositions"
          :dirty="v$.member.position.$dirty"
          :error-message="v$.member.position.$errors[0]"
        />
        <TextField
          v-model="member.wslxId"
          :id="`key-decision-maker--wslx-${index}`"
          :label="$l('keyDecisionMaker.input.wslx')"
          :max-length="10"
          :dirty="v$.member.wslxId.$dirty"
          :error-message="v$.member.wslxId.$errors[0]"
        />
        <TextField
          v-model.trim="member.email"
          :id="`key-decision-maker--email-${index}`"
          :label="$l('general.email')"
          :dirty="v$.member.email.$dirty"
          :error-message="v$.member.email.$errors[0]"
        />
        <AppDropdown
          v-model="member.emailLanguage"
          :id="`key-decision-maker--email-language-${index}`"
          :label="$l('general.preferredLanguage')"
          :options="emailLanguages"
          :dirty="v$.member.emailLanguage.$dirty"
          :error-message="v$.member.emailLanguage.$errors[0]"
        />
        <TextField
          v-model="member.phoneNumber"
          :id="`key-decision-maker--phone-${index}`"
          mask="000-000-0000"
          :label="$l('keyDecisionMaker.input.phone')"
          :dirty="v$.member.phoneNumber.$dirty"
          :error-message="v$.member.phoneNumber.$errors[0]"
        />
        <TextField
          v-model.trim="member.extension"
          :id="`key-decision-maker--extension-${index}`"
          mask="000000"
          :required="false"
          :max-length="6"
          :label="$l('keyDecisionMaker.input.extension')"
        />
      </div>
      <template v-if="userIsKeyDecisionMaker">
        <AppCheckbox
          v-model="infoIsConfirmed"
          :id="`key-decision-maker--checkbox-${index}`"
          :label="$l('keyDecisionMaker.item.checkboxLabel')"
        />
        <p
          v-if="formError"
          class="member-details__form-error"
        >
          {{ formError }}
        </p>
        <div class="member-details__form-footer">
          <StandardButton
            type="submit"
            size="lg"
            track-event-name="submitForm_keyDecisionMaker"
            track-section-name="keyDecisionMaker"
            :is-loading="isLoading"
            :disabled="formError || !infoIsConfirmed"
            :text="$l('keyDecisionMaker.buttons.save')"
          />
          <StandardButton
            type="button"
            size="lg"
            track-event-name="click_deleteKeyDecisionMaker"
            track-section-name="keyDecisionMaker"
            :is-loading="isDeletingContact"
            :disabled="keyDecisionMakerIsTheOnlyActive"
            :text="$l('keyDecisionMaker.buttons.delete')"
            @click="deleteContact"
          />
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required, email, customMinLength } from '@/helpers/i18nValidators';
import AppToggle from './common/AppToggle.vue';
import AppCheckbox from './common/AppCheckbox.vue';
import AppDropdown from './common/AppDropdown.vue';
import TextField from './common/TextField.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'DashboardKeyDecisionMakerMemberDetails',
  components: {
    AppToggle,
    AppCheckbox,
    AppDropdown,
    TextField,
    StandardButton,
  },
  emits: ['save-contact', 'delete-contact'],
  props: {
    index: { type: Number, required: true },
    isLoading: { type: Boolean, required: true },
    isDeletingContact: { type: Boolean, required: true },
    fullName: { type: String, default: '' },
    contactId: { type: String, required: true },
    email: { type: String, default: '' },
    extension: { type: String, default: '' },
    phoneNumber: { type: String, default: '' },
    position: { type: String, default: '' },
    emailLanguage: { type: String, default: '' },
    userId: { type: String, default: '' },
    isActive: { type: Boolean, required: true },
  },
  setup() {
    return {
      v$: useVuelidate({ $autoDirty: true }),
    };
  },
  data() {
    return {
      infoIsConfirmed: false,
      topBannerMessageId: null,
      member: {
        isActive: this.isActive,
        position: this.position || '',
        wslxId: this.userId || '',
        email: this.email || '',
        emailLanguage: this.emailLanguage || '',
        phoneNumber: this.phoneNumber || '',
        extension: this.extension || '',
      },
    };
  },
  validations() {
    return {
      member: {
        position: { required },
        email: { required, email },
        wslxId: { required },
        emailLanguage: { required },
        phoneNumber: {
          required,
          minLength: customMinLength('validations.phoneNumber', 10),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      kdmPositions: 'dealerPersonnel/keyDecisionMakerPositions',
      emailLanguages: 'dealerPersonnel/emailLanguages',
      activeKeyDecisionMakers: 'dealerPersonnel/activeKeyDecisionMakers',
    }),
    formHasChanges() {
      return this.isActive !== this.member.isActive
      || this.position !== this.member.position
      || this.userId !== this.member.wslxId
      || this.emailLanguage !== this.member.emailLanguage
      || this.email !== this.member.email
      || this.phoneNumber !== this.member.phoneNumber
      || this.extension !== this.member.extension;
    },
    keyDecisionMakerIsActive() {
      return !!this.activeKeyDecisionMakers.find(({ contactID }) => contactID === this.contactId);
    },
    keyDecisionMakerIsTheOnlyActive() {
      return this.activeKeyDecisionMakers.length === 1
        && this.activeKeyDecisionMakers[0].contactID === this.contactId;
    },
    formError() {
      if (this.keyDecisionMakerIsTheOnlyActive && !this.member.isActive) {
        return this.$l('keyDecisionMaker.messages.disableKdm');
      }

      if (!this.keyDecisionMakerIsActive
        && this.activeKeyDecisionMakers.length >= 4
        && this.member.isActive
      ) {
        return this.$l('keyDecisionMaker.messages.fourKeys');
      }

      return '';
    },
  },
  watch: {
    formHasChanges() {
      this.handleFormChanges();
    },
  },
  beforeUnmount() {
    this.removeNotSavedEditMessage();
  },
  methods: {
    async submitForm() {
      if (this.isLoading || this.isDeletingContact) return;

      const formIsValid = await this.v$.$validate();

      if (!formIsValid) return;

      this.$emit('save-contact', this.member);
      this.removeNotSavedEditMessage();
    },
    deleteContact() {
      if (this.isLoading || this.isDeletingContact) return;

      this.$emit('delete-contact');
    },
    async handleFormChanges() {
      if (
        this.userIsKeyDecisionMaker &&
        this.formHasChanges &&
        !this.topBannerMessageId
      ) {
        this.topBannerMessageId = await this.$store.dispatch(
          'topBanner/addNotSavedEdit',
          `${this.$l('keyDecisionMaker.messages.saveKdm')} - ${this.fullName}`,
        );
      }
    },
    removeNotSavedEditMessage() {
      if (this.topBannerMessageId) {
        this.$store.dispatch('topBanner/removeNotSavedEdit', this.topBannerMessageId);
        this.topBannerMessageId = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.member-details {
  &__departments {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__departments-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 170px;
    height: 120px;
  }
  &__departments-item-label {
    margin-bottom: 12px;
  }
  &__form {
    max-width: 700px;
  }
  &__form-content {
    display: grid;
    gap: 48px 40px;
    margin-bottom: 40px;
    @include respond(sm-up) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__form-error {
    color: $color--fds-error1;
    margin-top: 20px;
  }
  &__form-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 40px;
  }
}
</style>
