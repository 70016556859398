<template>
  <ExperienceCard
    v-if="activeBrandIsFord"
    title="Cart and Checkout"
    :experience-id="3"
  >
    <template #top>
      <TextButton
        text="Mustang Mach-E"
        class="text-button"
        track-event-name="click_mustangMachE"
        track-section-name="cartCheckout"
        :to="{ name:'ModelEResourceResults', query: { experience: 11, topic: 185 }}"
      />
      <p class="full-line">
        Full Line (Coming Soon)
      </p>
    </template>
    <template #topFooter>
      <StandardButton
        icon-right="chevron-right"
        track-event-name="select_cartCheckout"
        track-section-name="cartCheckout"
        :to="{ name: 'ModelEResourceResults', query: { experience: 11, topic: 168 }}"
        :text="$l('experienceCard.btnText')"
      />
    </template>
  </ExperienceCard>
  <ExperienceCard
    v-else
    title="Cart and Checkout"
  >
    <template #top>
      <p>Full Line (Coming Soon)</p>
    </template>
    <template #topFooter>
      <StandardButton
        outline
        disabled
        icon-right="chevron-right"
        :text="$l('experienceCard.btnText')"
      />
    </template>
  </ExperienceCard>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import TextButton from './common/TextButton.vue';
import ExperienceCard from './ModelEResourceCenterExperienceCard.vue';

export default {
  name: 'ModelEResourceCenterCardCart',
  components: {
    StandardButton,
    TextButton,
    ExperienceCard,
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
    }),
  },
};
</script>

<style lang="scss" scoped>
.text-button{
  padding-block: 14px;
}
.full-line{
  color: $color--fds-primary;
}
</style>
