export default {
  appName: 'commerce électronique',
  links: {
    dashboard: 'Tableau de bord',
    faqs: 'QFPs',
    help: 'Aider',
    lincolnPay: 'Lincoln Pay',
    reports: 'Rapports',
    resources: 'Centre de ressources',
    stripe: 'Stripe <sup>TM</sup>',
    tools: 'Outils',
    commsCenter: 'Centre de communications',
  },
};
