import BaseAPI from './baseAPI';

export default class Stripe extends BaseAPI {
  async getStripePay() {
    const { data } = await this.axios.get('/Stripe/StripePay');

    return data;
  }

  async getAccountUrl({ dealerId, language }) {
    const { data } = await this.axios.get('/Stripe/StripeAccountUrl', {
      params: { dealerId, language },
    });
    return data;
  }

  async postStripePay({
    dealerId, deposit, depositConfirmed, division, merchantId, merchantIdType, merchantIdConfirmed,
  }) {
    const { data } = await this.axios.post('/Stripe/StripePay', [{
      dealerID: dealerId,
      merchantID: merchantId,
      merchantID_Confirmed: merchantIdConfirmed,
      division,
      deposit,
      deposit_Confirmed: depositConfirmed,
      merchantIDType: merchantIdType,
    }]);

    return data;
  }

  async accountChoice(numberOfAccounts) {
    const { data } = await this.axios.post('/Stripe/StripeAccountChoice', null, {
      params: {
        count: numberOfAccounts,
      },
    });

    return data;
  }

  async getModelE() {
    const { data } = await this.axios.get('/StripeModelE/StripePay');

    return data;
  }

  async postModelE(model) {
    const { data } = await this.postUnique('/StripeModelE/StripePay', model);
    return data;
  }

  async getModelEAccountUrl({ dealerId, language }) {
    const { data } = await this.axios.get('/Stripe/StripeAccountUrl', {
      params: { dealerId, language },
    });
    return data;
  }

}
