<template>
  <div
    class="base-layout"
    :class="$store.getters['user/activeBrandName']"
  >
    <component :is="currentLayout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { experiences } from '@/constants/experiences';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import StripeApplicationLayout from '@/layouts/StripeApplicationLayout.vue';
import ModelELayout from '@/layouts/ModelELayout.vue';

export default {
  name: 'App',
  components: {
    DefaultLayout,
    StripeApplicationLayout,
  },
  computed: {
    currentLayout() {
      switch (this.$route.meta.layout) {
        case 'StripeApplication':
          return StripeApplicationLayout;
        case 'ModelE':
          return ModelELayout;
        default:
          return DefaultLayout;
      }
    },
  },
  watch: {
    currentLayout: {
      immediate: true,
      handler(layout) {
        this.$store.dispatch(
          'user/changeActiveExperience',
          (layout.name === 'ModelELayout') ? experiences.MODEL_E : experiences.BLUE,
        );
      },
    },
  },
};
</script>
