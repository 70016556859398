import apiManager from '@/api';
import { status } from '@/constants/activationSummary';

const normalizeStatus = (value) => {
  switch (value) {
    case 'Active':
      return status.ACTIVE;
    case 'Opt-Out initiated':
      return status.OPT_OUT;
    case 'In Active':
      return status.INACTIVE;
    case 'Not Available':
      return status.NOT_AVAILABLE;
    case 'In Progress':
    default:
      return status.IN_PROGRESS;
  }
};

export default {
  namespaced: true,
  state() {
    return {
      requirements: {},
    };
  },
  getters: {
    percentageComplete: ({ requirements }) => requirements.percentageComplete || 0,
    status: ({ requirements }) => ({
      activation: normalizeStatus(requirements.overallStatusText),
      enrollment: requirements.enrollmentStatus || 'grey',
      stripe: requirements.stripeSetupStatus || 'grey',
      vehiclePricing: requirements.vehiclePricingStatus || 'grey',
      accessoryPricing: requirements.accessoryPricingStatus || 'grey',
      tradeIn: requirements.tradeInSetupStatus || 'grey',
      // fiSetup: requirements.fniSetupStatus || 'grey',
      // protectionPlans: requirements.protectionPlanStatus || 'grey',
      fordInsure: requirements.fordInsuranceStatus || 'grey',
      customerHandling: requirements.customerHandlingSetupStatus || 'grey',
      tablets: requirements.tabletStatus || 'grey',
      // compliance: requirements.modelEStandardsComplianceStatus || 'grey',
    }),
  },
  mutations: {
    setRequirements(state, requirements) {
      state.requirements = requirements;
    },
  },
  actions: {
    async load({ commit, rootGetters }) {
      const data = await apiManager.activationSummary.getModelEData();

      const { dealerCartCheckout: requirements } = data.find(
        ({ division }) => division === rootGetters['user/activeBrand'],
      ) || {};

      if (requirements) {
        commit('setRequirements', requirements);
      }
    },
    async updateProgress({ dispatch, rootGetters }, step) {
      const { dealerId } = rootGetters['user/activeDealership'];

      await apiManager.activationSummary.updateModelEProgress({dealerId, step});

      dispatch('load');
    },
    async updateEnrollmentProgress({ dispatch, rootGetters }, step) {
      const { dealerId } = rootGetters['user/activeDealership'];

      const progress = await apiManager.activationSummary.updateModelEProgress({dealerId, step});

      dispatch('load');

      return progress;
    },
    async deactivateExperience({ rootGetters, dispatch }, { experienceId }) {
      const { dealerId } = rootGetters['user/activeDealership'];

      await apiManager.activationSummary.postModelE({
        type: experienceId,
        from: 'active',
        to: 'inactive',
        dealerId,
      });

      return dispatch('load');
    },
  },
};
