<template>
  <div class="summary-control">
    <template v-if="controlStatus.notAvailable">
      <span class="summary-control__not-available">
        <template v-if="division === 'F'">Not Available</template>
        <template v-else>{{ percentageComplete }}% Complete - Not Available</template>
      </span>
    </template>
    <template v-else>
      <ControlButton
        disabled
        :is-active="controlStatus.inProgress"
      >
        {{ $l('general.status.inProgress') }}
        <template v-if="controlStatus.inProgress">
          {{ Math.floor(percentageComplete) }}%
        </template>
      </ControlButton>
      <ControlButton
        :disabled="controlStatus.inProgress || controlStatus.active"
        :is-active="controlStatus.active"
        @click="activateExperience"
      >
        {{ $l('general.status.active') }}
      </ControlButton>
      <ControlButton
        :disabled="!controlStatus.active"
        :is-active="controlStatus.optOut || controlStatus.inactive"
        @click="deactivateExperience"
      >
        {{ controlStatus.inactive ? $l('general.status.inactive') : $l('general.status.optOut') }}
      </ControlButton>
      <ModalOptOutPermission
        :open="showOptOutPermissionModal"
        @close="showOptOutPermissionModal = false"
      />
      <ModalCancelOptOutPermission
        :open="showCancelOptOutPermissionModal"
        @close="showCancelOptOutPermissionModal = false"
      />
      <ModalReactivatePermission
        :open="showReactivatePermissionModal"
        @close="showReactivatePermissionModal = false"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { status } from '@/constants/activationSummary';
import ControlButton from './ActivationSummaryControlButton.vue';
import ModalOptOutPermission from './ActivationSummaryModalOptOutPermission.vue';
import ModalCancelOptOutPermission from './ActivationSummaryModalCancelOptOutPermission.vue';
import ModalReactivatePermission from './ActivationSummaryModalReactivatePermission.vue';

export default {
  name: 'ActivationSummaryControl',
  components: {
    ControlButton,
    ModalOptOutPermission,
    ModalCancelOptOutPermission,
    ModalReactivatePermission,
  },
  emits: ['activate-experience', 'deactivate-experience', 'cancel-opt-out'],
  props: {
    status: {
      type: String,
      default: status.IN_PROGRESS,
      validator(value) {
        return Object.values(status).includes(value);
      },
    },
    percentageComplete: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showOptOutPermissionModal: false,
      showCancelOptOutPermissionModal: false,
      showReactivatePermissionModal: false,
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
    }),
    controlStatus() {
      return {
        inProgress: this.status === status.IN_PROGRESS,
        active: this.status === status.ACTIVE,
        optOut: this.status === status.OPT_OUT,
        inactive: this.status === status.INACTIVE,
        notAvailable: this.status === status.NOT_AVAILABLE,
      };
    },
  },
  methods: {
    activateExperience() {
      if (!this.userIsKeyDecisionMaker && this.controlStatus.optOut) {
        this.showCancelOptOutPermissionModal = true;
        return;
      }

      if (!this.userIsKeyDecisionMaker && this.controlStatus.inactive) {
        this.showReactivatePermissionModal = true;
        return;
      }

      if (this.controlStatus.optOut) {
        this.$emit('cancel-opt-out');
        return;
      }

      this.$emit('activate-experience');
    },
    deactivateExperience() {
      if (!this.userIsKeyDecisionMaker) {
        this.showOptOutPermissionModal = true;
        return;
      }

      this.$emit('deactivate-experience');
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-control {
  display: flex;
  height: 40px;
  background-color: $color--white;
  border: 1px solid $color--fds-gray2;
  border-radius: 32px;
}
</style>
