export default {
  title: 'FAQs',
  searchLabel: 'Type your question here',
  card: {
    helpful: 'Helpful?',
    feedback: {
      successMessage: 'Thank you for your feedback!',
    },
  },
};
