<template>
  <vueVimeoPlayer
    :player-height="500"
    :video-id="videoUrl"
    @ended="ended"
    @pause="pause"
    @play="play"
    @progress="progress"
  />
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';

export default {
  name: 'VimeoPlayer',
  components: {
    vueVimeoPlayer,
  },
  props: {
    pageName: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    trackable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      action: 'notReady',
      duration: 0,
      percent: 0,
      seconds: 0,
    };
  },
  methods: {
    track() {
      if (this.trackable) {
        this.$store.dispatch('track/video', {
          id: this.videoUrl.split('/').reverse()[0],
          action: this.action,
          duration: this.duration,
          seconds: this.seconds,
          percent: this.percent,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
    ended() {
      this.action = 'ended';
      this.track();
    },
    pause() {
      this.action = 'pause';
      this.track();
    },
    play() {
      this.action = 'play';
      this.track();
    },
    progress(obj) {
      this.duration = obj.duration;
      this.percent = obj.percent;
      this.seconds = obj.seconds;
    },
  },
};
</script>
