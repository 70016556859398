import { createStore } from 'vuex';

import userModule from './modules/user';
import authModule from './modules/auth';
import activationSummaryModule from './modules/activationSummary';
import commsCenterModule from './modules/commsCenter';
import dashboardModule from './modules/dashboard';
import dealerPersonnelModule from './modules/dealerPersonnel';
import documentModalModule from './modules/documentModal';
import featuresModule from './modules/features';
import helpModule from './modules/help';
import localeModule from './modules/locale';
import readinessPlanModule from './modules/readinessPlan';
import reportsModule from './modules/reports';
import stripeModule from './modules/stripe';
import termsAndConditionsModule from './modules/termsAndConditions';
import topBannerModule from './modules/topBanner';
import trackModule from './modules/track';
import welcomeModule from './modules/welcome';
import modelEModule from './modules/modelE';
import powerbiModule from './modules/powerbi';

import watchers from './watchers';

const store = createStore({
  modules: {
    user: userModule,
    activationSummary: activationSummaryModule,
    auth: authModule,
    commsCenter: commsCenterModule,
    dashboard: dashboardModule,
    dealerPersonnel: dealerPersonnelModule,
    documentModal: documentModalModule,
    features: featuresModule,
    help: helpModule,
    locale: localeModule,
    readinessPlan: readinessPlanModule,
    reports: reportsModule,
    stripe: stripeModule,
    termsAndConditions: termsAndConditionsModule,
    topBanner: topBannerModule,
    track: trackModule,
    welcome: welcomeModule,
    modelE: modelEModule,
    powerbi: powerbiModule,
  },
});

watchers(store);

export default store;

export const useStore = () => store;
