<template>
  <AppDialog
    v-bind="$attrs"
    size="md"
    :title="$l('dashboard.stripe.sectionOne.modal.title')"
  >
    <p>{{ $l('dashboard.stripe.sectionOne.modal.subtitle') }}</p>
    <p class="mb-0">
      {{ $l('dashboard.stripe.sectionOne.modalKdm.paragraphOne', { names: kdmNames }) }}
    </p>
  </AppDialog>
</template>

<script>
import AppDialog from './common/AppDialog.vue';

export default {
  name: 'StripeAccountModalKeyDecisionMaker',
  components: {
    AppDialog,
  },
  computed: {
    kdmNames() {
      return this.$store.getters['dealerPersonnel/activeKeyDecisionMakerNames'].join(', ');
    },
  },
};
</script>
