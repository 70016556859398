<template>
  <div>
    <AppAccordion
      :id="accordionId"
      :status="activationSummaryStatus.enrollment"
      :title="$l('modelEDashboard.enrollment.title')"
      :description="$l('modelEDashboard.enrollment.description')"
      :is-open="accordionIsOpen"
      color="primary-light"
      @click="$store.dispatch('modelE/dashboard/accordions/openAccordion', accordionId)"
    >
      <div class="container--accordion-group">
        <AppAccordion
          :id="termsAndConditionsId"
          color="secondary-light"
          :track-section-name="accordionId"
          :title="$l('modelEDashboard.termsAndConditions.title')"
          :is-open="activeSubcordionId === termsAndConditionsId"
          @click="$store.dispatch('modelE/dashboard/accordions/openSubcordion', termsAndConditionsId)"
        >
          <ModelEDashboardTermsAndConditions />
        </AppAccordion>
        <AppAccordion
          :id="teamSetupId"
          color="secondary-light"
          :track-section-name="accordionId"
          :title="$l('modelEDashboard.teamSetup.title')"
          :is-open="activeSubcordionId === teamSetupId"
          @click="$store.dispatch('modelE/dashboard/accordions/openSubcordion', teamSetupId)"
        >
          <ModelEDashboardTeamSetup />
        </AppAccordion>
        <AppAccordion
          :id="remoteDeliveryId"
          color="secondary-light"
          :track-section-name="accordionId"
          :title="$l('modelEDashboard.remoteDelivery.title')"
          :is-open="activeSubcordionId === remoteDeliveryId"
          @click="$store.dispatch('modelE/dashboard/accordions/openSubcordion', remoteDeliveryId)"
        >
          <ModelEDashboardRemoteDelivery />
        </AppAccordion>
      </div>
    </AppAccordion>
    <div
      v-if="showSubmitEnrollmentButton"
      class="submit-enrollment"
    >
      <StandardButton
        :text="$l('modelEDashboard.enrollment.submitButton')"
        :is-loading="isLoading"
        @click="submitEnrollment"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import AppAccordion from './common/AppAccordion.vue';
import StandardButton from './common/StandardButton.vue';
import ModelEDashboardTermsAndConditions from './ModelEDashboardTermsAndConditions.vue';
import ModelEDashboardTeamSetup from './ModelEDashboardTeamSetup.vue';
import ModelEDashboardRemoteDelivery from './ModelEDashboardRemoteDelivery.vue';

export default {
  name: 'ModelEAccordionEnrollment',
  components: {
    AppAccordion,
    StandardButton,
    ModelEDashboardTermsAndConditions,
    ModelEDashboardTeamSetup,
    ModelEDashboardRemoteDelivery,
  },
  emits: ['enrollment-complete'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      activeBrandIsFord: 'user/activeBrandIsFord',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      accordions: 'modelE/dashboard/accordions/accordions',
      activeAccordionId: 'modelE/dashboard/accordions/activeAccordionId',
      activeSubcordionId: 'modelE/dashboard/accordions/activeSubcordionId',
      activeKeyDecisionMakers: 'modelE/dealerPersonnel/activeKeyDecisionMakers',
      activationSummaryStatus: 'modelE/activationSummary/status',
    }),
    accordionId() {
      return this.accordions.enrollment.id;
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.accordionId;
    },
    termsAndConditionsId() {
      return this.accordions.enrollment.termsAndConditions.id;
    },
    teamSetupId() {
      return this.accordions.enrollment.dealerInformationTeamSetup.id;
    },
    remoteDeliveryId() {
      return this.accordions.enrollment.remoteDeliveryPreferences.id;
    },
    showSubmitEnrollmentButton() {
      return this.userIsKeyDecisionMaker && !this.activeDealership.enrolled;
    },

  },
  methods: {
    enrollmentIsComplete(payload) {
      const errors = [];

      if (!payload.termsAndConditionsComplete) {
        errors.push(this.$l('modelEDashboard.errors.TERMS_CONDITIONS_NOT_COMPLETED'));
      }
      if (this.activeKeyDecisionMakers.length < 1) {
        errors.push(this.$l('modelEDashboard.errors.kdmNotSet'));
      }
      if (!payload.dealerInfoComplete) {
        errors.push(this.$l('modelEDashboard.errors.DEALER_INFO_NOT_COMPLETED'));
      }
      if (!payload.teamSetupComplete) {
        errors.push(this.$l('modelEDashboard.errors.teamSetupNotCompleted'));
      }

      if (!payload.remoteDeliverySet) {
        errors.push(this.$l('modelEDashboard.errors.remoteDeliveryNotSet'));
      }

      if (errors.length) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: errors.join(' '),
          duration: 10000,
        });

        return false;
      }

      return true;
    },
    async submitEnrollment() {
      this.isLoading = true;

      try {
        const { success: submitSuccess, data } = await apiManager.enrollment.submitModelE({
          ford: true,
          lincoln: false,
          dealerId: this.activeDealership.dealerId,
        });

        if (!submitSuccess) {
          throw new Error();
        }

        if (!this.enrollmentIsComplete(data)) {
          return;
        }

        const { success: updateProgress } = await this.$store.dispatch(
          'modelE/activationSummary/updateEnrollmentProgress',
          'EnrollmentSection_Completed',
        );

        if (!updateProgress) {
          throw new Error();
        }

        this.$emit('enrollment-complete');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('modelEDashboard.enrollment.errorMessage'),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-enrollment {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 40px 0;
  padding-right: 20px;
}
</style>
