<template>
  <div class="tab-manager-meeting">
    <ol
      v-if="userDealershipPAisFORDC"
      class="list"
    >
      <li>
        {{ $l('readinessPrep.versionOne.itemOne.title') }}
        <ol class="list list--lower-alpha">
          <li>{{ $l('readinessPrep.versionOne.itemOne.list.itemOne') }}</li>
          <li>{{ $l('readinessPrep.versionOne.itemOne.list.itemTwo') }}</li>
          <li>{{ $l('readinessPrep.versionOne.itemOne.list.itemThree') }}</li>
        </ol>
      </li>
      <li>
        {{ $l('readinessPrep.versionOne.itemTwo.title') }}
        <ol class="list list--lower-alpha">
          <li>
            <span v-html="$l('readinessPrep.versionOne.itemTwo.list.itemOne.title')" />
            <ol class="list list--lower-roman">
              <li>{{ $l('readinessPrep.versionOne.itemTwo.list.itemOne.listItemOne') }}</li>
              <li>{{ $l('readinessPrep.versionOne.itemTwo.list.itemOne.listItemTwo') }}</li>
              <li>{{ $l('readinessPrep.versionOne.itemTwo.list.itemOne.listItemThree') }}</li>
              <li>{{ $l('readinessPrep.versionOne.itemTwo.list.itemOne.listItemFour') }}</li>
            </ol>
          </li>
          <li v-html="$l('readinessPrep.versionOne.itemTwo.list.itemTwo')" />
          <li>{{ $l('readinessPrep.versionOne.itemTwo.list.itemThree') }}</li>
          <li>{{ $l('readinessPrep.versionOne.itemTwo.list.itemFour') }}</li>
        </ol>
      </li>
      <li>
        {{ $l('readinessPrep.versionOne.itemThree.title') }}
        <ol class="list list--lower-alpha">
          <li v-html="$l('readinessPrep.versionOne.itemThree.list.itemOne')" />
          <li>{{ $l('readinessPrep.versionOne.itemThree.list.itemTwo') }}</li>
          <li v-html="$l('readinessPrep.versionOne.itemThree.list.itemThree')" />
          <li v-html="$l('readinessPrep.versionOne.itemThree.list.itemFour')" />
          <li v-html="$l('readinessPrep.versionOne.itemThree.list.itemFive')" />
          <li>{{ $l('readinessPrep.versionOne.itemThree.list.itemSix') }}</li>
        </ol>
      </li>
      <li v-html="$l('readinessPrep.versionOne.itemFour')" />
      <li>
        <span v-html="$l('readinessPrep.versionOne.itemFive.title')" />
        <ol class="list list--lower-alpha">
          <li>{{ $l('readinessPrep.versionOne.itemFive.list.itemOne') }}</li>
          <li>{{ $l('readinessPrep.versionOne.itemFive.list.itemTwo') }}</li>
          <li>{{ $l('readinessPrep.versionOne.itemFive.list.itemThree') }}</li>
        </ol>
      </li>
    </ol>
    <template v-else>
      <p>{{ $l('readinessPrep.versionTwo.title') }}</p>
      <ol class="list">
        <li>{{ $l('readinessPrep.versionTwo.itemOne') }}</li>
        <li>
          <span v-html="$l('readinessPrep.versionTwo.itemTwo.title')" />
          <ol class="list list--lower-alpha">
            <li>
              {{ $l('readinessPrep.versionTwo.itemTwo.list.itemOne.pieceOne') }}
              <TextButton
                :to="activateTeamLink"
                :text="$l('readinessPrep.versionTwo.itemTwo.list.itemOne.pieceTwo')"
              />
            </li>
            <li>{{ $l('readinessPrep.versionTwo.itemTwo.list.itemTwo') }}</li>
            <li v-html="$l('readinessPrep.versionTwo.itemTwo.list.itemThree')" />
          </ol>
        </li>
        <li>{{ $l('readinessPrep.versionTwo.itemThree') }}</li>
        <li>
          {{ $l('readinessPrep.versionTwo.itemFour.title') }}
          <ol class="list list--lower-alpha">
            <li v-html="$l('readinessPrep.versionTwo.itemFour.list.itemOne')" />
            <li v-html="$l('readinessPrep.versionTwo.itemFour.list.itemTwo')" />
          </ol>
        </li>
      </ol>
    </template>
    <div
      v-if="documents.length"
      class="document-row"
    >
      <DocumentCard
        v-for="(document, index) in documents"
        :key="index"
        :title="document.title"
        :thumbnail="document.thumbnail"
        :description="document.longdesc"
      />
    </div>
  </div>
</template>

<script>
import TextButton from './common/TextButton.vue';
import DocumentCard from './DocumentCard.vue';

export default {
  name: 'ReadinessPlanModalManagerMeeting',
  components: {
    TextButton,
    DocumentCard,
  },
  data() {
    return {
      activateTeamLink: {
        name: 'Dashboard',
        params: {
          section: 'enrollment',
          sub: 'dealerInformationTeamSetup',
        },
      },
    };
  },
  computed: {
    documents() {
      return this.$store.getters['readinessPlan/activeManagerMeetingDocuments'].documents;
    },
    userDealershipPAisFORDC() {
      return this.$store.getters['user/activeDealership'].pa === 'FORDC';
    },
  },
};
</script>

<style lang="scss">
a {
  color: $color--fds-primary;
}
.list {
  padding-inline-start: 20px;
  @include respond(xs-dn) {
    padding-inline-start: 20px;
  }
  &--lower-alpha {
    list-style: lower-alpha;
  }
  &--lower-roman {
    list-style: lower-roman;
  }
}
.document-row {
  display: flex;
  flex-wrap: wrap;
  gap: 100px 40px;
  width: 100%;
  padding: 0 20px;
  margin-top: 50px;
}
</style>
