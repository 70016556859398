/* eslint-disable */

export const ElementType = {
  ACCORDION: 'accordion',
  BUTTON: 'button',
  CARD: 'card',
  FORM: 'form',
  HYPERLINK: 'hyperlink',
  MODAL: 'modal',
  NAVIGATION: 'navigation',
  SEARCH: 'search',
  TOGGLE: 'toggle',
};

export const Event = {
  ACCESS_PAGE: 'accessPage',
  CLOSE_STRIPE_NOTIFICATION_MODAL: 'Stripe_Modal_v1',
  DOCUMENT_SEARCH: 'documentSearch',
  VIEW_NOTIFICATION: 'viewNotification',
  OPEN_ACCORDION: 'openAccordion',
  OPEN_DEALER_READINESS_MODAL: 'openModal_dealerReadiness',
  SELECT_BRAND: 'selectBrand',
  SELECT_EXPERIENCE_BLUE: 'selectExperience_Blue',
  SELECT_EXPERIENCE_MODEL_E: 'selectExperience_ModelE',
  SELECT_LANGUAGE_ENGLISH: 'selectLanguage_english',
  SELECT_LANGUAGE_FRENCH: 'selectLanguage_french',
  SUBMIT_CONTACT_INFO: 'submitForm_contactInfo',
  SUBMIT_SUBSCRIPTIONS: 'submitForm_subscriptions',
  OPEN_FAQS_QUESTION: 'open_FAQsQuestion',
  SELECT_FAQS_CATEGORY: 'select_FAQsCategory',
  SUBMIT_FAQS_SEARCH: 'submitForm_FAQsSearch',
  OPEN_ABOUT_MODAL: 'openModal_about',
  SUBMIT_HELP_FORM: 'submitForm_help',
  OPEN_OPT_OUT_MODAL: 'openModal_experienceOptOut',
  DEACTIVATE_EXPERIENCE: 'deactivatExperience',
  REACTIVATE_EXPERIENCE: 'reactivateExperience',
  CANCEL_OPT_OUT: 'cancelExperienceOptOut',
  OPEN_MAXIM_TRAK_CONFIRMATION_MODAL: 'openModal_maximTrak',
  SUBMIT_MAXIM_TRAK_ENROLLMENT: 'submitForm_maximTrakEnrollment',
  OPEN_STRIPE_NON_KDM_MODAL: 'openModal_stripeNonKDM',
  OPEN_STRIPE_ACCOUNT_ON_HOLD_MODAL: 'openModal_stripeAccountOnHold',
  DOWNLOAD_DOCUMENT: 'downloadDocument',
  OPEN_DOCUMENT: 'openDocument',
  SHARE_DOCUMENT_BY_EMAIL: 'shareDocumentByEmail',
  SHARE_VIDEO_BY_EMAIL: 'shareVideoByEmail',
  CLOSE_OUTDATED_BROWSER_MODAL: 'closeModal_outdatedBrowser',
  OPEN_VIDEO: 'openVideo',
  OPEN_PROCEED_WITHOUT_ACCEPT_TERMS_MODAL: 'openModal_proceedWithoutAcceptTerms',
  OPEN_NOT_ALLOWED_TO_ACCEPT_TERMS_MODAL: 'openModal_notAllowedToAcceptTerms',
};
