export default {
  dashboard: {
    title: 'Dashboard',
    subtitle: 'Enrol, activate, and maintain',
    description: 'This is your one-stop shop for activation and maintaining eCommerce experiences. Here you can:',
    list: {
      item1: 'Enrol and activate in each eCommerce experience independently',
      item2: 'Assign and update your in-dealership team',
      item3: 'Modify your dealership settings',
      item4: 'Request support',
    },
  },
  commsCenter: {
    title: 'Communications Center',
    subtitle: 'Stay informed on the latest eCommerce updates',
    description: 'The Communications Center houses the latest eCommerce program updates and news, with the most current items on top. This resource offers timely items that can be quickly located and reviewed to be sure you stay informed as the eCommerce program grows.',
  },
  resourceCenter: {
    title: 'Resource Center',
    subtitle: 'Learn More',
    description: 'This is your one-stop shop for eCommerce tools. As you explore you\'ll gather everything you need to assist you in activating and providing the best experience for your eCommerce Clients.',
  },
};
