export default {
  departament: {
    name: 'Département',
    management: 'Direction',
    newCar: 'Véhicules neufs',
    accessories: 'Accessoires',
    usedCar: 'Véhicules d\'occasion',
    fAndI: 'Financement et assurance',
    eCommSales: 'Ventes commerce électronique',
  },
  tooltip: {
    management: 'Responsable de la gestion du programme chez le concessionnaire et du suivi avec les autres membres de l’équipe pour veiller à ce que les étapes d’activation du concessionnaire soient bien exécutées. Responsable de la coordination en vertu de l’alinéa F(ii) et avec le propriétaire pour exécuter la configuration du compte Stripe (cela peut déjà avoir été effectué pour la participation des concessionnaires aux réservations et commandes en ligne).',
    newCar: 'Responsable de l’établissement des prix des véhicules à afficher dans les expériences commerce électronique pour le concessionnaire.',
    usedCar: 'Responsable de l’inscription du concessionnaire à ARS (au besoin) ainsi que de savoir qui a été affecté à la réception de ces clients potentiels et d’assurer la coordination avec ces personnes.',
    accessories: 'Responsable de l’établissement des prix des accessoires à afficher dans les expériences commerce électronique.',
    fAndI: 'Responsable de configurer tous les éléments de financement et d’assurance (lorsqu’ils deviennent accessibles).',
    eCommSales: 'Responsable des réservations, des demandes de commande et des demandes d’achat de véhicules neufs ainsi que du soutien aux clients tout au long du processus d’achat. Vous pouvez attitrer une ou plusieurs de ces responsabilités à des personnes dans l’ensemble du concessionnaire.',
  },
  addContact: {
    buttonText: 'Ajouter une personne-ressource',
  },
  item: {
    toggleDetailsButtonAriaLabel: '[NT] Open contact details',
    saveButtonText: 'Enregistrer la personne-ressource',
    deleteButtonText: 'Supprimer la personne-ressource',
    errorMessage: 'Informations sur le concessionnaire et configuration de l\'équipe',
    tooltipButton: 'ICI',
    reservations: {
      name: 'Réservations:',
      label: 'En cochant cette case, cette personne recevra des alertes chaque fois qu\'un client effectuera une réservation auprès de votre concessionnaire.',
      tooltip: 'Les personnes auront besoin des rôles WBDO « Soumettre » et « EMP_eComm_CustomerHandling » pour voir et accepter les réservations et les commandes dans les pages de traitement de la clientèle. Les rôles WBDO et EMP peuvent être configurés sur www.dsps.dealerconnection.com. Pour afficher une liste des personnes chez votre concessionnaire ayant les rôles WBDO et EMP, veuillez cliquer',
    },
    orders: {
      name: 'Commandes:',
      label: 'En cochant cette case, cette personne recevra des alertes chaque fois qu\'un client fera une demande de commande auprès de votre concessionnaire. Les demandes de commande devront être approuvées et saisies dans WBDO par une personne ayant les rôles appropriés.',
      tooltip: 'Les personnes auront besoin du rôle WBDO « Soumettre » pour saisir les commandes dans les pages de traitement de la clientèle dans WBDO. Les rôles peuvent être configurés sur www.dsps.dealerconnection.com. Pour afficher une liste des personnes chez votre concessionnaire ayant les rôles WBDO et EMP, veuillez cliquer',
    },
    vehicles: {
      name: 'Demandes d\'achat de véhicules neufs:',
      label: 'En cochant cette case, la personne recevra des alertes chaque fois qu\'un client fera une demande d\'achat de véhicule neuf auprès de votre concessionnaire.',
      tooltip: 'Les personnes auront besoin des rôles WBDO « Soumettre » et « EMP_eComm_CustomerHandling » pour traiter les demandes d’achat de véhicules neufs. Les rôles WBDO et EMP peuvent être configurés sur www.dsps.dealerconnection.com. Pour afficher une liste des personnes chez votre concessionnaire ayant les rôles WBDO et EMP, veuillez cliquer',
    },
  },
  input: {
    position: 'Rôle / Titre',
    phone: 'Numéro de téléphone direct/cellulaire',
    extension: 'Poste',
  },
};
