<template>
  <div class="home d-flex flex-column align-center">
    <HomeWelcome v-if="showWelcomeComponent" />
    <HomeTermsAndConditions v-if="showTermsAndConditions" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeWelcome from '@/components/HomeWelcome.vue';
import HomeTermsAndConditions from '@/components/HomeTermsAndConditions.vue';

export default {
  name: 'HomeView',
  components: {
    HomeWelcome,
    HomeTermsAndConditions,
  },
  data() {
    return {
      showWelcomeComponent: false,
      showTermsAndConditions: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      userHasAcceptedTerms: 'termsAndConditions/userHasAcceptedTerms',
      newTermsAvailable: 'termsAndConditions/newTermsAvailable',
    }),
  },
  async mounted() {
    if (!this.activeDealership.enrolled) {
      this.showWelcomeComponent = true;
      return;
    }

    if (!this.newTermsAvailable && this.userHasAcceptedTerms) {
      this.$router.push({ name: 'Dashboard' });
      return;
    }

    this.showTermsAndConditions = true;
  },
};
</script>
