<template>
  <div class="menu-url | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="model-e-menu-url-document"
      :resources="resources"
    />
    <p v-html="$l('modelEDashboard.tablets.menuUrl.p1')" />
    <p v-html="$l('modelEDashboard.tablets.menuUrl.instructions')" />
    <p v-html="$l('modelEDashboard.tablets.menuUrl.p2')" />
    <p v-html="$l('modelEDashboard.tablets.menuUrl.description')" />
    <p v-html="$l('modelEDashboard.tablets.menuUrl.additionalInfo')" />
    <div class="menu-url__container">
      <TextField
        id="model-e-menu-url--input"
        readonly
        :model-value="modelEMenuUrl"
        :label="$l('modelEDashboard.tablets.menuUrl.label')"
      />
      <StandardButton
        class="menu-url__button"
        track-event-name="click_accessModelEMenu"
        track-section-name="modelEMenuUrl"
        :text="$l('modelEDashboard.tablets.menuUrl.buttonText')"
        :to="`https://${modelEMenuUrl}`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import TextField from './common/TextField.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEDashboardMenuUrl',
  components: {
    StandardButton,
    TextField,
    DashboardResourcesDisplay,
  },
  computed: {
    ...mapGetters({
      resources: 'modelE/dashboard/tablets/modelETabletsURLActiveResources',
    }),
    modelEMenuUrl() {
      const { pa } = this.$store.getters['user/activeDealership'];

      return `www.ford.com/guest/menu/?dealerId=${pa}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-url {
  &__container {
    display: grid;
    gap: 24px;
    margin-top: 48px;
    @include respond(sm-up) {
      grid-template-columns: 1fr auto;
    }
  }
  &__button {
    align-self: center;
  }
}
</style>
