import apiManager from '@/api';

const ENV_IS_NOT_PRODUCTION = !['preProd', 'production'].includes(process.env.VUE_APP_BASE_ENVIRONMENT);

export default {
  namespaced: true,
  state() {
    return {
      features: [],
    };
  },
  getters: {
    features({ features }, getters, rootState, rootGetters) {
      return features.filter(({ division, name }) => (
        division === rootGetters['user/activeBrand'] &&
        name.toLowerCase().trim() !== 'blank'
      ));
    },
    activeFeatures(_, getters) {
      const activeFeaturesId = getters.features
        .filter(({ active }) => active)
        .map(({ id }) => id);

      return {
        dealerAccountability: ENV_IS_NOT_PRODUCTION,
        fiAccordion: ENV_IS_NOT_PRODUCTION,
        modelE: activeFeaturesId.includes(2),
        powerBi: activeFeaturesId.includes(3),
      };
    },
  },
  mutations: {
    setFeatures(state, features) {
      state.features = features;
    },
  },
  actions: {
    async getFeatures({ commit }) {
      const features = await apiManager.login.getFeatureFlags();

      commit('setFeatures', features);
    },
  },
};
