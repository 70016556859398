<template>
  <div class="resource-results">
    <PageHeader
      :title="$l('resourceCenter.title')"
      :home-path="{ name: 'ResourceCenter' }"
    />
    <SearchBar
      v-show="showSearchBar"
      :disabled="isLoading"
      @search="getDocuments"
    />
    <div class="resource-results__filters">
      <MultipleDropdown
        v-model="selectedDepartments"
        id="dropdown--departments"
        :label="$l('resourcesSearchBar.filterText1')"
        :options="departmentOptions"
      />
      <MultipleDropdown
        v-model="selectedRoles"
        id="dropdown--roles"
        :label="$l('resourcesSearchBar.filterText2')"
        :options="roleOptions"
      />
      <MultipleDropdown
        v-model="selectedTopics"
        id="dropdown--topics"
        :label="$l('resourcesSearchBar.filterText3')"
        :options="topicOptions"
      />
    </div>
    <div class="resource-results__search-controls">
      <SearchButton
        icon="printer"
        :text="$l('resourcesSearchBar.printPage')"
        @click="printWindow"
      />
      <SearchButton
        :text="$l('resourcesSearchBar.keywordSearch')"
        @click="showSearchBar = !showSearchBar"
      />
    </div>
    <LoadingIndicator v-if="isLoading" />
    <div
      v-else-if="displayedDocuments.length > 0"
      ref="cards"
      class="resource-results__cards"
    >
      <ResultsCard
        v-for="document in displayedDocuments"
        :key="document.documentID"
        :title="document.title"
        :document="document.document"
        :thumbnail="document.thumbnail"
        :read-play-time="document.readPlayTime"
        :video-url="document.video"
        :long-description="document.longdesc"
        :meta="document.meta"
        :published-date="document.publishedDate"
        :related-links="document.relatedLinks"
      />
    </div>
    <h2
      v-else
      v-text="$l('resourceResults.noResultText')"
      class="resource-results__no-results"
    />
    <ScrollToTopButton />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElementType, Event } from '@/constants/track';
import apiManager from '@/api';
import resourceCenterId from '@/data/resourceCenter';
import ScrollToTopButton from '@/components/common/ScrollToTopButton.vue';
import MultipleDropdown from '@/components/common/MultipleDropdown.vue';
import SearchBar from '@/components/ResourceResultsSearchBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import ResultsCard from '@/components/ResourceResultsCard.vue';
import SearchButton from '@/components/ResourceCenterSearchButton.vue';

export default {
  name: 'ResourceResultsView',
  components: {
    SearchBar,
    PageHeader,
    LoadingIndicator,
    ScrollToTopButton,
    ResultsCard,
    MultipleDropdown,
    SearchButton,
  },
  data() {
    return {
      isLoading: false,
      showSearchBar: false,
      filters: {
        departments: [],
        roles: [],
        topics: [],
      },
      documents: [],
      selectedDepartments: [],
      selectedTopics: [],
      selectedRoles: [],
    };
  },
  computed: {
    ...mapGetters({
      locales: 'locale/locales',
      activeLocale: 'locale/activeLocale',
      activeBrand: 'user/activeBrand',
    }),
    departmentOptions() {
      return this.filters.departments
        .filter(({ language }) => language === this.activeLocale)
        .map(({ text, id }) => ({
          text,
          value: id,
        }));
    },
    roleOptions() {
      return this.filters.roles
        .filter(({ language }) => language === this.activeLocale)
        .map(({ text, id }) => ({
          text,
          value: id,
        }));
    },
    topicOptions() {
      return this.filters.topics
        .filter(({ language }) => language === this.activeLocale)
        .map(({ text, id }) => ({
          text,
          value: id,
        }));
    },
    displayedDocuments() {
      return this.documents.filter(({
        assignedPages,
        language,
        division,
        meta: { departments, roles, topics },
      }) => {
        if (
          assignedPages.length === 1 &&
          assignedPages[0].pageID === resourceCenterId.page.REPORTS
        ) {
          return false;
        }

        if (language !== this.activeLocale || !division.includes(this.activeBrand)) {
          return false;
        }

        const hasId = (meta, selectedIds) => {
          const ids = meta.map(({ id }) => id);

          return selectedIds.length > 0
            ? ids.some((id) => selectedIds.includes(id))
            : true;
        };

        const hasDepartments = hasId(departments, this.selectedDepartments);
        const hasRoles = hasId(roles, this.selectedRoles);
        const hasTopics = hasId(topics, this.selectedTopics);

        return hasDepartments && hasRoles && hasTopics;
      });
    },
  },
  async mounted() {
    this.getFilters();
    this.getDocuments();

    const {
      search, department, role, topic,
    } = this.$route.query;

    if (search) {
      this.showSearchBar = true;
    }

    if (department && !Number.isNaN(+department)) {
      this.selectedDepartments.push(+department);
    }

    if (role && !Number.isNaN(+role)) {
      this.selectedRoles.push(+role);
    }

    if (topic && !Number.isNaN(+topic)) {
      this.selectedTopics.push(+topic);
    }
  },
  methods: {
    async getFilters() {
      const {
        data: { departments, roles, topics },
      } = await apiManager.resourceCenter.getFilters();

      this.filters.departments = departments;
      this.filters.roles = roles;
      this.filters.topics = topics;
    },
    async getDocuments(keyword) {
      let experience = this.$route.query.experience || '0';

      if (keyword) {
        experience = '0';

        this.selectedDepartments = [];
        this.selectedRoles = [];
        this.selectedTopics = [];

        this.$store.dispatch('track/event', {
          event: `${Event.DOCUMENT_SEARCH}_${keyword.replaceAll(' ', '')}`,
          elementType: ElementType.SEARCH,
          pageName: this.$route.meta.track.pageName,
        });
      }

      this.isLoading = true;

      try {
        const [
          { value: { data: { result: englishDocuments } } },
          { value: { data: { result: frenchDocuments } } },
        ] = await Promise.allSettled([
          apiManager.resourceCenter.getResults({
            newContent: this.$route.params.query || false,
            experience,
            keyword,
            language: this.locales.english,
          }),
          apiManager.resourceCenter.getResults({
            newContent: this.$route.params.query || false,
            experience,
            keyword,
            language: this.locales.french,
          }),
        ]);

        this.documents = [
          ...englishDocuments,
          ...frenchDocuments,
        ];
      } catch (error) {
        this.documents = [];
      } finally {
        this.isLoading = false;
      }
    },
    printWindow() {
      if (this.isLoading || this.displayedDocuments.length < 1) {
        return;
      }

      const printWindow = window.open('');

      printWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <style>body {max-width: 850px;}</style>
          </head>
          <body>
            ${this.$refs.cards.innerHTML || ''}
          </body>
        </html>
      `);

      // wait a bit to allow images to load
      window.setTimeout(() => {
        printWindow.stop();
        printWindow.print();
        printWindow.close();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-results {
  &__filters {
    display: grid;
    gap: 32px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 24px;
    @include respond(sm-up) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__search-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 40px;
    padding: 0 16px;
  }
  &__cards {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0 20px;
  }
  &__no-results {
    margin: 0;
    text-align: center;
  }
}
</style>
