export default {
  instructions: 'Please review your dealership\'s information and set your In-Dealership team. You may add more than one team member to a role and each role may apply to more than one person. Be sure you are selecting the person within your dealership that is best suited to complete the required setup pieces per role. To learn more about each role, click the information circle next to the title.',
  section1Title: 'Dealer Information',
  section2Title: 'In-Dealership Setup Team',
  saveButtonText: 'Save',
  phoneNumber: {
    error: 'Failed to save phone number. Please try again.',
  },
  enrollmentProgress: {
    success: 'Enrolment progress successfully updated.',
    error: 'Failed to update the enrolment progress. Please try again.',
  },
};
