<template>
  <div class="model-e-dashboard">
    <ModelEDashboardHeader />
    <ModelEAccordionNotification />
    <ModelEAccordionEnrollment @enrollment-complete="handleEnrollmentComplete" />
    <template v-if="activeDealership.enrolled">
      <ModelEAccordionStripeAccount />
      <ModelEAccordionVehiclePricing />
      <ModelEAccordionAccessoryPricing />
      <ModelEAccordionTradeIn />
      <ModelEAccordionProtectionPlans />
      <ModelEAccordionTablets />
      <ModelEAccordionStandardCompliance />
      <ModelEAccordionCustomerHandling />
    </template>
    <ModalEnrollmentSuccessful
      :open="showEnrollmentModal"
      @close="showEnrollmentModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import modelEKeyDecisionMakerAlertMixin from '@/mixins/modelEKeyDecisionMakerAlertMixin';
import ModelEDashboardHeader from '@/components/ModelEDashboardHeader.vue';
import ModelEAccordionNotification from '@/components/ModelEAccordionNotification.vue';
import ModelEAccordionEnrollment from '@/components/ModelEAccordionEnrollment.vue';
import ModelEAccordionStripeAccount from '@/components/ModelEDashboardAccordionStripeAccount.vue';
import ModelEAccordionVehiclePricing from '@/components/ModelEAccordionVehiclePricing.vue';
import ModalEnrollmentSuccessful from '@/components/ModelEDashboardModalEnrollmentSuccessful.vue';
import ModelEAccordionAccessoryPricing from '@/components/ModelEAccordionAccessoryPricing.vue';
import ModelEAccordionTradeIn from '@/components/ModelEAccordionTradeIn.vue';
import ModelEAccordionTablets from '@/components/ModelEAccordionTablets.vue';
import ModelEAccordionStandardCompliance from '@/components/ModelEAccordionStandardCompliance.vue';
import ModelEAccordionCustomerHandling from '@/components/ModelEAccordionCustomerHandling.vue';
import ModelEAccordionProtectionPlans from '../components/ModelEAccordionProtectionPlans.vue';

export default {
  name: 'ModelEDashboardView',
  components: {
    ModelEDashboardHeader,
    ModelEAccordionNotification,
    ModelEAccordionEnrollment,
    ModalEnrollmentSuccessful,
    ModelEAccordionAccessoryPricing,
    ModelEAccordionTradeIn,
    ModelEAccordionStripeAccount,
    ModelEAccordionTablets,
    ModelEAccordionStandardCompliance,
    ModelEAccordionCustomerHandling,
    ModelEAccordionVehiclePricing,
    ModelEAccordionProtectionPlans,
  },
  mixins: [modelEKeyDecisionMakerAlertMixin],
  data() {
    return {
      showEnrollmentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      activeDealership: 'user/activeDealership',
      dashboardAccordions: 'modelE/dashboard/accordions/accordions',
      activeAccordionId: 'modelE/dashboard/accordions/activeAccordionId',
      notifications: 'modelE/dashboard/notifications/notifications',
    }),
  },
  async mounted() {
    this.getNotifications();
    this.$store.dispatch('modelE/dashboard/loadData');
    this.$store.dispatch('modelE/dealerPersonnel/loadContacts');
    this.$store.dispatch('modelE/dashboard/getResources');
    this.$store.dispatch('modelE/activationSummary/load');
  },
  beforeUnmount() {
    this.closeAccordions();
  },
  methods: {
    closeAccordions() {
      this.$store.dispatch('modelE/dashboard/accordions/resetActiveAccordion');
      this.$store.dispatch('modelE/dashboard/accordions/resetActiveSubcordion');
    },
    async getNotifications() {
      await this.$store.dispatch('modelE/dashboard/notifications/getNotifications');

      if (this.activeAccordionId || !this.notifications.length) return;

      this.$store.dispatch(
        'modelE/dashboard/accordions/openAccordion',
        this.dashboardAccordions.notifications.id,
      );
    },
    async handleEnrollmentComplete() {
      this.showEnrollmentModal = true;

      this.$store.dispatch('user/loadUser');

      this.closeAccordions();
      this.getNotifications();
    },
  },
};
</script>
