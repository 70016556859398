<template>
  <AppAccordion
    :id="stripeAccountSetupId"
    :title="$l('dashboard.stripe.title')"
    :is-open="accordionIsOpen"
    @click="$store.dispatch('dashboard/accordions/openAccordion', stripeAccountSetupId)"
  >
    <div class="container--accordion-group">
      <AppAccordion
        :id="feeStripeAccountId"
        color="secondary"
        :track-section-name="stripeAccountSetupId"
        :title="$l('dashboard.stripe.sectionOne.title')"
        :is-open="activeSubcordionId === feeStripeAccountId"
        @click="openSubcordion(feeStripeAccountId)"
      >
        <DashboardStripeAccount />
      </AppAccordion>
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import DashboardStripeAccount from './DashboardStripeAccount.vue';

export default {
  name: 'DashboardAccordionStripeAccount',
  components: {
    AppAccordion,
    DashboardStripeAccount,
  },
  computed: {
    ...mapGetters('dashboard/accordions', {
      accordions: 'accordions',
      activeAccordionId: 'activeAccordionId',
      activeSubcordionId: 'activeSubcordionId',
    }),
    activeBrandIsFord() {
      return this.$store.getters['user/activeBrandIsFord'];
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.stripeAccountSetupId;
    },
    stripeAccountSetupId() {
      return this.accordions.stripeAccountSetup.id;
    },
    feeStripeAccountId() {
      return this.accordions.stripeAccountSetup.feeStripeAccount.id;
    },
  },
  methods: {
    openSubcordion(subcordionId) {
      this.$store.dispatch('dashboard/accordions/openSubcordion', subcordionId);
    },
  },
};
</script>
