export default {
  title: 'Décisionnaire(s) clé(s)',
  description1: 'Pour vous inscrire avec succès, vous devez désigner au moins un décisionnaire clé au sein de votre concessionnaire qui aura l’autorité nécessaire pour prendre les décisions relatives au programme de commerce électronique. Vous pouvez désigner jusqu’à quatre décisionnaires clés au total, y compris vous-même. Un pouvoir de gestion F(ii) est requis pour désigner des décisionnaires clés, et pour être désigné décisionnaire clé.',
  description2: '[NT] When updated Terms & Conditions are sent, your dealership will need to agree to them to remain in the program. The functionality below allows you to add up to three Key Decision Makers, in addition to yourself, who will be authorized to agree to the updated Terms & Conditions.',
  tooltip: '[NT] The Key Decision Maker has the authority to agree to Terms & Conditions and manage the eCommerce team members for your dealership.',
  form: {
    position: '[NT] Role / Title',
    phone: '[NT] Direct Phone or Cell',
    extension: '[NT] Extension',
    wslx: '[NT] WSLX ID (FMCDealer Login)',
    checkbox: '[NT] By selecting the checkbox, I acknowledge that the information provided is correct and that I am authorized (with F(ii) Authority) to complete the enrolment for my dealership.',
    addButton: '[NT] Add Key Decision Maker',
    saveButton: '[NT] Save Contact',
    deleteButton: '[NT] Delete Contact',
    unsavedChanges: '[NT] Key Decision Maker',
  },
};
