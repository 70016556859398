<template>
  <div
    class="card"
    :class="[{ 'card--open': isOpen }, $store.getters['user/activeBrandName']]"
  >
    <button
      class="card__question"
      type="button"
      aria-label="Open card"
      @click="$emit('toggle-is-open')"
    >
      <p
        v-html="question"
        class="card__question-text"
      />
      <AppChevron :open="isOpen" />
    </button>
    <TransitionExpandCollapse>
      <div
        v-show="isOpen"
        class="card__answer"
      >
        <p
          v-html="answer"
          class="card__answer-text"
        />
        <div class="card__feedback">
          <span>{{ $l('faqs.card.helpful') }}</span>
          <button
            class="card__feedback-button card__feedback-button--positive"
            type="button"
            aria-label="Helpful"
            @click="sendFeedback(true)"
          >
            <span class="app-icon-thumb-up" />
          </button>
          <button
            class="card__feedback-button card__feedback-button--negative"
            type="button"
            aria-label="Not helpful"
            @click="sendFeedback(false)"
          >
            <span class="app-icon-thumb-down" />
          </button>
        </div>
      </div>
    </TransitionExpandCollapse>
  </div>
</template>

<script>
import apiManager from '@/api';
import AppChevron from './common/AppChevron.vue';
import TransitionExpandCollapse from './TransitionExpandCollapse.vue';

export default {
  name: 'FAQsCard',
  components: {
    AppChevron,
    TransitionExpandCollapse,
  },
  emits: ['toggle-is-open'],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      answerIsVisible: false,
    };
  },
  methods: {
    async sendFeedback(isHelpful) {
      await apiManager.faqs.registerUserFeedback({
        questionId: this.id,
        helpful: isHelpful,
      });

      this.$store.dispatch('topBanner/open', {
        message: this.$l('faqs.card.feedback.successMessage'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  --border-color: #{$fordColorPrimary2};
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &--open {
    --border-color: #{$color--fds-secondary};
  }
  &.lincoln {
    --border-color: #{$color--lds-primary};
    &.card--open {
      --border-color: #{$color--lds-secondary};
    }
  }
  &__question {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10%;
    width: 100%;
    align-items: center;
    padding: 20px 32px;
    background-color: #f7f7f7;
    border: 0;
    border-left: 5px solid var(--border-color);
    text-align: left;
    @media screen and (min-width: $sm-breakpoint) {
      padding: 40px 64px;
    }
  }
  &__question-text, &__answer-text {
    margin: 0;
    font-size: 16px;
  }
  &__answer {
    padding: 40px;
  }
  &__feedback {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  &__feedback-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: transparent;
    padding: 0;
    border: 0;
    font-size: 20px;
    &--positive {
      color: $color--fds-success1;
    }
    &--negative {
      color: $color--fds-error1;
    }
  }
}
</style>
