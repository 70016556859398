<template>
  <div class="resource-center">
    <PageHeader :title="$l('resourceCenter.title')" />
    <div class="resource-center__content">
      <ResourceCenterExperience />
      <hr class="resource-center__divider" />
      <ResourceCenterJourney />
      <hr class="resource-center__divider" />
      <ResourceCenterOperationalize />
      <hr class="resource-center__divider" />
      <ResourceCenterDepartment />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import ResourceCenterExperience from '@/components/ResourceCenterExperience.vue';
import ResourceCenterJourney from '@/components/ResourceCenterJourney.vue';
import ResourceCenterDepartment from '@/components/ResourceCenterDepartment.vue';
import ResourceCenterOperationalize from '@/components/ResourceCenterOperationalize.vue';

export default {
  name: 'ResourceCenterView',
  components: {
    PageHeader,
    ResourceCenterExperience,
    ResourceCenterJourney,
    ResourceCenterDepartment,
    ResourceCenterOperationalize,
  },
};
</script>

<style lang="scss" scoped>
.resource-center {
  &__content {
    max-width: 1290px;
    margin: 0 auto;
  }
  &__divider {
    height: 2px;
    margin: 40px 0;
    background-color: #ddd;
  }
}
</style>
