<template>
  <div class="model-e-help">
    <ModelEPageHeader :title="$l('modelEHelp.title')" />
    <ModelESheet>
      {{ $l('modelEHelp.heading') }}
    </ModelESheet>
    <div class="model-e-help__content">
      <p>
        {{ $l('modelEHelp.callHeadquarters') }}
        <AppLink
          track-event-name="click_headquartersPhone"
          track-section-name="program-headquarters"
          to="tel:18552256341"
        >
          855-225-6341
        </AppLink>
      </p>
      <p>
        {{ $l('help.email' ) }}
        <AppLink
          track-event-name="click_helpdesk@eCommDealerSetup.ca"
          track-section-name="program-headquarters"
          to="mailto:helpdesk@eCommDealerSetup.ca"
        >
          helpdesk@eCommDealerSetup.ca
        </AppLink>
      </p>
      <ModelEHelpForm />
      <ModelEHelpResources />
    </div>
  </div>
</template>

<script>
import AppLink from '@/components/common/AppLink.vue';
import ModelESheet from '@/components/ModelESheet.vue';
import ModelEPageHeader from '@/components/ModelEPageHeader.vue';
import ModelEHelpForm from '@/components/ModelEHelpForm.vue';
import ModelEHelpResources from '@/components/ModelEHelpResources.vue';

export default {
  name: 'ModelEHelpView',
  components: {
    AppLink,
    ModelEPageHeader,
    ModelESheet,
    ModelEHelpForm,
    ModelEHelpResources,
  },
};
</script>

<style lang="scss" scoped>
.model-e-help__content {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 40px;
}
</style>
