import BaseAPI from './baseAPI';

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export default class Tablets extends BaseAPI {
  async getDealerTablets({ dealerId }) {
    const { data } = await this.getUnique('/Document/api/DealerTablets', {
      params: { dealerID: dealerId },
    });
    const tablets = data.data ? data.data : [];
    return tablets;
  }

  async uploadTabletImage({ file, dealerId }) {
    const base64Image = await toBase64(file);

    const { data } = await this.postUnique('/Document/api/UploadDealerTabletImage', {
      imgText: base64Image,
      dealerID: dealerId,
    });

    return data;
  }
}
