import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

export default {
  namespaced: true,
  state() {
    return {
      entitlements: [],
      enrolledStatus: [],
    };
  },
  getters: {
    entitlements: (state) => [...state.entitlements],
    isEnrolledInARS(state, getters, rootState, rootGetters) {
      return state.enrolledStatus.find(
        ({ division }) => division === rootGetters['user/activeBrand'],
      )?.enrolled;
    },
    tradeInTeam(state, getters, rootState, rootGetters) {
      return rootGetters['dealerPersonnel/contacts']
        .filter(({ contactID, tradeTool }) => contactID && tradeTool)
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`);
    },
    resources(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        resourceCenterId.section.TRADE_IN,
        resourceCenterId.subsection.ARS_ENROLLMENT_SECTION,
      );
    },
    formDocuments(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        resourceCenterId.section.TRADE_IN,
        resourceCenterId.subsection.ARS_ENROLLMENT_SECTION2,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
    activeFormDocuments(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.formDocuments,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
  },
  mutations: {
    setEntitlements(state, entitlements) {
      state.entitlements = entitlements;
    },
    setEnrolledStatus(state, enrolledStatus) {
      state.enrolledStatus = enrolledStatus;
    },
  },
  actions: {
    async load({ commit }) {
      const [
        { value: { data: entitlements } },
        { value: { data: enrolledStatus } },
      ] = await Promise.allSettled([
        apiManager.tradeIn.getEntitlements(),
        apiManager.tradeIn.getEnrolledStatus(),
      ]);

      commit('setEntitlements', entitlements || []);
      commit('setEnrolledStatus', enrolledStatus);
    },
  },
};
