<template>
  <AppAccordion
    :id="accordionId"
    color="primary-light"
    :title="$l('modelEDashboard.notifications.title')"
    :description="accordionDescription"
    :is-open="accordionIsOpen"
    @click="$store.dispatch('modelE/dashboard/accordions/openAccordion', accordionId)"
  >
    <div class="container--accordion-group">
      <ModelEDashboardNotification
        v-for="(notification, index) in notifications"
        :id="`notification--${index}`"
        :key="index"
        :message="notification.contentMessage"
        :status="notification.notificationStatus"
        :url="notification.url"
      />
    </div>
  </AppAccordion>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAccordion from './common/AppAccordion.vue';
import ModelEDashboardNotification from './ModelEDashboardNotification.vue';

export default {
  name: 'ModelEAccordionNotification',
  components: {
    AppAccordion,
    ModelEDashboardNotification,
  },
  computed: {
    ...mapGetters({
      accordions: 'modelE/dashboard/accordions/accordions',
      activeAccordionId: 'modelE/dashboard/accordions/activeAccordionId',
      notifications: 'modelE/dashboard/notifications/notifications',
    }),
    accordionId() {
      return this.accordions.notifications.id;
    },
    accordionDescription() {
      return this.$l('modelEDashboard.notifications.description', {
        count: this.notifications.length,
      });
    },
    accordionIsOpen() {
      return this.activeAccordionId === this.accordionId;
    },
  },
};
</script>
