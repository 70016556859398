<template>
  <div class="help">
    <PageHeader :title="$l('help.title')" />
    <div class="help__content">
      <h2 class="help__title">
        {{ $l('help.heading') }}
      </h2>
      <p>
        {{ $l('help.callHeadquarters') }}
        <AppLink
          to="tel:18552256341"
          track-event-name="click_headquartersPhone"
          track-section-name="program-headquarters"
        >
          855-225-6341
        </AppLink>
      </p>
      <p>
        {{ $l('help.email' ) }}
        <AppLink
          to="mailto:helpdesk@eCommDealerSetup.ca"
          track-event-name="click_mailto:helpdesk@eCommDealerSetup.ca"
          track-section-name="program-headquarters"
        >
          helpdesk@eCommDealerSetup.ca
        </AppLink>
      </p>
      <HelpForm />
      <HelpResources />
      <HelpAbout />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import HelpForm from '@/components/HelpForm.vue';
import HelpResources from '@/components/HelpResources.vue';
import AppLink from '@/components/common/AppLink.vue';
import HelpAbout from '@/components/HelpAbout.vue';

export default {
  name: 'HelpView',
  components: {
    PageHeader,
    HelpForm,
    HelpResources,
    AppLink,
    HelpAbout,
  },
};
</script>

<style lang="scss" scoped>
.help {
  &__title {
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: 300;
  }
  &__content {
    max-width: 900px;
    margin: 0 auto;
  }
}
</style>
