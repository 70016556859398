<template>
  <AppDialog
    v-bind="$attrs"
    :dismissible="false"
  >
    <slot />
    <template #footer>
      <slot name="footer" />
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from './AppDialog.vue';

export default {
  name: 'PromptDialog',
  components: {
    AppDialog,
  },
};
</script>
