<template>
  <div>
    <AppDialog
      size="md"
      :open="showPrompt"
      :title="$l('dashboardStripeAccountSetup.text1')"
    >
      <p>{{ $l('dashboardStripeAccountSetup.text9') }}</p>
      <p>{{ $l("dashboardStripeAccountSetup.p6") }}</p>
      <div
        class="d-flex"
        style="margin-left: -20px"
      >
        <StandardButton
          track-event-name="click_setupOneId"
          :text="$l('dashboardStripeAccountSetup.btn5')"
          style="margin: 20px"
          @click="handleConfirm(true)"
        />
        <StandardButton
          track-event-name="click_setupTwoIds"
          :text="$l('dashboardStripeAccountSetup.btn6')"
          style="margin: 20px"
          @click="handleConfirm(false)"
        />
      </div>
    </AppDialog>
    <div class="stripe-account-number-fee">
      <SubsectionHeader>{{ $l('stripeAccountIdFees.text1') }}</SubsectionHeader>
      <p>{{ $l("stripeAccountIdFees.p3") }}</p>
      <p class="mt-2">
        {{ $l("stripeAccountIdFees.modelE") }}
      </p>
      <div class="d-flex align-center flex-wrap mt-4">
        <AppLogo />
        <div>
          <span class="stripe-account-number-fee__label ml-3">{{
            $l("stripeAccountIdFees.text2")
          }}</span>&nbsp;
          <span class="stripe-account-number-fee__contact">
            {{ primaryContact }}
          </span>
        </div>
      </div>
      <div class="stripe-account-number-fee__boxed">
        <template v-if="applicationStatus === 'active'">
          <div
            class="stripe-account-id"
          >
            <form
              class="stripe-account-id__form"
              @submit.prevent="submit(1)"
            >
              <div class="stripe-account-id__field-wrapper">
                <div
                  class="stripe-account-id__field-label"
                >
                  <span
                    v-text="$l('stripe.feeSetup.card.accountId.input')"
                    class="stripe-account-id__field-label-text"
                  />
                  <AppTooltip :text="$l('stripe.feeSetup.card.accountId.tooltip')" />
                </div>
                <TextField
                  v-model="mid.reservation.merchantID"
                  id="stripe-field-account-id"
                  class="stripe-account-id__field"
                  disabled
                />
              </div>
              <span
                v-if="mid.reservation.merchantID_Confirmed"
                v-text="$l('general.confirmed')"
                class="stripe-account-id__confirmed-message"
              />
              <template v-if="!mid.reservation.merchantID_Confirmed && userIsKeyDecisionMaker">
                <AppCheckbox
                  v-model="mid.reservation.localConfirm"
                  id="stripe-account-id__checkbox"
                  :label="$l('general.confirm')"
                />
                <StandardButton
                  type="submit"
                  track-event-name="submitForm_accountId"
                  track-section-name="stripeAccountId"
                  :is-loading="isLoading"
                  :disabled="!mid.reservation.localConfirm"
                  :text="$l('general.save')"
                />
              </template>
            </form>
            <TextButton
              to="/Help?feedback=2&topic=8"
              :text="$l('stripe.feeSetup.card.accountId.questions')"
            />
          </div>
        </template>
        <div class="accordion-row">
          <AppAccordion
            v-for="accordion in accordions"
            :id="accordion.id"
            :key="accordion.id"
            color="secondary"
            :title="accordion.title"
            :is-open="activeAccordionId === accordion.id"
            @click="openAccordion(accordion.id)"
          >
            <!-- RESERVATION -->
            <div
              v-if="accordion.id === accordions[0].id"
              class="row mt-2"
            >
              <div class="reservation-wrapper | col-md-10 d-flex flex-wrap align-center">
                <TextField
                  v-model="reservationText"
                  id="stripe-field-oem"
                  :label="$l('stripeAccountIdFees.text8')"
                  description="OEM Set"
                  disabled
                >
                  <template #labelTooltip>
                    &nbsp; <AppTooltip :text="$l('stripe.feeSetup.card.accountId.tooltip2')" />
                  </template>
                </TextField>
              </div>
            </div>
            <!-- ORDERS -->
            <ModelEStripeAccountIdFeesOrder
              v-if="accordion.id === accordions[1].id"
              :mid="mid.order"
              @selection="updateSelection($event, 'order')"
              @action="submit(2)"
            />
            <!-- CART AND CHECKOUT -->
            <ModelEStripeAccountIdFeesCartCheckout
              v-if="accordion.id === accordions[2].id"
              :mid="mid.cartcheckout"
              @selection="updateSelection($event, 'cartcheckout')"
              @selectionACH="updateSelectionACH($event)"
              @selectionAllPayment="updateSelectionAllPayments($event)"
              @action="submit(3)"
              @actionACH="submit(4)"
              @actionAllPayments="submit(5)"
            />
          </AppAccordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubsectionHeader from '@/components/StripeSubsectionHeader.vue';
import AppLogo from '@/components/common/AppLogo.vue';
import StandardButton from '@/components/common/StandardButton.vue';
import AppDialog from '@/components/common/AppDialog.vue';
import TextButton from '@/components/common/TextButton.vue';
import ModelEStripeAccountIdFeesOrder from './ModelEStripeAccountIdFeesOrder.vue';
import ModelEStripeAccountIdFeesCartCheckout from './ModelEStripeAccountIdFeesCartCheckout.vue'
import TextField from './common/TextField.vue';
import AppAccordion from './common/AppAccordion.vue';
import AppTooltip from './common/AppTooltip.vue';
import AppCheckbox from './common/AppCheckbox.vue';
export default {
  name: 'ModelEStripeAccountIdFees',
  components: {
    AppLogo,
    SubsectionHeader,
    AppDialog,
    TextField,
    AppAccordion,
    AppTooltip,
    TextButton,
    ModelEStripeAccountIdFeesOrder,
    ModelEStripeAccountIdFeesCartCheckout,
    StandardButton,
    AppCheckbox,
  },
  data() {
    return {
      accordions: [
        { id: 'stripe-accordion--reservation', title: 'Advanced Access' },
        { id: 'stripe-accordion--order', title: 'Order' },
        { id: 'stripe-accordion--cart-checkout', title: 'Cart and Checkout' },
      ],
      activeAccordionId: null,
      confirm: false,
      showPrompt: false,
      reservationText: '$100',
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
    }),
    primaryContact() {
      return this.$store.getters['modelE/stripe/primaryContact'] || '';
    },
    mid() {
      return this.$store.state.modelE.stripe.mid;
    },
    applicationStatus() {
      return this.$store.state.modelE.stripe.accountInfo.applicationStatus;
    },
  },
  watch: {
    division() {
      this.activeAccordionId = null;
    },
  },
  methods: {
    openAccordion(accordionId) {
      if (this.activeAccordionId === accordionId) {
        this.activeAccordionId = null;
      } else {
        this.activeAccordionId = accordionId;
      }
    },
    handleConfirm(confirm) {
      if (confirm) {
        this.$store
          .dispatch('modelE/stripe/postStripeData', this.mid)
          .then(() => {
            this.$store.dispatch('topBanner/open', {
              message: 'Data Successfully Saved!',
            });
          });
      }
      this.showPrompt = false;
    },
    updateSelection(selection, property) {
      this.mid[property].deposit = selection.value;
      this.mid[property].deposit_Confirmed = false;
    },
    updateSelectionACH(selection) {
      this.mid.cartcheckout.deposit_ACH = selection.value;
      this.mid.cartcheckout.deposit_ACH_Confirmed = false;
    },
    updateSelectionAllPayments(selection) {
      this.mid.cartcheckout.deposit_AllPaymentTypes = selection.value;
      this.mid.cartcheckout.deposit_AllPaymentTypes_Confirmed = false;
    },
    async submit(saveType) {
      const err = [];
      if (saveType === 1) {
        this.mid.experience = 'reservation';
        this.mid.reservation.merchantID_Confirmed = true;
      }
      if (saveType === 2) {
        // ORDER Validation
        this.mid.experience = 'order';
        if (this.mid.order.deposit === '') {
          err.push('Order deposit must be selected');
        }
      }
      if (saveType === 3) {
        // CARTCHECKOUT Validation
        this.mid.experience = 'cartAndCheckout';
        if (this.mid.cartcheckout.deposit === '' || this.mid.cartcheckout.deposit === '0') {
          err.push('Cart and Checkout deposit Credit card must be selected');
        }
      }
      if (saveType === 4) {
        this.mid.experience = 'cartAndCheckout';
        if (this.mid.cartcheckout.deposit_ACH === '' || this.mid.cartcheckout.deposit_ACH === '0') {
          err.push('Cart and Checkout deposit ACH must be selected');
        }
      }
      if (saveType === 5) {
        this.mid.experience = 'cartAndCheckout';
        if (this.mid.cartcheckout.deposit_AllPaymentTypes === '' || this.mid.cartcheckout.deposit_AllPaymentTypes === '0') {
          err.push('Cart and Checkout  all payment types checkout deposit must be selected');
        }
      }
      if (err.length > 0) {
        err.forEach((msg) => {
          this.$store.dispatch('topBanner/open', {
            type: 'error',
            message: msg,
          });
        });
      } else {
        this.$store
          .dispatch('modelE/stripe/postStripeData', this.mid)
          .then(() => {
            this.$store.dispatch('topBanner/open', {
              message: 'Data Successfully Saved!',
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-account-number-fee {

  .stripe-account-number-fee__label,
  .stripe-account-number-fee__contact {
    font-size: fds-rem(18px);
    font-family: $font-family--antenna-condensed;
    font-weight: $font-weight--normal;
  }
  .stripe-account-number-fee__boxed {
    .stripe-account-id {
      margin-bottom: 60px;
      &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        margin-bottom: 20px;
        @include respond(sm-up) {
          flex-direction: row;
          justify-content: unset;
          align-items: center;
        }
      }
      &__field-wrapper {
        position: relative;
      }
      &__field {
        @include respond(sm-dn) {
          --input-min-width: unset;
        }
      }
      &__field-label {
        &.lincoln {
          justify-content: flex-end;
          width: 100%;
        }
        display: flex;
        gap: 8px;
        font-size: 12px;
        position: absolute;
        bottom: 100%;
        z-index: 1;
      }
      &__confirmed-message {
        color: $color--fds-success2;
      }
    }
  }
}
.stripe-account-number-fee__label {
  color: var(--app-color--primary);
}

.stripe-account-number-fee__contact {
  color: var(--app-color--secondary);
}

.stripe-account-number-fee__boxed {
  margin-top: 24px;
  background-color: $color--white;
  min-height: 270px;
  padding: 60px;
  box-shadow: 0px 5px 10px $color--fds-gray3;

  .mid-confirmed {
    font-size: fds-rem(16px);
    color: $color--fds-success2;
  }
}

.ml-3 {
  margin-left: 30px;
}
.alignRight {
  text-align: right;
}
.reservation-wrapper {
  position: relative;
}
.reservation-tooltip {
  position: absolute;
  top: 0;
  left: 75px;
  z-index: 2;
}
</style>
