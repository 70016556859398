<template>
  <DealerProfileSection :title="$l('dealerProfile.subtitle1')">
    <ul class="dealer-readiness">
      <li
        v-if="canStartNewReadinessMeeting"
        class="dealer-readiness__item"
      >
        <div class="dealer-readiness__item-column">
          <span class="dealer-readiness__item-title">
            {{ $l('dealerProfile.section1Label') }}
          </span>
        </div>
        <div class="dealer-readiness__item-column">
          <span>{{ selectedDealer.text }}</span>
          <span class="dealer-readiness__status">
            {{ $l('general.status.notStarted') }}
          </span>
        </div>
        <div class="dealer-readiness__item-column dealer-readiness__item-column--controls">
          <StandardButton
            size="lg"
            :text="$l('dealerProfile.meetingInfo.getStarted')"
            icon-right="chevron-right"
            @click="toggleReadinessModal"
          />
        </div>
      </li>
      <li
        v-for="meeting in readinessMeetings"
        :key="meeting.id"
        class="dealer-readiness__item"
      >
        <div class="dealer-readiness__item-column">
          <span class="dealer-readiness__item-title">
            {{ $l('dealerProfile.section1Label') }}
          </span>
        </div>
        <div class="dealer-readiness__item-column">
          <span>{{ selectedDealer.text }}</span>
          <span>{{ $l('dealerProfile.meetingInfo.admin') }} {{ meeting.admin }}</span>
          <span>{{ $l('dealerProfile.meetingInfo.date') }} {{ formatDate(meeting.date) }}</span>
          <span
            v-if="!meeting.completed"
            class="dealer-readiness__status dealer-readiness__status--in-progress"
          >
            {{ $l('general.status.inProgress') }}
          </span>
          <template v-else>
            <div>
              <span class="dealer-readiness__status dealer-readiness__status--complete">
                {{ $l('general.status.complete') }}
              </span>
              | {{ formatDate(meeting.date) }} | {{ meeting.name }}
            </div>
            <span>{{ $l('dealerProfile.meetingInfo.role') }} {{ meeting.role }}</span>
            <span v-if="meeting.note">
              {{ $l('dealerProfile.meetingInfo.note') }} {{ meeting.note }}
            </span>
          </template>
        </div>
        <div class="dealer-readiness__item-column dealer-readiness__item-column--controls">
          <StandardButton
            size="lg"
            :text="meeting.completed
              ? $l('dealerProfile.meetingInfo.review')
              : $l('dealerProfile.meetingInfo.resume')"
            @click="toggleReadinessModal(2)"
          />
        </div>
      </li>
    </ul>
    <ReadinessPlanModal
      v-if="showReadinessModal"
      :action-type="showReadinessModalTab"
      :dealer-id="selectedDealer.value"
      @close="toggleReadinessModal"
    />
  </DealerProfileSection>
</template>

<script>
import { format } from 'date-fns';
import StandardButton from './common/StandardButton.vue';
import DealerProfileSection from './DealerProfileSection.vue';
import ReadinessPlanModal from './ReadinessPlanModal.vue';

export default {
  name: 'DealerProfileReadiness',
  components: {
    StandardButton,
    DealerProfileSection,
    ReadinessPlanModal,
  },
  props: {
    selectedDealer: {
      type: Object,
      default: () => ({}),
    },
    readinessMeetings: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      showReadinessModal: false,
      showReadinessModalTab: 0,
    };
  },
  computed: {
    canStartNewReadinessMeeting() {
      return this.readinessMeetings.filter(({ completed }) => !completed).length === 0;
    },
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), this.$store.getters['locale/dateFormatPattern']);
    },
    toggleReadinessModal(tab) {
      if (tab) {
        this.showReadinessModalTab = tab;
      } else {
        this.showReadinessModalTab = 0;
      }

      this.showReadinessModal = !this.showReadinessModal;
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-readiness {
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  &__item {
    display: grid;
    gap: 24px;
    width: 100%;
    @include respond(sm-up) {
      grid-template-columns: 1fr 2fr 1fr;
    }
  }
  &__item-column {
    display: flex;
    flex-direction: column;
    &--controls {
      justify-content: center;
      align-items: center;
    }
  }
  &__status {
    font-weight: $font-weight--medium;
    &--complete {
      color: $color--fds-success1;
    }
    &--in-progress {
      color: $color--fds-caution1;
    }
  }
}
</style>
