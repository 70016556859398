<template>
  <div class="popup-tip__wrapper">
    <div
      class="popup-tip"
      :class="$store.getters['user/activeBrandName']"
    >
      <p
        v-if="title"
        class="popup-tip__title"
      >
        {{ title }}
      </p>
      <slot name="popupTipContent" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PopUpTip',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-tip {
  visibility: hidden;
  width: 414px;
  padding: 25px 15px 8px 15px;
  background-color: $color--white;
  border: 1px solid #95989a;
  box-shadow: 0 0 10px 0 rgba($color--black, 0.2);
  color: $color--fds-primary;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  position: absolute;
  z-index: $z-index__popup-tip;
  transform: translate(calc(-25% - 10px), calc(-100% - 15px));
  transition: visibility .2s ease-in-out;
  &.lincoln {
    color: $color--white;
    background-color: $color--lds-primary;
    border: 1px solid $color--lds-primary;
    &::after {
        background-color: $color--lds-primary;
        border-left-color: $color--lds-primary;
        border-top-color: $color--lds-primary;
    }
  }
  &::after {
    content: '';
    background-color: $color--white;
    border: 1px solid #95989a;
    border-left-color: $color--white;
    border-top-color: $color--white;
    position: absolute;
    left: calc(50% - 10px);
    bottom: -10px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
  }
  &__wrapper {
    position: relative;
    @include respond(md-up) {
      &:hover .popup-tip {
        visibility: visible;
      }
    }
  }
  &__title {
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 1em;
  }
}
</style>
