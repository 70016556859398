import BaseAPI from './baseAPI';

export default class DealerAccountability extends BaseAPI {
  async getMeetings(dealerId) {
    const { data } = await this.axios.get('/DealerAccountability/Meetings', {
      params: {
        dealerID: dealerId,
      },
    });

    return data;
  }

  async getDealerMeeting(dealerID) {
    const { data } = await this.axios.get('/DealerAccountability/DealerMeeting', {
      dealerID,
    });

    return data;
  }

  async saveDealerMeeting(payload) {
    await this.axios.post('/DealerAccountability/DealerMeeting', {
      meetingID: payload.meetingId,
      dealerID: payload.dealerId,
      note: payload.note,
      completedBy_UserID: payload.completedByUserId,
      meeting_Date: payload.meetingDate,
      meetingCampaignID: payload.meetingCampaignId,
      metWithZM: payload.metWithZM,
      completedBy_Name: payload.completedByName,
      department_TeamMeetings_Completed: payload.departmentTeamMeetingsCompleted,
      department_Managers_Present: payload.departmentManagersPresent,
      department_TeamHosting_Completed: payload.departmentTeamHostingCompleted,
      strategyImplementation_Completed: payload.strategyImplementationCompleted,
      manager_Meeting_Completed: payload.managerMeetingCompleted,
      lastSavedOn: payload.lastSavedOn,
      markedFinalOn: payload.markedFinalOn,
      fieldReviewedPlanWithDealer: payload.fieldReviewedPlanWithDealer,
      providedResourcesAdequate: payload.providedResourcesAdequate,
    });
  }

  async getFieldMeeting(dealerId) {
    const { data } = await this.axios.get('/DealerAccountability/FieldMeeting', {
      params: {
        dealerID: dealerId,
      },
    });

    return data;
  }

  async saveFieldMeeting(payload) {
    const { data } = await this.axios.post('/DealerAccountability/FieldMeeting', {
      ...payload,
    });

    return data;
  }
}
