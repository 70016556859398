<template>
  <h3 class="message">
    {{ $l('general.comingSoon') }}!
  </h3>
</template>

<script>
export default {
  name: 'IneligibleView',
};
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
  text-transform: uppercase;
}
</style>
