import BaseAPI from './baseAPI';

export default class VehiclePricing extends BaseAPI {
  async getEntitlements() {
    const { data } = await this.axios.get('/Dashboard/VehiclePricing/Entitlements');
    return data;
  }

  async getPricingSource() {
    const { data } = await this.axios.get('/Dashboard/VehiclePricing/SourceData');
    return data;
  }

  async getModelEEntitlements() {
    const { data } = await this.axios.get('/DashboardModelE/VehiclePricingModelE/EntitlementsModelE');
    return data;
  }

  async getModelEPricingSource() {
    const { data } = await this.axios.get('/DashboardModelE/VehiclePricingModelE/SourceDataModelE');
    return data;
  }
}
