<template>
  <div class="notification">
    <div class="notification__content">
      <TopBanner
        v-for="notification in persistentNotifications"
        :key="notification.id"
        :type="notification.type"
        :message="notification.message || $l(notification.messageKey)"
        :has-details="!!notification.details"
        :is-persistent="notification.persistent"
        @close="$store.dispatch('topBanner/close', notification.id)"
      >
        <div v-html="notification.details" />
      </TopBanner>
    </div>
    <div class="notification__content--dismissible">
      <TopBanner
        v-for="notification in dismissibleNotifications"
        :key="notification.id"
        class="notification__banner--absolute"
        :type="notification.type"
        :message="notification.message || $l(notification.messageKey)"
        :has-details="!!notification.details"
        :is-persistent="notification.persistent"
        @close="$store.dispatch('topBanner/close', notification.id)"
      >
        <div v-html="notification.details" />
      </TopBanner>
    </div>
  </div>
</template>

<script>
import TopBanner from './common/TopBanner.vue';

export default {
  name: 'TheHeaderTopBanner',
  components: {
    TopBanner,
  },
  computed: {
    dismissibleNotifications() {
      return this.$store.getters['topBanner/notificationQueue']
        .filter((notification) => !notification.persistent);
    },
    persistentNotifications() {
      return this.$store.getters['topBanner/notificationQueue']
        .filter((notification) => notification.persistent);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  &__content--dismissible {
    position: relative;
  }
  &__banner--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
