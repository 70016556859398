import apiManager from '@/api';
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';

const filterDepositOptions = ({
  depositOptions, depositType, activeBrand,
}) => depositOptions.filter((option) => option.depositType === depositType
&& option.division === activeBrand);

export default {
  namespaced: true,
  state() {
    return {
      lastLoadingAt: '',
      accounts: [],
      records: [],
      depositOptions: [],
      contacts: [],
    };
  },
  getters: {
    resources(state, getters, rootState, rootGetters) {
      return ResourceCenter.findSectionResources(
        rootGetters['dashboard/resources'],
        resourceCenterId.section.STRIPE_ACCOUNT,
        resourceCenterId.subsection.STRIPE_PAGE_ACCOUNT_SETUP,
      );
    },
    activeResources(state, getters, rootState, rootGetters) {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: getters.resources,
        brand: rootGetters['user/activeBrand'],
        locale: rootGetters['locale/activeLocale'],
      });
    },
    lastLoadingAt: (state) => state.lastLoadingAt,
    primaryContact({ contacts }, getters, rootState, rootGetters) {
      const contact = contacts.find(
        ({ division }) => division === rootGetters['user/activeBrand'],
      );

      return (contact) ? `${contact.firstName} ${contact.lastName}` : null;
    },
    account({ accounts }, getters, rootState, rootGetters) {
      return accounts.filter(
        (acc) => acc.division === rootGetters['user/activeBrand'],
      )[0] || {};
    },
    records({ records }, getters, rootState, rootGetters) {
      let reservation = {};
      let order = {};
      let cartCheckout = {};

      records.forEach((record) => {
        if (record.division !== rootGetters['user/activeBrand']) return;

        switch (record.merchantIdType) {
          case 'Reservation':
            reservation = record;
            break;
          case 'Order':
            order = record;
            break;
          case 'CartCheckout':
            cartCheckout = record;
            break;
          default:
        }
      });
      return { reservation, order, cartCheckout };
    },
    orderDepositOptions({ depositOptions }, getters, rootState, rootGetters) {
      return filterDepositOptions({
        depositOptions,
        depositType: 'Order',
        activeBrand: rootGetters['user/activeBrand'],
      });
    },
    reservationDepositOptions({ depositOptions }, getters, rootState, rootGetters) {
      return filterDepositOptions({
        depositOptions,
        depositType: 'Reservation',
        activeBrand: rootGetters['user/activeBrand'],
      });
    },
    cartCheckoutDepositOptions({ depositOptions }, getters, rootState, rootGetters) {
      return filterDepositOptions({
        depositOptions,
        depositType: 'CartCheckout',
        activeBrand: rootGetters['user/activeBrand'],
      });
    },
    aplicationStatus(state, getters) {
      const { applicationStatus: status } = getters.account;
      return {
        unknown: status === 'unknown',
        notstarted: status === 'notstarted',
        pending: status === 'pending',
        inprogress: status === 'inprogress',
        onhold: status === 'onhold',
        active: status === 'active',
      };
    },
    accountStatus(state, getters) {
      const { accountStatus: status } = getters.account;
      return {
        incomplete: status === 'incomplete',
        complete: status === 'complete',
      };
    },
  },
  mutations: {
    setDepositOptions(state, payload) {
      state.depositOptions = payload;
    },
    setAccounts(state, payload) {
      state.accounts = payload;
    },
    setRecords(state, payload) {
      state.records = payload;
    },
    setLastLoadingAt(state, payload) {
      state.lastLoadingAt = payload;
    },
    setAccountUrl(state, payload) {
      state.accountUrl = payload;
    },
    setContacts(state, payload) {
      state.contacts = payload;
    },
  },
  actions: {
    async setupOneAccount({ dispatch }) {
      await apiManager.stripe.accountChoice(1);

      return Promise.all([
        dispatch('load'),
        dispatch('user/loadUser', null, { root: true }),
      ]);
    },
    async setupTwoAccounts({ dispatch }) {
      await apiManager.stripe.accountChoice(2);

      return Promise.all([
        dispatch('load'),
        dispatch('user/loadUser', null, { root: true }),
      ]);
    },
    async load({ commit }) {
      const { data } = await apiManager.stripe.getStripePay();

      commit('setDepositOptions', data.depositOptions);
      commit('setRecords', data.stripeRecords.map((r) => ({
        dealerId: r.dealerID,
        deposit: r.deposit,
        depositConfirmed: r.deposit_Confirmed,
        division: r.division,
        merchantId: r.merchantID,
        merchantIdType: r.merchantIDType,
        merchantIdConfirmed: r.merchantID_Confirmed,
      })));
      commit('setAccounts', data.stripeAccounts.map((acc) => ({
        division: acc.division,
        primaryContact: acc.primaryContact,
        accountStatus: acc.stripeAccountStatus,
        applicationStatus: acc.stripeApplicationStatus,
      })));
      commit('setContacts', data.stripeContacts.map((contact) => ({
        division: contact.division,
        firstName: contact.contact_FirstName,
        lastName: contact.contact_LastName,
      })));

      commit('setLastLoadingAt', new Date());
    },
    async getAccountUrl({ dispatch, rootGetters }) {
      const { data: accountUrl } = await apiManager.stripe.getAccountUrl({
        dealerId: rootGetters['user/activeDealership'].dealerId,
        language: rootGetters['locale/activeLocale'],
      });

      dispatch('load');

      return accountUrl;
    },
    async confirmAccountId({ getters, dispatch }) {
      await apiManager.stripe.postStripePay({
        ...getters.records.reservation,
        merchantIdConfirmed: true,
      });

      return dispatch('load');
    },
    async confirmOrderDeposit({ getters, dispatch }, { depositValue }) {
      await apiManager.stripe.postStripePay({
        ...getters.records.order,
        depositConfirmed: true,
        deposit: Number(depositValue),
      });

      return dispatch('load');
    },
    async confirmCartCheckoutAmount({ getters, dispatch }, { amount }) {
      await apiManager.stripe.postStripePay({
        ...getters.records.cartCheckout,
        depositConfirmed: true,
        deposit: Number(amount),
      });

      return dispatch('load');
    },
  },
};
