<template>
  <AppDialog
    open
    @close="$emit('close')"
  >
    <template #header>
      <div class="readiness-plan__header">
        <h2 class="readiness-plan__title">
          {{ $l('readinessPlan.title') }} <br />
          <span
            v-text="$l('readinessPlan.subtitle')"
            class="readiness-plan__subtitle fds-subtitle__1"
            :class="$store.getters['user/activeBrandName']"
          />
        </h2>
        <span class="readiness-plan__dealership">{{ dealershipAddress }}</span>
      </div>
    </template>
    <div class="readiness-plan__body">
      <LoadingIndicator v-if="isLoading" />
      <AppDropdown
        v-else-if="!selectedDealerId"
        v-model="selectedDealerId"
        id="readiness-plan--dealership"
        class="readiness-plan__search-field"
        searchable
        label="Enter Dealership Name"
        :options="dropdownOptions"
      />
      <template v-else>
        <div class="readiness-plan__tab-group">
          <AppTab
            v-for="(tab, index) in tabs"
            :key="index"
            :is-active="activeTabIndex === index"
            :text="`${index + 1}.  ${$l(tab)}`"
            @click="activeTabIndex = index"
          />
        </div>
        <div class="readiness-plan__content">
          <ManagerMeeting v-if="activeTabIndex === 0" />
          <DepartmentTeamMeeting v-if="activeTabIndex === 1" />
          <ReadinessCheck
            v-if="activeTabIndex === 2"
            :dealer="selectedDealer"
          />
        </div>
      </template>
    </div>
  </AppDialog>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import AppTab from './common/AppTab.vue';
import AppDialog from './common/AppDialog.vue';
import AppDropdown from './common/AppDropdown.vue';
import LoadingIndicator from './LoadingIndicator.vue';
import ManagerMeeting from './ReadinessPlanModalManagerMeeting.vue';
import DepartmentTeamMeeting from './ReadinessPlanModalDepartmentTeamMeeting.vue';
import ReadinessCheck from './ReadinessPlanModalReadinessCheck.vue';

export default {
  name: 'ReadinessPlanModal',
  components: {
    AppTab,
    AppDialog,
    AppDropdown,
    ManagerMeeting,
    DepartmentTeamMeeting,
    ReadinessCheck,
    LoadingIndicator,
  },
  emits: ['close'],
  props: {
    actionType: {
      type: Number,
      default: 0,
    },
    dealerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      tabs: [
        'readinessPlan.tabOne',
        'readinessPlan.tabTwo',
        'readinessPlan.tabThree',
      ],
      activeTabIndex: this.actionType,
      selectedDealerId: this.dealerId,
      dealers: {
        ford: [],
        lincoln: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
      activeDealership: 'user/activeDealership',
      userDealershipIsAnytown: 'user/dealershipIsAnytown',
    }),
    dealershipDealers() {
      return this.activeBrandIsFord ? this.dealers.ford : this.dealers.lincoln;
    },
    dropdownOptions() {
      return this.dealershipDealers.map((item) => ({
        text: item.name,
        value: item.dealerId,
      }));
    },
    selectedDealer() {
      return this.dealershipDealers
        .find((option) => option.dealerId === this.selectedDealerId) || {};
    },
    dealershipAddress() {
      const { name, city, state } = this.selectedDealer;
      return name ? `${name} (${city}, ${state})` : '';
    },
  },
  mounted() {
    this.togglePageScroll();
    this.$store.dispatch('readinessPlan/getDocuments');
    this.getDealershipsList();

    if (!this.userDealershipIsAnytown && !this.selectedDealerId) {
      this.selectedDealerId = this.activeDealership.dealerId;
    }
  },
  beforeUnmount() {
    this.togglePageScroll();
  },
  methods: {
    togglePageScroll() {
      const { overflowY } = document.body.style;
      document.body.style.overflowY = overflowY === 'hidden' ? '' : 'hidden';
    },
    normalizeDealerList(list) {
      return [...list]
        .map(({ dealership }) => dealership)
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    async getDealershipsList() {
      this.isLoading = true;

      const { data } = await apiManager.reports.getDealers();

      this.dealers.ford = this.normalizeDealerList(data.fordDealerList);
      this.dealers.lincoln = this.normalizeDealerList(data.lincolnDealerList);

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.readiness-plan {
  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    @include respond(lg-up) {
      flex-direction: row;
    }
  }
  &__title {
    margin: 0 !important;
    font-weight: $font-weight--light;
  }
  &__subtitle {
    color: $color--fds-secondary;
    font-size: 24px;
    font-weight: $font-weight--medium;
    text-transform: uppercase;
    &.lincoln {
      color: $color--lds-secondary;
    }
  }
  &__dealership {
    @include respond(lg-up) {
      text-align: right;
    }
  }
  &__body {
    color: $color--fds-gray3;
    min-height: 400px;
  }
  &__search-field {
    margin-top: 32px;
  }
  &__content {
    margin-top: 16px;
    padding: 20px;
    background-color: #F7F7F7;
    border: 2px #E1E1E1 solid;
  }
  &__tab-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
  }
}
</style>
