<template>
  <div class="customer-handling | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="customer-handling-document"
      :resources="resources"
    />
    <div class="row">
      <div class="col-md-9">
        <p>{{ $l('modelEDashboard.customer.handling.description1') }}</p>
        <p>{{ $l('modelEDashboard.customer.handling.description2') }}</p>
      </div>
      <div class="customer-handling__button-wrapper | col-md-3">
        <StandardButton
          to="https://www.emp.dealerconnection.com"
          track-event-name="click_customerHandlingPages"
          track-section-name="customerHandling"
          :text="$l('modelEDashboard.customer.handling.button')"
        />
      </div>
    </div>
    <DashboardDivider />
    <div class="row">
      <div class="col-md-9">
        <p>{{ $l('modelEDashboard.customer.handling.entitlementsDescription') }}</p>
      </div>
      <div class="customer-handling__button-wrapper | col-md-3">
        <StandardButton
          to="https://www.dsps.dealerconnection.com/"
          track-event-name="click_setEntitlements"
          track-section-name="customerHandling"
          :text="$l('modelEDashboard.customer.handling.entitlementsButton')"
        />
      </div>
    </div>
    <p class="customer-handling__list-title">
      {{ $l('modelEDashboard.customer.handling.listTitle') }}
    </p>
    <ul class="app-model-e-list">
      <li>{{ $l('modelEDashboard.customer.handling.listItem1') }}</li>
      <li>{{ $l('modelEDashboard.customer.handling.listItem2') }}</li>
    </ul>
    <div class="customer-handling__sheet-wrapper">
      <ModelESheet>
        <b>{{ $l('modelEDashboard.customer.handling.userIds') }}</b>
      </ModelESheet>
      <AppToast
        v-if="entitlements.length === 0"
        class="customer-handling__toast--error"
        theme="model-e"
        type="error"
        :text="$l('modelEDashboard.customer.handling.noEntitlements')"
      />
      <span v-else>
        {{ entitlements.join(', ') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppToast from './common/AppToast.vue';
import StandardButton from './common/StandardButton.vue';
import DashboardDivider from './DashboardDivider.vue';
import ModelESheet from './ModelESheet.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEDashboardCustomerHandling',
  components: {
    AppToast,
    StandardButton,
    DashboardDivider,
    ModelESheet,
    DashboardResourcesDisplay,
  },
  computed: {
    ...mapGetters({
      resources: 'modelE/dashboard/customerHandling/modelECustomerHandlingActiveResources',
      entitlements: 'modelE/dashboard/customerHandling/entitlements',
    }),
  },
};
</script>

<style lang="scss" scoped>
.customer-handling {
  &__button-wrapper {
    margin-top: 24px;
    @include respond(md-up) {
      margin-top: 0;
      text-align: right;
    }
  }
  &__list-title {
    color: $color--fds-primary;
    font-weight: $font-weight--medium;
  }
  &__sheet-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 32px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
    text-align: center;
  }
  &__toast--error {
    border: none;
  }
}
</style>
