<template>
  <div class="stripe-application-layout container-large">
    <TheHeader :show-controls="false" />
    <main class="stripe-application-layout__main container--page">
      <slot />
    </main>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';

export default {
  name: 'StripeApplicationLayout',
  components: {
    TheHeader,
  },
};
</script>

<style lang="scss" scoped>
.stripe-application-layout__main {
  min-height: calc(100vh - 101px);
  @include respond(sm-up) {
    min-height: calc(100vh - 130px);
  }
}
</style>
