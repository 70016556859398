import BaseAPI from './baseAPI';

export default class ResourceCenter extends BaseAPI {
  async getResults({
    newContent,
    experience,
    keyword,
    pageId,
    sectionId,
    subSectionId,
    language,
  } = {
    newContent: null,
    experience: null,
    keyword: null,
    pageId: null,
    sectionId: null,
    subSectionId: null,
  }) {
    const { data } = await this.axios.get('/ResourceCenter/SearchResults', {
      params: {
        experience,
        keyword,
        newContent,
        pageID: pageId,
        sectionID: sectionId,
        subSectionID: subSectionId,
        language,
      },
    });

    return data;
  }

  async getFilters(departmentOrder) {
    const { data } = await this.getUnique('/ResourceCenter/Filters', {
      params: {
        departmentOrder,
      },
    });

    return data;
  }

  async getKeywords({ keyword, language }) {
    const { data } = await this.getUnique('/ResourceCenter/Keywords', {
      params: {
        keyword,
        language,
      },
    });

    return data;
  }

  async getNewContentExperience() {
    const { data } = await this.getUnique('/ResourceCenter/NewContentExperience');
    return data;
  }

  async getModelEResults({
    newContent,
    experience,
    keyword,
    pageId,
    sectionId,
    subSectionId,
    language,
  } = {
    newContent: null,
    experience: null,
    keyword: null,
    pageId: null,
    sectionId: null,
    subSectionId: null,
  }) {
    const { data } = await this.axios.get('/ResourceCenterModelE/SearchResultsModelE', {
      params: {
        experience,
        keyword,
        newContent,
        pageID: pageId,
        sectionID: sectionId,
        subSectionID: subSectionId,
        language,
      },
    });

    return data;
  }

  async getModelEFilters(departmentOrder) {
    const { data } = await this.getUnique('/ResourceCenterModelE/FiltersModelE', {
      params: {
        departmentOrder,
      },
    });

    const filters = data.data;
    return filters;
  }

  async getModelEKeywords({ keyword, language }) {
    const { data } = await this.getUnique('/ResourceCenterModelE/KeywordsModelE', {
      params: {
        keyword,
        language,
      },
    });

    return data;
  }

  async getModelENewContentExperience() {
    const { data } = await this.getUnique('/ResourceCenterModelE/NewContentExperienceModelE');

    const experience = data.data;
    return experience;
  }

}
