<template>
  <div class="dealer-profile">
    <PageHeader :title="$l('dealerProfile.title')" />
    <div class="dealer-profile__container">
      <div class="dealer-profile__search">
        <AppDropdown
          v-model="selectedDealerId"
          id="dealer-profile--dealer-list"
          searchable
          :label="$l('dealerProfile.labelDropdown')"
          :options="dropdownOptions"
        />
        <p class="dealer-profile__last-updated-at">
          {{ $l('dealerProfile.fileDate', { date: lastUpdate }) }}
        </p>
      </div>
      <LoadingIndicator v-if="isLoading" />
      <template v-if="!isLoading && selectedDealerId">
        <DealerProfileReadiness
          v-if="activeFeatures.dealerAccountability"
          :selected-dealer="selectedDealer"
          :readiness-meetings="readinessMeetings"
        />
        <DealerProfileInfo
          :selected-dealer="selectedDealer"
          :dealer-data="dealerData"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import PageHeader from '@/components/PageHeader.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AppDropdown from '@/components/common/AppDropdown.vue';
import DealerProfileReadiness from '@/components/DealerProfileReadiness.vue';
import DealerProfileInfo from '@/components/DealerProfileInfo.vue';

export default {
  name: 'DealerProfileView',
  components: {
    PageHeader,
    LoadingIndicator,
    AppDropdown,
    DealerProfileReadiness,
    DealerProfileInfo,
  },
  data() {
    return {
      isLoading: false,
      selectedDealerId: null,
      dealerList: [],
      readinessMeetings: [],
      dealerProfileReports: [],
    };
  },
  computed: {
    ...mapGetters({
      activeBrandName: 'user/activeBrandName',
      activeFeatures: 'features/activeFeatures',
      locales: 'locale/locales',
      activeLocale: 'locale/activeLocale',
      activeDealerList: 'reports/activeDealerList',
      lastUpdate: 'reports/lastUpdate',
    }),
    dropdownOptions() {
      return this.activeDealerList.map((dealer) => ({
        text: `${dealer.name} (${dealer.city}, ${dealer.state})`,
        value: dealer.dealerId,
      }));
    },
    selectedDealer() {
      return this.dropdownOptions.filter(({ value }) => value === this.selectedDealerId)[0] || {};
    },
    dealerData() {
      return this.dealerProfileReports
        .find(({ language }) => language === this.activeLocale)?.reportData || {};
    },
  },
  watch: {
    activeBrandName() {
      this.selectedDealerId = null;
    },
    selectedDealerId(id) {
      if (id) {
        this.loadProfile();
      }
    },
  },
  mounted() {
    this.getDealerList();
  },
  methods: {
    async getDealerList() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('reports/getDealerList');
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('reports.errors.loadDealerListFailed'),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async loadProfile() {
      this.isLoading = true;

      try {
        await Promise.all([
          this.getReadinessMeetings(),
          this.getDealerProfileData(),
        ]);
      } catch (error) {
        this.selectedDealerId = null;

        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('reports.errors.loadDealerDataFailed'),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getReadinessMeetings() {
      const meetings = await apiManager.dealerAccountability.getMeetings(this.selectedDealerId);

      this.readinessMeetings = meetings.map((meeting) => ({
        id: meeting.meetingID,
        admin: meeting.administeredBy,
        name: meeting.completedByName,
        role: meeting.completedByRole,
        date: meeting.meetingDate,
        note: meeting.note,
        completed: meeting.departmentTeamHostingCompleted === 'Yes'
          && meeting.departmentTeamMeetingCompleted === 'Yes'
          && meeting.strategyImplementationCompleted === 'Yes',
      }));
    },
    async getDealerProfileData() {
      const { data } = await apiManager.reports.getDealerProfile({
        dealerId: this.selectedDealerId,
        division: this.activeBrandName,
      });

      this.dealerProfileReports = this.parseDealerProfileReports(data.dealerProfileReports);
    },
    parseDealerProfileReports(data) {
      const englishData = data.find(({ language }) => language === this.locales.english);
      const frenchData = data.find(({ language }) => language === this.locales.french);

      // Replaces status words in french to status words in english
      const {
        cartCheckoutActivationInfo,
        orderActivationInfo,
        profileInfo,
        reservationActivationInfo,
      } = englishData.reportData;

      const parsedFrenchData = {
        ...frenchData,
        reportData: {
          ...frenchData.reportData,
          cartCheckoutActivationInfo,
          orderActivationInfo,
          reservationActivationInfo,
          profileInfo: {
            ...frenchData.reportData.profileInfo,
            remoteDelivery: profileInfo.remoteDelivery,
          },
        },
      };

      return [englishData, parsedFrenchData];
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-profile {
  &__container {
    @include respond(md-up) {
      padding: 0 50px;
    }
  }
  &__search {
    max-width: 705px;
    margin: 40px auto;
  }
  &__last-updated-at {
    margin: 0;
    margin-top: 8px;
    text-align: center;
  }
}
</style>
