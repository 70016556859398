<template>
  <footer
    id="footer"
    class="footer | container-large"
    :data-theme="activeExperience"
  >
    <ul
      v-if="showFooterList"
      :key="$route.path"
      class="footer__list"
    >
      <li v-show="termsAndConditionsPdf">
        <AppLink
          class="footer__item"
          @click="openDocumentModal"
        >
          {{ $l('footer.tc') }}
        </AppLink>
      </li>
      <li v-if="userCanAccessImpersonate">
        <AppLink
          class="footer__item"
          :to="{ name : 'Impersonate' }"
        >
          {{ $l('footer.impersonate') }}
        </AppLink>
      </li>
      <!-- <li>
        <AppLink
          v-text="$l('footer.faq')"
          class="footer__item"
          :to="{ name : 'FAQs' }"
        />
      </li> -->
    </ul>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLink from './common/AppLink.vue';

export default {
  name: 'TheFooter',
  components: {
    AppLink,
  },
  computed: {
    ...mapGetters({
      activeExperience: 'user/activeExperience',
      userCanAccessImpersonate: 'user/userCanAccessImpersonate',
    }),
    showFooterList() {
      const routeNames = ['Home', 'Brand'];
      return !routeNames.includes(this.$route.name);
    },
    termsAndConditionsPdf() {
      const { lastAcceptedPdf } = this.$store.getters['termsAndConditions/terms'];

      if (!lastAcceptedPdf) {
        return '';
      }

      return `${this.$store.getters['termsAndConditions/src']}/${lastAcceptedPdf}`;
    },
  },
  methods: {
    openDocumentModal() {
      this.$store.dispatch('documentModal/open', {
        title: this.$l('footer.tc'),
        fileHref: this.termsAndConditionsPdf,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 64px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fafafa;
  box-shadow: 0 -5px 5px rgba($color--black, 0.15);
  z-index: $z-index__the-footer;
  &[data-theme="model-e"] {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  @include respond(sm-dn) {
    padding: 0 20px;
  }
  &__list {
    display: flex;
    gap: 24px;
    margin: 0;
    padding: 0;
    list-style: none;
    @include respond(sm-dn) {
      width: 100%;
      justify-content: center;
    }
  }
  &__item {
    text-transform: uppercase;
    font-size: 18px;
    @include respond(sm-dn) {
      font-size: 12px;
    }
  }
}
</style>
