<template>
  <ExperienceCard
    v-if="activeBrandIsFord"
    :experience-id="3"
    :title="$l('resourceCenter.experience.cart.title')"
  >
    <template #top>
      <!-- This code wasn't deleted because the links might be needed in the future -->
      <!-- <TextButton
        :to="{ name:'ResourceResults', query:{ experience: 3, topic: 23 }}"
        text="Mustang Mach-E"
      />
      <p>{{ $l('resourceCenter.experience.cart.fullLine') }}</p> -->
    </template>
    <template #topFooter>
      <StandardButton
        icon-right="chevron-right"
        track-event-name="select_cartCheckout"
        track-section-name="cartCheckout"
        :to="{ name: 'ResourceResults', query: { experience: 3 }}"
        :text="$l('general.select')"
      />
    </template>
  </ExperienceCard>
  <ExperienceCard
    v-else
    :title="$l('resourceCenter.experience.cart.title')"
  >
    <template #topFooter>
      <StandardButton
        outline
        icon-right="chevron-right"
        track-event-name="select_cartCheckout"
        track-section-name="cartCheckout"
        :to="{ name: 'ResourceResults', query: { experience: 3 }}"
        :text="$l('general.select')"
      />
    </template>
  </ExperienceCard>
</template>

<script>
import { mapGetters } from 'vuex';
import StandardButton from './common/StandardButton.vue';
import ExperienceCard from './ResourceCenterExperienceCard.vue';

export default {
  name: 'ResourceCenterCardCart',
  components: {
    StandardButton,
    ExperienceCard,
  },
  computed: {
    ...mapGetters({
      activeBrandIsFord: 'user/activeBrandIsFord',
    }),
  },
};
</script>
