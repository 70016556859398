const DEFAULT_DEPARTMENTS = [
  {
    id: 1,
    name: 'dealerPersonnel.departament.management',
    tooltip: 'dealerPersonnel.tooltip.management',
    key: 'programManagement',
  },
  {
    id: 2,
    name: 'dealerPersonnel.departament.newCar',
    tooltip: 'dealerPersonnel.tooltip.newCar',
    key: 'vehiclePricing',
  },
  {
    id: 6,
    name: 'dealerPersonnel.departament.accessories',
    tooltip: 'dealerPersonnel.tooltip.accessories',
    key: 'accessoryPricing',
  },
  {
    id: 4,
    name: 'dealerPersonnel.departament.usedCar',
    tooltip: 'dealerPersonnel.tooltip.usedCar',
    key: 'tradeTool',
  },
  {
    id: 5,
    name: 'dealerPersonnel.departament.fAndI',
    tooltip: 'dealerPersonnel.tooltip.fAndI',
    key: 'fniSetup',
  },
  {
    id: 3,
    name: 'dealerPersonnel.departament.eCommSales',
    tooltip: 'dealerPersonnel.tooltip.eCommSales',
    key: 'customerHandling',
  },
];

export default DEFAULT_DEPARTMENTS;
