<template>
  <AppDialog
    centered
    size="md"
    :open="open"
    @close="$emit('close')"
  >
    <div class="modal-enrollment__content">
      <FontAwesomeIcon
        size="2x"
        :icon="successIcon"
      />
      <p class="modal-enrollment__description">
        {{ $l('dashboard.enrollment.submit.successMessage') }}
      </p>
    </div>
  </AppDialog>
</template>

<script>
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AppDialog from './common/AppDialog.vue';

export default {
  name: 'DashboardModalEnrollmentSuccessful',
  components: {
    AppDialog,
  },
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      successIcon: faCheckCircle,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-enrollment {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    color: $color--fds-success1;
  }
  &__description {
    margin: 0;
  }
}
</style>
