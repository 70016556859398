<template>
  <section
    id="marketplace-portal"
    class="marketplace-portal"
  >
    <h2 class="marketplace-portal__title fds-headline__5">
      {{ $l('resourceEmp.title') }}
    </h2>
    <div class="row">
      <div class="marketplace-portal__content col-xs-12 col-lg-11">
        <img
          class="marketplace-portal__image"
          alt="eCommerce marketplace portal demo"
          src="@/assets/images/marketplace-portalModelE.jpg"
        />
        <h3 class="marketplace-portal__coming-soon ">
          {{ $l('general.comingSoon') }}
        </h3>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.marketplace-portal {
  .marketplace-portal__title {
    margin: 0;
    margin-bottom: 40px;
    color: $color--fds-primary;
    font-weight: $font-weight--normal;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    @include respond(sm-up) {
      flex-direction: row;
    }
  }
  &__image {
    border: 1px solid rgba($color--black, 0.6);
    width: 100%;
    max-width: 350px;
  }
  &__coming-soon {
    margin: auto;
    color: $color--fds-primary;
  }
}
</style>
