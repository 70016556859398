<template>
  <div
    class="stripe-primary-contact"
    :class="$store.getters['user/activeBrandName']"
  >
    <AppLogo />
    <p class="stripe-primary-contact__label">
      {{ $l('stripe.feeSetup.primaryContact') }}
      <span class="stripe-primary-contact__name">
        {{ primaryContact }}
      </span>
    </p>
  </div>
</template>

<script>
import AppLogo from './common/AppLogo.vue';

export default {
  name: 'StripeFeeSetupPrimaryContact',
  components: {
    AppLogo,
  },
  computed: {
    primaryContact() {
      return this.$store.getters['stripe/primaryContact'] || this.$l('general.none');
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-primary-contact {
  --label-color: #{$color--fds-primary};
  ---name-color: #{$color--fds-secondary};
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 45px;
  &__label {
    margin-bottom: 0;
    font-weight: $font-weight--medium;
    color: var(--label-color);
  }
  &__name {
    color: var(---name-color);
  }
  &.lincoln {
    --label-color: #{$color--lds-primary};
    ---name-color: #{$color--lds-secondary};
  }
}
</style>
