export default {
  title: 'Steps For Activation',
  elite: {
    text1: 'Your Dealership is Model e Certified',
    text2: 'Elite',
  },
  notifications: {
    title: 'Notifications',
    description: 'Number of notifications: {count}',
  },
  enrollment: {
    title: 'Enrolment F(ii) Authority',
    description: 'Confirm dealership information, set in-dealership team, set remote delivery preference',
    submitButton: 'Submit enrolment',
    errorMessage: 'Failed to submit the enrolment. Please try again.',
    modal: {
      title: 'Your dealership has been enroled in Model e eCommerce!',
      subtitle: 'Model e eCommerce experience',
      description: 'As you move on to complete your steps for activation, you\'ll notice some information has been pre-populated from your Ford Blue setup. If you see a green check mark, that step is complete, however you always have the option to make edits. Open each accordion to review all completion steps.',
    },
  },
  termsAndConditions: {
    title: 'Terms & Conditions and Key Decision Makers',
    subtitle: 'Model e enrolment Terms & Conditions',
    description: 'Accepting Terms & Conditions is necessary for eCommerce enrolment. As Terms & Conditions are updated, your dealership will need to agree to them to remain in the program.',
    acceptTerms: {
      warning: 'Accept Terms and Conditions.',
      success: 'Terms and Conditions successfully marked as accepted.',
      error: 'Failed to mark Terms and Conditions as accepted. Please try again.',
    },
  },
  teamSetup: {
    title: 'Dealer Information and Team Setup',
    description: 'Please review your dealership’s information and set your in-dealership team. Key Decision Makers (KDMs) are the only individuals in the dealership who can assign these leads. As you are building your team, you can assign individuals within your dealership to have more than one of these responsibilities. You can also choose to assign more than one individual to each role. Be sure you are selecting the person or people within your dealership that are best suited to complete their relevant setup steps. To learn more about each role, click the information circle next to the title.',
    subtitle: 'Dealership Information',
  },
  remoteDelivery: {
    title: 'Remote Delivery Preference',
    description: 'Please choose whether your dealership would like to present an option for Customers to indicate their interest in remote delivery. All remote delivery details will be handled offline between your dealership and your Customer. Dealership agrees to remain compliant with federal and local guidelines.',
    label: 'Would your dealership like to present remote delivery as on option to customers in the eCommerce experience?',
    successMessage: 'Remote Delivery Preference saved successfully.',
    errorMessage: 'Failed to save Remote Delivery Preference. Please try again.',
  },
  dealershipSetupTeam: {
    p1: 'This team member’s information has been carried over from your dealership’s Ford Blue experience. Please review the details below, and if correct, click \'Save Contact.\' If their information requires edits, click the (+) to expand the contact, then update and click \'Save.\'',
    p2: 'Our records indicate this team member is no longer assigned to your dealership. Please click \'Delete Contact\' and add a new team member, if necessary.',
  },
  stripe: {
    title: 'Stripe Account',
    description: 'Create a Stripe account to receive direct-to-Dealer payments from eCommerce Guests',
    accountSetup: {
      title: 'Stripe Account and Fee Setup',
      p1: 'The Model e eCommerce experience introduces the ability for Guests to request Advanced Access and submit a request to purchase or lease their electric vehicle online, directly to Dealers. To accept eCommerce Guest-submitted funds, dealerships must set up a Stripe account. To begin the Stripe account application process, click on the ‘Stripe Account Setup’ button.',
      p2: 'The Model e eCommerce experience introduces the ability for Guests to request Advanced Access and submit a request to purchase or lease their electric vehicle online, directly to Dealers. To accept eCommerce Guest-submitted funds, dealerships must set up a Stripe account.',
    },
  },
  vehiclePricing: {
    title: 'Vehicle Pricing',
    description: 'Complete Dealer-installed option pricing to be presented to Guests in the experience',
    p1: 'Model e vehicle pricing is set by Ford of Canada at MRP (Manufacturers Retail Price). Vehicle Locator Plus (VL Plus) provides the ability to set pricing for Dealer-installed options (DIOs), which will be included in the vehicle price displayed to your Guests within the Model e eCommerce experience.',
    button: 'Set DIO Pricing',
    entitlements: {
      p1: 'Entitlements must be set prior to complete DIO pricing in the Inventory Manager in VL Plus. Assigned personnel shown in the box below are pre-populated from your Ford Blue setup. If you wish to make changes, click the \'Set Entitlements\' button to assign the VL_CTIMNGT entitlement. Only authorized Dealer users with authority to set DIO pricing should be granted entitlements.',
      button: 'Set Entitlements',
      label: 'Vehicle Pricing Entitlements - User IDs',
      empty: 'Your dealership does not have entitlements set.',
    },
  },
  accessoryPricing: {
    title: 'Accessory Pricing',
    description: 'Set your Dealer pricing structure for accessories',
    buttonPricing: 'Set Accessory Pricing',
    buttonEntitlements: 'Set Entitlements',
    entitlementsTitle: 'Accessory Pricing Entitlements - User IDs',
    noEntitlements: 'Your dealership does not have entitlements set.',
    p1: 'Pricing in the eCommerce Marketplace Portal (EMP) provides Dealers the opportunity to set the pricing structure for accessories and labor rates for installation.',
    p2: '**100% of Ford accessories must be priced',
    p3: 'Setting accessory pricing entitlements is a mandatory step that must be completed before entering EMP.  Assigned personnel are shown in the box below are pre-populated from your Ford Blue setup. If you wish to make changes, click the \'Set Entitlements\' button to assign the EMP_eComm_Accessories entitlement. Only authorized Dealer users with authority to set pricing should be granted accessory pricing module entitlements.',
  },
  tradeIn: {
    title: 'Trade-In',
    description: 'Make a decision on whether to offer an optional Ford-backed value in eCommerce or select to offer an estimated value that will be handled offline',
    p1: 'Model e Dealers have choices when it comes to trade-in vehicle submissions from eCommerce Guests. The optional trade-in tool for Model e eCommerce provides your dealership the ability to select to offer either an estimated value or a Ford-backed value on a trade-in vehicle. If you have opted out of the Ford-backed appraisal tool and choose to use the estimated tool instead, you will obtain the estimate and work with your Guest to agree on the final trade-in value for the transaction. If you elect to offer a Ford-backed value, your dealership will choose whether to (1) retain a Guest-submitted trade-in vehicle for inventory, (2) redeem the value and send to auction through Automotive Remarketing Services (ARS), or (3) reject the vehicle if it does not match the condition and information provided by the Guest during the online appraisal process. If you choose to reject a vehicle, your dealership may treat it like an estimated value, and you can then handle the trade-in evaluation and finalization offline.',
    label1: 'If your dealership would like to offer the Ford-backed trade-in values online, toggle to \'Yes\' and click \'Save.\' To activate your selection, your dealership must complete ARS enrolment. ',
    label2: 'If your dealership would like to offer estimated values and handle trade-in evaluations offline, toggle to \'No\' and click \'Save.\' ',
    label3: '* If your dealership decides to change to offer a Ford-backed value, your dealership needs to be enroled in ARS prior to modifying your selection.',
    savedSuccessfully: 'Trade-In preference successfully saved.',
    failedToSave: 'Failed to save Trade-In preference. Please try again.',
    notEnrolledInArs: 'Your selection was not saved, opt-in requires your dealership to be enroled in ARS.',
    dashboardArs: {
      text1: 'Your dealership is enroled in ARS.',
      text2: 'Your dealership is not enroled in ARS.',
      p1: 'Ford has built a trade tool that allows a Guest to receive a Ford-backed value with their trade-in vehicle submission. Dealers maintain full control and make decisions about accepting the vehicle to retain for inventory, rejecting, or sending the vehicle to auction through Automotive Remarketing Services (ARS) to redeem the Ford-backed value. Dealers must be enroled in the ARS program to redeem Ford offers.',
      p2: 'To enrol in ARS, download and complete the forms below and email them directly to Cars4&commat;Ford.com with the subject line: ”&lt;your dealership name&gt; - ARS Enrolment Docs.” Enrolment can take up to 10 business days to complete.',
      text3: 'ARS Dealer Information Form',
      text4: 'ARS Remarketing Agreement',
      text8: 'Power of Attorney Form(s) specific to province or city:',
      text9: 'Power of Attorney – ADESA British Columbia',
      text10: 'Power of Attorney – Manheim Toronto and Manheim Montreal',
      text11: 'Power of Attorney – ADESA (all other Provinces and Cities)',
      text5: 'ARS Dealer Code Payment Letter',
      text6: 'Trade-In Entitlements - User IDs',
      p4: 'Setting trade-in entitlements is a mandatory step that must be completed before entering the trade-in module. Assigned personnel are shown in the box below have been pre-populated from your pre-existing Ford Blue setup for eCommerce. If you wish to make changes, click the \'Set Entitlements\' button to assign the ARSInvoice and ARSVehicleNotification entitlements. Only authorized Dealer users should be granted trade-in module entitlements.',
      btn2: 'Set Entitlements',
      text7: 'Your dealership does not have entitlements set.',
    },
  },
  tablets: {
    title: 'Tablets',
    accordionDescription: 'Review specifications and submit screenshot for verification',
    description1: 'iPad<sup> ® </sup> mobile digital devices will be used to enable many Model e Guest Experiences.',
    description2: 'Review the minimum specifications below, then from your iPad<sup> ® </sup> take a screenshot of the system information and upload the screenshot for verification. Complete this verification process for each iPad<sup> ® </sup>. A URL will also need to be saved to each iPad<sup> ® </sup>, which will become available later this year.',
    description3: 'How to take a screenshot of the iPad<sup> ® </sup> system information: Access the iPad<sup> ® </sup> settings, click on ‘General,’ and screenshot the information shown in the ‘About’ section.',
    listItem1: 'Model e Certified Elite Dealers: 3 iPad<sup> ® </sup> tablets are required',
    listItem2: 'Model e Certified Dealers: two iPad<sup> ® </sup> tablets are required',
    reviewButton: 'Request Manual Review',
    message: 'Tablet meets the minimum technical requirements.',
    errorMessage : 'Failed to load tablet list. Please try again.',
    upload: {
      error: 'The image verification failed for the following reason: {reason}',
      button: 'Upload',
    },
    table: {
      model: 'Model',
      modelNumber: 'Model #',
      serialNumber: 'Serial #',
      version: 'OS Version',
      verifiedAt: 'Date Verified',
      button: 'Refresh Table',
    },
    specifications: {
      title: 'Minimum Specifications for Activation:',
      item1: 'Number of mobile digital devices:',
      item2: 'Serial numbers must be unique per dealership',
      item3: 'Software: iOS 16 or higher',
      text1: 'Model e Certified Elite Dealers: 3 iPad<sup> ® </sup> mobile digital devices',
      text2: 'Model e Certified Dealers: 3 iPad<sup> ® </sup> mobile digital devices',
      disclaimer1: '*iPad<sup> ® </sup> is a trademark of Apple Inc.',
      disclaimer2: '*We do not retain any uploaded images.',
      disclaimer3: '*Please do not share any personally identifiable information.',
      disclaimer4: '*iPad<sup> ® </sup> Cellular or Wi-Fi™ connection is necessary to support remote experiences and vehicle delivery. You may use an iPad<sup> ® </sup> with built in cellular data or leverage resources, such as a mobile cellular hotspot, that can provide the iPad<sup> ® </sup> with a minimum of 5G LTE cellular connectivity when local Wi-Fi™ is unavailable or unreliable. Dealer should supply own Wi-Fi™ capabilities.',
    },
    menuUrl: {
      title: 'Model e Menu URL',
      description: '*The menu URL needs to be saved to each unique iPad <sup>®</sup>.',
      p1: 'Now that you have verified your iPad <sup> ® </sup> mobile digital devices, the next step is to save your Dealer-specific Model e Menu URL as a shortcut to each iPad<sup> ® </sup> for ease of accessing. The Model e Menu experience will be an important tool for working with your Guests shopping for electric vehicles. It includes EV shopping and buying tools. The link will direct you to content and inventory for your dealership. This includes Build & Order/Search Inventory functionality, Home Charging, Public Charging, shopping tools to work through Guest questions, as well as information on recommended care for electric vehicles. The Model e Menu is designed with the goal of making Ford EV information at the fingertips of your dealership’s Retail Purchase Specialists. The Model e Menu is meant to be Guest-facing, but to be Retail Purchase Specialist led.',
      p2: 'From your iPad <sup> ® </sup>, click ‘Access Model e Menu,’ which will open your Dealer-specific menu in a new browser window. From this page, find and tap on the share icon on the menu bar (it looks like a box with an up arrow). Then, from the dropdown list choose \'Add to Home Screen.\' Click \'Add\' in the upper right-hand corner. The shortcut should now appear on the home screen of your iPad<sup> ® </sup>. We recommend clicking the icon to test functionality to confirm it loads properly.',
      instructions: 'To save to your tablet’s Home Screen:',
      additionalInfo: 'For additional information and instructional videos on features/usage of the Model e Menu, please search \'Tablets\' in the Resource Center.',
      label: 'Model e Menu URL',
      buttonText: 'Access Model e Menu',
    },
  },
  standardCompliance: {
    title: 'Model e Standards Compliance',
    description: 'Meet the compliance standards that are required for activation',
  },
  protectionPlans: {
    title: 'Protection Plans',
    description: 'Create a customized Dealer-priced F&I menu to present to Customers in the experience',
  },
  customer: {
    title: 'Customer Handling/Activity',
    description: 'Set entitlements and prepare team to respond to Guest submissions',
    handling: {
      title: 'Customer Handling',
      description1: 'The Model e eCommerce experience provides your Guests an option to request Advanced Access and submit a request to purchase or lease their electric vehicle online, with your dealership. Guest-submitted requests will flow directly into your dealerships CRM and into the eCommerce Marketplace Portal (EMP). The EV specialist assigned during your dealership’s enrolment will receive hot alert emails, notifying them of new requests and directing them to the Customer Handling Pages in EMP to take action.',
      description2: 'The Customer Handling Pages provide access to the selections the Guest has made in the online experience. Here you will be able to accept the submission, accept deposits and down payments, review trade-in requests, and respond to inquiries for online paperwork.',
      button: 'Customer Handling Pages',
      entitlementsDescription: 'Entitlements are needed to manage Guest requests. Click the \'Set Entitlements\' button to assign the entitlements listed below. Only authorized Dealer users with authority to manage Guest Customer submissions should be granted these entitlements.',
      entitlementsButton: 'Set Entitlements',
      listTitle: 'Customer Handling Entitlements:',
      listItem1: 'EMP_eComm_Customer Handling',
      listItem2: 'EMP_Model e',
      userIds: 'Customer Handling Entitlements - User IDs',
      noEntitlements: 'Your dealership does not have entitlements set.',
    },
    activity: {
      title: 'Customer Activity',
      description: 'Your dealership will have access to eCommerce Customer Guest activity reporting in the Enhanced Dealer Dashboard (EDD). There are two eCommerce reporting views in EDD. Dealer Mode includes two eCommerce views: eCommerce Leads and Sales, and eCommerce Lead Details. Enterprise Mode includes one eCommerce view: eCommerce Leads and Sales. Reports can be generated from either metrics or Customer-level activity. You can access these views with your existing credentials. If you do not have access to EDD, contact your dealership administrator.',
      button: 'Enhanced Dealer Dashboard',
    },
  },
  optOut: {
    title: 'Opt-out',
    subtitle: 'Initiate opt-out for the Model e experience',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  errors: {
    TERMS_CONDITIONS_NOT_COMPLETED: 'Accept Terms and Conditions.',
    DEALER_INFO_NOT_COMPLETED: 'Add a dealer phone and save before continue.',
    teamSetupNotCompleted: 'Team Setup form is not completed.',
    kdmNotSet: 'At least one Key Decision Maker (KDM) is required. More details on KDM\'s can be found in the Terms & Conditions section of enrolment.',
    remoteDeliveryNotSet: 'Remote Delivery option not set.',
  },
};
