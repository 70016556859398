<template>
  <div>
    <button
      class="help-about__button"
      @click="toggleModal"
    >
      <span :title="formatTitle(app.date)">
        {{ $l('help.about.appVersion', { version: app.version }) }},
      </span>
      <span :title="formatTitle(api.date)">
        {{ $l('help.about.apiVersion', { version: api.version }) }}
      </span>
    </button>
    <AppDialog
      centered
      size="md"
      :open="showModal"
      :title="$l('help.about.modal.title')"
      @close="toggleModal"
    >
      <table class="table table-striped">
        <colgroup>
          <col />
          <col style="width: 40%;" />
        </colgroup>
        <tbody>
          <tr>
            <td>{{ $l('help.about.modal.environment') }}</td>
            <td>{{ environment }}</td>
          </tr>
          <tr>
            <td>{{ $l('help.about.modal.app') }}</td>
            <td>{{ app.version }} on {{ formatDate(app.date) }}</td>
          </tr>
          <tr>
            <td>{{ $l('help.about.modal.api') }}</td>
            <td>{{ api.version }} on {{ formatDate(api.date) }}</td>
          </tr>
        </tbody>
      </table>
    </AppDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { ElementType, Event } from '@/constants/track';
import AppDialog from './common/AppDialog.vue';

export default {
  name: 'HelpAbout',
  components: {
    AppDialog,
  },
  data() {
    return {
      showModal: false,
      environment: process.env.VUE_APP_BASE_ENVIRONMENT,
      app: {
        version: '2.0',
        date: '2021-01-01T00:00:00Z',
      },
      api: {
        version: '0.0',
        date: '2021-01-01T00:00:00Z',
      },
    };
  },
  computed: {
    ...mapGetters({
      dateFormatPattern: 'locale/dateFormatPattern',
    }),
  },
  methods: {
    formatTitle(date) {
      return format(new Date(date), `${this.dateFormatPattern} hh:mm:ss a`);
    },
    formatDate(date) {
      return format(new Date(date), this.dateFormatPattern);
    },
    toggleModal() {
      this.showModal = !this.showModal;

      if (this.showModal) {
        this.$store.dispatch('track/event', {
          event: Event.OPEN_ABOUT_MODAL,
          elementType: ElementType.MODAL,
          pageName: this.$route.meta.track.pageName,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.help-about__button {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 10px;
  outline-offset: 5px;
  float: right;
  cursor: pointer;
}
</style>
