<template>
  <div class="select-experience">
    <p>{{ $l('selectExperience.description') }}</p>
    <div class="select-experience__navigation-wrapper">
      <NavigationCard
        class="select-experience__card"
        aria-label="Go to the Start page"
        :title="$l('selectExperience.blue.title')"
        :subtitle="$l('selectExperience.blue.subtitle')"
        :path="{ name: 'Start' }"
        :track-event-name="Event.SELECT_EXPERIENCE_BLUE"
      >
        <p>{{ $l('selectExperience.blue.description') }}</p>
        <StatusIcon
          class="select-experience__card-status"
          show-label
          :status="blueProgram.programProgressStatus || ''"
        />
      </NavigationCard>
      <NavigationCard
        v-if="certificationLevel.modelE || certificationLevel.modelEElite"
        class="select-experience__card"
        aria-label="Go to the Model e Start page"
        :title="modelECard.title"
        :subtitle="modelECard.subtitle"
        :path="{ name: 'ModelEStart' }"
        :track-event-name="Event.SELECT_EXPERIENCE_MODEL_E"
      >
        <template
          v-if="certificationLevel.modelEElite"
          #subtitle
        >
          {{ $l('selectExperience.modelE.subtitleElite') }}
          <span>
            {{ $l('selectExperience.modelE.subtitleElite2') }}
          </span>
        </template>
        <p>{{ $l('selectExperience.modelE.description') }}</p>
        <StatusIcon
          class="select-experience__card-status"
          show-label
          :status="modelEProgram.programProgressStatus || ''"
        />
      </NavigationCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Event } from '@/constants/track';
import NavigationCard from '@/components/NavigationCard.vue';
import StatusIcon from '@/components/StatusIcon.vue';

export default {
  name: 'SelectExperienceView',
  components: {
    NavigationCard,
    StatusIcon,
  },
  data() {
    return {
      Event,
    };
  },
  computed: {
    ...mapGetters({
      blueProgram: 'user/blueProgram',
      modelEProgram: 'user/modelEProgram',
      certificationLevel: 'user/certificationLevel',
    }),
    modelECard() {
      const { modelEElite } = this.certificationLevel;

      return {
        title: (modelEElite)
          ? this.$l('selectExperience.modelE.titleElite')
          : this.$l('selectExperience.modelE.title'),
        subtitle: (modelEElite) ? '' : this.$l('selectExperience.modelE.subtitle'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.select-experience {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 24px;
  text-align: center;
  &__navigation-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    @media (min-width: $sm-breakpoint) {
      flex-direction: row;
      align-items: unset;
    }
  }
  &__card {
    --max-width: 500px;
  }
  &__card-status {
    margin-top: auto;
  }
}
</style>
