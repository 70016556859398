<template>
  <div class="menuContainer">
    <nav
      class="mini-menu"
      role="navigation"
    >
      <button
        type="button"
        aria-label="menu"
      >
        <span class="app-icon-v-eclipse-square" />
      </button>
      <img
        src="../assets/images/menu-caret.png"
        alt="caret"
        class="menu-caret"
      />
      <ul class="drop-menu">
        <li @click="scrollTo('customer-experience')">
          {{ $l("resourceCenter.menu.link1") }}
        </li>
        <li @click="scrollTo('customer-journey')">
          {{ $l("resourceCenter.menu.link2") }}
        </li>
        <li @click="scrollTo('marketplace-portal')">
          {{ $l("resourceCenter.menu.link3") }}
        </li>
        <li @click="scrollTo('operationalize')">
          {{ $l("resourceCenter.menu.link4") }}
        </li>
        <li @click="scrollTo('resource-departments')">
          {{ $l("resourceCenter.menu.link5") }}
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'ResourceCenterMenu',
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);

      if (element) {
        window.scrollTo(0, element.offsetTop - 140);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menuContainer {
  width: 200vw;
  margin: 0 auto;

  .mini-menu {
    position: fixed;
    top: 345px;
    width: 25px;
    z-index: 100;

    &[data-brand="ford"] {
      font-weight: $font-weight--normal;

      .drop-menu {
        width: 490px;
      }
    }
    &[data-brand="lincoln"] {
      .drop-menu {
        width: 440px;
      }
    }

    &:hover {
      .drop-menu {
        display: block;
      }

      .menu-caret {
        display: block;
        width: 17px;
        height: 26px;
      }
    }

    button {
      border: none;
      background: transparent;
      font-weight: bold;
      font-size: 24px;

      .app-icon-v-eclipse-square {
        color: var(--app-color--primary);
      }
    }

    .menu-caret {
      display: none;
      position: absolute;
      left: 27px;
      top: 0px;
      z-index: 2;
    }

    .drop-menu {
      position: absolute;
      top: -40px;
      left: 40px;
      width: 490px;
      z-index: 1;
      display: none;
      float: left;
      padding: 0px 0;
      font-size: 16px;
      color: var(--app-color--primary);
      text-align: left;
      list-style: none;
      background-color: $color--white;
      background-clip: padding-box;
      border: 1px solid #636363;
      border-radius: 4px;
      box-shadow: 0 6px 12px lightgray;
      max-height: 400px;
      overflow-y: auto;

      li {
        height: 50px;
        padding: 10px 15px;
        cursor: pointer;
        color: $color--fds-primary;
        font-weight: $font-weight--normal;

        &:hover {
          background-color: $color--fds-secondary;
          color: $color--white;
        }
      }

      .sub-item {
        margin-left: 10px;
        font-weight: bold;
      }

      .divider {
        padding: 0px;
        height: 1px;
        margin: 0px 0;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.2);
      }

      .active {
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .menuContainer {
    display: none;
  }
}
</style>
