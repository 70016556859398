<template>
  <div class="start">
    <NavigationCard
      aria-label="Go to Resources Center page"
      :title="$l('start.resourceCenter.title')"
      :subtitle="$l('start.resourceCenter.subtitle')"
      :path="{ name: 'ResourceCenter' }"
    >
      <p>{{ $l('start.resourceCenter.description') }}</p>
    </NavigationCard>
    <NavigationCard
      aria-label="Go to the Communications Center page"
      :title="$l('start.commsCenter.title')"
      :subtitle="$l('start.commsCenter.subtitle')"
      :path="{ name: 'Communications Center' }"
      :show-alert-icon="commsCenterHasUnreadContent"
    >
      <p>{{ $l('start.commsCenter.description') }}</p>
    </NavigationCard>
    <NavigationCard
      aria-label="Go to the Dashboard page"
      :title="$l('start.dashboard.title')"
      :subtitle="$l('start.dashboard.subtitle')"
      :path="{ name: 'Dashboard' }"
      :show-alert-icon="pricingIsBelowInventory"
    >
      <p>{{ $l('start.dashboard.description') }}</p>
      <ul class="mt-2">
        <li>{{ $l('start.dashboard.list.item1') }}</li>
        <li>{{ $l('start.dashboard.list.item2') }}</li>
        <li>{{ $l('start.dashboard.list.item3') }}</li>
        <li>{{ $l('start.dashboard.list.item4') }}</li>
      </ul>
    </NavigationCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import vehiclePricingAlertMixin from '@/mixins/vehiclePricingAlertMixin';
import NavigationCard from '@/components/NavigationCard.vue';

export default {
  name: 'StartView',
  // mixins: [vehiclePricingAlertMixin],
  components: {
    NavigationCard,
  },
  computed: {
    ...mapGetters({
      commsCenterHasUnreadContent: 'commsCenter/hasUnreadContent',
      pricingIsBelowInventory: 'dashboard/vehiclePricing/pricingIsBelowInventory',
    }),
  },
  mounted() {
    this.$store.dispatch('dashboard/vehiclePricing/load');
  },
};
</script>

<style lang="scss" scoped>
.start {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 24px;
  @media (min-width: $sm-breakpoint) {
    flex-direction: row;
    align-items: stretch;
  }
}
</style>
