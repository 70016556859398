export default {
  dashboard: {
    list: {
      item2: 'Assign and update your in-Store team',
      item3: 'Modify your Store settings',
    },
  },
  resourceCenter: {
    description: 'This is your one-stop shop for eCommerce tools. As you explore you\'ll gather everything you need to assist you in activating and providing the best experience for your eCommerce Clients.',
  },
};
