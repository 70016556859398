<template>
  <div class="home-terms-conditions">
    <h3 class="fds-headline__5">
      {{ $l('home.title') }}
    </h3>
    <p>{{ $l('home.terms.description') }}</p>
    <TermsAndConditionsView
      v-model="userAcceptedTerms"
      class="home-terms-conditions__view"
    />
    <StandardButton
      track-event-name="confirm"
      :is-loading="isLoading"
      :text="$l('general.confirm')"
      @click="handleConfirm"
    />
    <ModalAccept
      :open="showModalAccept"
      @close="showModalAccept = false"
    />
    <ModalNotAllowed
      :open="showModalNotAllowed"
      @close="showModalNotAllowed = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import StandardButton from './common/StandardButton.vue';
import TermsAndConditionsView from './TermsAndConditionsView.vue';
import ModalAccept from './HomeTermsAndConditionsModalAccept.vue';
import ModalNotAllowed from './HomeTermsAndConditionsModalNotAllowed.vue';

export default {
  name: 'HomeTermsAndConditions',
  components: {
    StandardButton,
    TermsAndConditionsView,
    ModalAccept,
    ModalNotAllowed,
  },
  data() {
    return {
      isLoading: false,
      userAcceptedTerms: false,
      confirmCount: 0,
      showModalAccept: false,
      showModalNotAllowed: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      termsAndConditions: 'termsAndConditions/terms',
    }),
  },
  methods: {
    handleConfirm() {
      this.confirmCount += 1;

      if (!this.userIsKeyDecisionMaker) {
        this.showModalNotAllowed = true;
        return;
      }

      if (!this.userAcceptedTerms && this.confirmCount === 1) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('home.terms.errorMessage'),
        });

        return;
      }

      if (!this.userAcceptedTerms) {
        this.showModalAccept = true;
        return;
      }

      this.saveTermsAndConditions();
    },
    async saveTermsAndConditions() {
      this.isLoading = true;

      try {
        await apiManager.termsAndConditions.acceptTermsAndConditions(
          this.activeDealership.dealerId,
          this.termsAndConditions.latestPdf,
        );
        await this.$store.dispatch('termsAndConditions/load');

        this.$router.push({ name: 'Dashboard' });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-terms-conditions {
  width: 100%;
  max-width: 850px;
  text-align: center;
  &__view {
    margin: 32px 0;
  }
}
</style>
