<template>
  <div
    class="notification"
    :class="$store.getters['user/activeBrandName']"
  >
    <div class="notification__content">
      <div class="notification__message-wrapper">
        <span
          class="notification__status"
          :class="status"
        />
        <p class="notification__message">
          {{ message }}
        </p>
      </div>
      <TextButton
        v-if="accordion || subcordion"
        aria-label="Go to section"
        track-section-name="notifications"
        :track-event-name="`${Event.VIEW_NOTIFICATION}_${url}`"
        :text="$l('general.view')"
        @click="openAccordion"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { Event } from '@/constants/track';
import { dashboardAccordions } from '@/data/accordion';
import TextButton from './common/TextButton.vue';

const store = useStore();

const props = defineProps({
  message: { type: String, default: '' },
  status: { type: String, default: '' },
  url: { type: String, default: '' },
  accordion: { type: String, default: '' },
  subcordion: { type: String, default: '' },
});

function openAccordion() {
  const accordion = dashboardAccordions[props.accordion];
  const subcordion = accordion[props.subcordion];

  store.dispatch('dashboard/accordions/openAccordion', accordion.id);
  store.dispatch('dashboard/accordions/openSubcordion', subcordion.id);
}
</script>

<style lang="scss" scoped>
.notification {
  --text-color: #{$color--fds-primary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  background-color: $color--fds-gray1;
  border: 1px solid $color--white;
  padding: 0 16px;
  @include respond(sm-up) {
    min-height: 48px;
  }
  &.lincoln {
    --text-color: #{$color--lds-primary};
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1150px;
  }
  &__message-wrapper {
    display: grid;
    grid-template-columns: 32px auto;
    align-items: center;
  }
  &__message {
    margin: 0;
    margin-right: 16px;
    color: var(--text-color);
  }
  &__status {
    width: 16px;
    height: 16px;
    border: 1px solid $color--white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    background-color: #808080;
    &.red {
      background-color: $color--fds-error1;
    }
    &.green {
      background-color: $color--fds-success1;
    }
  }
}
</style>
