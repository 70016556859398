export default {
  title: 'Key Decision Maker(s)',
  description1: 'In order to successfully enrol you must assign at least one Key Decision Maker (KDM) within your dealership that will have authority to make eCommerce Program decisions. You can assign up to four total Key Decision Makers, including yourself. F(ii) authority is necessary to be the assignee of KDMs and to be assigned as a KDM.',
  description2: 'When updated Terms & Conditions are sent, your dealership will need to agree to them to remain in the program. The functionality below allows you to add up to three Key Decision Makers, in addition to yourself, who will be authorized to agree to the updated Terms & Conditions.',
  tooltip: 'The Key Decision Maker has the authority to agree to Terms & Conditions and manage the eCommerce team members for your dealership.',
  form: {
    position: 'Role / Title',
    phone: 'Direct Phone or Cell',
    extension: 'Extension',
    wslx: 'WSLX ID (FMCDealer Login)',
    checkbox: 'By selecting the checkbox, I acknowledge that the information provided is correct and that I am authorized (with F(ii) Authority) to complete the enrolment for my dealership.',
    addButton: 'Add Key Decision Maker',
    saveButton: 'Save Contact',
    deleteButton: 'Delete Contact',
    unsavedChanges: 'Key Decision Maker',
  },
};
