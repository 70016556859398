<template>
  <div
    v-show="open"
    class="overlay"
    :class="overlayClasses"
    @mousedown.self="closeDialog"
  >
    <div
      class="dialog"
      role="dialog"
      aria-modal="true"
      :class="[dialogClasses, $store.getters['user/activeBrandName']]"
    >
      <button
        v-show="dismissible"
        ref="dialogButton"
        class="dialog__close-button"
        type="button"
        aria-label="Close dialog"
        @click="closeDialog"
      >
        <i class="dialog__close-button-icon app-icon-clear" />
      </button>
      <header
        v-if="title || $slots.header"
        class="dialog__header"
      >
        <slot name="header">
          <h3 class="dialog__title">
            {{ title }}
          </h3>
        </slot>
      </header>
      <div class="dialog__body">
        <slot />
      </div>
      <div
        v-if="$slots.footer"
        class="dialog__footer"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
const VALID_SIZES = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

export default {
  name: 'AppDialog',
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: VALID_SIZES.large,
      validator(size) {
        return Object.values(VALID_SIZES).includes(size);
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    overlayClasses() {
      return {
        'overlay--centered': this.centered,
      };
    },
    dialogClasses() {
      const { small, medium, large } = VALID_SIZES;
      return {
        'dialog--small': this.size === small,
        'dialog--medium': this.size === medium,
        'dialog--large': this.size === large,
        'dialog--align-y': !this.title,
      };
    },
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          this.$refs.dialogButton.focus();

          document.body.addEventListener('keyup', this.handleKeyupEvent);
        });
      } else {
        document.body.removeEventListener('keyup', this.handleKeyupEvent);
      }
    },
  },
  methods: {
    handleKeyupEvent(event) {
      if (event.key === 'Escape' && this.dismissible) {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  --vertical-align: baseline;
  --horizontal-align: center;
  --footer-justify-content: flex-start;
  --vertical-padding: 20px;
  --horizontal-padding: 96px;
  display: grid;
  place-items: var(--vertical-align) var(--horizontal-align);
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $color--black-70;
  z-index: $z-index__dialog;
  overflow-y: auto;
  &--centered {
    --vertical-align: center;
  }
  @media (min-width: $screen-sm-min) {
    padding: var(--horizontal-padding) var(--vertical-padding);
  }
  @media (max-height: 750px) {
    --horizontal-padding: 48px;
  }
}
.dialog {
  width: 100%;
  height: 100%;
  padding: 32px;
  padding-top: 56px;
  background-color: $color--white;
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: 85%;
    height: auto;
    border-radius: 3px;
    &--small {
      max-width: 480px;
    }
    &--medium {
      max-width: 716px;
    }
    &--large {
      max-width: 952px;
    }
    &--align-y {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  @media (min-width: $screen-md-min) {
    width: 75%;
  }
  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    outline: none;
    &:focus-visible::after {
      content: '';
      outline: 1px solid transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  &__header {
    margin-bottom: 20px;
  }
  &__title {
    margin: 0;
    font-size: 24px;
    font-weight: $font-weight--normal;
  }
  &__body {
    font-weight: $font-weight--normal;
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--footer-justify-content);
    width: 100%;
    gap: 25px;
    margin-top: 32px;
  }
}
.dialog.ford {
  color: $color--fds-primary;
  .dialog__close-button:focus-visible::after {
    outline-color: $color--fds-primary;
  }
}
.dialog.lincoln {
  color: $color--lds-primary;
  .dialog__close-button:focus-visible::after {
    outline-color: $color--lds-primary;
  }
  .dialog__title {
    font-size: 18px;
    text-transform: uppercase;
  }
}
</style>
