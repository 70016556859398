export default {
  title: 'Étapes de la mise en œuvre',
  elite: {
    text1: 'Votre concessionnaire est certifié Model e',
    text2: 'Élite',
  },
  notifications: {
    title: 'Notifications',
    description: 'Nombre de notifications : {count}',
  },
  enrollment: {
    title: 'Inscription (F(ii) Pouvoir de gestion)',
    description: 'Confirmer les renseignements relatifs au concessionnaire, créer l’équipe chez le concessionnaire, définir les préférences en matière de livraison à distance',
    submitButton: 'Soumettre Inscription',
    errorMessage: '[NT] Failed to submit the enrolment. Please try again.',
    modal: {
      title: 'Votre concessionnaire est maintenant inscrit au commerce électronique Model e!',
      subtitle: 'Expérience de commerce électronique Model e',
      description: 'Alors que vous procédez aux étapes de mise en œuvre, vous remarquerez que certains renseignements ont été préremplis depuis votre configuration Ford Blue. Si vous voyez un crochet vert, cette étape est terminée. Cependant, vous avez toujours la possibilité d’apporter des modifications. Ouvrez chaque menu en accordéon pour passer en revue toutes les étapes de réalisation.',
    },
  },
  termsAndConditions: {
    title: 'Conditions générales et décisionnaires clés',
    subtitle: 'Conditions générales de l’inscription au programme Model e',
    description: 'L’acceptation des Conditions générales est nécessaire pour l’inscription au commerce électronique. Une fois les Conditions générales mises à jour, votre concessionnaire devra les accepter pour continuer à faire partie du programme.',
    acceptTerms: {
      warning: 'Accepter les Termes et Conditions.',
      success: '[NT] Terms and Conditions successfully marked as accepted.',
      error: '[NT] Failed to mark Terms and Conditions as accepted. Please try again.',
    },
  },
  teamSetup: {
    title: 'Renseignements sur le concessionnaire et création de l’équipe',
    description: 'Veuillez passer en revue les renseignements sur votre concessionnaire et créer l’équipe de votre concessionnaire. Les décisionnaires clés sont les seuls employés au sein du concessionnaire qui peuvent désigner ces personnes. Lors de la création de votre équipe, vous pouvez confier plusieurs de ces responsabilités à une même personne de votre concessionnaire. Vous pouvez également choisir de désigner plus d’une personne pour chaque rôle. Veillez à sélectionner la ou les personnes de votre concessionnaire qui sont les mieux placées pour mener à bien les étapes de la mise en œuvre qui lui ont été confiées. Pour en savoir plus sur chacun des rôles, cliquez sur le cercle Information situé à côté du titre.',
    subtitle: 'Renseignements sur le concessionnaire',
  },
  remoteDelivery: {
    title: 'Préférence de livraison à distance',
    description: 'Veuillez choisir si votre concessionnaire souhaite offrir aux invités une option pour indiquer qu’ils sont intéressés par la livraison à distance dans le cadre de l’expérience de commerce électronique (lorsqu’elle sera offerte). Tous les détails relatifs à la livraison à distance seront réglés hors ligne par votre concessionnaire avec la participation de votre invité. Le concessionnaire convient de respecter les lignes directrices fédérales et locales.',
    label: 'Votre concessionnaire souhaite-t-il offrir à ses invités l’option de livraison à distance dans le cadre de l’expérience de commerce électronique?',
    successMessage: '[NT] Remote Delivery Preference saved successfully.',
    errorMessage: '[NT] Failed to save Remote Delivery Preference. Please try again.',
  },
  dealershipSetupTeam: {
    p1: '[NT] This team member’s information has been carried over from your dealership’s Ford Blue experience. Please review the details below, and if correct, click \'Save Contact.\' If their information requires edits, click the (+) to expand the contact, then update and click \'Save.\'',
    p2: '[NT] Our records indicate this team member is no longer assigned to your dealership. Please click \'Delete Contact\' and add a new team member, if necessary.',
  },
  stripe: {
    title: 'Compte Stripe ',
    description: 'Créer un compte Stripe pour recevoir des règlements directement avec le concessionnaire de la part d’invités qui utilisent le commerce électronique',
    accountSetup: {
      title: 'Établissement de compte Stripe et des frais',
      p1: 'L’expérience de commerce électronique Model e instaure la capacité des invités de demander un accès avancé et de soumettre une demande d’achat ou de location de leur véhicule électrique en ligne, directement aux concessionnaires. Pour accepter les fonds soumis par les clients du commerce électronique, les concessionnaires doivent configurer un compte Stripe. Pour commencer le processus de demande de compte Stripe, cliquez sur le bouton « Établissement de compte Stripe ».',
      p2: '[NT] The Model e eCommerce experience introduces the ability for Guests to request Advanced Access and submit a request to purchase or lease their electric vehicle online, directly to Dealers. To accept eCommerce Guest-submitted funds, dealerships must set up a Stripe account.',
    },
  },
  vehiclePricing: {
    title: 'Prix des véhicules :',
    description: 'Fixez le prix de l’équipement en option installé par le concessionnaire qui sera présenté aux invités durant l’expérience.',
    p1: 'Le prix des véhicules Model e est établi par Ford Canada au PDC (prix de détail du constructeur). L’outil Vehicle Locator Plus (VL Plus) permet de fixer le prix de l’équipement en option installé par le concessionnaire qui sera compris dans le prix du véhicule présenté à vos invités dans le cadre de l’expérience de commerce électronique Model e.',
    button: 'Déterminer le prix de l’équipement en option installé par le concessionnaire',
    entitlements: {
      p1: 'Il faut définir des autorisations avant de fixer le prix de l’équipement en option installé par le concessionnaire dans le gestionnaire des stocks de VL Plus. Le personnel désigné, qui figure dans la boîte ci-dessous, est prérémpli à partir de votre configuration Ford Blue. Cliquez sur le bouton « Établir les autorisations » pour attribuer l’autorisation VL_CTIMNGT. Seuls les utilisateurs autorisés du concessionnaire détenant la permission d’établir le prix de l’équipement en option installé par le concessionnaire doivent obtenir les autorisations.',
      button: 'Définir les autorisations',
      label: 'Autorisations liées aux prix des véhicules – ID d’utilisateur',
      empty: '[NT] Your dealership does not have entitlements set.',
    },
  },
  accessoryPricing: {
    title: 'Prix des accessoires :',
    description: 'Établir votre structure de prix défini par le concessionnaire pour les accessoires',
    buttonPricing: 'Établir les prix des accessoires',
    buttonEntitlements: 'Définir les autorisations',
    entitlementsTitle: 'Autorisations liées au prix des accessoires – Identifiants d’utilisateur',
    noEntitlements: '[NT] Your dealership does not have entitlements set.',
    p1: 'Les prix dans le portail de commerce électronique permettent aux concessionnaires de définir la structure de prix des accessoires et les tarifs de main-d’œuvre des installations.',
    p2: '** Il faut établir le prix de l’intégralité des Accessoires Ford.',
    p3: 'Il faut définir les privilèges liés aux prix des accessoires avant d’accéder au Portail de commerce électronique. Le personnel affecté présenté dans la case ci-dessous est préalablement inscrit en fonction de votre configuration de Ford Blue. Si vous souhaitez apporter des modifications, cliquez sur le bouton « Définir les autorisations » pour attribuer les autorisations EMP_eComm_Accessories. Seuls les utilisateurs autorisés du concessionnaire détenant la permission d’établir les prix des accessoires doivent obtenir les autorisations propres au module à cet effet.',
  },
  tradeIn: {
    title: 'Véhicule d’échange :',
    description: 'Choisissez d’offrir une valeur garantie par Ford pour le commerce électronique ou de proposer une valeur estimative gérée hors ligne.',
    p1: 'Des choix s’offrent aux concessionnaires Model e en ce qui a trait aux soumissions de véhicules d’échange des invités du commerce électronique. L’outil d’échange de véhicule en option pour le commerce électronique Model e permet à votre concessionnaire de sélectionner une valeur estimative ou une valeur garantie par Ford pour un véhicule d’échange. Si vous avez refusé l’inscription à l’outil d’évaluation garantie par Ford et choisi de plutôt utiliser l’outil de devis estimatif, vous obtiendrez le devis et négocierez avec votre invité pour déterminer la valeur finale du véhicule d’échange pour la transaction. Si vous choisissez d’offrir une valeur garantie par Ford, votre concessionnaire choisira (1) d’ajouter un véhicule d’échange soumis par l’invité à ses stocks, (2) de récupérer la valeur du véhicule et de l’envoyer aux enchères par l’entremise des Services de remise en marché des véhicules (ARS) ou (3) de rejeter le véhicule s’il ne correspond pas à l’état indiqué par l’invité et aux renseignements fournis sur le véhicule durant le processus d’évaluation en ligne. Si vous choisissez de rejeter un véhicule, votre concessionnaire peut le traiter comme si vous aviez fourni une valeur estimative à l’invité, puis effectuer l’évaluation de la valeur d’échange et la conclusion de la transaction hors ligne.',
    label1: 'Si votre concessionnaire souhaite offrir la valeur du véhicule d’échange garantie par Ford en ligne, basculez à « Oui » et sélectionnez l’option d’enregistrement. Pour activer votre sélection, votre concessionnaire doit s’inscrire au programme ARS.',
    label2: 'Si votre concessionnaire souhaite offrir la valeur estimative et gérer les évaluations de véhicule d’échange hors ligne, basculez à « Oui » et sélectionnez l’option d’enregistrement.',
    label3: '* Si votre concessionnaire décide ultérieurement qu’il préfère offrir une valeur d’échange garantie par Ford, il doit s’inscrire au programme ARS avant de modifier son choix.',
    savedSuccessfully: '[NT] Trade-In preference successfully saved.',
    failedToSave: '[NT] Failed to save Trade-In preference. Please try again.',
    notEnrolledInArs: '[NT] Your selection was not saved, opt-in requires your dealership to be enroled in ARS.',
    dashboardArs: {
      text1: '[NT] Your dealership is enroled in ARS.',
      text2: '[NT] Your dealership is not enroled in ARS.',
      p1: 'Ford compte sur un outil d’échange de véhicule qui permet à un invité de recevoir une valeur garantie par Ford lors de la soumission de son véhicule d’échange. Les concessionnaires demeurent en pleine maîtrise et décident d’accepter le véhicule pour l’ajouter à leurs stocks, de le rejeter ou de l’envoyer aux enchères par l’entremise des Services de remise en marché des véhicules (ARS) afin de réclamer la valeur garantie par Ford. Les concessionnaires doivent être inscrits au programme ARS pour réclamer les offres de Ford.',
      p2: 'Pour vous inscrire au programme ARS, téléchargez et remplissez les formulaires ci-dessous, puis envoyez-les par courriel directement à l’adresse Cars4@Ford.com en indiquant en objet : « <nom de votre concessionnaire> – Documents d’inscription aux ARS ». Le traitement de l’inscription peut demander jusqu’à 10 jours ouvrables.',
      text3: 'ARS – Formulaire de renseignements sur le client',
      text4: 'Entente de remise en marché du programme ARS',
      text8: 'Formulaires de procuration propres à la province ou à la ville',
      text9: 'Procuration – ADESA Colombie-Britannique',
      text10: 'Procuration – Manheim Toronto et Manheim Montréal',
      text11: 'Procuration – ADESA (toutes les autres provinces et villes)',
      text5: '[NT] ARS Dealer Code Payment Letter',
      text6: 'Autorisations d’accès à l’outil d’échange de véhicules – ID d’utilisateur',
      p4: 'Il faut définir les autorisations au sujet des échanges avant d’accéder au module des véhicules d’échange. Le personnel désigné, qui figure dans la boîte ci-dessous, est prérémpli à partir de votre configuration Ford Blue actuelle pour le commerce électronique. Si vous voulez apporter des changements, cliquez sur le bouton « Établir les autorisations » pour attribuer les autorisations ARSInvoice et ARSVehicleNotification. Seuls les utilisateurs autorisés du concessionnaire devraient détenir les autorisations propres au module des véhicules d’échange.',
      btn2: 'Définir les autorisations',
      text7: '[NT] Your dealership does not have entitlements set.',
    },
  },
  tablets: {
    title: 'Tablettes :',
    accordionDescription: 'Passer en revue les spécifications et soumettre des captures d’écran aux fins de vérification',
    description1: 'Les appareils numériques mobiles iPad<sup>MD</sup> serviront à rendre possible de nombreuses expériences d’invité Model e.',
    description2: 'Passez en revue les spécifications ci-dessous, puis, de votre iPad<sup>MD</sup>, prenez une capture d’écran des renseignements sur le système et téléversez la capture d’écran aux fins de vérification. Faites la démarche de vérification pour chaque appareil iPad<sup>MD</sup>. ',
    description3: 'Comment prendre une capture d’écran des renseignements du système du iPad<sup>MD</sup> : Accédez aux réglages du iPad<sup>MD</sup>, cliquez sur « Général » et prenez une capture d’écran des renseignements affichés à la « À propos ».',
    listItem1: '[NT] Model e Certified Elite Dealers: three iPad<sup> ® </sup> tablets are required',
    listItem2: '[NT] Model e Certified Dealers: two iPad<sup> ® </sup> tablets are required',
    reviewButton: '[NT] Request Manual Review',
    message: '[NT] Tablet meets the minimum technical requirements.',
    errorMessage : '[NT] Failed to load tablet list. Please try again.',
    upload: {
      error: '[NT] The image verification failed for the following reason: {reason}',
      button: '[NT] Upload',
    },
    table: {
      model: '[NT] Model',
      modelNumber: '[NT] Model #',
      serialNumber: '[NT] Serial #',
      version: '[NT] OS Version',
      verifiedAt: '[NT] Date Verified',
      button: '[NT] Refresh Table',
    },
    specifications: {
      title: 'Spécifications minimales pour l’activation :',
      item1: 'nombre d’appareils numériques mobiles :',
      item2: 'les numéros de série doivent être uniques pour chaque concessionnaire;',
      item3: 'logiciel : iOS 16 ou version ultérieure.',
      text1: 'concessionnaires Certifiés Élite Model e : trois appareils numériques mobiles iPad<sup>MD</sup>;',
      text2: 'concessionnaires Certifiés Model e : trois appareils numériques mobiles iPad<sup>MD</sup>;',
      disclaimer1: '* iPad<sup>MD</sup> est une marque de commerce d\'Apple Inc.',
      disclaimer2: '* Nous ne conservons aucune image téléversée.',
      disclaimer3: '* Veuillez ne pas communiquer de renseignement nominatif.',
      disclaimer4: '* La connexion cellulaire ou Wi-Fi<sup>MD</sup> de l’appareil iPad<sup>MD</sup>  est nécessaire pour prendre en charge les expériences à distance et la livraison de véhicule dans une région éloignée. Vous pouvez utiliser un iPad<sup>MD</sup> équipé de données cellulaires intégrées ou tirer parti de ressources, comme un point d’accès cellulaire mobile, qui peut fournir à l’appareil iPad<sup>MD</sup> au moins une connectivité cellulaire 5G-LTE lorsque le Wi-Fi<sup>MD</sup> local n’est ni accessible ni fiable. Le concessionnaire doit fournir ses propres capacités Wi-Fi<sup>MD</sup>.',
    },
    menuUrl: {
      title: 'URL du menu de Model e',
      description: '* Il faut enregistrer l’URL de menu sur chaque appareil iPad<sup>MD</sup>.',
      p1: 'Maintenant que vous avez vérifié vos appareils numériques mobiles iPad<sup>MD</sup>, la prochaine étape consiste à enregistrer l’URL du menu de Model e propre à votre concessionnaire comme raccourci sur chaque appareil iPad<sup>MD</sup> pour un accès facile. L’expérience du menu de Model e sera un outil important pour travailler avec vos invités qui magasinent les véhicules électriques (VE). Il comprend des outils de magasinage et d’achat de VE. Le lien vous dirigera vers le contenu et les stocks de votre concessionnaire. Ces éléments comprennent la fonctionnalité Personnaliser et commander ou recherche dans les stocks, la recharge à domicile, la recharge publique, les outils d’achat en ligne pour répondre aux questions des invités et les renseignements sur l’entretien recommandé pour les véhicules électriques. Le menu de Model e est conçu pour que les renseignements sur les VE de Ford soient à la portée de la main des spécialistes de l’achat au détail de votre concessionnaire. Le menu de Model e est destiné aux invités, mais il a été fait pour que les spécialistes de l’achat au détail dirigent la démarche.',
      p2: 'À partir de votre iPad<sup>MD</sup>, cliquez sur « Accéder au menu de Model e », ce qui ouvrira le menu propre à votre concessionnaire dans une nouvelle fenêtre de navigateur. À partir de cette page, trouvez et touchez l’icône de partage dans la barre de menu (elle ressemble à une boîte affichant une flèche tournée vers le haut). Ensuite, à partir de la liste déroulante, choisissez « Ajouter à l’écran d’accueil ». Cliquez sur « Ajouter » dans le coin supérieur droit. Le raccourci devrait désormais figurer sur l’écran d’accueil de votre appareil iPad<sup>MD</sup>. Nous recommandons de cliquer sur l’icône pour tester la fonctionnalité afin de confirmer si le chargement se fait correctement.',
      instructions: 'Pour l’enregistrer à l’écran d’accueil de votre tablette :',
      additionalInfo: 'Pour obtenir des renseignements supplémentaires et accéder à des vidéos d’instruction sur les fonctions ou l’utilisation du menu de Model e, veuillez rechercher « Tablettes » dans le Centre de ressources.',
      label: '[NT] Model e Menu URL',
      buttonText: '[NT] Access Model e Menu',
    },
  },
  standardCompliance: {
    title: 'Conformité aux normes Model e :',
    description: '[NT] Meet the compliance standards that are required for activation',
  },
  protectionPlans: {
    title: '[NT] Protection Plans',
    description: '[NT] Create a customized Dealer-priced F&I menu to present to Customers in the experience',
  },
  customer: {
    title: 'Traitement et activité de la clientèle :',
    description: 'Définir les autorisations et préparer l’équipe à répondre aux soumissions des invités',
    handling: {
      title: 'Traitement de la clientèle',
      description1: 'L’expérience de commerce électronique Model e offre à vos invités une option pour demander un accès avancé et soumettre une demande d’achat ou de location de leur véhicule électrique en ligne, auprès de votre concessionnaire. Les demandes soumises par les invités seront acheminées directement dans la gestion des relations avec la clientèle (GRC) de votre concessionnaire et dans le portail de commerce électronique. La personne spécialiste en VE affectée pendant l’inscription de votre cessionnaire recevra les courriels d’alerte urgente qui l’informeront des nouvelles demandes et la dirigeront vers les pages de traitement de la clientèle dans le portail de commerce électronique pour qu’elle prenne des mesures.',
      description2: 'Les pages de traitement de la clientèle fournissent un accès aux sélections que l’invité a faites au cours de l’expérience en ligne. Sur ces pages, vous serez en mesure d’accepter la soumission, d’accepter les dépôts et les versements initiaux, de passer en revue les demandes de véhicule d’échange et de répondre aux demandes de renseignements relatives aux documents en ligne.',
      entitlementsDescription: 'Il faut des autorisations pour gérer les demandes des invités. Cliquez sur le bouton « Définir les autorisations » pour attribuer les autorisations énumérées ci-dessous. Seuls les utilisateurs des concessionnaires autorisés ayant l’autorité de gérer les soumissions des clients devraient obtenir ces autorisations.',
      entitlementsButton: 'Définir les autorisations',
      listTitle: '[NT] Required entitlements:',
      listItem1: 'EMP_eComm_Customer Handling',
      listItem2: 'EMP_Modele',
      userIds: 'Autorisations pour le traitement de la clientèle – Identifiants d’utilisateur',
      noEntitlements: '[NT] Your dealership does not have entitlements set.',
      button: '[NT] Customer Handling Pages',
    },
    activity: {
      title: 'Activité de la clientèle',
      description: 'Votre concessionnaire aura accès aux rapports d’activité des clients du commerce électronique dans le tableau de bord amélioré du concessionnaire (TBAC). Il existe deux affichages des rapports sur le commerce électronique dans le TBAC. Le mode Concessionnaire comprend deux affichages de commerce électronique : Clients potentiels et ventes du commerce électronique, et Renseignements détaillés sur les clients potentiels du commerce électronique. Le mode Entreprise comprend un affichage de commerce électronique : Clients potentiels et ventes du commerce électronique. On peut produire des rapports sur les deux mesures ou sur l’activité de la clientèle. Vous pouvez accéder à ces affichages à l’aide de vos identifiants de connexion actuels. Si vous n’avez pas accès au TBAC, communiquez avec l’administrateur de votre concessionnaire.',
      button: 'Tableau de bord amélioré du concessionnaire',
    },
  },
  optOut: {
    title: '[NT] Opt-out',
    subtitle: '[NT] Initiate opt-out for the Model e experience',
    description: '[NT] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  errors: {
    TERMS_CONDITIONS_NOT_COMPLETED: 'Accepter les Termes et Conditions.',
    DEALER_INFO_NOT_COMPLETED: 'Ajoutez un téléphone de revendeur et enregistrez avant de continuer.',
    teamSetupNotCompleted: 'Le formulaire Création d’équipe n’est pas rempli.',
    kdmNotSet: 'Au moins un Décisionnaire clé est requis. [NT] More details on KDM\'s can be found in the Terms & Conditions section of enrolment.',
    remoteDeliveryNotSet: 'Option de livraison à distance non définie.',
  },
};
