import BaseAPI from './baseAPI';

export default class CommsCenter extends BaseAPI {
  async getDocuments(division) {
    const { data } = await this.axios.get('/CommCenter/Documents', {
      params: {
        division,
      },
    });

    return data;
  }

  async setSectionAsViewed({ division, subSectionId }) {
    const { data } = await this.axios.post('/CommCenter/SectionViewed', null, {
      params: {
        division,
        subSectionId,
      },
    });

    return data;
  }
}
