<template>
  <div class="header-logo">
    <router-link
      v-if="renderLink"
      :to="startPageLink"
    >
      <AppLogo :theme="activeExperience" />
    </router-link>
    <AppLogo
      v-else
      :theme="activeExperience"
    />
    <span
      v-text="$l('header.appName')"
      class="header-logo__app-name"
      :class="$store.getters['user/activeBrandName']"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLogo from './common/AppLogo.vue';

export default {
  name: 'TheHeaderLogo',
  components: {
    AppLogo,
  },
  props: {
    renderLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      activeFeatures: 'features/activeFeatures',
      activeBrandIsLincoln: 'user/activeBrandIsLincoln',
      activeExperience: 'user/activeExperience',
      activeExperienceIsModelE: 'user/activeExperienceIsModelE',
    }),
    startPageLink() {
      if (this.activeFeatures.modelE && this.activeExperienceIsModelE) {
        return { name: 'ModelEStart' };
      }

      return { name: 'Start' };
    },
  },
};
</script>

<style lang="scss" scoped>
.header-logo {
  display: flex;
  align-items: center;
  &__app-name {
    margin-left: 15px;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: 1px;
    @include respond(md-up) {
      font-size: 18px;
      color: $color--fds-primary;
      font-weight: $font-weight--light;
      &.lincoln {
        color: $color--lds-primary;
        font-weight: $font-weight--normal;
      }
    }
    @include respond(lg-up) {
      font-size: 24px;
    }
  }
}
</style>
