export default {
  deactivate: {
    date: 'Votre concessionnaire sera désactivé le:',
    cancel: 'Cliquez sur « Annuler » pour garder cette expérience active ou cliquez sur « Accepter » pour continuer.',
    warning: '[NT] Your dealership will be deactivated on:<br>{date}<br>[NT] Click on ‘Active’ to remain in the experience',
    errorMessage: '[NT] Failed to initiate the deactivation process. Please try again.',
  },
  optOutPermission: {
    title: 'Désinscription de l’expérience',
    subtitle: 'Lancer la désinscription d’une expérience de commerce électronique',
    description: 'Vous n’avez pas l’autorisation de vous désinscrire des expériences de commerce électronique. Les personnes suivantes de votre concessionnaire ont l’autorisation de procéder à la désinscription:',
  },
  cancelOptOut: {
    errorMessage: '[NT] Failed to cancel the opt-out process. Please try again.',
  },
  cancelOptOutPermission: {
    title: 'Annuler la désinscription',
    subtitle: 'Annuler la désinscription d’une expérience de commerce électronique',
    description: 'Vous n’avez pas l’autorisation d’annuler la désinscription d’une expérience de commerce électronique. Les personnes suivantes de votre concessionnaire ont l’autorisation nécessaire:',
  },
  reactivate: {
    errorMessage: '[NT] Failed to initiate the re-activation process. Please try again.',
  },
  reactivatePermission: {
    title: 'Réactiver l’expérience',
    subtitle: 'Réactiver une expérience de commerce électronique',
    description: 'Vous n’avez pas l’autorisation de réactiver une expérience de commerce électronique. Les personnes suivantes de votre concessionnaire ont l’autorisation nécessaire:',
  },
  reservation: {
    title: 'Réservation',
    item1: 'Inscription',
    item2: 'Compte Stripe',
    optOut: {
      title: 'Réservation et commande',
      subtitle: 'Lancer la désinscription des expériences de réservation et de commande',
      description: 'Vous avez lancé le processus de désactivation de l’expérience de réservation et, par conséquent, lancé en même temps le processus de désactivation de l’expérience de commande.',
    },
    cancelOptOut: {
      title: 'Réservation et commande',
      subtitle: 'Annuler la désinscription de l’expérience de Réservation et commande',
      description: 'Vous avez réussi l’annulation du processus de désinscription des expériences de réservation et de commande du programme de commerce électronique pour votre concessionnaire.',
    },
    reactivate: {
      title: 'Réservation',
      subtitle: 'Réactiver l’expérience de réservation',
      description: 'Vous avez réussi la réactivation de l’expérience de réservation du programme de commerce électronique pour votre concessionnaire.',
    },
  },
  order: {
    title: 'Commande',
    item1: 'Inscription',
    item2: 'Compte Stripe',
    optOut: {
      title: 'Commande',
      subtitle: 'Lancer la désinscription de l’expérience de commande',
      description: 'Vous avez lancé le processus de désactivation de l’expérience de commande.',
    },
    cancelOptOut: {
      title: 'Commande',
      subtitle: 'Annuler la désinscription de l’expérience de commande',
      description: 'Vous avez réussi l’annulation du processus de désinscription de l’expérience de commande du programme de commerce électronique pour votre concessionnaire.',
    },
    reactivate: {
      title: 'Commande',
      subtitle: 'Réactiver l’expérience de commande',
      description: 'Vous avez lancé le processus de réactivation de l’expérience de commande du programme de commerce électronique pour votre concessionnaire. Veuillez passer en revue toutes les exigences relatives à l’activation pour le terminer; la réactivation pourrait prendre jusqu’à 24 heures',
    },
    reactivateReservation: {
      title: 'Commande',
      subtitle: 'Réactiver l’expérience de commande',
      description: 'L’activation de la réservation est requise pour réactiver l’expérience de commande. Veuillez réactiver la réservation en cliquant sur « Active », puis réactiver l’expérience de commande.',
    },
  },
  cartCheckout: {
    title: 'Panier et Paiement',
    item1: 'Inscription',
    item2: 'Compte Stripe',
    item3: 'Prix des véhicules',
    item4: 'Prix des accessoires',
    item5: 'Véhicule d’échange',
    item6: 'F et A',
    item6ComingSoon: 'F et A <i style=\'font-size: 13px;\'>(à venir bientôt)</i>',
    item7: 'Gestion des clients <i style=\'font-size: 13px;\'>(à venir bientôt)</i>',
    optOut: {
      title: 'Panier d’achats et paiement',
      subtitle: 'Lancer la désinscription des expériences de panier d’achats et de paiement',
      description: 'Vous avez lancé le processus de désactivation de l’expérience de panier d’achats et de paiement.',
    },
    cancelOptOut: {
      title: 'Panier d’achats et paiement',
      subtitle: 'Annuler la désinscription de l’expérience de panier d’achats et de paiement',
      description: 'Vous avez réussi l’annulation du processus de désinscription de l’expérience de panier d’achats et de paiement du programme de commerce électronique pour votre concessionnaire.',
    },
    reactivate: {
      title: 'Panier d’achats et paiement',
      subtitle: 'Réactiver des expériences de panier d’achats et de paiement',
      description: 'Vous avez lancé le processus de réactivation de l’expérience de panier d’achats et de paiement du programme de commerce électronique pour votre concessionnaire. Veuillez passer en revue toutes les exigences relatives à l’activation pour le terminer; la réactivation pourrait prendre jusqu’à 24 heures.',
    },
  },
};
