export default class ResourceCenter {
  static findSectionResources(resources, sectionId, subsectionId) {
    const pageIsAssignedToSection = (page) => (
      page.sectionID === sectionId && page.subsectionID === subsectionId
    );

    return resources
      .filter(({ assignedPages }) => assignedPages.find(pageIsAssignedToSection))
      .sort((a, b) => {
        const orderA = a.assignedPages.find(pageIsAssignedToSection).placement;
        const orderB = b.assignedPages.find(pageIsAssignedToSection).placement;

        return orderA - orderB;
      });
  }

  static getActiveVideosAndDocuments({ resources, brand, locale }) {
    const activeResources = resources
      .filter((item) => (item.division.includes(brand) && item.language === locale));

    const videos = [];
    const documents = [];

    activeResources.forEach((resource) => {
      // Videos are resources with a video url
      if (resource.video) {
        videos.push(resource);
      } else {
        documents.push(resource);
      }
    });

    return { videos, documents };
  }
}
