<template>
  <div class="model-e-layout">
    <div class="model-e-layout__content | container-large">
      <TheHeader />
      <TheMobileAppControls />
      <main
        id="main"
        class="model-e-layout__main | container--page"
      >
        <slot />
      </main>
      <DocumentModal />
      <BrowserOutdatedModal />
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheMobileAppControls from '@/components/TheMobileAppControls.vue';
import BrowserOutdatedModal from '@/components/BrowserOutdatedModal.vue';
import DocumentModal from '@/components/DocumentModal.vue';

export default {
  name: 'ModelELayout',
  components: {
    TheHeader,
    TheFooter,
    TheMobileAppControls,
    BrowserOutdatedModal,
    DocumentModal,
  },
};
</script>

<style lang="scss" scoped>
.model-e-layout {
  background-color: $color--fds-gray1;
  &__content {
    padding-bottom: 60px;
  }
  &__main {
    @include respond(lg-up) {
      padding-top: 40px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
</style>
