export default {
  meeting: {
    sectionOne: {
      title: 'Date de la réunion et renseignements sur le directeur',
      labelOne: 'Date de la réunion',
      labelTwo: 'Ton rôle',
      labelThree: 'Votre identifiant CDS',
    },
    sectionTwo: {
      title: 'Confirmer l\'achèvement du plan de préparation',
      p1: 'L\'activité ci-dessous peut ne pas avoir lieu dans la même réunion. Veuillez vous assurer de revenir à ce <b>rapport de visite de préparation au commerce électronique</b> pour effectuer des mises à jour avant de le marquer comme final.',
      subtitle1: 'Rencontre du directeur',
      p2: 'La réunion des directeurs de commerce électronique est terminée',
      p3: 'Confirmer la présence du chef de service',
      subtitle2: 'Qui as-tu rencontré?',
      small1: '(doit sélectionner au moins un)',
      label1: 'Nom',
      subtitle3: 'Y a-t-il quelqu\'un d\'autre présenté?',
      small2: '(non répertorié dans la liste déroulante Nom ci-dessus)',
      label2: 'Prénom',
      label3: 'Nom de famille',
      label4: 'Liste de présence (Sélectionnez pour modifier ou supprimer)',
      btn1: 'Enregistrer le contact',
      btn2: 'Supprimer le contact',
    },
    sectionThree: {
      title: 'Réunions de l\'équipe du département',
      p1: 'Les réunions d\'équipe du département de commerce électronique sont terminées',
      p2: 'Confirmez que chaque service a organisé sa réunion d\'équipe',
    },
    sectionFour: {
      title: 'Mise en uvre de la stratégie',
      p1: 'Des stratégies de réponse CRM commerce électronique avec les équipes de réponse client/BDC ont été mises en œuvre',
    },
    sectionFive: {
      title: 'Suivi des demandes',
      p1: 'Si une assistance de suivi est nécessaire, veuillez sélectionner un groupe demandé:',
    },
    sectionSix: {
      title: 'Remarques',
      label1: 'J\'ai examiné le plan de préparation au commerce électronique avec',
      label2: '**Si vous marquez cette visite comme finale, vous ne pourrez pas apporter de modifications',
      btn1: 'Enregister',
      btn2: 'Marquer comme final',
    },
    sectionSeven: {
      title: 'Réunions précédentes',
      label1: 'Terminé par:',
      label2: 'Rôle:',
      label3: 'Date de réunion:',
      label4: 'Rencontré:',
      label5: 'Demande de suivi:',
      label6: 'Remarques:',
    },
    messages: {
      selectOneAttendee: '[NT] Must select at least one attendee',
      hadReviewedPlan: '[NT] Please select that you had reviewed the plan',
      dateMeetRequired: '[NT] Date of Meeting is required',
      selectRole: '[NT] Must select a role',
      cdsRequired: '[NT] CDS ID is required',
      cdsNotMatch: '[NT] CDS ID doesn\'t match logged in user [ABC]',
      checkReviewedPlan: '[NT] You must checked that you reviewed the plan',
      formSaveSuccess: '[NT] Form saved successfully.',
      completeEntireForm: '[NT] Please complete the entire form.',
      formMarkedFinal: '[NT] The form has been marked final',
    },
  },
  feedback: {
    titleOne: '[NT] Readiness Plan Check',
    checkboxOne: 'La réunion des directeurs de commerce électronique est terminée',
    radioOne: '[NT] <b>With</b> Ford Field Representative',
    radioTwo: '[NT] <b>Without</b> Ford Field Representative',
    checkboxTwo: {
      pieceOne: 'La réunion de l\'équipe du département de commerce électronique est terminée',
      pieceTwo: '(par le concessionnaire pour les équipes du concessionnaire)',
    },
    wslxID: 'Votre identifiant WSLX (connexion FMCDealer)',
    checkboxThree: 'Je certifie que [DEALER_NAME] a terminé le plan de préparation au commerce électronique et a mis en œuvre des stratégies de réponse CRM (avec les équipes de réponse client/BDC)',
    titleTwo: '[NT] Readiness Plan Feedback',
    labelOne: '[NT] Do you feel the provided resources have adequately prepared your dealership to support eCommerce Customers?',
    labelTwo: '[NT] In which areas of eCommerce would you like to see more support?',
    buttonRequestSupport: '[NT] Request Support',
    formSaveSuccess: '[NT] Form saved successfully.',
  },
};
