export default {
  title: 'QFPs',
  searchLabel: 'Taper votre question ici',
  card: {
    helpful: 'Utile?',
    feedback: {
      successMessage: 'Nous vous remercions de nous avoir transmis vos commentaires!',
    },
  },
};
