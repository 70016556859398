export default {
  title: 'In-Dealership Team Setup',
  form: {
    position: 'Role / Title',
    phone: 'Direct Phone or Cell',
    extension: 'Extension',
    addButton: 'Add A Contact',
    saveButton: 'Save Contact',
    deleteButton: 'Delete Contact',
    reservations: {
      name: 'Reservations:',
      label: 'by checking this box this individual will receive alerts any time a customer places a reservation with your dealership.',
      tooltip: '<div>Individuals will require WBDO Submit and EMP_eComm_CustomerHandling permissions to view or accept Reservations and Orders in Customer Handling Pages. WBDO and EMP Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click <button type="button" class="btn-link" @click="emitBack" style="padding:0px;">HERE</button>.</div>',
    },
    orders: {
      name: 'Orders:',
      label: 'by checking this box this individual will receive alerts any time a customer places an order request with your dealership. Order requests will need to be approved and entered into WBDO by an individual with appropriate permissions.',
      tooltip: '<div>Individuals will require WBDO Submit permissions to enter Orders into WBDO Customer Handling Pages. Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click <button type="button" class="btn-link" @click="emitBack" style="padding:0px;">HERE</button>.</div>',
    },
    vehicles: {
      name: 'New Vehicle Purchase Requests:',
      label: 'by checking this box this individual will receive alerts any time a customer places a new vehicle purchase request with your dealership.',
      tooltip: '<div>Individuals will require WBDO Submit permissions to enter Orders into WBDO Customer Handling Pages. Permissions can be set at www.dsps.dealerconnection.com. To see a listing of individuals in your dealership that have WBDO and EMP permissions set please click <button type="button" class="btn-link" @click="emitBack" style="padding:0px;">HERE</button>.</div>',
    },
  },
};
