<template>
  <div class="trade-in">
    <p>{{ $l('modelEDashboard.tradeIn.p1') }}</p>
    <div class="trade-in__form">
      <AppToggle
        v-model="doOptIn"
        id="trade-in-toggle"
        :active-text="$l('general.yes')"
        :inactive-text="$l('general.no')"
      />
      <div>
        <p>{{ $l('modelEDashboard.tradeIn.label1') }}</p>
        <p>{{ $l('modelEDashboard.tradeIn.label2') }}</p>
        <p>{{ $l('modelEDashboard.tradeIn.label3') }}</p>
      </div>
    </div>
    <StandardButton
      :disabled="isDisabled"
      size="sm"
      track-event-name="submitForm_tradeInPreference"
      track-section-name="tradeIn"
      :text="$l('general.save')"
      :is-loading="isSaving"
      @click="submit"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiManager from '@/api';
import AppToggle from './common/AppToggle.vue';
import StandardButton from './common/StandardButton.vue';

export default {
  name: 'ModelEDashboardArsTradeIn',
  components: {
    AppToggle,
    StandardButton,
  },
  emits: ['trade-in-complete'],
  data() {
    return {
      doOptIn: false,
      isSaving: false,
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      activeDealership: 'user/activeDealership',
      tradeInOptIn: 'modelE/dashboard/tradeIn/optIn',
      isEnrolledInARS: 'modelE/dashboard/tradeIn/isEnrolledInARS',
      hideSection: 'modelE/dashboard/tradeIn/hideSection',
    }),
  },
  watch: {
    tradeInOptIn: {
      immediate: true,
      async handler() {
        this.doOptIn = this.tradeInOptIn;
      },
    },
    hideSection: {
      immediate: true,
      async handler() {
        this.isDisabled = this.hideSection;
      },
    },
  },
  methods: {
    async submit() {
      if (this.doOptIn && !this.isEnrolledInARS) {
        this.$store.dispatch('topBanner/open', {
          type: 'warning',
          message: this.$l('modelEDashboard.tradeIn.notEnrolledInArs'),
        });
        this.doOptIn = false;

        return;
      }

      this.isSaving = true;

      try {
        await apiManager.tradeIn.saveModelETradeInPreference({
          dealerId: this.activeDealership.dealerId,
          optIn: this.doOptIn,
        });

        await Promise.allSettled([
          this.$store.dispatch('modelE/activationSummary/load'),
          this.$store.dispatch('modelE/dashboard/tradeIn/load'),
        ]);

        if(!this.isEnrolledInARS) {
          this.$emit('trade-in-complete');
          this.isDisabled = true;
        }

        this.$store.dispatch('topBanner/open', {
          type: 'success',
          message: this.$l('modelEDashboard.tradeIn.savedSuccessfully'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('modelEDashboard.tradeIn.failedToSave'),
        });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trade-in {
  &__form {
    display: grid;
    gap: 24px;
    place-items: flex-start;
    margin: 32px 0;
    @include respond(sm-up) {
      grid-template-columns: auto 1fr;
    }
  }
}
</style>
