<template>
  <div class="impersonate">
    <h1 class="impersonate__title">
      {{ $l('impersonate.title') }}
    </h1>
    <div class="impersonate__select-wrapper">
      <AppDropdown
        v-model="selectedDealerId"
        id="impersonate-dealer"
        searchable
        :label="$l('impersonate.label.dealer')"
        :options="dealerList"
      />
      <AppDropdown
        v-model="stripeStatus"
        id="impersonate-stripe-status"
        aria-label="Show stripe status options"
        :label="$l('impersonate.label.stripeStatus')"
        :options="stripeStatusList"
      />
    </div>
    <LoadingIndicator
      v-if="isLoading"
      class="impersonate__loader"
    />
    <div class="impersonate__controls">
      <StandardButton
        :text="$l('impersonate.button.login')"
        :disabled="!selectedDealerId"
        @click="openLoginConfirmation"
      />
      <StandardButton
        :text="$l('impersonate.button.reset')"
        :disabled="!selectedDealerId"
        @click="openResetProgressConfirmation"
      />
      <StandardButton
        :text="$l('impersonate.button.resetModelE')"
        :disabled="!selectedDealerId"
        @click="openResetProgressConfirmationModelE"
      />
      <StandardButton
        :text="$l('impersonate.button.clearTerms')"
        :disabled="!selectedDealerId"
        @click="openClearTermsConfirmation"
      />
      <StandardButton
        :text="$l('impersonate.button.clearTermsModelE')"
        :disabled="!selectedDealerId"
        @click="openClearTermsConfirmationModelE"
      />
      <StandardButton
        :text="$l('impersonate.button.setStripeStatus')"
        :disabled="!selectedDealerId"
        @click="setStripeStatus"
      />
      <StandardButton
        :text="$l('impersonate.button.activateCartCheckout')"
        :disabled="!selectedDealerId"
        @click="activateCartCheckout"
      />
      <StandardButton
        :text="$l('impersonate.button.track')"
        :to="{ name : 'Track' }"
      />
    </div>
    <div class="panel__content">
      <div class="panel__feature">
        <AppDropdown
          v-model="feature"
          id="impersonate-feature"
          searchable
          :label="$l('impersonate.label.feature')"
          :options="featureList"
        />
        <AppDropdown
          v-model="featureStatus"
          id="impersonate-feature-status"
          :label="$l('impersonate.label.featureStatus')"
          :options="featureStatusList"
        />
        <StandardButton
          :text="$l('impersonate.button.setFeatureStatus')"
          :disabled="!selectedDealerId"
          @click="setFeatureStatus"
        />
      </div>
    </div>
    <div class="steps__content">
      <div class="steps__blue">
        <AppDropdown
          v-model="step"
          id="steps"
          class="panel__wide-dropdown"
          searchable
          :label="$l('impersonate.label.steps')"
          :options="stepList"
        />
        <StandardButton
          :text="$l('impersonate.button.blueStep')"
          :disabled="!selectedDealerId"
          @click="setStep"
        />
      </div>
    </div>
    <div class="steps__content">
      <div class="steps__modele">
        <AppDropdown
          v-model="modelEStep"
          id="modelESteps"
          searchable
          :label="$l('impersonate.label.steps')"
          :options="modelEStepList"
          class="wide-dropdown"
        />
        <StandardButton
          :text="$l('impersonate.button.modelEStep')"
          :disabled="!selectedDealerId"
          @click="setModelEStep"
        />
      </div>
    </div>
    <ModalConfirmation
      :is-active="confirmationModal.show"
      :text="confirmationModal.text"
      :text-cancel="$l('general.cancel')"
      :text-continue="$l('general.continue')"
      @response="handleModalConfirmation"
    />
  </div>
</template>

<script>
import apiManager from '@/api';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import StandardButton from '@/components/common/StandardButton.vue';
import AppDropdown from '@/components/common/AppDropdown.vue';
import ModalConfirmation from '@/components/ModalConfirmation.vue';

export default {
  name: 'ImpersonateView',
  components: {
    LoadingIndicator,
    StandardButton,
    ModalConfirmation,
    AppDropdown,
  },
  data() {
    return {
      dealerList: [],
      featureList: [],
      stepList: [],
      modelEStepList: [],
      stripeStatusList: [
        { text: 'Not started', value: 'notstarted' },
        { text: 'In Progress', value: 'inprogress' },
        { text: 'Pending', value: 'pending' },
        { text: 'On Hold', value: 'onhold' },
        { text: 'Active', value: 'active' },
        { text: 'Unknown', value: 'unknown' },
      ],
      featureStatusList: [
        { text: 'Active', value: true },
        { text: 'InActive', value: false },
      ],
      isLoading: true,
      selectedDealerId: this.$store.getters['user/activeDealership'].dealerId,
      stripeStatus: '',
      feature:'',
      featureStatus:'',
      step:'',
      modelEStep: '',
      confirmationModal: {
        show: false,
        text: '',
        callback: null,
      },
    };
  },
  watch: {
    selectedDealerId() {
      this.stripeStatus = '';
    },
  },
  async mounted() {
    await this.getDealerList();
    await this.getFeatureList();
    await this.getStepList();
    await this.getModelEStepList();

    this.stripeStatus = this.$store.getters['stripe/account'].applicationStatus;
  },
  methods: {
    toggleConfirmationModal() {
      this.confirmationModal.show = !this.confirmationModal.show;
    },
    handleModalConfirmation(hasConfirmed) {
      this.toggleConfirmationModal();

      if (hasConfirmed === 'yes' && this.confirmationModal.callback) {
        this.confirmationModal.callback();
      }

      this.confirmationModal.callback = null;
      this.confirmationModal.text = '';
    },
    openLoginConfirmation() {
      this.confirmationModal.text = this.$l('impersonate.modal.login.text');
      this.confirmationModal.callback = this.impersonateLogin;

      this.toggleConfirmationModal();
    },
    openResetProgressConfirmation() {
      this.confirmationModal.text = this.$l('impersonate.modal.reset.text');
      this.confirmationModal.callback = this.resetDealerProgress;

      this.toggleConfirmationModal();
    },
    openResetProgressConfirmationModelE() {
      this.confirmationModal.text = this.$l('impersonate.modal.reset.text');
      this.confirmationModal.callback = this.resetDealerProgressModelE;

      this.toggleConfirmationModal();
    },
    openClearTermsConfirmation() {
      this.confirmationModal.text = this.$l('impersonate.modal.clearTerms.text');
      this.confirmationModal.callback = this.resetTermsAndConditions;

      this.toggleConfirmationModal();
    },
    openClearTermsConfirmationModelE() {
      this.confirmationModal.text = this.$l('impersonate.modal.clearTerms.text');
      this.confirmationModal.callback = this.resetTermsAndConditionsModelE;

      this.toggleConfirmationModal();
    },
    async getDealerList() {
      this.isLoading = true;

      try {
        const { success, data: { dealers } } = await apiManager.impersonate.getDealerList();

        if (!success) {
          throw new Error(this.$l('impersonate.error.loadDealerList'));
        }

        this.dealerList = dealers.map(({ dealerName, dealerID }) => ({
          text: dealerName,
          value: dealerID,
        }));
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setStripeStatus() {
      this.isLoading = true;

      try {
        const { success } = await apiManager.impersonate.setStripeStatus({
          dealerID: this.selectedDealerId,
          status: this.stripeStatus,
        });

        if (!success) {
          throw new Error(this.$l('impersonate.error.stripeStatus'));
        }

        await this.$store.dispatch('user/loadUser');
        await this.$store.dispatch('stripe/load');

        this.$store.dispatch('topBanner/open', {
          message: this.$l('impersonate.success.stripeStatus'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async resetDealerProgress() {
      this.isLoading = true;

      try {
        const {
          success, message,
        } = await apiManager.impersonate.resetProgress(this.selectedDealerId);

        if (!success) {
          throw new Error(`impersonate.error.${message}`);
        }

        this.$store.dispatch('topBanner/open', {
          message: this.$l('impersonate.success.resetDealerProgress'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async resetDealerProgressModelE() {
      this.isLoading = true;

      try {
        const {
          success, message,
        } = await apiManager.impersonate.resetProgressModelE(this.selectedDealerId);

        if (!success) {
          throw new Error(`impersonate.error.${message}`);
        }

        this.$store.dispatch('topBanner/open', {
          message: this.$l('impersonate.success.resetDealerProgress'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async impersonateLogin() {
      this.isLoading = true;

      try {
        const { success, message } = await apiManager.impersonate.login(this.selectedDealerId);

        if (!success) {
          throw new Error(`impersonate.error.${message}`);
        }

        this.$store.dispatch('topBanner/open', {
          message: this.$l('impersonate.success.impersonateLogin'),
        });

        setTimeout(() => {
          this.$router.push({ name: 'Brand' });
        }, 1000);
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async resetTermsAndConditions() {
      this.isLoading = true;

      try {
        const { success,detailMessage } = await apiManager.impersonate.clearTermsAndConditions(this.selectedDealerId);

        if (!success) {
          if (detailMessage === 'No TermsAndConditions found to reset') {
            throw new Error(this.$l('Please accept the Terms & Conditions before proceeding with the reset.'));
          } else {
            throw new Error(this.$l('impersonate.error.termsAndConditions'));
          }
        }

        await this.$store.dispatch('termsAndConditions/load');

        this.$store.dispatch('topBanner/open', {
          message: this.$l('impersonate.success.termsAndConditions'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async resetTermsAndConditionsModelE() {
      this.isLoading = true;

      try {
        const { success,detailMessage } = await apiManager.impersonate.clearTermsAndConditionsModelE(this.selectedDealerId);

        if (!success) {
          if (detailMessage === 'No TermsAndConditions found to reset') {
            throw new Error(this.$l('Please accept the Terms & Conditions before proceeding with the reset.'));
          } else {
            throw new Error(this.$l('impersonate.error.termsAndConditions'));
          }
        }

        await this.$store.dispatch('modelE/termsAndConditions/load');

        this.$store.dispatch('topBanner/open', {
          message: this.$l('impersonate.success.termsAndConditions'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async activateCartCheckout() {
      this.isLoading = true;

      try {
        await apiManager.impersonate.activateDealerCartCheckout(this.selectedDealerId);
        await this.$store.dispatch('activationSummary/load');

        this.$store.dispatch('topBanner/open', {
          message: this.$l('impersonate.success.cartCheckout'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('impersonate.error.cartCheckout'),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getFeatureList() {
      this.isLoading = true;

      try {
        const { success, data: features } = await apiManager.impersonate.getFeatureList();

        if (!success) {
          throw new Error(this.$l('impersonate.error.featureList'));
        }

        this.featureList = features
        .filter((feature) => feature.name !== 'BLANK')
        .map((feature) => ({
          text: feature.name,
          value: feature.id,
        }));
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setFeatureStatus() {
      // Field validations
      if (!this.feature || (this.featureStatus === '')) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: 'Please select the feature, status before proceeding.',
        });
        return;
      }

      this.isLoading = true;

      try {
        const response = await apiManager.impersonate.setFeatureStatus(
          this.selectedDealerId,
          this.feature,
          this.featureStatus,
        );

        if (!response.success) {
          throw new Error(response.message);
        }

        this.$store.dispatch('features/getFeatures');

        this.$store.dispatch('topBanner/open', {
          type: 'success',
          message: this.$l('impersonate.success.featureStatus'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getStepList() {
      this.isLoading = true;

      try {
        const { success, data } = await apiManager.impersonate.getStepList();

        if (!success) {
          throw new Error(this.$l('impersonate.error.loadStepList'));
        }

        this.stepList = data.map((step) => ({
          text: step,
          value: step,
        }));
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setStep() {
      this.isLoading = true;

      try {
        const response = await apiManager.impersonate.setStep(
          this.selectedDealerId,
          this.step,
        );

        if (!response.success) {
          throw new Error(response.message);
        }

        this.$store.dispatch('topBanner/open', {
          type: 'success',
          message: this.$l('impersonate.success.stepStatus'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getModelEStepList() {
      this.isLoading = true;

      try {
        const { success, data } = await apiManager.impersonate.getModelEStepList();

        if (!success) {
          throw new Error(this.$l('impersonate.error.loadStepList'));
        }

        this.modelEStepList = data.map((step) => ({
          text: step,
          value: step,
        }));
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setModelEStep() {
      this.isLoading = true;

      try {
        const response = await apiManager.impersonate.setModelEStep(
          this.selectedDealerId,
          this.modelEStep,
        );

        if (!response.success) {
          throw new Error(response.message);
        }

        this.$store.dispatch('topBanner/open', {
          type: 'success',
          message: this.$l('impersonate.success.stepStatus'),
        });
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: error.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.impersonate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20%;
  &__title {
    margin-bottom: 32px;
    text-align: center;
    font-size: 24px;
    font-weight: $font-weight--normal;
  }
  &__select-wrapper {
    display: grid;
    grid-template-columns: 556px 300px;
    gap: 30px;
    margin-bottom: 30px;
    @include respond(md-dn) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  &__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 40px;
    max-width: 956px;
    @include respond(sm-dn) {
      flex-direction: column;
      align-items: center;
    }
  }
}
.panel {
  &__content {
    background-color: $color--fds-gray1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
  }

  &__feature {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @media (max-width: $sm-breakpoint) {
      flex-direction: column;
    }
  }
}

.steps {
  &__content {
    background-color: $color--fds-gray1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
  }

  &__blue {
    display: grid;
    grid-template-columns: 429px 274px;
    gap: 55px;

    @media (max-width: $sm-breakpoint) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__modele {
    display: grid;
    grid-template-columns: 429px 300px;
    gap: 30px;

    @media (max-width: $sm-breakpoint) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>
