import { mapGetters } from 'vuex';
import { topBannerTypes } from '@/constants/topBanner';

export default {
  data() {
    return {
      keyDecisionMakerAlertId: null,
    };
  },
  computed: {
    ...mapGetters({
      userIsKeyDecisionMaker: 'user/userIsKeyDecisionMaker',
      activeKeyDecisionMakerNames: 'modelE/dealerPersonnel/activeKeyDecisionMakerNames',
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    activeLocale() {
      this.toggleKeyDecisionMakerAlert();
    },
    activeKeyDecisionMakerNames() {
      this.toggleKeyDecisionMakerAlert();
    },
  },
  mounted() {
    this.toggleKeyDecisionMakerAlert();
  },
  beforeUnmount() {
    this.closeKeyDecisionMakerAlert();
  },
  methods: {
    closeKeyDecisionMakerAlert() {
      if (this.keyDecisionMakerAlertId) {
        this.$store.dispatch('topBanner/close', this.keyDecisionMakerAlertId);
        this.keyDecisionMakerAlertId = null;
      }
    },
    async openKeyDecisionMakerAlert() {
      this.keyDecisionMakerAlertId = await this.$store.dispatch('topBanner/open', {
        message: this.$l('nonKeyDecisionMakerDisclaimer.title'),
        type: topBannerTypes.WARNING,
        persistent: true,
        details: `
          <p style="margin-bottom: 16px;">${this.$l('nonKeyDecisionMakerDisclaimer.message2')}</p>
          <p>${this.activeKeyDecisionMakerNames.join(', ')}</p>
        `,
      });
    },
    toggleKeyDecisionMakerAlert() {
      this.closeKeyDecisionMakerAlert();

      if (!this.userIsKeyDecisionMaker) {
        this.openKeyDecisionMakerAlert();
      }
    },
  },
};
