<template>
  <div class="container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="activation-tools-document"
      :render-divider="false"
      :resources="activeResources"
    />
  </div>
</template>

<script>
import ResourceCenter from '@/helpers/resourceCenter';
import resourceCenterId from '@/data/resourceCenter';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ReportsActivationTools',
  components: {
    DashboardResourcesDisplay,
  },
  computed: {
    resources() {
      return ResourceCenter.findSectionResources(
        this.$store.getters['reports/resources'],
        resourceCenterId.section.ACTIVATION_TOOLS,
        resourceCenterId.subsection.ACTIVATION_TOOLS,
      );
    },
    activeResources() {
      return ResourceCenter.getActiveVideosAndDocuments({
        resources: this.resources,
        brand: this.$store.getters['user/activeBrand'],
        locale: this.$store.getters['locale/activeLocale'],
      });
    },
  },
};
</script>
