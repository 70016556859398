<template>
  <div class="tablets | container--dashboard-accordion">
    <DashboardResourcesDisplay
      id="tablets-document"
      :resources="resources"
    />
    <p v-html="$l('modelEDashboard.tablets.description1')" />
    <p v-html="$l('modelEDashboard.tablets.description2')" />
    <p v-html="$l('modelEDashboard.tablets.description3')" />
    <p class="tablets__list-title indent15">
      {{ $l('modelEDashboard.tablets.specifications.title') }}
    </p>
    <ul class="app-model-e-list indent15">
      <li>
        {{ $l('modelEDashboard.tablets.specifications.item1') }}
        <ul>
          <li v-html="$l('modelEDashboard.tablets.specifications.text1')" />
          <li v-html="$l('modelEDashboard.tablets.specifications.text2')" />
        </ul>
      </li>
      <li>{{ $l('modelEDashboard.tablets.specifications.item2') }}</li>
      <li>{{ $l('modelEDashboard.tablets.specifications.item3') }}</li>
    </ul>
    <DashboardDivider />
    <form
      class="tablets__form"
      @submit.prevent="submitForm"
    >
      <FileInput
        v-model="fileName"
        id="tablets-form--file-input"
        class="tablets__form-file-input"
        @select-file="file = $event"
      />
      <div class="tablets__form-footer">
        <StandardButton
          type="submit"
          track-event-name="click_uploadImage"
          track-section-name="tablets"
          :text="$l('modelEDashboard.tablets.upload.button')"
          :disabled="!fileName"
          :is-loading="isUploadingFile"
        />
        <StandardButton
          v-if="showManualReviewButton"
          class="tablets__form-button"
          :text="$l('modelEDashboard.tablets.reviewButton')"
          :to="{ name: 'ModelEHelp', query: { topic: 21, feedback: 7 } }"
        />
      </div>
    </form>
    <p
      v-html="$l('modelEDashboard.tablets.specifications.disclaimer1')"
      class="tablets__disclaimer"
    />
    <p class="tablets__disclaimer">
      {{ $l('modelEDashboard.tablets.specifications.disclaimer2') }}
    </p>
    <p class="tablets__disclaimer">
      {{ $l('modelEDashboard.tablets.specifications.disclaimer3') }}
    </p>
    <p
      v-html="$l('modelEDashboard.tablets.specifications.disclaimer4')"
      class="tablets__disclaimer"
    />
    <LoadingIndicator
      v-if="isLoadingTabletList"
      class="tablets__loading-indicator"
    />
    <table
      v-else
      class="tablets__table | app-model-e-table"
    >
      <thead>
        <th>{{ $l('modelEDashboard.tablets.table.model') }}</th>
        <th>{{ $l('modelEDashboard.tablets.table.modelNumber') }}</th>
        <th>{{ $l('modelEDashboard.tablets.table.serialNumber') }}</th>
        <th>{{ $l('modelEDashboard.tablets.table.version') }}</th>
        <th>{{ $l('modelEDashboard.tablets.table.verifiedAt') }}</th>
      </thead>
      <tbody>
        <tr
          v-for="tablet in tabletList"
          :key="tablet.id"
        >
          <td>{{ tablet.modelName }}</td>
          <td>{{ tablet.modelNumber }}</td>
          <td>{{ tablet.serialNumber }}</td>
          <td>{{ tablet.softwareVersion }}</td>
          <td>{{ format(new Date(tablet.createdOn), 'MM/dd/yyyy') }}</td>
        </tr>
      </tbody>
    </table>
    <StandardButton
      class="tablets__refresh-button"
      track-event-name="click_refreshTable"
      track-section-name="tablets"
      :text="$l('modelEDashboard.tablets.table.button')"
      :disabled="isLoadingTabletList"
      @click="getTabletList"
    />
    <p
      v-if="showManualReviewButton"
      v-text="$l('modelEDashboard.tablets.specifications.disclaimer3')"
      class="tablets__disclaimer tablets__review-disclaimer"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import apiManager from '@/api';
import StandardButton from './common/StandardButton.vue';
import FileInput from './common/FileInput.vue';
import LoadingIndicator from './LoadingIndicator.vue';
import DashboardDivider from './DashboardDivider.vue';
import DashboardResourcesDisplay from './DashboardResourcesDisplay.vue';

export default {
  name: 'ModelEDashboardTablets',
  components: {
    StandardButton,
    FileInput,
    LoadingIndicator,
    DashboardDivider,
    DashboardResourcesDisplay,
  },
  data() {
    return {
      isLoadingTabletList: true,
      isUploadingFile: false,
      tabletList: [],
      fileName: '',
      file: null,
      errorCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      activeBrand: 'user/activeBrand',
      dashboardResources: 'modelE/dashboard/resources',
      activeDealership: 'user/activeDealership',
      resources: 'modelE/dashboard/tablets/modelETabletsActiveResources',
    }),
    showManualReviewButton() {
      return this.errorCount >= 3;
    },
  },
  mounted() {
    this.getTabletList();
  },
  methods: {
    format,
    async getTabletList() {
      this.isLoadingTabletList = true;

      try {
        const tabletList = await apiManager.tablets.getDealerTablets({
          dealerId: this.activeDealership.dealerId,
        });

        this.tabletList = tabletList || [];
      } catch (error) {
        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('modelEDashboard.tablets.errorMessage'),
        });
      } finally {
        this.isLoadingTabletList = false;
      }
    },
    async submitForm() {
      if (this.isUploadingFile) return;

      this.isUploadingFile = true;

      try {
        const { data: response, success, detailMessage: message } = await apiManager.tablets.uploadTabletImage({
          file: this.file,
          dealerId: this.activeDealership.dealerId,
        });

        if(success) {
          const { verified ,unVerified_Reason: reason } = response;

          if (!verified) {
          throw new Error(reason);
        }
        }

        if(!success) {
          throw new Error(message);
        }

        this.$store.dispatch('topBanner/open', {
          message: this.$l('modelEDashboard.tablets.message'),
        });

        this.errorCount = 0;

        this.$store.dispatch('modelE/activationSummary/load');

        this.getTabletList();
      } catch (error) {
        this.errorCount += 1;

        this.$store.dispatch('topBanner/open', {
          type: 'error',
          message: this.$l('modelEDashboard.tablets.upload.error', { reason: error.message }),
        });
      } finally {
        this.isUploadingFile = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tablets {
  &__loading-indicator {
    margin-top: 40px;
  }
  &__table {
    width: 100%;
    max-width: 900px;
    margin-top: 40px;
  }
  &__form {
    display: grid;
    gap: 24px;
    margin-bottom: 16px;
    @include respond(sm-up) {
      grid-template-columns: minmax(350px, 400px) 1fr;
    }
  }
  &__form-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-self: center;
  }
  &__list-title {
    color: $color--fds-primary;
    font-weight: $font-weight--medium;
  }
  &__disclaimer {
    margin: 0;
    font-size: 14px;
  }
  &__review-disclaimer {
    margin-top: 20px;
  }
  &__refresh-button {
    margin-top: 20px;
  }
}
.indent15 {
  position:relative;
  left: 15px;
}
</style>
